import React from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import moment from "moment";

import { utilityActions } from "../_actions";
import { utilityService } from "../_services";

import { PageWithAppDrawer2 } from "../_components/main/PageWithAppDrawer2";
import FiltersGrid from "../_components/generic/FiltersGrid";
import { Paper, CircularProgress, Typography } from "@material-ui/core";
import {
  formatNumberToStringMoney,
  generateCSV,
  downloadBlob,
} from "../_helpers";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

class InvoicesPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {}

  render() {
    const { classes } = this.props;
    return (
      <PageWithAppDrawer2 pageTitle={"Auctions"}>
        <Paper className={classes.paper}>
          <InvoiceTable />
        </Paper>
      </PageWithAppDrawer2>
    );
  }

  handleAddItem = () => {
    // utilityService
    //   .addUtility("auctions", { auctionname: "NEW AUCTION" })
    //   .then((response) => {
    //     hashHistory.push(`/admin/auctions/${response.id}`);
    //   });
  };
}

function InvoiceTable(props) {
  const dispatch = useDispatch();
  const items = useSelector((state) => state.utility.invoices);
  // const clients_active = useSelector((state) => state.utility.clients_active);
  // const columns = [
  //   {
  //     width: 2,
  //     title: "Invoice Batch Date",
  //     field: "invoice_batch.invoicedate",
  //     dataRender: (item) =>
  //       moment(item.invoice_batch.invoicedate).format("YYYY-MM-DD"),
  //   },
  //   {
  //     width: 2,
  //     title: "Invoice #",
  //     field: "invoicedetailid",
  //     dataRender: (item) => (
  //       <RouterLink to={`/invoices/${item.invoicedetailid}`}>
  //         {item.invoicedetailid}
  //       </RouterLink>
  //     ),
  //   },
  //   {
  //     width: 3,
  //     title: "Client",
  //     field: "client.clientname",
  //   },
  //   {
  //     width: 1,
  //     title: "Batch #",
  //     field: "invoice_batch.invoiceid",
  //   },
  //   {
  //     width: 3,
  //     title: "Vehicle",
  //     field: "vehicle.vehiclevin",
  //     dataRender: (item) => (
  //       <RouterLink to={`/vehicles/${item.vehicle.vehicleid}`}>
  //         {item.vehicle.vehiclevin}
  //       </RouterLink>
  //     ),
  //   },
  //   {
  //     width: 1,
  //     title: "Total $",
  //     field: "totalcost",
  //     dataRender: (item) => formatNumberToStringMoney(item.totalcost),
  //   },
  //   // {
  //   //   width: 1,
  //   //   title: "Download",
  //   //   field: null,
  //   //   dataRender: (item) => (
  //   //     <BlobProvider document={InvoiceDocument}>
  //   //       {({ url }) => (
  //   //         <a href={url} target="_blank">
  //   //           Open in new tab
  //   //         </a>
  //   //       )}
  //   //     </BlobProvider>
  //   //   ),
  //   // },
  // ];

  let date = new Date();
  let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);

  const [availableFilters, setAvailableFilters] = React.useState([
    {
      name: "Start Date",
      field: "invoice_batch.invoicedate",
      operator: ">",
      value: moment(firstDay).format("YYYY-MM-DD"),
      // value: "2019-09-01",
      // value: "",
      type: "text",
      default: "",
    },
    {
      name: "End Date",
      field: "invoice_batch.invoicedate",
      operator: "<",
      value: "",
      type: "text",
      default: "",
    },
    {
      name: "Client",
      field: "clientid",
      operator: "eq",
      value: "",
      type: "choice",
      useUtility: true,
      utility: "clients_active",
      utilityValue: "clientname",
      utilityOrder: {
        field: "clientname",
        direction: "asc",
      },
      utilityFilters: [
        {
          field: "clientactive",
          operator: "eq",
          value: 2,
        },
      ],
      // choices: [],
      default: "",
    },
  ]);

  const [getData, setGetData] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [perPage, setPerPage] = React.useState(0);
  const [total, setTotal] = React.useState(0);

  const [report, setReport] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  // const downloadInvoice = (item) => {
  //   console.log("downloadInvoice", item.invoicedetailid);
  //   const invoice = <InvoiceDocument {...item}></InvoiceDocument>;
  //   const blob = pdf(invoice).toBlob();
  //   console.log("invoice", invoice);
  //   console.log("invoiceblob", blob);

  //   // downloadBlob(
  //   //   new Blob([pdf(invoice).toBlob()], { type: "application/pdf" }),
  //   //   `Invoice${item.invoicedetailid}.pdf`
  //   // );

  //   // downloadBlob(blob, `Invoice${item.invoicedetailid}.pdf`);
  // };

  React.useEffect(() => {
    if (getData === true) {
      setGetData(false);
      getNewData();
    }
  }, [getData]);

  React.useEffect(() => {
    setTotal(items.total ? Number(items.total) : 0);
    setPage(items.page ? Number(items.page) : 0);
    setPerPage(items.per_page ? Number(items.per_page) : 10);
  }, [items]);

  React.useEffect(() => {
    if (items.items.length === 0) {
      setLoading(true);
      getNewData();
    }
  }, []);

  const getNewData = async (download = false) => {
    let newFilters = availableFilters
      .filter((filter) => {
        if (["", null].includes(filter.value)) return false;
        if (
          ["isnull", "notnull"].includes(filter.operator) &&
          filter.value === false
        )
          return false;
        return true;
      })
      .map((filter) => {
        return {
          field: filter.field,
          operator: filter.operator,
          value: filter.value,
        };
      });
    console.log("newFilters", newFilters);

    if (download) {
      const options = {
        relations: ["vehicle", "invoice_batch", "client", "charges"],
        filters: newFilters,
        orders: [
          {
            field: "invoicedetailid",
            direction: "desc",
          },
        ],
      };
      await utilityService
        .getUtility("invoices", options)
        .then((values) => {
          return generateCSV(
            [
              "batch number",
              "invoice date",
              "client name",
              "user",
              "overnight charge",
              "overnight tracking #",
              "count",
              "total",
            ],
            [
              "invoicebatchid",
              "invoicedate",
              "client.clientname",
              "user.username",
              "overnightcharge",
              "overnighttrackingno",
              "invoice_count",
              "invoice_total",
            ],
            values.data
          );
        })
        .then((csv) => {
          const blob = new Blob([csv], { type: "text/csv" });
          downloadBlob(blob, "invoicebatches.csv");
        })
        .catch(console.error);
      return;
    }

    dispatch(
      utilityActions.getUtility(
        "invoices",
        {
          page,
          perPage: 1000,
          relations: ["vehicle", "invoice_batch", "client", "charges"],
          filters: newFilters,
          orders: [
            {
              field: "invoicedetailid",
              direction: "desc",
            },
          ],
        },
        (data) => {
          let report = [];
          if (data.data && data.data.length > 0) {
            for (const [index, data2] of data.data.entries()) {
              const clientId = data2.clientid;
              const invoiceDate = moment(data2.invoicedate).format("YYYYMMDD");

              if (!report[clientId]) {
                report[clientId] = {
                  client: data2.client,
                  dates: [],
                };
              }
              if (!report[clientId].dates[invoiceDate]) {
                report[clientId].dates[invoiceDate] = {
                  invoices: [],
                  date: moment(data2.invoicedate).format("MM/DD/YYYY"),
                  total: 0,
                };
              }
              report[clientId].dates[invoiceDate].invoices.push({
                invoiceId: data2.id,
                batchId: data2.invoicebatchid,
                vin: data2.vehicle.vehiclevin,
                total: data2.totalcost,
              });
              report[clientId].dates[invoiceDate].total += data2.totalcost;
            }
          }
          // console.log("report", report);
          setReport(report);
          setLoading(false);
        }
      )
    );
  };

  const handleFilterChange = (newFilter) => {
    const newFilters = availableFilters.map((filter) => {
      if (filter.name !== newFilter.name) return filter; // not the droid we're looking for, return unchanged
      filter.value = newFilter.value;
      return filter;
    });
    setAvailableFilters(newFilters);
    setPage(1);
    setGetData(true);
  };

  return (
    <>
      <FiltersGrid
        availableFilters={availableFilters}
        onChange={handleFilterChange}
      />
      {!loading && report.length > 0 && <InvoiceReport report={report} />}
      {!loading && report.length === 0 && <Typography>No data</Typography>}
      {loading && <CircularProgress />}
    </>
  );
}

function InvoiceReport(props) {
  const r = props.report;
  return (
    <Table style={{ width: "100%" }} size="small">
      {r.map((client, clientIndex) => (
        <React.Fragment key={clientIndex}>
          <TableHead>
            <TableRow>
              <TableCell
                colSpan={4}
                style={{ backgroundColor: "black", color: "white" }}
              >
                {client.client.clientname}
              </TableCell>
            </TableRow>
          </TableHead>
          {client.dates.map((date, dateIndex) => (
            <React.Fragment key={dateIndex}>
              <TableHead>
                <TableRow>
                  <TableCell
                    colSpan={3}
                    style={{ backgroundColor: "grey", color: "white" }}
                  >
                    {date.date}
                  </TableCell>
                  <TableCell
                    align="right"
                    style={{ backgroundColor: "grey", color: "white" }}
                  >
                    {formatNumberToStringMoney(date.total)}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableHead>
                <TableRow>
                  <TableCell>Invoice #</TableCell>
                  <TableCell>Batch #</TableCell>
                  <TableCell>VIN</TableCell>
                  <TableCell>Total</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {date.invoices.map((invoice, invoiceIndex) => (
                  <TableRow key={invoiceIndex}>
                    <TableCell>{invoice.invoiceId}</TableCell>
                    <TableCell>{invoice.batchId}</TableCell>
                    <TableCell>{invoice.vin}</TableCell>
                    <TableCell align="right">
                      {formatNumberToStringMoney(invoice.total)}
                    </TableCell>
                  </TableRow>
                ))}
                {/* <TableRow>
                  <TableCell colSpan={4} align="right">
                    {formatNumberToStringMoney(date.total)}
                  </TableCell>
                </TableRow> */}
              </TableBody>
            </React.Fragment>
          ))}
        </React.Fragment>
      ))}
    </Table>
  );
}

const styles = (theme) => ({
  root: {
    display: "flex",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
});

function mapStateToProps(state) {
  const { authentication } = state;
  const { user } = authentication;
  return {
    user,
  };
}

const connectedInvoicesPage = connect(mapStateToProps)(
  withStyles(styles)(InvoicesPage)
);
export { connectedInvoicesPage as InvoicesPage };
