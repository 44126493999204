import React from "react";
import { Link as RouterLink } from "react-router-dom";
// import Link from "@material-ui/core/Link";
import { connect, useSelector, useDispatch } from "react-redux";
import { withStyles } from "@material-ui/core/styles";

import moment from "moment";

import { utilityActions } from "../_actions";
import { utilityService } from "../_services";
import { hashHistory } from "../_helpers";

import { PageWithAppDrawer2 } from "../_components/main/PageWithAppDrawer2";
import ResponsiveGrid from "../_components/generic/ResponsiveGrid";
import FiltersGrid from "../_components/generic/FiltersGrid";
import { Paper, Button, Grid } from "@material-ui/core";

class ClientLoadsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {}

  render() {
    const { classes } = this.props;

    return (
      <PageWithAppDrawer2 pageTitle={"Client Loads"}>
        <Paper className={classes.paper}>
          <ClientLoadsTable />
        </Paper>
      </PageWithAppDrawer2>
    );
  }
}

function ClientLoadsTable(props) {
  const dispatch = useDispatch();
  const items = useSelector((state) => state.utility.client_loads);
  const webUser = useSelector((state) => state.authentication.webUser);
  const isAdmin = webUser.roleid === 2 ? true : false;
  const isClient = webUser.roleid !== 2 ? true : false;
  const clientId = webUser.client_id;
  const columns = [
    {
      width: 2,
      title: "Client Name",
      field: "client.clientname",
      dataRender: (item) =>
        (item.client && item.client.clientname) || <i>Unassigned</i>,
    },
    {
      width: 2,
      title: "Name",
      field: "name",
      dataRender: (item) => (
        <RouterLink to={`/clientLoads/${item.id}`}>{item.name}</RouterLink>
      ),
    },
    {
      width: 1,
      title: "Sent to Bay",
      field: "bay_shipment",
      dataRender: (item) => (item.bay_shipment ? "Sent" : ""),
    },
    {
      width: 1,
      title: "Type",
      field: "load_type",
    },
    {
      width: 1,
      title: "Created",
      field: "created_at",
      dataRender: (item) => moment(item.created_at).format("M/D/Y"),
    },
    {
      width: 1,
      title: "# Vehicles",
      field: "vehicles_count",
    },
    {
      width: 2,
      title: "Created By",
      field: "created_by.name",
    },
  ];

  const [availableFilters, setAvailableFilters] = React.useState([
    {
      name: "Client",
      field: "client_id",
      operator: "eq",
      value: isClient ? clientId : "",
      type: isClient ? "hidden" : "choice",
      type: "choice",
      useUtility: true,
      utility: "clients_active",
      utilityValue: "clientname",
      utilityOrder: {
        field: "clientname",
        direction: "asc",
      },
      utilityFilters: [
        {
          field: "clientactive",
          operator: "eq",
          value: 2,
        },
      ],
      choices: [],
      default: "",
    },
    {
      name: "Name",
      field: "name",
      operator: "like",
      value: "",
      type: "text",
      default: "",
    },
  ]);

  const [getData, setGetData] = React.useState(false);

  const [page, setPage] = React.useState(
    items.page ? Number(items.page) - 1 : 0
  );
  const [perPage, setPerPage] = React.useState(
    items.per_page ? Number(items.per_page) : 0
  );
  const [total, setTotal] = React.useState(
    items.total ? Number(items.total) : 0
  );

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
    setGetData(true);
  };
  const handleChangeRowsPerPage = (event) => {
    const value = parseInt(event.target.value);
    setPerPage(value);
    setGetData(true);
  };
  React.useEffect(() => {
    if (getData === true) {
      setGetData(false);
      getNewData();
    }
  }, [getData]);

  React.useEffect(() => {
    setTotal(items.total ? Number(items.total) : 0);
    setPage(items.page ? Number(items.page) : 0);
    setPerPage(items.per_page ? Number(items.per_page) : 10);
  }, [items]);

  React.useEffect(() => {
    if (items.items.length === 0) {
      getNewData();
    }
  }, []);

  const getNewData = () => {
    let newFilters = availableFilters
      .filter((filter) => {
        if (["", null].includes(filter.value)) return false;
        if (
          ["isnull", "notnull"].includes(filter.operator) &&
          filter.value === false
        )
          return false;
        return true;
      })
      .map((filter) => {
        return {
          field: filter.field,
          operator: filter.operator,
          value: filter.value,
        };
      });
    dispatch(
      utilityActions.getUtility("client_loads", {
        page,
        perPage,
        filters: newFilters,
        relations: ["created_by", "client", "bay_shipment"],
        orders: [{ field: "created_at", direction: "desc" }],
      })
    );
  };

  const handleFilterChange = (newFilter) => {
    const newFilters = availableFilters.map((filter) => {
      if (filter.field !== newFilter.field) return filter; // not the droid we're looking for, return unchanged
      filter.value = newFilter.value;
      return filter;
    });
    setAvailableFilters(newFilters);
    setPage(1);
    setGetData(true);
  };

  const addItem = (load_type = "multiple") => {
    utilityService
      .addUtility("client_loads", {
        name: "NEW LOAD " + load_type,
        load_type,
        client_id: isClient ? clientId : 0,
        created_by: webUser.userid,
      })
      .then((response) => {
        getNewData();
        hashHistory.push(`/clientLoads/${response.id}`);
      });
  };

  return (
    <>
      <Grid container spacing={1}>
        <Grid item>
          <Button
            color="primary"
            variant="contained"
            onClick={() => addItem("multiple")}
          >
            Add Load (Multiple)
          </Button>
        </Grid>
        <Grid item>
          <Button
            color="primary"
            variant="contained"
            onClick={() => addItem("single")}
          >
            Add Load (Single)
          </Button>
        </Grid>
      </Grid>
      <FiltersGrid
        availableFilters={availableFilters}
        onChange={handleFilterChange}
      />
      <ResponsiveGrid
        loading={items.loading}
        columns={columns}
        data={items.items}
        page={page - 1} // API has a 1 based page count, Material UI has a zero based page count
        perPage={perPage}
        total={total}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </>
  );
}

const styles = (theme) => ({
  root: {
    display: "flex",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
});

function mapStateToProps(state) {
  const { authentication } = state;
  const { user, webUser } = authentication;
  return {
    user,
    webUser,
  };
}

const connectedClientLoadsPage = connect(mapStateToProps)(
  withStyles(styles)(ClientLoadsPage)
);
export { connectedClientLoadsPage as ClientLoadsPage };
