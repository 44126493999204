import React, { useEffect, useState } from "react";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useDispatch, useSelector } from "react-redux";

import { utilityActions } from "../../_actions";
import { utilityService } from "../../_services";

import ResponsiveGrid from "../generic/ResponsiveGrid";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { CircularProgress, Typography } from "@material-ui/core";

import { Grid, Input } from "@material-ui/core";

export default function ModalClientLoadAddVehicle(props) {
  const dispatch = useDispatch();
  const vehicleSelector = useSelector(
    (state) => state.utility.client_load_vehicles
  );
  const [render, setRender] = useState(0);

  const [getData, setGetData] = useState(false);
  const [page, setPage] = useState(
    vehicleSelector.page ? Number(vehicleSelector.page) - 1 : 0
  );
  const [perPage, setPerPage] = useState(
    vehicleSelector.per_page ? Number(vehicleSelector.per_page) : 0
  );
  const [total, setTotal] = useState(
    vehicleSelector.total ? Number(vehicleSelector.total) : 0
  );
  const [error, setError] = useState("");

  useEffect(() => {
    setTotal(vehicleSelector.total ? Number(vehicleSelector.total) : 0);
    setPage(vehicleSelector.page ? Number(vehicleSelector.page) : 0);
    setPerPage(
      vehicleSelector.per_page ? Number(vehicleSelector.per_page) : 10
    );
  }, [vehicleSelector]);
  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
    setGetData(true);
  };
  const handleChangeRowsPerPage = (event) => {
    const value = parseInt(event.target.value);
    setPerPage(value);
    setGetData(true);
  };
  useEffect(() => {
    if (getData === true) {
      setGetData(false);
      getVehicles();
    }
  }, [getData]);

  const [open, setOpen] = useState(props.open || false);
  // const [vehicles, setVehicles] = useState([]);
  let [loadingIndex, setLoadingIndex] = useState([]);
  let [addedIndex, setAddedIndex] = useState([]);

  const addVehicle = async (clientLoadId, vehicleId) => {
    loadingIndex.push(vehicleId);
    setLoadingIndex(loadingIndex);
    await utilityService
      .getEndpoint(`api/client_loads/${props.item.id}/add/${vehicleId}`, "POST")
      .then((response) => {
        addedIndex.push(vehicleId);
        setAddedIndex(addedIndex);
        setRender(render + 1);
      })
      .catch((error) => {
        setError(error);
      });
  };

  const columns = [
    {
      width: 2,
      title: "Add",
      field: null,
      dataRender: (item, rowIndex, colIndex) => {
        const updating =
          loadingIndex && loadingIndex.includes(item.vehicleid) ? true : false;
        const added =
          addedIndex && addedIndex.includes(item.vehicleid) ? true : false;

        if (added) {
          return "Added";
        }

        if (!added) {
          return (
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                addVehicle(props.item.id, item.vehicleid);
              }}
            >
              {updating ? (
                <CircularProgress color="secondary" size={20} />
              ) : (
                `Add`
              )}
            </Button>
          );
        }
      },
    },
    {
      width: 4,
      title: "VIN",
      field: "vehiclevin",
    },
  ];

  const [availableFilters, setAvailableFilters] = useState([
    {
      name: "Client",
      field: "clientid",
      operator: "eq",
      value: props.item.client_id,
      type: "hidden",
      default: "",
    },
    {
      name: "VIN",
      field: "vehiclevin",
      operator: "like",
      value: "",
      type: "text",
      default: "",
    },
    {
      name: "Last6",
      field: "last6",
      operator: "eq",
      value: "",
      type: "text",
      default: "",
    },
  ]);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));

  useEffect(() => {
    setOpen(props.open);
  }, [props.open]);

  useEffect(() => {
    if (open === true) getVehicles();
  }, [open]);

  const handleClose = () => {
    setOpen(false);
    setAvailableFilters(
      availableFilters.map((filter) => {
        return {
          ...filter,
          value: "",
        };
      })
    );
    if (props.onClose) props.onClose();
  };

  const getVehicles = () => {
    dispatch(
      utilityActions.getUtility("client_load_vehicles", {
        page,
        perPage,
        filters: [
          {
            field: "client_load_id",
            operator: "isnull",
            value: null,
          },
          ...availableFilters.filter((filter) => filter.value !== ""),
        ],
        orders: [{ field: "vehicleid", direction: "desc" }],
      })
    );
  };

  const handleFilterChange = async (newFilter) => {
    const newFilters = availableFilters.map((filter) => {
      if (filter.field !== newFilter.field) return filter; // not the droid we're looking for, return unchanged
      filter.value = newFilter.value;
      return filter;
    });
    await setAvailableFilters(newFilters);
    getVehicles();
  };

  return (
    <Dialog open={open} fullScreen={fullScreen}>
      <DialogTitle id="form-dialog-title" style={{ width: 600 }}>
        Add Vehicle to Load
      </DialogTitle>
      <DialogContent>
        <FiltersGrid
          availableFilters={availableFilters}
          onChange={handleFilterChange}
        />
        {error && (
          <Typography
            variant="h5"
            style={{ textAlign: "center", color: "red" }}
          >
            {error}
          </Typography>
        )}
        <ResponsiveGrid
          loading={vehicleSelector.loading}
          data={vehicleSelector.items}
          columns={columns}
          page={page - 1} // API has a 1 based page count, Material UI has a zero based page count
          perPage={perPage}
          total={total}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Done
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function FiltersGrid(props) {
  const [availableFilters, setAvailableFilters] = useState([]);

  useEffect(() => {
    setAvailableFilters(props.availableFilters);
  }, [props]);

  return (
    <Grid
      container
      display="flex"
      justify="center"
      alignContent="center"
      alignItems="center"
    >
      <Grid
        container
        style={{ flex: 1, fontSize: 12 }}
        alignItems="center"
        alignContent="center"
      >
        {availableFilters.map((filter, index) => {
          if (filter.type === "hidden")
            return <React.Fragment key={index}></React.Fragment>;
          const isActive = filter.value === filter.default ? false : true;
          return (
            <React.Fragment key={index}>
              <Grid
                item
                xs={2}
                style={{ fontWeight: isActive ? "bold" : "inherit" }}
              >
                {filter.name}
              </Grid>
              <Grid item xs={4}>
                {filter.type === "text" && (
                  <FiltersTypeText filter={filter} onChange={props.onChange} />
                )}
              </Grid>
            </React.Fragment>
          );
        })}
      </Grid>
    </Grid>
  );
}

function FiltersTypeText(props) {
  const [filter, setFilter] = useState({});
  // const [value, setValue] = useState("");
  const [text, setText] = useState("");

  useEffect(() => {
    setFilter(props.filter);
    setText(props.filter.value || "");
  }, [props.filter]);

  return (
    <Input
      style={{ fontSize: 12 }}
      value={text || ""}
      placeholder={filter.name}
      onChange={(event) => setText(event.target.value)}
      onKeyPress={(event) => {
        if (event.key === "Enter") {
          props.onChange({
            field: filter.field,
            operator: "like",
            value: text,
          });
        }
      }}
    />
  );
}
