import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import { Route, Redirect } from "react-router-dom";

import { utilityActions } from "../_actions";
import { getEnvironmentURL } from "../_helpers";

import { PageWithAppDrawer2 } from "../_components/main/PageWithAppDrawer2";
import ResponsiveGrid from "../_components/generic/ResponsiveGrid";
import ModalEditGst from "../_components/vehicle/ModalEditGst";

import FiltersGrid from "../_components/generic/FiltersGrid";
import {
  Paper,
  Button,
  makeStyles,
  Grid,
  Typography,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import LaunchTwoToneIcon from "@material-ui/icons/LaunchTwoTone";
import BlockIcon from "@material-ui/icons/Block";
import JSONPretty from "react-json-pretty";
import ModalGeneric from "../_components/generic/ModalGeneric";
import useHasRole from "../_hooks/useHasRole";

export default function GstSubmissionsPage(props) {
  const classes = useStyles();

  return (
    <PageWithAppDrawer2 pageTitle={"Gst Submissions"}>
      <Paper className={classes.paper}>
        <GstSubmissions />
      </Paper>
    </PageWithAppDrawer2>
  );
}

function GstSubmissions(props) {
  const dispatch = useDispatch();
  const items = useSelector((state) => state.utility.vehicle_gst);
  const webUser = useSelector((state) => state.authentication.webUser);
  const hasGstRole = useHasRole("gst_manage");

  const clientGroup = webUser.client_group || null;
  const clientsList = clientGroup
    ? clientGroup.clients_base.map((c) => c.clientid)
    : [webUser.client_id];
  const isAdmin = webUser.roleid === 2 ? true : false;
  const isClient = webUser.roleid !== 2 ? true : false;
  const clientId = webUser.client_id;

  const [selectedItems, setSelectedItems] = useState([]);
  const [modalGenerate, setModalGenerate] = useState(false);
  const [modalGstEdit, setModalGstEdit] = useState(false);
  const [modalGstVehicleId, setModalGstVehicleId] = useState(null);
  const [modalGstClientId, setModalGstClientId] = useState(null);

  const [setAppliedDate, setSetAppliedDate] = useState(false);
  const gstFormRef = useRef(null);

  const checkItem = (vehicleId, checked) => {
    if (!checked) {
      setSelectedItems((selectedItems) => [
        ...selectedItems.filter((item) => item != vehicleId),
      ]);
    } else {
      setSelectedItems((selectedItems) => [...selectedItems, vehicleId]);
    }
  };

  const columns = [
    {
      width: 2,
      title: "Client Name",
      field: "client_base.clientname",
      dataRender: (item) => {
        return (
          <>
            {Number(item.gst_value) < 1 ||
            item.applied_date ||
            !item.purchase_date ? (
              <>
                <BlockIcon style={{ color: "red" }} />
                {item.client_base.clientname}
              </>
            ) : (
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={(event) =>
                      checkItem(item.id, event.target.checked)
                    }
                    name={`gst_vehicle_${item.id}`}
                  />
                }
                label={
                  <Typography style={{ fontSize: 12 }}>
                    {item.client_base.clientname}
                  </Typography>
                }
              />
            )}
          </>
        );
      },
    },
    {
      width: 2,
      title: "VIN",
      field: "vehicle_base.vehiclevin",
      dataRender: (item) => (
        <>
          <RouterLink
            to={`/vehicles/${item.vehicle_base.vehicleid}`}
            target="_blank"
          >
            {item.vehicle_base.vehiclevin}{" "}
            <LaunchTwoToneIcon style={{ fontSize: 12 }} color="primary" />
          </RouterLink>
          {item.year_make_model}
          <br />
          <Button
            size="small"
            variant="outlined"
            onClick={() =>
              launchGstModal({
                vehicleId: item.vehicle_id,
                clientId: item.client_id,
              })
            }
          >
            Edit GST Info
          </Button>
        </>
      ),
    },
    {
      width: 2,
      title: "GST Value",
      field: "gst_value",
      dataRender: (item) => (
        <>
          {!item.gst_value ? (
            <BlockIcon style={{ color: "red" }} />
          ) : (
            `${item.gst_value}`
          )}
        </>
      ),
    },
    {
      width: 2,
      title: "Purchase Date",
      field: "purchase_date",
      dataRender: (item) => (
        <>
          {!item.purchase_date ? (
            <BlockIcon style={{ color: "red" }} />
          ) : (
            `${item.purchase_date}`
          )}
        </>
      ),
    },
    {
      width: 2,
      title: "Applied Date",
      field: "applied_date",
    },
    {
      width: 2,
      title: "Received Date",
      field: "received_date",
    },
  ];

  const [availableFilters, setAvailableFilters] = useState([
    {
      name: "VIN",
      field: "vehicle_base.vehiclevin",
      operator: "like",
      value: "",
      type: "text",
      default: "",
    },
    {
      name: "Cancelled",
      field: "vehicle_base.cancelled",
      operator: "neq",
      value: "1",
      type: "hidden",
      default: "",
    },
    isAdmin && {
      name: "Client",
      field: "client_id",
      operator: "eq",
      value: "",
      type: "choice",
      useUtility: true,
      utility: "clients_active_gst",
      utilityValue: "clientname",
      utilityOrder: {
        field: "clientname",
        direction: "asc",
      },
      utilityFilters: [
        {
          field: "clientactive",
          operator: "eq",
          value: 2,
        },
        {
          field: "gst_info_base.docs_complete",
          operator: "eq",
          value: 1,
        },
      ],
      choices: [],
      default: "",
    },
    isClient &&
      !clientGroup && {
        name: "Client",
        field: "client_id",
        operator: "eq",
        value: clientId,
        type: "hidden",
        default: "",
      },
    isClient &&
      clientGroup && {
        name: "Clients",
        field: "client_id",
        operator: "in",
        value: clientsList.join(","),
        type: "hidden",
        default: "",
      },
    {
      name: "Not Applied For",
      field: "applied_date",
      operator: "isnull",
      value: "1",
      type: "choice",
      choices: [
        {
          key: "1",
          value: "Not Applied",
        },
      ],
      default: "",
    },
    {
      name: "Already Applied For",
      field: "applied_date",
      operator: "notnull",
      value: "",
      type: "choice",
      choices: [
        {
          key: "1",
          value: "Already Applied",
        },
      ],
      default: "",
    },
    {
      name: "Missing GST Value",
      field: "gst_value",
      operator: "isnull",
      value: "",
      type: "choice",
      choices: [
        {
          key: "1",
          value: "No GST Value",
        },
      ],
      default: "",
    },
    {
      name: "Missing Purchase Date",
      field: "purchase_date",
      operator: "isnull",
      value: "",
      type: "choice",
      choices: [
        {
          key: "1",
          value: "No Purchase Date",
        },
      ],
      default: "",
    },
    {
      name: "Purchase Date Start",
      field: "purchase_date",
      operator: ">=",
      value: "",
      type: "date",
      default: "",
    },
    {
      name: "Purchase Date End",
      field: "purchase_date",
      operator: "<=",
      value: "",
      type: "date",
      default: "",
    },
  ]);

  const [getData, setGetData] = useState(false);

  const [page, setPage] = useState(items.page ? Number(items.page) - 1 : 0);
  const [perPage, setPerPage] = useState(
    items.per_page ? Number(items.per_page) : 50
  );
  const [total, setTotal] = useState(items.total ? Number(items.total) : 0);

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
    setGetData(true);
  };
  const handleChangeRowsPerPage = (event) => {
    const value = parseInt(event.target.value);
    setPerPage(value);
    setGetData(true);
  };
  useEffect(() => {
    if (getData === true) {
      setGetData(false);
      getNewData();
    }
  }, [getData]);

  useEffect(() => {
    setTotal(items.total ? Number(items.total) : 0);
    setPage(items.page ? Number(items.page) : 0);
    setPerPage(items.per_page ? Number(items.per_page) : 50);
  }, [items]);

  useEffect(() => {
    if (items.items.length === 0) {
      getNewData();
    }
  }, []);

  const getNewData = (download = false) => {
    let newFilters = availableFilters
      .filter((filter) => {
        if ([null, false].includes(filter)) return false;
        if (["", null].includes(filter.value)) return false;
        if (
          ["isnull", "notnull"].includes(filter.operator) &&
          filter.value === false
        )
          return false;
        return true;
      })
      .map((filter) => {
        return {
          field: filter.field,
          operator: filter.operator,
          value: filter.value,
        };
      });

    dispatch(
      utilityActions.getUtility("vehicle_gst", {
        page,
        perPage,
        filters: newFilters,
        relations: ["client_base", "vehicle_base"],
        appends: ["year_make_model"],
        // params: "withCount[]=images&withCount[]=vehicle_photos",
        // orders: [{ field: "crossedborderdate", direction: "desc" }],
      })
    );
  };

  const handleFilterChange = (newFilter) => {
    const newFilters = availableFilters.map((filter) => {
      if (filter.field !== newFilter.field) return filter; // not the droid we're looking for, return unchanged
      if (filter.name !== newFilter.name) return filter;
      filter.value = newFilter.value;
      return filter;
    });
    setAvailableFilters(newFilters);
    setPage(1);
    setGetData(true);
  };

  const launchGstModal = (params = {}) => {
    const { vehicleId, clientId } = params;
    setModalGstEdit(true);
    setModalGstVehicleId(vehicleId);
    setModalGstClientId(clientId);
  };

  if (hasGstRole === false) {
    return (
      <Route
        render={(props) => (
          <Redirect
            to={{ pathname: "/access/gst", state: { from: props.location } }}
          />
        )}
      />
    );
  }

  return (
    <>
      <Grid container spacing={1}>
        <Grid item>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              getNewData();
            }}
          >
            Refresh
          </Button>
        </Grid>
      </Grid>

      <FiltersGrid
        availableFilters={availableFilters}
        onChange={handleFilterChange}
      />

      <Grid container style={{ marginTop: 10 }}>
        <Grid item xs={6}>
          {selectedItems.length} vehicles selected
        </Grid>
        {selectedItems.length > 0 && (
          <Grid item xs={6}>
            <Button
              size="small"
              variant="contained"
              color="primary"
              onClick={() => setModalGenerate(true)}
            >
              Generate GST Document
            </Button>
            <Button
              size="small"
              variant="contained"
              color="primary"
              onClick={() => {
                setSelectedItems([]);
                getNewData();
              }}
            >
              Clear selected items
            </Button>
          </Grid>
        )}
      </Grid>

      <ResponsiveGrid
        loading={items.loading}
        columns={columns}
        data={items.items}
        page={page - 1} // API has a 1 based page count, Material UI has a zero based page count
        perPage={perPage}
        total={total}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />

      <ModalEditGst
        open={modalGstEdit}
        vehicleId={modalGstVehicleId}
        clientId={modalGstClientId}
        onClose={() => {
          setModalGstEdit(false);
          setModalGstClientId(null);
          setModalGstVehicleId(null);
          getNewData();
        }}
        onCancel={() => {
          setModalGstEdit(false);
          setModalGstClientId(null);
          setModalGstVehicleId(null);
        }}
      />

      <ModalGeneric
        open={modalGenerate}
        title="GST Generate Documents"
        closeText="Generate GST Documents"
        onClose={() => {
          setModalGenerate(false);
          gstFormRef.current.submit();
        }}
        cancelText="Cancel"
        onCancel={() => setModalGenerate(false)}
      >
        <FormControlLabel
          control={
            <Checkbox
              name="setAppliedDate"
              onChange={(e) => setSetAppliedDate(e.target.checked)}
            />
          }
          label="Set Applied Date"
        />
      </ModalGeneric>
      <form
        id="form-generate-gst"
        name="form-generate-gst"
        target="_blank"
        onSubmit={(e) => e.preventDefault()}
        ref={gstFormRef}
        method="post"
        action={getEnvironmentURL() + "/api/gst-paperwork"}
      >
        <input type="hidden" name="setAppliedDate" value={setAppliedDate} />
        <input type="hidden" name="gstIds" value={selectedItems} />
        <input type="hidden" name="webUser" value={webUser.userid} />
      </form>
      <JSONPretty data={JSON.stringify(selectedItems)} />
      <JSONPretty data={JSON.stringify(setAppliedDate)} />
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
}));
