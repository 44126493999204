import React from "react";
import { useSelector } from "react-redux";

import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import HistoryItems from "../HistoryItems";
import Divider from "@material-ui/core/Divider";
import VehicleDetailHeaderSummaryDisplay from "./VehicleDetailHeaderSummaryDisplay";

export default function VehicleDetailOther(props) {
  const vehicle = props.item;
  const isAdmin = useSelector((state) =>
    state.authentication.webUser.roleid === 2 ? true : false
  );
  return (
    <>
      <VehicleDetailHeaderSummaryDisplay
        vehicle={{
          vin: props?.item?.vehiclevin,
          year: props?.item?.yr,
          make: props?.item?.make,
          model: props?.item?.model,
        }}
        titleStatus={props?.item?.title_status?.statusname}
        timeInLatestStatus={props?.item?.time_in_latest_status}
        latestNote={props?.item?.latest_note?.note}
      />
      <FormControl fullWidth>
        <InputLabel shrink>Rev Hit</InputLabel>
        <Select
          native
          value={0}
          onChange={() => {}}
          displayEmpty
          disabled={!isAdmin}
          name="revhit"
        >
          <option value={0}>No</option>
          <option value={1}>Yes</option>
        </Select>
      </FormControl>
      <Divider style={{ marginTop: 20 }} />
      <HistoryItems objType={"vehicle"} id={vehicle.id} />
    </>
  );
}
