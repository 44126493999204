import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Font,
  Image,
} from "@react-pdf/renderer";

import logo from "../../_assets/dvslogo_web_new_cropped.png";

import moment from "moment";

const debug = false;

// https://medium.com/investing-in-tech/creating-api-driven-pdfs-on-the-fly-in-the-browser-using-react-react-pdf-and-typescript-78ab4d1e7da5
// https://react-pdf.org/advanced#on-the-fly-rendering

Font.register({
  family: "Oswald",
  src: "https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf",
});

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
    fontFamily: "Helvetica",
  },
  bold: {
    fontFamily: "Helvetica-Bold",
  },
  title: {
    fontSize: 24,
    textAlign: "center",
    // fontFamily: "Oswald",
  },
  author: {
    fontSize: 12,
    textAlign: "center",
    // marginBottom: 40,
  },
  subtitle: {
    fontSize: 18,
    margin: 12,
    // fontFamily: "Oswald",
  },
  text: {
    margin: 12,
    fontSize: 12,
    textAlign: "left",
    // fontFamily: "Times-Roman",
  },
  image: {
    marginVertical: 15,
    marginHorizontal: 100,
  },
  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: "center",
    color: "grey",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
  viewCustomerInfo: {
    border: "1px solid black",
  },
  hr: {
    borderStyle: "solid",
    borderTopColor: "black",
    borderTopWidth: 1,
  },
});

export function InvoiceDocument(props) {
  let invoiceId, invoiceDate, invoiceTotal, client, invoices;

  if (props.item) {
    invoiceId = props.item.invoiceid;
    invoiceDate = props.item.invoicedate;
    invoiceTotal = props.item.invoice_total;
    client = props.item.client;
    invoices = props.item.invoices;
  }

  if (props.client) {
    client = props.client;
  }

  if (props.invoices) {
    invoices = props.invoices;
  }

  const clientDefaults = client && client.defaults;

  const hasVehicleDetails =
    (clientDefaults &&
      clientDefaults.vehicle &&
      clientDefaults.vehicle.invoice_vehicle_details &&
      clientDefaults.vehicle.invoice_vehicle_details.value &&
      clientDefaults.vehicle.invoice_vehicle_details.value === true) ||
    false;
  const extraInfo =
    (clientDefaults &&
      clientDefaults.vehicle &&
      clientDefaults.vehicle.invoice_vehicle_extra_info &&
      clientDefaults.vehicle.invoice_vehicle_extra_info.value) ||
    "";
  // console.log("extraInfo", extraInfo);
  const bulkInvoice =
    props.singleInvoice === 2 || props.singleInvoice === true ? false : true;
  const showDetails = client.clientinvoicedetails === 1 ? true : false;

  if (bulkInvoice) {
    return (
      <Document>
        <InvoicePage>
          <InvoiceHeader invoiceNumber={invoiceId} />
          <InvoiceCustomerInfo
            client={client}
            invoiceDate={invoiceDate}
            extraInfo={extraInfo}
          />
          <InvoiceLine />
          <InvoiceDetailsHeader details={showDetails} />
          <InvoiceDetailsTable
            invoices={invoices}
            details={showDetails}
            bulkInvoice={bulkInvoice}
            hasVehicleDetails={hasVehicleDetails}
          />
          <InvoiceLine />
          <InvoiceDetailsFooter total={invoiceTotal} />
          <FooterPageNumber />
        </InvoicePage>
      </Document>
    );
  }

  if (!bulkInvoice) {
    return (
      <Document>
        {invoices.map((i, index) => (
          <InvoicePage key={index}>
            <InvoiceHeader invoiceNumber={i.invoicedetailid} />
            <InvoiceCustomerInfo
              client={client}
              invoiceDate={invoiceDate}
              extraInfo={extraInfo}
            />
            <InvoiceLine />
            <InvoiceDetailsHeader details={showDetails} />
            <InvoiceDetailsTable
              invoices={[i]}
              details={showDetails}
              bulkInvoice={bulkInvoice}
              total={i.totalcost}
              hasVehicleDetails={hasVehicleDetails}
            />
            <InvoiceLine />
            <InvoiceDetailsFooter total={i.totalcost} />
            <FooterPageNumber />
          </InvoicePage>
        ))}
      </Document>
    );
  }
}

function InvoicePage(props) {
  return <Page style={styles.body}>{props.children}</Page>;
}

function InvoiceHeader(props) {
  return (
    <View fixed>
      <View style={{ alignItems: "center" }} debug={debug}>
        <Image
          style={[styles.image, { width: "20%" }]}
          src={logo}
          debug={debug}
        />
      </View>
      <View style={{ flexDirection: "row" }}>
        <View style={{ width: "50%" }}>
          <Text style={styles.author}>1919b S Post Rd</Text>
          <Text style={styles.author}>Indianapolis, IN 46239</Text>
        </View>
        <View style={{ width: "50%" }}>
          <Text style={styles.author}>Tel: +1.317.862.9100</Text>
          <Text style={styles.author}> Fax: +1.317.862.7625</Text>
        </View>
      </View>

      <InvoiceLine />

      <Text style={[styles.author, { marginTop: 10 }]}>
        <Text style={styles.bold}>Invoice Number:</Text>{" "}
        {props.invoiceNumber || "foo"}
      </Text>

      <InvoiceLine />
    </View>
  );
}

function InvoiceCustomerInfo(props) {
  return (
    <View style={[styles.text, { flexDirection: "row" }]}>
      <View style={{ width: "70%" }} debug={debug}>
        <Text style={styles.bold} debug={debug}>
          Customer
        </Text>
        <Text>{props.client.clientname}</Text>
        <Text> </Text>
        <Text style={styles.bold} debug={debug}>
          Address
        </Text>
        <Text>{props.client.clientstreetaddress}</Text>
        <Text>
          {props.client.clientcity}, {props.client.clientstate}{" "}
          {props.client.clientzip}
        </Text>
      </View>

      <View style={{ width: "30%" }} debug={debug}>
        <Text style={styles.bold} debug={debug}>
          Invoice Date
        </Text>
        <Text debug={debug}>
          {props.invoiceDate
            ? moment(props.invoiceDate).format("M/D/Y")
            : moment().format("M/D/Y")}
        </Text>
        <Text> </Text>
        <Text>{props.extraInfo || ""}</Text>
      </View>
    </View>
  );
}

function InvoiceDetailsHeader(props) {
  const details = props.details === true ? true : false;

  return (
    <View style={styles.text} debug={debug}>
      {details ? (
        <View style={[styles.bold, { flexDirection: "row" }]}>
          <View style={{ width: "30%" }}>
            <Text>VIN</Text>
          </View>
          <View style={{ width: "30%" }}>
            <Text>Customer</Text>
          </View>
          <View style={{ width: "40%" }}>
            <Text>Charges</Text>
          </View>
        </View>
      ) : (
        <View style={[styles.bold, { flexDirection: "row" }]} debug={debug}>
          <View style={{ width: "30%" }}>
            <Text>VIN</Text>
          </View>
          <View style={{ width: "30%" }}>
            <Text>Customer</Text>
          </View>
          <View style={{ width: "10%" }}>
            <Text>State Fees</Text>
          </View>
          <View style={{ width: "10%" }}>
            <Text>DVS Fees</Text>
          </View>
          <View style={{ width: "10%" }}>
            <Text>Other Fees</Text>
          </View>
          <View style={{ width: "10%" }}>
            <Text>Total</Text>
          </View>
        </View>
      )}
    </View>
  );
}

function InvoiceDetailsTable(props) {
  const details = props.details === true ? true : false;
  const bulkInvoice = props.bulkInvoice === true ? true : false;
  // const invoiceTotal = props.total;
  const hasVehicleDetails = props.hasVehicleDetails || false;

  if (details) {
    return (
      <View debug={debug}>
        {props.invoices.map((invoice, index) => {
          const vin = invoice.vehicle
            ? invoice.vehicle.vehiclevin
            : "Charges w/o Vehicle";
          const vehDetails = invoice.vehicle
            ? `${invoice.vehicle.yr} ${invoice.vehicle.make} ${invoice.vehicle.model}`
            : "";
          const vehMileage = invoice.vehicle
            ? `Mileage: ${invoice.vehicle.mileage}`
            : "";
          const vehDebtorName = invoice.vehicle
            ? invoice.vehicle.debtorname
            : "";
          return (
            <View style={styles.text} key={index} debug={debug} wrap={false}>
              <View style={{ flexDirection: "row" }} debug={debug}>
                {!hasVehicleDetails && (
                  <View style={{ width: "30%", fontSize: 10 }}>
                    <Text>{vin}</Text>
                  </View>
                )}
                {hasVehicleDetails && (
                  <View style={{ width: "30%", fontSize: 10 }}>
                    <Text>{vin}</Text>
                    <Text>{vehDetails}</Text>
                    <Text>{vehMileage}</Text>
                  </View>
                )}
                <View style={{ width: "30%", fontSize: 10 }}>
                  <Text>{vehDebtorName}</Text>
                </View>
                <View style={{ width: "40%", fontSize: 10 }}>
                  <Text> </Text>
                </View>
              </View>
              {invoice.charges.map((charge, index) => {
                if (Number(charge.chargeamt) === 0) {
                  return;
                }
                return (
                  <View
                    key={index}
                    style={{ flexDirection: "row" }}
                    debug={debug}
                  >
                    <View style={{ width: "50%", fontSize: 10 }}>
                      <Text> </Text>
                    </View>
                    <View style={{ width: "40%", fontSize: 10 }}>
                      <Text>{charge.type.vehiclechargetype}</Text>
                    </View>
                    <View style={{ width: "10%", fontSize: 10 }}>
                      <Text>${Number(charge.chargeamt).toFixed(2)}</Text>
                    </View>
                  </View>
                );
              })}
              {bulkInvoice && (
                <View
                  style={[styles.bold, { flexDirection: "row" }]}
                  debug={debug}
                >
                  <View style={{ width: "50%", fontSize: 10 }}>
                    <Text> </Text>
                  </View>
                  <View style={{ width: "40%", fontSize: 10 }}>
                    <Text>Total</Text>
                  </View>
                  <View style={{ width: "10%", fontSize: 10 }}>
                    <Text>${Number(invoice.totalcost).toFixed(2)}</Text>
                  </View>
                </View>
              )}
            </View>
          );
        })}
      </View>
    );
  } else {
    return (
      <View debug={debug}>
        {props.invoices.map((invoice, index) => {
          const vin = invoice.vehicle
            ? invoice.vehicle.vehiclevin
            : "No Vehicle";
          const vehDebtorName = invoice.vehicle ? (
            <View style={{ width: "30%", fontSize: 10 }}>
              <Text>{invoice.vehicle.debtorname}</Text>
            </View>
          ) : (
            <View style={{ width: "30%", fontSize: 10 }}>
              {invoice.charges.map((charge, index) => (
                <Text>{charge.type.vehiclechargetype}</Text>
              ))}
            </View>
          );
          return (
            <View key={index} debug={debug}>
              <View style={{ flexDirection: "row" }} debug={debug}>
                <View style={{ width: "30%", fontSize: 10 }}>
                  <Text>{vin}</Text>
                </View>
                {vehDebtorName}
                <View style={{ width: "10%", fontSize: 10 }}>
                  <Text>${invoice.fees_breakdown.state}</Text>
                </View>
                <View style={{ width: "10%", fontSize: 10 }}>
                  <Text>${invoice.fees_breakdown.dvs}</Text>
                </View>
                <View style={{ width: "10%", fontSize: 10 }}>
                  <Text>${invoice.fees_breakdown.other}</Text>
                </View>
                <View style={{ width: "10%", fontSize: 10 }}>
                  <Text>${Number(invoice.totalcost).toFixed(2)}</Text>
                </View>
              </View>
            </View>
          );
        })}
      </View>
    );
  }
}

function InvoiceDetailsFooter(props) {
  return (
    <View style={styles.text}>
      <View style={{ flexDirection: "row" }}>
        <View style={{ width: "60%" }}></View>
        <View style={{ width: "20%" }}>
          <Text style={styles.bold}>Total (US$)</Text>
        </View>
        <View style={{ width: "20%" }}>
          <Text style={{ textAlign: "right" }}>
            ${Number(props.total).toFixed(2)}
          </Text>
        </View>
      </View>
    </View>
  );
}

function FooterPageNumber() {
  return (
    <Text
      style={styles.pageNumber}
      render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
      fixed
    />
  );
}

function InvoiceLine() {
  return <View style={[styles.hr, { marginTop: 10, marginBottom: 10 }]} />;
}
