import React from "react";
import { Link as RouterLink } from "react-router-dom";
// import Link from "@material-ui/core/Link";
import { connect } from "react-redux";
import MomentUtils from "@date-io/moment";
import moment from "moment-timezone";
import { hashHistory } from "../_helpers";
import {
  MuiPickersUtilsProvider,
  DatePicker,
  // KeyboardTimePicker,
  // KeyboardDatePicker
} from "@material-ui/pickers";

import { withStyles } from "@material-ui/core/styles";
import withWidth from "@material-ui/core/withWidth";

import { PageWithAppDrawer2 } from "../_components/main/PageWithAppDrawer2";
import ResponsiveGrid from "../_components/generic/ResponsiveGrid";
import { utilityActions } from "../_actions";
import { utilityService } from "../_services";

import {
  Paper,
  Grid,
  Select,
  Checkbox,
  Divider,
  TextField,
  Button,
} from "@material-ui/core";

class PaperworkBatchesPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      reload: false,
      filters: [],
      availableFilters: [],
      orders: [],
      relations: [],
      data: [],
      columns: [],
      page: 1,
      perPage: 10,
      total: 0,
    };
  }

  async componentDidMount() {
    let columns = [];
    let utility = null;
    let availableFilters = [];
    let relations = [];
    let orders = [];

    utility = "paperwork_batches";

    orders.push({
      field: "printtitledocsid",
      direction: "desc",
    });

    columns = [
      {
        width: 2,
        title: "Batch #",
        field: "printtitledocsid",
        dataRender: (item) => (
          <RouterLink to={`/paperworkbatches/${item.printtitledocsid}`}>
            {item.printtitledocsid}
          </RouterLink>
        ),
      },
      {
        width: 2,
        title: "Date",
        field: "printtitledocsdate",
      },
      {
        width: 2,
        title: "User",
        field: "user.name",
      },
      {
        width: 1,
        title: "# Vehicles",
        field: "vehicles_count",
      },
    ];
    // var date = new Date();
    // var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    availableFilters.push({
      name: "Start Date",
      field: "printtitledocsdate",
      operator: ">",
      // value: moment(firstDay).format("YYYY-MM-DD"),
      // value: "2019-09-01",
      value: "",
      type: "date",
      default: "",
    });
    availableFilters.push({
      name: "End Date",
      field: "printtitledocsdate",
      operator: "<",
      value: "",
      type: "date",
      default: "",
    });

    this.setState(
      { relations, orders, utility, columns, availableFilters },
      () => this.getNewData()
    );
  }

  render() {
    const {
      loading,
      reload,
      data,
      columns,
      filters,
      availableFilters,
      page,
      perPage,
      total,
    } = this.state;

    const { classes } = this.props;

    if (reload === true) {
      this.setState({ reload: false }, () => {
        this.componentDidMount();
      });
    }

    return (
      <PageWithAppDrawer2 pageTitle={`Paperwork Batches`}>
        <Paper className={classes.paper}>
          <Button
            color="primary"
            variant="contained"
            onClick={this.handleAddItem}
          >
            Create Paperwork Batch
          </Button>
          <FiltersGrid
            classes={this.props.classes}
            availableFilters={availableFilters}
            filters={filters}
            onChange={this.handleFilterChange}
            clientList={this.props.utility.clients_active.items}
            typeList={this.props.utility.title_application_types.items}
            statusList={this.props.utility.title_statuses.items}
          />
          <br />
          <Divider />
          <ResponsiveGrid
            loading={loading}
            columns={columns}
            data={data}
            page={page - 1} // API has a 1 based page count, Material UI has a zero based page count
            perPage={perPage}
            total={total}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
            // showActions
            // onDownload={this.handleDownload}
          />
        </Paper>
      </PageWithAppDrawer2>
    );
  }

  handleAddItem = () => {
    const { webUser } = this.props;
    utilityService
      .addUtility("paperwork_batches", {
        printtitledocsdate: moment()
          .tz("America/Indiana/Indianapolis")
          .format("YYYY-MM-DD"),
        printtitledocspersonprinting: webUser.username,
      })
      .then((response) => {
        // console.log("response", response);
        hashHistory.push(`/paperworkbatches/${response.printtitledocsid}`);
      });
  };

  handleDownload = () => {
    console.log("handleDownload");
    this.getNewData(null, null, true);
  };

  handleChangePage = (event, newPage) => {
    // console.log("handleChangePage", newPage + 1);
    this.setState({ page: newPage + 1 }, () => this.getNewData());
  };

  handleChangeRowsPerPage = (event) => {
    const value = parseInt(event.target.value);
    this.setState(
      {
        perPage: value,
        page: 1,
      },
      () => this.getNewData()
    );
  };

  handleFilterChange = (newFilter) => {
    // console.log("handleFilterChange", newFilter);
    let availableFilters = this.state.availableFilters;
    if (newFilter.name) {
      availableFilters = this.state.availableFilters.map((filter) => {
        if (filter.name !== newFilter.name) return filter; // not the droid we're looking for, return unchanged
        filter.value = newFilter.value;
        return filter;
      });
    } else if (newFilter.field) {
      availableFilters = this.state.availableFilters.map((filter) => {
        if (filter.field !== newFilter.field) return filter; // not the droid we're looking for, return unchanged
        filter.value = newFilter.value;
        return filter;
      });
    }

    this.setState(
      {
        availableFilters,
        page: 1,
      },
      () => this.getNewData()
    );
  };

  getNewData = async (page = null, perPage = null, download = false) => {
    const { utility, relations, orders, availableFilters } = this.state;
    if (!perPage) perPage = this.state.perPage;
    if (!page) page = this.state.page;

    if (download !== false) download = true;
    if (!download) this.setState({ loading: true });

    // translate available filters into service filters
    const newFilters = availableFilters
      .filter((filter) => {
        if (["", null].includes(filter.value)) return false;
        if (
          ["isnull", "notnull"].includes(filter.operator) &&
          filter.value === false
        )
          return false;
        return true;
      })
      .map((filter) => {
        return {
          field: filter.field,
          operator: filter.operator,
          value: filter.value,
        };
      });

    let options = {
      filters: newFilters,
      relations: relations,
      orders: orders,
      perPage: perPage,
      page: page,
    };

    // if (download) {
    //   options.perPage = 1000;
    //   await utilityService
    //     .getUtility(utility, options)
    //     .then(values => {
    //       // console.log(values.data);
    //       return generateCSV(
    //         [
    //           "batch number",
    //           "invoice date",
    //           "client name",
    //           "user",
    //           "overnight charge",
    //           "overnight tracking #",
    //           "count",
    //           "total"
    //         ],
    //         [
    //           "invoiceid",
    //           "invoicedate",
    //           "client.clientname",
    //           "user.username",
    //           "overnightcharge",
    //           "overnighttrackingno",
    //           "invoice_count",
    //           "invoice_total"
    //         ],
    //         values.data
    //       );
    //     })
    //     .then(csv => {
    //       const blob = new Blob([csv], { type: "text/csv" });
    //       downloadBlob(blob, "paperworkbatches.csv");
    //     })
    //     .catch(console.error);
    //   return;
    // }

    await this.props.dispatch(
      utilityActions.getUtility(utility, options, () => {
        const { items, page, per_page: perPage, total } = this.props.utility[
          utility
        ];
        this.setState({
          loading: false,
          data: items,
          page,
          perPage,
          total,
        });
      })
    );
  };
}

function FiltersGrid(props) {
  // const classes = props.classes;
  const [availableFilters, setAvailableFilters] = React.useState([]);
  // const [clientList, setClientList] = React.useState([]);
  // const [typeList, setTypeList] = React.useState([]);
  // const [statusList, setStatusList] = React.useState([]);

  React.useEffect(() => {
    setAvailableFilters(props.availableFilters);
    // setClientList(props.clientList);
    // setTypeList(props.typeList);
    // setStatusList(props.statusList);
    // console.log("availablfilters", props.availableFilters);
  }, [props]);

  return (
    <Grid
      container
      display="flex"
      justify="center"
      alignContent="center"
      alignItems="center"
    >
      <Grid
        container
        style={{ flex: 1, fontSize: 12 }}
        alignItems="center"
        alignContent="center"
      >
        {availableFilters.map((filter, index) => {
          if (filter.type === "hidden") return <></>;
          // const isActive = filter.value === filter.default ? false : true;
          return (
            <React.Fragment key={index}>
              <Grid item xs={12} sm={4}>
                {filter.type === "choice" && (
                  <FiltersTypeSelect
                    filter={filter}
                    onChange={props.onChange}
                  />
                )}
                {filter.type === "text" && (
                  <FiltersTypeText filter={filter} onChange={props.onChange} />
                )}
                {filter.type === "checkbox" && (
                  <FiltersTypeCheckbox
                    filter={filter}
                    onChange={props.onChange}
                  />
                )}
                {filter.type === "date" && (
                  <FiltersTypeDate filter={filter} onChange={props.onChange} />
                )}
              </Grid>
            </React.Fragment>
          );
        })}
      </Grid>
    </Grid>
  );
}

function FiltersTypeDate(props) {
  // const classes = props.classes;
  const [filter, setFilter] = React.useState({});

  React.useEffect(() => {
    setFilter(props.filter);
  }, [props.filter]);

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <DatePicker
        style={{ fontSize: 12 }}
        variant="inline"
        name={filter.field}
        label={filter.name}
        format="YYYY-MM-DD"
        autoOk={true}
        value={filter.value || null}
        InputProps={{ style: { fontSize: 12 } }}
        InputLabelProps={{ style: { fontSize: 12 } }}
        // inputProps={{ style: { fontSize: 12 } }}
        // style={{ fontSize: 12 }}
        onChange={(date) =>
          props.onChange({
            name: filter.name,
            field: filter.field,
            operator: filter.operator,
            value: date.format("YYYY-MM-DD"),
          })
        }
      />
    </MuiPickersUtilsProvider>
  );
}

function FiltersTypeCheckbox(props) {
  // const classes = props.classes;
  const [filter, setFilter] = React.useState({});
  const [checked, setChecked] = React.useState(false);

  React.useEffect(() => {
    setFilter(props.filter);
    setChecked(props.filter.value === false ? false : true);
    console.log("checkedFilter", props.filter.value);
  }, [props.filter]);

  return (
    <Checkbox
      checked={checked}
      onChange={(event) =>
        props.onChange({
          name: filter.name,
          field: filter.field,
          operator: filter.operator,
          value: event.target.checked,
        })
      }
      value={filter.value}
    />
  );
}

function FiltersTypeText(props) {
  // const classes = props.classes;
  const [filter, setFilter] = React.useState({});
  // const [value, setValue] = React.useState("");
  const [text, setText] = React.useState("");

  React.useEffect(() => {
    setFilter(props.filter);
    setText(props.filter.value || "");
  }, [props.filter]);

  return (
    <TextField
      style={{ fontSize: 12 }}
      value={text || ""}
      placeholder={filter.name}
      label={filter.name}
      onChange={(event) => setText(event.target.value)}
      InputProps={{ style: { fontSize: 12 } }}
      InputLabelProps={{ style: { fontSize: 12 } }}
      onKeyPress={(event) => {
        if (event.key === "Enter") {
          props.onChange({
            field: filter.field,
            operator: "like",
            value: text,
          });
        }
      }}
    />
  );
}

function FiltersTypeSelect(props) {
  // const classes = props.classes;
  const [filter, setFilter] = React.useState({});
  const [choices, setChoices] = React.useState([]);

  React.useEffect(() => {
    setFilter(props.filter);
    setChoices(props.filter.choices || []);
  }, [props.filter]);

  return (
    <Select
      native
      style={{ fontSize: 12 }}
      value={filter.value || ""}
      onChange={(event, child) => {
        console.log("child", child.props);
        props.onChange({
          field: filter.field,
          operator: "eq",
          value: event.target.value,
        });
      }}
      displayEmpty
    >
      <option value={""}>Choose Option</option>

      {choices.map((choice, index) => (
        <option
          key={index}
          data-operator={choice.operator || null}
          value={choice.key}
        >
          {choice.value}
        </option>
      ))}
    </Select>
  );
}

const styles = (theme) => ({
  root: {
    display: "flex",
    fontSize: 12,
  },
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    width: "100%",
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    width: "100%",
  },
  fixedHeight: {
    height: 240,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },

  Button: {
    display: "block",
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
});

function mapStateToProps(state) {
  const { authentication, sites, utility } = state;
  const { user, webUser } = authentication;
  return {
    user,
    webUser,
    sites,
    utility,
  };
}

const connectedPaperworkBatchesPage = connect(mapStateToProps)(
  withWidth()(withStyles(styles)(PaperworkBatchesPage))
);
export { connectedPaperworkBatchesPage as PaperworkBatchesPage };
