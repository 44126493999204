import React, { useEffect } from "react";
import { Link as RouterLink, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { utilityActions } from "../_actions";

import { PageWithAppDrawer2 } from "../_components/main/PageWithAppDrawer2";
import ResponsiveGrid from "../_components/generic/ResponsiveGrid";

import { Paper, makeStyles, Typography } from "@material-ui/core";
import SignatureComponent from "../_components/generic/SignatureComponent";

export function RiUserTrainingSignPage(props) {
  const classes = useRiUserTrainingSignPageStyles();
  const { userId, webUserId, documentName } = useParams();

  return (
    <PageWithAppDrawer2 pageTitle={"NHTSA RI Training"}>
      <Paper className={classes.paper}>
        <Typography variant="h6">Acknowledge review of 2024 DVS RI User Run Book</Typography>
        <SignatureComponent
          userId={userId}
          saveUrl={`api/training/${webUserId}/sign/${documentName}?userId=${userId}`}
        />
      </Paper>
    </PageWithAppDrawer2>
  );
}

const useRiUserTrainingSignPageStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
}));

export { RiUserTrainingSignPage as default };
