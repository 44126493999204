import React from "react";
import { useDispatch, useSelector } from "react-redux";

import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import { utilityActions } from "../../_actions";
import { utilityService } from "../../_services";

import ModalGeneric from "../generic/ModalGeneric";

import { Select, FormControl, InputLabel, TextField } from "@material-ui/core";

export default function ModalVehicleAddCharge(props) {
  const dispatch = useDispatch();
  const webUser = useSelector((state) => state.authentication.webUser);
  // const charges = useSelector((state) => state.utility.vehicle_charges);
  const charge_types = useSelector(
    (state) => state.utility.vehicle_charge_types
  );

  const [open, setOpen] = React.useState(props.open || false);
  const [edit, setEdit] = React.useState(false);

  const [chargeDate, setChargeDate] = React.useState(null);
  const [chargeTypeId, setChargeTypeId] = React.useState(null);
  const [chargeAmount, setChargeAmount] = React.useState(null);
  const [chargeNote, setChargeNote] = React.useState(null);
  const [invoiceId, setInvoiceId] = React.useState(null);

  React.useEffect(() => {
    if (charge_types.items.length === 0) {
      dispatch(
        utilityActions.getUtility("vehicle_charge_types", {
          perPage: 1000,
          orders: [{ field: "vehiclechargetype", direction: "asc" }],
        })
      );
    }
  }, []);

  React.useEffect(() => {
    setOpen(props.open);
  }, [props.open]);

  React.useEffect(() => {
    if (props.item) {
      setChargeDate(props.item.chargedate);
      setChargeTypeId(props.item.chargetype);
      setChargeAmount(props.item.chargeamt);
      setChargeNote(props.item.chargenote);
    } else {
      setChargeDate(null);
      setChargeTypeId(null);
      setChargeAmount(null);
      setChargeNote(null);
    }
    if (props.invoiceId) {
      setInvoiceId(props.invoiceId);
    }
  }, [props.item, props.invoiceId]);

  React.useEffect(() => {
    setEdit(props.edit);
  }, [props.edit]);

  const handleClose = () => {
    setOpen(false);
    if (props.onClose) props.onClose();
  };

  const handleCancel = () => {
    setOpen(false);
    if (props.onCancel) props.onCancel();
  };

  const handleSave = () => {
    if (edit) {
      utilityService
        .updateUtility("vehicle_charges", props.item.id, {
          chargeamt: chargeAmount,
          chargedate: chargeDate,
          chargenote: chargeNote,
          chargetype: chargeTypeId,
          enteredby: webUser.userid,
          vehicleid: props.vehicleId,
        })
        .then((response) => {
          handleClose();
        });
    } else {
      utilityService
        .addUtility("vehicle_charges", {
          chargeamt: chargeAmount,
          chargedate: chargeDate,
          chargenote: chargeNote,
          chargetype: chargeTypeId,
          enteredby: webUser.userid,
          vehicleid: props.vehicleId,
          invoiceid: invoiceId || null,
        })
        .then((response) => {
          handleClose();
        });
    }
    return;
  };

  return (
    <ModalGeneric
      open={open}
      onClose={handleSave}
      onCancel={handleCancel}
      closeText={edit ? "Update" : "Add"}
      cancelText="Cancel"
      title={edit ? "Update Charge" : "Add Charge"}
    >
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          margin="normal"
          name="chargedate"
          label="Charge Date"
          format="MM/dd/yyyy"
          value={chargeDate}
          onChange={(date, value) => setChargeDate(value)}
        />
      </MuiPickersUtilsProvider>
      <FormControl fullWidth>
        <InputLabel shrink>Charge Type</InputLabel>
        <Select
          native
          style={{ fontSize: 12 }}
          value={chargeTypeId || ""}
          onChange={(event) => setChargeTypeId(event.target.value)}
          displayEmpty
        >
          <option value={""}>Charge Type</option>

          {charge_types.items.map((type, index) => (
            <option key={index} value={type.vehiclechargetypeid}>
              {type.vehiclechargetype}
            </option>
          ))}
        </Select>
      </FormControl>
      <TextField
        margin="dense"
        required
        name={"chargeamt"}
        label={"Charge Amount"}
        helperText="Charge Amount"
        type="text"
        value={chargeAmount || ""}
        onChange={(event) => setChargeAmount(event.target.value)}
        fullWidth
      />
      <TextField
        margin="dense"
        required
        name={"chargenote"}
        label={"Charge Note"}
        helperText="Charge Note"
        type="text"
        value={chargeNote || ""}
        onChange={(event) => setChargeNote(event.target.value)}
        fullWidth
      />
    </ModalGeneric>
  );
}
