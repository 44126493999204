import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { Typography, Button, TextField } from "@material-ui/core";
import { utilityActions } from "../../_actions";
import ResponsiveGrid from "../generic/ResponsiveGrid";
import ModalGeneric from "../generic/ModalGeneric";
import { isEmpty } from "lodash";

export default function ClientNotes(props) {
  const dispatch = useDispatch();
  const notes = useSelector((state) => state.utility.client_notes);
  const webUser = useSelector((state) => state.authentication.webUser);

  const [clientId, setClientId] = useState(props.clientId || null);
  const [openModal, setOpenModal] = useState(false);
  const [note, setNote] = useState("");

  useEffect(() => {
    if (clientId) {
      dispatch(
        utilityActions.getUtility("client_notes", {
          filters: [{ field: "clientid", operator: "eq", value: clientId }],
          orders: [
            {
              field: "contactdate",
              direction: "desc",
            },
          ],
        })
      );
    }
  }, [clientId]);

  const saveNote = () => {
    if (!isEmpty(note)) {
      dispatch(
        utilityActions.addUtility(
          "client_notes",
          {
            clientid: clientId,
            notes: note,
            userid: webUser.userid,
            roleid: webUser.roleid,
          },
          () => {
            dispatch(
              utilityActions.getUtility("client_notes", {
                filters: [
                  { field: "clientid", operator: "eq", value: clientId },
                ],
                orders: [
                  {
                    field: "contactdate",
                    direction: "desc",
                  },
                ],
              })
            );
          }
        )
      );
    }
    setNote("");
  };

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        size="small"
        onClick={() => setOpenModal(true)}
      >
        Add Note
      </Button>
      <br />
      <br />
      {notes.items.length === 0 ? (
        "No Notes"
      ) : (
        <ResponsiveGrid
          data={notes.items}
          columns={columns}
          usePagination={false}
        />
      )}
      <ModalGeneric
        open={openModal}
        title={"Add Note"}
        closeText={"Save Note"}
        onClose={() => {
          setOpenModal(false);
          saveNote();
        }}
        cancelText={"Cancel"}
        onCancel={() => {
          setOpenModal(false);
          setNote("");
        }}
      >
        <TextField
          id="outlined-multiline-static"
          label="New Note"
          multiline
          rows="4"
          value={note}
          variant="outlined"
          style={{ width: "100%" }}
          onChange={(event) => {
            setNote(event.target.value);
          }}
        />
      </ModalGeneric>
    </>
  );
}

const columns = [
  {
    width: 1,
    title: "ID",
    field: "id",
  },
  {
    width: 2,
    title: "Date",
    field: "contactdate",
  },
  {
    width: 7,
    title: "Note",
    field: "notes",
  },
  {
    width: 2,
    title: "User",
    field: "user.name",
  },
];
