import React, { useEffect, useState } from "react";

import TitleServiceDialog from "../_components/homepage/TitleServicesDialog";
import RegisteredImportationDialog from "../_components/homepage/RegisteredImportationDialog";

import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import {
  Button,
  Box,
  makeStyles,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { PageWithAppDrawer3 } from "../_components/main/PageWithAppDrawer3";

export function HomePage(props) {
  const classes = useHomePageStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [titleVariant, setTitleVariant] = useState("h3");
  const [titleServiceOpen, setTitleServiceOpen] = useState(false);
  const [registeredImportOpen, setRegisteredImportOpen] = useState(false);

  useEffect(() => {
    setTitleVariant(isMobile ? "h6" : "h3");
  }, [isMobile]);

  return (
    <PageWithAppDrawer3
      pageTitle={"Home (" + process.env.NODE_ENV + ")"}
      showSideBar={true}
      showHomeLinks={true}
    >
      <Container maxWidth="lg" className={classes.container}>
        <Grid container className={classes.titleGrid}>
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <Typography
                variant={titleVariant}
                color="primary"
                align="center"
                gutterBottom
              >
                Diversified Vehicle Services (DVS)
              </Typography>
              <Typography variant="subtitle1">
                Diversified Vehicle Services (<Dvs />) has been North America’s
                largest Registered Importers since the inception of the National
                Highway Transportation Safety Administration (NHTSA).
                <br />
                <br />
                After making significant investments in technology, logistics
                and financial services, <Dvs /> launched a new web platform
                making it North America’s first full service global trading
                platform. Utilizing <Dvs />
                ’s 22-year importing history with more than 650,000 cross-border
                transactions we developed a digitized inventory management
                system that will revolutionize global auto trade by providing a
                simple, seamless and safe point-to-point trading experience.
              </Typography>
            </Paper>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Paper className={classes.paper}>
              <Typography
                variant="h6"
                color="primary"
                align="center"
                gutterBottom
              >
                Nationwide Vehicle Titling Services
              </Typography>
              <Dvs /> was the first privately owned and operated BMV in the
              United States. <Dvs /> provides the fastest average turn-around
              time when processing vehicle titles from all 50 states and Canada.
              Our history as a partial service license branch of the Indiana
              Bureau of Motor Vehicles provides us with unique insight and
              expertise into the vehicle titling process. Our customers include
              Automobile Manufacturers, Finance Companies, Auctions, Trucking
              Companies, Banks, Insurance Companies, and Credit Unions.
              <br />
              <br />
              <Box align="center">
                <Button
                  color="primary"
                  variant="contained"
                  href="https://www.dvs-corp.com/nationwide-vehicle-titling-services/"
                  target="_blank"
                >
                  Learn More
                </Button>
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12} md={6}>
            <Paper className={classes.paper}>
              <Typography
                variant="h6"
                color="primary"
                align="center"
                gutterBottom
              >
                Registered Importation
              </Typography>
              <Dvs /> is the dominant importer of used Canadian vehicles into
              the United States with 5 modern regulation approved locations. The
              combination of our in-house title processing, remote filing
              capability for U.S. Customs and our transportation management
              system makes DVS the “One Stop Shop” for the importation process,
              and the only economically viable option for large car
              manufacturers that wish to import pre-owned cars from Canada to
              the U.S.
              <br />
              <br />
              <Box align="center">
                <Button
                  color="primary"
                  variant="contained"
                  href="https://www.dvs-corp.com/registered-importation/"
                  target="_blank"
                >
                  Learn More
                </Button>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Container>
      <TitleServiceDialog
        open={titleServiceOpen}
        onClose={() => {
          setTitleServiceOpen(false);
        }}
      />
      <RegisteredImportationDialog
        open={registeredImportOpen}
        onClose={() => {
          setRegisteredImportOpen(false);
        }}
      />
    </PageWithAppDrawer3>
  );
}

const useHomePageStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
  },
  titleGrid: {
    paddingBottom: theme.spacing(2),
  },
}));

function Dvs() {
  return (
    <Typography color="primary" display="inline" style={{ fontWeight: "bold" }}>
      DVS
    </Typography>
  );
}

export { HomePage as default };
