import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment-timezone";

import { BlobProvider } from "@react-pdf/renderer";
import { InvoiceDocument } from "../../_components/pdf/Invoice";

import { formatNumberToStringMoney } from "../../_helpers";
import { utilityActions } from "../../_actions";

import ResponsiveGrid from "../generic/ResponsiveGrid";
import ModalVehicleAddCharge from "../vehicle/ModalVehicleAddCharge";

import { Button } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";

export default function VehicleDetailCharges(props) {
  const dispatch = useDispatch();
  const charges = useSelector((state) => state.utility.vehicle_charges);
  const vehicle = useSelector((state) => state.utility.vehicles_item);

  const [open, setOpen] = useState(props.open || false);
  const [itemEdit, setItemEdit] = useState(null);
  const [edit, setEdit] = useState(false);
  const [download, setDownload] = useState(false);

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    dispatch(
      utilityActions.getUtility("vehicle_charges", {
        perPage: 1000,
        filters: [
          {
            field: "vehicleid",
            operator: "eq",
            value: props.vehicleId,
          },
          {
            field: "deleted",
            operator: "neq",
            value: "1",
          },
        ],
        orders: [{ field: "chargedate", direction: "desc" }],
      })
    );
  };

  const removeCharge = (id) => {
    const confirm = window.confirm(
      "Are you sure you want to delete this charge?"
    );
    if (!confirm) {
      return;
    }
    dispatch(
      utilityActions.deleteOneUtility("vehicle_charges", id, null, () => {
        getData();
      })
    );
  };

  const downloadInvoice = () => {};

  return (
    <>
      <Typography variant="h6" align="center">
        Charges{" "}
        <Button
          size="small"
          color="primary"
          variant="contained"
          onClick={() => setOpen(true)}
        >
          Add Charge
        </Button>{" "}
        <Button
          size="small"
          color="primary"
          variant="contained"
          onClick={getData}
        >
          Refresh Charges
        </Button>{" "}
        <Button
          size="small"
          color="primary"
          variant="contained"
          onClick={() => setDownload(true)}
        >
          {download ? (
            <BlobProvider
              document={
                <InvoiceDocument
                  client={vehicle.client}
                  invoices={[vehicle.invoice]}
                  singleInvoice={true}
                />
              }
            >
              {({ blob, url, loading, error }) => {
                if (!loading && url) {
                  // window.location.href = url;
                  // console.log("singleInvoice", singleInvoice);
                  // console.log("item", item);
                  window.open(url, "_blank");
                  setDownload(false);
                  return null;
                }
                return (
                  <>
                    <CircularProgress color="secondary" size={22} />
                    Print Single Invoice
                  </>
                );
              }}
            </BlobProvider>
          ) : (
            <>Print Single Invoice</>
          )}
        </Button>
      </Typography>
      <ResponsiveGrid
        loading={charges.loading}
        usePagination={false}
        columns={[
          {
            title: "Date",
            width: 2,
            field: "chargedate",
            dataRender: (item) => moment(item.chargedate).format("M/D/Y"),
          },
          {
            title: "Type",
            width: 3,
            field: "type.vehiclechargetype",
            dataRender: (item) => (
              <>
                {item.type.vehiclechargetype}
                <Button
                  size="small"
                  color="primary"
                  onClick={() => {
                    setEdit(true);
                    setItemEdit(item);
                    setOpen(true);
                  }}
                >
                  Edit
                </Button>
              </>
            ),
          },
          {
            title: "Amount",
            width: 3,
            field: "chargeamt",
            dataRender: (item) => formatNumberToStringMoney(item.chargeamt),
          },
          { title: "User", width: 3, field: "user.name" },
          {
            title: "",
            width: 1,
            field: null,
            dataRender: (item) => (
              <Button
                size="small"
                color="primary"
                onClick={() => {
                  console.log("item", item);
                  removeCharge(item.id);
                }}
              >
                Remove
              </Button>
            ),
          },
        ]}
        data={charges.items}
      />
      <ModalVehicleAddCharge
        edit={edit}
        item={itemEdit}
        open={open}
        onClose={() => {
          setOpen(false);
          setEdit(false);
          setItemEdit(null);
          getData();
        }}
        onCancel={() => {
          setOpen(false);
          setEdit(false);
          setItemEdit(null);
        }}
        vehicleId={props.vehicleId}
      />
    </>
  );
}
