import { userConstants } from "../_constants";
import { userService } from "../_services";
import { alertActions } from "./";
import { history, hashHistory } from "../_helpers";

export const userActions = {
  setMenu,
  setEnvironment,
  login,
  logout,
  register,
  getAll,
  delete: _delete,
};

function setMenu(menuOpen) {
  return (dispatch) => {
    dispatch(request(menuOpen));
  };

  function request(menuOpen) {
    return { type: userConstants.SETMENU_SUCCESS, menuOpen };
  }
}

function setEnvironment(environmentDVS) {
  localStorage.setItem("environmentDVS", JSON.stringify(environmentDVS));
  return { type: userConstants.SETENV, environmentDVS: environmentDVS };
}

function login(username, password, options = {}) {
  let { route } = options;
  if (!route) route = "/welcome";
  return (dispatch) => {
    dispatch(request({ username }));

    userService.login(username, password, options).then(
      (response) => {
        dispatch(success(response.user, response.webUser));
        hashHistory.push(route);
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request(user) {
    return { type: userConstants.LOGIN_REQUEST, user };
  }
  function success(user, webUser) {
    return { type: userConstants.LOGIN_SUCCESS, user, webUser };
  }
  function failure(error) {
    return { type: userConstants.LOGIN_FAILURE, error };
  }
}

function logout() {
  userService.logout();
  return { type: userConstants.LOGOUT };
}

function register(registrationData, callback = null) {
  return (dispatch) => {
    dispatch(request());
    userService.register(registrationData).then(
      (response) => {
        if (response.success) {
          dispatch(success());
          // hashHistory.push("/registration-requested");
          if (callback) callback(response);
        } else {
          dispatch(failure(response.message));
          dispatch(alertActions.error(response.message));
          if (callback) callback(response);
        }
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
        if (callback) callback(error);
      }
    );
  };

  function request() {
    return { type: userConstants.REGISTER_REQUEST };
  }
  function success(user) {
    return { type: userConstants.REGISTER_SUCCESS };
  }
  function failure(error) {
    return { type: userConstants.REGISTER_FAILURE };
  }
}

function getAll() {
  return (dispatch) => {
    dispatch(request());

    userService.getAll().then(
      (users) => dispatch(success(users)),
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: userConstants.GETALL_REQUEST };
  }
  function success(users) {
    return { type: userConstants.GETALL_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.GETALL_FAILURE, error };
  }
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
  return (dispatch) => {
    dispatch(request(id));

    userService.delete(id).then(
      (user) => dispatch(success(id)),
      (error) => dispatch(failure(id, error.toString()))
    );
  };

  function request(id) {
    return { type: userConstants.DELETE_REQUEST, id };
  }
  function success(id) {
    return { type: userConstants.DELETE_SUCCESS, id };
  }
  function failure(id, error) {
    return { type: userConstants.DELETE_FAILURE, id, error };
  }
}
