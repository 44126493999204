import React from "react";

import { formatNumberToStringMoney } from "../../_helpers";

import Typography from "@material-ui/core/Typography";
import ResponsiveGrid from "../generic/ResponsiveGrid";

export default function VehicleDetailPayments(props) {
  const payments = props.payments || [];

  return (
    <>
      <Typography variant="h6" align="center">
        Payments
      </Typography>
      <ResponsiveGrid
        usePagination={false}
        columns={[
          { title: "Date", width: 3, field: "paymentdate" },
          { title: "Type", width: 3, field: "invoiceid" },
          {
            title: "Amount",
            width: 3,
            field: "paymentamount",
            dataRender: item => formatNumberToStringMoney(item.paymentamount)
          },
          {
            title: "Balance",
            width: 3,
            field: "shortageamount",
            dataRender: item => formatNumberToStringMoney(item.shortageamount)
          }
        ]}
        data={payments}
      />
    </>
  );
}
