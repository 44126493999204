import React, { useState, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { PageWithAppDrawer2 } from "../_components/main/PageWithAppDrawer2";
import ResponsiveGrid from "../_components/generic/ResponsiveGrid";
import { utilityActions } from "../_actions";
import { hashHistory } from "../_helpers";
import { Paper, Button, makeStyles } from "@material-ui/core";
import { utilityService } from "../_services";

export function OneOffInvoicesPage() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const items = useSelector((state) => state.utility.one_off_invoices);

  const columns = [
    {
      width: 2,
      title: "Client Name",
      field: "client_name",
      dataRender: (item) => (
        <RouterLink to={`/accounting/one-off-invoices/${item.id}`}>
          {item.client_name}
        </RouterLink>
      ),
    },
    {
      width: 2,
      title: "VIN",
      field: "vin",
    },
    {
      width: 2,
      title: "Amount",
      field: "amount",
    },

    {
      width: 2,
      title: "Pickup Location",
      field: "pickup_location",
    },
    {
      width: 1,
      title: "Delivery Location",
      field: "delivery_location",
    },
  ];

  const [getData, setGetData] = useState(false);

  const [page, setPage] = useState(items.page ? Number(items.page) - 1 : 0);
  const [perPage, setPerPage] = useState(
    items.per_page ? Number(items.per_page) : 0
  );
  const [total, setTotal] = useState(items.total ? Number(items.total) : 0);

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
    setGetData(true);
  };
  const handleChangeRowsPerPage = (event) => {
    const value = parseInt(event.target.value);
    setPerPage(value);
    setGetData(true);
  };
  useEffect(() => {
    if (getData === true) {
      setGetData(false);
      getNewData();
    }
  }, [getData]);

  useEffect(() => {
    setTotal(items.total ? Number(items.total) : 0);
    setPage(items.page ? Number(items.page) : 0);
    setPerPage(items.per_page ? Number(items.per_page) : 10);
  }, [items]);

  useEffect(() => {
    if (items.items.length === 0) {
      getNewData();
    }
  }, []);

  const getNewData = () => {
    dispatch(
      utilityActions.getUtility("one_off_invoices", {
        page,
        perPage,
        orders: [{ field: "id", direction: "desc" }],
      })
    );
  };

  const handleAddItem = () => {
    utilityService
      .addUtility("one_off_invoices", {
        client_name: "NEW CLIENT",
      })
      .then((response) => {
        hashHistory.push(`/accounting/one-off-invoices/${response.id}`);
      });
  };

  return (
    <PageWithAppDrawer2 pageTitle={`One Off Invoice`}>
      <Paper className={classes.paper}>
        <div>
          <Button
            size="small"
            color="primary"
            variant="contained"
            onClick={handleAddItem}
          >
            Create New One Off Invoice
          </Button>
        </div>
        <ResponsiveGrid
          loading={items.loading}
          columns={columns}
          data={items.items}
          page={page - 1} // API has a 1 based page count, Material UI has a zero based page count
          perPage={perPage}
          total={total}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </PageWithAppDrawer2>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
}));
