import React from "react";
import { Link as RouterLink } from "react-router-dom";
// import Link from "@material-ui/core/Link";
import { connect, useSelector, useDispatch } from "react-redux";

import { withStyles } from "@material-ui/core/styles";
import withWidth from "@material-ui/core/withWidth";

// import { formatNumberToStringMoney } from "../_helpers";

import { PageWithAppDrawer2 } from "../_components/main/PageWithAppDrawer2";
import { utilityActions } from "../_actions";
import { Paper, Grid, Select, TextField, Button } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";

class AdminRackRateOverrideDetailsPage extends React.Component {
  constructor(props) {
    super(props);
    const { id: overrideId } = this.props.match ? this.props.match.params : {};
    this.state = {
      overrideId,
      reload: false,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { id: overrideId } = nextProps.match ? nextProps.match.params : {};
    if (overrideId !== prevState.overrideId) {
      return {
        ...prevState,
        overrideId,
        reload: true,
      };
    }
    return false;
  }

  async componentDidMount() {}

  render() {
    const { overrideId, reload } = this.state;
    const { classes } = this.props;

    if (reload === true) {
      this.setState({ reload: false }, () => {
        this.componentDidMount();
      });
      return <></>;
    }

    return (
      <PageWithAppDrawer2 pageTitle={`Override #${overrideId}`}>
        <RouterLink to="/admin/rackRates/overrides">All Overrides</RouterLink>
        <Paper className={classes.paper}>
          <OverrideDetails itemId={overrideId} />
        </Paper>
      </PageWithAppDrawer2>
    );
  }
}

function OverrideDetails(props) {
  const dispatch = useDispatch();
  const item = useSelector((state) => state.utility.rack_rate_overrides_item);
  const clients = useSelector((state) => state.utility.clients_active);
  const rack_rates = useSelector((state) => state.utility.rack_rates);
  const fields = [
    {
      width: 2,
      title: "Rack Rate",
      field: "rack_rate_id",
      type: "selectrackrate",
    },
    {
      width: 2,
      title: "Client",
      field: "clientid",
      type: "selectclient",
    },

    {
      width: 2,
      title: "Charge Amount",
      field: "charge_amount",
      type: "string",
    },
    {
      width: 2,
      title: "Every Vehicle",
      field: "every_vehicle",
      type: "selecteveryvehicle",
    },
  ];

  const id = props.itemId;

  // React.useEffect(() => {
  // }, [item]);

  React.useEffect(() => {
    if (clients.items.length === 0) {
      dispatch(
        utilityActions.getUtility("clients_active", {
          perPage: 1000,
          filters: [
            {
              field: "clientactive",
              operator: "eq",
              value: "2",
            },
          ],
          orders: [{ field: "clientname", direction: "asc" }],
        })
      );
    }
    if (rack_rates.items.length === 0) {
      dispatch(
        utilityActions.getUtility("rack_rates", {
          page: 1,
          perPage: 1000,
        })
      );
    }
  }, []);

  React.useEffect(() => {
    if (id) {
      if (!item) {
        getNewData();
      } else if (Number(item.id) !== Number(id)) {
        getNewData();
      }
    }
  }, [id]);

  const getNewData = () => {
    dispatch(utilityActions.getOneUtility("rack_rate_overrides", id, []));
  };

  const updateLocal = (event) => {
    const { name, value } = event.target;
    dispatch(
      utilityActions.updateOneUtilityLocal("rack_rate_overrides", {
        [name]: value,
      })
    );
  };

  const handleSave = () => {
    dispatch(
      utilityActions.updateUtility("rack_rate_overrides", item.id, item, () => {
        getNewData();
      })
    );
  };

  return (
    <>
      {item && item.id ? (
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Button variant="contained" color="primary" onClick={handleSave}>
              Save Changes
            </Button>
          </Grid>
          {fields.map((field, index) => (
            <React.Fragment key={index}>
              <Grid item xs={12} sm={4}>
                {field.title}
              </Grid>
              <Grid item xs={12} sm={8}>
                {field.type === "string" && (
                  <TextField
                    name={field.field}
                    placeholder={field.title}
                    value={item[field.field] || ""}
                    onChange={updateLocal}
                  />
                )}
                {field.type === "selectclient" && (
                  <Select
                    native
                    name={field.field}
                    value={item[field.field] ? Number(item[field.field]) : 1}
                    onChange={updateLocal}
                    style={{ fontSize: 12 }}
                    displayEmpty
                  >
                    <option value="">Client</option>
                    {clients &&
                      clients.items.map((client, index) => (
                        <option key={index} value={client.id}>
                          {client.clientname}
                        </option>
                      ))}
                  </Select>
                )}
                {field.type === "selectrackrate" && (
                  <Select
                    native
                    name={field.field}
                    value={item[field.field] || 1}
                    onChange={updateLocal}
                    style={{ fontSize: 12 }}
                    displayEmpty
                  >
                    <option value="">Application Type</option>
                    {rack_rates &&
                      rack_rates.items.map((rack_rate, index) => (
                        <option key={index} value={rack_rate.id}>
                          {rack_rate.name}
                        </option>
                      ))}
                  </Select>
                )}
                {field.type === "selecteveryvehicle" && (
                  <Select
                    native
                    name={field.field}
                    value={
                      item[field.field] === true ||
                      item[field.field] === 1 ||
                      item[field.field] === "1"
                        ? 1
                        : 0
                    }
                    onChange={updateLocal}
                    style={{ fontSize: 12 }}
                  >
                    <option value={0}>Not every vehicle</option>
                    <option value={1}>Every Vehicle</option>
                  </Select>
                )}
              </Grid>
            </React.Fragment>
          ))}
        </Grid>
      ) : (
        <CircularProgress />
      )}
    </>
  );
}

const styles = (theme) => ({
  root: {
    display: "flex",
    fontSize: 12,
  },
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    width: "100%",
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    width: "100%",
  },
  fixedHeight: {
    height: 240,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },

  Button: {
    display: "block",
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
});

function mapStateToProps(state) {
  const { authentication, utility, batch } = state;
  const { user } = authentication;
  return {
    user,
    batch,
    utility,
  };
}

const connectedAdminRackRateOverrideDetailsPage = connect(mapStateToProps)(
  withWidth()(withStyles(styles)(AdminRackRateOverrideDetailsPage))
);
export { connectedAdminRackRateOverrideDetailsPage as AdminRackRateOverrideDetailsPage };
