import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";

import { Box } from "@material-ui/core";

export default function TitleServiceDialog(props) {
  const [open, setOpen] = React.useState(props.open || false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));

  // const handleClickOpen = () => {
  //   setOpen(true);
  // };

  const handleClose = () => {
    setOpen(false);
    props.onClose();
  };

  React.useEffect(() => {
    setOpen(props.open);
  }, [props.open]);

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogActions>
        <Button autoFocus onClick={handleClose} color="primary">
          Close
        </Button>
      </DialogActions>
      <DialogTitle id="responsive-dialog-title">
        {"Nationwide Vehicle Titling Services"}
      </DialogTitle>

      <DialogContent>
        <DialogContentText>
          DVS provides the fastest average turn-around time (approximately 5
          days) when processing vehicle titles from all 50 states and Canada.
          Our history as a partial service license branch of the Indiana Bureau
          of Motor Vehicles provides us with unique insight and expertise into
          the vehicle titling process. Our customers include Automobile
          Manufacturers, Finance Companies, Banks, Insurance Companies, Credit
          Unions and Auctions.
          <br />
          <br />
          Our customers benefit from our 10-year history of focusing on the
          speed, accuracy and standardization of processing to generate millions
          of dollars in annualized savings. The savings is a combination of
          cost-effective processing and the lowest average turn time of
          processing titles, regardless of the state of origin.
          <br />
          <br />
          We have a proprietary web-based system designed for ease of use and
          real-time inventory management. This is a free service available to
          our customers and allows them 24-hour access and updates as to the
          progress of the title application.
          <br />
          <br />
          Our title service expertise extends beyond traditional automotive
          titling. Our national base of customers currently has DVS processing
          titles for numerous types of vehicles, including:
          <br />
          <Box style={{ paddingLeft: 20 }}>
            Automobiles <br />
            Travel Trailers <br />
            5th-Wheels <br />
            Motor Homes <br />
            Motorcycles <br />
            ATVs <br />
            Watercraft <br />
            Semi-Trailers <br />
            Commercial Vehicles <br />
            Salvage Vehicle <br />
            Out-of-Country
          </Box>
          <br />
          DVS processes a full range of titles, including:
          <Box style={{ paddingLeft: 20 }}>
            Repossession Titles <br />
            Salvage Titles <br />
            Flood Salvage Titles <br />
            Redemption Titles <br />
            Duplicate Titles <br />
            Watercraft Titles <br />
            Title Transfers <br />
            Accelerated Titles (48 hour turn-time) <br />
            Out-of-Country <br />
            Title Corrections <br />
            Certificate of Origin / MSO
          </Box>
          <br />
          DVS can tailor our services to meet the customer’s needs:
          <Box style={{ paddingLeft: 20 }}>
            Application processing <br />
            Documentation processing <br />
            Vehicle history reports and corrections <br />
            Vehicle inquiry reports <br />
            Accelerated title request (48 hour turn-time) <br />
            Direct to customer delivery <br />
            Direct to auction delivery <br />
            Direct to third-party delivery <br />
          </Box>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
