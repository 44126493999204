import { createMuiTheme } from "@material-ui/core/styles";
import { getCustomer } from "../_helpers";

const customer = getCustomer();

let customerTheme = "";

if (customer === "adesa") {
  customerTheme = {
    typography: {
      useNextVariants: true,
    },
    palette: {
      primary: { main: "#008c99" },
      secondary: { main: "#616161" },
    },
    shape: {
      borderRadius: 8,
    },
    overrides: {
      MuiDrawer: {
        paper: {
          minWidth: 256,
        },
        paperAnchorDockedLeft: {
          borderRight: "none",
        },
      },
    },
  };
} else {
  customerTheme = {
    typography: {
      useNextVariants: true,
    },
    palette: {
      primary: { main: "#589fcb" },
      secondary: { main: "#616161" },
    },
    shape: {
      borderRadius: 8,
    },
    overrides: {
      MuiDrawer: {
        paper: {
          minWidth: 256,
        },
        paperAnchorDockedLeft: {
          borderRight: "none",
        },
      },
    },
  };
}

const theme = createMuiTheme(customerTheme);

export default theme;
