import { Button, Grid, makeStyles, Paper, Typography } from "@material-ui/core";
import React from "react";

import { PageWithAppDrawer2 } from "../_components/main/PageWithAppDrawer2";
import appStoreBadge from "../_assets/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg";

export default function HudsonPaperworkPage(props) {
  const classes = useStyles();

  return (
    <PageWithAppDrawer2 pageTitle="Mobile App Download">
      <Paper className={classes.paper}>
        <Typography variant="h6" color="primary">
          Mobile App Download
        </Typography>
        <Grid container spacing={2} style={{ marginTop: 10 }}>
          <Grid item xs={12}>
            <Typography variant="h6">Apple iPhone</Typography>
            <a href="https://apps.apple.com/us/app/dvs-ri/id1539317802">
              <img src={appStoreBadge} height="48" alt="store badge" />{" "}
            </a>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6">Android APK (Not Google Play Store right now)</Typography>
            <Button size="medium" color="primary" variant="contained" href="https://api.dvs-carzip.com/dvs_android.apk">
                Download Android APK from DVS
            </Button>

            <Typography variant="body1">This Android version IS NOT on the Google Play store (long story)</Typography>
            <Typography variant="body1">If you have the Play Store version installed please uninstall it before installing this version</Typography>
            <img src="https://api.dvs-carzip.com/Android1.png" width="200" alt="android screenshot" />
            <img src="https://api.dvs-carzip.com/Android2.png" width="200" alt="android screenshot" />
            <img src="https://api.dvs-carzip.com/Android3.png" width="200" alt="android screenshot" />
            <img src="https://api.dvs-carzip.com/Android4.png" width="200" alt="android screenshot" />
            
          </Grid>
        </Grid>
      </Paper>
    </PageWithAppDrawer2>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
}));
