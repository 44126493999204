import React, { useEffect } from "react";
import { Link as RouterLink, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { PageWithAppDrawer2 } from "../_components/main/PageWithAppDrawer2";
import { utilityActions } from "../_actions";
import {
  Paper,
  Grid,
  Select,
  Button,
  Backdrop,
  Snackbar,
  makeStyles,
  Divider,
  Typography,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import CircularProgress from "@material-ui/core/CircularProgress";
import { userService } from "../_services";

export function SvetnaUserDetailsPage() {
  const classes = useAdminUserPageStyles();
  const { id: userId } = useParams();

  return (
    <PageWithAppDrawer2 pageTitle={`User #${userId}`}>
      <RouterLink to="/svetna/users">All Svetna Users</RouterLink>
      <Paper className={classes.paper}>
        <UserDetails itemId={userId} />
      </Paper>
    </PageWithAppDrawer2>
  );
}

const useAdminUserPageStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    width: "100%",
  },
}));

function UserDetails(props) {
  const dispatch = useDispatch();
  const item = useSelector((state) => state.utility.web_users_item);
  const hasSvetnaRole = useSelector(
    (state) => state.utility.web_users_item?.cloud_user?.has_svetna_role
  );
  const hasSvetnaAdminRole = useSelector(
    (state) => state.utility.web_users_item?.cloud_user?.has_svetna_admin_role
  );
  const hasSvetnaBuyerRole = useSelector(
    (state) => state.utility.web_users_item?.cloud_user?.has_svetna_buyer_role
  );
  const [loading, setLoading] = React.useState(false);
  const [snackbar, setSnackbar] = React.useState({
    open: false,
    severity: "info", //error, warning, info, success
    message: "default message",
    duration: 6000,
  });

  const id = props.itemId;

  useEffect(() => {}, []);

  useEffect(() => {
    if (id) {
      if (!item) {
        getNewData();
      } else if (Number(item.id) != -Number(id)) {
        getNewData();
      }
    }
  }, [id]);

  const getNewData = () => {
    dispatch(
      utilityActions.getOneUtility("web_users", id, [], () => {
        setLoading(false);
      })
    );
  };

  const updateLocal = (event) => {
    let { name, value } = event.target;

    if (name === "has_svetna_role") {
      name = "cloud_user";
      value = {
        ...item.cloud_user,
        has_svetna_role: value === "1" ? true : false,
      };
    }
    if (name === "has_svetna_admin_role") {
      name = "cloud_user";
      value = {
        ...item.cloud_user,
        has_svetna_admin_role: value === "1" ? true : false,
      };
    }
    if (name === "has_svetna_buyer_role") {
      name = "cloud_user";
      value = {
        ...item.cloud_user,
        has_svetna_buyer_role: value === "1" ? true : false,
      };
    }

    dispatch(
      utilityActions.updateOneUtilityLocal("web_users", { [name]: value })
    );
    if (name === "clientid") {
      dispatch(
        utilityActions.updateOneUtilityLocal("web_users", {
          client_id: value,
        })
      );
    }
  };

  const handleSave = () => {
    delete item.loading;
    setLoading(true);
    dispatch(
      utilityActions.updateUtility("web_users", item.id, item, () => {
        getNewData();
        setSnackbar({
          open: true,
          severity: "success",
          message: "User Updated",
          duration: 6000,
        });
      })
    );
  };

  const handleResetPassword = () => {
    const email = prompt("Send PW reset to email", item.useremail);
    if (email){
      userService.passwordReset(id, email).then((response) => {
        console.log(response);
      });
    }
  };

  return (
    <>
      <Backdrop open={loading} style={{ zIndex: 100 }} />
      <Snackbar
        color={snackbar.severity}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={snackbar.open}
        autoHideDuration={snackbar.duration}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <MuiAlert severity={snackbar.severity} variant="filled">
          {snackbar.message}
        </MuiAlert>
      </Snackbar>
      {item && item.id ? (
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSave}
              disabled={loading}
            >
              Save Changes
            </Button>{" "}
            <Button
              variant="contained"
              color="primary"
              onClick={handleResetPassword}
              disabled={loading}
            >
              Reset Password
            </Button>
          </Grid>

          <Grid item xs={12} sm={4}>
            First Name
          </Grid>
          <Grid item xs={12} sm={8}>
            <input
              type="text"
              name="firstname"
              placeholder="First Name"
              value={item.firstname || ""}
              onChange={updateLocal}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            Last Name
          </Grid>
          <Grid item xs={12} sm={8}>
            <input
              type="text"
              name="lastname"
              placeholder="Last Name"
              value={item.lastname || ""}
              onChange={updateLocal}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            Username
          </Grid>
          <Grid item xs={12} sm={8}>
            <input
              type="text"
              name="username"
              placeholder="Username"
              value={item.username || ""}
              onChange={updateLocal}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            Svetna Access
          </Grid>
          <Grid item xs={12} sm={8}>
            <Select
              native
              name="has_svetna_role"
              value={hasSvetnaRole === true ? "1" : "0"}
              onChange={updateLocal}
              style={{ fontSize: 12 }}
              displayEmpty
            >
              <option value="0">No</option>
              <option value="1">Yes</option>
            </Select>
            <br/>
            <Typography variant="body2" style={{fontStyle: "italic"}}>User will have the Svetna Menu, Dashboard, Scan, Dealers, and Reports</Typography>
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>

          <Grid item xs={12} sm={4}>
            Svetna Buyer
          </Grid>
          <Grid item xs={12} sm={8}>
            <Select
              native
              name="has_svetna_buyer_role"
              value={hasSvetnaBuyerRole === true ? "1" : "0"}
              onChange={updateLocal}
              style={{ fontSize: 12 }}
              displayEmpty
            >
              <option value="0">No</option>
              <option value="1">Yes</option>
            </Select><br/>
            <Typography variant="body2" style={{fontStyle: "italic"}}>User will have only the Svetna Menu, will not have DVS menu (loads, transporters, etc)</Typography>
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>

          <Grid item xs={12} sm={4}>
            Svetna Admin
          </Grid>
          <Grid item xs={12} sm={8}>
            <Select
              native
              name="has_svetna_admin_role"
              value={hasSvetnaAdminRole === true ? "1" : "0"}
              onChange={updateLocal}
              style={{ fontSize: 12 }}
              displayEmpty
            >
              <option value="0">No</option>
              <option value="1">Yes</option>
            </Select>
            <br/>
            <Typography variant="body2" style={{fontStyle: "italic"}}>Adds the ability to admin users</Typography>
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>
        </Grid>
      ) : (
        <CircularProgress />
      )}
    </>
  );
}

export { SvetnaUserDetailsPage as default };
