import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";

import { withStyles } from "@material-ui/core/styles";
import withWidth from "@material-ui/core/withWidth";
import { getEnvironmentURL } from "../_helpers";
import UserSignatureCanvas from "../_components/user/UserSignatureCanvas";

import { PageWithAppDrawer2 } from "../_components/main/PageWithAppDrawer2";
import { Paper, CircularProgress, Typography } from "@material-ui/core";
import { utilityService } from "../_services";

class UserSignaturePage extends React.Component {
  constructor(props) {
    super(props);
    const { type: mobileType } = this.props.match
      ? this.props.match.params
      : {};
    this.state = {
      mobileType,
      reload: false,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { type: mobileType } = nextProps.match ? nextProps.match.params : {};
    if (mobileType !== prevState.mobileType) {
      return {
        ...prevState,
        mobileType,
        reload: true,
      };
    }
    return false;
  }

  async componentDidMount() {}

  render() {
    const { classes, webUser } = this.props;

    return (
      <PageWithAppDrawer2 pageTitle={`Update Signature`}>
        <Paper className={classes.paper}>
          <UserSignature userId={webUser.userid} />
        </Paper>
      </PageWithAppDrawer2>
    );
  }
}

function UserSignature(props) {
  const dispatch = useDispatch();
  const [saving, setSaving] = useState(false);
  const [preview, setPreview] = useState(null);
  const [time, setTime] = useState(Date.now());

  useEffect(() => {}, []);

  const save = (data) => {
    setSaving(true);
    setPreview(null);
    utilityService
      .getEndpoint("api/user/setSignature", "POST", {
        body: {
          photo: data,
        },
      })
      .then(() => {
        setSaving(false);
        setTime(Date.now());
      });
  };

  return (
    <>
      <Typography variant="h4">Best updated using a phone</Typography>
      <UserSignatureCanvas
        widthRatio={3}
        onSave={(data) => {
          save(data.dataURL);
        }}
        onPreview={(data) => {
          setPreview(data.dataURL);
        }}
      />
      {saving ? (
        <CircularProgress />
      ) : (
        <>
          {preview && (
            <>
              Preview of new Signature:
              <br />
              <img src={preview} />
            </>
          )}
          Current Signature:
          <br />
          <img
            style={{ width: 200 }}
            src={`${getEnvironmentURL()}/api/user/${
              props.userId
            }/mySignature?${time}`}
            onError={({ currentTarget}) => {
              currentTarget.error = null;
              currentTarget.src = "https://via.placeholder.com/350x150?text=No%20Signature"
            }}
          />
        </>
      )}
    </>
  );
}

const styles = (theme) => ({
  root: {
    display: "flex",
    fontSize: 12,
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    width: "100%",
    backgroundColor: "#ccc",
  },
});

function mapStateToProps(state) {
  const { authentication } = state;
  const { user, webUser } = authentication;
  return {
    user,
    webUser,
  };
}

const connectedUserSignaturePage = connect(mapStateToProps)(
  withWidth()(withStyles(styles)(UserSignaturePage))
);
export { connectedUserSignaturePage as UserSignaturePage };
