import { utilityConstants } from "../_constants";
import { utilityService } from "../_services";
import { alertActions } from ".";

export const utilityActions = {
  getUtility,
  clearUtility,
  getOneUtility,
  addUtility,
  deleteOneUtility,
  clearOneUtility,
  updateUtility,
  updateOneUtilityLocal,
  updateOneUtilityLocalTires,
  getOpenNotes,
};

function getUtility(utilityName, options, callback) {
  return (dispatch) => {
    dispatch(request(utilityName));

    // console.log("getUtility action", {options})

    utilityService
      .getUtility(utilityName, options)
      .then(
        (values) => {
          dispatch(success(values, utilityName));
          return values;
        },
        (error) => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      )
      .then((values) => {
        if (callback) {
          callback(values);
        }
      });
  };

  function request() {
    return { type: utilityConstants.UTILITY_GET_REQUEST, utilityName };
  }
  function success(values) {
    return { type: utilityConstants.UTILITY_GET_SUCCESS, values, utilityName };
  }
  function failure(error) {
    return { type: utilityConstants.UTILITY_GET_FAILURE, error };
  }
}

function clearUtility(utilityName) {
  return (dispatch) => {
    dispatch(request(utilityName));
  };

  function request() {
    return { type: utilityConstants.UTILITY_CLEAR_REQUEST, utilityName };
  }
}

function getOneUtility(utilityName, id, options, callback) {
  return (dispatch) => {
    dispatch(request(utilityName));

    utilityService
      .getOneUtility(utilityName, id, options)
      .then(
        (values) => {
          dispatch(success(values, utilityName));
          return values;
        },
        (error) => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      )
      .then((values) => {
        if (callback) {
          callback(values);
        }
      });
  };

  function request() {
    return { type: utilityConstants.UTILITY_GET_ONE_REQUEST, utilityName };
  }
  function success(values) {
    return {
      type: utilityConstants.UTILITY_GET_ONE_SUCCESS,
      values,
      utilityName,
    };
  }
  function failure(error) {
    return { type: utilityConstants.UTILITY_GET_ONE_FAILURE, error };
  }
}

function deleteOneUtility(utilityName, id, options = [], callback) {
  // console.log("callback", callback);
  return (dispatch) => {
    dispatch(request(utilityName));

    utilityService
      .deleteOneUtility(utilityName, id, options)
      .then(
        (values) => {
          dispatch(success(values, utilityName));
        },
        (error) => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      )
      .then(() => {
        if (callback) {
          console.log("deleteOneUtility callback");
          callback();
        }
      });
  };

  function request() {
    return { type: utilityConstants.UTILITY_DELETE_ONE_REQUEST, utilityName };
  }
  function success(values) {
    return {
      type: utilityConstants.UTILITY_DELETE_ONE_SUCCESS,
      values,
      utilityName,
    };
  }
  function failure(error) {
    return { type: utilityConstants.UTILITY_DELETE_ONE_FAILURE, error };
  }
}

function clearOneUtility(utilityName) {
  return (dispatch) => {
    dispatch(request(utilityName));
  };

  function request() {
    return { type: utilityConstants.UTILITY_CLEAR_ONE_REQUEST, utilityName };
  }
}

function addUtility(utilityName, data, callback) {
  return (dispatch) => {
    dispatch(request(utilityName));

    utilityService
      .addUtility(utilityName, data)
      .then(
        (values) => {
          dispatch(success(values, utilityName));
        },
        (error) => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      )
      .then(() => {
        if (callback) {
          callback();
        }
      });
  };

  function request() {
    return { type: utilityConstants.UTILITY_ADD_ONE_REQUEST, utilityName };
  }
  function success(values) {
    return {
      type: utilityConstants.UTILITY_ADD_ONE_SUCCESS,
      values,
      utilityName,
    };
  }
  function failure(error) {
    return { type: utilityConstants.UTILITY_ADD_ONE_FAILURE, error };
  }
}

function getOpenNotes() {
  const notesFilters = [
    { field: "complete", operator: "eq", value: 0 },
    { field: "roleid", operator: "eq", value: 1 },
  ];

  return (dispatch) => {
    dispatch(
      utilityActions.getUtility(
        "vehicle_notes_open",
        notesFilters,
        null,
        null,
        100,
        1
      )
    );
  };
}

function updateOneUtilityLocal(utilityName, data, callback) {
  return (dispatch) => {
    dispatch(update(utilityName, data));
    if (callback) {
      callback(data);
    }
  };

  function update(utilityName, data) {
    return { type: utilityConstants.UTILITY_UPDATE_LOCAL, utilityName, data };
  }
}

function updateOneUtilityLocalTires(utilityName, data, callback) {
  return (dispatch) => {
    dispatch(update(utilityName, data));
    if (callback) {
      callback(data);
    }
  };

  function update(utilityName, data) {
    return { type: utilityConstants.UTILITY_UPDATE_LOCAL_TIRES, utilityName, data };
  }
}

function updateUtility(utilityName, id, item, callback) {
  return (dispatch) => {
    dispatch(request(utilityName));

    utilityService
      .updateUtility(utilityName, id, item)
      .then(
        (values) => {
          dispatch(success(utilityName, id, values));
          return values;
        },
        (error) => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
          return error;
        }
      )
      .then((response) => {
        if (callback) {
          callback(response);
        }
      })
      .catch((error) => {
        return error;
      });
  };

  function request(utilityName) {
    return { type: utilityConstants.UTILITY_UPDATE_REQUEST, utilityName };
  }
  function success(utilityName, id, item) {
    return {
      type: utilityConstants.UTILITY_UPDATE_SUCCESS,
      utilityName,
      id,
      item,
    };
  }
  function failure(error) {
    return { type: utilityConstants.UTILITY_UPDATE_FAILURE, error };
  }
}
