export const batchConstants = {
  BATCH_ADD_REQUEST: "BATCH_ADD_REQUEST",
  BATCH_ADD_SUCCESS: "BATCH_ADD_SUCCESS",
  BATCH_ADD_FAILURE: "BATCH_ADD_FAILURE",

  BATCH_GET_REQUEST: "BATCH_GET_REQUEST",
  BATCH_GET_SUCCESS: "BATCH_GET_SUCCESS",
  BATCH_GET_FAILURE: "BATCH_GET_FAILURE",

  BATCH_UPDATE_REQUEST: "BATCH_UPDATE_REQUEST",
  BATCH_UPDATE_SUCCESS: "BATCH_UPDATE_SUCCESS",
  BATCH_UPDATE_FAILURE: "BATCH_UPDATE_FAILURE",

  BATCH_SET_LOCAL: "BATCH_SET_LOCAL",
  BATCH_CLEAR: "BATCH_CLEAR"
};
