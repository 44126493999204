import React from "react";
import { Link as RouterLink } from "react-router-dom";
// import Link from "@material-ui/core/Link";
import { connect } from "react-redux";
import moment from "moment-timezone";
import { hashHistory } from "../_helpers";

import { withStyles } from "@material-ui/core/styles";
import withWidth from "@material-ui/core/withWidth";

import {
  formatNumberToStringMoney,
  generateCSV,
  downloadBlob,
} from "../_helpers";

import { PageWithAppDrawer2 } from "../_components/main/PageWithAppDrawer2";
import ResponsiveGrid from "../_components/generic/ResponsiveGrid";
import { utilityActions } from "../_actions";
import { utilityService } from "../_services";
import {
  Paper,
  Checkbox,
  Divider,
  Button,
  CircularProgress,
  Backdrop,
} from "@material-ui/core";

import MuiAlert from "@material-ui/lab/Alert";
import { Link, Snackbar } from "@material-ui/core";
import ModalGeneric from "../_components/generic/ModalGeneric";
import MasterBatchManage from "../_components/invoiceBatches/MasterBatchManage";
import FiltersGrid from "../_components/generic/FiltersGrid";

class InvoiceBatchesPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      reload: false,
      filters: [],
      availableFilters: [],
      orders: [],
      relations: [],
      data: [],
      columns: [],
      page: 1,
      perPage: 5,
      total: 0,
      checkedList: [],
      snackbar: {
        open: false,
        severity: "info", //error, warning, info, success
        message: "default message",
        duration: 6000,
      },
      openBackdrop: false,
      mibModalOpen: false,
      mibId: null,
      shouldRefresh: false,
    };
  }

  async componentDidMount() {
    const { items: clients } = this.props.utility.clients_active;
    if (clients.length === 0) {
      this.props.dispatch(
        utilityActions.getUtility("clients_active", {
          perPage: 1000,
          filters: [
            {
              field: "clientactive",
              operator: "eq",
              value: "2",
            },
          ],
          orders: [{ field: "clientname", direction: "asc" }],
        })
      );
    }
    let columns = [];
    let utility = null;
    let availableFilters = [];
    let relations = [];
    let orders = [];

    utility = "invoice_batches";

    orders.push({
      field: "invoiceid",
      direction: "desc",
    });

    columns = [
      {
        width: 1,
        title: "Batch #",
        field: "invoiceid",
        dataRender: (item) => (
          <>
            <RouterLink to={`/invoicebatches/${item.invoiceid}`}>
              {item.invoiceid}
            </RouterLink>
            <br />
            MIB:
            {item.master_invoice_batch ? (
              <Link
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  this.setState({
                    mibModalOpen: true,
                    mibId: item.master_invoice_batch.id,
                  });
                }}
                style={{ textDecoration: "underline" }}
              >
                {item.master_invoice_batch.id}
              </Link>
            ) : (
              <Checkbox onChange={(e) => this.checkMasterInvoice(item.id, e)} />
            )}
          </>
        ),
      },
      {
        width: 1,
        title: "Date",
        field: "invoicedate",
      },
      {
        width: 3,
        title: "Client Name",
        field: "client.clientname",
        dataRender: (item) => {
          return (
            <>
              {item.client ? item.client.clientname : "UKNOWN CLIENT"}
              <br />
              {item.emailed && (
                <>
                  <b>Emailed:</b>{" "}
                  {moment(item.emailed).format("M/D/Y, h:mm:ss a")}
                </>
              )}
            </>
          );
        },
      },
      {
        width: 2,
        title: "User",
        field: "user.name",
      },
      {
        width: 1,
        title: "Overnight Charge",
        field: "overnightcharge",
        dataRender: (item) => {
          if (item.overnightcharge === null) return "";
          return formatNumberToStringMoney(item.overnightcharge);
        },
      },
      { width: 2, title: "Overnight Tracking #", field: "overnighttrackingno" },
      { width: 1, title: "Count", field: "invoice_count" },
      {
        width: 1,
        title: "Total",
        field: "invoice_total",
        dataRender: (item) => formatNumberToStringMoney(item.invoice_total),
      },
    ];
    availableFilters.push({
      name: "Client",
      field: "client.clientname",
      operator: "like",
      value: "",
      type: "text",
      default: "",
    });
    availableFilters.push({
      name: "MIB #",
      field: "masterinvbatchid",
      operator: "eq",
      value: "",
      type: "text",
      default: "",
    });
    availableFilters.push({
      name: "Invoice #",
      field: "invoiceid",
      operator: "eq",
      value: "",
      type: "text",
      default: "",
    });
    // var date = new Date();
    // var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    availableFilters.push({
      name: "Start Date",
      field: "invoicedate",
      operator: ">",
      // value: moment(firstDay).format("YYYY-MM-DD"),
      // value: "2019-09-01",
      value: "",
      type: "date",
      default: "",
    });
    availableFilters.push({
      name: "End Date",
      field: "invoicedate",
      operator: "<",
      value: "",
      type: "date",
      default: "",
    });

    this.setState(
      { relations, orders, utility, columns, availableFilters },
      () => this.getNewData()
    );
  }

  render() {
    const {
      loading,
      reload,
      data,
      columns,
      filters,
      availableFilters,
      page,
      perPage,
      total,
      checkedList,
      snackbar,
      openBackdrop,
      mibModalOpen,
      mibId,
      shouldRefresh,
    } = this.state;

    const { classes } = this.props;

    if (reload === true) {
      this.setState({ reload: false }, () => {
        this.componentDidMount();
      });
    }

    return (
      <PageWithAppDrawer2 pageTitle={`Invoice Batches`}>
        <Paper className={classes.paper}>
          <div>
            <Button
              color="primary"
              variant="contained"
              onClick={this.handleAddItem}
            >
              Create Invoice Batch
            </Button>{" "}
            <Button
              color="primary"
              variant="contained"
              onClick={this.createMasterInvoiceBatch}
              disabled={checkedList.length === 0}
            >
              Add To Master Invoice Batch
            </Button>
          </div>
          <FiltersGrid
            classes={this.props.classes}
            availableFilters={availableFilters}
            filters={filters}
            onChange={this.handleFilterChange}
            clientList={this.props.utility.clients_active.items}
            typeList={this.props.utility.title_application_types.items}
            statusList={this.props.utility.title_statuses.items}
          />
          <br />
          <Divider />
          <ResponsiveGrid
            loading={loading}
            columns={columns}
            data={data}
            page={page - 1} // API has a 1 based page count, Material UI has a zero based page count
            perPage={perPage}
            total={total}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
            showActions
            onDownload={this.handleDownload}
          />
        </Paper>
        <Snackbar
          color={snackbar.severity}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          open={snackbar.open}
          autoHideDuration={snackbar.duration}
          onClose={() =>
            this.setState((prevState, props) => ({
              snackbar: {
                ...prevState.snackbar,
                open: false,
              },
            }))
          }
        >
          <MuiAlert severity={snackbar.severity} variant="filled">
            {snackbar.message}
          </MuiAlert>
        </Snackbar>
        <Backdrop open={openBackdrop} style={{ zIndex: 10000, color: "white" }}>
          <CircularProgress />
          Updating
        </Backdrop>
        <ModalGeneric
          open={mibModalOpen}
          title={"MIB #" + mibId}
          closeText={"Close"}
          onClose={() => {
            this.setState({ mibModalOpen: false, mibId: null });
            if (shouldRefresh === true) {
              this.setState({ shouldRefresh: false });
              this.getNewData();
            }
          }}
        >
          <MasterBatchManage
            id={mibId}
            shouldRefresh={(shouldRefresh) => this.setState({ shouldRefresh })}
          />
        </ModalGeneric>
      </PageWithAppDrawer2>
    );
  }

  checkMasterInvoice = (id, e) => {
    let { checkedList } = this.state;
    let isChecked = checkedList.includes(id);

    if (isChecked) {
      checkedList = checkedList.filter((existingId) => existingId !== id);
    } else {
      checkedList.push(id);
    }
    this.setState({ checkedList });
  };

  createMasterInvoiceBatch = () => {
    const { checkedList } = this.state;
    this.setState({ openBackdrop: true });
    utilityService
      .getEndpoint(`api/master_invoice_batches`, "POST", {
        body: {
          invoiceBatchIds: checkedList,
        },
      })
      .then((response) => {
        console.log("mib create response", response);
        this.setState({ openBackdrop: false });
        this.getNewData();
      })
      .catch((error) => {
        console.log("create error", error);
        this._showMessage(`Error: ${error}`, "error");
        this.setState({ openBackdrop: false });
      });
  };

  handleAddItem = () => {
    const { webUser } = this.props;
    utilityService
      .addUtility("invoice_batches", {
        invoicedate: moment()
          .tz("America/Indiana/Indianapolis")
          .format("YYYY-MM-DD"),
        userid: webUser.username,
      })
      .then((response) => {
        hashHistory.push(`/invoicebatches/${response.invoiceid}`);
      });
  };

  handleDownload = () => {
    this.getNewData(null, null, true);
  };

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage + 1 }, () => this.getNewData());
  };

  handleChangeRowsPerPage = (event) => {
    const value = parseInt(Number(event.target.value));
    console.log("handleChangeRowsPerPage", {
      passed: event.target.value,
      parsed: value,
    });
    this.setState(
      {
        perPage: value,
        page: 1,
      },
      () => this.getNewData()
    );
  };

  handleFilterChange = (newFilter) => {
    let availableFilters = this.state.availableFilters;
    if (newFilter.name) {
      availableFilters = this.state.availableFilters.map((filter) => {
        if (filter.name !== newFilter.name) return filter; // not the droid we're looking for, return unchanged
        filter.value = newFilter.value;
        return filter;
      });
    } else if (newFilter.field) {
      availableFilters = this.state.availableFilters.map((filter) => {
        if (filter.field !== newFilter.field) return filter; // not the droid we're looking for, return unchanged
        filter.value = newFilter.value;
        return filter;
      });
    }

    this.setState(
      {
        availableFilters,
        page: 1,
      },
      () => this.getNewData()
    );
  };

  getNewData = async (page = null, perPage = null, download = false) => {
    const { utility, relations, orders, availableFilters } = this.state;
    if (!perPage) perPage = this.state.perPage;
    if (!page) page = this.state.page;

    if (download !== false) download = true;
    if (!download) this.setState({ loading: true });

    // translate available filters into service filters
    const newFilters = availableFilters
      .filter((filter) => {
        if (["", null].includes(filter.value)) return false;
        if (
          ["isnull", "notnull"].includes(filter.operator) &&
          filter.value === false
        )
          return false;
        return true;
      })
      .map((filter) => {
        return {
          field: filter.field,
          operator: filter.operator,
          value: filter.value,
        };
      });

    let options = {
      filters: newFilters,
      relations: relations,
      orders: orders,
      perPage: perPage,
      page: page,
    };

    if (download) {
      options.perPage = 1000;
      await utilityService
        .getUtility(utility, options)
        .then((values) => {
          // console.log(values.data);
          return generateCSV(
            [
              "batch number",
              "invoice date",
              "client name",
              "user",
              "overnight charge",
              "overnight tracking #",
              "count",
              "total",
            ],
            [
              "invoiceid",
              "invoicedate",
              "client.clientname",
              "user.username",
              "overnightcharge",
              "overnighttrackingno",
              "invoice_count",
              "invoice_total",
            ],
            values.data
          );
        })
        .then((csv) => {
          const blob = new Blob([csv], { type: "text/csv" });
          downloadBlob(blob, "invoicebatches.csv");
        })
        .catch(console.error);
      return;
    }

    await this.props.dispatch(
      utilityActions.getUtility(utility, options, () => {
        const { items, page, per_page: perPage, total } = this.props.utility[
          utility
        ];
        this.setState({
          loading: false,
          data: items,
          page,
          perPage,
          total,
          checkedList: [],
        });
      })
    );
  };

  _showMessage = (message, severity = "info", duration = 6000) => {
    this.setState({
      snackbar: {
        open: true,
        severity,
        message,
        duration,
      },
    });
  };
}

const styles = (theme) => ({
  root: {
    display: "flex",
    fontSize: 12,
  },
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    width: "100%",
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    width: "100%",
  },
  fixedHeight: {
    height: 240,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },

  Button: {
    display: "block",
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
});

function mapStateToProps(state) {
  const { authentication, sites, utility } = state;
  const { user, webUser } = authentication;
  return {
    user,
    webUser,
    sites,
    utility,
  };
}

const connectedInvoiceBatchesPage = connect(mapStateToProps)(
  withWidth()(withStyles(styles)(InvoiceBatchesPage))
);
export { connectedInvoiceBatchesPage as InvoiceBatchesPage };
