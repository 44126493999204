import React from "react";
// import { Link as RouterLink } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import moment from "moment-timezone";
import { isIOS, isSafari } from "react-device-detect";

import { hashHistory } from "../_helpers";
import { utilityActions } from "../_actions";
import { utilityService, vehicleService } from "../_services";

import { PageWithAppDrawer2 } from "../_components/main/PageWithAppDrawer2";
import ScannerComponent from "../_components/generic/ScannerComponent";
import safari_icon from "../_assets/safari-icon.png";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";

import { Typography, Button, CircularProgress } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import ResponsiveGrid from "../_components/generic/ResponsiveGrid";

class PhotoUploadPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      openScanner: false,
      startScanner: false,
      scannedBarcode: null,
      last6: "",
      loading: false,
    };
  }

  componentDidMount() {
    const { items: image_types } = this.props.utility.vehicle_image_types;
    if (image_types.length === 0) {
      this.props.dispatch(
        utilityActions.getUtility("vehicle_image_types", {
          perPage: 100,
        })
      );
    }

    // const barcode = {
    //   text: "2C3CDXHG8FH896309"
    // };
    // this.setState({ scannedBarcode: "2LMPJ8LRXJBL11214" });
    // this.handleBarcode(barcode);
  }

  render() {
    const { classes, webUser, utility } = this.props;
    const { clientname: clientName, client_id: clientId } =
      webUser.client || {};
    const {
      openScanner,
      startScanner,
      scannedBarcode,
      last6,
      loading,
    } = this.state;

    const { vehicles } = utility;

    if (!clientName) {
      return (
        <PageWithAppDrawer2 pageTitle={"Upload Photos"}>
          <Paper className={classes.paper}>
            <Typography variant="h6" align="center">
              For clients only right now
            </Typography>
          </Paper>
        </PageWithAppDrawer2>
      );
    }

    return (
      <PageWithAppDrawer2 pageTitle={"Upload Photos"}>
        <Paper className={classes.paper}>
          <Typography variant="h6" align="center">
            {clientName}
          </Typography>
          {!openScanner ? (
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={() =>
                this.setState({ openScanner: true }, () =>
                  this.setState({ startScanner: true })
                )
              }
            >
              Scan VIN
            </Button>
          ) : (
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={() =>
                this.setState({ startScanner: false }, () =>
                  this.setState({ openScanner: false })
                )
              }
            >
              Close Scanner
            </Button>
          )}

          <br />

          <Grid container>
            <Grid item xs={6} sm={4}>
              <TextField
                value={last6 || ""}
                autoFocus
                placeholder="Vin / Last6"
                fullWidth
                onChange={(event) =>
                  this.setState({ last6: event.target.value })
                }
                onKeyPress={(event) => {
                  if (event.key === "Enter") {
                    this.setState({ loading: true });
                    this.handleLast6(last6, clientId);
                  }
                }}
              />
            </Grid>
            <Grid item xs={6} sm={8}>
              <Button
                variant="contained"
                color="primary"
                size="small"
                disabled={loading || last6.length < 6}
                startIcon={
                  loading ? (
                    <CircularProgress size={24} color="secondary" />
                  ) : null
                }
                onClick={() => {
                  this.setState({ loading: true });
                  this.handleLast6(last6, clientId);
                }}
              >
                {` Search by VIN / Last6`}
              </Button>
            </Grid>
          </Grid>

          <br />
          <br />
          {scannedBarcode && (
            <Typography align="center">
              Value Searched: {scannedBarcode}
            </Typography>
          )}
          {openScanner && (
            <>
              {isIOS && !isSafari ? (
                <Typography align="center">
                  VIN scanner on iPhone/iPad requires Safari
                  <br />
                  <img src={safari_icon} alt="img" style={{ width: 50 }} />
                </Typography>
              ) : (
                <ScannerComponent
                  started={startScanner}
                  onResult={this.handleBarcode}
                  showButtons={false}
                />
              )}
            </>
          )}
          {!openScanner && scannedBarcode && vehicles.items.length > 0 && (
            <VehicleResults clientFilter={clientId} />
          )}
          {!openScanner && scannedBarcode && vehicles.items.length === 0 && (
            <>
              <Typography align="center">
                No existing DVS registrations
              </Typography>
              <VehicleNhtsaLookup vin={scannedBarcode} />
            </>
          )}
        </Paper>
      </PageWithAppDrawer2>
    );
  }

  handleLast6 = async (vin) => {
    const { dispatch } = this.props;

    if (vin.length < 6) {
      alert("Provde at least 6 numbers/letters");
      return;
    }

    await dispatch(
      utilityActions.getUtility(
        "vehicles",
        {
          filters: [
            {
              field: "vehiclevin",
              operator: "like",
              value: vin,
            },
            // {
            //   field: "clientid",
            //   operator: "eq",
            //   value: clientId
            // }
          ],
        },
        () => {
          this.setState({ scannedBarcode: vin, loading: false });
        }
      )
    );
  };

  handleBarcode = async (barcode) => {
    const { dispatch } = this.props;

    this.setState({ startScanner: false }, () => {
      this.setState({ openScanner: false });
    });

    let vin = null;
    if (barcode.text.includes(",")) {
      // this is likely a GM QR Code, or something with extra data, but VIN is usually first
      const parts = barcode.text.split(",");
      vin = parts[0];
    } else {
      vin = barcode.text;
    }

    // clean the vin for any import flags
    vin = vin.replace(/[iI]/g, "");

    await dispatch(
      utilityActions.getUtility(
        "vehicles",
        {
          filters: [
            {
              field: "vehiclevin",
              operator: "eq",
              value: vin,
            },
          ],
        },
        () => {
          this.setState({ scannedBarcode: vin });
        }
      )
    );

    // const vehicles = await utilityService.getUtility("vehicles", {
    //   filters: [
    //     {
    //       field: "vehiclevin",
    //       operator: "eq",
    //       value: barcode.text
    //     }
    //   ]
    // });
    // if (vehicles.data && vehicles.data.length === 1) {
    //   dispatch(
    //     utilityActions.getOneUtility("vehicles", vehicles.data[0].vehicleid)
    //   );
    // }
  };
}

function VehicleNhtsaLookup(props) {
  // const dispatch = useDispatch();
  const user = useSelector((state) => state.authentication.webUser);
  const columns = [
    {
      width: 1,
      title: "Year",
      field: "modelyear",
    },
    {
      width: 2,
      title: "Make",
      field: "make",
    },
    {
      width: 2,
      title: "Model",
      field: "model",
    },
    {
      width: 2,
      title: "Series",
      field: "series",
    },
    {
      width: 2,
      title: "Mileage",
      dataRender: () => (
        <TextField
          value={mileage}
          placeholder="Mileage"
          type="number"
          onChange={(event) => setMileage(event.target.value)}
        />
      ),
    },

    {
      width: 2,
      dataRender: (item) => (
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={() => registerVehicle(props.vin, mileage, item)}
        >
          Register
        </Button>
      ),
    },
  ];

  const [nhtsaData, setNhtsaData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [mileage, setMileage] = React.useState("");

  const registerVehicle = async (vin, mileage, item) => {
    if (mileage.trim() === "") {
      alert("mileage cannot be empty");
      return;
    }
    if (Number(mileage) < 1) {
      alert("mileage cannot be empty");
      return;
    }
    const data = {
      vehiclevin: props.vin,
      mileage,
      clientid: user.clientid,
      statusid: 1,
      titlestatusid: 2,
      recordcreateddate: moment()
        .tz("America/Indiana/Indianapolis")
        .format("YYYY-MM-DD h:mm:ss"),
      yr: nhtsaData[0].modelyear,
      make: nhtsaData[0].make,
      model: nhtsaData[0].model,
      vehicletype: nhtsaData[0].bodyclass,
      // zones: moment.tz.names()
    };

    await utilityService
      .addUtility("vehicles", data)
      .then((response) => {
        const { vehicleid } = response;
        utilityService.addUtility("vehicle_notes", {
          notedate: moment()
            .tz("America/Indiana/Indianapolis")
            .format("YYYY-MM-DD h:mm:ss"),
          vehicleid,
          note: "waiting upon load",
          userid: user.userid,
          roleid: user.roleid,
        });
        return response;
      })
      .then((response) => {
        hashHistory.push(`/photoupload/${response.id}`);
      });
  };

  React.useEffect(() => {
    setLoading(true);
    vehicleService
      .nhtsaVinLookup(props.vin, { recall: true })
      .then((response) => {
        setLoading(false);
        setNhtsaData(response);
      });
  }, [props.vin]);

  return (
    <>
      <Typography align="center">NHTSA Lookup</Typography>
      {nhtsaData.length > 0 && Number(nhtsaData[0].errorcode) === 0 ? (
        <ResponsiveGrid
          loading={loading}
          columns={columns}
          data={nhtsaData}
          usePagination={false}
        />
      ) : (
        <Typography align="center">No NHTSA results</Typography>
      )}
    </>
  );
}

function VehicleResults(props) {
  const vehicles = useSelector((state) => state.utility.vehicles);
  const [clientFilter, setClientFilter] = React.useState(null);

  const columns = [
    {
      width: 3,
      title: "VIN",
      field: "vehiclevin",
    },
    {
      width: 1,
      title: "Year",
      field: "yr",
    },
    {
      width: 2,
      title: "Make",
      field: "make",
    },
    {
      width: 2,
      title: "Model",
      field: "model",
    },
    {
      width: 4,
      dataRender: (item) => {
        const valid =
          clientFilter !== null &&
          Number(item.clientid) === Number(clientFilter);
        if (!valid)
          return (
            <Typography variant="body2" color="secondary">
              Unable to register
            </Typography>
          );

        return (
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={() => hashHistory.push(`/photoupload/${item.id}`)}
          >
            Edit
          </Button>
        );
      },
    },
  ];

  React.useEffect(() => {
    setClientFilter(props.clientFilter);
  }, [props.clientFilter]);

  return (
    <>
      <Typography align="center">Vehicles in the system</Typography>
      <ResponsiveGrid
        columns={columns}
        data={vehicles.items}
        usePagination={false}
      />
    </>
  );
}

const styles = (theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
});

function mapStateToProps(state) {
  const { users, authentication, utility } = state;
  const { user, webUser } = authentication;
  return {
    user,
    webUser,
    users,
    utility,
  };
}

const connectedPhotoUploadPage = connect(mapStateToProps)(
  withStyles(styles)(PhotoUploadPage)
);
export { connectedPhotoUploadPage as PhotoUploadPage };
