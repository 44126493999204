export const utilityConstants = {
  UTILITY_GET_REQUEST: "UTILITY_GET_REQUEST",
  UTILITY_GET_SUCCESS: "UTILITY_GET_SUCCESS",
  UTILITY_GET_FAILURE: "UTILITY_GET_FAILURE",

  UTILITY_UPDATE_REQUEST: "UTILITY_UPDATE_REQUEST",
  UTILITY_UPDATE_SUCCESS: "UTILITY_UPDATE_SUCCESS",
  UTILITY_UPDATE_FAILURE: "UTILITY_UPDATE_FAILURE",

  UTILITY_GET_ONE_REQUEST: "UTILITY_GET_ONE_REQUEST",
  UTILITY_GET_ONE_SUCCESS: "UTILITY_GET_ONE_SUCCESS",
  UTILITY_GET_ONE_FAILURE: "UTILITY_GET_ONE_FAILURE",

  UTILITY_CLEAR_ONE_REQUEST: "UTILITY_CLEAR_ONE_REQUEST",
  UTILITY_CLEAR_REQUEST: "UTILITY_CLEAR_REQUEST",

  UTILITY_UPDATE_ONE_REQUEST: "UTILITY_UPDATE_ONE_REQUEST",
  UTILITY_UPDATE_ONE_SUCCESS: "UTILITY_UPDATE_ONE_SUCCESS",
  UTILITY_UPDATE_ONE_FAILURE: "UTILITY_UPDATE_ONE_FAILURE",

  UTILITY_ADD_ONE_REQUEST: "UTILITY_ADD_ONE_REQUEST",
  UTILITY_ADD_ONE_SUCCESS: "UTILITY_ADD_ONE_SUCCESS",
  UTILITY_ADD_ONE_FAILURE: "UTILITY_ADD_ONE_FAILURE",

  UTILITY_DELETE_ONE_REQUEST: "UTILITY_DELETE_ONE_REQUEST",
  UTILITY_DELETE_ONE_SUCCESS: "UTILITY_DELETE_ONE_SUCCESS",
  UTILITY_DELETE_ONE_FAILURE: "UTILITY_DELETE_ONE_FAILURE",

  UTILITY_UPDATE_LOCAL: "UTILITY_UPDATE_LOCAL",
  UTILITY_UPDATE_LOCAL_TIRES: "UTILITY_UPDATE_LOCAL_TIRES",
};
