import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { connect, useSelector, useDispatch } from "react-redux";
import { byString } from "../_helpers/generic";
import { withStyles } from "@material-ui/core/styles";
import withWidth from "@material-ui/core/withWidth";

import { utilityActions } from "../_actions";

import { PageWithAppDrawer2 } from "../_components/main/PageWithAppDrawer2";

import { Paper, Grid, Select, TextField, Button } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";

class ClientLocationDetailsPage extends React.Component {
  constructor(props) {
    super(props);
    const { id } = this.props.match ? this.props.match.params : {};
    this.state = {
      id,
      reload: false,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { id } = nextProps.match ? nextProps.match.params : {};
    if (id !== prevState.id) {
      return {
        ...prevState,
        id,
        reload: true,
      };
    }
    return false;
  }

  async componentDidMount() {}

  render() {
    const { id, reload } = this.state;
    const { classes } = this.props;

    if (reload === true) {
      this.setState({ reload: false }, () => {
        this.componentDidMount();
      });
      return <></>;
    }

    return (
      <PageWithAppDrawer2 pageTitle={`Client Location #${id}`}>
        <RouterLink to="/clientLocations">All Locations</RouterLink>
        <Paper className={classes.paper}>
          <ClientLocationDetails id={id} />
        </Paper>
      </PageWithAppDrawer2>
    );
  }
}

function ClientLocationDetails(props) {
  const dispatch = useDispatch();
  const id = props.id;
  const webUser = useSelector((state) => state.authentication.webUser);
  const isAdmin = webUser.roleid === 2 ? true : false;
  const isClient = webUser.roleid !== 2 ? true : false;
  const clientId = webUser.client_id;
  const item = useSelector((state) => state.utility.client_locations_item);
  const clients_active = useSelector((state) => state.utility.clients_active);

  const fields = [
    {
      width: 2,
      title: "Admin Only",
      field: "is_admin_only",
      type: isClient ? "displayBoolean" : "selectBoolean",
    },
    {
      width: 2,
      title: "Client Name",
      field: isClient ? "client.clientname" : "client_id",
      type: isClient ? "display" : "selectClient",
    },
    {
      width: 2,
      title: "Location Alias/Nickname",
      field: "alias",
      type: isClient && item?.is_admin_only ? "display" : "string",
    },
    {
      width: 2,
      title: "Location Name",
      field: "name",
      type: isClient && item?.is_admin_only ? "display" : "string",
    },
    {
      width: 2,
      title: "Street",
      field: "street",
      type: isClient && item?.is_admin_only ? "display" : "string",
    },
    {
      width: 2,
      title: "City",
      field: "city",
      type: isClient && item?.is_admin_only ? "display" : "string",
    },
    {
      width: 2,
      title: "State",
      field: "state",
      type: isClient && item?.is_admin_only ? "display" : "string",
    },
    {
      width: 2,
      title: "Zip",
      field: "zip",
      type: isClient && item?.is_admin_only ? "display" : "string",
    },
    {
      width: 2,
      title: "Phone",
      field: "phone",
      type: isClient && item?.is_admin_only ? "display" : "string",
    },
    {
      width: 2,
      title: "Country",
      field: "country",
      type: isClient && item?.is_admin_only ? "display" : "string",
    },
    
  ];

  React.useEffect(() => {
    if (clients_active.items.length === 0) {
      dispatch(
        utilityActions.getUtility("clients_active", {
          perPage: 1000,
          filters: [
            {
              field: "clientactive",
              operator: "eq",
              value: "2",
            },
          ],
          orders: [{ field: "clientname", direction: "asc" }],
        })
      );
    }

    return () => {
      dispatch(utilityActions.clearOneUtility("client_locations"));
    };
  }, []);

  React.useEffect(() => {
    if (id) {
      if (!item) {
        getNewData();
      } else if (Number(item.id) !== Number(id)) {
        getNewData();
      }
    }
  }, [id]);

  const getNewData = () => {
    dispatch(utilityActions.getOneUtility("client_locations", id, []));
  };

  const updateLocal = (event) => {
    let { name, value } = event.target;
    if (name === "is_admin_only") {
      if (value === "true") {
        value = true;
      } else {
        value = false;
      }
    }
    dispatch(
      utilityActions.updateOneUtilityLocal("client_locations", {
        [name]: value,
      })
    );
  };
  // const updateLocalManual = (name, value) => {
  //   dispatch(
  //     utilityActions.updateOneUtilityLocal("client_locations", {
  //       [name]: value,
  //     })
  //   );
  // };

  const handleSave = () => {
    dispatch(
      utilityActions.updateUtility("client_locations", item.id, item, () => {
        getNewData();
      })
    );
  };

  return (
    <>
      {item && item.id ? (
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Button variant="contained" color="primary" onClick={handleSave}>
              Save Changes
            </Button>{" "}
          </Grid>
          {fields.map((field, index) => (
            <React.Fragment key={index}>
              <Grid item xs={12} sm={2}>
                {field.title}
              </Grid>
              <Grid item xs={12} sm={10}>
                {field.type === "display" && <>{byString(item, field.field)}</>}
                {field.type === "displayBoolean" && (
                  <>{item.is_admin_only ? "Yes" : "No"}</>
                )}

                {field.type === "string" && (
                  <TextField
                    name={field.field}
                    placeholder={field.title}
                    value={item[field.field] || ""}
                    onChange={updateLocal}
                    fullWidth
                  />
                )}
                {field.type === "selectBoolean" && (
                  <Select
                    native
                    name={field.field}
                    value={item[field.field] === true ? true : false}
                    onChange={updateLocal}
                    style={{ fontSize: 12 }}
                    displayEmpty
                  >
                    <option value={false}>Client Edit</option>
                    <option value={true}>Admin Only</option>
                  </Select>
                )}
                {clients_active.items.length > 0 &&
                  field.type === "selectClient" && (
                    <Select
                      native
                      name={field.field}
                      value={item[field.field] || ""}
                      onChange={updateLocal}
                      style={{ fontSize: 12 }}
                      displayEmpty
                    >
                      <option value="">Client Name</option>
                      {clients_active &&
                        clients_active.items.map((client, index) => (
                          <option key={index} value={client.clientid}>
                            {client.clientname}
                          </option>
                        ))}
                    </Select>
                  )}
              </Grid>
            </React.Fragment>
          ))}
        </Grid>
      ) : (
        <CircularProgress />
      )}
    </>
  );
}

const styles = (theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    width: "100%",
  },
});

function mapStateToProps(state) {
  const { authentication, utility, batch } = state;
  const { user } = authentication;
  return {
    user,
    batch,
    utility,
  };
}

const connectedClientLocationDetailsPage = connect(mapStateToProps)(
  withWidth()(withStyles(styles)(ClientLocationDetailsPage))
);
export { connectedClientLocationDetailsPage as ClientLocationDetailsPage };
