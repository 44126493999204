import { vehicleConstants } from "../_constants";
import { vehicleService } from "../_services";
import { alertActions } from ".";

export const vehicleActions = {
  getVehicles,
  addVehicle,
  getVehicle,
  updateVehicle,
  updateLocalVehicle,
  clearVehicle,
};

function getVehicles(filters = [], relations = [], perPage = 10, page = 1) {
  if (filters === null) filters = [];
  if (relations === null) relations = [];

  return (dispatch) => {
    dispatch(request());

    vehicleService.getVehicles(filters, relations, perPage, page).then(
      (vehicles) => {
        dispatch(success(vehicles));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request() {
    return { type: vehicleConstants.VEHICLES_GET_REQUEST };
  }
  function success(vehicles) {
    return { type: vehicleConstants.VEHICLES_GET_SUCCESS, vehicles };
  }
  function failure(error) {
    return { type: vehicleConstants.VEHICLES_GET_FAILURE, error };
  }
}

function addVehicle(data = {}, callback) {
  return (dispatch) => {
    dispatch(request());

    vehicleService
      .addVehicle(data)
      .then(
        (vehicle) => {
          dispatch(success(vehicle));
          return vehicle;
        },
        (error) => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      )
      .then((vehicle) => {
        if (callback) callback(vehicle);
      });
  };

  function request() {
    return { type: vehicleConstants.VEHICLE_ADD_REQUEST };
  }
  function success(vehicle) {
    return { type: vehicleConstants.VEHICLE_ADD_SUCCESS, vehicle };
  }
  function failure(error) {
    return { type: vehicleConstants.VEHICLE_ADD_FAILURE, error };
  }
}

function getVehicle(id, relations = []) {
  if (relations === null) relations = [];

  return (dispatch) => {
    dispatch(request());

    vehicleService.getVehicle(id, relations).then(
      (vehicle) => {
        dispatch(success(vehicle));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request() {
    return { type: vehicleConstants.VEHICLE_GET_REQUEST };
  }
  function success(vehicle) {
    return { type: vehicleConstants.VEHICLE_GET_SUCCESS, vehicle };
  }
  function failure(error) {
    return { type: vehicleConstants.VEHICLE_GET_FAILURE, error };
  }
}

function updateVehicle(id, data = {}, callback) {
  return (dispatch) => {
    dispatch(request());

    vehicleService.updateVehicle(id, data).then(
      (vehicle) => {
        dispatch(success(vehicle));
        if (callback) callback(vehicle);
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
        if (callback) callback({ error: error.toString() });
      }
    );
  };

  function request() {
    return { type: vehicleConstants.VEHICLE_UPDATE_REQUEST };
  }
  function success(vehicle) {
    return { type: vehicleConstants.VEHICLE_UPDATE_SUCCESS, vehicle };
  }
  function failure(error) {
    return { type: vehicleConstants.VEHICLE_UPDATE_FAILURE, error };
  }
}

function updateLocalVehicle(data) {
  return (dispatch) => {
    dispatch(update(data));
  };

  function update(data) {
    return { type: vehicleConstants.VEHICLE_SET_LOCAL, data };
  }
}

function clearVehicle() {
  return (dispatch) => {
    dispatch(clear());
  };

  function clear() {
    return { type: vehicleConstants.VEHICLE_CLEAR };
  }
}
