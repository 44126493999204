import React, { useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { utilityActions } from "../_actions";
import { generateCSV, downloadBlob } from "../_helpers";

import { PageWithAppDrawer2 } from "../_components/main/PageWithAppDrawer2";
import ResponsiveGrid from "../_components/generic/ResponsiveGrid";

import FiltersGrid from "../_components/generic/FiltersGrid";
import { Paper, Button, makeStyles, Grid } from "@material-ui/core";
import { utilityService } from "../_services";
import { hashHistory } from "../_helpers";

export function AdminUsers2Page(props) {
  const dispatch = useDispatch();
  const classes = useAdminUsers2PageStyles();

  useEffect(() => {
    dispatch(
      utilityActions.getUtility("clients_active", {
        perPage: 1000,
        filters: [
          {
            field: "clientactive",
            operator: "eq",
            value: "2",
          },
        ],
        orders: [{ field: "clientname", direction: "asc" }],
      })
    );
  }, []);

  const handleAddItem = () => {
    const rand = Math.round(Math.random() * 1000000);
    utilityService
      .addUtility("web_users", {
        firstname: "FIRSTNAME" + rand,
        lastname: "LASTNAME" + rand,
        username: "USERNAME" + rand,
        useremail: rand + "@email.com",
        active: 1,
      })
      .then((response) => {
        hashHistory.push(`/admin/users/${response.id}`);
      });
  };

  return (
    <PageWithAppDrawer2 pageTitle={"Users"}>
      <Paper className={classes.paper}>
        <Grid container>
          <Grid item>
            <Button color="primary" variant="contained" onClick={handleAddItem}>
              Add User
            </Button>
          </Grid>
        </Grid>

        <UsersTable />
      </Paper>
    </PageWithAppDrawer2>
  );
}

const useAdminUsers2PageStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
}));

export { AdminUsers2Page as default };

function UsersTable(props) {
  const dispatch = useDispatch();
  const items = useSelector((state) => state.utility.web_users);
  const columns = [
    {
      width: 2,
      title: "Name",
      field: "name",
      dataRender: (item) => (
        <RouterLink to={`/admin/users/${item.id}`}>{item.name}</RouterLink>
      ),
    },
    {
      width: 1,
      title: "First",
      field: "firstname",
    },
    {
      width: 1,
      title: "Last",
      field: "lastname",
    },
    {
      width: 1,
      title: "User",
      field: "username",
    },
    {
      width: 1,
      title: "Active",
      field: "active",
    },
    {
      width: 2,
      title: "Auction",
      field: "auction.auctionname",
    },
    {
      width: 2,
      title: "Phone",
      field: "phonenumber",
    },
    {
      width: 2,
      title: "Email",
      field: "useremail",
    },
    {
      width: 2,
      title: "Client",
      field: "client.clientname",
    },
  ];

  const [availableFilters, setAvailableFilters] = React.useState([
    {
      name: "First",
      field: "firstname",
      operator: "like",
      value: "",
      type: "text",
      default: "",
    },
    {
      name: "Last",
      field: "lastname",
      operator: "like",
      value: "",
      type: "text",
      default: "",
    },
    {
      name: "Active",
      field: "active",
      operator: "eq",
      value: "0",
      type: "choice",
      choices: [
        {
          key: "0",
          value: "Active",
        },
        {
          key: "1",
          value: "Not Active",
        },
      ],
      default: "",
    },
    {
      name: "Client",
      field: "client.clientname",
      operator: "like",
      value: "",
      type: "text",
      default: "",
    },
  ]);

  const [getData, setGetData] = React.useState(false);

  const [page, setPage] = React.useState(
    items.page ? Number(items.page) - 1 : 0
  );
  const [perPage, setPerPage] = React.useState(
    items.per_page ? Number(items.per_page) : 0
  );
  const [total, setTotal] = React.useState(
    items.total ? Number(items.total) : 0
  );

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
    setGetData(true);
  };
  const handleChangeRowsPerPage = (event) => {
    const value = parseInt(event.target.value);
    setPerPage(value);
    setGetData(true);
  };
  const getNewData = (download = false) => {
    let newFilters = availableFilters
      .filter((filter) => {
        if (["", null].includes(filter.value)) return false;
        if (
          ["isnull", "notnull"].includes(filter.operator) &&
          filter.value === false
        )
          return false;
        return true;
      })
      .map((filter) => {
        return {
          field: filter.field,
          operator: filter.operator,
          value: filter.value,
        };
      });

    if (download) {
      let options = {
        filters: newFilters,
        perPage: perPage,
        page: page,
      };
      options.page = 1;
      options.perPage = total + 1;
      utilityService
        .getUtility("web_users", options)
        .then((values) => {
          const downloadHeaders = columns
            .filter((item) => item.title)
            .map((item) => {
              if (!item.title) return null;
              return item.title;
            });
          const downloadFields = columns
            .filter((item) => item.field)
            .map((item) => {
              if (!item.field) return null;
              return item.field;
            });

          return generateCSV(downloadHeaders, downloadFields, values.data);
        })
        .then((csv) => {
          const blob = new Blob([csv], { type: "text/csv" });
          downloadBlob(blob, `users.csv`);
        })
        .catch(console.error)
        .finally(() => {});
      return;
    }

    dispatch(
      utilityActions.getUtility("web_users", {
        page,
        perPage,
        filters: newFilters,
      })
    );
  };

  useEffect(() => {
    if (getData === true) {
      setGetData(false);
      getNewData();
    }
  }, [getData]);

  useEffect(() => {
    setTotal(items.total ? Number(items.total) : 0);
    setPage(items.page ? Number(items.page) : 0);
    setPerPage(items.per_page ? Number(items.per_page) : 10);
  }, [items]);

  useEffect(() => {
    if (items.items.length === 0) {
      getNewData();
    }
  }, []);

  const handleFilterChange = (newFilter) => {
    const newFilters = availableFilters.map((filter) => {
      if (filter.field !== newFilter.field) return filter; // not the droid we're looking for, return unchanged
      filter.value = newFilter.value;
      return filter;
    });
    setAvailableFilters(newFilters);
    setPage(1);
    setGetData(true);
  };

  const handleDownload = () => {
    getNewData(true);
  };

  return (
    <>
      <FiltersGrid
        availableFilters={availableFilters}
        onChange={handleFilterChange}
      />
      <ResponsiveGrid
        loading={items.loading}
        columns={columns}
        data={items.items}
        page={page - 1} // API has a 1 based page count, Material UI has a zero based page count
        perPage={perPage}
        total={total}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        showActions
        onDownload={handleDownload}
      />
    </>
  );
}
