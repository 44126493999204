import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import clsx from "clsx";
import { withStyles, makeStyles, fade } from "@material-ui/core/styles";
import { hashHistory, removeDiskCache } from "../../_helpers";

import { utilityActions, vehicleActions } from "../../_actions";
import { utilityService } from "../../_services";

import PageMenu from "./PageMenu";
import VinLookupDialog from "../vehicle/VinLookupDialog";
import ModalGeneric from "../generic/ModalGeneric";

import logo_xs_dvs from "../../_assets/dvslogo_web_new_xs.png";
import logo_adesa from "../../_assets/adesalogo.png";

import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import InputBase from "@material-ui/core/InputBase";
import Button from "@material-ui/core/Button";
import {
  Hidden,
  Backdrop,
  CircularProgress,
  TextField,
} from "@material-ui/core";

import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import SearchIcon from "@material-ui/icons/Search";
import HelpIcon from "@material-ui/icons/Help";

import MoreIcon from "@material-ui/icons/MoreVert";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";

import { getCustomer } from "../../_helpers";

const customer = getCustomer();

let logo_xs = null;
if (customer === "adesa") {
  logo_xs = logo_adesa;
} else {
  logo_xs = logo_xs_dvs;
}

class PageWithAppDrawer2 extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isElectron: false,
      open: false,
      openVinSearch: false,
      showHomeLinks: props.showHomeLinks === true ? true : false,
      loggedIn: props.loggedIn,
      showSideBar: props.showSideBar === false ? false : true,
      menuOpen: props.menuOpen === true ? true : false,
      backdropOpen: false,
      vinSearch: "",
      vinDetails: null,
    };
  }

  componentDidMount() {
    const { loggedIn } = this.props;

    const userAgent = navigator.userAgent.toLowerCase();
    if (userAgent.indexOf(" electron/") > -1) {
      this.setState({
        isElectron: true,
      });
    }

    if (loggedIn) {
      const newFilters = [];
      newFilters.push({
        field: "complete",
        operator: "eq",
        value: 0,
      });
      newFilters.push({
        field: "roleid",
        operator: "eq",
        value: 1,
      });

      let options = {
        filters: newFilters,
      };

      this.props.dispatch(
        utilityActions.getUtility("vehicle_notes_open", options)
      );
    }
  }

  drawerToggle = () => {
    // const { dispatch, menuOpen } = this.props;
    // dispatch(userActions.setMenu(!menuOpen));
    this.setState((prevState) => {
      return {
        menuOpen: !prevState.menuOpen,
      };
    });
  };

  handleVinSearch = () => {
    const { webUser } = this.props;
    const roleid = webUser?.role.roleid;

    const clientGroup = webUser.client_group || null;
    const clientsList = clientGroup
    ? clientGroup.clients_base.map((c) => c.clientid)
    : [webUser.client_id];

    // 2 is admin, anything less than that should filter by client
    const shouldFilterByClient = Number(roleid) < 2;

    this.props.dispatch(utilityActions.clearUtility("vehicles"));
    removeDiskCache("vehicles-filters");
    const { vinSearch } = this.state;
    this.setState({ backdropOpen: true });

    let field = "vehiclevin";
    let operator = "like";
    if (vinSearch.length === 6) {
      field = "last6";
      operator = "eq";
    }

    let filters = [
      {
        field,
        operator,
        value: vinSearch,
      },
    ];
    if (shouldFilterByClient) {
      filters.push({
        field: "clientid",
        operator: "in",
        value: clientsList,
      });
    }

    utilityService
      .getUtility("vehicles", {
        page: 1,
        perPage: 2,
        params: "with=none",
        filters,
      })
      .then((response) => {
        this.setState({ backdropOpen: false });
        if (response && response.data) {
          if (response.data.length === 1) {
            hashHistory.push(`/vehicles/${response.data[0].vehicleid}`);
          } else if (response.data.length > 1) {
            hashHistory.push(`/vehicles/?vin=${vinSearch}`);
          } else {
            console.log("no results");
          }
        }
        console.log("response", response);
      })
      .catch((e) => {
        console.log("error");
        this.setState({ backdropOpen: true });
      });
  };

  render() {
    const {
      loggedIn,
      showHomeLinks,
      menuOpen,
      openVinSearch,
      vinSearch,
      backdropOpen,
      vinDetails,
    } = this.state;
    const { classes, theme } = this.props;
    // console.log("classes", classes);
    const { user, vehicle_notes_open: notes } = this.props;
    const { pageTitle } = this.props;

    const notesCount = user ? (notes.items ? notes.items.length : 0) : 0;
    const showSideBar = this.state.showSideBar && this.props.loggedIn;

    return (
      <div className={classes.root}>
        <CssBaseline />
        <AppBar
          position="fixed"
          className={clsx(classes.appBar, {
            [classes.appBarShift]: menuOpen && showSideBar,
          })}
        >
          <PageToolBar
            showSideBar={showSideBar}
            loggedIn={loggedIn}
            menuOpen={menuOpen}
            pageTitle={pageTitle}
            backdropOpen={backdropOpen}
            showHomeLinks={showHomeLinks}
            onDrawerToggle={this.drawerToggle}
            vinSearchText={vinSearch}
            onVinSearch={this.handleVinSearch}
            onVinSearchOpen={this.handleVinSearchOpen}
            onVinSearchTextChange={(event) =>
              this.setState({ vinSearch: event.target.value })
            }
          />
        </AppBar>
        {showSideBar && (
          <Drawer
            className={classes.drawer}
            variant="persistent"
            anchor="left"
            open={menuOpen}
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            <div className={classes.drawerHeader}>
              Version2
              <IconButton onClick={this.drawerToggle}>
                {theme.direction === "ltr" ? (
                  <ChevronLeftIcon />
                ) : (
                  <ChevronRightIcon />
                )}
              </IconButton>
            </div>
            <Divider />
            <PageMenu notesCount={notesCount} />
          </Drawer>
        )}
        {loggedIn && (
          <VinLookupDialog
            open={openVinSearch}
            onClose={this.handleVinSearchClose}
            onVinLookup={this.handleVinLookup}
            onRegister={this.handleRegister}
            vinDetails={vinDetails}
          />
        )}

        <main
          className={clsx(classes.content, {
            [classes.contentShift]: menuOpen || !showSideBar,
          })}
        >
          <div className={classes.drawerHeader} />
          {this.props.children}
        </main>
      </div>
    );
  }

  handleVinSearchOpen = () => {
    this.setState({ openVinSearch: true });
  };

  handleVinSearchClose = (value) => {
    this.setState({ openVinSearch: false });
  };

  handleRegister = (vinDetails) => {
    const { dispatch } = this.props;

    dispatch(
      vehicleActions.updateLocalVehicle({
        vehiclevin: vinDetails.vin,
        yr: vinDetails.modelyear,
        make: vinDetails.make,
        model: vinDetails.model,
        vehicletype: vinDetails.vehicletype,
      })
    );
    hashHistory.push("/vehicles/new");
  };
}

function PageToolBar(props) {
  const classes = styles2();

  const [showSideBar, setShowSideBar] = useState(props.showSideBar || false);
  const [loggedIn, setLoggedIn] = useState(props.loggedIn || false);
  const [menuOpen, setMenuOpen] = useState(props.menuOpen || false);
  const [pageTitle, setPageTitle] = useState(props.pageTitle || "");
  const [showHomeLinks, setShowHomeLinks] = useState(
    props.showHomeLinks || false
  );
  const [vinSearchText, setVinSearchText] = useState("");
  const backdropOpen = false;

  const [openVinSearch, setOpenVinSearch] = useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  useEffect(() => {
    setShowSideBar(props.showSideBar);
    setLoggedIn(props.loggedIn);
    setMenuOpen(props.menuOpen);
    setPageTitle(props.pageTitle);
    setShowHomeLinks(props.showHomeLinks);
    setVinSearchText(props.vinSearchText);
  }, [props]);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Toolbar>
      {showSideBar && loggedIn && (
        <IconButton
          edge="start"
          color="inherit"
          aria-label="open drawer"
          onClick={props.onDrawerToggle}
          className={clsx(
            classes.menuButton,
            menuOpen && classes.menuButtonHidden
          )}
        >
          <MenuIcon />
        </IconButton>
      )}
      <img
        src={logo_xs}
        alt="logo"
        style={{ height: 42, marginRight: 10, borderRadius: 21 }}
      />

      <Typography variant="h6" noWrap style={{ flexGrow: 1 }}>
        {pageTitle}
      </Typography>
      {loggedIn && (
        <>
          <Hidden xsDown>
            <Button
              size="small"
              variant="outlined"
              onClick={props.onVinSearchOpen}
            >
              Add Vehicle
            </Button>
            <div className={classes.search}>
              <div className={classes.searchIcon}>
                <SearchIcon />
              </div>
              <InputBase
                placeholder="Vin / Last6"
                value={vinSearchText}
                onChange={props.onVinSearchTextChange}
                onKeyPress={(e) => {
                  if (e.key === "Enter") props.onVinSearch();
                }}
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                inputProps={{ "aria-label": "search" }}
              />
            </div>
          </Hidden>
          <Hidden mdUp>
            <IconButton onClick={() => setOpenVinSearch(true)}>
              <SearchIcon />
            </IconButton>
          </Hidden>

          <ModalGeneric
            open={openVinSearch}
            title="Vin Search"
            closeText="Search"
            cancelText="Cancel"
            onClose={props.onVinSearch}
            fullScreen={false}
            onCancel={() => setOpenVinSearch(false)}
          >
            <TextField
              fullWidth
              placeholder="Vin / Last6"
              value={vinSearchText}
              onChange={props.onVinSearchTextChange}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  setOpenVinSearch(false);
                  props.onVinSearch();
                }
              }}
            />
          </ModalGeneric>
          <Backdrop
            className={classes.backdrop}
            open={backdropOpen}
            // onClick={handleClose}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </>
      )}
      {showHomeLinks && (
        <>
          <Hidden xsDown>
            <Button
              onClick={() => {
                hashHistory.push("/");
              }}
            >
              Home
            </Button>
            <Button
              onClick={() => {
                hashHistory.push("/");
              }}
            >
              About Us
            </Button>
          </Hidden>

          {pageTitle !== "Login" && !loggedIn && (
            <Button
              onClick={() => {
                hashHistory.push("/login");
              }}
            >
              {!loggedIn ? "Login" : "Logout"}
            </Button>
          )}

          <Hidden smUp>
            <div>
              <IconButton
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
              >
                <MoreIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={open}
                onClose={handleClose}
              >
                <MenuItem
                  onClick={() => {
                    hashHistory.push("/");
                  }}
                >
                  Home
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    hashHistory.push("/");
                  }}
                >
                  About Us
                </MenuItem>
              </Menu>
            </div>
          </Hidden>
        </>
      )}
      <IconButton
        color="inherit"
        aria-label="open help"
        onClick={() => {
          hashHistory.push("/help");
        }}
        className={clsx(classes.menuButton)}
      >
        <HelpIcon />
      </IconButton>
    </Toolbar>
  );
}

const drawerWidth = 240;

const styles2 = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

const styles = (theme) => ({
  root: {
    display: "flex",
    flexGrow: 1,
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
});

function mapStateToProps(state) {
  const { users, authentication, utility } = state;
  const { user, webUser } = authentication;
  const { vehicle_notes_open } = utility;
  const isAdmin = true;
  // const { menuOpen } = users;
  return {
    loggedIn: authentication.loggedIn,
    user,
    webUser,
    users,
    isAdmin,
    // menuOpen,
    vehicle_notes_open,
  };
}

const connectedPageWithAppDrawer2 = connect(mapStateToProps)(
  withStyles(styles, { withTheme: true })(PageWithAppDrawer2)
);
export { connectedPageWithAppDrawer2 as PageWithAppDrawer2 };
