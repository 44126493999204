import React, { useRef, useState, useEffect } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import moment from "moment";

import { getEnvironmentURL } from "../_helpers";

import { PageWithAppDrawer2 } from "../_components/main/PageWithAppDrawer2";
// import { AuctionModal } from "../_components/admin/AuctionModal";
import FiltersGrid from "../_components/generic/FiltersGrid";

import { Paper, Button, Divider } from "@material-ui/core";

class ManagementReportChargesPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {}

  render() {
    const { classes } = this.props;
    return (
      <PageWithAppDrawer2 pageTitle={"Charge Breakdown Report"}>
        <Paper className={classes.paper}>
          <ChargeBreakdownReport />
        </Paper>
      </PageWithAppDrawer2>
    );
  }
}

function ChargeBreakdownReport(props) {
  let date = new Date();
  let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
  let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

  const formRef = useRef(null);
  const [json, setJson] = useState(null);

  const [availableFilters, setAvailableFilters] = React.useState([
    {
      name: "Start Date",
      field: "start",
      operator: "eq",
      value: moment(firstDay).format("YYYY-MM-DD"),
      type: "date",
      default: "",
    },
    {
      name: "End Date",
      field: "end",
      operator: "eq",
      value: moment(lastDay).format("YYYY-MM-DD"),
      type: "date",
      default: "",
    },
    {
      name: "Client",
      field: "clientid",
      operator: "eq",
      value:
        props.defaultFilters && props.defaultFilters.clientid
          ? props.defaultFilters.clientid
          : "",
      type: "choice",
      useUtility: true,
      utility: "clients_active",
      utilityValue: "clientname",
      utilityOrder: {
        field: "clientname",
        direction: "asc",
      },
      utilityFilters: [
        {
          field: "clientactive",
          operator: "eq",
          value: 2,
        },
      ],
      default: "",
    },
  ]);

  const [start, setStart] = useState(moment(firstDay).format("YYYY-MM-DD"));
  const [end, setEnd] = useState(moment(lastDay).format("YYYY-MM-DD"));

  const handleFilterChange = (newFilter) => {
    const newFilters = availableFilters.map((filter) => {
      if (filter.field !== newFilter.field) return filter; // not the droid we're looking for, return unchanged
      if (filter.field === "start") setStart(newFilter.value);
      if (filter.field === "end") setEnd(newFilter.value);
      filter.value = newFilter.value;
      return filter;
    });
    setAvailableFilters(newFilters);
  };

  useEffect(() => {
    console.log("availableFilters", availableFilters);
    setJson(JSON.stringify(availableFilters));
  }, [availableFilters]);

  return (
    <>
      <FiltersGrid
        availableFilters={availableFilters}
        onChange={handleFilterChange}
      />
      <Divider style={{ marginTop: 10, marginBottom: 50 }} />
      <div style={{ display: "inline-block" }}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            formRef.current.submit();
          }}
        >
          Get CSV
        </Button>
        <form
          ref={formRef}
          method="POST"
          action={`${getEnvironmentURL()}/api/reports/management/charge_breakdown`}
          target="_blank"
        >
          <input type="hidden" name="json" value={json} />
        </form>
      </div>
    </>
  );
}

const styles = (theme) => ({
  root: {
    display: "flex",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
});

function mapStateToProps(state) {
  const { authentication, utility } = state;
  const { user } = authentication;
  const { us_states, auctions } = utility;
  return {
    user,
    auctions,
    us_states,
  };
}

const connectedManagementReportChargesPage = connect(mapStateToProps)(
  withStyles(styles)(ManagementReportChargesPage)
);
export { connectedManagementReportChargesPage as ManagementReportChargesPage };
