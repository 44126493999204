import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import { makeStyles, fade } from "@material-ui/core/styles";
import { hashHistory, removeDiskCache } from "../../_helpers";

import { utilityActions } from "../../_actions";
import { utilityService } from "../../_services";

import PageMenu from "./PageMenu";
import VinLookupDialog from "../vehicle/VinLookupDialog";
import ModalGeneric from "../generic/ModalGeneric";

import logo_xs_dvs from "../../_assets/dvslogo_web_new_xs.png";
import logo_svetna from "../../_assets/svetna-icon.png";

import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import InputBase from "@material-ui/core/InputBase";
import Button from "@material-ui/core/Button";
import { Hidden, TextField, useTheme } from "@material-ui/core";

import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import SearchIcon from "@material-ui/icons/Search";
import HelpIcon from "@material-ui/icons/Help";

import MoreIcon from "@material-ui/icons/MoreVert";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import useOnlineStatus from "../../_hooks/useOnlineStatus";
import useCustomerDNS from "../../_hooks/useCustomerDNS";

export function PageWithAppDrawer3(props) {
  const classes = usePageStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const isOnline = useOnlineStatus();

  const loggedIn = useSelector((state) => state.authentication.loggedIn);
  const webUser = useSelector((state) => state.authentication.webUser);
  const user = useSelector((state) => state.authentication.user);
  const vehicle_notes_open = useSelector(
    (state) => state.utility.vehicle_notes_open
  );

  const [menuOpen, setMenuOpen] = useState(!!props.menuOpen);
  const showSideBar = props.showSideBar === false ? false : true;
  const [pageTitle, setPageTitle] = useState(props.pageTitle);
  const showHomeLinks = !!props.showHomeLinks;
  const [vinSearch, setVinSearch] = useState("");
  const [vinDetails, setVinDetails] = useState(null);
  const [notesCount, setNotesCount] = useState(0);
  const [openVinSearch, setOpenVinSearch] = useState(false);
  const [appBarStyle, setAppBarStyle] = useState({});

  useEffect(() => {
    if (loggedIn) {
      const newFilters = [];
      newFilters.push({
        field: "complete",
        operator: "eq",
        value: 0,
      });
      newFilters.push({
        field: "roleid",
        operator: "eq",
        value: 1,
      });

      let options = {
        filters: newFilters,
      };

      dispatch(utilityActions.getUtility("vehicle_notes_open", options));
    }
  }, [loggedIn]);

  useEffect(() => {
    if (user) {
      setNotesCount(vehicle_notes_open.items.length);
    }
  }, [user, vehicle_notes_open]);

  useEffect(() => {
    if (isOnline){
      setAppBarStyle({})
      setPageTitle(props.pageTitle);
    }
    else{
      setAppBarStyle({backgroundColor: "#ff0000"})
      setPageTitle(`${props.pageTitle} (Offline)`);
    }
  }, [isOnline]);

  const drawerToggle = () => {
    setMenuOpen(!menuOpen);
  };

  const handleVinSearchOpen = () => {
    setOpenVinSearch(true);
  };

  const handleVinSearchClose = (value) => {
    setOpenVinSearch(false);
  };

  const handleVinSearch = () => {
    const roleid = webUser?.role.roleid;

    const clientGroup = webUser.client_group || null;
    const clientsList = clientGroup
      ? clientGroup.clients_base.map((c) => c.clientid)
      : [webUser.client_id];

    // 2 is admin, anything less than that should filter by client
    const shouldFilterByClient = Number(roleid) < 2;

    dispatch(utilityActions.clearUtility("vehicles"));
    removeDiskCache("vehicles-filters");

    let field = "vehiclevin";
    let operator = "like";
    if (vinSearch.length === 6) {
      field = "last6";
      operator = "eq";
    }

    let filters = [
      {
        field,
        operator,
        value: vinSearch,
      },
    ];
    if (shouldFilterByClient) {
      filters.push({
        field: "clientid",
        operator: "in",
        value: clientsList,
      });
    }

    utilityService
      .getUtility("vehicles", {
        page: 1,
        perPage: 2,
        params: "with=none",
        filters,
      })
      .then((response) => {
        if (response && response.data) {
          if (response.data.length === 1) {
            hashHistory.push(`/vehicles/${response.data[0].vehicleid}`);
          } else if (response.data.length > 1) {
            hashHistory.push(`/vehicles/?vin=${vinSearch}`);
          } else {
            console.log("no results");
          }
        }
        console.log("response", response);
      })
      .catch((e) => {
        console.log("error");
      });
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        style={appBarStyle}
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: menuOpen && showSideBar,
        })}
      >
        <PageToolBar
          showSideBar={showSideBar}
          loggedIn={loggedIn}
          menuOpen={menuOpen}
          pageTitle={isOnline ? pageTitle : `${pageTitle} (offline)`}
          showHomeLinks={showHomeLinks}
          onDrawerToggle={drawerToggle}
          vinSearchText={vinSearch}
          onVinSearch={handleVinSearch}
          onVinSearchOpen={handleVinSearchOpen}
          onVinSearchTextChange={(event) => setVinSearch(event.target.value)}
        />
      </AppBar>
      {showSideBar && (
        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="left"
          open={menuOpen}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <div className={classes.drawerHeader}>
            Version2
            <IconButton onClick={drawerToggle}>
              {theme.direction === "ltr" ? (
                <ChevronLeftIcon />
              ) : (
                <ChevronRightIcon />
              )}
            </IconButton>
          </div>
          <Divider />
          <PageMenu notesCount={notesCount} />
        </Drawer>
      )}
      {loggedIn && (
        <VinLookupDialog
          open={openVinSearch}
          onClose={handleVinSearchClose}
          onVinLookup={() => console.log("VinLookupDialog onVinLookup")}
          onRegister={() => console.log("VinLookupDialog onRegister")}
          vinDetails={vinDetails}
        />
      )}

      <main
        className={clsx(classes.content, {
          [classes.contentShift]: menuOpen || !showSideBar,
        })}
      >
        <div className={classes.drawerHeader} />
        {props.children}
      </main>
    </div>
  );
}

function PageToolBar(props) {
  const classes = usePageStyles();
  const customerName = useCustomerDNS();

  const showSideBar = !!props.showSideBar;
  const loggedIn = !!props.loggedIn;
  const menuOpen = !!props.menuOpen;
  const pageTitle = props.pageTitle || "";
  const showHomeLinks = !!props.showHomeLinks;
  const [vinSearchText, setVinSearchText] = useState("");

  const [openVinSearch, setOpenVinSearch] = useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  useEffect(() => {
    setVinSearchText(props.vinSearchText);
  }, [props]);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Toolbar>
      {showSideBar && loggedIn && (
        <IconButton
          edge="start"
          color="inherit"
          aria-label="open drawer"
          onClick={props.onDrawerToggle}
          className={clsx(
            classes.menuButton,
            menuOpen && classes.menuButtonHidden
          )}
        >
          <MenuIcon />
        </IconButton>
      )}
      <img
        src={customerName === 'svetna' ? logo_svetna : logo_xs_dvs}
        alt="logo"
        style={{ height: 42, marginRight: 10, borderRadius: 21 }}
      />

      <Typography variant="h6" noWrap style={{ flexGrow: 1 }}>
        {pageTitle}
      </Typography>
      {loggedIn && (
        <>
          <Hidden xsDown>
            <Button
              size="small"
              variant="outlined"
              onClick={props.onVinSearchOpen}
            >
              Add Vehicle
            </Button>
            <div className={classes.search}>
              <div className={classes.searchIcon}>
                <SearchIcon />
              </div>
              <InputBase
                placeholder="Vin / Last6"
                value={vinSearchText}
                onChange={props.onVinSearchTextChange}
                onKeyPress={(e) => {
                  if (e.key === "Enter") props.onVinSearch();
                }}
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                inputProps={{ "aria-label": "search" }}
              />
            </div>
          </Hidden>
          <Hidden mdUp>
            <IconButton onClick={() => setOpenVinSearch(true)}>
              <SearchIcon />
            </IconButton>
          </Hidden>

          <ModalGeneric
            open={openVinSearch}
            title="Vin Search"
            closeText="Search"
            cancelText="Cancel"
            onClose={props.onVinSearch}
            fullScreen={false}
            onCancel={() => setOpenVinSearch(false)}
          >
            <TextField
              fullWidth
              placeholder="Vin / Last6"
              value={vinSearchText}
              onChange={props.onVinSearchTextChange}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  setOpenVinSearch(false);
                  props.onVinSearch();
                }
              }}
            />
          </ModalGeneric>
        </>
      )}
      {showHomeLinks && (
        <>
          <Hidden xsDown>
            <Button
              onClick={() => {
                hashHistory.push("/");
              }}
            >
              Home
            </Button>
            <Button
              onClick={() => {
                hashHistory.push("/");
              }}
            >
              About Us
            </Button>
          </Hidden>

          {pageTitle !== "Login" && !loggedIn && (
            <Button
              onClick={() => {
                hashHistory.push("/login");
              }}
            >
              {!loggedIn ? "Login" : "Logout"}
            </Button>
          )}

          <Hidden smUp>
            <div>
              <IconButton
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
              >
                <MoreIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={open}
                onClose={handleClose}
              >
                <MenuItem
                  onClick={() => {
                    hashHistory.push("/");
                  }}
                >
                  Home
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    hashHistory.push("/");
                  }}
                >
                  About Us
                </MenuItem>
              </Menu>
            </div>
          </Hidden>
        </>
      )}
      <IconButton
        color="inherit"
        aria-label="open help"
        onClick={() => {
          hashHistory.push("/help");
        }}
        className={clsx(classes.menuButton)}
      >
        <HelpIcon />
      </IconButton>
    </Toolbar>
  );
}

const drawerWidth = 240;

const usePageStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexGrow: 1,
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  title: {
    flexGrow: 1,
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
}));

export { PageWithAppDrawer3 as default};