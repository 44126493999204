import React, { useState, useEffect } from "react";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useDispatch } from "react-redux";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import {
  TextField,
} from "@material-ui/core";
import { utilityService } from "../../_services";

export default function ModalUpdateMileage(props) {
  // const dispatch = useDispatch();
  const [open, setOpen] = useState(props.open || false);
  const [item, setItem] = useState(props.item || null);
  const [mileage, setMileage] = useState(null);
  const [vin, setVin] = useState(null);

  useEffect(() => {
    setOpen(props.open);
  }, [props.open]);

  useEffect(() => {
    setItem(props.item);
    if (props.item) {
      setMileage(props.item.mileage);
      setVin(props.item.vehiclevin);
    }
  }, [props.item]);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));

  const handleClose = () => {
    setOpen(false);
    if (props.onClose) props.onClose();
  };

  const handleUpdate = async () => {
    await utilityService.updateUtility("vehicles", item.id, { mileage });
    setOpen(false);
    handleClose();
  };

  return (
    <Dialog open={open} fullScreen={fullScreen}>
      <DialogTitle id="form-dialog-title" style={{ width: 600 }}>
        Update Mileage for {vin}
      </DialogTitle>
      <DialogContent>
        <TextField
          margin="dense"
          name={"mileage"}
          label={"Mileage"}
          type="number"
          value={mileage || ""}
          onChange={(event) => setMileage(event.target.value)}
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleUpdate} color="primary">
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
}
