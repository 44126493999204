import React, { useState, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Link, Snackbar } from "@material-ui/core";
import { connect, useDispatch, useSelector } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import moment from "moment-timezone";

import { getEnvironmentURL, authHeader } from "../_helpers";

import { isEmpty } from "../_helpers";

import { utilityActions } from "../_actions";
import { utilityService } from "../_services";

import { PageWithAppDrawer2 } from "../_components/main/PageWithAppDrawer2";
import VehicleDetailComponent from "../_components/vehicle/VehicleDetailComponent";
import VehicleDetailImages from "../_components/vehicle/VehicleDetailImages";
import VehicleDetailAddNote from "../_components/vehicle/VehicleDetailAddNote";
import BayIntegration from "../_components/vehicle/BayIntegration";

import ModalGeneric from "../_components/generic/ModalGeneric";

import {
  Button,
  Typography,
  TextField,
  Backdrop,
  CircularProgress,
} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import MuiAlert from "@material-ui/lab/Alert";
import Alert from "@material-ui/lab/Alert";
import AlertRiNewVehicleType from "../_components/vehicle/AlertRiNewVehicleType";
import ModalEditGst from "../_components/vehicle/ModalEditGst";

class VehicleDetailsPage extends React.Component {
  constructor(props) {
    super(props);
    const { webUser } = this.props;
    const { vehicleid: vehicleId } = this.props.match
      ? this.props.match.params
      : {};
    this.state = {
      vehicleId,
      isNew: false,
      isAdmin: webUser.roleid === 2 || webUser.roleid === 11 ? true : false,
      isClient: webUser.roleid !== 2 ? true : false,
      clientId: webUser.client_id,
      modalRequiredFields: false,
      modalCancel: false,
      cancelReason: "",
      modalImages: false,
      modalGst: false,
      modalNote: false,
      modalBay: false,
      newNote: "",
      reload: false,
      saving: false,
      snackbar: {
        open: false,
        severity: "info", //error, warning, info, success
        message: "default message",
        duration: 6000,
      },
      openBackdrop: false,
      imagesView: "active", // or 'deleted'
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { vehicleid: vehicleId } = nextProps.match
      ? nextProps.match.params
      : {};

    if (vehicleId !== prevState.vehicleId) {
      return {
        ...prevState,
        vehicleId,
        reload: true,
      };
    }
    return false;
  }

  componentDidMount() {
    const { dispatch } = this.props;
    const { vehicleid: vehicleId } = this.props.match
      ? this.props.match.params
      : {};
    const { items: title_statuses } = this.props.utility.title_statuses;
    const { items: title_application_types } =
      this.props.utility.title_application_types;
    const { items: auctions } = this.props.utility.auctions;
    const { items: clients } = this.props.utility.clients_active;

    this.setState({ vehicleId });
    const options = {
      relationsAdd: ["dot_timers", "policy_number"],
    };
    dispatch(
      utilityActions.getOneUtility("vehicles", vehicleId, options, (values) => {
        const { cancelledreason } = values;
        this.setState({ cancelledreason: cancelledreason });
      })
    );

    if (auctions.length === 0) {
      this.props.dispatch(
        utilityActions.getUtility("auctions", {
          perPage: 1000,
          orders: [{ field: "auctionname", direction: "asc" }],
        })
      );
    }
    if (title_statuses.length === 0) {
      this.props.dispatch(
        utilityActions.getUtility("title_statuses", { perPage: 1000 })
      );
    }
    if (title_application_types.length === 0) {
      this.props.dispatch(
        utilityActions.getUtility("title_application_types", {
          perPage: 1000,
          orders: [
            {
              field: "titleapplicationtype",
              direction: "asc",
            },
          ],
        })
      );
    }
    if (clients.length === 0) {
      this.props.dispatch(
        utilityActions.getUtility("clients", {
          perPage: 1000,
          // filters: [
          //   {
          //     field: "clientactive",
          //     operator: "eq",
          //     value: "2"
          //   }
          // ]
          orders: [{ field: "clientname", direction: "asc" }],
        })
      );
    }
  }

  render() {
    const {
      reload,
      modalRequiredFields,
      modalImages,
      modalGst,
      modalNote,
      modalCancel,
      modalBay,
      cancelReason,
      // newNote,
      // isAdmin,
      isClient,
      // cliendId,
      // saving,
      snackbar,
      openBackdrop,
      imagesView,
    } = this.state;
    const { classes, utility } = this.props;
    const { vehicles_item: vehicle } = utility || {};
    const loading = vehicle ? vehicle.loading : true;

    const hasImages =
      vehicle && vehicle.images && vehicle.images.length > 0 ? true : false;

    if (reload === true) {
      this.setState({ reload: false }, () => {
        this.componentDidMount();
      });
      return <></>;
    }

    return (
      <PageWithAppDrawer2 pageTitle={"Vehicle Details"}>
        <Snackbar
          color={snackbar.severity}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          open={snackbar.open}
          autoHideDuration={snackbar.duration}
          onClose={() =>
            this.setState((prevState, props) => ({
              snackbar: {
                ...prevState.snackbar,
                open: false,
              },
            }))
          }
        >
          <MuiAlert severity={snackbar.severity} variant="filled">
            {snackbar.message}
          </MuiAlert>
        </Snackbar>
        <Button
          variant="outlined"
          size="small"
          onClick={this.props.history.goBack}
        >
          Back
        </Button>
        <br />
        {vehicle &&
        vehicle.client &&
        vehicle.client.is_ri &&
        vehicle.client.ri_reg_docs_complete === 0 ? (
          <div>
            <br />
            <Alert severity="error" variant="filled">
              RI Client has not completed registration documents
            </Alert>
          </div>
        ) : (
          <></>
        )}
        {vehicle && vehicle.is_ri_new_vehicle ? (
          <div>
            <br />
            <AlertRiNewVehicleType
              vehicleInfo={{
                id: vehicle.vehicleid,
                yr: vehicle.yr,
                make: vehicle.make,
                model: vehicle.model,
              }}
              onChange={() =>
                this.handleRiNewVehicleTypeChange(vehicle.vehicleid)
              }
            />
          </div>
        ) : (
          <></>
        )}
        {vehicle && Number(vehicle.mileage) < 1000 ? (
          <div>
            <br />
            <Alert severity="warning" variant="filled">
              Mileage less than 1,000 mi
            </Alert>
          </div>
        ) : (
          <></>
        )}

        <br />
        {loading ? (
          <>
            <div>Loading...</div>
            <p>
              <RouterLink to="/login">Logout</RouterLink>
            </p>
          </>
        ) : (
          <>
            {isClient && (
              <>
                <Button
                  onClick={this.handleCancel}
                  variant="contained"
                  style={{ backgroundColor: "#ff0000" }}
                >
                  Cancel Vehicle
                </Button>{" "}
              </>
            )}
            <Button
              onClick={this.handleSave}
              variant="contained"
              color="primary"
            >
              Update Vehicle
            </Button>{" "}
            <Button
              onClick={() => {
                this.setState({ modalImages: true });
              }}
              variant="contained"
              disabled={!hasImages}
              color="primary"
            >
              {hasImages ? "View Files/Images" : "No Files/Images"}
            </Button>{" "}
            <Button
              // onClick={() => {
              //   console.log("upload files");
              // }}
              variant="contained"
              color="primary"
              component="label"
            >
              Upload File
              <input
                id={`upload_file`}
                type="file"
                name={`file`}
                style={{ display: "none" }}
                onChange={this.handleFileUpload}
              />
            </Button>{" "}
            <Button
              onClick={() => {
                this.setState({ modalNote: true });
              }}
              variant="contained"
              color="primary"
            >
              Add Note
            </Button>{" "}
            <Button
              component={RouterLink}
              to={`/photoupload/${vehicle.vehicleid}`}
              variant="contained"
              color="primary"
            >
              &gt; Photo Upload Page
            </Button>{" "}
            <Button
              component={RouterLink}
              to={`/vehicles/${vehicle.vehicleid}/conformance`}
              variant="contained"
              color="primary"
            >
              Conformance Report
            </Button>{" "}
            <Button
              component={RouterLink}
              to={`/vehicles/${vehicle.vehicleid}/vehicleIVR`}
              variant="contained"
              color="primary"
            >
              NHTSA IVR
            </Button>{" "}
            {vehicle?.client?.defaults?.client?.lotvision_domain && (
              <>
                <Button
                  color="primary"
                  variant="contained"
                  href={`https://${vehicle?.client?.defaults?.client?.lotvision_domain?.value}/vehicles?q=${vehicle.vehiclevin}`}
                  target="_blank"
                >
                  Lot Vision
                </Button>{" "}
              </>
            )}
            <Button
              variant="contained"
              color="primary"
              onClick={() => this.setState({ modalGst: true })}
            >
              GST/HST
            </Button>{" "}
            <VehicleDetailComponent
              loading={loading}
              item={vehicle}
              utility={this.props.utility}
              onUpdateLocal={this.handleUpdateLocal}
              onUpdateLocalTires={this.handleUpdateLocalTires}
              // onSave={this.handleSave}
            />
          </>
        )}
        <ModalEditGst
          open={modalGst}
          vehicleId={vehicle?.vehicleid}
          clientId={vehicle?.clientid}
          onClose={() => this.setState({ modalGst: false })}
          onCancel={() => this.setState({ modalGst: false })}
          
        />
        <ModalGeneric
          open={modalRequiredFields}
          title={"Unable to save, required fields"}
          closeText={"Return to editing"}
          onClose={() => {
            this.setState({ modalRequiredFields: false });
          }}
        >
          <b>Client ID</b> and <b>Title Application Type</b> are required fields
          <br />
          <br />
          If the vehicle is <b>CANCELLED</b>, a reason must be supplied
          <br />
          <br />
          If <b>CONFORMING</b> is required (RI), <b>DOT Date</b> and{" "}
          <b>Bond Release Date</b> must be supplied
        </ModalGeneric>
        <ModalGeneric
          open={modalNote}
          title={"Add Note"}
          closeText={"Cancel"}
          onClose={() => {
            this.setState({ modalNote: false });
          }}
        >
          <VehicleDetailAddNote
            item={vehicle}
            vin={vehicle ? vehicle.vehiclevin : ""}
            onClose={(note) => {
              this.handleSaveNote(note);
              this.setState({ modalNote: false });
            }}
          />
        </ModalGeneric>
        <ModalGeneric
          open={modalImages}
          title={imagesView === "active" ? "Vehicle Images" : "Deleted Images"}
          closeText="Close"
          onClose={() => {
            this.setState({ modalImages: false });
          }}
        >
          <VehicleImagesModalContent
            vehicleId={vehicle && vehicle.vehicleid}
            displayType={imagesView === "active" ? "active" : "deleted"}
            images={vehicle && vehicle.images}
            imagesDeleted={vehicle && vehicle.images_deleted}
            onSave={this.handleSave}
          />
        </ModalGeneric>
        <ModalGeneric
          open={modalCancel}
          title={"Cancel this vehicle?"}
          closeText="Cancel this vehicle"
          cancelText="Back to Editing"
          onClose={() => {
            this.setState({ modalCancel: false });
            this.handleUpdateLocal(
              {
                titlestatusid: 5,
                cancelledreason: cancelReason,
                cancelled: 1,
              },
              true
            ); // true, to save after update
          }}
          onCancel={() => {
            this.setState({ modalCancel: false });
          }}
        >
          <Paper className={classes.paper}>
            <Typography variant="h6">
              Are you sure you want to cancel this vehicle?
              <br />
              {vehicle && vehicle.vehiclevin}
              <br />
              <TextField
                margin="dense"
                name={"cancelledreason"}
                label={"Cancel Reason"}
                type="text"
                helperText="Provide a reason for Cancel"
                value={cancelReason}
                onChange={(event) =>
                  this.setState({ cancelReason: event.target.value })
                }
                fullWidth
              />
            </Typography>
          </Paper>
        </ModalGeneric>
        <ModalGeneric
          open={modalBay}
          title={"Bay Brokerage Integration"}
          closeText="Close"
          onClose={() => {
            this.setState({ modalBay: false });
          }}
        >
          <BayIntegration item={vehicle} />
        </ModalGeneric>
        <Backdrop open={openBackdrop} style={{ zIndex: 10000, color: "white" }}>
          <CircularProgress />
          Updating
        </Backdrop>
      </PageWithAppDrawer2>
    );
  }

  handleRiNewVehicleTypeChange = (vehicleId) => {
    const { dispatch } = this.props;

    const options = {
      relationsAdd: ["dot_timers", "policy_number"],
    };
    dispatch(
      utilityActions.getOneUtility("vehicles", vehicleId, options, (values) => {
        const { cancelledreason } = values;
        this.setState({ cancelledreason: cancelledreason });
      })
    );
  };

  handleFileUpload = (event) => {
    const { vehicleId } = this.state;
    event.preventDefault();

    const file = event.target.files[0];

    let formData = new FormData();
    formData.append("file", file);
    formData.append("vehicleId", vehicleId);
    formData.append("note", file.name);
    formData.append("type", 0);

    fetch(`${getEnvironmentURL()}/api/vehicles/${vehicleId}/images`, {
      method: "POST",
      headers: { ...authHeader() },
      body: formData,
    })
      .then(
        (response) => response.json() // if the response is a JSON object
      )
      .then(
        //TODO:save also in customer object
        (success) => {
          this._showMessage("File Uploaded", "success");
          // console.log(success); // Handle the success response object
        }
      )
      .catch((error) => {
        this._showMessage("Unable to upload file: " + error, "error");
        // console.log(error); // Handle the error response object
      });
  };

  handleUpdateLocal = async (data, doSave = false) => {
    const { dispatch } = this.props;
    await dispatch(utilityActions.updateOneUtilityLocal("vehicles", data));
    if (doSave === true) {
      this.handleSave();
    }
  };

  handleUpdateLocalTires = async (data, doSave = false) => {
    const { dispatch } = this.props;
    await dispatch(utilityActions.updateOneUtilityLocalTires("vehicles", data));
    if (doSave === true) {
      this.handleSave();
    }
  };

  handleCancel = () => {
    this.setState({
      modalCancel: true,
    });
  };

  handleSave = () => {
    const isValid = this.checkFields();
    if (!isValid) {
      this.setState({
        modalRequiredFields: true,
      });
      return;
    }

    this.setState({
      saving: true,
      openBackdrop: true,
    });

    const { dispatch } = this.props;
    const { utility } = this.props;
    let { vehicles_item: vehicle } = utility;
    delete vehicle.loading;

    dispatch(
      utilityActions.updateUtility(
        "vehicles",
        vehicle.id,
        vehicle,
        (response) => {
          this.setState({
            saving: false,
            openBackdrop: false,
          });
          if (response && response.vehicleid) {
            this._showMessage("Vehicle Updated", "success");
          } else {
            this._showMessage("Unable to update: " + response, "error");
          }
        }
      )
    );
  };

  handleSaveNote = (note) => {
    const { utility, webUser } = this.props;
    const { vehicles_item: vehicle } = utility;
    utilityService
      .addUtility("vehicle_notes", {
        notedate: moment()
          .tz("America/Indiana/Indianapolis")
          .format("YYYY-MM-DD h:mm:ss"),
        vehicleid: vehicle.vehicleid,
        userid: webUser.userid,
        roleid: webUser.roleid,
        complete: 0,
        note,
      })
      .then((response) => {
        this._showMessage("Note Saved", "success");
        this.componentDidMount();
      })
      .catch((error) => {
        this._showMessage("Unable to save note", "error");
      });
  };

  checkFields = () => {
    const { utility } = this.props;
    const { vehicles_item: vehicle } = utility || {};
    let errors = [];

    if (vehicle.titlestatusid === 5) {
      if (isEmpty(vehicle.cancelledreason)) {
        errors.push("cancelledreason");
      }
    } else {
      if (isEmpty(vehicle.clientid)) errors.push("clientid");
      if (isEmpty(vehicle.titleprocessingtype)) {
        errors.push("titleprocessingtype");
      }
      if (vehicle.titlestatusid === 6) {
        // this is a completed state
        const titleapp = utility.title_application_types.items.find(
          (item) => item.id == vehicle.titleprocessingtype
        );
        if (titleapp) {
          if (titleapp.has_conforming_requirement === true) {
            if (isEmpty(vehicle.submitteddotdate)) {
              errors.push("conforming check, submitteddotdate");
            }
            if (isEmpty(vehicle.bondreleasedate)) {
              errors.push("conforming check, bondreleasedate");
            }
          }
        }
      }
    }

    if (errors.length > 0) {
      console.log("errors", errors);
      return false;
    }
    return true;
  };

  _showMessage = (message, severity = "info", duration = 6000) => {
    this.setState({
      snackbar: {
        open: true,
        severity,
        message,
        duration,
      },
    });
  };
}

const styles = (theme) => ({
  root: {
    display: "flex",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "left",
    color: theme.palette.text.primary,
  },
});

function VehicleImagesModalContent(props) {
  const dispatch = useDispatch();
  const vehicleImageTypes = useSelector(
    (state) => state.utility.vehicle_image_types
  );
  const [displayType, setDisplayType] = useState(props.displayType);
  const [vehicleId, setVehicleId] = useState(props.vehicleId);
  const [images, setImages] = useState(props.images);
  const [imagesDeleted, setImagesDeleted] = useState(props.imagesDeleted);

  useEffect(() => {
    if (vehicleImageTypes.items.length === 0) {
      dispatch(
        utilityActions.getUtility("vehicle_image_types", {
          perPage: 1000,
          orders: [
            {
              field: "display_order",
              direction: "asc",
            },
          ],
        })
      );
    }
  }, []);

  useEffect(() => {
    setDisplayType(props.displayType);
    setVehicleId(props.vehicleId);
    setImages(props.images);
    setImagesDeleted(props.imagesDeleted);
  }, [props]);

  return (
    <Paper>
      <Button
        color="primary"
        size="small"
        variant="contained"
        component={Link}
        disabled={displayType === "deleted"}
        href={`${getEnvironmentURL()}/api/vehicles/${vehicleId}/images`}
      >
        Download All Images
      </Button>{" "}
      <Button
        color="primary"
        size="small"
        variant="contained"
        component={Link}
        disabled={displayType === "deleted"}
        href={`${getEnvironmentURL()}/api/vehicles/${vehicleId}/imagesNhtsa`}
      >
        NHTSA Images
      </Button>{" "}
      <Button
        color="primary"
        size="small"
        variant="contained"
        onClick={() => {
          setDisplayType((displayType) => {
            return displayType === "active" ? "deleted" : "active";
          });
        }}
      >
        {displayType === "active"
          ? "Show Deleted Images"
          : "Show Active Images"}
      </Button>
      {displayType === "active" ? (
        <VehicleDetailImages items={images || []} onSave={props.onSave} />
      ) : (
        <VehicleDetailImages items={imagesDeleted || []} />
      )}
    </Paper>
  );
}

function mapStateToProps(state) {
  const { vehicles, authentication, auctions, utility } = state;
  const { user, webUser } = authentication;
  return {
    user,
    webUser,
    vehicles,
    auctions,
    utility,
  };
}

const connectedVehicleDetailsPage = connect(mapStateToProps)(
  withStyles(styles)(VehicleDetailsPage)
);
export { connectedVehicleDetailsPage as VehicleDetailsPage };
