import {
  makeStyles,
  Paper,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import FiltersGrid from "../_components/generic/FiltersGrid";
import ResponsiveGrid from "../_components/generic/ResponsiveGrid";
import LaunchTwoToneIcon from "@material-ui/icons/LaunchTwoTone";

import { PageWithAppDrawer2 } from "../_components/main/PageWithAppDrawer2";
import { utilityService } from "../_services";
import { downloadBlob, generateCSV } from "../_helpers";

export default function RiNeedsAppliedPage(props) {
  const classes = useStyles();
  const clients = useSelector((state) => state.utility.clients_active_ri);

  const [loading, setLoading] = useState(false);
  const [clientId, setClientId] = useState(null);
  const [client, setClient] = useState(null);
  const [tabIndex, setTabIndex] = useState(0);

  const [data1, setData1] = useState([]);

  const [availableFilters, setAvailableFilters] = useState([
    {
      name: "RI Client",
      field: "clientid",
      operator: "eq",
      value:
        props.defaultFilters && props.defaultFilters.clientid
          ? props.defaultFilters.clientid
          : "",
      type: "choice",
      useUtility: true,
      utility: "clients_active_ri",
      utilityValue: "clientname",
      utilityOrder: {
        field: "clientname",
        direction: "asc",
      },
      utilityFilters: [
        {
          field: "clientactive",
          operator: "eq",
          value: 2,
        },
        {
          field: "is_ri",
          operator: "eq",
          value: 1,
        },
      ],
      // choices: [],
      default: "",
    },
  ]);

  const handleFilterChange = async (newFilter) => {
    const newFilters = availableFilters.map((filter) => {
      if (filter.field !== newFilter.field) return filter; // not the droid we're looking for, return unchanged
      filter.value = newFilter.value;
      if (filter.name === "RI Client") {
        setClientId(Number(filter.value));
        setClient(
          clients.items.find(
            (client) => Number(client.clientid) === Number(filter.value)
          )
        );
      }
      return filter;
    });
    await setAvailableFilters(newFilters);
  };

  const getData = (download = false) => {
    setLoading(true);
    if (!clientId) {
      console.log("no client");
      return;
    }

    if (download) {
      utilityService
        .getUtility("vehicles_base", {
          perPage: 1000,
          relations: ["document_signatures"],
          filters: [
            {
              field: "titlestatusid",
              operator: "neq",
              value: "5",
            },
            {
              field: "titlestatusid",
              operator: "neq",
              value: "5",
            },
            {
              field: "clientid",
              operator: "eq",
              value: clientId,
            },
            {
              field: "titleregownershipdate",
              operator: "notnull",
              value: null,
            },
            {
              field: "submitteddotdate",
              operator: "notnull",
              value: null,
            },
            {
              field: "titlesenttobmvdate",
              operator: "isnull",
              value: null,
            },
          ],
          params: "appends[]=has_signed_inspection",
        })
        .then((values) => {
          const downloadHeaders = columns1
            .filter((item) => item.title)
            .map((item) => {
              if (!item.title) return null;
              return item.title;
            });
          const downloadFields = columns1
            .filter((item) => item.field)
            .map((item) => {
              if (!item.field) return null;
              return item.field;
            });

          return generateCSV(downloadHeaders, downloadFields, values.data);
        })
        .then((csv) => {
          const blob = new Blob([csv], { type: "text/csv" });
          downloadBlob(blob, `ri_needs_applied.csv`);
        })
        .catch(console.error)
        .finally(() => {
          setLoading(false);
        });
      return;
    }

    utilityService
      .getUtility("vehicles_base", {
        perPage: 1000,
        relations: ["document_signatures"],
        filters: [
          {
            field: "titlestatusid",
            operator: "neq",
            value: "5",
          },
          {
            field: "titlestatusid",
            operator: "neq",
            value: "5",
          },
          {
            field: "clientid",
            operator: "eq",
            value: clientId,
          },
          {
            field: "titleregownershipdate",
            operator: "notnull",
            value: null,
          },
          {
            field: "submitteddotdate",
            operator: "notnull",
            value: null,
          },
          {
            field: "titlesenttobmvdate",
            operator: "isnull",
            value: null,
          },
        ],
        params: "appends[]=has_signed_inspection",
        orders: [
          {
            field: "submitteddotdate",
            direction: "desc",
          },
        ],
      })
      .then((result) => {
        setData1(result.data);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (!clientId) {
      return;
    }
    if (clientId > 0) {
      getData();
    }
  }, [clientId]);

  return (
    <PageWithAppDrawer2 pageTitle="RI Needs Applied">
      <Paper className={classes.paper}>
        <FiltersGrid
          availableFilters={availableFilters}
          onChange={handleFilterChange}
        />
        {client && (
          <Typography>Selected Client: {client.clientname}</Typography>
        )}
        {client && (
          <>
            <Tabs value={tabIndex} onChange={(e, index) => setTabIndex(index)}>
              <Tab label={`Needs Applied (${data1 && data1.length})`} />
            </Tabs>
            <Typography component="div" role="tabpanel" hidden={tabIndex !== 0}>
              <ResponsiveGrid
                loading={loading}
                data={data1}
                columns={columns1}
                usePagination={false}
                onDownload={() => getData(true)}
                showActions={true}
              />
            </Typography>
          </>
        )}
      </Paper>
    </PageWithAppDrawer2>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
}));

const columns1 = [
  {
    width: 2,
    title: "VIN",
    field: "vehiclevin",
    dataRender: (item) => (
      <Link target="_blank" to={`/vehicles/${item.vehicleid}`}>
        {item.vehiclevin}{" "}
        <LaunchTwoToneIcon style={{ fontSize: 12 }} color="primary" />
      </Link>
    ),
  },
  {
    width: 2,
    title: "Onwership Date",
    field: "titleregownershipdate",
  },
  {
    width: 2,
    title: "DOT Submitted Date",
    field: "submitteddotdate",
  },
  {
    width: 2,
    title: "Sent to BMV Date",
    field: "titlesenttobmvdate",
  },
  {
    width: 4,
    title: "Has VIN Inspection Signature",
    field: "has_signed_inspection",
    dataRender: (item) => (item.has_signed_inspection ? "Signed" : ""),
  },
];
