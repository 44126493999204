import React, { useState, useEffect, Fragment } from "react";
import { Link as RouterLink } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { utilityActions } from "../_actions";

import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";

import {
  Typography,
  CircularProgress,
  Button,
  Divider,
  Select,
  makeStyles,
} from "@material-ui/core";
import { utilityService } from "../_services";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import BorderColorTwoToneIcon from "@material-ui/icons/BorderColorTwoTone";
import appStoreBadge from "../_assets/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg";
import { isEmpty } from "lodash";
import PageWithAppDrawer3 from "../_components/main/PageWithAppDrawer3";

export function DashboardPage(props) {
  const classes = useDashboardPageStyles();

  const webUser = useSelector((state) => state.authentication.webUser);
  const user = useSelector((state) => state.authentication.user);

  const clientGroup = webUser.client_group || null;
  const clientsList = clientGroup?.client_ids || [];

  return (
    <PageWithAppDrawer3 pageTitle={"Dashboard"}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Paper className={classes.paper}>
            <div>
              <Button
                component={RouterLink}
                to="/user/signature"
                startIcon={<BorderColorTwoToneIcon />}
                variant="contained"
                color="primary"
              >
                Update my signature
              </Button>
            </div>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper className={classes.paper}>
            <div>
              <Typography variant="h6">Apple iPhone</Typography>
              <a href="https://apps.apple.com/us/app/dvs-ri/id1539317802">
                <img src={appStoreBadge} height="48" alt="store badge" />{" "}
              </a>
            </div>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <Typography variant="h6">{user.name}</Typography>
            <br />
            {user.email}
            <br />
            {webUser.client && (
              <span>
                <b>Client Primary:</b>
                {webUser.client.clientname}
              </span>
            )}
            <br />
            {clientGroup && (
              <>
                <span>
                  <b>Client Group: </b>
                  {clientGroup.name}
                </span>
                {clientGroup?.clients_base.map((group) => (
                  <div key={group.clientid}>{group.clientname}</div>
                ))}
                <br />
              </>
            )}
            {webUser.roleid === 2 && (
              <DashboardWidgetTable
                isAdmin={true}
                clientId={null}
                canChangeClient={true}
              />
            )}
            {webUser.roleid !== 2 && webUser.client && (
              <DashboardWidgetTable
                clientId={clientsList}
                canChangeClient={true}
              />
            )}
          </Paper>
        </Grid>
      </Grid>
    </PageWithAppDrawer3>
  );
}

function DashboardWidgetTable(props) {
  const dispatch = useDispatch();
  const clients_active = useSelector((state) => state.utility.clients_active);
  const isAdmin = props.isAdmin;

  const [data7, setData7] = useState(null);
  const [data30, setData30] = useState(null);
  const [data90, setData90] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [clientId, setClientId] = useState(props.clientId || null);
  const [client, setClient] = useState(props.client || null);
  const canChangeClient = props.canChangeClient || false;
  const [showRi, setShowRi] = useState(true);

  let gettingData = false;

  useEffect(() => {
    if (clients_active.items.length === 0) {
      dispatch(
        utilityActions.getUtility("clients_active", {
          perPage: 1000,
          filters: [
            {
              field: "clientactive",
              operator: "eq",
              value: "2",
            },
            !isAdmin && {
              field: "clientid",
              operator: "in",
              value: clientId,
            },
          ],
          orders: [{ field: "clientname", direction: "asc" }],
        })
      );
    }
    getNewData();
  }, []);

  useEffect(() => {
    getNewData();
  }, [clientId]);

  useEffect(() => {
    if (data7 && data30 && data90) {
      setIsLoading(false);
    } else {
      setIsLoading(true);
    }
  }, [data7, data30, data90]);

  const useClient = isEmpty(clientId) ? "" : `&clientId=${clientId}`;

  const getNewData = async () => {
    console.log("getNewData");
    if (gettingData === true) {
      return;
    }
    gettingData = true;
    await utilityService
      .getEndpoint("api/reports/management/dashboard", "GET", {
        params: `days=thisweek${useClient}`,
      })
      .then((response) => setData7(response));
    await utilityService
      .getEndpoint("api/reports/management/dashboard", "GET", {
        params: `days=thismonth${useClient}`,
      })
      .then((response) => setData30(response));
    await utilityService
      .getEndpoint("api/reports/management/dashboard", "GET", {
        params: `days=90${useClient}`,
      })
      .then((response) => setData90(response));
    gettingData = false;
  };

  const metricList = [
    { field: "total", name: "Vehicles Received" },
    { field: "cancelled", name: "Cancelled Vehicles" },
    { field: "in_state", name: "In State" },
    { field: "out_of_state", name: "Out of State" },
    { field: "registered_import", name: "Registered Import", ri: true },
    { field: "vault", name: "Vault", ri: true },
  ];
  const metricListDetailed = [
    { field: "total", name: "Vehicles Received" },
    { field: "speedoverificationneeded", name: "Speedo Verification Needed" },
    {
      field: "speedoverificationcomplete",
      name: "Speedo Verification Complete",
    },
    { field: "assignedtotransport", name: "Assigned to Transport", ri: true },
    { field: "submittedtocustomsdate", name: "Submitted to Customs", ri: true },
    { field: "crossedborderdate", name: "Crossed Border", ri: true },
    { field: "submitteddotdate", name: "Submitted to DOT", ri: true },
    { field: "titleregownershipdate", name: "Ownerships Received" },
    { field: "titlesenttobmvdate", name: "Titles Applied For" },
    { field: "state_white_copy_date", name: "White Copies Received" },
    { field: "bondreleasedate", name: "Bond Released", ri: true },
    { field: "titlesenttoauctiondate", name: "Title Sent to Customer" },
  ];

  return (
    <>
      {isLoading && <CircularProgress />}
      {!isLoading && (
        <>
          <Typography variant="h5">Dashboard</Typography>
          {canChangeClient && (
            <Select
              native
              name={"clientId"}
              value={clientId || ""}
              onChange={(e) => setClientId(e.target.value)}
              style={{ fontSize: 12 }}
              displayEmpty
            >
              <option value="">Select Client</option>
              {clients_active &&
                clients_active.items.map((client, index) => (
                  <option key={index} value={client.clientid}>
                    {client.clientname}
                  </option>
                ))}
            </Select>
          )}

          {/* False, because nothing works for this right now */}
          {false && canChangeClient && (
            <>
              <TableContainer>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell component="th">RI Static Metrics</TableCell>
                      <TableCell component="th" align="right">
                        Count
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        Missing Ownership, has Crossed
                      </TableCell>
                      <TableCell align="right">
                        <RouterLink
                          to={
                            `/dashboard/drill/needsownership` +
                            (clientId ? `/${clientId}` : "")
                          }
                        >
                          {data7.riStatic.titleregownershipdate_null}
                        </RouterLink>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        Missing Bond Release, has DOT Submission
                      </TableCell>
                      <TableCell align="right">
                        <RouterLink
                          to={
                            `/dashboard/drill/needsbondrelease` +
                            (clientId ? `/${clientId}` : "")
                          }
                        >
                          {data7.riStatic.bondreleasedate_null}
                        </RouterLink>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
              <Divider />
            </>
          )}

          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell component="th">Generic</TableCell>
                  <TableCell component="th" align="right">
                    This week (Sunday)
                  </TableCell>
                  <TableCell component="th" align="right">
                    This Month
                  </TableCell>
                  <TableCell component="th" align="right">
                    90 days
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {metricList.map((item, index) => {
                  if (item.ri && !showRi) return null;
                  return (
                    <TableRow key={index}>
                      <TableCell component="th" scope="row">
                        {item.name}
                      </TableCell>
                      <TableCell align="right">
                        {data7.totals[item.field] || "0"}
                      </TableCell>
                      <TableCell align="right">
                        {data30.totals[item.field] || "0"}
                      </TableCell>
                      <TableCell align="right">
                        {data90.totals[item.field] || "0"}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <Divider />
          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell component="th">Detailed</TableCell>
                  <TableCell component="th" align="right">
                    This week (Sunday)
                  </TableCell>
                  <TableCell component="th" align="right">
                    This Month
                  </TableCell>
                  <TableCell component="th" align="right">
                    90 days
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {metricListDetailed.map((item, index) => {
                  if (item.ri && !showRi) return null;
                  return (
                    <TableRow key={index}>
                      <TableCell component="th" scope="row">
                        {item.name}
                      </TableCell>
                      <TableCell align="right">
                        {data7.statuses[item.field] || "0"}
                      </TableCell>
                      <TableCell align="right">
                        {data30.statuses[item.field] || "0"}
                      </TableCell>
                      <TableCell align="right">
                        {data90.statuses[item.field] || "0"}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </>
  );
}

const useDashboardPageStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
}));

export { DashboardPage as default };
