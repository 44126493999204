import React from "react";
import { connect } from "react-redux";

import { userActions, alertActions } from "../_actions";
import { getEnvironment } from "../_helpers";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

import PictureAsPdfTwoToneIcon from "@material-ui/icons/PictureAsPdfTwoTone";

import { PageWithAppDrawer2 } from "../_components/main/PageWithAppDrawer2";

class RegistrationDocumentsPage extends React.Component {
  constructor(props) {
    super(props);

    // reset login status
    // this.props.dispatch(userActions.logout());

    const env = getEnvironment("reset");

    this.state = {};
  }

  componentDidMount() {
    const { dispatch } = this.props;
    const { environmentDVS } = this.state;
    dispatch(userActions.setEnvironment(environmentDVS));
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(alertActions.clear());
  }

  handleClearAlert = () => {
    const { dispatch } = this.props;
    dispatch(alertActions.clear());
  };

  render() {
    const { classes } = this.props;

    return (
      <PageWithAppDrawer2
        pageTitle={"Registration Documents"}
        showSideBar={true}
        showHomeLinks={true}
      >
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <div className={classes.paper}>
            <Typography variant="h5">Registration Documents</Typography>
            <Typography style={{ marginTop: 20 }}>
              There are documents that must be completed before DVS can fully
              service our RI customers. Below is a list of documents that are
              typically required.
            </Typography>
            <List component="nav">
              <ListItem
                button
                component="a"
                target="_blank"
                href="https://dvs-asc-public.s3.amazonaws.com/forms/DVS+Power+of+Attornery.pdf"
              >
                <ListItemIcon>
                  <PictureAsPdfTwoToneIcon color="primary" />
                </ListItemIcon>
                <ListItemText primary="Power of Attornery (DVS)" />
              </ListItem>
              <ListItem
                button
                component="a"
                target="_blank"
                href="https://dvs-asc-public.s3.amazonaws.com/forms/POWER+OF+ATTORNEY+FOR+CUSTOMS+AND+EXPORT+FORWARDING+AGENT.pdf"
              >
                <ListItemIcon>
                  <PictureAsPdfTwoToneIcon color="primary" />
                </ListItemIcon>
                <ListItemText primary="Power of Attorney (Customs and Export)" />
              </ListItem>
              <ListItem
                button
                component="a"
                target="_blank"
                href="https://dvs-asc-public.s3.amazonaws.com/forms/CbpForm5106.pdf"
              >
                <ListItemIcon>
                  <PictureAsPdfTwoToneIcon color="primary" />
                </ListItemIcon>
                <ListItemText primary="Importer Identify Form - CBP Form 5106" />
              </ListItem>
              <ListItem
                button
                component="a"
                target="_blank"
                href="https://dvs-asc-public.s3.amazonaws.com/forms/DVS+Customer+Info.pdf"
              >
                <ListItemIcon>
                  <PictureAsPdfTwoToneIcon color="primary" />
                </ListItemIcon>
                <ListItemText primary="Customer Information Document" />
              </ListItem>
            </List>
          </div>
        </Container>
      </PageWithAppDrawer2>
    );
  }
}

const styles = (theme) => ({
  "@global": {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
});

function mapStateToProps(state) {
  const { alert } = state;
  const { loggingIn, environmentDVS } = state.authentication;
  return {
    alert,
    loggingIn,
    environmentDVS,
  };
}

const connectedRegistrationDocumentsPage = connect(mapStateToProps)(
  withStyles(styles)(RegistrationDocumentsPage)
);
export { connectedRegistrationDocumentsPage as RegistrationDocumentsPage };
