import React, { Fragment, useEffect, useState } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import moment from "moment";
import { BlobProvider } from "@react-pdf/renderer";

import { utilityActions } from "../_actions";

import { TitlesSentSheet } from "../_components/pdf/TitlesSentSheet";

import { PageWithAppDrawer2 } from "../_components/main/PageWithAppDrawer2";
import FiltersGrid from "../_components/generic/FiltersGrid";
import ModalGeneric from "../_components/generic/ModalGeneric";

import {
  Paper,
  Divider,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Link,
  TableHead,
  Snackbar,
  Button,
} from "@material-ui/core";

import { Alert } from "@material-ui/lab";

import { utilityService } from "../_services";
import { getEnvironmentURL } from "../_helpers";

class TitlesSentPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {}

  render() {
    const { classes } = this.props;
    return (
      <PageWithAppDrawer2 pageTitle={"Titles Sent"}>
        <Paper className={classes.paper}>
          <TitlesSent />
        </Paper>
      </PageWithAppDrawer2>
    );
  }
}

function TitlesSent(props) {
  const dispatch = useDispatch();
  const titles_sent = useSelector((state) => state.utility.titles_sent);
  // const clients_active = useSelector((state) => state.utility.clients_active);

  let date = new Date();
  const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
  const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

  const [getData, setGetData] = useState(false);
  const [start, setStart] = useState(moment(firstDay).format("YYYY-MM-DD"));
  const [end, setEnd] = useState(moment(lastDay).format("YYYY-MM-DD"));
  const [openModal, setOpenModal] = useState(false);
  const [data, setData] = useState(null);
  // const [bayData, setBayData] = useState(null);

  const [pageMeta, setPageMeta] = useState({
    date: null,
    client: null,
  });
  const [title, setTitle] = useState("Title");
  const [download, setDownload] = useState(false);
  const [send, setSend] = useState(false);
  const [email, setEmail] = useState(null);

  const [snackOpen, setSnackOpen] = useState(false);
  const [snackText, setSnackText] = useState("");

  const [availableFilters, setAvailableFilters] = useState([
    {
      name: "Start Date",
      field: "sent_to_auction_date",
      operator: ">=",
      value: moment(firstDay).format("YYYY-MM-DD"),
      // value: "2020-07-01",
      type: "date",
      default: "",
    },
    {
      name: "End Date",
      field: "sent_to_auction_date",
      operator: "<=",
      value: moment(lastDay).format("YYYY-MM-DD"),
      type: "date",
      default: "",
    },
    {
      name: "Client",
      field: "client_id",
      operator: "eq",
      value: "",
      type: "choice",
      useUtility: true,
      utility: "clients_active",
      utilityKey: "clientid",
      utilityValue: "clientname",
      utilityFilters: [
        {
          field: "clientactive",
          operator: "eq",
          value: 2,
        },
      ],
      utilityOrder: {
        field: "clientname",
        direction: "asc",
      },
      choices: [],
      default: "",
    },
  ]);

  useEffect(() => {
    if (getData === true) {
      setGetData(false);
      getNewData();
    }
  }, [getData]);

  useEffect(() => {
    getNewData();
  }, []);

  const getNewData = async () => {
    if (!start || !end) {
      return;
    }

    const filters = availableFilters.filter((filter) => {
      return filter.value ? true : false;
    });

    const options = {
      filters,
      orders: [
        {
          field: "sent_to_auction_date",
          direction: "desc",
        },
        {
          field: "client_name",
          direction: "asc",
        },
        {
          field: "auction_name",
          direction: "asc",
        },
      ],
      perPage: 1000,
      page: 1,
    };

    dispatch(utilityActions.getUtility("titles_sent", options));
  };

  const handleFilterChange = (newFilter) => {
    const newFilters = availableFilters.map((filter) => {
      if (filter.field !== newFilter.field) return filter; // not the droid we're looking for, return unchanged
      if (filter.name !== newFilter.name) return filter; // not the droid we're looking for, return unchanged

      if (filter.field === "start") setStart(newFilter.value);
      if (filter.field === "end") setEnd(newFilter.value);
      filter.value = newFilter.value;
      return filter;
    });
    setAvailableFilters(newFilters);
    setGetData(true);
  };

  const generateClientData = (client) => {
    let newData = [];
    client.auctions.map((auction) => {
      auction.data.map((item) => {
        newData.push(item);
        return null;
      });
      return null;
    });

    setData(newData);
    setOpenModal(true);
  };

  const generateAuctionData = (auction) => {
    let newData = [];
    auction.data.map((item) => {
      newData.push(item);
      return null;
    });

    setData(newData);
    setOpenModal(true);
  };

  return (
    <>
      <Snackbar
        open={snackOpen}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={() => setSnackOpen(false)}
      >
        <Alert onClose={() => setSnackOpen(false)} severity="success">
          {snackText}
        </Alert>
      </Snackbar>
      <FiltersGrid
        availableFilters={availableFilters}
        onChange={handleFilterChange}
      />
      <Divider style={{ marginTop: 10, marginBottom: 50 }} />
      {titles_sent.items && titles_sent.items.length > 0 ? (
        <Table size="small">
          <TableBody>
            {titles_sent.items.map((date, index) => (
              <Fragment key={index}>
                <TableRow style={{ backgroundColor: "black" }}>
                  <TableCell style={{ color: "white", fontWeight: "bold" }}>
                    {date.name || "foo"} ({date.count} titles)
                  </TableCell>
                </TableRow>
                {date.clients.map((client, index2) => (
                  <Fragment key={index2}>
                    <TableRow style={{ backgroundColor: "lightgrey" }}>
                      <TableCell>
                        <Link
                          style={{ color: "black" }}
                          onClick={() => {
                            setTitle(date.name + " - " + client.name);
                            setPageMeta({
                              date: date.name,
                              client: client.name,
                            });
                            generateClientData(client);
                          }}
                        >
                          {client.name} ({client.count} titles)
                        </Link>
                      </TableCell>
                    </TableRow>
                    {client.auctions.map((auction, index3) => (
                      <Fragment key={index3}>
                        <TableRow>
                          <TableCell style={{ paddingLeft: 40 }}>
                            <Link
                              style={{ color: "black" }}
                              onClick={() => {
                                setTitle(
                                  date.name +
                                    " - " +
                                    client.name +
                                    " - " +
                                    auction.name
                                );
                                setPageMeta({
                                  date: date.name,
                                  client: client.name,
                                });
                                generateAuctionData(auction);
                              }}
                            >
                              {auction.name} ({auction.count} titles)
                            </Link>
                          </TableCell>
                        </TableRow>
                      </Fragment>
                    ))}
                  </Fragment>
                ))}
              </Fragment>
            ))}
          </TableBody>
        </Table>
      ) : (
        "No Data"
      )}
      <ModalGeneric
        open={openModal}
        title={title}
        closeText={"Print Titles Sent"}
        onClose={() => {
          setOpenModal(false);
          setDownload(true);
        }}
        cancelText={"Cancel"}
        onCancel={() => {
          setOpenModal(false);
          setData(null);
        }}
      >
        {data && data.length > 0 && (
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Overnight Tracking #</TableCell>
                <TableCell>Auction</TableCell>
                <TableCell>VIN</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((item, index) => (
                <TableRow key={index}>
                  <TableCell>{item.overnight_tracking_number}</TableCell>
                  <TableCell>{item.auction_name}</TableCell>
                  <TableCell>{item.vin}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
        <form
          id="bay-form"
          method="post"
          action={
            getEnvironmentURL() + "/api/reports/management/bay/recallWarranty"
          }
          target="_blank"
        >
          {data &&
            data.map((v, index) => (
              <input
                key={index}
                type="hidden"
                name="vehicles[]"
                value={v.vehicle_id}
              />
            ))}
        </form>
        <Button
          form="bay-form"
          type="submit"
          size="small"
          variant="contained"
          color="primary"
          disabled={!data || (data && data.length === 0)}
        >
          Bay Recall Warranties
        </Button>
      </ModalGeneric>
      {send && (
        <BlobProvider
          document={<TitlesSentSheet items={data} pageMeta={pageMeta} />}
        >
          {({ blob, url, loading, error }) => {
            if (!loading && url) {
              console.log("data", data);
              var reader = new FileReader();
              reader.readAsDataURL(blob);
              reader.onloadend = function () {
                let base64data = reader.result;
                console.log("base64data", base64data);
                utilityService
                  .getEndpoint(`api/titles_sent/send`, "post", {
                    body: {
                      email,
                      docBase64: base64data,
                    },
                  })
                  .then((response) => {
                    setSend(false);
                    setEmail(null);
                    setOpenModal(false);
                    setSnackText(response.message);
                    setSnackOpen(true);
                    return null;
                  })
                  .catch((e) => {
                    setSnackText(e.message);
                    setSend(false);
                  });
                return null;
              };
            }
            return null;
          }}
        </BlobProvider>
      )}
      {download && (
        <BlobProvider
          document={<TitlesSentSheet items={data} pageMeta={pageMeta} />}
        >
          {({ blob, url, loading, error }) => {
            if (!loading && url) {
              window.open(url, "_blank");
              setDownload(false);
              return null;
            }
            return null;
          }}
        </BlobProvider>
      )}
    </>
  );
}

const styles = (theme) => ({
  root: {
    display: "flex",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
});

function mapStateToProps(state) {
  const { authentication, utility } = state;
  const { user } = authentication;
  const { us_states, auctions } = utility;
  return {
    user,
    auctions,
    us_states,
  };
}

const connectedTitlesSentPage = connect(mapStateToProps)(
  withStyles(styles)(TitlesSentPage)
);
export { connectedTitlesSentPage as TitlesSentPage };
