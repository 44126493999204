import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Font,
  Image,
} from "@react-pdf/renderer";

import logo from "../../_assets/dvslogo_web_new_cropped.png";

import { formatNumberToStringMoney } from "../../_helpers";

const debug = false;

// https://medium.com/investing-in-tech/creating-api-driven-pdfs-on-the-fly-in-the-browser-using-react-react-pdf-and-typescript-78ab4d1e7da5
// https://react-pdf.org/advanced#on-the-fly-rendering

Font.register({
  family: "Oswald",
  src: "https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf",
});

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
    fontFamily: "Helvetica",
  },
  bold: {
    fontFamily: "Helvetica-Bold",
  },
  title: {
    fontSize: 24,
    textAlign: "center",
    // fontFamily: "Oswald",
  },
  author: {
    fontSize: 12,
    textAlign: "center",
    // marginBottom: 40,
  },
  subtitle: {
    fontSize: 18,
    margin: 12,
    // fontFamily: "Oswald",
  },
  text: {
    // margin: 12,
    fontSize: 12,
    textAlign: "left",
    // fontFamily: "Times-Roman",
  },
  image: {
    marginVertical: 15,
    marginHorizontal: 100,
  },
  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: "center",
    color: "grey",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
  viewCustomerInfo: {
    border: "1px solid black",
  },
  hr: {
    borderStyle: "solid",
    borderTopColor: "black",
    borderTopWidth: 1,
  },
});

export function InvoiceSummaryDocument(props) {
  return (
    <Document>
      <InvoiceSummaryPage>
        <InvoiceSummaryHeader meta={{ start: props.start, end: props.end }} />
        <InvoiceSummaryData items={props.data} />
        <FooterPageNumber />
      </InvoiceSummaryPage>
    </Document>
  );
}

function InvoiceSummaryPage(props) {
  return <Page style={styles.body}>{props.children}</Page>;
}

function InvoiceSummaryHeader(props) {
  return (
    <View>
      <View style={{ alignItems: "center" }} debug={debug}>
        <Image
          style={[styles.image, { width: "20%" }]}
          src={logo}
          debug={debug}
        />
      </View>
      <View style={{ flexDirection: "row" }} debug={debug}>
        <View style={{ width: "50%" }} debug={debug}>
          <Text style={styles.author} debug={debug}>
            1919b S Post Rd
          </Text>
          <Text style={styles.author} debug={debug}>
            Indianapolis, IN 46239
          </Text>
        </View>
        <View style={{ width: "50%" }} debug={debug}>
          <Text style={styles.author} debug={debug}>
            Tel: (317)862-7626
          </Text>
          <Text style={styles.author} debug={debug}>
            Fax: (317)862-7680
          </Text>
        </View>
      </View>

      <Line />

      <View style={{ width: "100%" }} debug={debug}>
        <Text style={styles.author}>Invoice Summary</Text>
        <Text style={styles.author}>
          {props.meta.start} to {props.meta.end}
        </Text>
      </View>

      <Line />
    </View>
  );
}

function InvoiceSummaryData(props) {
  return (
    <View debug={debug}>
      {props.items.map((client, index) => (
        <View key={index} debug={debug}>
          <View style={{ flexDirection: "row" }}>
            <View
              style={{ width: "100%", backgroundColor: "black", padding: 5 }}
            >
              <Text style={[styles.text, { color: "white" }]}>
                {client.name}
              </Text>
            </View>
          </View>
          {client.dates.map((date, index2) => {
            let totalForDay = 0;
            return (
              <View key={index2} debug={debug}>
                <View style={{ flexDirection: "row" }} key={index2}>
                  <View
                    style={{
                      width: "100%",
                      backgroundColor: "lightgrey",
                      padding: 5,
                    }}
                  >
                    <Text style={[styles.text]}>{date.name}</Text>
                  </View>
                </View>
                <InvoiceDataHeader />
                {date.data.map((data, index3) => {
                  totalForDay += Number(data.total_cost);
                  return <InvoiceDataItem item={data} key={index3} />;
                })}
                <InvoiceDataFooter total={totalForDay} />
              </View>
            );
          })}
        </View>
      ))}
    </View>
  );
}

function InvoiceDataItem(props) {
  const item = props.item;
  return (
    <View style={{ flexDirection: "row" }}>
      <View
        style={{
          width: "20%",
          padding: 5,
        }}
      >
        <Text style={[styles.text]}>{item.invoice_detail_id}</Text>
      </View>
      <View
        style={{
          width: "20%",
          padding: 5,
        }}
      >
        <Text style={[styles.text]}>{item.invoice_batch_id}</Text>
      </View>
      <View
        style={{
          width: "40%",
          padding: 5,
        }}
      >
        <Text style={[styles.text]}>{item.serial_number}</Text>
      </View>
      <View
        style={{
          width: "20%",
          padding: 5,
        }}
      >
        <Text style={[styles.text]}>
          {formatNumberToStringMoney(item.total_cost)}
        </Text>
      </View>
    </View>
  );
}

function InvoiceDataHeader(props) {
  return (
    <View>
      <View style={{ flexDirection: "row" }}>
        <View
          style={{
            width: "20%",
            padding: 5,
          }}
        >
          <Text style={[styles.text, styles.bold]}>Invoice #</Text>
        </View>
        <View
          style={{
            width: "20%",
            padding: 5,
          }}
        >
          <Text style={[styles.text, styles.bold]}>Batch #</Text>
        </View>
        <View
          style={{
            width: "40%",
            padding: 5,
          }}
        >
          <Text style={[styles.text, styles.bold]}>VIN</Text>
        </View>
        <View
          style={{
            width: "20%",
            padding: 5,
          }}
        >
          <Text style={[styles.text, styles.bold]}>Invoice Total</Text>
        </View>
      </View>
    </View>
  );
}

function InvoiceDataFooter(props) {
  return (
    <View>
      <View style={{ flexDirection: "row" }}>
        <View
          style={{
            width: "60%",
            padding: 5,
          }}
        ></View>
        <View
          style={{
            width: "20%",
            padding: 5,
          }}
        >
          <Text style={[styles.text, styles.bold]}>Date Total:</Text>
        </View>
        <View
          style={{
            width: "20%",
            padding: 5,
          }}
        >
          <Text style={[styles.text, styles.bold]}>
            {formatNumberToStringMoney(props.total)}
          </Text>
        </View>
      </View>
    </View>
  );
}

function FooterPageNumber() {
  return (
    <Text
      style={styles.pageNumber}
      render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
      fixed
    />
  );
}

function Line() {
  return <View style={[styles.hr, { marginTop: 10, marginBottom: 10 }]} />;
}
