import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  Typography,
  Button,
  TextField,
  Grid,
  Select,
  CircularProgress,
} from "@material-ui/core";
import { utilityActions } from "../../_actions";
import ModalGeneric from "../generic/ModalGeneric";
// import _ from "lodash";

export default function ClientDefaults(props) {
  const dispatch = useDispatch();
  // const client = useSelector((state) => state.utility.clients_item);
  const clients_active = useSelector((state) => state.utility.clients_active);
  const client_agents = useSelector((state) => state.utility.client_agents);
  const client_defaults = useSelector((state) => state.utility.client_defaults);
  const auctions = useSelector((state) => state.utility.auctions);
  const titleStatuses = useSelector((state) => state.utility.title_statuses);
  const titleApplicationTypes = useSelector(
    (state) => state.utility.title_application_types
  );
  // const [defaultsObject, setDefaultsObject] = useState(
  //   props.defaultsObject || {}
  // );
  // const [clientId, setClientId] = useState(props.clientId || null);
  const [modalAddAgent, setModalAddAgent] = useState(false);
  const [agentName, setAgentName] = useState("");

  const [speed, setSpeed] = useState(false);
  const [auctionId, setAuctionId] = useState(null);
  const [billToClient, setBillToClient] = useState(null);
  const [purchaseClient, setPurchaseClient] = useState(null);
  const [titleAppType, setTitleAppType] = useState(null);
  const [titleStatus, setTitleStatus] = useState(null);
  const [invoiceVehicleDetails, setInvoiceVehicleDetails] = useState(false);
  const [invoiceVehicleExtraInfo, setInvoiceVehicleExtraInfo] = useState("");
  const [vehicleReleaseDays, setVehicleReleaseDays] = useState(21);
  const [nhtsaAutoLocation, setNhtsaAutoLocation] = useState(false);
  const [nhtsaAutoDate, setNhtsaAutoDate] = useState(false);
  const [signatureBlue, setSignatureBlue] = useState(false);
  const [titleStatusAdditional, setTitleStatusAdditional] = useState(false);

  useEffect(() => {
    let d_title_status_additional =
      client_defaults.items.find(
        (item) => item.field === "title_status_additional"
      ) || false;
    let d_signature_blue =
      client_defaults.items.find((item) => item.field === "signature_blue") ||
      false;
    let d_nhtsa_auto_location = client_defaults.items.find(
      (item) => item.field === "nhtsa_auto_location"
    );
    let d_nhtsa_auto_date = client_defaults.items.find(
      (item) => item.field === "nhtsa_auto_date"
    );
    let d_auctionid = client_defaults.items.find(
      (item) => item.field === "auctionid"
    );
    let d_titlestatus = client_defaults.items.find(
      (item) => item.field === "titlestatus"
    );
    let d_titleprocessingtype = client_defaults.items.find(
      (item) => item.field === "titleprocessingtype"
    );
    let d_speedyesno =
      client_defaults.items.find((item) => item.field === "speedyesno") ||
      false;
    let d_billtoclientid = client_defaults.items.find(
      (item) => item.field === "billtoclientid"
    );
    let d_purchaseclientid = client_defaults.items.find(
      (item) => item.field === "purchaseclientid"
    );
    let d_invoice_vehicle_details =
      client_defaults.items.find(
        (item) => item.field === "invoice_vehicle_details"
      ) || false;
    let d_invoice_vehicle_extra_info =
      client_defaults.items.find(
        (item) => item.field === "invoice_vehicle_extra_info"
      ) || "";
    let d_vehicle_release_days =
      client_defaults.items.find(
        (item) => item.field === "vehicle_release_days"
      ) || 21;

    if (d_title_status_additional?.value)
      d_title_status_additional = d_title_status_additional.value;
    else d_title_status_additional = false;

    if (d_signature_blue?.value) d_signature_blue = d_signature_blue.value;
    else d_signature_blue = false;

    if (d_nhtsa_auto_location)
      d_nhtsa_auto_location = d_nhtsa_auto_location.value;
    if (d_nhtsa_auto_date) d_nhtsa_auto_date = d_nhtsa_auto_date.value;
    if (d_auctionid) d_auctionid = d_auctionid.value;
    if (d_titlestatus) d_titlestatus = d_titlestatus.value;
    if (d_titleprocessingtype)
      d_titleprocessingtype = d_titleprocessingtype.value;
    if (d_speedyesno?.value) d_speedyesno = d_speedyesno.value;
    else d_speedyesno = false;
    if (d_billtoclientid) d_billtoclientid = d_billtoclientid.value;
    if (d_purchaseclientid) d_purchaseclientid = d_purchaseclientid.value;
    if (d_invoice_vehicle_details)
      d_invoice_vehicle_details = d_invoice_vehicle_details.value;
    if (d_invoice_vehicle_extra_info)
      d_invoice_vehicle_extra_info = d_invoice_vehicle_extra_info.value;
    if (d_vehicle_release_days)
      d_vehicle_release_days = d_vehicle_release_days.value;

    setNhtsaAutoLocation(d_nhtsa_auto_location);
    setNhtsaAutoDate(d_nhtsa_auto_date);
    setAuctionId(d_auctionid);
    setTitleStatus(d_titlestatus);
    setTitleAppType(d_titleprocessingtype);
    setSpeed(d_speedyesno);
    setBillToClient(d_billtoclientid);
    setPurchaseClient(d_purchaseclientid);
    setInvoiceVehicleDetails(d_invoice_vehicle_details);
    setInvoiceVehicleExtraInfo(d_invoice_vehicle_extra_info);
    setVehicleReleaseDays(d_vehicle_release_days);
    setSignatureBlue(d_signature_blue);
    setTitleStatusAdditional(d_title_status_additional);
  }, [client_defaults]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "title_status_additional") {
      console.log({name, value})
      setTitleStatusAdditional(value);
    }
    if (name === "signature_blue") {
      setSignatureBlue(value);
    }
    if (name === "nhtsa_auto_location") {
      setNhtsaAutoLocation(value);
    }
    if (name === "nhtsa_auto_date") {
      setNhtsaAutoDate(value);
    }
    if (name === "auctionid") {
      setAuctionId(value);
    }
    if (name === "speedyesno") {
      setSpeed(value);
    }
    if (name === "billtoclientid") {
      setBillToClient(value);
    }
    if (name === "purchaseclientid") {
      setPurchaseClient(value);
    }
    if (name === "titleprocessingtype") {
      setTitleAppType(value);
    }
    if (name === "titlestatus") {
      setTitleStatus(value);
    }
    if (name === "invoiceVehicleDetails") {
      setInvoiceVehicleDetails(value);
    }
    if (name === "invoiceVehicleExtraInfo") {
      setInvoiceVehicleExtraInfo(value);
    }
    if (name === "vehicleReleaseDays") {
      setVehicleReleaseDays(value);
    }
  };

  const addAgent = () => {
    if (agentName.trim().length > 0) {
      dispatch(
        utilityActions.addUtility(
          "client_agents",
          { client_id: props.clientId, name: agentName },
          () => {
            getAgents();
          }
        )
      );
    }

    setModalAddAgent(false);
    setAgentName("");
  };

  useEffect(() => {
    const updateObj = {
      vehicle: {
        nhtsa_auto_location: nhtsaAutoLocation,
        nhtsa_auto_date: nhtsaAutoDate,
        auctionid: auctionId,
        speedyesno: speed,
        billtoclientid: billToClient,
        purchaseclientid: purchaseClient,
        titleprocessingtype: titleAppType,
        titlestatus: titleStatus,
        invoice_vehicle_details: invoiceVehicleDetails,
        invoice_vehicle_extra_info: invoiceVehicleExtraInfo,
        vehicle_release_days: vehicleReleaseDays,
        signature_blue: signatureBlue,
        title_status_additional: titleStatusAdditional
      },
    };
    dispatch(
      utilityActions.updateOneUtilityLocal("clients", { defaults: updateObj })
    );
    // console.log("updateObj", updateObj);
  }, [
    nhtsaAutoLocation,
    nhtsaAutoDate,
    auctionId,
    speed,
    billToClient,
    purchaseClient,
    titleAppType,
    titleStatus,
    invoiceVehicleDetails,
    invoiceVehicleExtraInfo,
    vehicleReleaseDays,
    signatureBlue,
    titleStatusAdditional
  ]);

  const getAgents = () => {
    dispatch(
      utilityActions.getUtility("client_agents", {
        perPage: 1000,
        filters: [
          {
            field: "client_id",
            operator: "eq",
            value: props.clientId,
          },
        ],
        orders: [
          {
            field: "name",
            direction: "asc",
          },
        ],
      })
    );
  };

  const getDefaults = () => {
    dispatch(
      utilityActions.getUtility("client_defaults", {
        perPage: 1000,
        filters: [
          {
            field: "client_id",
            operator: "eq",
            value: props.clientId,
          },
        ],
      })
    );
  };

  useEffect(() => {
    if (client_agents.items.length === 0) {
      getAgents();
    }
    getDefaults();
  }, []);

  useEffect(() => {
    console.log("client_defaults", client_defaults);
  }, [client_defaults]);

  return (
    <>
      <Grid container>
        <Grid item xs={4}>
          Title Status Additional Options (e.g. Problem Details)
        </Grid>
        <Grid item xs={8}>
          <Select
            native
            name="title_status_additional"
            value={titleStatusAdditional}
            onChange={handleChange}
            style={{ fontSize: 12 }}
          >
            <option value={false}>No</option>
            <option value={true}>Yes</option>
          </Select>
        </Grid>
        <Grid item xs={4}>
          Signature in Blue
        </Grid>
        <Grid item xs={8}>
          <Select
            native
            name="signature_blue"
            value={signatureBlue}
            onChange={handleChange}
            style={{ fontSize: 12 }}
          >
            <option value={false}>No</option>
            <option value={true}>Yes</option>
          </Select>
        </Grid>
        <Grid item xs={4}>
          NHTSA Autopopulate Location
        </Grid>
        <Grid item xs={8}>
          <Select
            native
            name="nhtsa_auto_location"
            value={nhtsaAutoLocation}
            onChange={handleChange}
            style={{ fontSize: 12 }}
          >
            <option value={false}>No</option>
            <option value={true}>Yes</option>
          </Select>
        </Grid>
        <Grid item xs={4}>
          NHTSA Autopopulate Date
        </Grid>
        <Grid item xs={8}>
          <Select
            native
            name="nhtsa_auto_date"
            value={nhtsaAutoDate}
            onChange={handleChange}
            style={{ fontSize: 12 }}
          >
            <option value={false}>No</option>
            <option value={true}>Yes</option>
          </Select>
        </Grid>
        <Grid item xs={4}>
          Is Speed?
        </Grid>
        <Grid item xs={8}>
          <Select
            native
            name="speedyesno"
            value={speed}
            onChange={handleChange}
            style={{ fontSize: 12 }}
          >
            <option value={false}>No</option>
            <option value={true}>Yes</option>
          </Select>
        </Grid>
        <Grid item xs={4}>
          Auction
        </Grid>
        <Grid item xs={8}>
          <Select
            native
            name="auctionid"
            value={auctionId || ""}
            onChange={handleChange}
            style={{ fontSize: 12 }}
          >
            <option value="">Select Auction</option>
            {auctions &&
              auctions.items.map((auction, index) => (
                <option key={index} value={auction.id}>
                  {auction.auctionname}
                </option>
              ))}
          </Select>
        </Grid>
        <Grid item xs={4}>
          Title To Client
        </Grid>
        <Grid item xs={8}>
          <Select
            native
            name="billtoclientid"
            value={billToClient || ""}
            onChange={handleChange}
            style={{ fontSize: 12 }}
          >
            <option value="">Select Title To Client</option>
            {clients_active &&
              clients_active.items.map((client, index) => (
                <option key={index} value={client.clientid}>
                  {client.clientname}
                </option>
              ))}
          </Select>
        </Grid>
        <Grid item xs={4}>
          Purchasing Client
        </Grid>
        <Grid item xs={8}>
          <Select
            native
            name="purchaseclientid"
            value={purchaseClient || ""}
            onChange={handleChange}
            style={{ fontSize: 12 }}
          >
            <option value="">Select Purchasing Client</option>
            {clients_active &&
              clients_active.items.map((client, index) => (
                <option key={index} value={client.clientid}>
                  {client.clientname}
                </option>
              ))}
          </Select>
        </Grid>
        <Grid item xs={4}>
          Title Application Type
        </Grid>
        <Grid item xs={8}>
          <Select
            native
            name="titleprocessingtype"
            value={titleAppType || ""}
            onChange={handleChange}
            style={{ fontSize: 12 }}
          >
            <option value="">Select Title Application Type</option>
            {titleApplicationTypes &&
              titleApplicationTypes.items.map((appType, index) => (
                <option key={index} value={appType.id}>
                  {appType.titleapplicationtype}
                </option>
              ))}
          </Select>
        </Grid>
        <Grid item xs={4}>
          Title Status
        </Grid>
        <Grid item xs={8}>
          <Select
            native
            name="titlestatus"
            value={titleStatus || ""}
            onChange={handleChange}
            style={{ fontSize: 12 }}
          >
            <option value="">Select Title Status</option>
            {titleStatuses &&
              titleStatuses.items.map((statusType, index) => (
                <option key={index} value={statusType.id}>
                  {statusType.statusname}
                </option>
              ))}
          </Select>
        </Grid>
        <Grid item xs={4}>
          Invoice: include vehicle details
        </Grid>
        <Grid item xs={8}>
          <Select
            native
            name="invoiceVehicleDetails"
            value={invoiceVehicleDetails}
            onChange={handleChange}
            style={{ fontSize: 12 }}
          >
            <option value={false}>No</option>
            <option value={true}>Yes</option>
          </Select>
        </Grid>
        <Grid item xs={4}>
          Invoice: Extra Info per Vehicle (text)
        </Grid>
        <Grid item xs={8}>
          <TextField
            fullWidth
            style={{ fontSize: 12 }}
            placeholder="Extra text to be show with vehicle"
            value={invoiceVehicleExtraInfo}
            name="invoiceVehicleExtraInfo"
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={4}>
          Vehicle Release Days
        </Grid>
        <Grid item xs={8}>
          <TextField
            fullWidth
            type="number"
            style={{ fontSize: 12 }}
            placeholder="Days from DOT Submission"
            value={vehicleReleaseDays || 100}
            name="vehicleReleaseDays"
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={4}>
          Client Agents
          <br />
          <Button
            color="primary"
            variant="contained"
            size="small"
            onClick={() => setModalAddAgent(true)}
          >
            Add Client Agent
          </Button>
        </Grid>
        <Grid item xs={8}>
          {client_agents.loading && <CircularProgress />}
          {!client_agents.loading &&
            client_agents.items.map((item, index) => (
              <Typography key={index}>{item.name}</Typography>
            ))}
        </Grid>
      </Grid>
      <ModalGeneric
        open={modalAddAgent}
        onClose={addAgent}
        onCancel={() => {
          setModalAddAgent(false);
          setAgentName("");
        }}
        closeText={"Add Agent"}
        cancelText="Cancel"
        title={"Add Client Agent"}
      >
        <TextField
          placeholder="Agent Name"
          name="name"
          value={agentName}
          onChange={(e) => setAgentName(e.target.value)}
        />
      </ModalGeneric>
    </>
  );
}

const columns = [
  {
    width: 1,
    title: "ID",
    field: "id",
  },
  {
    width: 2,
    title: "Table",
    field: "table",
  },
  {
    width: 2,
    title: "Field",
    field: "field",
  },
  {
    width: 5,
    title: "Value",
    field: "value",
    dataRender: (item) =>
      item.type === "boolean"
        ? item.value === false
          ? "False"
          : "True"
        : item.value,
  },
  //   {
  //     width: 2,
  //     title: "Remove",
  //     field: "id",
  //     dataRender: (item) => (
  //       <Button variant="contained" color="primary" size="small">
  //         Remove
  //       </Button>
  //     ),
  //   },
];
