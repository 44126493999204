import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";

import { withStyles } from "@material-ui/core/styles";
import withWidth from "@material-ui/core/withWidth";

import { PageWithAppDrawer2 } from "../_components/main/PageWithAppDrawer2";
import { Paper, Grid, Typography } from "@material-ui/core";

class MobileInstructionsPage extends React.Component {
  constructor(props) {
    super(props);
    const { type: mobileType } = this.props.match
      ? this.props.match.params
      : {};
    this.state = {
      mobileType,
      reload: false,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { type: mobileType } = nextProps.match ? nextProps.match.params : {};
    if (mobileType !== prevState.mobileType) {
      return {
        ...prevState,
        mobileType,
        reload: true,
      };
    }
    return false;
  }

  async componentDidMount() {}

  render() {
    const { mobileType, reload } = this.state;
    const { classes } = this.props;

    if (reload === true) {
      this.setState({ reload: false }, () => {
        this.componentDidMount();
      });
      return <></>;
    }

    return (
      <PageWithAppDrawer2 pageTitle={`Mobile Instructions`}>
        <Paper className={classes.paper}>
          <MobileInstructions mobileType={mobileType} />
        </Paper>
      </PageWithAppDrawer2>
    );
  }
}

function MobileInstructions(props) {
  const mobileType = props.mobileType;

  return (
    <Grid container>
      <Grid item xs={12} sm={6}>
        <Typography variant="h6">How to install on iPhone</Typography>
        The first time the app is installed you need to trust the developer
        certificate before running the app
        <ul>
          <li>Go to "Settings"</li>
          <li>Click on General</li>
          <li>Go to Profiles and Devices Management</li>
          <li>Choose the developer under "Enterprise".</li>
          <li>Trust the developer</li>
          <li>Choose Trust</li>
        </ul>
      </Grid>

      <Grid item xs={12} sm={6}>
        <Typography variant="h6">How to install on Android</Typography>
        There are many different types of Android devices
        <br />
        Performing a Google search for{" "}
        <a
          href="https://www.google.com/search?q=android+install+unknown+apps"
          target="_blank"
        >
          Android install uknown apps
        </a>{" "}
        will be instructive
        <br />
        <br />
        On a Google Pixel device:
        <ul>
          <li>Go to "Settings"</li>
          <li>Search settings for "uknown"</li>
          <li>Go to "Install unknown apps</li>
          <li>Select "Install unknown apps"</li>
          <li>Select "Chrome"</li>
          <li>Check to "Allow from this source"</li>
        </ul>
      </Grid>
    </Grid>
  );
}

const styles = (theme) => ({
  root: {
    display: "flex",
    fontSize: 12,
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    width: "100%",
  },
});

function mapStateToProps(state) {
  const { authentication } = state;
  const { user } = authentication;
  return {
    user,
  };
}

const connectedMobileInstructionsPage = connect(mapStateToProps)(
  withWidth()(withStyles(styles)(MobileInstructionsPage))
);
export { connectedMobileInstructionsPage as MobileInstructionsPage };
