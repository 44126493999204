import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  // Font,
  Image,
} from "@react-pdf/renderer";

import moment from "moment";
import {
  formatNumberToStringMoney,
  formatNumberWithCommas,
} from "../../_helpers";

// const debug = false;

// https://medium.com/investing-in-tech/creating-api-driven-pdfs-on-the-fly-in-the-browser-using-react-react-pdf-and-typescript-78ab4d1e7da5
// https://react-pdf.org/advanced#on-the-fly-rendering

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
    fontFamily: "Helvetica",
  },
  bold: {
    fontFamily: "Helvetica-Bold",
  },
  italic: {
    fontFamily: "Helvetica-Oblique",
  },
  text: {
    fontSize: 10,
    textAlign: "left",
    // fontFamily: "Times-Roman",
  },
  textSmall: {
    fontSize: 8,
    textAlign: "left",
    // fontFamily: "Times-Roman",
  },
  image: {
    marginVertical: 15,
    marginHorizontal: 100,
  },
  header: {
    fontSize: 10,
    marginBottom: 20,
    textAlign: "center",
    color: "grey",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 10,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
  hr: {
    borderStyle: "solid",
    borderTopColor: "black",
    borderTopWidth: 1,
  },
});

export function ClientLoadSheet(props) {
  console.log("pdf props", props);
  const i = props.item;
  const loadType = i.load_type || "multiple";
  console.log("loadType", loadType);
  return (
    <Document>
      <Page style={styles.body} size="letter">
        <View fixed>
          <View style={{ flexDirection: "row" }}>
            <View
              style={{ width: "50%" }}
              // debug={true}
            >
              {i.client.has_logo ? (
                <Image
                  style={[{ width: "100%" }]}
                  src={i.client.logo_url}
                  // debug={true}
                />
              ) : (
                <Text style={styles.text}>{i.client.clientname}</Text>
              )}
            </View>
            <View style={{ width: "50%", textAlign: "center" }}>
              <Text>VEHICLE MOTOR PURCHASE PRICE INVOICE</Text>
            </View>
          </View>
          <View style={[styles.text, { textAlign: "right", marginTop: 20 }]}>
            <Text>Date: {moment().format("MMMM Do, YYYY")}</Text>
            <Text>
              Invoice # {i.name || <Text style={{ color: "red" }}>??</Text>}
            </Text>
          </View>
        </View>

        <View style={[styles.text, { flexDirection: "row", marginTop: 20 }]}>
          <View style={{ width: "50%", flexDirection: "row" }}>
            <View style={{ width: "20%" }}>
              <Text>From:</Text>
            </View>
            <Address item={i.from || {}} style={[{ width: "80%" }]} />
          </View>
          <View style={{ width: "50%", flexDirection: "row" }}>
            <View style={{ width: "20%" }}>
              <Text>To:</Text>
            </View>
            <Address item={i.to || {}} style={[{ width: "80%" }]} />
          </View>
        </View>

        <View style={[styles.hr, { marginTop: 5, marginBottom: 5 }]} />

        <View style={styles.text}>
          <View style={{ flexDirection: "row" }}>
            <View style={[styles.bold, { width: "20%" }]}>
              <Text>Transporter:</Text>
            </View>
            <View style={{ width: "80%" }}>
              <Text>{i.transporter && i.transporter.name}</Text>
            </View>
          </View>
          <View style={{ flexDirection: "row" }}>
            <View style={[styles.bold, { width: "20%" }]}>
              <Text>Load # / Port:</Text>
            </View>
            <View style={{ width: "80%" }}>
              <Text>
                {i.name || "??"}
                {" / "}
                {i.port || "??"}
              </Text>
            </View>
          </View>
          <View style={{ flexDirection: "row" }}>
            <View style={[styles.bold, { width: "20%" }]}>
              <Text>Pick Up Location:</Text>
            </View>
            <View style={{ width: "80%" }}>
              <Text>
                {i.pickup &&
                  `${i.pickup.street}, ${i.pickup.city}, ${i.pickup.state} ${i.pickup.zip}`}
              </Text>
            </View>
          </View>
          <View style={{ flexDirection: "row" }}>
            <View style={[styles.bold, { width: "20%" }]}>
              <Text>Date of Departure:</Text>
            </View>
            <View style={{ width: "80%" }}>
              <Text>
                {i.date_departure &&
                  moment(i.date_departure).format("MMMM Do, YYYY")}
              </Text>
            </View>
          </View>
        </View>

        <VehicleHeader
          style={[styles.text, styles.bold, { marginTop: 20, marginBottom: 5 }]}
          items={["Year", "Make/Model/VIN", "Mileage"]}
          
        />
        {i.vehicles.map((vehicle, index) => (
          <View key={index}>
            <VehicleDetail
              item={vehicle}
              style={[styles.textSmall, { marginBottom: 5 }]}
              rowStyle={[]}
              textStyle={[]}
            />
            {i.load_type === "single" && (
              <VehicleDetailSingle
                item={vehicle}
                style={[styles.textSmall, { marginBottom: 5 }]}
                rowStyle={[]}
                textStyle={[]}
              />
            )}
          </View>
        ))}
        <View>
          <Text style={[styles.text, styles.italic, { textAlign: "right" }]}>
            Thank you for your business!
          </Text>
        </View>

        <View
          style={{
            position: "absolute",
            width: "50%",
            fontSize: 12,
            bottom: 100,
            left: 35,
            right: 35,
            // borderStyle: "solid",
            // borderTopColor: "black",
            // borderTopWidth: 1,
          }}
        >
          {i.client.has_signature && (
            <Image
              style={[
                {
                  width: "100%",
                  borderStyle: "solid",
                  borderBottomColor: "black",
                  borderBottomWidth: 1,
                },
              ]}
              src={i.client.signature_url}
              // debug={true}
            />
          )}
          <Text style={[styles.bold, styles.text, { marginTop: 10 }]}>
            {i.client.legal_name}
          </Text>
        </View>

        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  );
}

function Address(props) {
  const { name, street, city, state, zip, phone } = props.item;
  return (
    <View style={[...props.style]}>
      <Text>{name || "??"}</Text>
      <Text>{street || "??"}</Text>
      <Text>
        {city || "??"}, {state || "??"} {zip || "??"}
      </Text>
      <Text>{phone ? phone : ""}</Text>
    </View>
  );
}

function VehicleDetail(props) {
  const v = props.item;
  return (
    <View style={[...props.style, { flexDirection: "row" }]}>
      <View style={[...props.rowStyle, { width: "25%" }]}>
        <Text style={[...props.textStyle]}>{v.yr}</Text>
      </View>
      <View style={[...props.rowStyle, { width: "50%" }]}>
        <Text style={[...props.textStyle]}>
          {v.make} {v.model}: {v.vehiclevin}
          {Number(v.has_recall) === 1 ? (
            <Text style={{ color: "red" }}> (HAS RECALL)</Text>
          ) : (
            ""
          )}
        </Text>
      </View>
      <View style={[...props.rowStyle, { width: "25%" }]}>
        <Text style={[...props.textStyle, { textAlign: "right" }]}>
          {formatNumberWithCommas(v.mileage)}
        </Text>
      </View>
    </View>
  );
}
function VehicleDetailSingle(props) {
  const v = props.item;
  return (
    <View style={[...props.style, { flexDirection: "row" }]}>
      <View style={[...props.rowStyle, { width: "25%" }]} />
      <View style={[...props.rowStyle, { width: "50%" }]}>
        <Text style={[...props.textStyle]}>
          <Text style={[styles.bold]}>Driver:</Text>{" "}
          {v.driver ? v.driver.name : "Unknown"},{" "}
          <Text style={[styles.bold]}>Transit#:</Text>{" "}
          {v.transit_number || "-------"}
        </Text>
      </View>
      <View style={[...props.rowStyle, { width: "25%" }]} />
    </View>
  );
}

function VehicleHeader(props) {
  return (
    <View style={[...props.style, { flexDirection: "row" }]}>
      <View style={{ width: "25%" }}>
        <Text>{props.items[0] || ""}</Text>
      </View>
      <View style={{ width: "50%" }}>
        <Text>{props.items[1] || ""}</Text>
      </View>
      <View style={{ width: "25%" }}>
        <Text style={[{ textAlign: "right" }]}>{props.items[2] || ""}</Text>
      </View>
    </View>
  );
}
