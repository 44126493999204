import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { PageWithAppDrawer2 } from "../_components/main/PageWithAppDrawer2";
import Paper from "@material-ui/core/Paper";

import { Button, Grid, Typography, makeStyles } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import LaunchTwoToneIcon from "@material-ui/icons/LaunchTwoTone";
import useHasRole from "../_hooks/useHasRole";
import { utilityService } from "../_services";
import { downloadBlob, generateCSV } from "../_helpers";
import { utilityActions } from "../_actions";
import FiltersGrid from "../_components/generic/FiltersGrid";
import ResponsiveGrid from "../_components/generic/ResponsiveGrid";
import moment from "moment";

export function SvetnaReportsPage(props) {
  const classes = useReportPageStyles();

  return (
    <PageWithAppDrawer2 pageTitle={"RI Problems"}>
      <Paper className={classes.paper}>
        <SvetnaReports />
      </Paper>
    </PageWithAppDrawer2>
  );
}

export function SvetnaReports() {
  const dispatch = useDispatch();
  const classes = useReportPageStyles();
  const client = useSelector((state) => state.authentication.webUser?.client);
  //   const hasSvetnaRole = useHasRole("svetna");
  const items = useSelector((state) => state.utility.svetna_report_vehicles);

  const columns = [
    {
      width: 2,
      title: "VIN",
      field: "vehiclevin",
      dataRender: (item) => (
        <RouterLink to={`/vehicles/${item.vehicleid}`} target="_blank">
          {item.vehiclevin}{" "}
          <LaunchTwoToneIcon style={{ fontSize: 12 }} color="primary" />
        </RouterLink>
      ),
    },
    {
      width: 2,
      title: "yr/mk/model",
      field: "year_make_model",
    },
    {
      width: 2,
      title: "Registered",
      field: "recordcreateddate",
      dataRender: (item) => moment(item.recordcreateddate).format("MM/DD/YYYY"),
    },
    {
      width: 2,
      title: "Status",
      field: "title_status.statusname",
    },
    {
      width: 2,
      title: "Has Declarations",
      field: "preimport_vehicle",
      dataRender: (item) => (item.preimport_vehicle ? "Yes" : "No"),
    },
    {
      width: 2,
      title: "Purchase Price",
      field: "preimport_vehicle.details.purchase_price_before_taxes",
    },
    {
      width: 2,
      title: "MMR",
      field: "preimport_vehicle.details.purchase_mmr",
    },
    {
      width: 2,
      title: "Recall",
      field: "preimport_vehicle.details.has_recall",
      dataRender: (item) => (item.preimport_vehicle?.details?.has_recall ? "Yes" : "No"),
    },
    {
      width: 2,
      title: "Windshield",
      field: "preimport_vehicle.details.requires_windshield_replacement",
      dataRender: (item) =>
        item.preimport_vehicle?.details?.requires_windshield_replacement ? "Yes" : "No",
    },
    {
      width: 2,
      title: "Tires",
      field: "preimport_vehicle.details.requires_tires",
      dataRender: (item) => (item.preimport_vehicle?.details?.requires_tires ? "Yes" : "No"),
    },
    {
      width: 2,
      title: "Body Work",
      field: "preimport_vehicle.details.requires_body_work",
      dataRender: (item) => (item.preimport_vehicle?.details?.requires_body_work ? "Yes" : "No"),
    },
    {
      width: 2,
      title: "Body Work Details",
      field: "preimport_vehicle.details.body_work_details",
    },
    {
      width: 2,
      title: "Additional Information",
      field: "preimport_vehicle.details.additional_information",
    },
  ];

  const [availableFilters, setAvailableFilters] = useState([
    {
      name: "Start Date",
      field: "recordcreateddate",
      operator: ">=",
      value: "",
      type: "date",
      default: "",
    },
    {
      name: "End Date",
      field: "recordcreateddate",
      operator: "<=",
      value: "",
      type: "date",
      default: "",
    },
    {
      name: "VIN",
      field: "vehiclevin",
      operator: "like",
      value: "",
      type: "text",
      default: "",
    },
    {
      name: "Cancelled",
      field: "cancelled",
      operator: "eq",
      value: 0,
      type: "hidden",
      default: "",
    },
    {
      name: "Client",
      field: "clientid",
      operator: "eq",
      value: client.clientid,
      type: "hidden",
      default: "",
    },
  ]);

  const [getData, setGetData] = useState(false);

  const [page, setPage] = useState(items.page ? Number(items.page) - 1 : 0);
  const [perPage, setPerPage] = useState(
    items.per_page ? Number(items.per_page) : 50
  );
  const [total, setTotal] = useState(items.total ? Number(items.total) : 0);

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
    setGetData(true);
  };
  const handleChangeRowsPerPage = (event) => {
    const value = parseInt(event.target.value);
    setPerPage(value);
    setGetData(true);
  };
  useEffect(() => {
    if (getData === true) {
      setGetData(false);
      getNewData();
    }
  }, [getData]);

  useEffect(() => {
    setTotal(items.total ? Number(items.total) : 0);
    setPage(items.page ? Number(items.page) : 0);
    setPerPage(items.per_page ? Number(items.per_page) : 50);
  }, [items]);

  useEffect(() => {
    if (items.items.length === 0) {
      getNewData();
    }
  }, []);

  const getNewData = (download = false) => {
    let newFilters = availableFilters
      .filter((filter) => {
        if ([null, false].includes(filter)) return false;
        if (["", null].includes(filter.value)) return false;
        if (
          ["isnull", "notnull"].includes(filter.operator) &&
          filter.value === false
        )
          return false;
        return true;
      })
      .map((filter) => {
        return {
          field: filter.field,
          operator: filter.operator,
          value: filter.value,
        };
      });

    if (download) {
      let options = {
        page,
        perPage,
        filters: newFilters,
        relations: ["title_status", "preimport_vehicle"],
        appends: ["year_make_model"],
        params: "",
        orders: [{ field: "vehicleid", direction: "desc" }],
      };
      // setLoading(true);
      options.page = 1;
      options.perPage = total + 1;
      utilityService
        .getUtility("svetna_report_vehicles", options)
        .then((values) => {
          const downloadHeaders = columns
            .filter((item) => item.title)
            .map((item) => {
              if (!item.title) return null;
              return item.title;
            });
          const downloadFields = columns
            .filter((item) => item.field)
            .map((item) => {
              if (!item.field) return null;
              return item.field;
            });

          return generateCSV(downloadHeaders, downloadFields, values.data);
        })
        .then((csv) => {
          const blob = new Blob([csv], { type: "text/csv" });
          downloadBlob(blob, `svetna_report_vehicles.csv`);
        })
        .catch(console.error)
        .finally(() => {
          // setLoading(false);
        });
      return;
    }

    dispatch(
      utilityActions.getUtility("svetna_report_vehicles", {
        page,
        perPage,
        filters: newFilters,
        relations: ["title_status", "preimport_vehicle"],
        appends: ["year_make_model"],
        params: "",
        orders: [{ field: "vehicleid", direction: "desc" }],
      })
    );
  };

  const handleFilterChange = (newFilter) => {
    const newFilters = availableFilters.map((filter) => {
      if (filter.field !== newFilter.field) return filter; // not the droid we're looking for, return unchanged
      if (filter.name !== newFilter.name) return filter;
      filter.value = newFilter.value;
      return filter;
    });
    setAvailableFilters(newFilters);
    setPage(1);
    setGetData(true);
  };

  return (
    <>
      <Grid container spacing={1}>
        <Grid item>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              getNewData();
            }}
          >
            Refresh
          </Button>
        </Grid>
        <Grid item>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              getNewData(true);
            }}
          >
            Download
          </Button>
        </Grid>
      </Grid>

      <FiltersGrid
        availableFilters={availableFilters}
        onChange={handleFilterChange}
      />
      <ResponsiveGrid
        loading={items.loading}
        columns={columns}
        data={items.items}
        page={page - 1} // API has a 1 based page count, Material UI has a zero based page count
        perPage={perPage}
        total={total}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </>
  );
}

const useReportPageStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
}));

export { SvetnaReportsPage as default };
