import React from "react";
import { connect } from "react-redux";
import { Link as RouterLink } from "react-router-dom";

import { userActions, alertActions } from "../_actions";
import { getEnvironment } from "../_helpers";

import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";

import SnackbarContent from "@material-ui/core/SnackbarContent";
import ErrorIcon from "@material-ui/icons/Error";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import LaunchTwoToneIcon from "@material-ui/icons/LaunchTwoTone";

import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

import { PageWithAppDrawer2 } from "../_components/main/PageWithAppDrawer2";
import { userService } from "../_services";

class RegisterPage extends React.Component {
  constructor(props) {
    super(props);

    // reset login status
    this.props.dispatch(userActions.logout());

    // const { environmentDVS } = this.props;
    // const env = environmentDVS.find(obj => obj.name === "Development");
    const env = getEnvironment("reset");
    const isAcuity = this.props.location.pathname.includes("acuity");

    this.state = {
      isAcuity,
      firstName: "", // user
      lastName: "", // user
      cbp: "", // client
      username: "", // user, email, "primordius@gmail.com",
      usernameAvailable: false,
      password: "", // "m0rtalp1",
      phone: "", // user, client
      businessName: "",
      country: "",
      street: "", // client
      city: "", // client
      state: "", // client
      zip: "", // client
      services: [
        {
          name: "Registered Importation",
          value: "ri",
          checked: true,
          edit: false,
        },
        { name: "Titles", value: "titles", checked: false, edit: true },
        { name: "Odometer Service", value: "odo", checked: false, edit: true },
        {
          name: "Transportation Service",
          value: "transportation",
          checked: false,
          edit: true,
        },
      ],
      environmentDVS: env,
      submitted: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const { dispatch } = this.props;
    const { environmentDVS } = this.state;
    dispatch(userActions.setEnvironment(environmentDVS));
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(alertActions.clear());
  }

  handleClearAlert = () => {
    const { dispatch } = this.props;
    dispatch(alertActions.clear());
  };

  handleChange(e) {
    const { dispatch } = this.props;
    const { environmentDVS } = this.props;

    let { name, value, checked, type } = e.target;
    // console.log("change", { name, value, checked, type });
    if (name === "environmentDVS") {
      value = environmentDVS.find((obj) => obj.name === value);
      dispatch(userActions.setEnvironment(value));
    }
    if (type === "checkbox") {
      this.setState((prevState) => {
        return {
          services: prevState.services.map((service) => {
            if (service.value !== name) {
              return service;
            }
            service.checked = checked;
            return service;
          }),
        };
      });
    } else {
      this.setState({ [name]: value });
    }
  }

  handleSubmit(e) {
    e.preventDefault();

    const { dispatch } = this.props;

    this.setState({ submitted: true });
    dispatch(
      userActions.register(
        {
          ...this.state,
        },
        (response) => {
          if (response && response.success && response.success === true) {
            // TODO: update this with useCustomerDNS ?
            dispatch(
              userActions.login(this.state.username, this.state.password)
            );
          }
        }
      )
    );
  }

  checkEmailAvailable = () => {
    const { username } = this.state;

    userService.checkEmailAvailable(username).then((response) => {
      console.log("response", response);
      let available = false;
      if (response && response.success && response.success === true) {
        available = true;
      }
      this.setState({ usernameAvailable: available });
    });
  };

  render() {
    const { classes, alert } = this.props;
    const {
      isAcuity,
      firstName,
      lastName,
      cbp,
      username,
      usernameAvailable,
      password,
      phone,
      businessName,
      country,
      street,
      city,
      state,
      zip,
      services,
    } = this.state;

    const isDisabled =
      !firstName ||
      !lastName ||
      !username ||
      !password ||
      !phone ||
      !businessName ||
      !country ||
      !street ||
      !city ||
      !state ||
      !zip;

    return (
      <PageWithAppDrawer2
        pageTitle={"Register for RI"}
        showSideBar={false}
        showHomeLinks={true}
      >
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <div className={classes.paper}>
            <Avatar className={classes.avatar}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Register for RI with DVS{isAcuity && " and Acuity"}
            </Typography>
            {isAcuity && (
              <Typography style={{textAlign: "center"}}>
                Complete to Become a Register Importer with DVS and Acuity
                America and access to our Import Journey Technology
              </Typography>
            )}
            <RouterLink to="/registration-documents" target="_blank">
              Registration Documents
              <LaunchTwoToneIcon style={{ fontSize: 16 }} color="primary" />
            </RouterLink>
            <form
              className={classes.form}
              noValidate
              onSubmit={this.handleSubmit}
            >
              {isAcuity && (
                <input type="hidden" name="isAcuity" value={isAcuity} />
              )}
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="firstName"
                label="First Name"
                name="firstName"
                type="text"
                autoComplete="given-name"
                autoFocus
                value={firstName}
                onChange={this.handleChange}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="lastName"
                label="Last Name"
                name="lastName"
                type="text"
                autoComplete="family-name"
                value={lastName}
                onChange={this.handleChange}
              />
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                id="cbp"
                label="CBP Number (leave blank if you don't have one)"
                name="cb["
                type="text"
                autoComplete="none"
                value={cbp}
                onChange={this.handleChange}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="username"
                label="Email Address"
                name="username"
                type="email"
                onBlur={this.checkEmailAvailable}
                autoComplete="email"
                value={username}
                onChange={this.handleChange}
              />
              {username && usernameAvailable === false && (
                <Typography style={{ color: "red" }}>
                  This email has already been registered
                </Typography>
              )}
              {username && usernameAvailable === true && (
                <Typography style={{ color: "green" }}>
                  This email is available for registration
                </Typography>
              )}
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="none"
                value={password}
                onChange={this.handleChange}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="phone"
                label="Phone"
                type="tel"
                id="phone"
                autoComplete="tel"
                value={phone}
                onChange={this.handleChange}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="businessName"
                label="Business Name"
                type="text"
                id="businessName"
                autoComplete="organization"
                value={businessName}
                onChange={this.handleChange}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="country"
                label="Country"
                type="text"
                id="country"
                autoComplete="country"
                value={country}
                onChange={this.handleChange}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="street"
                label="Street Address"
                type="text"
                id="street"
                autoComplete="street-address"
                value={street}
                onChange={this.handleChange}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="city"
                label="City"
                type="text"
                id="city"
                autoComplete="address-level2"
                value={city}
                onChange={this.handleChange}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="state"
                label="State/Province"
                type="text"
                id="state"
                autoComplete="address-level1"
                value={state}
                onChange={this.handleChange}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="zip"
                label="Zip"
                type="text"
                id="zip"
                autoComplete="postal-code"
                value={zip}
                onChange={this.handleChange}
              />
              <FormGroup>
                {services.map((service, index) => (
                  <FormControlLabel
                    key={index}
                    control={
                      <Checkbox
                        checked={service.checked}
                        value={true}
                        onChange={(e) => {
                          if (service.edit) {
                            this.handleChange(e);
                          }
                        }}
                        name={service.value}
                      />
                    }
                    label={service.name}
                  />
                ))}
              </FormGroup>
              {alert.message && (
                <SnackbarContent
                  style={{ backgroundColor: "red" }}
                  aria-describedby="client-snackbar"
                  message={
                    <span
                      id="client-snackbar"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <ErrorIcon />
                      {alert.message}
                    </span>
                  }
                  action={[
                    <IconButton
                      key="close"
                      aria-label="close"
                      color="inherit"
                      onClick={this.handleClearAlert}
                    >
                      <CloseIcon />
                    </IconButton>,
                  ]}
                />
              )}

              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                disabled={isDisabled}
                className={classes.submit}
              >
                Request an Account
              </Button>
            </form>
          </div>
        </Container>
      </PageWithAppDrawer2>
    );
  }
}

const styles = (theme) => ({
  "@global": {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
});

function mapStateToProps(state) {
  const { alert } = state;
  const { loggingIn, environmentDVS } = state.authentication;
  return {
    alert,
    loggingIn,
    environmentDVS,
  };
}

// const connectedRegisterPage = connect(mapStateToProps)(RegisterPage);
// export default withRouter(connectedRegisterPage);

// export default withRouter(connect(mapStateToProps)(RegisterPage));

const connectedRegisterPage = connect(mapStateToProps)(
  withStyles(styles)(RegisterPage)
);
export { connectedRegisterPage as RegisterPage };
