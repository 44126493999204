import React, { useEffect, useState } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";

import { Button, makeStyles } from "@material-ui/core";

import PageWithAppDrawer3 from "../_components/main/PageWithAppDrawer3";
import { utilityActions } from "../_actions";
import FiltersGrid from "../_components/generic/FiltersGrid";
import ResponsiveGrid from "../_components/generic/ResponsiveGrid";

import SendIcon from "@material-ui/icons/Send";
import AddAPhotoIcon from "@material-ui/icons/AddAPhoto";
import moment from "moment";
import { utilityService } from "../_services";
import { hashHistory } from "../_helpers";

export function SvetnaVehicleDashboardPage() {
  const classes = useDashboardPageStyles();

  return (
    <PageWithAppDrawer3 pageTitle={"Svetna Vehicle Dashboard"}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <SvetnaVehicleDashboard />
          </Paper>
        </Grid>
      </Grid>
    </PageWithAppDrawer3>
  );
}

const useDashboardPageStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
}));

function SvetnaVehicleDashboard(props) {
  const dispatch = useDispatch();
  const location = useLocation();
  const { refresh } = location.state || {};
  const items = useSelector((state) => state.utility.svetna_preimport_vehicles);
  const webUser = useSelector((state) => state.authentication.webUser);
  const { client } = webUser;

  useEffect(() => {
    if (refresh === true) {
      setGetData(true);
    }
  }, [refresh]);

  const columns = [
    {
      width: 2,
      title: "VIN",
      field: "vin",
      dataRender: (item) => (
        <RouterLink to={`/svetna/vehicles/${item.id}`}>{item.vin}</RouterLink>
      ),
    },
    {
      width: 1,
      title: "DVS ID",
      field: "vehicle_id",
      dataRender: (item) =>
        Number(item.vehicle_id) > 0 ? item.vehicle_id : "",
    },
    {
      width: 2,
      title: "Seller",
      field: "seller.name",
    },
    {
      width: 4,
      title: "Yr/Mk/Model",
      field: "year_make_model",
    },
    {
      width: 3,
      title: "Actions",
      field: "",
      dataRender: (item) => {
        if (Number(item.vehicle_id) > 0) {
          return (
            <Button
              variant="contained"
              color="primary"
              component={RouterLink}
              to={{
                pathname: `/photoupload/${item.vehicle_id}`,
                state: { back: "/svetna/vehicledashboard" },
              }}
            >
              <AddAPhotoIcon style={{ marginRight: 5 }} />
              Photos and Load
            </Button>
          );
        } else {
          return (
            <Button
              variant="contained"
              color="primary"
              onClick={() => sendPreimportToDvs(item)}
            >
              <SendIcon style={{ marginRight: 5 }} />
              Send to DVS
            </Button>
          );
        }
      },
    },
  ];

  const [availableFilters, setAvailableFilters] = useState([
    {
      name: "VIN / Last6 / Partial",
      field: "vin",
      operator: "like",
      value: "",
      type: "text",
      default: "",
    },
    {
      name: "Seller",
      field: "seller.name",
      operator: "like",
      value: "",
      type: "text",
      default: "",
    },
    {
      name: "Client",
      field: "client_id",
      operator: "eq",
      value: client.clientid,
      type: "hidden",
      default: "",
    },
  ]);

  const [getData, setGetData] = useState(false);
  const [loading, setLoading] = useState(false);

  const [page, setPage] = useState(items.page ? Number(items.page) - 1 : 0);
  const [perPage, setPerPage] = useState(
    items.per_page ? Number(items.per_page) : 0
  );
  const [total, setTotal] = useState(items.total ? Number(items.total) : 0);

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
    setGetData(true);
  };

  const handleChangeRowsPerPage = (event) => {
    const value = parseInt(event.target.value);
    setPerPage(value);
    setGetData(true);
  };

  const getNewData = () => {
    let newFilters = availableFilters
      .filter((filter) => {
        if (["", null].includes(filter.value)) return false;
        if (
          ["isnull", "notnull"].includes(filter.operator) &&
          filter.value === false
        )
          return false;
        return true;
      })
      .map((filter) => {
        return {
          field: filter.field,
          operator: filter.operator,
          value: filter.value,
        };
      });

    dispatch(
      utilityActions.getUtility("svetna_preimport_vehicles", {
        page,
        perPage,
        filters: newFilters,
        relationsAdd: ["external_data"],
      }, () => {
        setLoading(false);
      })
    );
  };

  useEffect(() => {
    if (getData === true) {
      getNewData();
      setGetData(false);
    }
  }, [getData]);

  useEffect(() => {
    setTotal(items.total ? Number(items.total) : 0);
    setPage(items.page ? Number(items.page) : 0);
    setPerPage(items.per_page ? Number(items.per_page) : 10);
  }, [items]);

  useEffect(() => {
    if (items.items.length === 0) {
      getNewData();
    }
  }, []);

  const handleFilterChange = (newFilter) => {
    const newFilters = availableFilters.map((filter) => {
      if (filter.field !== newFilter.field) return filter; // not the droid we're looking for, return unchanged
      filter.value = newFilter.value;
      return filter;
    });
    setAvailableFilters(newFilters);
    setPage(1);
    setGetData(true);
  };

  const sendPreimportToDvs = async (preimportVehicle) => {
    if (Number(preimportVehicle.odo_value) < 1) {
      alert("ODO value cannot be empty");
      return;
    }

    const nhtsa = preimportVehicle.external_data?.nhtsa_vpic || null;

    const data = {
      preimportVehicleId: preimportVehicle.id,
      vehiclevin: preimportVehicle.vin,
      mileage: preimportVehicle.odo_value,
      clientid: client.clientid,
      statusid: 1,
      titlestatusid: 2,
      recordcreateddate: moment()
        .tz("America/Indiana/Indianapolis")
        .format("YYYY-MM-DD h:mm:ss"),
      yr: preimportVehicle.year,
      make: preimportVehicle.make,
      model: preimportVehicle.model,
      vehicletype: nhtsa?.bodyclass || null,
      manufacturer: nhtsa?.manufacturer || null,
      manufacture_date: null,
      gvwr_kg: parseInt(nhtsa.gvwr_kg || 0),
      gawr_front_kg: parseInt(nhtsa.gawr_front_kg || 0),
      gawr_rear_kg: parseInt(nhtsa.gawr_rear_kg || 0),
      gvwr_lbs: parseInt(nhtsa.gvwr_lbs || 0),
      gawr_front_lbs: parseInt(nhtsa.gawr_front_lbs || 0),
      gawr_rear_lbs: parseInt(nhtsa.gawr_rear_lbs || 0),
    };

    setLoading(true);

    await utilityService
      .addUtility("vehicles", data)
      .then((response) => {
        const { vehicleid } = response;
        utilityService.addUtility("vehicle_notes", {
          notedate: moment()
            .tz("America/Indiana/Indianapolis")
            .format("YYYY-MM-DD h:mm:ss"),
          vehicleid,
          note: "waiting upon load",
          userid: webUser.userid,
          roleid: webUser.roleid,
        });
        return response;
      })
      .then((response) => {
        hashHistory.push(`/photoupload/${response.id}`);
      });
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <FiltersGrid
          availableFilters={availableFilters}
          onChange={handleFilterChange}
        />
      </Grid>
      <Grid item xs={12}>
        <Button
          color="primary"
          variant="contained"
          component={RouterLink}
          to="/svetna/scan"
        >
          Scan/Add Vehicle
        </Button>{" "}
        <Button color="primary" variant="contained" onClick={getNewData}>
          Refresh List
        </Button>
      </Grid>
      <Grid item xs={12}>
        <ResponsiveGrid
          loading={items.loading || loading}
          columns={columns}
          data={items.items}
          page={page - 1} // API has a 1 based page count, Material UI has a zero based page count
          perPage={perPage}
          total={total}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          showActions
        />
      </Grid>
    </Grid>
  );
}

export { SvetnaVehicleDashboardPage as default };
