import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";

import { Typography } from "@material-ui/core";
import { PageWithAppDrawer3 } from "../_components/main/PageWithAppDrawer3";

export function WelcomePage(){

  return (
    <PageWithAppDrawer3 pageTitle={"Welcome to DVS"}>
      <Typography variant="h5">Welcome to DVS</Typography>
      <Typography>
        If you just registered you will have received an email with your login
        details.
      </Typography>
      <RouterLink to="/dashboard">
        <Typography>Go to my dashboard</Typography>
      </RouterLink>
      <br />
      <Typography>
        There are several documents required for DVS to import vehicles on
        your behalf
      </Typography>
      <RouterLink to="/registration-documents">
        <Typography>Registration Documents</Typography>
      </RouterLink>
      <Typography>
        Please make sure that DVS has completed copies of your registration
        documents
      </Typography>
    </PageWithAppDrawer3>
  );
}

export { WelcomePage as default}
