import React, { useState } from "react";
import { Button, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import ModalGeneric from "../generic/ModalGeneric";
import moment from "moment";
import { vehicleService } from "../../_services";

export default function AlertRiNewVehicleType(props) {
  const additionalNotes = props.additionalNotes;
  const vehicleInfo = props.vehicleInfo;
  const lastYear = Number(moment().subtract(1, "years").format("YYYY"));
  const [showModal, setShowModal] = useState(false);

  const changeRiNewVehicleType = () => {
    setShowModal(false);
    vehicleService
      .updateVehicle(vehicleInfo.id, { is_ri_new_vehicle: 0 })
      .then((response) => {
        if (props.onChange){
          props.onChange(response);
        }
      });
  };

  return (
    <Alert severity="warning" variant="filled">
      RI New Vehicle Type
      {additionalNotes && `, ${additionalNotes}`}
      {vehicleInfo && vehicleInfo.yr && vehicleInfo.yr < lastYear && (
        <Button
          variant="contained"
          size="small"
          color="primary"
          style={{ marginLeft: 10 }}
          onClick={() => setShowModal(true)}
        >
          Not a new Vehicle Type?
        </Button>
      )}
      <ModalGeneric
        open={showModal}
        onClose={changeRiNewVehicleType}
        onCancel={() => {
          setShowModal(false);
        }}
        closeText={"Remove RI New Vehicle Type?"}
        cancelText="Cancel"
        title={"Remove RI New Vehicle Type?"}
      >
        {vehicleInfo && (
          <>
            {vehicleInfo.yr} {vehicleInfo.make} {vehicleInfo.model}
            <br />
            Vehicle is older than {lastYear}, New RI Vehicle Type can be removed
            <br/>
            <Typography style={{color: 'red'}}>Removing Type will reload data, make sure all changes are saved</Typography>
          </>
        )}
      </ModalGeneric>
    </Alert>
  );
}
