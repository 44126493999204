import { userConstants } from "../_constants";

let user = JSON.parse(localStorage.getItem("user"));
let webUser = JSON.parse(localStorage.getItem("webUser"));

const defaultState = {
  user: null,
  loggedIn: false,
  loggingIn: false,
  autoLogin: false,
  selectedEnvironment: null,
  environmentDVS: [
    {
      name: "Development",
      env: "development",
      url: "https://godvs.greers.org",
    },
    {
      name: "Production",
      env: "production",
      // url: "https://api.carzipx.com",
      url: "https://api.dvs-carzip.com",
    },
  ],
};
const initialState = user
  ? {
      ...defaultState,
      loggedIn: true,
      user,
      webUser,
    }
  : defaultState;

export function authentication(state = initialState, action) {
  switch (action.type) {
    case userConstants.SETENV:
      return {
        ...state,
        selectedEnvironment: action.environmentDVS,
      };
    case userConstants.LOGIN_REQUEST:
      return {
        ...state,
        loggingIn: true,
        loggedIn: false,
        user: action.user,
      };
    case userConstants.LOGIN_SUCCESS:
      return {
        ...state,
        loggingIn: false,
        loggedIn: true,
        user: action.user,
        webUser: action.webUser,
      };
    case userConstants.LOGIN_FAILURE:
      return {
        ...state,
        loggingIn: false,
        loggedIn: false,
        user: null,
      };
    case userConstants.LOGOUT:
      return {
        ...state,
        loggingIn: false,
        loggedIn: false,
        user: null,
      };
    default:
      return state;
  }
}
