import React, { useState, useEffect, useRef } from "react";
import { Link as RouterLink, useLocation, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";

import { utilityActions } from "../_actions";
import { getEnvironmentURL, authHeader, hashHistory } from "../_helpers";

import { PageWithAppDrawer3 } from "../_components/main/PageWithAppDrawer3";

import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Camera, { FACING_MODES } from "react-html5-camera-photo";
import "./RapidPhotos.css";

import { utilityService, vehicleService } from "../_services";
import ModalGeneric from "../_components/generic/ModalGeneric";
import SignatureComponent from "../_components/generic/SignatureComponent";

import { usePosition } from "use-position";

import {
  Typography,
  Button,
  Link,
  CircularProgress,
  TextField,
  Divider,
  Backdrop,
  Select,
  FormControl,
  InputLabel,
  FormHelperText,
  AppBar,
  Tabs,
  Tab,
} from "@material-ui/core";
import { isEmpty } from "lodash";
import moment from "moment";
import ResponsiveGrid from "../_components/generic/ResponsiveGrid";

export function PhotoUploadPage2(props) {
  const dispatch = useDispatch();
  const classes = usePhotoUploadPageStyles();
  const location = useLocation();
  const { back } = location.state || {};

  const { id: vehicleId } = useParams();
  const client = useSelector((state) => state.authentication.webUser.client);
  const vehicle = useSelector((state) => state.utility.vehicles_item);
  const preimportVehicle = vehicle?.preimport_vehicle_with_history;
  const image_types = useSelector(
    (state) => state.utility.vehicle_image_types.items
  );
  const [openModalHistory, setOpenModalHistory] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);

  useEffect(() => {
    dispatch(
      utilityActions.getOneUtility("vehicles", vehicleId, {
        relationsAdd: ["preimport_vehicle_with_history"],
      })
    );
  }, [vehicleId]);

  useEffect(() => {
    if (client) {
      dispatch(
        utilityActions.getUtility("client_loads", {
          perPage: 25,
          relations: ["client", "notHas|bay_shipment"],
          filters: [
            {
              field: "client_id",
              operator: "eq",
              value: client.clientid,
            },
          ],
          orders: [
            {
              field: "created_at",
              direction: "desc",
            },
          ],
        })
      );
    }
  }, [client]);

  useEffect(() => {
    // when mounting goes here
    if (image_types.length === 0) {
      dispatch(
        utilityActions.getUtility("vehicle_image_types", {
          perPage: 100,
          orders: [
            {
              field: "display_order",
              direction: "asc",
            },
          ],
        })
      );
    }

    // when unmounting goes here
    return () => {
      dispatch(utilityActions.clearOneUtility("vehicles"));
    };
  }, []);

  return (
    <PageWithAppDrawer3 pageTitle={"Upload Photos v4"}>
      {back && (
        <Button
          variant="contained"
          color="primary"
          component={RouterLink}
          to="/svetna/vehicledashboard"
        >
          Back to Svetna Dashboard
        </Button>
      )}
      <Paper className={classes.paper}>
        <Typography variant="h6" align="center">
          {client?.clientname ?? "NO CLIENT FOR THIS USER"}
        </Typography>
        {vehicle && (
          <PhotoUploadHeader
            vin={vehicle?.vehiclevin}
            year={vehicle?.year}
            make={vehicle?.make}
            model={vehicle?.model}
            clientName={vehicle?.client?.clientname}
          />
        )}
        {!vehicle && <CircularProgress />}
        {vehicle && client?.clientname === "SVETNA AUTO WHOLESALES" && (
          <>
            <AppBar position="static" style={{ marginTop: 10 }}>
              <Tabs
                value={tabIndex}
                onChange={(e, newValue) => setTabIndex(newValue)}
                aria-label="Photo Upload Tabs"
                variant="fullWidth"
              >
                <Tab label="DVS Photos / Load" />
                <Tab label="Svetna Decs" style={{backgroundColor: preimportVehicle ? "inherit" : "red"}} />
                <Tab label="Svetna History" />
              </Tabs>
            </AppBar>
            <Typography component="div" role="tabpanel" hidden={tabIndex !== 0}>
              <Paper className={classes.paper}>
                <VehicleCard vehicle={vehicle} />
              </Paper>
            </Typography>
            <Typography component="div" role="tabpanel" hidden={tabIndex !== 1}>
              <Paper className={classes.paper}>
                {preimportVehicle ? (
                  <PreimportDeclarations id={preimportVehicle.id} />
                ) : (
                  <PreimportMissing
                    vehicleId={vehicle.vehicleid}
                    vin={vehicle.vehiclevin}
                    mileage={vehicle.mileage}
                  />
                )}
              </Paper>
            </Typography>
            <Typography component="div" role="tabpanel" hidden={tabIndex !== 2}>
              <Paper className={classes.paper}>
                {preimportVehicle ? (
                  <PreimportHistory id={preimportVehicle.id} />
                ) : (
                  <PreimportMissing
                    vehicleId={vehicle.vehicleid}
                    vin={vehicle.vehiclevin}
                    mileage={vehicle.mileage}
                  />
                )}
              </Paper>
            </Typography>
          </>
        )}
        {vehicle && client?.clientname !== "SVETNA AUTO WHOLESALES" && (
          <VehicleCard vehicle={vehicle} />
        )}
      </Paper>
    </PageWithAppDrawer3>
  );
}

function PreimportMissing(props) {
  const vin = props.vin;
  const vehicleId = props.vehicleId;
  const mileage = props.mileage;

  const user = useSelector((state) => state.authentication.webUser);

  const [loading, setLoading] = useState(null);
  const [error, setError] = useState(null);
  let nhtsaData = null;

  const addPreimportFromVehicle = () => {
    setError(null);
    if (mileage.trim() === "") {
      setError("Mileage cannot be empty");
      return;
    }
    if (Number(mileage) < 1) {
      setError("Mileage cannot be empty");
      return;
    }

    setLoading(true);
    vehicleService
      .nhtsaVinLookup(vin)
      .then(async (response) => {
        nhtsaData = response;
        if (!nhtsaData[0]) {
          setError("No information from NTHSA");
          return;
        }

        const data = {
          vin,
          vehicle_id: vehicleId,
          seller_id: 0,
          odo_value: mileage,
          client_id: user.clientid,
          year: nhtsaData[0].modelyear,
          make: nhtsaData[0].make,
          model: nhtsaData[0].model,
          details: {
            vehicle_id: vehicleId,
            client_id: user.clientid,
          },
          external_data: {
            vin,
            nhtsa_vpic: nhtsaData[0],
          },
        };

        await utilityService
          .addUtility("svetna_preimport_vehicles", data)
          .then((response) => {
            hashHistory.push(`/svetna/vehicles/${response.id}`);
          });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <Typography>
        This VIN{" "}
        <Typography
          color="primary"
          style={{ display: "inline", fontWeight: "bold" }}
        >
          {vin}
        </Typography>{" "}
        does not have a Declarations Record
      </Typography>
      <div>
        <Button
          variant="contained"
          color="primary"
          onClick={addPreimportFromVehicle}
        >
          Add Declarations Record
        </Button>
      </div>
      {error && <Typography style={{ color: "red" }}>{error}</Typography>}
      {loading && <CircularProgress />}
    </>
  );
}

function PhotoUploadHeader(props) {
  const { latitude, longitude } = usePosition();
  const [hasPosition, setHasPosition] = useState(false);

  useEffect(() => {
    if (latitude && longitude) {
      setHasPosition(true);
    }
  }, [latitude, longitude]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {hasPosition ? (
          <Typography align="center" style={{ color: "green" }}>
            {latitude},{longitude}
          </Typography>
        ) : (
          <Typography align="center" style={{ color: "red" }}>
            Position not available
          </Typography>
        )}

        <Typography align="center">{props.vin}</Typography>
        <Typography align="center">
          {props.year} {props.make} {props.model}{" "}
        </Typography>
        {props.clientName && (
          <Typography align="center">{props.clientName}</Typography>
        )}
      </Grid>
    </Grid>
  );
}

function PreimportDeclarations(props) {
  const dispatch = useDispatch();
  const item = useSelector(
    (state) => state.utility.svetna_preimport_vehicles_item
  );
  const id = props.id;

  useEffect(() => {
    if (Number(id) > 0) {
      dispatch(
        utilityActions.getOneUtility("svetna_preimport_vehicles", id, {
          relationsAdd: ["history"],
        })
      );
    }
  }, [dispatch, id]);

  const navigateToDecs = () => {
    hashHistory.push(`/svetna/vehicles/${item.id}`);
  };

  return (
    <>
      {item && item.id && (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              onClick={navigateToDecs}
            >
              Edit Declarations
            </Button>
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              variant="outlined"
              label="VIN"
              type="text"
              name="vin"
              placeholder="VIN"
              value={item.vin || ""}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              variant="outlined"
              label="Model Year"
              type="number"
              name="year"
              placeholder="Model Year"
              value={item.year || ""}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              variant="outlined"
              label="Make"
              type="text"
              name="make"
              placeholder="Make"
              value={item.make || ""}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              variant="outlined"
              label="Model"
              type="text"
              name="model"
              placeholder="Model"
              value={item.model || ""}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              variant="outlined"
              label="Odometer"
              type="number"
              name="odo_value"
              placeholder="Odometer"
              value={item.odo_value || ""}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              variant="outlined"
              label="Odometer Type"
              type="text"
              name="odo_type"
              placeholder="Odometer Type"
              value={item.odo_type || ""}
            />
          </Grid>

          <Grid
            item
            xs={12}
            md={4}
            style={{
              backgroundColor: item.details.has_recall ? "yellow" : "inherit",
            }}
          >
            <TextField
              fullWidth
              variant="outlined"
              label="Has Recall"
              type="text"
              name="has_recall"
              placeholder="Has Recall"
              value={item.details.has_recall ? "Yes" : "No"}
            />
          </Grid>

          <Grid
            item
            xs={12}
            md={4}
            style={{
              backgroundColor: item.details.requires_windshield_replacement
                ? "yellow"
                : "inherit",
            }}
          >
            <TextField
              fullWidth
              variant="outlined"
              label="Windshield Replacement"
              type="text"
              name="requires_windshield_replacement"
              placeholder="Windshield Replacement"
              value={
                item.details.requires_windshield_replacement ? "Yes" : "No"
              }
            />
          </Grid>

          <Grid
            item
            xs={12}
            md={4}
            style={{
              backgroundColor: item.details.requires_tires
                ? "yellow"
                : "inherit",
            }}
          >
            <TextField
              fullWidth
              variant="outlined"
              label="Tires Required"
              type="text"
              name="requires_tires"
              placeholder="Tires Required"
              value={item.details.requires_tires ? "Yes" : "No"}
            />
          </Grid>

          <Grid
            item
            xs={12}
            md={4}
            style={{
              backgroundColor: item.details.requires_body_work
                ? "yellow"
                : "inherit",
            }}
          >
            <TextField
              fullWidth
              id="requires_body_work"
              name="requires_body_work"
              label="Requires Body Work"
              value={item.details.requires_body_work ? "Yes" : "No"}
              variant="outlined"
            />
          </Grid>

          <Grid
            item
            xs={12}
            md={8}
            style={{
              backgroundColor:
                item.details.body_work_details?.trim().length > 0
                  ? "yellow"
                  : "inherit",
            }}
          >
            <TextField
              fullWidth
              id="body_work_details"
              name="body_work_details"
              label="Body Work Details"
              multiline
              rows={4}
              value={item.details.body_work_details}
              variant="outlined"
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              variant="outlined"
              label="Purchase Price before taxes"
              type="number"
              name="purchase_price_before_taxes"
              placeholder="Purchase Price before taxes"
              value={item.details.purchase_price_before_taxes || ""}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              variant="outlined"
              label="MMR at purchase"
              type="number"
              name="purchase_mmr"
              placeholder="MMR at purchase"
              value={item.details.purchase_mmr || ""}
            />
          </Grid>

          <Grid item xs={4}>
            <TextField
              fullWidth
              variant="outlined"
              label="Seller"
              type="text"
              name="seller"
              placeholder="Seller"
              value={item.seller?.name || ""}
            />
          </Grid>

          <Grid
            item
            xs={12}
            style={{
              backgroundColor:
                item.details.additional_information?.trim().length > 0
                  ? "yellow"
                  : "inherit",
            }}
          >
            <TextField
              fullWidth
              multiline
              rows={4}
              variant="outlined"
              label="Additional Declarations"
              type="text"
              name="additional_information"
              placeholder="Additional Declarations"
              value={item.details.additional_information || ""}
            />
          </Grid>
        </Grid>
      )}
    </>
  );
}

function PreimportHistory(props) {
  const dispatch = useDispatch();
  const historyItems = useSelector((state) => state.utility.vehicle_history);

  const historyColumns = [
    {
      width: 3,
      title: "Field",
      field: "target_field",
    },
    {
      width: 3,
      title: "New Value",
      field: "value",
    },
    {
      width: 3,
      title: "User",
      field: "user.name",
    },
    {
      width: 3,
      title: "Date",
      field: "updated_at",
    },
  ];

  useEffect(() => {
    if (Number(props.id) > 0) {
      dispatch(
        utilityActions.getUtility("vehicle_history", {
          perPage: 1000,
          filters: [
            {
              field: "preimport_vehicle_id",
              operator: "eq",
              value: props.id,
            },
          ],
          orders: [
            {
              field: "id",
              direction: "desc",
            },
          ],
        })
      );
    }
  }, [dispatch, props.id]);

  return (
    <>
      {historyItems.items.length === 0 && (
        <Typography variant="h6" style={{ color: "red" }}>
          Unable to retrieve history for this vehicle
        </Typography>
      )}
      {historyItems.items.length > 0 && (
        <ResponsiveGrid
          loading={false}
          columns={historyColumns}
          data={historyItems.items || []}
          usePagination={false}
        />
      )}
    </>
  );
}

function VehicleCard(props) {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.authentication.webUser);
  const clientId = useSelector(
    (state) => state.authentication.webUser.client_id
  );
  const vehicleImageTypes = useSelector(
    (state) => state.utility.vehicle_image_types
  );
  const clientLoads = useSelector((state) => state.utility.client_loads);
  const drivers = useSelector((state) => state.utility.client_drivers_full);
  const { latitude, longitude } = usePosition();
  const [hasPosition, setHasPosition] = useState(false);
  const [vehicle, setVehicle] = useState(props.vehicle);
  const preimportVehicle = vehicle?.preimport_vehicle_with_history;
  const [vehicleType, setVehicleType] = useState("");
  const [images, setImages] = useState([]);
  const [mileage, setMileage] = useState("");
  const [kilometers, setKilometers] = useState("");
  const [manufacturer, setManufacturer] = useState("");
  const [manuDate, setManuDate] = useState("");
  const [gvwr, setGvwr] = useState("");
  const [gvwrLbs, setGvwrLbs] = useState("");
  const [gawrFront, setGawrFront] = useState("");
  const [gawrFrontLbs, setGawrFrontLbs] = useState("");
  const [gawrRear, setGawrRear] = useState("");
  const [gawrRearLbs, setGawrRearLbs] = useState("");
  const [valueUSD, setValueUSD] = useState("");
  const [hasRecall, setHasRecall] = useState(0);
  const [transitNumber, setTransitNumber] = useState("");

  const [frontSize, setFrontSize] = useState("");
  const [frontKpa, setFrontKpa] = useState("");
  const [frontPsi, setFrontPsi] = useState("");
  const [rearSize, setRearSize] = useState("");
  const [rearKpa, setRearKpa] = useState("");
  const [rearPsi, setRearPsi] = useState("");

  const [passAirbagModified, setPassAirbagModified] = useState(null);
  const [doSave, setDoSave] = useState(false);

  const [loadNumber, setLoadNumber] = useState("");
  const [loadId, setLoadId] = useState("");
  const [needToSave, setNeedToSave] = useState(true);
  const [saving, setSaving] = useState(false);

  const [modalPhotoOpen, setModalPhotoOpen] = useState(false);
  const [imageIndex, setImageIndex] = useState(0);
  const [mirror, setMirror] = useState(false);

  const [openSingle, setOpenSingle] = useState(false);
  const [singleError, setSingleError] = useState("");
  const [driverId, setDriverId] = useState(null);
  const [singleLoadNumber, setSingleLoadNumber] = useState("");

  const [modalSign, setModalSign] = useState(false);
  const [inspectionSignature, setInspectionSignature] = useState(null);

  useEffect(() => {
    if (latitude && longitude) {
      setHasPosition(true);
    }
  }, [latitude, longitude]);

  useEffect(() => {
    setVehicle(props.vehicle);
    let getDrivers = true;
    if (props.vehicle) {
      setInspectionSignature(props.vehicle.doc_signatures?.physicalInspection);
      if (getDrivers) {
        dispatch(
          utilityActions.getUtility("client_drivers_full", {
            perPage: 1000,
            filters: [
              {
                field: "client_id",
                operator: "eq",
                value: clientId,
              },
            ],
            orders: [{ field: "first_name", direction: "asc" }],
          })
        );
      }
      setMileage(props.vehicle.mileage);
      updateMileageKilometers(props.vehicle.mileage, null);
      setLoadNumber(props.vehicle.customer_load_number);
      setLoadId(props.vehicle.client_load_id);
      setDriverId(props.vehicle.client_driver_id);
      setManufacturer(props.vehicle.manufacturer);
      setManuDate(props.vehicle.manufacture_date);
      setGvwr(props.vehicle.gvwr_kg);
      setGvwrLbs(props.vehicle.gvwr_lbs);
      setGawrFront(props.vehicle.gawr_front_kg);
      setGawrFrontLbs(props.vehicle.gawr_front_lbs);
      setGawrRear(props.vehicle.gawr_rear_kg);
      setGawrRearLbs(props.vehicle.gawr_rear_lbs);
      setVehicleType(props.vehicle.vehicletype);
      setValueUSD(props.vehicle.value_us_dollars);
      setTransitNumber(props.vehicle.transit_number);
      setDriverId(props.vehicle.client_driver_id);
      setHasRecall(
        props.vehicle.has_recall === null ? "" : props.vehicle.has_recall
      );
      setFrontSize(props.vehicle.vehicle_tire_data?.front_size);
      setFrontKpa(props.vehicle.vehicle_tire_data?.front_kpa);
      setFrontPsi(props.vehicle.vehicle_tire_data?.front_psi);
      setRearSize(props.vehicle.vehicle_tire_data?.rear_size);
      setRearKpa(props.vehicle.vehicle_tire_data?.rear_kpa);
      setRearPsi(props.vehicle.vehicle_tire_data?.rear_psi);
      setPassAirbagModified(props.vehicle.is_pass_airbag_modified);
    }
    let images = [];
    props.vehicle.images &&
      props.vehicle.images.forEach((image) => {
        const imageType = image.type && image.type.id;
        if (imageType) {
          images[imageType] = image;
        }
      });
    setImages(images);

    setImageIndex(getFirstImageIndex());
  }, [props.vehicle]);

  useEffect(() => {
    setImageIndex(getFirstImageIndex());
  }, [images]);

  useEffect(() => {
    if (user.roleid === 2) {
      setNeedToSave(false);
      return;
    }

    if (!props.vehicle) {
      setNeedToSave(true);
      return;
    } else if (
      props.vehicle.mileage === null ||
      props.vehicle.mileage === "" ||
      props.vehicle.customer_load_id === null ||
      props.vehicle.customer_load_id === ""
    ) {
      setNeedToSave(true);
    } else if (
      Number(props.vehicle.mileage) !== Number(mileage) ||
      props.vehicle.customer_load_number !== loadNumber
    ) {
      setNeedToSave(true);
    } else {
      setNeedToSave(false);
    }
  }, [mileage, loadNumber, props.vehicle]);

  useEffect(() => {
    if (doSave === true) {
      setDoSave(false);
      saveBasics();
    }
  }, [doSave]);

  const handleChangePassAirBag = (value) => {
    setPassAirbagModified(value);
    setDoSave(true);
  };

  const saveBasics = () => {
    console.log({ passAirbagModified });
    setSaving(true);
    dispatch(
      utilityActions.updateUtility(
        "vehicles",
        props.vehicle.id,
        {
          mileage,
          customer_load_number: loadNumber,
          client_load_id: loadId,
          manufacturer,
          manufacture_date: manuDate,
          gvwr_kg: gvwr,
          gawr_front_kg: gawrFront,
          gawr_rear_kg: gawrRear,
          gvwr_lbs: gvwrLbs,
          gawr_front_lbs: gawrFrontLbs,
          gawr_rear_lbs: gawrRearLbs,
          value_us_dollars: parseInt(valueUSD),
          is_pass_airbag_modified: passAirbagModified,
          has_recall:
            Number(hasRecall) === 0 || Number(hasRecall) === 1
              ? Number(hasRecall)
              : null,
          transit_number: transitNumber,
          client_driver_id: driverId,
          vehicle_tire_data: {
            front_size: frontSize,
            front_kpa: frontKpa,
            front_psi: frontPsi,
            rear_size: rearSize,
            rear_kpa: rearKpa,
            rear_psi: rearPsi,
          },
        },
        () => {
          setSaving(false);
        }
      )
    );
  };

  const updateMileageKilometers = (mileage, kilometers) => {
    if (mileage) {
      console.log("mileage");
      setMileage(mileage);
      setKilometers(Number(mileage * 1.60934).toFixed(0));
    }
    if (kilometers) {
      setKilometers(kilometers);
      setMileage(Number(kilometers * 0.621371).toFixed(0));
    }
    if (!mileage && !kilometers) {
      setMileage("");
      setKilometers("");
    }
  };

  const quickAddLoad = async () => {
    const load = window.prompt("New Load Number");
    if (!load || load.toString().trim() === "") {
      return;
    }

    utilityService
      .getUtility("client_loads", {
        filters: [
          {
            field: "client_id",
            operator: "eq",
            value: clientId,
          },
          {
            field: "name",
            operator: "eq",
            value: load,
          },
        ],
      })
      .then((response) => {
        if (response && response.data && response.data.length === 1) {
          setLoadId(response.data[0].id);
          alert("Load already exists, setting load");
        } else if (response && response.data && response.data.length > 1) {
          alert("More than one load with that name, please use dropdown");
        } else if (response && response.data && response.data.length === 0) {
          // creating new load
          utilityService
            .addUtility("client_loads", {
              client_id: clientId,
              created_by: user.userid,
              name: load,
            })
            .then(async (response) => {
              await dispatch(
                utilityActions.getUtility("client_loads", {
                  perPage: 25,
                  relations: ["client"],
                  filters: [
                    {
                      field: "client_id",
                      operator: "eq",
                      value: clientId,
                    },
                  ],
                  orders: [
                    {
                      field: "created_at",
                      direction: "desc",
                    },
                  ],
                })
              );
              setLoadId(response.id);
            });
        }
      });
  };

  function handleTakePhotoAnimationDone(dataUri) {
    const imageType = vehicleImageTypes.items[imageIndex];
    setImageIndex(imageIndex + 1);
    uploadImage(dataUri, vehicle.id, imageType.name, imageType.id);
  }

  function getFirstImageIndex() {
    let x;
    for (x = 0; x < vehicleImageTypes.items.length; x++) {
      if (!images[vehicleImageTypes.items[x].id]) {
        return x;
      }
    }
    return 0;
  }

  const uploadImage = (file, vehicleId, note, type) => {
    let formData = new FormData();
    formData.append("photo", file);
    formData.append("vehicleId", vehicleId);
    formData.append("note", note);
    formData.append("type", type);

    fetch(`${getEnvironmentURL()}/api/vehicles/${props.vehicleId}/images`, {
      // Your POST endpoint
      method: "POST",
      headers: { ...authHeader() },
      body: formData,
    })
      .then(
        (response) => response.json() // if the response is a JSON object
      )
      .then(
        //TODO:save also in customer object
        (success) => {
          // console.log(success); // Handle the success response object
          // refreshImages();
        }
      )
      .catch((error) => {
        // console.log(error); // Handle the error response object
        // setPreviewUrl("");
      });
  };

  const refreshImages = () => {
    dispatch(utilityActions.getOneUtility("vehicles", vehicle.id));
  };

  const createSingle = () => {
    if (!singleLoadNumber) {
      setSingleError("Load Number is required");
      return;
    }
    setSingleError("");
    utilityService
      .addUtility("client_loads", {
        name: singleLoadNumber,
        load_type: "single",
        client_id: clientId,
        created_by: user.userid,
      })
      .then((response) => {
        setLoadId(response.id);
        setOpenSingle(false);
        dispatch(
          utilityActions.getUtility("client_loads", {
            perPage: 25,
            filters: [
              {
                field: "client_id",
                operator: "eq",
                value: clientId,
              },
            ],
            orders: [
              {
                field: "created_at",
                direction: "desc",
              },
            ],
          })
        );
      });
  };

  const convertKgToLbs = () => {
    if (parseInt(gvwr) > 0) {
      setGvwrLbs(parseInt(gvwr * 2.20462));
    }
    if (parseInt(gawrFront) > 0) {
      setGawrFrontLbs(parseInt(gawrFront * 2.20462));
    }
    if (parseInt(gawrRear) > 0) {
      setGawrRearLbs(parseInt(gawrRear * 2.20462));
    }
  };

  const convertLbsToKg = () => {
    if (parseInt(gvwrLbs) > 0) {
      setGvwr(parseInt(gvwrLbs * 0.453592));
    }
    if (parseInt(gawrFrontLbs) > 0) {
      setGawrFront(parseInt(gawrFrontLbs * 0.453592));
    }
    if (parseInt(gawrRearLbs) > 0) {
      setGawrRear(parseInt(gawrRearLbs * 0.453592));
    }
  };

  return (
    <>
      {/* <Grid item xs={12}>preimportVehicle={JSON.stringify(preimportVehicle)}</Grid> */}
      <Grid container spacing={2}>
        {vehicle.client?.clientname === "SVETNA AUTO WHOLESALES" && (
          <>
            {preimportVehicle &&
              preimportVehicle.declarations?.hasDeclarations && (
                <Grid item xs={12} style={{ backgroundColor: "yellow" }}>
                  Svetna Vehicle has Declarations
                  <br />
                  {preimportVehicle.declarations.declarations.map((dec) => (
                    <Typography variant="body2">{dec}</Typography>
                  ))}
                </Grid>
              )}
              {!preimportVehicle && (
                <Grid item xs={12} style={{ backgroundColor: "red" }}>
                  Svetna Vehicle missing declaration record
                </Grid>
              )}
          </>
        )}

        <Grid item xs={6} sm={4} md={3}>
          <TextField
            required
            id="mileage"
            name="mileage"
            label="Mileage"
            value={mileage || ""}
            helperText="US Miles"
            onChange={(event) =>
              updateMileageKilometers(event.target.value, null)
            }
          />
        </Grid>
        <Grid item xs={6} sm={4} md={3}>
          <TextField
            required
            id="kilometers"
            name="kilometers"
            label="Kilometers"
            value={kilometers}
            helperText="Kilometers"
            onChange={(event) =>
              updateMileageKilometers(null, event.target.value)
            }
          />
        </Grid>
        {vehicle.client_load &&
        (vehicle.bay_shipment || vehicle.client_load.bay_shipment) ? (
          <Grid item xs={6} sm={4} md={3}>
            <FormControl>
              <TextField
                label="Load Name"
                value={vehicle.client_load.name}
                helperText="Sent to Bay"
                disabled={true}
              />
            </FormControl>
          </Grid>
        ) : (
          <Grid item xs={6} sm={4} md={3}>
            <FormControl>
              <InputLabel shrink>Load</InputLabel>
              <Select
                native
                style={{ fontSize: 12 }}
                value={loadId || ""}
                name="client_load_id"
                onChange={(event) => setLoadId(event.target.value)}
                displayEmpty
              >
                <option value={""}>Not in Load</option>
                {vehicle.client_load && (
                  <option value={vehicle.client_load.id}>
                    {vehicle.client_load.name}
                  </option>
                )}
                {clientLoads.items &&
                  clientLoads.items.map((load, index) => {
                    return (
                      <option value={load.id} key={index}>
                        {load.name}
                        {load.load_type === "single" &&
                          ` - singles(${load.vehicles_count})`}
                      </option>
                    );
                  })}
              </Select>
              <FormHelperText>Load Number</FormHelperText>
            </FormControl>
            <br />
            <Button
              color="primary"
              onClick={quickAddLoad}
              disabled={loadId ? true : false}
            >
              Quick Add Load
            </Button>
            <br />
            <Button
              color="primary"
              onClick={() => setOpenSingle(true)}
              disabled={loadId ? true : false}
            >
              Create Single Load
            </Button>
          </Grid>
        )}
        <Grid item xs={6} sm={4} md={3}>
          <FormControl>
            <InputLabel shrink>Has Recall</InputLabel>
            <Select
              native
              style={{ fontSize: 12 }}
              value={hasRecall || 0}
              name="has_recall"
              onChange={(event) => setHasRecall(event.target.value)}
              displayEmpty
            >
              <option value={""}>Has Recall?</option>
              <option value={0}>No - No Recall</option>
              <option value={1}>Yes - Has Recall</option>
            </Select>
            <FormHelperText>NHTSA Recall Check</FormHelperText>
          </FormControl>
        </Grid>
        {props.vehicle.client_load &&
          props.vehicle.client_load.load_type === "single" && (
            <>
              <Grid item xs={12} sm={4} md={3}>
                <TextField
                  fullWidth
                  name="transit_number"
                  label="Transit Number"
                  value={transitNumber || ""}
                  onChange={(event) => setTransitNumber(event.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={8} md={9}>
                <FormControl>
                  <InputLabel shrink>Driver</InputLabel>
                  <Select
                    native
                    value={driverId || ""}
                    onChange={(e) => setDriverId(e.target.value)}
                  >
                    <option value={null}>SELECT DRIVER</option>
                    {drivers.items.map((driver, index) => (
                      <option value={driver.id} key={index}>
                        {driver.first_name} {driver.last_name}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </>
          )}
        <Grid item xs={12} sm={4} md={3}>
          <TextField
            fullWidth
            name="manufacturer"
            label="Manufacturer"
            value={manufacturer || ""}
            onChange={(event) => setManufacturer(event.target.value)}
          />
        </Grid>
        <Grid item xs={6} sm={4} md={3}>
          <TextField
            fullWidth
            name="manufacture_date"
            // type="number"
            inputMode="numeric"
            label="Manufacture Date"
            // helperText="Manufacture Date"
            value={manuDate || ""}
            onChange={(event) => setManuDate(event.target.value)}
          />
        </Grid>
        <Grid item xs={6} sm={12} md={6}>
          <TextField
            fullWidth
            name="vehicletype"
            label="Type"
            helperText="US NHTSA Vehicle Type"
            // type="number"
            value={vehicleType || ""}
            onChange={(event) => setVehicleType(event.target.value)}
          />
        </Grid>

        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={6} sm={3} md={3}>
              <TextField
                name="gvwr"
                label="GVWR (kg)"
                type="number"
                value={gvwr || ""}
                onChange={(event) => setGvwr(event.target.value)}
              />
            </Grid>
            <Grid item xs={6} sm={3} md={3}>
              <TextField
                name="gvwr"
                label="GVWR (lbs)"
                type="number"
                value={gvwrLbs || ""}
                onChange={(event) => setGvwrLbs(event.target.value)}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={6} sm={3} md={3}>
              <TextField
                name="gawr_front"
                label="GAWR Front (kg)"
                type="number"
                value={gawrFront || ""}
                onChange={(event) => setGawrFront(event.target.value)}
              />
            </Grid>
            <Grid item xs={6} sm={3} md={3}>
              <TextField
                name="gawr_front"
                label="GAWR Front (lbs))"
                type="number"
                value={gawrFrontLbs || ""}
                onChange={(event) => setGawrFrontLbs(event.target.value)}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={6} sm={3} md={3}>
              <TextField
                name="gawr_rear"
                label="GAWR Rear (kg)"
                type="number"
                value={gawrRear || ""}
                onChange={(event) => setGawrRear(event.target.value)}
              />
            </Grid>
            <Grid item xs={6} sm={3} md={3}>
              <TextField
                name="gawr_rear"
                label="GAWR Rear (lbs)"
                type="number"
                value={gawrRearLbs || ""}
                onChange={(event) => setGawrRearLbs(event.target.value)}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={6} sm={4} md={3}>
          <TextField
            margin="dense"
            name={"front_size"}
            label={"Front Tire Size"}
            type="text"
            value={frontSize || ""}
            onChange={(e) => setFrontSize(e.target.value)}
            fullWidth
          />
        </Grid>
        <Grid item xs={6} sm={4} md={3}>
          <TextField
            margin="dense"
            name={"front_kpa"}
            label={"Front Tire kPa (numbers only)"}
            type="number"
            value={frontKpa || ""}
            onChange={(e) => setFrontKpa(e.target.value)}
            fullWidth
          />
        </Grid>
        <Grid item xs={6} sm={4} md={3}>
          <TextField
            margin="dense"
            name={"front_psi"}
            label={"Front Tire PSI (numbers only)"}
            type="number"
            value={frontPsi || ""}
            onChange={(e) => setFrontPsi(e.target.value)}
            fullWidth
          />
        </Grid>
        <Grid item xs={6} sm={4} md={3}>
          <TextField
            margin="dense"
            name={"rear_size"}
            label={"Rear Tire Size"}
            type="text"
            value={rearSize || ""}
            onChange={(e) => setRearSize(e.target.value)}
            fullWidth
          />
        </Grid>
        <Grid item xs={6} sm={4} md={3}>
          <TextField
            margin="dense"
            name={"rear_kpa"}
            label={"Rear Tire kPa (numbers only)"}
            type="number"
            // disabled={!isAdmin}
            value={rearKpa || ""}
            onChange={(e) => setRearKpa(e.target.value)}
            fullWidth
          />
        </Grid>
        <Grid item xs={6} sm={4} md={3}>
          <TextField
            margin="dense"
            name={"rear_psi"}
            label={"Rear Tire PSI (numbers only)"}
            type="number"
            value={rearPsi || ""}
            onChange={(e) => setRearPsi(e.target.value)}
            fullWidth
          />
        </Grid>

        <Grid item xs={6} sm={4} md={3}>
          <TextField
            name="value_us_dollars"
            label="Value USD"
            type="number"
            value={valueUSD || ""}
            onChange={(event) => setValueUSD(event.target.value)}
          />
        </Grid>
        <Grid item xs={12} align="center">
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={saveBasics}
          >
            Save Mileage and Load
          </Button>{" "}
          <Button
            variant="contained"
            color="primary"
            size="small"
            disabled={needToSave}
            href={`${getEnvironmentURL()}/api/vehicles/${vehicle.id}/forms/26`}
            target="_blank"
          >
            RI Label
          </Button>{" "}
          <Button
            size="small"
            color={inspectionSignature ? "" : "primary"}
            variant="contained"
            onClick={() => setModalSign(true)}
          >
            VIN Inspection {inspectionSignature ? "(Signed)" : "(Unsigned)"}
          </Button>
        </Grid>

        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h6" align="center">
            Passenger Airbag Indicator Modification
          </Typography>
        </Grid>

        <Grid
          item
          xs={12}
          style={{
            backgroundColor: passAirbagModified === null ? "red" : "inherit",
          }}
        >
          <FormControl>
            <InputLabel shrink>Modification Status</InputLabel>
            <Select
              native
              value={passAirbagModified === null ? "" : passAirbagModified}
              onChange={(e) => {
                let newVal = e.target.value;
                if (newVal === "") {
                  newVal = null;
                } else if (newVal === "true") {
                  newVal = true;
                } else {
                  newVal = false;
                }
                handleChangePassAirBag(newVal);
              }}
            >
              <option value={""}>Choose modification status</option>
              <option value={false}>No Modification</option>
              <option value={true}>Modified (sticker or faceplate)</option>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={12}>
          <Button
            size="small"
            color="primary"
            variant="contained"
            onClick={() => setModalPhotoOpen(true)}
          >
            Rapid Photos
          </Button>
        </Grid>

        <Grid item xs={12}>
          <Divider />
        </Grid>

        {!needToSave &&
          vehicleImageTypes.items.map((imageType, index) => {
            return (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <VehicleImageNamed
                  imageType={imageType}
                  vehicleId={vehicle.vehicleid}
                  vehicleImage={images[imageType.id]}
                  position={hasPosition ? { latitude, longitude } : null}
                  passAirbagModified={passAirbagModified}
                  onChangePassAirBagFromPhoto={handleChangePassAirBag}
                />
              </Grid>
            );
          })}
      </Grid>
      <Backdrop open={saving} style={{ zIndex: 10 }}>
        <CircularProgress />
      </Backdrop>
      <ModalGeneric
        open={openSingle}
        title="Add to Single Load"
        closeText="Create Single Load"
        onClose={createSingle}
        cancelText="Cancel"
        onCancel={() => {
          setOpenSingle(false);
          setLoadNumber("");
        }}
      >
        <TextField
          placeholder="Load Number"
          value={singleLoadNumber}
          onChange={(e) => setSingleLoadNumber(e.target.value)}
        />
      </ModalGeneric>
      <ModalGeneric
        open={modalPhotoOpen}
        actionStyle={{
          backgroundColor: "none",
          justifyContent: "space-between",
        }}
        fullScreen={true}
        onClose={() => {
          setModalPhotoOpen(false);
          refreshImages();
        }}
        onCancel={() => setMirror(!mirror)}
        cancelText={mirror ? "Mirror On" : "Mirror Off"}
      >
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              zIndex: 1000,
              width: "100%",
              flexDirection: "row",
            }}
          >
            {vehicleImageTypes.items.map((imageType, index) => {
              let color = "secondary";
              let variant = "outlined";
              if (images[imageType.id]) {
                color = "secondary";
                variant = "contained";
              }
              if (index === imageIndex) {
                color = "primary";
                variant = "contained";
              }
              return (
                <Button
                  key={index}
                  color={color}
                  size="small"
                  variant={variant}
                  onClick={() => setImageIndex(index)}
                >
                  {imageType.name}
                </Button>
              );
            })}
          </div>
          <Camera
            idealFacingMode={FACING_MODES.ENVIRONMENT}
            isFullscreen={true}
            isImageMirror={mirror}
            style={{
              width: "100%",
              height: "100%",
              position: "absolute",
              top: 0,
              left: 0,
            }}
            onTakePhotoAnimationDone={handleTakePhotoAnimationDone}
          />
        </div>
      </ModalGeneric>
      <ModalGeneric
        open={modalSign}
        onClose={() => {
          setModalSign(false);
        }}
        onCancel={() => {
          setModalSign(false);
        }}
        closeText={"Close"}
        title={"Sign Physical Inspection"}
      >
        <SignatureComponent
          userId={user.id}
          saveUrl={`api/vehicles/${vehicle.vehicleid}/sign/physicalInspection`}
        />
        {inspectionSignature && (
          <>
            <Typography variant="h5">
              Current VIN Inspection Signature
            </Typography>
            <img src={inspectionSignature.url} alt="inspection signature" />
          </>
        )}
      </ModalGeneric>
    </>
  );
}

function VehicleImageNamed(props) {
  const classes = useVehicleImageNamedStyles();
  const dispatch = useDispatch();

  const position = props.position;
  const passAirbagModified = props.passAirbagModified;

  const inputElement = useRef(null);
  const [previewUrl, setPreviewUrl] = useState("");
  const [cloudUrl, setCloudUrl] = useState(null);
  const [cloudUrlThumb, setCloudUrlThumb] = useState(null);

  const [redBg, setRedBg] = useState(false);
  const [mightHasKm, setMightHasKm] = useState(false);

  useEffect(() => {
    if (props.vehicleImage) {
      if (props.imageType.id === 17 && props.passAirbagModified === null) {
        setRedBg(true);
      }
      if (props.imageType.id === 8) {
        if (props.vehicleImage.mightHasKm) {
          setRedBg(true);
          setMightHasKm(true);
        }
      }
      if (isEmpty(props.vehicleImage.imagename)) {
        setCloudUrl(null);
        setCloudUrlThumb(null);
      } else {
        if (!isEmpty(props.vehicleImage.cloud_url_orig)) {
          setCloudUrl(props.vehicleImage.cloud_url_orig);
        } else {
          setCloudUrl(
            `${getEnvironmentURL()}/api/vehicles/${
              props.vehicleImage.vehicleid
            }/images/${props.vehicleImage.id}`
          );
        }
        if (!isEmpty(props.vehicleImage.cloud_url_thumb)) {
          setCloudUrlThumb(props.vehicleImage.cloud_url_thumb);
        } else {
          setCloudUrlThumb(
            `${getEnvironmentURL()}/api/vehicles/${
              props.vehicleImage.vehicleid
            }/images/${props.vehicleImage.id}?size=thumb`
          );
        }
      }
    }
  }, [props.vehicleImage]);

  const imageAdded = (event, imageTypeId) => {
    event.preventDefault();

    let reader = new FileReader();
    let file = event.target.files[0];
    event.target.files = null;

    reader.onloadend = () => {
      setPreviewUrl(reader.result);
      uploadImage(file);
    };

    try {
      reader.readAsDataURL(file);
    } catch (e) {}
  };

  const deleteImage = () => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this image?"
    );
    if (!confirmDelete) {
      return;
    }
    dispatch(
      utilityActions.deleteOneUtility(
        "vehicle_images",
        props.vehicleImage.id,
        null,
        () => {
          setPreviewUrl("");
          refreshImages();
        }
      )
    );
  };

  const uploadImage = (file) => {
    let formData = new FormData();
    formData.append("photo", file);
    formData.append("vehicleId", props.vehicleId);
    formData.append("note", props.imageType.name);
    formData.append("type", props.imageType.id);
    formData.append("latitude", position?.latitude);
    formData.append("longitude", position?.longitude);

    fetch(`${getEnvironmentURL()}/api/vehicles/${props.vehicleId}/images`, {
      // Your POST endpoint
      method: "POST",
      headers: { ...authHeader() },
      body: formData,
    })
      .then(
        (response) => response.json() // if the response is a JSON object
      )
      .then(
        //TODO:save also in customer object
        (success) => {
          // console.log(success); // Handle the success response object
          refreshImages();
        }
      )
      .catch((error) => {
        // console.log(error); // Handle the error response object
        setPreviewUrl("");
      });
  };

  const refreshImages = () => {
    dispatch(utilityActions.getOneUtility("vehicles", props.vehicleId));
  };

  return (
    <Paper
      className={classes.paper}
      align="center"
      style={{
        textAlign: "center",
        backgroundColor: redBg ? "red" : "inherit",
      }}
    >
      {props.imageType.name}
      <br />
      {position ? (
        <Typography style={{ color: "green" }}>Position Available</Typography>
      ) : (
        <Typography style={{ color: "red" }}>No Position Available</Typography>
      )}
      <br />
      {mightHasKm && (
        <Typography style={{ fontWeight: "bold" }}>
          ODO might be in KM
        </Typography>
      )}
      {props.imageType.id === 17 && passAirbagModified === null && (
        <FormControl>
          <InputLabel shrink>Modification Status</InputLabel>
          <Select
            native
            value={passAirbagModified === null ? "" : passAirbagModified}
            onChange={(e) => {
              let newVal = e.target.value;
              if (newVal === "") {
                newVal = null;
              } else if (newVal === "true") {
                newVal = true;
              } else {
                newVal = false;
              }
              props.onChangePassAirBagFromPhoto(newVal);
            }}
          >
            <option value={""}>Choose modification status</option>
            <option value={false}>No Modification</option>
            <option value={true}>Modified (sticker or faceplate)</option>
          </Select>
        </FormControl>
      )}
      <input
        id={`image_upload_${props.imageType.id}`}
        type="file"
        name={`files[${props.imageType.id}]`}
        accept="image/*"
        capture="camera"
        style={{ display: "none" }}
        onChange={(event) => imageAdded(event, props.imageType.id)}
      />
      <input
        id={`image_upload_file_${props.imageType.id}`}
        ref={inputElement}
        type="file"
        name={`files[${props.imageType.id}]`}
        accept="image/*"
        // capture="camera"
        style={{ display: "none" }}
        onChange={(event) => imageAdded(event, props.imageType.id)}
      />

      {props.vehicleImage ? (
        <>
          <Link target="_blank" href={cloudUrl}>
            <img
              alt="img"
              src={cloudUrlThumb}
              style={{
                width: 100,
                height: 100,
                maxHeight: 100,
                maxWidth: 100,
              }}
            />
          </Link>
          <div style={{ textAlign: "left" }}>
            <Button color="primary" variant="contained" onClick={deleteImage}>
              Delete
            </Button>{" "}
            User: {props.vehicleImage.uploaded_by.name}
            <br />
            {moment(props.vehicleImage.dateuploaded).fromNow()}
            <br />
            {props.vehicleImage.dateuploaded}
          </div>
        </>
      ) : (
        <>
          {previewUrl ? (
            <img
              src={previewUrl}
              alt="img"
              style={{
                width: 100,
                height: 100,
                maxWidth: 100,
                maxHeight: 100,
              }}
            />
          ) : (
            <>
              <label
                htmlFor={`image_upload_${props.imageType.id}`}
                style={{
                  display: "inline-block",
                  align: "center",
                  width: 100,
                  maxWidth: 100,
                  height: 100,
                  maxHeight: 100,
                  border: "1px solid black",
                }}
              >
                <img
                  alt="img"
                  src="https://via.placeholder.com/100/000000/FFFFFF?text=Camera"
                  style={{
                    width: "100%",
                    width: 100,
                    height: 100,
                    maxWidth: 100,
                    maxHeight: 100,
                  }}
                />
              </label>
              <br />
              <label htmlFor={`image_upload_${props.imageType.id}`}>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    inputElement.current.click();
                  }}
                >
                  Upload File
                </Button>
              </label>
            </>
          )}
        </>
      )}
    </Paper>
  );
}

const usePhotoUploadPageStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
}));

const useVehicleImageNamedStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));

export { PhotoUploadPage2 as default };
