import { auctionConstants } from "../_constants";

const initialState = {
  auctions: {
    loading: false,
    updating: false,
    items: [],
    page: 1,
    per_page: 10,
    total: 0
  }
  // auction: {
  //   loading: false,
  //   updating: false
  // }
};

export function auctions(state = initialState, action) {
  switch (action.type) {
    case auctionConstants.AUCTIONS_GET_REQUEST:
      return {
        ...state,
        auctions: {
          ...state.auctions,
          loading: true
        }
      };
    case auctionConstants.AUCTIONS_GET_SUCCESS:
      return {
        ...state,
        auctions: {
          ...state.auctions,
          items: action.auctions.data,
          page: action.auctions.current_page,
          per_page: action.auctions.per_page,
          total: action.auctions.total,
          loading: false
        }
      };
    case auctionConstants.AUCTIONS_GET_FAILURE:
      return {
        ...state,
        auctions: {
          ...state.auctions,
          loading: false
        }
      };

    case auctionConstants.AUCTIONS_UPDATE_REQUEST:
      return {
        ...state,
        updating: true
      };
    case auctionConstants.AUCTIONS_UPDATE_SUCCESS:
      return {
        ...state,
        auctions: {
          ...state.auctions,
          items: state.auctions.items.map(item =>
            Number(item.auctionid) === Number(action.auction.auctionid)
              ? action.auction
              : item
          )
        }
      };
    case auctionConstants.AUCTIONS_UPDATE_FAILURE:
      return {
        ...state,
        auctions: {
          ...state.auctions,
          updating: false
        }
      };
    default:
      return state;
  }
}
