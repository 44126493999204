import React from "react";
import { connect } from "react-redux";

import { userActions, alertActions } from "../_actions";
import { getEnvironment } from "../_helpers";

import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";

import SnackbarContent from "@material-ui/core/SnackbarContent";
import ErrorIcon from "@material-ui/icons/Error";

import { PageWithAppDrawer2 } from "../_components/main/PageWithAppDrawer2";
import { CircularProgress } from "@material-ui/core";
import { userService } from "../_services";

class ForgotPasswordPage extends React.Component {
  constructor(props) {
    super(props);

    // reset login status
    this.props.dispatch(userActions.logout());
    const env = getEnvironment("reset");

    this.state = {
      email: "",
      environmentDVS: env,
      submitted: false,
      error: null,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const { dispatch } = this.props;
    const { environmentDVS } = this.state;
    dispatch(userActions.setEnvironment(environmentDVS));
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(alertActions.clear());
  }

  handleClearAlert = () => {
    const { dispatch } = this.props;
    dispatch(alertActions.clear());
  };

  handleChange(e) {
    const { dispatch } = this.props;
    const { environmentDVS } = this.props;

    let { name, value } = e.target;
    if (name === "environmentDVS") {
      value = environmentDVS.find((obj) => obj.name === value);
      dispatch(userActions.setEnvironment(value));
    }
    this.setState({ [name]: value });
  }

  handleSubmit(e) {
    e.preventDefault();
    const { email } = this.state;

    this.setState({ submitted: true });
    if (email) {
      userService.forgotPassword(email).then((response) => {
        let errorText = null;
        if (response && response.error) {
          if (response.error === true) {
            errorText = response.message;
          }
        }
        if (errorText) {
          this.setState({ error: true, submitted: false });
        } else {
          this.setState({ error: false, submitted: false });
        }
      });
    }
  }

  render() {
    const { classes } = this.props;
    const { email, submitted, error } = this.state;
    return (
      <PageWithAppDrawer2
        pageTitle={"Forgot Password"}
        showSideBar={false}
        showHomeLinks={true}
      >
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <div className={classes.paper}>
            <Avatar className={classes.avatar}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Forgot Password
            </Typography>
            <form
              className={classes.form}
              noValidate
              onSubmit={this.handleSubmit}
            >
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address / Username"
                name="email"
                type="email"
                autoComplete="email"
                autoFocus
                value={email}
                onChange={this.handleChange}
              />
              {error === true && (
                <SnackbarContent
                  style={{ backgroundColor: "red" }}
                  aria-describedby="client-snackbar"
                  message={
                    <span
                      id="client-snackbar"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <ErrorIcon />
                      Unable to process a forgotten password. Please contact DVS
                      Support
                      <a href="mailto:dvsri@godvs.com">DVS Support</a>
                    </span>
                  }
                />
              )}
              {error === false && (
                <SnackbarContent
                  style={{ backgroundColor: "lightgrey" }}
                  aria-describedby="client-snackbar"
                  message={
                    <span
                      id="client-snackbar"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <ErrorIcon />
                      Please check your email. If your email/username exists you
                      will receive an email with a link to reset your password.
                      If you don't receive an email please contact DVS Support{" "}
                      <a
                        style={{ display: "inline" }}
                        href="mailto:dvsri@godvs.com"
                      >
                        DVS Support
                      </a>
                    </span>
                  }
                />
              )}

              <Button
                type="submit"
                disabled={submitted || email.length === 0}
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                startIcon={submitted ? <CircularProgress size={14} /> : null}
              >
                Reset Password
              </Button>
              <Grid container>
                <Grid item xs>
                  <Link href="#" variant="body2">
                    Back to Login Page
                  </Link>
                </Grid>
              </Grid>
            </form>
          </div>
        </Container>
      </PageWithAppDrawer2>
    );
  }
}

const styles = (theme) => ({
  "@global": {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
});

function mapStateToProps(state) {
  const { alert } = state;
  const { loggingIn, environmentDVS } = state.authentication;
  return {
    alert,
    loggingIn,
    environmentDVS,
  };
}

// const connectedForgotPasswordPage = connect(mapStateToProps)(ForgotPasswordPage);
// export default withRouter(connectedForgotPasswordPage);

// export default withRouter(connect(mapStateToProps)(ForgotPasswordPage));

const connectedForgotPasswordPage = connect(mapStateToProps)(
  withStyles(styles)(ForgotPasswordPage)
);
export { connectedForgotPasswordPage as ForgotPasswordPage };
