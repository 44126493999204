import React from "react";
import { Link as RouterLink } from "react-router-dom";
// import Link from "@material-ui/core/Link";
import { connect, useSelector, useDispatch } from "react-redux";
import { withStyles } from "@material-ui/core/styles";

import { utilityActions } from "../_actions";

import { PageWithAppDrawer2 } from "../_components/main/PageWithAppDrawer2";
import ResponsiveGrid from "../_components/generic/ResponsiveGrid";

import FiltersGrid from "../_components/generic/FiltersGrid";
import { Paper, Button } from "@material-ui/core";
import { utilityService } from "../_services";
import { hashHistory } from "../_helpers";
import moment from "moment";

class ClientTransportersPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {}

  render() {
    const { classes } = this.props;

    return (
      <PageWithAppDrawer2 pageTitle={"Client Transporters"}>
        <Paper className={classes.paper}>
          <ClientTransportersTable onAdd={this.handleAddItem} />
        </Paper>
      </PageWithAppDrawer2>
    );
  }

  handleAddItem = (refreshObject) => {
    const { webUser, dispatch } = this.props;
    const isAdmin = webUser.roleid === 2 ? true : false;
    const isClient = webUser.roleid !== 2 ? true : false;
    const clientId = webUser.client_id;
    utilityService
      .addUtility("client_transporters", {
        name: "NEW TRANSPORTER",
        client_id: isClient ? clientId : 0,
      })
      .then((response) => {
        hashHistory.push(`/clientTransporters/${response.id}`);
        dispatch(
          utilityActions.getUtility("client_transporters", refreshObject)
        );
      });
  };
}

function ClientTransportersTable(props) {
  const dispatch = useDispatch();
  const items = useSelector((state) => state.utility.client_transporters);
  const webUser = useSelector((state) => state.authentication.webUser);
  const isAdmin = webUser.roleid === 2 ? true : false;
  const isClient = webUser.roleid !== 2 ? true : false;
  const clientId = webUser.client_id;
  const columns = [
    {
      width: 2,
      title: "Client Name",
      field: "client.clientname",
      dataRender: (item) =>
        (item.client && item.client.clientname) || <i>Unassigned</i>,
    },
    {
      width: 2,
      title: "Name",
      field: "name",
      dataRender: (item) => (
        <RouterLink to={`/clientTransporters/${item.id}`}>
          {item.name}
        </RouterLink>
      ),
    },
    {
      width: 1,
      title: "Created",
      field: "created_at",
      dataRender: (item) => moment(item.created_at).format("M/D/Y"),
    },
  ];

  const [availableFilters, setAvailableFilters] = React.useState([
    isClient && {
      name: "Client",
      field: "client_id",
      operator: "eq",
      value: clientId,
      type: "hidden",
      default: "",
    },
    isAdmin && {
      name: "Client",
      field: "client_id",
      operator: "eq",
      value: "",
      type: "choice",
      useUtility: true,
      utility: "clients_active",
      utilityValue: "clientname",
      utilityOrder: {
        field: "clientname",
        direction: "asc",
      },
      utilityFilters: [
        {
          field: "clientactive",
          operator: "eq",
          value: 2,
        },
      ],
      choices: [],
      default: "",
    },
    {
      name: "Name",
      field: "name",
      operator: "like",
      value: "",
      type: "text",
      default: "",
    },
  ]);

  const [getData, setGetData] = React.useState(false);

  const [page, setPage] = React.useState(
    items.page ? Number(items.page) - 1 : 0
  );
  const [perPage, setPerPage] = React.useState(
    items.per_page ? Number(items.per_page) : 0
  );
  const [total, setTotal] = React.useState(
    items.total ? Number(items.total) : 0
  );

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
    setGetData(true);
  };
  const handleChangeRowsPerPage = (event) => {
    const value = parseInt(event.target.value);
    setPerPage(value);
    setGetData(true);
  };
  React.useEffect(() => {
    if (getData === true) {
      setGetData(false);
      getNewData();
    }
  }, [getData]);

  React.useEffect(() => {
    setTotal(items.total ? Number(items.total) : 0);
    setPage(items.page ? Number(items.page) : 0);
    setPerPage(items.per_page ? Number(items.per_page) : 10);
  }, [items]);

  React.useEffect(() => {
    if (items.items.length === 0) {
      getNewData();
    }
  }, []);

  // const handleDownload = () => {};

  const getNewData = () => {
    let newFilters = availableFilters
      .filter((filter) => {
        if (["", null].includes(filter.value)) return false;
        if (
          ["isnull", "notnull"].includes(filter.operator) &&
          filter.value === false
        )
          return false;
        return true;
      })
      .map((filter) => {
        return {
          field: filter.field,
          operator: filter.operator,
          value: filter.value,
        };
      });
    dispatch(
      utilityActions.getUtility("client_transporters", {
        page,
        perPage,
        filters: newFilters,
        orders: [{ field: "name", direction: "asc" }],
      })
    );
  };

  const handleFilterChange = (newFilter) => {
    const newFilters = availableFilters.map((filter) => {
      if (filter.field !== newFilter.field) return filter; // not the droid we're looking for, return unchanged
      filter.value = newFilter.value;
      return filter;
    });
    setAvailableFilters(newFilters);
    setPage(1);
    setGetData(true);
  };

  return (
    <>
      <Button
        color="primary"
        variant="contained"
        onClick={() => {
          props.onAdd({
            page,
            perPage,
            filters: availableFilters,
            orders: [{ field: "name", direction: "asc" }],
          });
        }}
      >
        Add Transporter
      </Button>
      <FiltersGrid
        availableFilters={availableFilters}
        onChange={handleFilterChange}
      />
      <ResponsiveGrid
        loading={items.loading}
        columns={columns}
        data={items.items}
        page={page - 1} // API has a 1 based page count, Material UI has a zero based page count
        perPage={perPage}
        total={total}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        // showActions
        // onDownload={handleDownload}
      />
    </>
  );
}

const styles = (theme) => ({
  root: {
    display: "flex",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
});

function mapStateToProps(state) {
  const { authentication } = state;
  const { user, webUser } = authentication;
  return {
    user,
    webUser,
  };
}

const connectedClientTransportersPage = connect(mapStateToProps)(
  withStyles(styles)(ClientTransportersPage)
);
export { connectedClientTransportersPage as ClientTransportersPage };
