import { paperworkConstants } from "../_constants";

const initialState = {
  paperwork: {
    loading: false,
    data: {}
  }
};

export function paperwork(state = initialState, action) {
  switch (action.type) {
    case paperworkConstants.PAPERWORK_GET_REQUEST:
      return {
        ...state,
        paperwork: {
          ...state.paperwork,
          loading: true
        }
      };
    case paperworkConstants.PAPERWORK_GET_SUCCESS:
      return {
        ...state,
        paperwork: {
          ...state.paperwork,
          loading: false,
          data: action.paperwork
        }
      };
    case paperworkConstants.PAPERWORK_GET_FAILURE:
      return {
        ...state,
        paperwork: {
          ...state.paperwork,
          loading: false
        }
      };

    case paperworkConstants.PAPERWORK_UPDATE_REQUEST:
      return {
        ...state,
        paperwork: {
          ...state.paperwork
          // loading: true
        }
      };
    case paperworkConstants.PAPERWORK_UPDATE_SUCCESS:
      return {
        ...state,
        paperwork: {
          ...state.paperwork,
          // loading: false,
          data: action.paperwork
        }
      };
    case paperworkConstants.PAPERWORK_UPDATE_FAILURE:
      return {
        ...state,
        paperwork: {
          ...state.paperwork
          // loading: false
        }
      };

    case paperworkConstants.PAPERWORK_ADD_REQUEST:
      return {
        ...state,
        paperwork: {
          ...state.paperwork,
          loading: true
        }
      };
    case paperworkConstants.PAPERWORK_ADD_SUCCESS:
      return {
        ...state,
        paperwork: {
          ...state.paperwork,
          loading: false,
          data: action.paperwork
        }
      };
    case paperworkConstants.PAPERWORK_ADD_FAILURE:
      return {
        ...state,
        paperwork: {
          ...state.paperwork,
          loading: false
        }
      };

    case paperworkConstants.PAPERWORK_SET_LOCAL:
      return {
        ...state,
        paperwork: {
          ...state.paperwork,
          data: {
            ...state.paperwork.data,
            ...action.data
          }
        }
      };
    case paperworkConstants.PAPERWORK_CLEAR:
      return {
        ...state,
        paperwork: {
          ...state.paperwork,
          data: {}
        }
      };

    default:
      return state;
  }
}
