import {
  Button,
  CircularProgress,
  Grid,
  makeStyles,
  MenuItem,
  Paper,
  Select,
  TextField,
} from "@material-ui/core";
import React, { Fragment, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { utilityActions } from "../_actions";
import FiltersGrid from "../_components/generic/FiltersGrid";

import { PageWithAppDrawer2 } from "../_components/main/PageWithAppDrawer2";
import { utilityService } from "../_services";

export default function PdfFormFieldsPage(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const items = useSelector((state) => state.utility.pdf_form_fields);
  const forms = useSelector((state) => state.utility.pdf_forms);

  const [availableFilters, setAvailableFilters] = useState([
    {
      name: "Name",
      field: "pdf_form.name",
      operator: "like",
      value: "",
      type: "text",
      default: "",
    },
    {
      name: "Form",
      field: "clientid",
      operator: "eq",
      value: "",
      type: "choice",
      useUtility: true,
      utility: "clients_active",
      utilityValue: "clientname",
      utilityOrder: {
        field: "clientname",
        direction: "asc",
      },
      utilityFilters: [
        {
          field: "clientactive",
          operator: "eq",
          value: 2,
        },
      ],
      choices: [],
      default: "",
    },
  ]);

  const handleFilterChange = async (newFilter) => {
    const newFilters = availableFilters.map((filter) => {
      if (filter.field !== newFilter.field) return filter; // not the droid we're looking for, return unchanged
      filter.value = newFilter.value;
      return filter;
    });
    await setAvailableFilters(newFilters);

  };

  useEffect(() => {
    dispatch(
      utilityActions.getUtility("pdf_forms", {
        perPage: 1000,
        orders: [
          {
            field: "name",
            direction: "asc",
          },
        ],
      })
    );
  }, []);

  useEffect(() => {
    let newFilters = availableFilters
      .filter((filter) => {
        if ([null, false].includes(filter)) return false;
        if (["", null].includes(filter.value)) return false;
        if (
          ["isnull", "notnull"].includes(filter.operator) &&
          filter.value === false
        )
          return false;
        return true;
      })
      .map((filter) => {
        return {
          field: filter.field,
          operator: filter.operator,
          value: filter.value,
        };
      });
    dispatch(
      utilityActions.getUtility("pdf_form_fields", {
        perPage: 1000,
        orders: [
          {
            field: "pdf_form_id",
            direction: "asc",
          },
        ],
        filters: newFilters
      })
    );
  }, [availableFilters]);

  return (
    <PageWithAppDrawer2 pageTitle="Form Fields">
      <Paper className={classes.paper}>
        <FiltersGrid
          availableFilters={availableFilters}
          onChange={handleFilterChange}
        />
        {items.loading ? (
          <CircularProgress />
        ) : (
          <Grid container style={{ marginTop: 20 }}>
            <Grid container style={{ marginTop: 20 }}>
              <Grid item xs={2} style={{ fontWeight: "bold" }}>
                Form Name
              </Grid>
              <Grid item xs={2} style={{ fontWeight: "bold" }}>
                Field Name
              </Grid>
              <Grid item xs={4} style={{ fontWeight: "bold" }}>
                Static Value
              </Grid>
              <Grid item xs={4} style={{ fontWeight: "bold" }}>
                Object Value
              </Grid>
            </Grid>
            {items.items.map((item, index) => (
              <FormField item={item} key={index} index={index} />
            ))}
          </Grid>
        )}
      </Paper>
    </PageWithAppDrawer2>
  );
}

function FormField(props) {
  const dispatch = useDispatch();
  const { item, index } = props;
  const even = Number(index) % 2 === 0;

  const updateItem = (e) => {
    const value = e.target.value;
    const name = e.target.name;

    dispatch(
      utilityActions.updateUtility("pdf_form_fields", item.id, {
        [name]: value,
      }),
      (response) => {
        console.log("response", response);
      }
    );
  };

  return (
    <Grid
      container
      style={{ backgroundColor: even ? "lightgrey" : "inherit" }}
      spacing={1}
    >
      <Grid item xs={2}>
        {item.pdf_form && item.pdf_form.name}
      </Grid>
      <Grid item xs={2}>
        <TextField name="form_field" value={item.form_field || ""} fullWidth />
      </Grid>
      <Grid item xs={4}>
        <Select name="is_static" value={item.is_static || 0} onChange={updateItem}>
          <MenuItem value={0}>No</MenuItem>
          <MenuItem value={1}>Yes</MenuItem>
        </Select>
        {item.is_static === 1 && (
          <TextField name="static_value" value={item.static_value || ""} fullWidth />
        )}
      </Grid>
      <Grid item xs={4}>
        {item.is_static === 0 && (
          <>
            <TextField
              name="object_name"
              placeholder="Object Name"
              value={item.object_name || ""}
              fullWidth
            />
            <TextField
              name="object_value"
              placeholder="Object Value"
              value={item.object_value || ""}
              fullWidth
            />
            <TextField
              name="regex_pattern"
              placeholder="Regex Pattern"
              value={item.regex_pattern || ""}
              fullWidth
            />
          </>
        )}
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
}));

export { PdfFormFieldsPage };
