import React, { useState, useEffect } from "react";

import {
  TextField,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  Button,
  Checkbox,
  FormControlLabel,
  CircularProgress,
  Snackbar,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import { utilityService } from "../../_services";

export default function ClientGstHst(props) {
  const clientId = props.clientId || null;
  const [loading, setLoading] = useState(false);
  const [clientGstInfoId, setClientGstInfoId] = useState(null);
  const [docsComplete, setDocsComplete] = useState(false);
  const [businessNumber, setBusinessNumber] = useState(null);
  const [mailingAddressId, setMailingAddressId] = useState(null);
  const [businessAddressId, setBusinessAddressId] = useState(null);
  const [thirdPartyName, setThirdPartyName] = useState(null);
  const [thirdPartyAddressId, setThirdPartyAddressId] = useState(null);
  const [addresses, setAddresses] = useState([]);
  const [snackbarSaved, setSnackbarSaved] = useState({
    open: false,
    severity: "success",
    message: "",
    duration: 1000,
  });

  useEffect(() => {
    if (clientId) {
      getData();
    }
  }, [clientId]);

  const getData = () => {
    utilityService
      .getUtility("client_gst_info", {
        filters: [
          {
            field: "client_id",
            operator: "eq",
            value: clientId,
          },
        ],
      })
      .then((response) => {
        if (response.data.length === 1) {
          setClientGstInfoId(response.data[0].id);
          setBusinessNumber(response.data[0].business_number);
          setMailingAddressId(response.data[0].mailing_address_id);
          setBusinessAddressId(response.data[0].business_address_id);
          setThirdPartyAddressId(response.data[0].third_party_address_id);
          setThirdPartyName(response.data[0].third_party_name);
          setDocsComplete(response.data[0].docs_complete);
        }
      });

    utilityService
      .getUtility("client_locations", {
        filters: [
          {
            field: "client_id",
            operator: "eq",
            value: clientId,
          },
        ],
      })
      .then((response) => {
        setAddresses(response.data);
      });
  };

  const save = () => {
    setLoading(true);
    const payload = {
      client_id: clientId,
      business_number: businessNumber,
      mailing_address_id: mailingAddressId,
      business_address_id: businessAddressId,
      third_party_address_id: thirdPartyAddressId,
      third_party_name: thirdPartyName,
      docs_complete: docsComplete,
    };
    if (!clientGstInfoId) {
      utilityService
        .addUtility("client_gst_info", payload)
        .catch((e) => {
          setSnackbarSaved({
            open: true,
            severity: "error",
            message: "Unable to save GST Info",
            duration: 3000,
          });
        })
        .then((response) => {
          setSnackbarSaved({
            open: true,
            severity: "success",
            message: "Client GST Info Saved",
            duration: 3000,
          });
        })
        .finally(() => {
          setLoading(false);
          getData();
        });
    } else {
      utilityService
        .updateUtility("client_gst_info", clientGstInfoId, payload)
        .catch((e) => {
          setSnackbarSaved({
            open: true,
            severity: "error",
            message: "Unable to save GST Info",
            duration: 3000,
          });
        })
        .then((response) => {
          setSnackbarSaved({
            open: true,
            severity: "success",
            message: "Client GST Info Saved",
            duration: 3000,
          });
        })
        .finally(() => {
          setLoading(false);
          getData();
        });
    }
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {loading ? (
            <CircularProgress />
          ) : (
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={save}
            >
              Update GST/HST Information
            </Button>
          )}
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={docsComplete}
                onChange={(e) => setDocsComplete(e.target.checked)}
                name="docsComplete"
              />
            }
            label="Documents Complete"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            id="businessNumber"
            label="Business Number"
            variant="outlined"
            value={businessNumber || ""}
            onChange={(e) => setBusinessNumber(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel shrink id="mailing-address-label">
              Mailing Address
            </InputLabel>
            <Select
              labelId="mailing-address-label"
              id="mailing-adress"
              value={mailingAddressId || ""}
              onChange={(e) => setMailingAddressId(e.target.value)}
              displayEmpty
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {addresses.map((a) => (
                <MenuItem key={String(a.id)} value={a.id}>
                  {a.name}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>GST Mailing Address</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel shrink id="business-address-label">
              Business Address
            </InputLabel>
            <Select
              labelId="business-address-label"
              id="business-adress"
              value={businessAddressId || ""}
              onChange={(e) => setBusinessAddressId(e.target.value)}
              displayEmpty
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {addresses.map((a) => (
                <MenuItem key={String(a.id)} value={a.id}>
                  {a.name}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>GST Business Address</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            id="thirdPartyName"
            label="Third Party Name"
            variant="outlined"
            value={thirdPartyName || ""}
            onChange={(e) => setThirdPartyName(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel shrink id="third-party-address-label">
              Third Party Address
            </InputLabel>
            <Select
              labelId="third-party-address-label"
              id="third-party-adress"
              value={thirdPartyAddressId || ""}
              onChange={(e) => setThirdPartyAddressId(e.target.value)}
              displayEmpty
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {addresses.map((a) => (
                <MenuItem key={String(a.id)} value={a.id}>
                  {a.name}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>GST Third Party Address</FormHelperText>
          </FormControl>
        </Grid>
      </Grid>
      <Snackbar
        color={snackbarSaved.severity}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={snackbarSaved.open}
        autoHideDuration={snackbarSaved.duration}
        onClose={() =>
          setSnackbarSaved({
            ...snackbarSaved,
            open: false,
          })
        }
      >
        <MuiAlert severity={snackbarSaved.severity} variant="filled">
          {snackbarSaved.message}
        </MuiAlert>
      </Snackbar>
    </>
  );
}
