import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { pdf, BlobProvider } from "@react-pdf/renderer";

import ResponsiveGrid from "../generic/ResponsiveGrid";

import Button from "@material-ui/core/Button";
import { formatNumberToStringMoney, getEnvironmentURL } from "../../_helpers";
import {
  Backdrop,
  CircularProgress,
  Grid,
  Typography,
} from "@material-ui/core";
import { utilityService } from "../../_services";
import { utilityActions } from "../../_actions";
import moment from "moment";
import { MasterInvoiceDocument } from "../pdf/MasterInvoice";

export default function MasterBatchManage(props) {
  const dispatch = useDispatch();

  const mib = useSelector((state) => state.utility.master_invoice_batches_item);
  const shouldRefresh = props.shouldRefresh ? props.shouldRefresh : () => {};

  const [id, setId] = useState(null);
  const [backdropOpen, setBackdropOpen] = useState(false);
  const [downloadPdf, setDownloadPdf] = useState(false);

  // const [alertOpen, setAlertOpen] = useState(false);
  // const [alertSeverity, setAlertSeverity] = useState("success");
  // const [alertMessage, setAlertMessage] = useState("");

  const columns = [
    {
      width: 2,
      title: "Batch #",
      field: "id",
    },
    {
      width: 2,
      title: "Date",
      field: "invoicedate",
      dataRender: (item) => moment(item.invoicedate).format("M/D/Y"),
    },
    {
      width: 2,
      title: "Count",
      field: "invoice_count",
    },
    {
      width: 3,
      title: "Total",
      field: "invoice_total",
      dataRender: (item) => formatNumberToStringMoney(item.invoice_total),
    },
    {
      width: 3,
      title: "",
      field: null,
      dataRender: (item) => (
        <Button
          variant="contained"
          size="small"
          color="primary"
          onClick={() => {
            removeInvoiceBatch(item.id);
          }}
        >
          Remove
        </Button>
      ),
    },
  ];

  const removeInvoiceBatch = (invoiceBatchId) => {
    console.log("remove invoice batch", invoiceBatchId);
    setBackdropOpen(true);
    utilityService
      .getEndpoint(
        `api/master_invoice_batches/${id}/invoice_batches/${invoiceBatchId}`,
        "DELETE"
      )
      .then((response) => {
        shouldRefresh(true);
        getData();
      })
      .catch((error) => {})
      .finally(() => {
        setBackdropOpen(false);
      });
  };

  useEffect(() => {
    setId(props.id);
  }, [props]);

  useEffect(() => {
    if (id) {
      getData();
    }
  }, [id]);

  //   useEffect(() => {
  //     console.log("mib", mib);
  //   }, [mib]);

  const getData = () => {
    dispatch(
      utilityActions.getOneUtility("master_invoice_batches", id, {
        relations: ["invoice_batches_full", "client"],
      })
    );
  };

  if (mib && mib.loading === false) {
    return (
      <div>
        <Backdrop open={backdropOpen} style={{ zIndex: 1000 }}></Backdrop>
        <Typography variant="h5">{mib.client.clientname}</Typography>
        <Grid container spacing={1} style={{ marginBottom: 10, marginTop: 10 }}>
          <Grid item>
            <Button
              variant="contained"
              size="small"
              color="primary"
              onClick={() => setDownloadPdf(true)}
            >
              {downloadPdf ? (
                <BlobProvider document={<MasterInvoiceDocument item={mib} />}>
                  {({ blob, url, loading, error }) => {
                    if (!loading && url) {
                      window.open(url, "_blank");
                      setDownloadPdf(false);
                      return null;
                    }
                    return (
                      <>
                        <CircularProgress color="secondary" size={22} />
                        Get MIB PDF
                      </>
                    );
                  }}
                </BlobProvider>
              ) : (
                <>Get MIB PDF</>
              )}
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              size="small"
              color="primary"
              href={`${getEnvironmentURL()}/api/reports/management/masterInvoice/${
                mib.id
              }`}
            >
              Get MIB Excel
            </Button>
          </Grid>
        </Grid>
        <ResponsiveGrid
          columns={columns}
          data={mib.invoice_batches_full}
          usePagination={false}
        />
      </div>
    );
  } else {
    return (
      <div>
        <CircularProgress />
      </div>
    );
  }
}
