import { authHeader, getEnvironmentURL } from "../_helpers";
import {
  buildRelations,
  buildFilters,
  buildOrders,
  buildAppends,
  handleResponse,
} from "../_helpers";

export const utilityService = {
  getUtility,
  getOneUtility,
  addUtility,
  deleteOneUtility,
  updateUtility,
  getEndpoint,
};

function getUtility(utilityName, options) {
  let filters = options.filters || [];
  let relations = options.relations || [];
  let relationsAdd = (options && options.relationsAdd) || [];
  let orders = options.orders || [];
  let perPage = options.perPage || 10;
  let page = options.page || 1;
  let appends = options.appends || [];
  const params = options.params;

  // console.log("service getUtility", {filters})

  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  const filterStrings = buildFilters(filters);
  const filterString = Array.isArray(filterStrings)
    ? filterStrings.join("&")
    : "null";
  const relationStrings = buildRelations(relations);
  const relationString = Array.isArray(relationStrings)
    ? relationStrings.join("&")
    : "null";
  const orderStrings = buildOrders(orders);
  const orderString = Array.isArray(orderStrings)
    ? orderStrings.join("&")
    : "null";
  const appendStrings = buildAppends(appends);
  const appendString = Array.isArray(appendStrings)
    ? appendStrings.join("&")
    : "null";
  const relationAddStrings = buildRelations(relationsAdd, "add");
  const relationAddString = Array.isArray(relationAddStrings)
    ? relationAddStrings.join("&")
    : "null";

  const url = getEnvironmentURL();
  return fetch(
    `${url}/api/${utilityName}?per_page=${perPage}&page=${page}&${filterString}&${relationString}&${orderString}&${appendString}&${params}&${relationAddString}`,
    requestOptions
  )
    .then(handleResponse)
    .then((response) => {
      if (response.data) return response;
      return [];
    });
}

function getOneUtility(utilityName, id, options) {
  let filters = (options && options.filters) || [];
  let relations = (options && options.relations) || [];
  let relationsAdd = (options && options.relationsAdd) || [];

  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  const filterStrings = buildFilters(filters);
  const filterString = Array.isArray(filterStrings)
    ? filterStrings.join("&")
    : "null";
  const relationStrings = buildRelations(relations);
  const relationString = Array.isArray(relationStrings)
    ? relationStrings.join("&")
    : "null";
  const relationAddStrings = buildRelations(relationsAdd, "add");
  const relationAddString = Array.isArray(relationAddStrings)
    ? relationAddStrings.join("&")
    : "null";

  const url = getEnvironmentURL();
  return fetch(
    `${url}/api/${utilityName}/${id}?${filterString}&${relationString}&${relationAddString}`,
    requestOptions
  )
    .then(handleResponse)
    .then((response) => {
      return response;
    });
}

function addUtility(utilityName, data) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json", "Accept": "application/json" },
    body: JSON.stringify(data),
  };

  const url = getEnvironmentURL();
  return fetch(`${url}/api/${utilityName}`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    });
}

function deleteOneUtility(utilityName, id, options) {
  const requestOptions = {
    method: "DELETE",
    headers: authHeader(),
  };

  const url = getEnvironmentURL();
  return fetch(`${url}/api/${utilityName}/${id}`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      // console.log("delete response", response.status);
      return response;
    });
}

function updateUtility(utilityName, id, item) {
  const requestOptions = {
    method: "PUT",
    headers: { ...authHeader(), "Content-Type": "application/json", "Accept": "application/json" },
    body: JSON.stringify(item),
  };

  const url = getEnvironmentURL();
  return fetch(`${url}/api/${utilityName}/${id}`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    });
}

function getEndpoint(path, method, options = {}) {
  let relations = (options && options.relations) || [];
  let params = (options && options.params) || "";
  let body = (options && options.body) || null;
  let skipAuth = (options && options.noAuth) || false;

  const authHeaders = skipAuth ? {} : authHeader();

  const requestOptions = {
    method,
    headers: {
      ...authHeaders,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: body && JSON.stringify(body),
  };

  const relationStrings = buildRelations(relations);
  const relationString = Array.isArray(relationStrings)
    ? relationStrings.join("&")
    : "null";

  const url = getEnvironmentURL();
  return fetch(`${url}/${path}?${relationString}&${params}`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
}
