export function formatNumberToStringMoney(num) {
  // console.log("num", num);
  if (isNaN(num)) return 0;
  if (num === null) return 0;
  if (typeof num != "string") num = num.toFixed(2).toString();
  return "$" + num.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}

export function formatNumberWithCommas(num) {
  if (isNaN(num)) return 0;
  if (num === null) return 0;
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
