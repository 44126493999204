import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { connect, useSelector, useDispatch } from "react-redux";

import { withStyles } from "@material-ui/core/styles";
import withWidth from "@material-ui/core/withWidth";

import { PageWithAppDrawer2 } from "../_components/main/PageWithAppDrawer2";
import { utilityActions } from "../_actions";
import { Paper, Grid, Select, TextField, Button } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";

class AdminTitleRackRateDetailsPage extends React.Component {
  constructor(props) {
    super(props);
    const { id: rackId } = this.props.match ? this.props.match.params : {};
    this.state = {
      rackId,
      reload: false,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { id: rackId } = nextProps.match ? nextProps.match.params : {};
    if (rackId !== prevState.rackId) {
      return {
        ...prevState,
        rackId,
        reload: true,
      };
    }
    return false;
  }

  async componentDidMount() {
    // const { items: clients } = this.props.utility.client_types;
    // if (clients.length === 0) {
    //   await this.props.dispatch(
    //     utilityActions.getUtility("clients", {
    //       perPage: 1000,
    //       filters: [
    //         {
    //           field: "clientactive",
    //           operator: "eq",
    //           value: "2"
    //         }
    //       ],
    //       orders: [{ field: "clientname", direction: "asc" }]
    //     })
    //   );
    // }
  }

  render() {
    const { rackId, reload } = this.state;
    const { classes } = this.props;

    if (reload === true) {
      this.setState({ reload: false }, () => {
        this.componentDidMount();
      });
      return <></>;
    }

    return (
      <PageWithAppDrawer2 pageTitle={`Rack Rate #${rackId}`}>
        <RouterLink to="/admin/titleRackRates">All Rack Rates</RouterLink>
        <Paper className={classes.paper}>
          <RackRateDetails itemId={rackId} />
        </Paper>
      </PageWithAppDrawer2>
    );
  }
}

function RackRateDetails(props) {
  const dispatch = useDispatch();
  const item = useSelector((state) => state.utility.title_rack_rates_item);
  const states = useSelector((state) => state.utility.us_states);
  const fields = [
    {
      width: 2,
      title: "ID",
      field: "dvsrackrateid",
      type: "stringreadonly",
    },
  ];

  const id = props.itemId;

  React.useEffect(() => {
    // console.log("useEffect item", item);
    // if (item && item.paymenttype === 0) {
    //   dispatch(
    //     utilityActions.updateOneUtilityLocal("clients", {
    //       ["paymentype"]: ""
    //     })
    //   );
    // }
  }, [item]);

  React.useEffect(() => {
    if (states.items.length === 0) {
      dispatch(
        utilityActions.getUtility("us_states", {
          perPage: 1000,
          orders: [
            {
              field: "stateabbreviation",
              direction: "asc",
            },
          ],
        })
      );
    }
  }, []);

  React.useEffect(() => {
    if (id) {
      if (!item) {
        getNewData();
      } else if (Number(item.id) !== Number(id)) {
        getNewData();
      }
    }
  }, [id]);

  const getNewData = () => {
    dispatch(utilityActions.getOneUtility("title_rack_rates", id, []));
  };

  const updateLocal = (event) => {
    const { name, value } = event.target;
    dispatch(
      utilityActions.updateOneUtilityLocal("title_rack_rates", {
        [name]: value,
      })
    );
  };

  const handleSave = () => {
    dispatch(
      utilityActions.updateUtility("title_rack_rates", item.id, item, () => {
        getNewData();
      })
    );
  };

  return (
    <>
      {item && item.id ? (
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSave}
              disabled
            >
              Save Changes
            </Button>
          </Grid>
          {fields.map((field, index) => (
            <React.Fragment key={index}>
              <Grid item xs={12} sm={4}>
                {field.title}
              </Grid>
              <Grid item xs={12} sm={8}>
                {field.type === "stringreadonly" && (
                  <TextField
                    name={field.field}
                    disabled
                    placeholder={field.title}
                    value={item[field.field] || ""}
                    onChange={updateLocal}
                  />
                )}
                {field.type === "string" && (
                  <TextField
                    name={field.field}
                    placeholder={field.title}
                    value={item[field.field] || ""}
                    onChange={updateLocal}
                  />
                )}
                {field.type === "state" && (
                  <Select
                    native
                    name={field.field}
                    value={item[field.field] || 1}
                    onChange={updateLocal}
                    style={{ fontSize: 12 }}
                    displayEmpty
                  >
                    <option value="">State</option>
                    {states &&
                      states.items.map((state, index) => (
                        <option key={index} value={state.stateid}>
                          {state.stateabbreviation} - {state.statefull}
                        </option>
                      ))}
                  </Select>
                )}
              </Grid>
            </React.Fragment>
          ))}
        </Grid>
      ) : (
        <CircularProgress />
      )}
    </>
  );
}

const styles = (theme) => ({
  root: {
    display: "flex",
    fontSize: 12,
  },
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    width: "100%",
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    width: "100%",
  },
  fixedHeight: {
    height: 240,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },

  Button: {
    display: "block",
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
});

function mapStateToProps(state) {
  const { authentication, utility, batch } = state;
  const { user } = authentication;
  return {
    user,
    batch,
    utility,
  };
}

const connectedAdminTitleRackRateDetailsPage = connect(mapStateToProps)(
  withWidth()(withStyles(styles)(AdminTitleRackRateDetailsPage))
);
export { connectedAdminTitleRackRateDetailsPage as AdminTitleRackRateDetailsPage };
