import React from "react";
import { useSelector } from "react-redux";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { Typography } from "@material-ui/core";
import VehicleDetailHeaderSummaryDisplay from "./VehicleDetailHeaderSummaryDisplay";

export default function VehicleDetailAddNote(props) {
  const webUser = useSelector((state) => state.authentication.webUser);
  const [note, setNote] = React.useState("");

  return (
    <>
      <VehicleDetailHeaderSummaryDisplay
        vehicle={{
          vin: props?.item?.vehiclevin,
          year: props?.item?.yr,
          make: props?.item?.make,
          model: props?.item?.model,
        }}
        titleStatus={props?.item?.title_status?.statusname}
        timeInLatestStatus={props?.item?.time_in_latest_status}
        latestNote={props?.item?.latest_note?.note}
      />
      <Typography>User: {webUser.name}</Typography>
      <br />
      <TextField
        id="outlined-multiline-static"
        label="New Note"
        multiline
        rows="4"
        value={note}
        variant="outlined"
        style={{ width: "100%" }}
        onChange={(event) => {
          setNote(event.target.value);
        }}
      />
      <br />
      <div style={{ textAlign: "center" }}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            props.onClose(note);
            setNote("");
          }}
        >
          Save Note
        </Button>
      </div>
    </>
  );
}
