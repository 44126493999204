import React from "react";
import { Link as RouterLink } from "react-router-dom";
// import Link from "@material-ui/core/Link";
import { connect, useSelector, useDispatch } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { hashHistory } from "../_helpers";

import { utilityActions } from "../_actions";
import { utilityService } from "../_services";
import { formatNumberToStringMoney } from "../_helpers";

import { PageWithAppDrawer2 } from "../_components/main/PageWithAppDrawer2";
import ResponsiveGrid from "../_components/generic/ResponsiveGrid";
import FiltersGrid from "../_components/generic/FiltersGrid";
import ModalGeneric from "../_components/generic/ModalGeneric";

import { Paper, Button, Select, Link } from "@material-ui/core";

class AdminRackRatesPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {}

  render() {
    const { classes } = this.props;
    return (
      <PageWithAppDrawer2 pageTitle={"Rack Rates"}>
        <Paper className={classes.paper}>
          <Button
            color="primary"
            variant="contained"
            onClick={this.handleAddItem}
          >
            Add Master Rack Rate
          </Button>
          <RackRatesTable />
        </Paper>
      </PageWithAppDrawer2>
    );
  }

  handleAddItem = () => {
    utilityService
      .addUtility("rack_rates", { name: "NEW RACK RATE" })
      .then((response) => {
        hashHistory.push(`/admin/rackRates/${response.id}`);
      });
  };
}

function RackRatesTable(props) {
  const dispatch = useDispatch();
  const items = useSelector((state) => state.utility.rack_rates);
  const title_application_types = useSelector(
    (state) => state.utility.title_application_types
  );

  const columns = [
    {
      width: 1,
      title: "ID",
      field: "id",
    },
    {
      width: 1,
      title: "Bucket",
      field: "charge_type.chargebucketname",
    },
    {
      width: 3,
      title: "Name",
      field: "name",
      dataRender: (item) => (
        <RouterLink to={`/admin/rackRates/${item.id}`}>{item.name}</RouterLink>
      ),
    },
    {
      width: 1,
      title: "All",
      field: "every_vehicle",
      dataRender: (item) => (item.every_vehicle ? "Yes" : "No"),
    },
    {
      width: 1,
      title: "Cost",
      field: "charge_amount",
      dataRender: (item) => formatNumberToStringMoney(item.charge_amount),
    },
    {
      width: 4,
      title: "Application Types",
      field: "",
      dataRender: (item) => (
        <>
          {item.title_application_types.map((i) => {
            return (
              <>
                <RouterLink
                  key={i.id}
                  to={`/admin/titleApplicationTypes/${i.id}`}
                >
                  {i.titleapplicationtype}
                </RouterLink>{" "}
                <Link onClick={() => removeApplicationType(i)}>
                  <i>remove</i>
                </Link>
                <br />
              </>
            );
          })}
          <Button
            color="primary"
            variant="outlined"
            size="small"
            onClick={() => addApplicationType(item)}
          >
            Add Application Type
          </Button>
        </>
      ),
    },
    {
      width: 1,
      title: "Overrides",
      field: "overrides_count",
      dataRender: (item) => (
        <RouterLink to={`/admin/rackRates/overrides?rack_rate_id=${item.id}`}>
          {item.overrides_count} overrides
        </RouterLink>
      ),
    },
  ];

  const [availableFilters, setAvailableFilters] = React.useState([
    // {
    //   name: "Bucket",
    //   field: "charge_type.b",
    //   operator: "like",
    //   value: "",
    //   type: "text",
    //   default: ""
    // },
    // {
    //   name: "City",
    //   field: "auctioncity",
    //   operator: "like",
    //   value: "",
    //   type: "text",
    //   default: ""
    // },
    // {
    //   name: "Contact Name",
    //   field: "auctioncontactname",
    //   operator: "like",
    //   value: "",
    //   type: "text",
    //   default: ""
    // }
  ]);

  const [getData, setGetData] = React.useState(false);
  const [orderField, setOrderField] = React.useState("name");
  const [orderDirection, setOrderDirection] = React.useState("asc");
  const [modalOpen, setModalOpen] = React.useState(false);
  const [modalItem, setModalItem] = React.useState({});
  const [modalSelected, setModalSelected] = React.useState(null);
  const [modalRemoveOpen, setModalRemoveOpen] = React.useState(false);
  const [modalRemoveItem, setModalRemoveItem] = React.useState({});

  const [page, setPage] = React.useState(
    items.page ? Number(items.page) - 1 : 0
  );
  const [perPage, setPerPage] = React.useState(
    items.per_page ? Number(items.per_page) : 50
  );
  const [total, setTotal] = React.useState(
    items.total ? Number(items.total) : 0
  );

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
    setGetData(true);
  };
  const handleChangeRowsPerPage = (event) => {
    const value = parseInt(event.target.value);
    setPerPage(value);
    setGetData(true);
  };
  React.useEffect(() => {
    if (getData === true) {
      setGetData(false);
      getNewData();
    }
  }, [getData]);

  React.useEffect(() => {
    setTotal(items.total ? Number(items.total) : 0);
    setPage(items.page ? Number(items.page) : 0);
    setPerPage(items.per_page ? Number(items.per_page) : 10);
  }, [items]);

  React.useEffect(() => {
    if (items.items.length === 0) {
      getNewData();
    }
    if (title_application_types.items.length === 0) {
      dispatch(
        utilityActions.getUtility("title_application_types", {
          page: 1,
          perPage: 1000,
          orders: [
            {
              field: "titleapplicationtype",
              direction: "asc",
            },
          ],
          // orders: [{ field: orderField, direction: orderDirection }],
          // filters: newFilters,
        })
      );
    }
  }, []);

  const getNewData = () => {
    let newFilters = availableFilters
      .filter((filter) => {
        if (["", null].includes(filter.value)) return false;
        if (
          ["isnull", "notnull"].includes(filter.operator) &&
          filter.value === false
        )
          return false;
        return true;
      })
      .map((filter) => {
        return {
          field: filter.field,
          operator: filter.operator,
          value: filter.value,
        };
      });
    dispatch(
      utilityActions.getUtility("rack_rates", {
        page,
        perPage,
        orders: [{ field: orderField, direction: orderDirection }],
        filters: newFilters,
      })
    );
  };

  const handleFilterChange = (newFilter) => {
    const newFilters = availableFilters.map((filter) => {
      if (filter.field !== newFilter.field) return filter; // not the droid we're looking for, return unchanged
      filter.value = newFilter.value;
      return filter;
    });
    setAvailableFilters(newFilters);
    setPage(1);
    setGetData(true);
  };

  const handleOrderChange = (field, direction) => {
    setOrderField(field);
    setOrderDirection(direction);
    setGetData(true);
  };

  const addApplicationType = (item) => {
    setModalItem(item);
    setModalOpen(true);
  };

  const createApplicationType = () => {
    utilityService
      .addUtility("rack_rate_title_app_types", {
        rack_rate_id: modalItem.id,
        vehiclechargetypeid: modalSelected,
      })
      .then((response) => {
        getNewData();
      });
    setModalSelected(null);
    setModalItem({});
  };

  const removeApplicationType = (appItem) => {
    setModalRemoveItem(appItem);
    setModalRemoveOpen(true);
  };

  const deleteApplicationType = () => {
    const id = modalRemoveItem.pivot.id;
    utilityService
      .deleteOneUtility("rack_rate_title_app_types", id)
      .then((response) => {
        getNewData();
      });
    setModalRemoveItem({});
  };

  return (
    <>
      <FiltersGrid
        availableFilters={availableFilters}
        onChange={handleFilterChange}
      />
      <ResponsiveGrid
        loading={items.loading}
        columns={columns}
        data={items.items}
        page={page - 1} // API has a 1 based page count, Material UI has a zero based page count
        perPage={perPage}
        total={total}
        useOrdering
        orderBy={orderField}
        orderDir={orderDirection}
        onChangeOrder={handleOrderChange}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        // showActions
        // onDownload={handleDownload}
      />
      <ModalGeneric
        open={modalOpen}
        title="Associate Application Type"
        cancelText="CANCEL"
        onCancel={() => setModalOpen(false)}
        closeText="Add Application Type"
        onClose={() => {
          createApplicationType();
          setModalOpen(false);
        }}
      >
        <Select
          native
          value={modalSelected || ""}
          onChange={(event) => {
            setModalSelected(event.target.value);
          }}
          style={{ fontSize: 12 }}
          displayEmpty
        >
          <option value="">Application Type</option>
          {title_application_types.items &&
            title_application_types.items.map((ta, index) => (
              <option key={index} value={ta.id}>
                {ta.titleapplicationtype}
              </option>
            ))}
        </Select>
      </ModalGeneric>
      <ModalGeneric
        open={modalRemoveOpen}
        title={`Remove ${modalRemoveItem.titleapplicationtype}?`}
        cancelText="CANCEL"
        onCancel={() => setModalRemoveOpen(false)}
        closeText={`Remove ${modalRemoveItem.titleapplicationtype}?`}
        onClose={() => {
          deleteApplicationType();
          setModalRemoveOpen(false);
        }}
      >
        {`Remove ${modalRemoveItem.titleapplicationtype}?`}
      </ModalGeneric>
    </>
  );
}

const styles = (theme) => ({
  root: {
    display: "flex",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
});

function mapStateToProps(state) {
  const { authentication } = state;
  const { user } = authentication;
  return {
    user,
  };
}

const connectedAdminRackRatesPage = connect(mapStateToProps)(
  withStyles(styles)(AdminRackRatesPage)
);
export { connectedAdminRackRatesPage as AdminRackRatesPage };
