import { makeStyles, Paper, Tab, Tabs, Typography } from "@material-ui/core";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import FiltersGrid from "../_components/generic/FiltersGrid";
import ResponsiveGrid from "../_components/generic/ResponsiveGrid";

import { PageWithAppDrawer2 } from "../_components/main/PageWithAppDrawer2";
import { utilityService } from "../_services";

export default function RiDotStatusPage(props) {
  const classes = useStyles();
  const clients = useSelector((state) => state.utility.clients_active_ri);

  const [loading, setLoading] = useState(false);
  const [clientId, setClientId] = useState(null);
  const [client, setClient] = useState(null);
  const [tabIndex, setTabIndex] = useState(0);

  const [data1, setData1] = useState([]);
  const [data2, setData2] = useState([]);
  const [data3, setData3] = useState([]);
  const [data4, setData4] = useState([]);

  const [availableFilters, setAvailableFilters] = useState([
    {
      name: "RI Client",
      field: "clientid",
      operator: "eq",
      value:
        props.defaultFilters && props.defaultFilters.clientid
          ? props.defaultFilters.clientid
          : "",
      type: "choice",
      useUtility: true,
      utility: "clients_active_ri",
      utilityValue: "clientname",
      utilityOrder: {
        field: "clientname",
        direction: "asc",
      },
      utilityFilters: [
        {
          field: "clientactive",
          operator: "eq",
          value: 2,
        },
        {
          field: "is_ri",
          operator: "eq",
          value: 1,
        },
      ],
      // choices: [],
      default: "",
    },
  ]);

  const handleFilterChange = async (newFilter) => {
    const newFilters = availableFilters.map((filter) => {
      if (filter.field !== newFilter.field) return filter; // not the droid we're looking for, return unchanged
      filter.value = newFilter.value;
      if (filter.name === "RI Client") {
        setClientId(Number(filter.value));
        setClient(
          clients.items.find(
            (client) => Number(client.clientid) === Number(filter.value)
          )
        );
      }
      return filter;
    });
    await setAvailableFilters(newFilters);
  };

  const getData = () => {
    setLoading(true);
    if (!clientId) {
      console.log("no client");
      return;
    }

    utilityService
      .getUtility("vehicles", {
        perPage: 1000,
        filters: [
          {
            field: "cancelled",
            operator: "neq",
            value: "1",
          },
          {
            field: "clientid",
            operator: "eq",
            value: clientId,
          },
          {
            field: "client_load_id",
            operator: "isnull",
            value: null,
          },
        ],
      })
      .then((result) => {
        setData1(result.data);
        setLoading(false);
      });

    utilityService
      .getUtility("vehicles", {
        perPage: 1000,
        filters: [
          {
            field: "cancelled",
            operator: "neq",
            value: "1",
          },
          {
            field: "clientid",
            operator: "eq",
            value: clientId,
          },
          {
            field: "client_load_id",
            operator: "notnull",
            value: null,
          },
          {
            field: "submitteddotdate",
            operator: "isnull",
            value: null,
          },
          {
            field: "has_recall",
            operator: "neq",
            value: 1,
          },
        ],
      })
      .then((result) => {
        setData2(result.data);
      });

    utilityService
      .getUtility("vehicles", {
        perPage: 1000,
        filters: [
          {
            field: "cancelled",
            operator: "neq",
            value: "1",
          },
          {
            field: "clientid",
            operator: "eq",
            value: clientId,
          },
          {
            field: "client_load_id",
            operator: "notnull",
            value: null,
          },
          {
            field: "submitteddotdate",
            operator: "notnull",
            value: null,
          },
        ],
      })
      .then((result) => {
        setData3(result.data);
      });

    utilityService
      .getUtility("vehicles", {
        perPage: 1000,
        filters: [
          {
            field: "cancelled",
            operator: "neq",
            value: "1",
          },
          {
            field: "clientid",
            operator: "eq",
            value: clientId,
          },
          {
            field: "client_load_id",
            operator: "notnull",
            value: null,
          },
          {
            field: "has_recall",
            operator: "1",
            value: null,
          },
        ],
      })
      .then((result) => {
        setData4(result.data);
      });
  };

  useEffect(() => {
    if (!clientId) {
      return;
    }
    if (clientId > 0) {
      getData();
    }
  }, [clientId]);

  return (
    <PageWithAppDrawer2 pageTitle="RI DOT Status">
      <Paper className={classes.paper}>
        <FiltersGrid
          availableFilters={availableFilters}
          onChange={handleFilterChange}
        />
        {client && (
          <Typography>Selected Client: {client.clientname}</Typography>
        )}
        {client && (
          <>
            <Tabs value={tabIndex} onChange={(e, index) => setTabIndex(index)}>
              <Tab label={`Recent Purchases (${data1 && data1.length})`} />
              <Tab label={`Assigned to Load (${data2 && data2.length})`} />
              <Tab label={`DOT Submitted (${data3 && data3.length})`} />
              <Tab label={`Has Recall (${data4 && data4.length})`} />
            </Tabs>
            <Typography component="div" role="tabpanel" hidden={tabIndex !== 0}>
              <ResponsiveGrid
                loading={loading}
                data={data1}
                columns={columns1}
                usePagination={false}
              />
            </Typography>
            <Typography component="div" role="tabpanel" hidden={tabIndex !== 1}>
              <ResponsiveGrid
                loading={loading}
                data={data2}
                columns={columns2}
                usePagination={false}
              />
            </Typography>
            <Typography component="div" role="tabpanel" hidden={tabIndex !== 2}>
              <ResponsiveGrid
                loading={loading}
                data={data3}
                columns={columns3}
                usePagination={false}
              />
            </Typography>
            <Typography component="div" role="tabpanel" hidden={tabIndex !== 3}>
              <ResponsiveGrid
                loading={loading}
                data={data4}
                columns={columns4}
                usePagination={false}
              />
            </Typography>
          </>
        )}
      </Paper>
    </PageWithAppDrawer2>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
}));

const columns1 = [
  {
    width: 2,
    title: "VIN",
    field: "vehiclevin",
  },
  {
    width: 3,
    title: "Year/Make/Model",
    field: "yr",
    dataRender: (item) => `${item.yr} ${item.make} ${item.model}`,
  },
  {
    width: 1,
    title: "Created",
    field: "recordcreateddate",
  },
  {
    width: 2,
    title: "Auction",
    field: "auction.auctionname",
  },
  {
    width: 2,
    title: "Copy of Ownership",
    field: "titleregownershipdate",
  },
  {
    width: 2,
    title: "Dispatched to Transport",
    field: "",
  },
];

const columns2 = [
  {
    width: 2,
    title: "VIN",
    field: "vehiclevin",
  },
  {
    width: 3,
    title: "Year/Make/Model",
    field: "yr",
    dataRender: (item) => `${item.yr} ${item.make} ${item.model}`,
  },
  {
    width: 1,
    title: "Load",
    field: "recordcreateddate",
  },
  {
    width: 2,
    title: "Auction",
    field: "auction.auctionname",
  },
  {
    width: 2,
    title: "Copy of Ownership",
    field: "titleregownershipdate",
  },
  {
    width: 2,
    title: "Dispatched to Transport",
    field: "",
  },
];

const columns3 = [
  {
    width: 2,
    title: "VIN",
    field: "vehiclevin",
  },
  {
    width: 3,
    title: "Year/Make/Model",
    field: "yr",
    dataRender: (item) => `${item.yr} ${item.make} ${item.model}`,
  },
  {
    width: 1,
    title: "Load",
    field: "recordcreateddate",
  },
  {
    width: 2,
    title: "Auction",
    field: "auction.auctionname",
  },
  {
    width: 2,
    title: "Copy of Ownership",
    field: "titleregownershipdate",
  },
  {
    width: 2,
    title: "Dispatched to Transport",
    field: "",
  },
];

const columns4 = [
  {
    width: 2,
    title: "VIN",
    field: "vehiclevin",
  },
  {
    width: 3,
    title: "Year/Make/Model",
    field: "yr",
    dataRender: (item) => `${item.yr} ${item.make} ${item.model}`,
  },
  {
    width: 1,
    title: "Load",
    field: "recordcreateddate",
  },
  {
    width: 2,
    title: "Auction",
    field: "auction.auctionname",
  },
  {
    width: 2,
    title: "Copy of Ownership",
    field: "titleregownershipdate",
  },
  {
    width: 2,
    title: "Dispatched to Transport",
    field: "",
  },
];

export { RiDotStatusPage };
