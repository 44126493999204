import React, { useState } from "react";
import { useSelector } from "react-redux";

import { vehicleService } from "../../_services";

import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import {
  FormHelperText,
  Grid,
  Divider,
  Button,
  CircularProgress,
  Typography,
} from "@material-ui/core";
import VehicleDetailHeaderSummaryDisplay from "./VehicleDetailHeaderSummaryDisplay";

export default function VehicleDetailVehicleInformation(props) {
  const vehicle = props.item;
  const auctionList = props.auctionList || [];
  const isAdmin = useSelector((state) =>
    state.authentication.webUser.roleid === 2 ? true : false
  );

  const [nhtsaUpdating, setNHtsaUpdating] = useState(false);

  const nhtsaRefresh = () => {
    setNHtsaUpdating(true);
    vehicleService.nhtsaVinLookup(vehicle.vehiclevin).then((response) => {
      setNHtsaUpdating(false);
      console.log(response);
      if (response && response[0]) {
        const data = response[0];
        console.log("stuff", {
          city: data.plantcity,
          state: data.plantstate,
          data,
        });
        props.onUpdateLocalExplicit("manufacture_city", data.plantcity);
        props.onUpdateLocalExplicit("manufacture_state", data.plantstate);
        props.onUpdateLocalExplicit("manufacturer", data.manufacturer);
        props.onUpdateLocalExplicit("yr", data.modelyear);
        props.onUpdateLocalExplicit("model", data.model);
        props.onUpdateLocalExplicit("make", data.make);
      }
    });
  };

  return (
    <>
      <VehicleDetailHeaderSummaryDisplay
        vehicle={{
          vin: props?.item?.vehiclevin,
          year: props?.item?.yr,
          make: props?.item?.make,
          model: props?.item?.model,
        }}
        titleStatus={props?.item?.title_status?.statusname}
        timeInLatestStatus={props?.item?.time_in_latest_status}Í
        latestNote={props?.item?.latest_note?.note}
      />
      <Grid container>
        <Grid item xs={12} md={6}>
          <TextField
            margin="dense"
            name={"vehiclevin"}
            label={"VIN"}
            type="text"
            disabled={true}
            value={vehicle.vehiclevin}
            // onChange={handleChangeAuctionData}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            margin="dense"
            name={"clientstocknumber"}
            label={"Client Stock Number"}
            type="text"
            value={
              vehicle.clientstocknumber == null ? "" : vehicle.clientstocknumber
            }
            onChange={props.onUpdateLocal}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            margin="dense"
            name={"bkg_entry_load_number"}
            label={"BKG Entry Load Number"}
            type="text"
            value={vehicle.bkg_entry_load_number || ""}
            onChange={props.onUpdateLocal}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <InputLabel shrink>Has Recall</InputLabel>
            <Select
              native
              value={vehicle.has_recall === null ? "" : vehicle.has_recall}
              name="has_recall"
              onChange={props.onUpdateLocal}
              displayEmpty
            >
              <option value={""}>Has Recall?</option>
              <option value={0}>No - No Recall</option>
              <option value={1}>Yes - Has Recall</option>
            </Select>
            <FormHelperText>NHTSA Recall Check</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <InputLabel shrink>Conforming</InputLabel>
            <Select
              native
              value={
                vehicle.is_conforming === null ? "" : vehicle.is_conforming
              }
              name="is_conforming"
              onChange={props.onUpdateLocal}
              displayEmpty
            >
              <option value={""}>Unknown or not required</option>
              <option value={0}>Not Conforming</option>
              <option value={1}>Conforming</option>
            </Select>
            <FormHelperText>Conforming check for RI vehicles</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <InputLabel shrink>Recall was remedied</InputLabel>
            <Select
              native
              value={
                vehicle.has_recall_remedy === null
                  ? 0
                  : vehicle.has_recall_remedy
              }
              name="has_recall_remedy"
              onChange={props.onUpdateLocal}
              displayEmpty
            >
              <option value={0}>No recall remedy performed</option>
              <option value={1}>Recall remedy performed</option>
            </Select>
            <FormHelperText>Applied to SOC</FormHelperText>
          </FormControl>
        </Grid>
        {/* <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <InputLabel shrink>Transportation POC</InputLabel>
            <Select
              native
              value={vehicle.transport_test}
              name="transport_test"
              onChange={props.onUpdateLocal}
            >
              <option value={0}>NO</option>
              <option value={1}>YES</option>
            </Select>
            <FormHelperText>Transportation POC</FormHelperText>
          </FormControl>
        </Grid> */}

        <Grid item xs={12}>
          <Divider style={{ marginTop: 20, marginBottom: 20 }} />
        </Grid>

        <Grid item xs={12}>
          <Button
            size="small"
            color="primary"
            variant="contained"
            onClick={nhtsaRefresh}
          >
            {nhtsaUpdating && <CircularProgress size={14} />}NHTSA Refresh
          </Button>
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            margin="dense"
            name={"manufacturer"}
            label={"Manufacturer (NHTSA)"}
            type="text"
            value={vehicle.manufacturer || ""}
            onChange={props.onUpdateLocal}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            margin="dense"
            name={"manufacture_date"}
            label={"Manufacture Date (From Sticker)"}
            type="text"
            value={vehicle.manufacture_date || ""}
            onChange={props.onUpdateLocal}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            margin="dense"
            name={"manufacture_city"}
            label={"Manufacture City (NHTSA)"}
            type="text"
            value={vehicle.manufacture_city || ""}
            onChange={props.onUpdateLocal}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            margin="dense"
            name={"manufacture_state"}
            label={"Manufacture State (NHTSA)"}
            type="text"
            value={vehicle.manufacture_state || ""}
            onChange={props.onUpdateLocal}
            fullWidth
          />
        </Grid>

        <Grid item xs={12}>
          <Divider style={{ marginTop: 20, marginBottom: 20 }} />
        </Grid>

        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <InputLabel shrink>Auction Name</InputLabel>
            <Select
              native
              value={vehicle.auctionid || ""}
              onChange={props.onUpdateLocal}
              displayEmpty
              disabled={!isAdmin}
              name="auctionid"
            >
              <option value="">None</option>
              {auctionList.map((auction) => {
                return (
                  <option key={auction.auctionid} value={auction.auctionid}>
                    {auction.auctionname}
                  </option>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <InputLabel shrink>Auction Selling</InputLabel>
            <Select
              native
              value={vehicle.auction_id_selling || ""}
              onChange={props.onUpdateLocal}
              displayEmpty
              disabled={!isAdmin}
              name="auction_id_selling"
            >
              <option value="">None</option>
              {auctionList.map((auction) => {
                return (
                  <option key={auction.auctionid} value={auction.auctionid}>
                    {auction.auctionname}
                  </option>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            margin="dense"
            name={"yr"}
            label={"Year"}
            type="text"
            disabled={!isAdmin}
            value={vehicle.yr || ""}
            onChange={props.onUpdateLocal}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            margin="dense"
            name={"make"}
            label={"Make"}
            type="text"
            disabled={!isAdmin}
            value={vehicle.make || ""}
            onChange={props.onUpdateLocal}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            margin="dense"
            name={"model"}
            label={"Model"}
            type="text"
            disabled={!isAdmin}
            value={vehicle.model || ""}
            onChange={props.onUpdateLocal}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            margin="dense"
            name={"color"}
            label={"Color"}
            type="text"
            value={vehicle.color || ""}
            onChange={props.onUpdateLocal}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            margin="dense"
            name={"vehicletype"}
            label={"Vehicle Type"}
            type="text"
            disabled={!isAdmin}
            value={vehicle.vehicletype || ""}
            onChange={props.onUpdateLocal}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            margin="dense"
            name={"mileage"}
            label={"Mileage"}
            type="number"
            value={vehicle.mileage || ""}
            onChange={props.onUpdateLocal}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <InputLabel shrink>Exempt?</InputLabel>
            <Select
              native
              value={vehicle.is_exempt === null ? 0 : vehicle.is_exempt}
              name="is_exempt"
              onChange={props.onUpdateLocal}
              disabled={!isAdmin}
            >
              <option value={0}>Actual</option>
              <option value={1}>Exempt</option>
              <option value={2}>Not Actual</option>
              <option value={3}>Exceeds</option>
            </Select>
            <FormHelperText>Conforming check for RI vehicles</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            margin="dense"
            name={"value_us_dollars"}
            label={"Value in US Dollars"}
            type="number"
            value={vehicle.value_us_dollars || ""}
            onChange={props.onUpdateLocal}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <Divider />
          <Typography style={{ fontWeight: "bold" }}>
            Tire Placard Information
          </Typography>
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            margin="dense"
            name={"front_size"}
            label={"Front Tire Size"}
            type="text"
            // disabled={!isAdmin}
            value={vehicle.vehicle_tire_data?.front_size || ""}
            onChange={props.onUpdateLocalTires}
            // onChange={() => console.log('change')}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            margin="dense"
            name={"front_kpa"}
            label={"Front Tire kPa (numbers only)"}
            type="number"
            // disabled={!isAdmin}
            value={vehicle.vehicle_tire_data?.front_kpa || ""}
            onChange={props.onUpdateLocalTires}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            margin="dense"
            name={"front_psi"}
            label={"Front Tire PSI (numbers only)"}
            type="number"
            // disabled={!isAdmin}
            value={vehicle.vehicle_tire_data?.front_psi || ""}
            onChange={props.onUpdateLocalTires}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            margin="dense"
            name={"rear_size"}
            label={"Rear Tire Size"}
            type="text"
            // disabled={!isAdmin}
            value={vehicle.vehicle_tire_data?.rear_size || ""}
            onChange={props.onUpdateLocalTires}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            margin="dense"
            name={"rear_kpa"}
            label={"Rear Tire kPa (numbers only)"}
            type="number"
            // disabled={!isAdmin}
            value={vehicle.vehicle_tire_data?.rear_kpa || ""}
            onChange={props.onUpdateLocalTires}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            margin="dense"
            name={"rear_psi"}
            label={"Rear Tire PSI (numbers only)"}
            type="number"
            // disabled={!isAdmin}
            value={vehicle.vehicle_tire_data?.rear_psi || ""}
            onChange={props.onUpdateLocalTires}
            fullWidth
          />
        </Grid>
      </Grid>
    </>
  );
}
