import React from "react";
import { Link as RouterLink } from "react-router-dom";
// import Link from "@material-ui/core/Link";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import moment from "moment";


import { PageWithAppDrawer2 } from "../_components/main/PageWithAppDrawer2";
// import { AuctionModal } from "../_components/admin/AuctionModal";
import ResponsiveGrid from "../_components/generic/ResponsiveGrid";
import FiltersGrid from "../_components/generic/FiltersGrid";

import {
  Paper,
  Button,
  Divider,
  Typography,
  Grid,
  CircularProgress,
} from "@material-ui/core";
import { utilityService } from "../_services";

class ManagementActivityPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {}

  render() {
    const { classes } = this.props;
    return (
      <PageWithAppDrawer2 pageTitle={"Staff Volume Report"}>
        <Paper className={classes.paper}>
          <ActivityTable />
        </Paper>
      </PageWithAppDrawer2>
    );
  }
}

function ActivityTable(props) {
  const columns = [
    {
      width: 2,
      title: "User",
      field: "username",
      dataRender: (item) => (item.username !== "totals" ? item.username : ""),
    },
    {
      width: 1,
      title: "Created",
      field: "created",
      dataRender: (item) =>
        item.username === "totals" ? (
          <Typography style={{ fontWeight: "bold" }}>{item.created}</Typography>
        ) : item.created > 0 ? (
          <RouterLink to={`/management/activity/created/${start}/${end}`}>
            {item.created}
          </RouterLink>
        ) : (
          ""
        ),
    },
    {
      width: 1,
      title: "Customs",
      field: "submittedtocustoms",
      dataRender: (item) =>
        item.username === "totals" ? (
          <Typography style={{ fontWeight: "bold" }}>
            {item.submittedtocustoms}
          </Typography>
        ) : item.submittedtocustoms > 0 ? (
          <RouterLink
            to={`/management/activity/submittedtocustoms/${start}/${end}`}
          >
            {item.submittedtocustoms}
          </RouterLink>
        ) : (
          ""
        ),
    },
    {
      width: 1,
      title: "DOT",
      field: "submittedtodot",
      dataRender: (item) =>
        item.username === "totals" ? (
          <Typography style={{ fontWeight: "bold" }}>
            {item.submittedtodot}
          </Typography>
        ) : item.submittedtodot > 0 ? (
          <RouterLink
            to={`/management/activity/submittedtodot/${start}/${end}`}
          >
            {item.submittedtodot}
          </RouterLink>
        ) : (
          ""
        ),
    },
    {
      width: 1,
      title: "Sent To State",
      field: "senttostate",
      dataRender: (item) =>
        item.username === "totals" ? (
          <Typography style={{ fontWeight: "bold" }}>
            {item.senttostate}
          </Typography>
        ) : item.senttostate > 0 ? (
          <RouterLink to={`/management/activity/senttostate/${start}/${end}`}>
            {item.senttostate}
          </RouterLink>
        ) : (
          ""
        ),
    },
    {
      width: 1,
      title: "Sent To Auction",
      field: "senttoauction",
      dataRender: (item) =>
        item.username === "totals" ? (
          <Typography style={{ fontWeight: "bold" }}>
            {item.senttoauction}
          </Typography>
        ) : item.senttoauction > 0 ? (
          <RouterLink to={`/management/activity/senttoauction/${start}/${end}`}>
            {item.senttoauction}
          </RouterLink>
        ) : (
          ""
        ),
    },
    {
      width: 1,
      title: "Notes",
      field: "notes",
      dataRender: (item) =>
        item.username === "totals" ? (
          <Typography style={{ fontWeight: "bold" }}>{item.notes}</Typography>
        ) : item.notes > 0 ? (
          <RouterLink to={`/management/activity/notes/${start}/${end}`}>
            {item.notes}
          </RouterLink>
        ) : (
          ""
        ),
    },
    {
      width: 1,
      title: "Total",
      field: "total",
      dataRender: (item) =>
        item.username === "totals" ? (
          <Typography style={{ fontWeight: "bold" }}>{item.total}</Typography>
        ) : (
          item.total
        ),
    },
  ];

  let date = new Date();
  let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
  let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

  const [availableFilters, setAvailableFilters] = React.useState([
    {
      name: "Start Date",
      field: "start",
      operator: "eq",
      value: moment(firstDay).format("YYYY-MM-DD"),
      type: "date",
      default: "",
    },
    {
      name: "End Date",
      field: "end",
      operator: "eq",
      value: moment(lastDay).format("YYYY-MM-DD"),
      type: "date",
      default: "",
    },
  ]);

  const [loading, setLoading] = React.useState(false);
  const [getData, setGetData] = React.useState(false);
  const [activity, setActivity] = React.useState([]);
  const [start, setStart] = React.useState(
    moment(firstDay).format("YYYY-MM-DD")
  );
  const [end, setEnd] = React.useState(moment(lastDay).format("YYYY-MM-DD"));

  React.useEffect(() => {
    if (getData === true) {
      setGetData(false);
      getNewData();
    }
  }, [getData]);

  React.useEffect(() => {
    if (activity.length === 0) {
      getNewData();
    }
  }, []);

  const getNewData = async () => {
    if (!start || !end) {
      return;
    }
    setLoading(true);
    utilityService
      .getEndpoint("/api/reports/management/titleActivity", "GET", {
        params: `start=${start}&end=${end}`,
      })
      .then((response) => {
        setActivity(response);
        setLoading(false);
      });
  };

  const handleFilterChange = (newFilter) => {
    const newFilters = availableFilters.map((filter) => {
      if (filter.field !== newFilter.field) return filter; // not the droid we're looking for, return unchanged
      if (filter.field === "start") setStart(newFilter.value);
      if (filter.field === "end") setEnd(newFilter.value);
      filter.value = newFilter.value;
      return filter;
    });
    setAvailableFilters(newFilters);
    setGetData(true);
  };

  return (
    <>
      <Grid container spacing={1} style={{ marginBottom: 20 }}>
        <Grid item>
          <Button
            variant="contained"
            size="small"
            color="primary"
            onClick={() => {
              handleFilterChange(
                {
                  name: "Start Date",
                  field: "start",
                  operator: ">=",
                  value: moment()
                    .subtract(1, "month")
                    .startOf("month")
                    .format("YYYY-MM-DD"),
                },
                false
              );
              handleFilterChange(
                {
                  name: "End Date",
                  field: "end",
                  operator: "<=",
                  value: moment()
                    .subtract(1, "month")
                    .endOf("month")
                    .format("YYYY-MM-DD"),
                },
                true
              );
            }}
          >
            Last Month
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            size="small"
            color="primary"
            onClick={() => {
              handleFilterChange(
                {
                  name: "Start Date",
                  field: "start",
                  operator: ">=",
                  value: moment().startOf("month").format("YYYY-MM-DD"),
                },
                false
              );
              handleFilterChange(
                {
                  name: "End Date",
                  field: "end",
                  operator: "<=",
                  value: moment().endOf("month").format("YYYY-MM-DD"),
                },
                true
              );
            }}
          >
            Current Month
          </Button>
        </Grid>
      </Grid>
      <FiltersGrid
        availableFilters={availableFilters}
        onChange={handleFilterChange}
      />
      <Divider style={{ marginTop: 10, marginBottom: 50 }} />
      {loading && <CircularProgress />}
      {!loading && activity && (
        <ResponsiveGrid
          usePagination={false}
          loading={false}
          columns={columns}
          data={activity}
        />
      )}
    </>
  );
}

const styles = (theme) => ({
  root: {
    display: "flex",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
});

function mapStateToProps(state) {
  const { authentication, utility } = state;
  const { user } = authentication;
  const { us_states, auctions } = utility;
  return {
    user,
    auctions,
    us_states,
  };
}

const connectedManagementActivityPage = connect(mapStateToProps)(
  withStyles(styles)(ManagementActivityPage)
);
export { connectedManagementActivityPage as ManagementActivityPage };
