import React from "react";
import { useSelector } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import "date-fns";

import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";

import VehicleDetailDocuments from "./VehicleDetailDocuments";
import VehicleDetailOther from "./VehicleDetailOther";
import VehicleDetailTitleInformation from "./VehicleDetailTitleInformation";
import VehicleDetailVehicleInformation from "./VehicleDetailVehicleInformation";
import VehicleDetailDebtorInformation from "./VehicleDetailDebtorInformation";
import VehicleDetailDates from "./VehicleDetailDates";
import VehicleDetailCharges from "./VehicleDetailCharges";
import VehicleDetailPayments from "./VehicleDetailPayments";
import VehicleDetailInvoice from "./VehicleDetailInvoice";

import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { Divider, Hidden } from "@material-ui/core";
import VehicleDetailHeaderSummaryDisplay from "./VehicleDetailHeaderSummaryDisplay";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "left",
    color: theme.palette.text.primary,
  },
  wrapper: {
    minWidth: 100,
  },
}));

const SmallTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(4),
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:hover": {
      color: "#40a9ff",
      opacity: 1,
    },
    "&$selected": {
      color: "#1890ff",
      fontWeight: theme.typography.fontWeightMedium,
    },
    "&:focus": {
      color: "#40a9ff",
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

export default function VehicleDetailComponent(props) {
  const classes = useStyles();
  const utility = props.utility;

  const isAdmin = useSelector((state) =>
    state.authentication.webUser.roleid === 2 ? true : false
  );

  const { items: auctionList } = utility.auctions;
  const { items: titleStatuses } = utility.title_statuses;
  const { items: titleApplicationTypes } = utility.title_application_types;
  const { items: clientList } = utility.clients_active;

  const [tabIndex, setTabIndex] = React.useState(0);

  const handleUpdateLocal = (event) => {
    const { name, value } = event.target;
    props.onUpdateLocal({ [name]: value });
  };

  const handleUpdateLocalTires = (event) => {
    const { name, value } = event.target;
    props.onUpdateLocalTires({ [name]: value });
  };

  const handleUpdateLocalExplicit = (name, value) => {
    props.onUpdateLocal({ [name]: value });
  };

  return (
    <div className={classes.root}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <Hidden xsDown>
              <AppBar position="static" color="default">
                {isAdmin ? (
                  <Tabs
                    value={tabIndex}
                    onChange={(e, newValue) => setTabIndex(newValue)}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="fullWidth"
                  >
                    <SmallTab label="Title" />
                    <SmallTab label="Vehicle" />
                    <SmallTab label="Debtor" />
                    <SmallTab label="Documents" />
                    <SmallTab label="Dates & Activity" />
                    <SmallTab label="Invoice & Charges" />
                    <SmallTab label="Other" />
                  </Tabs>
                ) : (
                  <Tabs
                    value={tabIndex}
                    onChange={(e, newValue) => setTabIndex(newValue)}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="fullWidth"
                  >
                    <SmallTab value={0} label="Title" />
                    <SmallTab value={1} label="Vehicle" />
                    <SmallTab value={3} label="Documents" />
                    <SmallTab value={4} label="Dates & Activity" />
                  </Tabs>
                )}
              </AppBar>
            </Hidden>
            <Hidden smUp>
              <AppBar position="static" color="default" style={{ padding: 20 }}>
                {isAdmin ? (
                  <Select
                    value={tabIndex}
                    onChange={(e) => setTabIndex(e.target.value)}
                  >
                    <MenuItem value={0}>Title</MenuItem>
                    <MenuItem value={1}>Vehicle</MenuItem>
                    <MenuItem value={2}>Debtor</MenuItem>
                    <MenuItem value={3}>Documents</MenuItem>
                    <MenuItem value={4}>Dates & Activity</MenuItem>
                    <MenuItem value={5}>Invoice & Charges</MenuItem>
                    <MenuItem value={6}>Other</MenuItem>
                  </Select>
                ) : (
                  <Select
                    value={tabIndex}
                    onChange={(e) => setTabIndex(e.target.value)}
                  >
                    <MenuItem value={0}>Title</MenuItem>
                    <MenuItem value={1}>Vehicle</MenuItem>
                    <MenuItem value={3}>Documents</MenuItem>
                    <MenuItem value={4}>Dates & Activity</MenuItem>
                  </Select>
                )}
              </AppBar>
            </Hidden>
            <Paper className={classes.paper}>
              <Typography
                component="div"
                role="tabpanel"
                hidden={tabIndex !== 0}
              >
                <VehicleDetailTitleInformation
                  item={props.item}
                  titleStatuses={titleStatuses}
                  titleApplicationTypes={titleApplicationTypes}
                  clientList={clientList}
                  auctionList={auctionList}
                  onUpdateLocal={handleUpdateLocal}
                  onUpdateLocalExplicit={handleUpdateLocalExplicit}
                />
              </Typography>
              <Typography
                component="div"
                role="tabpanel"
                hidden={tabIndex !== 1}
              >
                <VehicleDetailVehicleInformation
                  item={props.item}
                  auctionList={auctionList}
                  onUpdateLocal={handleUpdateLocal}
                  onUpdateLocalTires={handleUpdateLocalTires}
                  onUpdateLocalExplicit={handleUpdateLocalExplicit}
                />
              </Typography>
              <Typography
                component="div"
                role="tabpanel"
                hidden={tabIndex !== 2}
              >
                <VehicleDetailDebtorInformation
                  item={props.item}
                  onUpdateLocal={handleUpdateLocal}
                  onUpdateLocalExplicit={handleUpdateLocalExplicit}
                />
              </Typography>
              <Typography
                component="div"
                role="tabpanel"
                hidden={tabIndex !== 3}
              >
                <VehicleDetailDocuments item={props.item} />
              </Typography>
              <Typography
                component="div"
                role="tabpanel"
                hidden={tabIndex !== 4}
              >
                <VehicleDetailDates
                  item={props.item}
                  titleStatuses={titleStatuses}
                  onUpdateLocal={handleUpdateLocal}
                  onUpdateLocalExplicit={handleUpdateLocalExplicit}
                />
              </Typography>

              <Typography
                component="div"
                role="tabpanel"
                hidden={tabIndex !== 5}
              >
                <VehicleDetailHeaderSummaryDisplay
                  vehicle={{
                    vin: props?.item?.vehiclevin,
                    year: props?.item?.yr,
                    make: props?.item?.make,
                    model: props?.item?.model,
                  }}
                  titleStatus={props?.item?.title_status?.statusname}
                  timeInLatestStatus={props?.item?.time_in_latest_status}
                  latestNote={props?.item?.latest_note?.note}
                />
                <Typography>
                  Invoice Batch:{" "}
                  {props.item.invoice && props.item.invoice.invoice_batch ? (
                    <RouterLink
                      to={`/invoicebatches/${props.item.invoice.invoicebatchid}`}
                    >
                      {props.item.invoice.invoicebatchid}
                    </RouterLink>
                  ) : (
                    <i>None</i>
                  )}
                </Typography>
                <Grid
                  container
                  direction="column"
                  //   justify="center"
                  alignItems="center"
                >
                  <Grid item xs={12} style={{ width: 300 }}>
                    <VehicleDetailInvoice
                      invoice={props.item.invoice}
                      charges={props.item.charges}
                      payments={props.item.payments}
                    />
                  </Grid>
                </Grid>
                <Divider />

                <Grid container>
                  <Grid item xs={12} sm={12} md={6}>
                    <VehicleDetailCharges
                      vehicleId={props.item.vehicleid}
                      charges={props.item.charges}
                      payments={props.item.payments}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <VehicleDetailPayments
                      vehicleId={props.item.vehicleid}
                      charges={props.item.charges}
                      payments={props.item.payments}
                    />
                  </Grid>
                </Grid>
              </Typography>

              <Typography
                component="div"
                role="tabpanel"
                hidden={tabIndex !== 6}
              >
                <VehicleDetailOther
                  item={props.item}
                  onUpdateLocal={handleUpdateLocal}
                />
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      </MuiPickersUtilsProvider>
    </div>
  );
}
