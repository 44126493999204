import React, { useState, useRef, useCallback } from "react";
import PropTypes from "prop-types";
import SignatureCanvas from "react-signature-canvas";

import PageviewTwoToneIcon from "@material-ui/icons/PageviewTwoTone";
import CheckCircleTwoToneIcon from "@material-ui/icons/CheckCircleTwoTone";
import DeleteTwoToneIcon from "@material-ui/icons/DeleteTwoTone";
import { Button } from "@material-ui/core";

const UserSignatureCanvas = ({
  onSave,
  onPreview,
  widthRatio,
  // canvasProps,
}) => {
  const [signatureResult, setSignatureResult] = useState("");
  const sigCanvas = useRef({});
  const sigPad = useRef({});

  const setSignatureOnChange = () => {
    const dataURL = sigCanvas.current.toDataURL();

    setSignatureResult(dataURL);
  };

  const saveInput = () => {
    onSave({ dataURL: signatureResult });
  };

  const sendPreview = () => {
    onPreview({ dataURL: signatureResult });
  };

  const clearInput = () => {
    sigPad.current.clear();
    setSignatureResult("");
  };

  const measuredRef = useCallback(
    (node) => {
      const resizeCanvas = (signaturePad, canvas) => {
        canvas.width = canvas.parentElement.clientWidth; // width of the .canvasWrapper
        canvas.height = canvas.parentElement.clientWidth / widthRatio;
        signaturePad.clear();
      };

      if (node !== null) {
        sigCanvas.current = node.getCanvas();
        sigPad.current = node.getSignaturePad();
        resizeCanvas(node.getSignaturePad(), node.getCanvas());
      }
    },
    [widthRatio]
  );

  return (
    <>
      <div style={{ width: "100%", backgroundColor: "white", border: "1px solid blue" }}>
        <SignatureCanvas
          canvasProps={{ width: "100%", height: "100%" }}
          ref={measuredRef}
          clearOnResize={true}
          onEnd={setSignatureOnChange}
          minWidth={3}
          maxWidth={6}
        />
      </div>

      <div style={{ display: "inline" }}>
        <div style={{ display: "inline" }}>
          <Button
            startIcon={<DeleteTwoToneIcon />}
            variant="contained"
            color="primary"
            onClick={clearInput}
          >
            Clear
          </Button>
        </div>
        <div style={{ display: "inline" }}>
          <Button
            startIcon={<PageviewTwoToneIcon />}
            variant="contained"
            color="primary"
            onClick={sendPreview}
          >
            Preview
          </Button>
        </div>

        <div style={{ display: "inline" }}>
          <Button
            startIcon={<CheckCircleTwoToneIcon />}
            variant="contained"
            color="primary"
            onClick={saveInput}
          >
            Save
          </Button>
        </div>
      </div>
    </>
  );
};
UserSignatureCanvas.propTypes = {
  canvasProps: PropTypes.object,
  widthRatio: PropTypes.number.isRequired,
  onSave: PropTypes.func,
  onHide: PropTypes.func,
  displayNameInput: PropTypes.bool,
};

export default UserSignatureCanvas;
