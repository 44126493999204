import React from "react";
import { Link as RouterLink } from "react-router-dom";

export const Settings = {
  title: "Missing Bond Release",
  notes: [
    "Not cancelled",
    "RI Type",
    "Submitted to DOT",
    "Missing Bond Release",
  ],
  columns: [
    {
      width: 2,
      title: "VIN",
      field: "vehiclevin",
      dataRender: (item) => (
        <RouterLink to={`/vehicles/${item.id}`}>{item.vehiclevin}</RouterLink>
      ),
    },
    {
      width: 2,
      title: "Client",
      field: "client.clientname",
    },
    {
      width: 2,
      title: "Created",
      field: "recordcreateddate",
    },
    {
      width: 2,
      title: "DOT Submitted",
      field: "submitteddotdate",
    },
  ],
  availableFilters: [
    {
      name: "Not Cancelled",
      field: "titlestatusid",
      operator: "neq",
      value: "5",
      type: "hidden",
      default: "",
    },
    {
      name: "RI Type",
      field: "application_type.is_ri_type",
      operator: "eq",
      value: 1,
      type: "hidden",
      default: "",
    },
    {
      name: "Bond Release Date",
      field: "bondreleasedate",
      operator: "isnull",
      value: "1",
      type: "hidden",
      default: "",
    },
    {
      name: "Submitted DOT Date",
      field: "submitteddotdate",
      operator: "notnull",
      value: "1",
      type: "hidden",
      default: "",
    },
  ],
};
