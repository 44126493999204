import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { connect } from "react-redux";

import { withStyles } from "@material-ui/core/styles";
import withWidth from "@material-ui/core/withWidth";

import { utilityActions } from "../_actions";
import { utilityService } from "../_services";

import {
  // formatNumberToStringMoney,
  generateCSV,
  downloadBlob,
} from "../_helpers";

import { PageWithAppDrawer2 } from "../_components/main/PageWithAppDrawer2";
import ResponsiveGrid from "../_components/generic/ResponsiveGrid";
import FiltersGrid from "../_components/generic/FiltersGrid";

import { Paper, Divider } from "@material-ui/core";
import moment from "moment";

class TitleReportPage extends React.Component {
  constructor(props) {
    super(props);
    const { reportType } = this.props.match ? this.props.match.params : {};
    const { webUser } = this.props;
    const isAdmin = webUser.roleid === 2 ? true : false;
    const isClient = webUser.roleid !== 2 ? true : false;
    const clientId = webUser.client_id;
    this.state = {
      loading: true,
      reload: false,
      reportType,
      utility: null,
      filters: [],
      availableFilters: [],
      orders: [],
      relations: [],
      data: [],
      columns: [],
      page: 1,
      perPage: 10,
      total: 0,
      isAdmin,
      isClient,
      clientId,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { reportType } = nextProps.match ? nextProps.match.params : {};
    if (reportType !== prevState.reportType) {
      return {
        ...prevState,
        reportType,
        reload: true,
      };
    }
    return false;
  }

  componentWillUnmount() {
    const { dispatch } = this.props;

    dispatch(utilityActions.clearUtility("vehicles"));
  }

  async componentDidMount() {
    const { reportType, isAdmin, isClient, clientId } = this.state;
    const { items: clients } = this.props.utility.clients;
    const { items: title_application_types } =
      this.props.utility.title_application_types;
    const { items: title_statuses } = this.props.utility.title_statuses;

    if (clients.length === 0) {
      await this.props.dispatch(
        utilityActions.getUtility("clients", {
          perPage: 1000,
          orders: [{ field: "clientname", direction: "asc" }],
        })
      );
    }
    if (title_application_types.length === 0) {
      this.props.dispatch(
        utilityActions.getUtility("title_application_types", {
          perPage: 1000,
          orders: [
            {
              field: "titleapplicationtype",
              direction: "asc",
            },
          ],
        })
      );
    }
    if (title_statuses.length === 0) {
      this.props.dispatch(
        utilityActions.getUtility("title_statuses", { perPage: 1000 })
      );
    }
    let columns = [];
    let utility = "vehicles_base";
    let availableFilters = [];
    let relations = [
      "application_type",
      "auction",
      "client_base",
      "client_load",
      "note_latest",
      "process_state",
      "processing_type",
      "title_status",
    ];
    let orders = [];

    if (reportType === "vaultVsBond") {
      // utility = "vehicles_base";

      orders.push({
        field: "vehicleid",
        direction: "desc",
      });

      columns = [
        {
          width: 2,
          title: "Client",
          field: "client_base.clientname",
          dataRender: (item) =>
            isAdmin ? (
              <RouterLink to={`/admin/clients`}>
                {item && item.client_base ? item.client_base.clientname : ""}
              </RouterLink>
            ) : (
              item && item.client_base && item.client_base.clientname
            ),
        },
        {
          width: 2,
          title: "Application Type",
          field: "application_type.titleapplicationtype",
        },
        {
          width: 3,
          title: "VIN",
          field: "vehiclevin",
          dataRender: (item) => (
            <RouterLink to={`/vehicles/${item.vehicleid}`}>
              {item.vehiclevin}
            </RouterLink>
          ),
        },
        {
          width: 2,
          title: "Vault Release Date",
          field: "vaultreleasedate",
        },
        {
          width: 2,
          title: "Bond Release Date",
          field: "bondreleasedate",
        },
      ];

      availableFilters = [];
      if (isClient) {
        availableFilters.push({
          name: "ClientID",
          field: "clientid",
          operator: "eq",
          value: clientId,
          type: "hidden",
          default: "1",
        });
      }
      if (isAdmin) {
        availableFilters.push({
          name: "ClientID",
          field: "clientid",
          operator: ">",
          value: "0",
          type: "hidden",
          default: "1",
        });
      }
      availableFilters.push({
        name: "Start Vault Date",
        field: "vaultreleasedate",
        operator: ">=",
        value: moment().subtract(1, "day").format("MM/DD/YYYY"),
        type: "date",
        default: "",
      });
      availableFilters.push({
        name: "End Vault Date",
        field: "vaultreleasedate",
        operator: "<=",
        value: moment().add(1, "day").format("MM/DD/YYYY"),
        type: "date",
        default: "",
      });
    }

    if (
      reportType === "pending" ||
      reportType === "pendingDOT" ||
      reportType === "pendingBMV"
    ) {
      // utility = "vehicles_base";

      orders.push({
        field: "vehicleid",
        direction: "desc",
      });

      columns = [
        {
          width: 2,
          title: "Client",
          field: "client_base.clientname",
          dataRender: (item) =>
            isAdmin ? (
              <RouterLink to={`/admin/clients`}>
                {item && item.client_base ? item.client_base.clientname : ""}
              </RouterLink>
            ) : (
              item && item.client_base && item.client_base.clientname
            ),
        },
        {
          width: 2,
          title: "Application Type",
          field: "application_type.titleapplicationtype",
        },
        {
          width: 3,
          title: "VIN",
          field: "vehiclevin",
          dataRender: (item) => (
            <RouterLink to={`/vehicles/${item.vehicleid}`}>
              {item.vehiclevin}
            </RouterLink>
          ),
        },
        {
          width: 1,
          title: "yr",
          field: "yr",
        },
        {
          width: 1,
          title: "make",
          field: "make",
        },
        {
          width: 1,
          title: "model",
          field: "model",
        },
        {
          width: 12,
          titleRender: () => <Divider />,
        },
        {
          width: 2,
          title: "Vehicle Status",
          field: "title_status.statusname",
        },
        {
          width: 2,
          title: "Process Type",
          field: "process_type_display",
        },
        {
          width: 2,
          title: "Process State",
          field: "process_state.stateabbreviation",
        },
        {
          width: 12,
          titleRender: () => <Divider />,
        },
        {
          width: 1,
          title: "Date Received",
          field: "recordcreateddate",
        },
        {
          width: 1,
          title: "Ownership",
          field: "titleregownershipdate",
        },
        {
          width: 1,
          title: "Scanned Ownership",
          field: "title_reg_ownership_scan_date",
        },
        {
          width: 1,
          title: "7501 Sent",
          field: "sent_7501_date",
        },
        {
          width: 1,
          title: "To State",
          field: "titlesenttobmvdate",
        },
        {
          width: 1,
          title: "Client Load",
          field: "client_load.name",
          dataRender: (item) => (item.client_load ? item.client_load.name : ""),
        },
        {
          width: 1,
          title: "Back from State",
          field: "titlebackfrombmvdate",
        },
        {
          width: 1,
          title: "To Customs",
          field: "submittedtocustomsdate",
        },
        {
          width: 1,
          title: "Crossed Border",
          field: "crossedborderdate",
        },
        {
          width: 1,
          title: "Confirmed Laded",
          field: "confirmedladeddate",
        },
        {
          width: 1,
          title: "DOT Submitted",
          field: "submitteddotdate",
        },
        {
          width: 1,
          title: "Vault Release",
          field: "vaultreleasedate",
        },
        {
          width: 1,
          title: "Bond Release",
          field: "bondreleasedate",
        },
        {
          width: 1,
          title: "White Copy",
          field: "state_white_copy_date",
        },
        {
          width: 1,
          title: "BKG Number",
          field: "bkg_entry_load_number",
        },
        { width: 12, titleRender: () => <Divider /> },
        { width: 3, title: "Auction", field: "auction.auctionname" },
        {
          width: 2,
          title: "Selling Auction",
          field: "auction_selling.auctionname",
        },
        {
          width: 2,
          title: "Selling Auction Lot",
          field: "auction_lot_id",
        },
        {
          width: 2,
          title: "Selling Auction Stock",
          field: "auction_stock_number",
        },

        { width: 12, titleRender: () => <Divider /> },
        { width: 12, title: "Last Note", field: "note_latest.note" },
      ];

      if (reportType === "pendingBMV") {
        columns = [
          {
            width: 2,
            title: "Client",
            field: "client_base.clientname",
            dataRender: (item) =>
              isAdmin ? (
                <RouterLink to={`/admin/clients`}>
                  {item && item.client_base ? item.client_base.clientname : ""}
                </RouterLink>
              ) : (
                item && item.client_base && item.client_base.clientname
              ),
          },
          {
            width: 2,
            title: "Application Type",
            field: "application_type.titleapplicationtype",
          },
          {
            width: 8,
            title: "VIN",
            field: "vehiclevin",
            dataRender: (item) => (
              <RouterLink to={`/vehicles/${item.vehicleid}`}>
                {item.vehiclevin}
              </RouterLink>
            ),
          },
          {
            width: 2,
            title: "Created",
            field: "recordcreateddate",
            dataRender: (item) =>
              item.recordcreateddate
                ? moment(item.recordcreateddate).format("M/D/Y")
                : "",
          },
          {
            width: 2,
            title: "Ownership",
            field: "titleregownershipdate",
            dataRender: (item) =>
              item.titleregownershipdate
                ? moment(item.titleregownershipdate).format("M/D/Y")
                : "",
          },
          {
            width: 2,
            title: "Process Type",
            field: "process_type_display",
          },
          {
            width: 2,
            title: "Process State",
            field: "process_state.stateabbreviation",
          },
          {
            width: 2,
            title: "To State",
            field: "titlesenttobmvdate",
            dataRender: (item) =>
              item.titlesenttobmvdate
                ? moment(item.titlesenttobmvdate).format("M/D/Y")
                : "",
          },
          {
            width: 2,
            title: "Back From State",
            field: "titlebackfrombmvdate",
            dataRender: (item) =>
              item.titlebackfrombmvdate
                ? moment(item.titlebackfrombmvdate).format("M/D/Y")
                : "",
          },
          { width: 12, titleRender: () => <Divider /> },
          {
            width: 2,
            title: "Days Since Created",
            field: "days_since_created",
          },
          {
            width: 2,
            title: "Days Since Sent To State",
            field: "days_since_sent_to_state",
          },
          {
            width: 2,
            title: "White Copy Date",
            field: "state_white_copy_date",
            dataRender: (item) =>
              item.state_white_copy_date
                ? moment(item.state_white_copy_date).format("M/D/Y")
                : "",
          },
          { width: 12, titleRender: () => <Divider /> },
          { width: 12, title: "Last Note", field: "note_latest.note" },
        ];
      }

      availableFilters.push({
        name: "VIN",
        field: "vehiclevin",
        operator: "like",
        value: "",
        type: "text",
        default: "",
      });
      availableFilters.push({
        name: "Vehicle Status",
        field: "statusid",
        operator: "eq",
        value: "",
        type: "choice",
        useUtility: true,
        utility: "vehicle_statuses",
        utilityValue: "statusname",
        choices: [],
        default: "",
      });

      if (reportType === "pending") {
        if (isClient) {
          availableFilters.push({
            name: "ClientID",
            field: "clientid",
            operator: "eq",
            value: clientId,
            type: "hidden",
            default: "1",
          });
        }
        if (isAdmin) {
          availableFilters.push({
            name: "ClientID",
            field: "clientid",
            operator: ">",
            value: "0",
            type: "hidden",
            default: "1",
          });
        }
        availableFilters.push({
          name: "Process State",
          field: "processstate",
          operator: "notnull",
          value: "1",
          type: "hidden",
          default: "1",
        });
        availableFilters.push({
          name: "Process Type",
          field: "processtype",
          operator: "eq",
          value: "",
          type: "choice",
          choices: [
            {
              key: "1",
              operator: "eq",
              value: "In State",
            },
            {
              key: "2",
              operator: "eq",
              value: "Out of State",
            },
          ],
          default: "",
        });
      }

      if (reportType === "pendingDOT") {
        if (isClient) {
          availableFilters.push({
            name: "ClientID",
            field: "clientid",
            operator: "eq",
            value: clientId,
            type: "hidden",
            default: "1",
          });
        }
        availableFilters.push({
          name: "Application Type",
          field: "titleprocessingtype",
          operator: "in",
          value: "18,19,27",
          type: "hidden",
          default: "1",
        });
      }
      if (reportType === "pendingBMV") {
        if (isClient) {
          availableFilters.push({
            name: "ClientID",
            field: "clientid",
            operator: "eq",
            value: clientId,
            type: "hidden",
            default: "1",
          });
        }
        if (isAdmin) {
          availableFilters.push({
            name: "ClientID",
            field: "clientid",
            operator: ">",
            value: "0",
            type: "hidden",
            default: "1",
          });
        }
        availableFilters.push({
          name: "AuctionID",
          field: "auctionid",
          operator: ">",
          value: "0",
          type: "hidden",
          default: "1",
        });
        availableFilters.push({
          name: "Sent to BMV",
          field: "titlesenttobmvdate",
          operator: "notnull",
          value: "1",
          type: "choice",
          choices: [
            {
              key: "0",
              operator: "isnull",
              value: "Not Sent",
            },
            {
              key: "1",
              operator: "notnull",
              value: "Sent",
            },
          ],
          default: "",
        });
        availableFilters.push({
          name: "Sent to Auction",
          field: "titlesenttoauctiondate",
          operator: "isnull",
          value: "0",
          type: "choice",
          choices: [
            {
              key: "0",
              operator: "isnull",
              value: "Not Sent",
            },
            {
              key: "1",
              operator: "notnull",
              value: "Sent",
            },
          ],
          default: "",
        });
      }

      if (
        reportType === "pending" ||
        reportType === "pendingDOT" ||
        reportType === "pendingBMV"
      ) {
        availableFilters.push({
          name: "TitleStatusCompleted",
          field: "titlestatusid",
          operator: "notin",
          value: "5,6",
          type: "hidden",
          default: "5,6",
        });
      }

      if (isAdmin) {
        availableFilters.push({
          name: "Client",
          field: "clientid",
          operator: "eq",
          value: "",
          type: "choice",
          useUtility: true,
          utility: "clients_active",
          utilityValue: "clientname",
          utilityOrder: {
            field: "clientname",
            direction: "asc",
          },
          utilityFilters: [
            {
              field: "clientactive",
              operator: "eq",
              value: 2,
            },
          ],
          choices: [],
          default: "",
        });
        availableFilters.push({
          name: "Titles Customer",
          field: "client.is_titles",
          operator: "eq",
          value: "1",
          type: "choice",
          choices: [
            {
              key: "1",
              value: "Title Customers",
            },
            {
              key: "0",
              value: "Not Title Customers",
            },
          ],
          default: "1",
        });
      }

      availableFilters.push({
        name: "Status",
        field: "titlestatusid",
        operator: "eq",
        value: "",
        type: "choice",
        useUtility: true,
        utility: "title_statuses",
        utilityValue: "statusname",
        choices: [],
        default: "",
      });

      availableFilters.push({
        name: "Application Type",
        field: "titleprocessingtype",
        operator: "eq",
        value: "",
        type: "choice",
        useUtility: true,
        utility: "title_application_types",
        utilityKey: "id",
        utilityValue: "titleapplicationtype",
        utilityOrder: {
          field: "titleapplicationtype",
          direction: "asc",
        },
        choices: [],
        default: "",
      });
    }

    if (reportType === "titlesSent") {
      utility = "titles_sent";
      orders = [];
      availableFilters = [];

      orders.push({
        field: "sent_to_auction_date",
        direction: "desc",
      });
      orders.push({
        field: "client_name",
        direction: "asc",
      });
      orders.push({
        field: "auction_name",
        direction: "asc",
      });
      availableFilters.push({
        name: "Date Sent to Auction",
        field: "titlesenttoauctiondate",
        operator: ">",
        value: "2019-09-01",
        type: "date",
        default: "",
      });
      if (isClient) {
        availableFilters.push({
          name: "ClientID",
          field: "clientid",
          operator: "eq",
          value: clientId,
          type: "hidden",
          default: "1",
        });
      }
      if (isAdmin) {
        availableFilters.push({
          name: "Client",
          field: "clientid",
          operator: "eq",
          value: "",
          type: "choice",
          useUtility: true,
          utility: "clients_active",
          utilityValue: "clientname",
          utilityOrder: {
            field: "clientname",
            direction: "asc",
          },
          utilityFilters: [
            {
              field: "clientactive",
              operator: "eq",
              value: 2,
            },
          ],
          choices: [],
          default: "",
        });
      }

      columns = [
        {
          width: 3,
          title: "Date",
          field: "titlesenttoauctiondate",
        },
        {
          width: 3,
          title: "Client",
          field: "clientname",
        },
        {
          width: 3,
          title: "Auction",
          field: "auctionname",
        },
        {
          width: 1,
          title: "Count",
          field: "aggregate",
        },
      ];
    }

    this.setState(
      { relations, orders, utility, columns, availableFilters },
      () => this.getNewData()
    );
  }

  render() {
    const {
      reportType,
      loading,
      reload,
      data,
      columns,
      filters,
      availableFilters,
      page,
      perPage,
      total,
    } = this.state;

    const { classes } = this.props;

    if (reload === true) {
      this.setState({ reload: false }, () => {
        this.componentDidMount();
      });
    }

    return (
      <PageWithAppDrawer2 pageTitle={`Reports ${reportType}`}>
        <Paper className={classes.paper}>
          <FiltersGrid
            classes={this.props.classes}
            availableFilters={availableFilters}
            filters={filters}
            onChange={this.handleFilterChange}
            clientList={this.props.utility.clients.items}
            typeList={this.props.utility.title_application_types.items}
            statusList={this.props.utility.title_statuses.items}
          />
          <br />
          <Divider />
          <ResponsiveGrid
            loading={loading}
            columns={columns}
            data={data}
            page={page - 1} // API has a 1 based page count, Material UI has a zero based page count
            perPage={perPage}
            total={total}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
            showActions
            onDownload={this.handleDownload}
          />
        </Paper>
      </PageWithAppDrawer2>
    );
  }

  handleDownload = () => {
    this.getNewData(null, null, true);
  };

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage + 1 }, () => this.getNewData());
  };

  handleChangeRowsPerPage = (event) => {
    const value = parseInt(event.target.value);
    this.setState(
      {
        perPage: value,
        page: 1,
      },
      () => this.getNewData()
    );
  };

  handleFilterChange = (newFilter) => {
    const availableFilters = this.state.availableFilters.map((filter) => {
      if (filter.field !== newFilter.field) return filter; // not the droid we're looking for, return unchanged
      if (filter.operator !== newFilter.operator) return filter; // not the droid we're looking for, return unchanged
      filter.value = newFilter.value;
      return filter;
    });
    this.setState(
      {
        availableFilters,
        page: 1,
      },
      () => this.getNewData()
    );
  };

  getNewData = async (page = null, perPage = null, download = false) => {
    const {
      reportType,
      columns,
      utility,
      relations,
      orders,
      availableFilters,
      total,
    } = this.state;
    if (!perPage) perPage = this.state.perPage;
    if (!page) page = this.state.page;

    if (download !== false) download = true;
    if (!download) this.setState({ loading: true });

    // translate available filters into service filters
    let newFilters = availableFilters
      .filter((filter) => {
        if (["", null].includes(filter.value)) return false;
        if (
          ["isnull", "notnull"].includes(filter.operator) &&
          filter.value === false
        )
          return false;
        return true;
      })
      .map((filter) => {
        return {
          field: filter.field,
          operator: filter.operator,
          value: filter.value,
        };
      });

    if (reportType === "pending") {
      newFilters.push({
        field: "titlesenttoauctiondate",
        operator: "isnull",
        value: null,
      });
    }

    if (reportType === "pendingDOT") {
      let subFilters = [];
      subFilters.push({
        field: "submitteddotdate",
        operator: "isnull",
        value: null,
      });
      subFilters.push({
        field: "vaultreleasedate",
        operator: "isnull",
        value: null,
      });
      subFilters.push({
        field: "bondreleasedate",
        operator: "isnull",
        value: null,
      });
      newFilters.push(subFilters);
      newFilters.push({
        field: "recordcreateddate",
        operator: ">",
        value: "2015-01-01 00:00:00",
      });
    }

    const options = {
      filters: newFilters,
      relations: relations,
      orders: orders,
      perPage: perPage,
      page: page,
    };

    if (download) {
      this.setState({ loading: true });
      options.perPage = total + 1;
      await utilityService
        .getUtility(utility, options)
        .then((values) => {
          const downloadHeaders = columns
            .filter((item) => item.title)
            .map((item) => {
              if (!item.title) return null;
              return item.title;
            });
          const downloadFields = columns
            .filter((item) => item.field)
            .map((item) => {
              if (!item.field) return null;
              return item.field;
            });

          return generateCSV(downloadHeaders, downloadFields, values.data);
        })
        .then((csv) => {
          // console.log("csv", csv);
          const blob = new Blob([csv], { type: "text/csv" });
          downloadBlob(blob, `${reportType}.csv`);
        })
        .catch(console.error);
      this.setState({ loading: false });
      return;
    }

    await this.props.dispatch(
      utilityActions.getUtility(utility, options, () => {
        const {
          items,
          page,
          per_page: perPage,
          total,
        } = this.props.utility[utility];
        this.setState({
          loading: false,
          data: items,
          page,
          perPage,
          total,
        });
      })
    );
  };
}

const styles = (theme) => ({
  root: {
    display: "flex",
    fontSize: 12,
  },
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    width: "100%",
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    width: "100%",
  },
  fixedHeight: {
    height: 240,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },

  Button: {
    display: "block",
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
});

function mapStateToProps(state) {
  const { authentication, sites, utility } = state;
  const { user, webUser } = authentication;
  return {
    user,
    webUser,
    sites,
    utility,
  };
}

const connectedTitleReportPage = connect(mapStateToProps)(
  withWidth()(withStyles(styles)(TitleReportPage))
);
export { connectedTitleReportPage as TitleReportPage };
