import {
  CircularProgress,
  Grid,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { authHeader, handleResponse, getEnvironmentURL } from "../_helpers";

import { PageWithAppDrawer2 } from "../_components/main/PageWithAppDrawer2";
import moment from "moment";

const url = getEnvironmentURL();

export default function VehicleIvrPage(props) {
  const classes = useStyles();
  let { vehicleid: id } = useParams();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [vehicle, setVehicle] = useState(null);
  const [vehicleIsComplete, setVehicleIsComplete] = useState(false);

  const getData = () => {
    if (id) {
      setLoading(true);
      setError(null);
      const requestOptions = {
        method: "GET",
        headers: {
          ...authHeader(),
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };

      return fetch(`${url}/api/vehicles/${id}`, requestOptions)
        .then(handleResponse)
        .then((response) => {
          setVehicle(response);
        })
        .catch((e) => {
          if (e.message) {
            setError(e.message);
          } else {
            setError(e);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    getData();
  }, [id]);

  useEffect(() => {
    if (!vehicle?.bondreleasedate) {
      setVehicleIsComplete(false);
    } else {
      setVehicleIsComplete(true);
    }
  }, [vehicle]);

  return (
    <PageWithAppDrawer2 pageTitle="Vehicle IVR">
      <Paper className={classes.paper}>
        <Typography variant="h5" style={{ textAlign: "center" }}>
          Vehicle IVR
        </Typography>
        {loading && <CircularProgress />}
        {error && <Typography variant="h5" style={{ color: "red" }} />}
        {!loading && vehicle && (
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <IvrVehicleCard vehicle={vehicle} />
            </Grid>
            {!vehicleIsComplete && (
              <Grid item xs={12}>
                <Typography
                  variant="h3"
                  style={{
                    fontWeight: "bold",
                    color: "red",
                    textAlign: "center",
                  }}
                >
                  Vehicle is not complete
                </Typography>
              </Grid>
            )}
            <Grid item xs={12}>
              <IvrVehicleDates dates={vehicle.nhtsa_dates} />
            </Grid>
            <Grid item xs={12}>
              <IvrVehicleImages
                images={vehicle.images}
                location={vehicle.nhtsa_conformance_location}
              />
            </Grid>
            <Grid item xs={12}>
              <IvrConformanceMetrics
                conformance={vehicle.vehicle_conformance_plus}
                location={vehicle.nhtsa_conformance_location}
              />
            </Grid>
          </Grid>
        )}
      </Paper>
    </PageWithAppDrawer2>
  );
}

function IvrVehicleCard(props) {
  const v = props.vehicle;
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell colSpan={2} align="center">
              <Typography style={{ fontWeight: "bold" }}>
                Vehicle Information
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow style={{ padding: 5 }}>
            <TableCell>VIN:</TableCell>
            <TableCell>{v.vehiclevin}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Year:</TableCell>
            <TableCell>{v.yr}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Make:</TableCell>
            <TableCell>{v.make}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Model:</TableCell>
            <TableCell>{v.model}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}

function IvrVehicleDates(props) {
  const dates = props.dates;
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell colSpan={2} align="center">
              <Typography style={{ fontWeight: "bold" }}>Dates</Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {dates.map((date, index) => (
            <TableRow key={index}>
              <TableCell>{date.name}</TableCell>
              <TableCell>{date.value}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

function IvrVehicleImages(props) {
  const images = props.images;
  const location = props.location;

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell colSpan={2} align="center">
              <Typography style={{ fontWeight: "bold" }}>Images</Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {images.map((image, index) => (
            <TableRow key={index}>
              <TableCell>
                <Typography style={{ fontWeight: "bold" }}>
                  {image.type?.name || image.docnote}
                </Typography>
                {(image.type?.name === "RI Certification Label" ||
                  image.type?.name === "Labels Together") && (
                  <>
                    Date: {moment(image.dateuploaded).format("YYYY-MM-DD")}
                    <br />
                    {location.name}
                    <br />
                    {location.street}
                    <br />
                    {location.city}
                    <br />
                    {location.state}
                    <br />
                    {location.zip}
                  </>
                )}
              </TableCell>
              <TableCell>
                {["jpg", "jpeg", "png"].includes(image.imageextension) ? (
                  <img src={image.cloud_url_thumb} alt={image.docnote} />
                ) : (
                  <a href={image.cloud_url_orig} target="_blank" rel="noopener noreferrer">
                    {image.docnote}
                  </a>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

function IvrConformanceMetrics(props) {
  const c = props.conformance;
  const location = props.location;

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell colSpan={2} align="center">
              <Typography style={{ fontWeight: "bold" }}>
                Conformance Items
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {c?.conformance_items &&
            c.conformance_items.map((item, index) => (
              <TableRow key={index}>
                <TableCell>
                  <Typography style={{ fontWeight: "bold" }}>
                    {item.description}
                  </Typography>
                </TableCell>
                <TableCell>
                  Completed Date:{" "}
                  {item.is_remediated
                    ? moment(item.completed_date).format("YYYY-MM-DD")
                    : ""}
                  <br />
                  Completed By:{" "}
                  {item.is_remediated ? item.completed_by_user.name : ""}
                  <br />
                  Conformance: {item.is_remediated ? "Yes" : "No"}
                  <br />
                  Notes: {item.remedy_notes}
                  <br />
                  {location.name}
                  <br />
                  {location.street}
                  <br />
                  {location.city}
                  <br />
                  {location.state}
                  <br />
                  {location.zip}
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(1),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
}));

export { VehicleIvrPage };
