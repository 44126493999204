import React, { useState, useEffect } from "react";

import { bayService } from "../../_services";

import { Typography, Button } from "@material-ui/core";

export default function BayIntegration(props) {
  const item = useState(props.item || {});
  // const [vehicles, setVehicles] = useState([]);
  // const [loadingIndex, setLoadingIndex] = useState([]);

  const [logs, setLogs] = useState([]);

  const addLog = (text) => {
    setLogs((logs) => [...logs, text]);
  };

  const startTest = async () => {
    console.log("start test");
    let token = null;
    let userId = null;
    let companyId = null;
    let shipments = null;
    // let shipmentId = null;
    let vid = null;
    await bayService.login("robertg", "testaccount10").then((response) => {
      console.log("response", response);
      if (response.error === false && response.token && response.user_id) {
        token = response.token;
        userId = response.user_id;
        addLog("userId=" + userId);
        addLog("token=" + token);
      }
    });
    if (!token) {
      console.log("invalid auth");
      return;
    }

    await bayService.companies(userId, token).then((response) => {
      console.log("response", response);
      if (response.error === false) {
        response.companies.map((obj) => {
          companyId = obj.id;
          addLog(obj.id + "," + obj.name);
          return null;
        });
      }
    });

    if (!companyId) {
      console.log("no company");
      return;
    }

    await bayService.shipments(userId, token, companyId).then((response) => {
      console.log("response", response);
      if (response.error === false) {
        shipments = response.shipments;
        response.shipments.map((obj) => {
          addLog(obj.id + ",paps=" + obj.paps_number);
          return null;
        });
      }
    });

    if (!shipments) {
      console.log("no shipments");
      return;
    }

    shipments.map(async (obj) => {
      //   console.log("shipment", obj);
      await bayService
        .shipment(userId, token, companyId, obj.id)
        .then((response) => {
          console.log("response", response);
          if (response.error === false && response.vehicles.length > 0) {
            response.vehicles.map((v) => {
              addLog("vin=" + v.vin);
              if (v.vin === item.vehiclevin) {
                addLog("VIN MATCH");
                vid = v.id;
              }
              return null;
            });
          }
        });
    });

    if (!vid) {
      console.log("no matching vehicle");
      return;
    }

    console.log("userId", userId);
    console.log("token", token);
    console.log("companyId", companyId);
    console.log("end test");
    // setLogs(["foo"]);
  };

  useEffect(() => {
    console.log("render");
  });

  return (
    <Typography variant="body1">
      <Button onClick={startTest}>Start Test</Button>
      <br />
      Logs:
      <br />
      {logs.map((log, index) => {
        return (
          <React.Fragment key={index}>
            <span>{log}</span>
            <br />
          </React.Fragment>
        );
      })}
    </Typography>
  );
}
