import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Font,
  Image,
} from "@react-pdf/renderer";

import logo from "../../_assets/dvslogo_web_new_cropped.png";

import { formatNumberToStringMoney } from "../../_helpers";

const debug = false;

// https://medium.com/investing-in-tech/creating-api-driven-pdfs-on-the-fly-in-the-browser-using-react-react-pdf-and-typescript-78ab4d1e7da5
// https://react-pdf.org/advanced#on-the-fly-rendering

Font.register({
  family: "Oswald",
  src: "https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf",
});

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
    fontFamily: "Helvetica",
  },
  bold: {
    fontFamily: "Helvetica-Bold",
  },
  title: {
    fontSize: 24,
    textAlign: "center",
    // fontFamily: "Oswald",
  },
  author: {
    fontSize: 12,
    textAlign: "center",
    // marginBottom: 40,
  },
  subtitle: {
    fontSize: 18,
    margin: 12,
    // fontFamily: "Oswald",
  },
  text: {
    fontSize: 12,
    textAlign: "left",
    // fontFamily: "Times-Roman",
  },
  image: {
    marginVertical: 15,
    marginHorizontal: 100,
  },
  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: "center",
    color: "grey",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
  viewCustomerInfo: {
    border: "1px solid black",
  },
  hr: {
    borderStyle: "solid",
    borderTopColor: "black",
    borderTopWidth: 1,
  },
});

export function MasterInvoiceDocument(props) {
  const client = props.item.client;
  const total = props.item.invoice_batches_full.reduce((acc, item) => {
    return acc + item.invoice_total;
  }, 0);
  console.log(total);
  return (
    <Document>
      <MasterInvoicePage>
        <MasterInvoiceHeader
          client={client}
          invoiceNumber={props.item.master_invoice_number}
        />
        <MasterInvoiceDetailsTable items={props.item.invoice_batches_full} />
        <InvoiceLine style={{ marginBottom: 5, marginTop: 5 }} />
        <MasterInvoiceDetailsFooter total={total} />
        <FooterPageNumber />
      </MasterInvoicePage>
    </Document>
  );
}

function MasterInvoicePage(props) {
  return <Page style={styles.body}>{props.children}</Page>;
}

function MasterInvoiceHeader(props) {
  return (
    <View fixed>
      <View style={{ alignItems: "center" }} debug={debug}>
        <Image
          style={[styles.image, { width: "20%" }]}
          src={logo}
          debug={debug}
        />
      </View>
      <View style={{ flexDirection: "row" }}>
        <View style={{ width: "50%" }}>
          <Text style={styles.author}>1919b S Post Rd</Text>
          <Text style={styles.author}>Indianapolis, IN 46239</Text>
        </View>
        <View style={{ width: "50%" }}>
          <Text style={styles.author}>Tel: (317)862-7626</Text>
          <Text style={styles.author}> Fax: (317)862-7680</Text>
        </View>
      </View>

      <InvoiceLine style={{ marginBottom: 5, marginTop: 5 }} />

      <MasterInvoiceCustomerInfo
        client={props.client}
        invoiceNumber={props.invoiceNumber}
      />

      <InvoiceLine style={{ marginBottom: 5, marginTop: 5 }} />
      <MasterInvoiceDetailsHeader />
    </View>
  );
}

function MasterInvoiceCustomerInfo(props) {
  return (
    <View style={[styles.text, { flexDirection: "row" }]}>
      <View style={{ width: "70%" }} debug={debug}>
        <Text style={styles.bold} debug={debug}>
          Client Name
        </Text>
        <Text>{props.client.clientname}</Text>
        <Text> </Text>
        <Text style={styles.bold} debug={debug}>
          Address
        </Text>
        <Text>{props.client.clientstreetaddress}</Text>
        <Text>
          {props.client.clientcity}, {props.client.clientstate}{" "}
          {props.client.clientzip}
        </Text>
      </View>

      <View style={{ width: "30%" }} debug={debug}>
        <Text style={styles.bold} debug={debug}>
          Invoice Number
        </Text>
        <Text debug={debug}>{props.invoiceNumber}</Text>
        <Text> </Text>
        <Text>{props.extraInfo || ""}</Text>
      </View>
    </View>
  );
}

function MasterInvoiceDetailsHeader(props) {
  return (
    <View style={[styles.bold, styles.text, { flexDirection: "row" }]}>
      <View style={{ width: "30%" }}>
        <Text>VIN</Text>
      </View>
      <View style={{ width: "30%" }}>
        <Text>Auction Name</Text>
      </View>
      <View style={{ width: "20%" }}>
        <Text>Client Stk No</Text>
      </View>
      <View style={{ width: "20%" }}>
        <Text>Total Charges</Text>
      </View>
    </View>
  );
}

function MasterInvoiceDetailsTable(props) {
  return (
    <View debug={debug}>
      {props.items.map((invoiceBatch, index) => {
        return (
          <View key={index}>
            {invoiceBatch.invoices.map((invoice, index2) => {
              return (
                <View
                  style={[styles.text, { flexDirection: "row" }]}
                  key={index2}
                >
                  <View style={{ width: "30%" }}>
                    <Text>{invoice.serialnumber}</Text>
                  </View>
                  <View style={{ width: "30%" }}>
                    <Text>{invoice.vehicle.auction.auctionname}</Text>
                  </View>
                  <View style={{ width: "20%" }}>
                    <Text>{invoice.vehicle.clientstocknumber}</Text>
                  </View>
                  <View style={{ width: "20%", textAlign: "right" }}>
                    <Text>{formatNumberToStringMoney(invoice.totalcost)}</Text>
                  </View>
                </View>
              );
            })}
          </View>
        );
      })}
    </View>
  );
}

function MasterInvoiceDetailsFooter(props) {
  return (
    <View style={styles.text}>
      <View style={{ flexDirection: "row" }}>
        <View style={{ width: "60%" }}></View>
        <View style={{ width: "20%" }}>
          <Text style={styles.bold}>Invoice Total (US$)</Text>
        </View>
        <View style={{ width: "20%" }}>
          <Text style={{ textAlign: "right" }}>
            ${Number(props.total).toFixed(2)}
          </Text>
        </View>
      </View>
    </View>
  );
}

function FooterPageNumber() {
  return (
    <Text
      style={styles.pageNumber}
      render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
      fixed
    />
  );
}

function InvoiceLine(props) {
  const style = props.style ? props.style : [];
  return (
    <View
      style={[styles.hr, { marginTop: 10, marginBottom: 10 }, { ...style }]}
    />
  );
}
