import React, { Fragment, useEffect, useState } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { Link as RouterLink, Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import moment from "moment";
import { pdf, BlobProvider } from "@react-pdf/renderer";

import { utilityActions } from "../_actions";

import { PageWithAppDrawer2 } from "../_components/main/PageWithAppDrawer2";
import FiltersGrid from "../_components/generic/FiltersGrid";
import { InvoiceSummaryDocument } from "../_components/pdf/InvoiceSummaryDocument";

import LaunchTwoToneIcon from "@material-ui/icons/LaunchTwoTone";
import CircularProgress from "@material-ui/core/CircularProgress";

import {
  Paper,
  Divider,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Grid,
} from "@material-ui/core";

import { formatNumberToStringMoney } from "../_helpers";

class InvoiceSummaryPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {}

  render() {
    const { classes } = this.props;
    return (
      <PageWithAppDrawer2 pageTitle={"Invoice Summary"}>
        <Paper className={classes.paper}>
          <InvoiceSummary />
        </Paper>
      </PageWithAppDrawer2>
    );
  }
}

function InvoiceSummary(props) {
  const dispatch = useDispatch();
  const invoice_summary = useSelector((state) => state.utility.invoice_summary);

  let date = new Date();
  const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
  const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

  const [getData, setGetData] = useState(false);
  const [start, setStart] = useState(moment(firstDay).format("YYYY-MM-DD"));
  const [end, setEnd] = useState(moment(lastDay).format("YYYY-MM-DD"));

  const [download, setDownload] = useState(false);

  const [availableFilters, setAvailableFilters] = useState([
    {
      name: "Start Date",
      field: "invoice_date",
      operator: ">=",
      value: moment().format("YYYY-MM-DD"),
      // value: "2020-07-01",
      type: "date",
      default: "",
    },
    {
      name: "End Date",
      field: "invoice_date",
      operator: "<=",
      value: moment().format("YYYY-MM-DD"),
      // value: moment(lastDay).format("YYYY-MM-DD"),
      type: "date",
      default: "",
    },
    {
      name: "Client",
      field: "client_id",
      operator: "eq",
      value: "",
      type: "choice",
      useUtility: true,
      utility: "clients_active",
      utilityKey: "clientid",
      utilityValue: "clientname",
      utilityFilters: [
        {
          field: "clientactive",
          operator: "eq",
          value: 2,
        },
      ],
      utilityOrder: {
        field: "clientname",
        direction: "asc",
      },
      choices: [],
      default: "",
    },
  ]);

  useEffect(() => {
    if (getData === true) {
      setGetData(false);
      getNewData();
    }
  }, [getData]);

  useEffect(() => {
    getNewData();
  }, []);

  const getNewData = async () => {
    if (!start || !end) {
      return;
    }

    const filters = availableFilters.filter((filter) => {
      return filter.value ? true : false;
    });

    const options = {
      filters,
      orders: [
        {
          field: "client_name",
          direction: "asc",
        },
        {
          field: "invoice_date",
          direction: "desc",
        },
      ],
      perPage: 1000,
      page: 1,
    };

    dispatch(utilityActions.getUtility("invoice_summary", options));
  };

  const handleFilterChange = (newFilter, refresh = true) => {
    const newFilters = availableFilters.map((filter) => {
      if (filter.field !== newFilter.field) return filter; // not the droid we're looking for, return unchanged
      if (filter.name !== newFilter.name) return filter; // not the droid we're looking for, return unchanged

      if (filter.name === "Start Date") setStart(newFilter.value);
      if (filter.name === "End Date") setEnd(newFilter.value);
      filter.value = newFilter.value;
      return filter;
    });
    setAvailableFilters(newFilters);
    if (refresh) {
      setGetData(true);
    }
  };

  return (
    <>
      <Grid container spacing={1} style={{ marginBottom: 20 }}>
        <Grid item>
          <Button
            variant="contained"
            size="small"
            color="primary"
            onClick={() => {
              handleFilterChange(
                {
                  name: "Start Date",
                  field: "invoice_date",
                  operator: ">=",
                  value: moment().subtract(1, "days").format("YYYY-MM-DD"),
                },
                false
              );
              handleFilterChange(
                {
                  name: "End Date",
                  field: "invoice_date",
                  operator: "<=",
                  value: moment().subtract(1, "days").format("YYYY-MM-DD"),
                },
                true
              );
            }}
          >
            Yesterday
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            size="small"
            color="primary"
            onClick={() => {
              handleFilterChange(
                {
                  name: "Start Date",
                  field: "invoice_date",
                  operator: ">=",
                  value: moment().format("YYYY-MM-DD"),
                },
                false
              );
              handleFilterChange(
                {
                  name: "End Date",
                  field: "invoice_date",
                  operator: "<=",
                  value: moment().format("YYYY-MM-DD"),
                },
                true
              );
            }}
          >
            Today
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            size="small"
            color="primary"
            onClick={() => setDownload(true)}
          >
            {download ? (
              <BlobProvider
                document={
                  <InvoiceSummaryDocument
                    data={invoice_summary.items}
                    start={start}
                    end={end}
                  />
                }
              >
                {({ blob, url, loading, error }) => {
                  if (!loading && url) {
                    // window.location.href = url;
                    // console.log("singleInvoice", singleInvoice);
                    // console.log("item", item);
                    window.open(url, "_blank");
                    setDownload(false);
                    return null;
                  }
                  return (
                    <>
                      <CircularProgress color="secondary" size={22} />
                      Print Batch
                    </>
                  );
                }}
              </BlobProvider>
            ) : (
              <>Print Summary</>
            )}
          </Button>
        </Grid>
      </Grid>
      <FiltersGrid
        availableFilters={availableFilters}
        onChange={handleFilterChange}
      />
      <Divider style={{ marginTop: 10, marginBottom: 50 }} />
      {invoice_summary.items && invoice_summary.items.length > 0 ? (
        <Table size="small">
          <TableBody>
            {invoice_summary.items.map((client, index) => (
              <Fragment key={index}>
                <TableRow style={{ backgroundColor: "black" }}>
                  <TableCell
                    style={{ color: "white", fontWeight: "bold" }}
                    colSpan={4}
                  >
                    {client.name || "foo"} ({client.count} invoices)
                  </TableCell>
                </TableRow>
                {client.dates.map((date, index2) => {
                  let totalForDay = 0;
                  return (
                    <Fragment key={index2}>
                      <TableRow style={{ backgroundColor: "lightgrey" }}>
                        <TableCell colSpan={4}>
                          {date.name} ({date.count} invoices)
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell style={{ fontWeight: "bold" }}>
                          Invoice #
                        </TableCell>
                        <TableCell style={{ fontWeight: "bold" }}>
                          Batch #
                        </TableCell>
                        <TableCell style={{ fontWeight: "bold" }}>
                          VIN
                        </TableCell>
                        <TableCell style={{ fontWeight: "bold" }}>
                          Invoice Total
                        </TableCell>
                      </TableRow>
                      {date.data.map((data, index3) => {
                        totalForDay += Number(data.total_cost);
                        return (
                          <Fragment key={index3}>
                            <TableRow>
                              <TableCell>{data.invoice_detail_id}</TableCell>
                              <TableCell>
                                <RouterLink
                                  to={`/invoicebatches/${data.invoice_batch_id}`}
                                  target="_blank"
                                >
                                  {data.invoice_batch_id}
                                  <LaunchTwoToneIcon
                                    style={{ fontSize: 12 }}
                                    color="primary"
                                  />
                                </RouterLink>
                              </TableCell>
                              <TableCell>
                                <RouterLink
                                  to={`/vehicles/${data.vehicle_id}`}
                                  target="_blank"
                                >
                                  {data.serial_number}
                                  <LaunchTwoToneIcon
                                    style={{ fontSize: 12 }}
                                    color="primary"
                                  />
                                </RouterLink>
                              </TableCell>
                              <TableCell>
                                {formatNumberToStringMoney(data.total_cost)}
                              </TableCell>
                            </TableRow>
                          </Fragment>
                        );
                      })}
                      <TableRow>
                        <TableCell
                          style={{ fontWeight: "bold", textAlign: "right" }}
                          colSpan={3}
                        >
                          Date Total:
                        </TableCell>
                        <TableCell style={{ fontWeight: "bold" }}>
                          {formatNumberToStringMoney(totalForDay)}
                        </TableCell>
                      </TableRow>
                    </Fragment>
                  );
                })}
              </Fragment>
            ))}
          </TableBody>
        </Table>
      ) : (
        "No Data"
      )}
    </>
  );
}

const styles = (theme) => ({
  root: {
    display: "flex",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
});

function mapStateToProps(state) {
  const { authentication, utility } = state;
  const { user } = authentication;
  const { us_states, auctions } = utility;
  return {
    user,
    auctions,
    us_states,
  };
}

const connectedInvoiceSummaryPage = connect(mapStateToProps)(
  withStyles(styles)(InvoiceSummaryPage)
);
export { connectedInvoiceSummaryPage as InvoiceSummaryPage };
