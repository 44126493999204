import React, { useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";

import { Button, TextField, makeStyles } from "@material-ui/core";

import PageWithAppDrawer3 from "../_components/main/PageWithAppDrawer3";
import FiltersGrid from "../_components/generic/FiltersGrid";
import ResponsiveGrid from "../_components/generic/ResponsiveGrid";
import { utilityActions } from "../_actions";
import ModalGeneric from "../_components/generic/ModalGeneric";
import { utilityService } from "../_services";
import { hashHistory } from "../_helpers";

export function SvetnaUsersPage(props) {
  const classes = useDashboardPageStyles();

  return (
    <PageWithAppDrawer3 pageTitle={"Svetna Users"}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <SvetnaUsersTable />
          </Paper>
        </Grid>
      </Grid>
    </PageWithAppDrawer3>
  );
}

const useDashboardPageStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
}));

function SvetnaUsersTable(props) {
  const dispatch = useDispatch();
  const items = useSelector((state) => state.utility.web_users);
  const webUser = useSelector((state) => state.authentication.webUser);
  const { client } = webUser;

  const [userFirstName, setUserFirstName] = useState("");
  const [userLastName, setUserLastName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [modalAddUser, setModalAddUser] = useState(false);

  const columns = [
    {
      width: 2,
      title: "Name",
      field: "name",
      dataRender: (item) => (
        <RouterLink to={`/svetna/users/${item.id}`}>{item.name}</RouterLink>
      ),
    },
    {
      width: 3,
      title: "User",
      field: "username",
    },
    {
      width: 3,
      title: "Email",
      field: "useremail",
    },
    {
      width: 2,
      title: "Svetna User",
      field: "",
      dataRender: (item) => (item?.cloud_user?.has_svetna_role ? "Yes" : "No"),
    },
    {
      width: 2,
      title: "Svetna Admin",
      field: "",
      dataRender: (item) =>
        item?.cloud_user?.has_svetna_admin_role ? "Yes" : "No",
    },
  ];

  const [availableFilters, setAvailableFilters] = React.useState([
    {
      name: "First",
      field: "firstname",
      operator: "like",
      value: "",
      type: "text",
      default: "",
    },
    {
      name: "Last",
      field: "lastname",
      operator: "like",
      value: "",
      type: "text",
      default: "",
    },
    {
      name: "Active",
      field: "active",
      operator: "eq",
      value: "0",
      type: "choice",
      choices: [
        {
          key: "0",
          value: "Active",
        },
        {
          key: "1",
          value: "Not Active",
        },
      ],
      default: "",
    },
    {
      name: "Client",
      field: "clientid",
      operator: "eq",
      value: client.clientid,
      type: "hidden",
      default: "",
    },
  ]);

  const [getData, setGetData] = React.useState(false);

  const [page, setPage] = React.useState(
    items.page ? Number(items.page) - 1 : 0
  );
  const [perPage, setPerPage] = React.useState(
    items.per_page ? Number(items.per_page) : 0
  );
  const [total, setTotal] = React.useState(
    items.total ? Number(items.total) : 0
  );

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
    setGetData(true);
  };
  const handleChangeRowsPerPage = (event) => {
    const value = parseInt(event.target.value);
    setPerPage(value);
    setGetData(true);
  };
  const getNewData = (download = false) => {
    let newFilters = availableFilters
      .filter((filter) => {
        if (["", null].includes(filter.value)) return false;
        if (
          ["isnull", "notnull"].includes(filter.operator) &&
          filter.value === false
        )
          return false;
        return true;
      })
      .map((filter) => {
        return {
          field: filter.field,
          operator: filter.operator,
          value: filter.value,
        };
      });

    dispatch(
      utilityActions.getUtility("web_users", {
        page,
        perPage,
        filters: newFilters,
      })
    );
  };

  useEffect(() => {
    if (getData === true) {
      setGetData(false);
      getNewData();
    }
  }, [getData]);

  useEffect(() => {
    setTotal(items.total ? Number(items.total) : 0);
    setPage(items.page ? Number(items.page) : 0);
    setPerPage(items.per_page ? Number(items.per_page) : 10);
  }, [items]);

  useEffect(() => {
    if (items.items.length === 0) {
      getNewData();
    }
  }, []);

  const handleFilterChange = (newFilter) => {
    const newFilters = availableFilters.map((filter) => {
      if (filter.field !== newFilter.field) return filter; // not the droid we're looking for, return unchanged
      filter.value = newFilter.value;
      return filter;
    });
    setAvailableFilters(newFilters);
    setPage(1);
    setGetData(true);
  };

  const addSvetnaUser = () => {
    utilityService
      .addUtility("web_users", {
        firstname: userFirstName,
        lastname: userLastName,
        useremail: userEmail,
        username: userEmail,
        clientid: client.clientid,
        client_id: client.clientid,
        active: 0,
      })
      .then((response) => {
        getNewData();
        return response;
      })
      .then((response) => {
        hashHistory.push(`/svetna/users/${response.id}`);
      })
      .catch((e) => {
        alert(e);
      });
    setModalAddUser(false);
  };

  return (
    <>
      <div>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            setModalAddUser(true);
          }}
        >
          Add Svetna User
        </Button>
      </div>
      <FiltersGrid
        availableFilters={availableFilters}
        onChange={handleFilterChange}
      />
      <ResponsiveGrid
        loading={items.loading}
        columns={columns}
        data={items.items}
        page={page - 1} // API has a 1 based page count, Material UI has a zero based page count
        perPage={perPage}
        total={total}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        showActions
      />
      <ModalGeneric
        open={modalAddUser}
        onClose={addSvetnaUser}
        onCancel={() => {
          setModalAddUser(false);
          setUserFirstName("");
          setUserLastName("");
          setUserEmail("");
        }}
        closeText={"Add Svetna User"}
        cancelText="Cancel"
        title={"Add Svetna User"}
      >
        <Grid container>
          <Grid item xs={12}>
            <TextField
              fullWidth
              placeholder="First Name"
              name="firstName"
              value={userFirstName}
              onChange={(e) => setUserFirstName(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              placeholder="Last Name"
              name="lastName"
              value={userLastName}
              onChange={(e) => setUserLastName(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              placeholder="Email"
              name="email"
              value={userEmail}
              onChange={(e) => setUserEmail(e.target.value)}
            />
          </Grid>
        </Grid>
      </ModalGeneric>
    </>
  );
}

export { SvetnaUsersPage as default };
