import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { connect } from "react-redux";
import { withStyles, withTheme } from "@material-ui/core/styles";
import withWidth from "@material-ui/core/withWidth";

import { PageWithAppDrawer2 } from "../_components/main/PageWithAppDrawer2";
import {
  Paper,
  CircularProgress,
  Typography,
  TextField,
  Button
} from "@material-ui/core";
import { userService } from "../_services";

class ResetPage extends React.Component {
  constructor(props) {
    super(props);
    const { token } = this.props.match ? this.props.match.params : {};
    this.state = {
      token
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { token } = nextProps.match ? nextProps.match.params : {};
    if (token !== prevState.token) {
      return {
        ...prevState,
        token,
        reload: true
      };
    }
    return false;
  }

  componentDidMount() {}

  render() {
    const { classes } = this.props;
    const { token } = this.state;

    return (
      <PageWithAppDrawer2 pageTitle={"Reset Password"} showSideBar={false}>
        <Paper className={classes.paper}>
          <ResetToken token={token} />
        </Paper>
      </PageWithAppDrawer2>
    );
  }
}

function ResetToken(props) {
  // const dispatch = useDispatch();
  // const item = useSelector(state => state.utility.web_users_item);

  const token = props.token;

  const [loading, setLoading] = React.useState(true);
  const [success, setSuccess] = React.useState(false);
  const [expired, setExpired] = React.useState(false);
  const [tokenData, setTokenData] = React.useState({ hasData: false });
  const [newPassword, setNewPassword] = React.useState("");

  React.useEffect(() => {
    if (!token) {
      setLoading(true);
      return;
    }

    if (tokenData.hasData === false) {
      userService.resetTokenData(token).then(response => {
        setTokenData({
          ...response,
          hasData: true
        });
        setLoading(false);
      });
    }
  }, [token, tokenData]);

  const updatePassword = () => {
    userService.resetTokenUpdate(token, newPassword).then(response => {
      if (response === "password updated") {
        setSuccess(true);
      } else {
        setExpired(true);
      }
    });
  };

  if (loading) {
    return (
      <Typography component="div" align="center">
        {token}
        <br />
        <CircularProgress />
      </Typography>
    );
  }

  if (!loading) {
    if (tokenData.expired === true || expired === true) {
      return (
        <Typography component="div" align="center">
          {tokenData.passwordresettoken}
          <br />
          This reset token has expired
        </Typography>
      );
    }
    if (success === true) {
      return (
        <Typography component="div" align="center">
          Your password has been updated
          <br />
          <br />
          <RouterLink to="/login">Login Page</RouterLink>
        </Typography>
      );
    }
    return (
      <Typography component="div" align="center">
        {tokenData.passwordresettoken}
        <br />
        Please choose a new Password
        <br />
        <TextField
          variant="outlined"
          type="password"
          value={newPassword}
          placeholder="New Password"
          onChange={event => setNewPassword(event.target.value)}
        />
        <br />
        <br />
        <Button variant="contained" color="primary" onClick={updatePassword}>
          Update Password
        </Button>
      </Typography>
    );
  }
}

const styles = theme => ({
  root: {
    display: "flex",
    fontSize: 12
  },
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto"
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    width: "100%"
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    width: "100%"
  },
  fixedHeight: {
    height: 240
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  },

  Button: {
    display: "block",
    marginTop: theme.spacing(2)
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  }
});

function mapStateToProps(state) {
  const { users, authentication, dms } = state;
  const { user } = authentication;
  return {
    user,
    users,
    dms
  };
}

const connectedResetPage = connect(mapStateToProps)(
  withWidth()(withTheme(withStyles(styles)(ResetPage)))
);
export { connectedResetPage as ResetPage };
