import React, { useCallback, useMemo } from "react";
import { useDropzone } from "react-dropzone";
import { connect, useSelector, useDispatch } from "react-redux";
import { withStyles } from "@material-ui/core/styles";

import { getEnvironmentURL, authHeader } from "../_helpers";

import { PageWithAppDrawer2 } from "../_components/main/PageWithAppDrawer2";
import ResponsiveGrid from "../_components/generic/ResponsiveGrid";
import Paper from "@material-ui/core/Paper";
import MuiAlert from "@material-ui/lab/Alert";


import {
  Snackbar,
  CircularProgress,
  Typography,
  Link,
} from "@material-ui/core";

class MassUpdateBondReleasePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      loading: false,
      snackbar: {
        open: false,
        severity: "info", //error, warning, info, success
        message: "default message",
        duration: 6000,
      },
    };
  }
  componentDidMount() {}

  static getDerivedStateFromProps(nextProps, prevState) {
    return null;
  }

  render() {
    const { classes } = this.props;
    const { data, snackbar, loading } = this.state;
    return (
      <PageWithAppDrawer2 pageTitle={"Bond Release Mass Update"}>
        <Paper className={classes.paper}>
          <DragAndDrop
            onAddFile={this.handleAddFile}
            onRejectFile={this.handleRejectFile}
          />
          {loading && <CircularProgress />}
          {data && <VehicleUpload data={data} />}
        </Paper>
        <Snackbar
          color={snackbar.severity}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          open={snackbar.open}
          autoHideDuration={snackbar.duration}
          onClose={() =>
            this.setState((prevState, props) => ({
              snackbar: {
                ...prevState.snackbar,
                open: false,
              },
            }))
          }
        >
          <MuiAlert severity={snackbar.severity} variant="filled">
            {snackbar.message}
          </MuiAlert>
        </Snackbar>
      </PageWithAppDrawer2>
    );
  }

  _showMessage = (message, severity = "info", duration = 6000) => {
    this.setState({
      snackbar: {
        open: true,
        severity,
        message,
        duration,
      },
    });
  };

  handleRejectFile = (file, errors) => {
    this._showMessage(
      file.path + " is not valid. " + errors[0].message,
      "error"
    );
  };

  handleAddFile = (file) => {
    this.setState({ loading: true });
    let formData = new FormData();
    formData.append("file", file);

    fetch(
      `${getEnvironmentURL()}/api/reports/management/bondReleaseMassUpdate`,
      {
        // Your POST endpoint
        method: "POST",
        headers: { ...authHeader() },
        body: formData,
      }
    )
      .then(
        (response) => response.json() // if the response is a JSON object
      )
      .then((data) => {
        if (data && data.results && Array.isArray(data.results)) {
          this.setState({ data });
          this._showMessage("Data uploaded");
        } else {
          this._showMessage("Unexpected data", "error");
        }
      })
      .finally(() => {
        this.setState({ loading: false });
      })
      .catch((error) => {
        this._showMessage(error, "error"); // Handle the error response object
      });
  };
}

function VehicleUpload(props) {

  const processColumns = [
    {
      width: 3,
      title: "VIN",
      field: "vin",
    },
    {
      width: 2,
      title: "Bond Release Date",
      field: "releaseDate",
    },
    {
      width: 7,
      title: "Updated",
      field: "",
      dataRender: (item) => (
        <>
          {item.updated ? (
            "Yes"
          ) : (
            <Typography style={{ color: "red" }}>{item.error}</Typography>
          )}
        </>
      ),
    },
  ];

  return (
    <>
    <Link href={props.data.url} target="_blank">{props.data.url}</Link>
    <ResponsiveGrid
      data={props.data.results}
      columns={processColumns}
      usePagination={false}
    />
    </>
  );
}

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
};

const activeStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

function DragAndDrop(props) {
  const onDrop = useCallback((acceptedFiles, fileRejections) => {
    acceptedFiles.forEach((file) => {
      props.onAddFile && props.onAddFile(file);
    });
    fileRejections.forEach((file) => {
      props.onRejectFile && props.onRejectFile(file.file, file.errors);
    });
  }, []);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({ onDrop, accept: [".pdf"] });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  return (
    <div className="container">
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} />
        <p>
          Drag 'n' drop
          <br />
          csv, xls, xlsx
          <br />
          Or click to select files
        </p>
      </div>
    </div>
  );
}

const styles = (theme) => ({
  root: {
    display: "flex",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
});

function mapStateToProps(state) {
  const { authentication, utility } = state;
  const { user } = authentication;
  const { us_states, auctions } = utility;
  return {
    user,
    auctions,
    us_states,
  };
}

const connectedMassUpdateBondReleasePage = connect(mapStateToProps)(
  withStyles(styles)(MassUpdateBondReleasePage)
);
export { connectedMassUpdateBondReleasePage as MassUpdateBondReleasePage };
