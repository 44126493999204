import React, { Fragment, useEffect, useState } from "react";
import { Link as RouterLink, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { withStyles } from "@material-ui/core/styles";

import { PageWithAppDrawer2 } from "../_components/main/PageWithAppDrawer2";
import { utilityActions } from "../_actions";
import {
  Paper,
  Grid,
  Select,
  Button,
  Backdrop,
  Snackbar,
  makeStyles,
  Hidden,
  AppBar,
  Tabs,
  Typography,
  Tab,
  Box,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import CircularProgress from "@material-ui/core/CircularProgress";
import { userService } from "../_services";
import JSONPretty from "react-json-pretty";

export function AdminUserDetailsPage(props) {
  const classes = useAdminUserPageStyles();
  const { id: userId } = useParams();

  return (
    <PageWithAppDrawer2 pageTitle={`User #${userId}`}>
      <RouterLink to="/admin/users">All Users</RouterLink>
      {/* <Paper className={classes.paper}> */}
      {/* <UserDetails itemId={userId} /> */}
      <UserDetailsWrapper itemId={userId} />
      {/* </Paper> */}
    </PageWithAppDrawer2>
  );
}

const useAdminUserPageStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    width: "100%",
  },
}));

function UserDetailsWrapper(props) {
  const classes = useAdminUserPageStyles();
  const [tabIndex, setTabIndex] = useState(0);
  const itemId = props.itemId;

  return (
    <>
      <Paper className={classes.paper}>
        <Hidden xsDown>
          <Tabs
            value={tabIndex}
            onChange={(e, newValue) => setTabIndex(newValue)}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
          >
            <SmallTab label="Basic Details" />
            <SmallTab label="Permissions" />
          </Tabs>
        </Hidden>
        <Hidden smUp>
          <Select
            native
            value={tabIndex}
            onChange={(e) => setTabIndex(Number(e.target.value))}
          >
            <option value={0}>Basic Details</option>
            <option value={1}>Permissions</option>
          </Select>
        </Hidden>
        <Typography
          component="div"
          role="tabpanel"
          hidden={tabIndex !== 0}
          style={{ paddingTop: 10 }}
        >
          <UserDetails itemId={itemId} />
        </Typography>

        <Typography
          component="div"
          role="tabpanel"
          hidden={tabIndex !== 1}
          style={{ paddingTop: 10 }}
        >
          <UserRoles itemId={itemId} />
        </Typography>
      </Paper>
    </>
  );
}

function UserRoles(props) {
  const dispatch = useDispatch();
  const allUserRoles = useSelector((state) => state.utility.user_roles);
  const userRoles = useSelector((state) => state.utility.user_to_roles);
  const webUser = useSelector((state) => state.utility.web_users_item);
  const cloudUser = webUser?.cloud_user || {};

  console.log({ cloudUser });

  const itemId = props.itemId;
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(allUserRoles?.loading || userRoles?.loading || webUser?.loading);
  }, [allUserRoles, userRoles, webUser]);

  const getUserData = () => {
    dispatch(utilityActions.getUtility("user_roles", {}));
    dispatch(
      utilityActions.getUtility("user_to_roles", {
        filters: [
          {
            field: "webuser_id",
            operator: "eq",
            value: itemId,
          },
        ],
      })
    );
  };

  useEffect(() => {
    getUserData();
  }, []);

  const handleRemoveRole = (userId, webUserId, userToRoleId) => {
    userId = Number(userId);
    webUserId = Number(webUserId);
    userToRoleId = Number(userToRoleId);

    dispatch(
      utilityActions.deleteOneUtility("user_to_roles", userToRoleId, null, () =>
        getUserData()
      )
    );
  };

  const handleAddRole = (userId, webUserId, userRoleId) => {
    userId = Number(userId);
    webUserId = Number(webUserId);
    userRoleId = Number(userRoleId);

    dispatch(
      utilityActions.addUtility(
        "user_to_roles",
        {
          user_id: userId,
          webuser_id: webUserId,
          user_role_id: userRoleId,
        },
        () => getUserData()
      )
    );
  };

  return (
    <Box>
      <Grid container>
        <Grid item xs={12}>
          <Typography style={{ fontWeight: "bold" }}>Permission</Typography>
          <Button variant="outlined" size="small" onClick={() => getUserData()}>
            Refresh User Roles
          </Button>
        </Grid>
        {loading ? (
          <CircularProgress />
        ) : (
          <>
            {allUserRoles?.items?.map((role) => {
              const hasRole = userRoles.items.find(
                (uRole) => uRole.user_role_id === role.id
              );
              return (
                <Fragment key={String(role.id)}>
                  <Grid item xs={8} sm={4}>
                    {role.description}
                  </Grid>
                  <Grid item xs={4} sm={8}>
                    {hasRole ? (
                      <Button
                        variant="outlined"
                        style={{ color: "red" }}
                        onClick={() =>
                          handleRemoveRole(cloudUser?.id, itemId, hasRole.id)
                        }
                      >
                        Remove
                      </Button>
                    ) : (
                      <Button
                        variant="outlined"
                        style={{ color: "blue" }}
                        onClick={() =>
                          handleAddRole(cloudUser?.id, itemId, role.id)
                        }
                      >
                        Add
                      </Button>
                    )}
                  </Grid>
                </Fragment>
              );
            })}
          </>
        )}
      </Grid>
      {/* <JSONPretty data={userRoles} />
      <JSONPretty data={allUserRoles} /> */}
    </Box>
  );
}

function UserDetails(props) {
  const dispatch = useDispatch();
  const item = useSelector((state) => state.utility.web_users_item);
  const states = useSelector((state) => state.utility.us_states);
  const clientGroups = useSelector((state) => state.utility.client_groups);
  const [roleId, setRoleId] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [snackbar, setSnackbar] = React.useState({
    open: false,
    severity: "info", //error, warning, info, success
    message: "default message",
    duration: 6000,
  });
  const fields = [
    {
      width: 2,
      title: "First Name",
      field: "firstname",
      type: "string",
    },
    {
      width: 2,
      title: "Last Name",
      field: "lastname",
      type: "string",
    },
    {
      width: 2,
      title: "Username",
      field: "username",
      type: "string",
    },
    {
      width: 2,
      title: "Client Group",
      field: "client_group_id",
      type: "selectclientgroup",
    },
    {
      width: 2,
      title: "Active",
      field: "active",
      type: "active",
    },
    {
      width: 2,
      title: "Can Sign SOC",
      field: "can_sign_soc",
      type: "boolean",
    },
    {
      width: 2,
      title: "Email",
      field: "useremail",
      type: "string",
    },
    {
      width: 1,
      title: "Phone",
      field: "phonenumber",
      type: "string",
    },
    {
      width: 2,
      title: "Role",
      field: "roleid",
      type: "role",
    },
  ];

  const id = props.itemId;

  useEffect(() => {
    // console.log("useEffect item", item);
    setRoleId(item ? item.roleid : "");
  }, [item]);

  useEffect(() => {
    if (states.items.length === 0) {
      dispatch(
        utilityActions.getUtility("us_states", {
          perPage: 1000,
          orders: [
            {
              field: "stateabbreviation",
              direction: "asc",
            },
          ],
        })
      );
    }
    if (clientGroups.items.length === 0) {
      dispatch(
        utilityActions.getUtility("client_groups", {
          perPage: 1000,
        })
      );
    }
  }, []);

  useEffect(() => {
    if (id) {
      if (!item) {
        getNewData();
      } else if (Number(item.id) != -Number(id)) {
        getNewData();
      }
    }
  }, [id]);

  const getNewData = () => {
    // console.log("getNewData");
    dispatch(
      utilityActions.getOneUtility("web_users", id, [], () => {
        setLoading(false);
      })
    );
  };

  const updateLocal = (event) => {
    const { name, value } = event.target;
    // console.log("updateLocal", { name, value });

    dispatch(
      utilityActions.updateOneUtilityLocal("web_users", { [name]: value })
    );
    if (name === "clientid") {
      dispatch(
        utilityActions.updateOneUtilityLocal("web_users", {
          client_id: value,
        })
      );
    }
  };

  const handleSave = () => {
    delete item.loading;
    setLoading(true);
    dispatch(
      utilityActions.updateUtility("web_users", item.id, item, () => {
        getNewData();
        setSnackbar({
          open: true,
          severity: "success",
          message: "User Updated",
          duration: 6000,
        });
      })
    );
  };

  const handleResetPassword = () => {
    const email = prompt("Send PW reset to email", item.useremail);
    userService.passwordReset(id, email).then((response) => {
      console.log(response);
    });
  };

  return (
    <>
      <Backdrop open={loading} style={{ zIndex: 100 }} />
      <Snackbar
        color={snackbar.severity}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={snackbar.open}
        autoHideDuration={snackbar.duration}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <MuiAlert severity={snackbar.severity} variant="filled">
          {snackbar.message}
        </MuiAlert>
      </Snackbar>
      {item && item.id ? (
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSave}
              disabled={loading}
            >
              Save Changes
            </Button>{" "}
            <Button
              variant="contained"
              color="primary"
              onClick={handleResetPassword}
              disabled={loading}
            >
              Reset Password
            </Button>
          </Grid>
          {fields.map((field, index) => (
            <React.Fragment key={index}>
              <Grid item xs={12} sm={4}>
                {field.title}
              </Grid>
              <Grid item xs={12} sm={8}>
                {field.type === "string" && (
                  <input
                    type="text"
                    name={field.field}
                    placeholder={field.title}
                    value={item[field.field] || ""}
                    onChange={updateLocal}
                  />
                )}
                {field.type === "selectclientgroup" && (
                  <Select
                    native
                    name={field.field}
                    value={item[field.field] || ""}
                    onChange={updateLocal}
                    style={{ fontSize: 12 }}
                    displayEmpty
                  >
                    <option value="">No Group</option>
                    {clientGroups &&
                      clientGroups.items.map((group, index) => (
                        <option key={index} value={group.id}>
                          {group.name}
                        </option>
                      ))}
                  </Select>
                )}
                {field.type === "boolean" && (
                  <Select
                    native
                    name={field.field}
                    value={Number(item[field.field])}
                    onChange={updateLocal}
                    style={{ fontSize: 12 }}
                  >
                    <option value={0}>No</option>
                    <option value={1}>Yes</option>
                  </Select>
                )}
                {field.type === "state" && (
                  <Select
                    native
                    name={field.field}
                    value={item[field.field] || ""}
                    onChange={updateLocal}
                    style={{ fontSize: 12 }}
                    displayEmpty
                  >
                    <option value="">State</option>
                    {states &&
                      states.items.map((state, index) => (
                        <option key={index} value={state.stateid}>
                          {state.stateabbreviation} - {state.statefull}
                        </option>
                      ))}
                  </Select>
                )}
                {field.type === "country" && (
                  <Select
                    native
                    name={field.field}
                    value={item[field.field] || ""}
                    onChange={updateLocal}
                    style={{ fontSize: 12 }}
                    displayEmpty
                  >
                    <option value="">Country</option>
                    <option value={1}>United States</option>
                    <option value={2}>Canada</option>
                  </Select>
                )}
                {field.type === "role" && (
                  <Select
                    native
                    name={field.field}
                    value={item[field.field] || ""}
                    onChange={updateLocal}
                    style={{ fontSize: 12 }}
                    displayEmpty
                  >
                    <option value="">Role</option>
                    <option value={2}>Admin</option>
                    <option value={1}>Client</option>
                    <option value={11}>Client Admin</option>
                  </Select>
                )}
                {field.type === "active" && (
                  <Select
                    native
                    name={field.field}
                    value={Number(item[field.field])}
                    onChange={updateLocal}
                    style={{ fontSize: 12 }}
                  >
                    <option value={0}>Active</option>
                    <option value={1}>Disabled</option>
                  </Select>
                )}
              </Grid>
            </React.Fragment>
          ))}
          {roleId === 2 && (
            <AdminUserAdminOptions onChange={updateLocal} item={item} />
          )}
          {roleId === 1 && (
            <AdminUserClientOptions onChange={updateLocal} item={item} />
          )}
          {roleId === 11 && (
            <AdminUserClientOptions onChange={updateLocal} item={item} />
          )}
        </Grid>
      ) : (
        <CircularProgress />
      )}
    </>
  );
}

function AdminUserClientOptions(props) {
  const clients = useSelector((state) => state.utility.clients_active);
  return (
    <>
      <Grid item xs={12} sm={4}>
        Client
      </Grid>
      <Grid item xs={12} sm={8}>
        <Select
          native
          name={`clientid`}
          value={props.item[`clientid`] || 0}
          onChange={props.onChange}
          style={{ fontSize: 12 }}
          displayEmpty
        >
          <option key={0} value={0}>
            Client
          </option>
          {clients.items.map((client, index) => (
            <option key={index} value={client.clientid}>
              {client.clientname}
            </option>
          ))}
        </Select>
      </Grid>
      <Grid item xs={12} sm={4}>
        Titles Sent
      </Grid>
      <Grid item xs={12} sm={8}>
        <Select
          native
          name={`titlessent`}
          value={props.item[`titlessent`] || 0}
          onChange={props.onChange}
          style={{ fontSize: 12 }}
        >
          <option value={0}>No</option>
          <option value={1}>Yes</option>
        </Select>
      </Grid>
      <Grid item xs={12} sm={4}>
        Title History
      </Grid>
      <Grid item xs={12} sm={8}>
        <Select
          native
          name={`titlehistory`}
          value={props.item[`titlehistory`] || 0}
          onChange={props.onChange}
          style={{ fontSize: 12 }}
        >
          <option value={0}>No</option>
          <option value={1}>Yes</option>
        </Select>
      </Grid>
      <Grid item xs={12} sm={4}>
        Report Menu
      </Grid>
      <Grid item xs={12} sm={8}>
        <Select
          native
          name={`reportmenu`}
          value={props.item[`reportmenu`] || 0}
          onChange={props.onChange}
          style={{ fontSize: 12 }}
        >
          <option value={0}>No</option>
          <option value={1}>Yes</option>
        </Select>
      </Grid>
      <Grid item xs={12} sm={4}>
        Vehicle Search
      </Grid>
      <Grid item xs={12} sm={8}>
        <Select
          native
          name={`vehiclesearch`}
          value={props.item[`vehiclesearch`] || 0}
          onChange={props.onChange}
          style={{ fontSize: 12 }}
        >
          <option value={0}>No</option>
          <option value={1}>Yes</option>
        </Select>
      </Grid>
      <Grid item xs={12} sm={4}>
        Lien Verifications
      </Grid>
      <Grid item xs={12} sm={8}>
        <Select
          native
          name={`lienverifications`}
          value={props.item[`lienverifications`] || 0}
          onChange={props.onChange}
          style={{ fontSize: 12 }}
        >
          <option value={0}>No</option>
          <option value={1}>Yes</option>
        </Select>
      </Grid>
    </>
  );
}

const SmallTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(4),
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:hover": {
      color: "#40a9ff",
      opacity: 1,
    },
    "&$selected": {
      color: "#1890ff",
      fontWeight: theme.typography.fontWeightMedium,
    },
    "&:focus": {
      color: "#40a9ff",
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

function AdminUserAdminOptions(props) {
  return (
    <>
      <Grid item xs={12} sm={4}>
        Title Module
      </Grid>
      <Grid item xs={12} sm={8}>
        <Select
          native
          name={`titlemodule`}
          value={props.item[`titlemodule`] || 0}
          onChange={props.onChange}
          style={{ fontSize: 12 }}
        >
          <option value={0}>No</option>
          <option value={1}>Yes</option>
        </Select>
      </Grid>
      <Grid item xs={12} sm={4}>
        Client Contact Module
      </Grid>
      <Grid item xs={12} sm={8}>
        <Select
          native
          name={`clientcontactmodule`}
          value={props.item[`clientcontactmodule`] || 0}
          onChange={props.onChange}
          style={{ fontSize: 12 }}
        >
          <option value={0}>No</option>
          <option value={1}>Yes</option>
        </Select>
      </Grid>
      <Grid item xs={12} sm={4}>
        Management Module
      </Grid>
      <Grid item xs={12} sm={8}>
        <Select
          native
          name={`managementmodule`}
          value={props.item[`managementmodule`] || 0}
          onChange={props.onChange}
          style={{ fontSize: 12 }}
        >
          <option value={0}>No</option>
          <option value={1}>Yes</option>
        </Select>
      </Grid>
    </>
  );
}

export { AdminUserDetailsPage as default };
