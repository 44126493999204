import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { connect, useSelector, useDispatch } from "react-redux";

import { withStyles } from "@material-ui/core/styles";
import withWidth from "@material-ui/core/withWidth";

import { utilityActions } from "../_actions";

import {
  getEnvironmentURL,
} from "../_helpers";

import { PageWithAppDrawer2 } from "../_components/main/PageWithAppDrawer2";
import ResponsiveGrid from "../_components/generic/ResponsiveGrid";
import FiltersGrid from "../_components/generic/FiltersGrid";

import { Paper, Button, Typography } from "@material-ui/core";

import { Settings as missingOwnershipSettings } from "../_components/dashboard/drillMissingOwnership";
import { Settings as missingBondReleaseSettings } from "../_components/dashboard/drillMissingBondRelease";

class DashboardDrillPage extends React.Component {
  constructor(props) {
    super(props);
    const { clientId, drillType } = this.props.match
      ? this.props.match.params
      : {};
    const { webUser } = this.props;
    const isAdmin = webUser.roleid === 2 ? true : false;
    const isClient = webUser.roleid !== 2 ? true : false;
    this.state = {
      loading: true,
      clientId,
      drillType,
      isAdmin,
      isClient,
      reload: false,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { drillType, clientId } = nextProps.match
      ? nextProps.match.params
      : {};
    if (drillType !== prevState.drillType || clientId !== prevState.clientId) {
      return {
        ...prevState,
        drillType,
        clientId,
        reload: true,
      };
    }
    return false;
  }

  async componentDidMount() {}

  render() {
    const { drillType, clientId, reload } = this.state;

    const { classes } = this.props;

    if (reload === true) {
      this.setState({ reload: false }, () => {
        this.componentDidMount();
      });
    }

    return (
      <PageWithAppDrawer2 pageTitle={`Dashboard Drilldown`}>
        <Paper className={classes.paper}>
          <DashboardDrill drillType={drillType} clientId={clientId} />
        </Paper>
      </PageWithAppDrawer2>
    );
  }
}

function DashboardDrill(props) {
  const dispatch = useDispatch();
  let history = useHistory();
  const items = useSelector((state) => state.utility.vehicles_drill);
  const [title, setTitle] = useState("Dashboard Drill");
  const [notes, setNotes] = useState([]);
  const [columns, setColumns] = useState([]);
  const [availableFilters, setAvailableFilters] = useState([]);

  const [getData, setGetData] = useState(false);

  const [page, setPage] = useState(items.page ? Number(items.page) - 1 : 0);
  const [perPage, setPerPage] = useState(
    items.per_page ? Number(items.per_page) : 25
  );
  const [total, setTotal] = useState(items.total ? Number(items.total) : 0);

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
    setGetData(true);
  };
  const handleChangeRowsPerPage = (event) => {
    const value = parseInt(event.target.value);
    setPerPage(value);
    setGetData(true);
  };

  useEffect(() => {
    if (props.drillType) {
      let settings = {};
      if (props.drillType === "needsownership") {
        settings = missingOwnershipSettings;
      }
      if (props.drillType === "needsbondrelease") {
        settings = missingBondReleaseSettings;
      }
      setColumns(settings.columns);
      setAvailableFilters(settings.availableFilters);
      setTitle(settings.title);
      setNotes(settings.notes);
      setGetData(true);
    }
  }, [props.drillType]);

  useEffect(() => {
    if (getData === true) {
      setGetData(false);
      getNewData();
    }
  }, [getData]);

  useEffect(() => {
    setTotal(items.total ? Number(items.total) : 0);
    setPage(items.page ? Number(items.page) : 0);
    setPerPage(items.per_page ? Number(items.per_page) : 25);
  }, [items]);

  useEffect(() => {
    if (items.items.length === 0) {
      // getNewData();
    }
  }, []);

  // const handleDownload = () => {};

  const getNewData = () => {
    let newFilters = availableFilters
      .filter((filter) => {
        if (["", null].includes(filter.value)) return false;
        // if (
        //   ["isnull", "notnull"].includes(filter.operator) &&
        //   filter.value === false
        // )
        //   return false;
        return true;
      })
      .map((filter) => {
        return {
          field: filter.field,
          operator: filter.operator,
          value: filter.value,
        };
      });
    dispatch(
      utilityActions.getUtility("vehicles_drill", {
        page,
        perPage,
        filters: newFilters,
        // relations: ["client"],
        orders: [{ field: "vehicleid", direction: "desc" }],
      })
    );
  };

  const handleFilterChange = (newFilter) => {
    const newFilters = availableFilters.map((filter) => {
      if (filter.field !== newFilter.field) return filter; // not the droid we're looking for, return unchanged
      filter.value = newFilter.value;
      return filter;
    });
    setAvailableFilters(newFilters);
    setPage(1);
    setGetData(true);
  };

  return (
    <>
      <div>
        <Button
          variant="contained"
          size="small"
          color="primary"
          onClick={history.goBack}
        >
          Back
        </Button>
      </div>
      <div style={{ width: "100%", textAlign: "center" }}>
        <Typography style={{ fontWeight: "bold", paddingTop: 20 }}>
          {title}
        </Typography>
        {notes &&
          notes.map((note, index) => (
            <Typography key={index}>{note}</Typography>
          ))}
      </div>
      {props.drillType === "needsbondrelease" && (
        <div>
          <Button
            size="small"
            color="primary"
            variant="contained"
            href={
              getEnvironmentURL() + "/api/reports/management/missingBondRelease"
            }
            target="_blank"
          >
            Download as XLSX
          </Button>
        </div>
      )}

      <FiltersGrid
        availableFilters={availableFilters}
        onChange={handleFilterChange}
      />
      <ResponsiveGrid
        loading={items.loading}
        columns={columns}
        data={items.items}
        page={page - 1} // API has a 1 based page count, Material UI has a zero based page count
        perPage={perPage}
        total={total}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        // showActions
        // onDownload={handleDownload}
      />
    </>
  );
}

const styles = (theme) => ({
  root: {
    display: "flex",
    fontSize: 12,
  },
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    width: "100%",
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    width: "100%",
  },
  fixedHeight: {
    height: 240,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },

  Button: {
    display: "block",
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
});

function mapStateToProps(state) {
  const { authentication, sites, utility } = state;
  const { user, webUser } = authentication;
  return {
    user,
    webUser,
    sites,
    utility,
  };
}

const connectedDashboardDrillPage = connect(mapStateToProps)(
  withWidth()(withStyles(styles)(DashboardDrillPage))
);
export { connectedDashboardDrillPage as DashboardDrillPage };
