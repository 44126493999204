import React, { useEffect, useState } from "react";
// import { Link as RouterLink } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { utilityActions } from "../../_actions";

import MomentUtils from "@date-io/moment";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import { Grid, Select, Input, Checkbox } from "@material-ui/core";

export default function FiltersGrid(props) {
  const [availableFilters, setAvailableFilters] = useState([]);

  useEffect(() => {
    setAvailableFilters(props.availableFilters);
  }, [props]);

  return (
    <Grid
      container
      display="flex"
      justify="center"
      alignContent="center"
      alignItems="center"
    >
      <Grid
        container
        style={{ flex: 1, fontSize: 12 }}
        alignItems="center"
        alignContent="center"
      >
        {availableFilters.map((filter, index) => {
          if (!filter) {
            return;
          }
          if (filter.type === "hidden")
            return <React.Fragment key={index}></React.Fragment>;
          const isActive = filter.value === filter.default ? false : true;
          return (
            <React.Fragment key={index}>
              <Grid
                item
                xs={2}
                style={{ fontWeight: isActive ? "bold" : "inherit" }}
              >
                {filter.name}
              </Grid>
              <Grid item xs={4}>
                {filter.type === "choice" && (
                  <FiltersTypeSelect
                    filter={filter}
                    onChange={props.onChange}
                  />
                )}
                {filter.type === "text" && (
                  <FiltersTypeText filter={filter} onChange={props.onChange} />
                )}
                {filter.type === "checkbox" && (
                  <FiltersTypeCheckbox
                    filter={filter}
                    onChange={props.onChange}
                  />
                )}
                {filter.type === "date" && (
                  <FiltersTypeDate filter={filter} onChange={props.onChange} />
                )}
              </Grid>
            </React.Fragment>
          );
        })}
      </Grid>
    </Grid>
  );
}

function FiltersTypeCheckbox(props) {
  // const classes = props.classes;
  const [filter, setFilter] = useState({});
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    setFilter(props.filter);
    setChecked(props.filter.value === false ? false : true);
  }, [props.filter]);

  return (
    <Checkbox
      checked={checked}
      onChange={(event) =>
        props.onChange({
          field: filter.field,
          operator: filter.operator,
          value: event.target.checked,
        })
      }
      value={filter.value}
    />
  );
}

function FiltersTypeText(props) {
  const [filter, setFilter] = useState({});
  // const [value, setValue] = useState("");
  const [text, setText] = useState("");

  useEffect(() => {
    setFilter(props.filter);
    setText(props.filter.value || "");
  }, [props.filter]);

  return (
    <Input
      style={{ fontSize: 12 }}
      value={text || ""}
      placeholder={filter.name}
      onChange={(event) => setText(event.target.value)}
      onKeyPress={(event) => {
        if (event.key === "Enter") {
          props.onChange({
            name: filter.name || null,
            field: filter.field,
            operator: filter.operator,
            value: text,
          });
        }
      }}
    />
  );
}

function FiltersTypeSelect(props) {
  const dispatch = useDispatch();

  const [filter, setFilter] = useState({});
  const [choices, setChoices] = useState([]);

  const utilitySelector = useSelector((state) => state.utility);

  useEffect(() => {
    if (props.filter.useUtility) {
      const {
        utility,
        // utilityValue,
        utilityOrder,
        utilityFilters,
        utilityPerPage,
        utilityRelations,
      } = props.filter;
      const { items } = utilitySelector[utility];
      if (items.length === 0) {
        dispatch(
          utilityActions.getUtility(utility, {
            perPage: utilityPerPage || 1000,
            orders: utilityOrder ? [utilityOrder] : [],
            filters: utilityFilters ? [...utilityFilters] : [],
            relations: utilityRelations || null,
          })
        );
      }
    }
  }, []);

  useEffect(() => {
    setFilter(props.filter);
    if (props.filter.useUtility) {
      const { utility, utilityValue } = props.filter;
      const { items } = utilitySelector[utility];
      setChoices(
        items.map((item) => ({
          key: item.id,
          operator: "eq",
          value: item[utilityValue],
        }))
      );
    } else {
      setChoices(props.filter.choices || []);
    }
  }, [props.filter, utilitySelector]);

  return (
    <Select
      native
      style={{ fontSize: 12 }}
      value={filter.value || ""}
      name={filter.name || null}
      onChange={(event, child) => {
        props.onChange({
          name: event.target.name,
          field: filter.field,
          operator: "eq",
          value: event.target.value,
        });
        if (
          props.filter.onChange &&
          typeof props.filter.onChange === "function"
        ) {
          props.filter.onChange(event.target.value);
        }
      }}
      displayEmpty
    >
      <option value={""}>Choose Option</option>

      {choices.map((choice, index) => (
        <option
          key={index}
          data-operator={choice.operator || null}
          value={choice.key}
        >
          {choice.value}
        </option>
      ))}
      {props.filter.includeAdditionalChoices?.map((choice, index) => (
        <option key={`a_${index}`} data-operator={choice.operator || null} value={choice.key}>{choice.value}</option>
      ))}
    </Select>
  );
}

function FiltersTypeDate(props) {
  // const classes = props.classes;
  const [filter, setFilter] = useState({});

  useEffect(() => {
    setFilter(props.filter);
  }, [props.filter]);

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <KeyboardDatePicker
        clearable
        style={{ fontSize: 12 }}
        // variant="inline"
        name={filter.field}
        label={filter.name}
        format="MM/DD/YYYY"
        autoOk={true}
        value={filter.value || null}
        InputProps={{
          style: { fontSize: 12 },
        }}
        InputLabelProps={{ style: { fontSize: 12 } }}
        // inputProps={{ style: { fontSize: 12 } }}
        // style={{ fontSize: 12 }}
        onChange={(date) =>
          props.onChange({
            name: filter.name,
            field: filter.field,
            operator: filter.operator,
            value: date ? date.format("YYYY-MM-DD") : "",
          })
        }
      />
    </MuiPickersUtilsProvider>
  );
}
