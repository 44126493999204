import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import moment from "moment-timezone";

import { hashHistory } from "../_helpers";
import { utilityActions } from "../_actions";
import { utilityService, vehicleService } from "../_services";

import { PageWithAppDrawer2 } from "../_components/main/PageWithAppDrawer2";
import ScannerComponentDynamsoft from "../_components/generic/ScannerComponentDynamsoft";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import CloseIcon from "@material-ui/icons/Close";

import {
  Typography,
  Button,
  CircularProgress,
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  makeStyles,
  RadioGroup,
  FormControlLabel,
  Radio,
  Backdrop,
} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import ResponsiveGrid from "../_components/generic/ResponsiveGrid";
import useHasSvetnaBuyerRole from "../_hooks/useHasSvetnaBuyerRole";

export function SvetnaScannerPage() {
  const dispatch = useDispatch();
  const classes = useScannerPageStyles();
  //   const webUser = useSelector((state) => state.authentication.webUser);
  const client = useSelector((state) => state.authentication.webUser?.client);
  const vehicles = useSelector(
    (state) => state.utility.svetna_scanned_vehicles
  );
  const vehiclesPreimport = useSelector(
    (state) => state.utility.svetna_scanned_preimport
  );
  const hasSvetnaBuyerRole = useHasSvetnaBuyerRole();

  const [loading, setLoading] = useState(false);
  const [openScanner, setOpenScanner] = useState(false);
  const [startScanner, setStartScanner] = useState(false);
  const [scannedBarcode, setScannedBarcode] = useState(null);
  const [last6, setLast6] = useState("");

  useEffect(() => {
    if (vehicles.loading || vehiclesPreimport.loading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [vehicles, vehiclesPreimport]);

  const handleLast6 = (vin) => {
    if (vin.length < 6) {
      alert("Provide at least 6 numbers/letters");
      return;
    }

    if (!hasSvetnaBuyerRole) {
      // buyers don't need to see DVS vehicles
      dispatch(
        utilityActions.getUtility(
          "svetna_scanned_vehicles",
          {
            filters: [
              {
                field: "vehiclevin",
                operator: "like",
                value: vin,
              },
              {
                field: "cancelled",
                operator: "neq",
                value: 1,
              },
            ],
            relations: [
              "client_note_latest",
              "client",
              "title_status",
              "vehicle_photos",
            ],
          },
          () => {
            setScannedBarcode(vin);
          }
        )
      );
    }

    dispatch(
      utilityActions.getUtility(
        "svetna_scanned_preimport",
        {
          filters: [
            {
              field: "vin",
              operator: "like",
              value: vin,
            },
          ],
          relations: ["vehicle"],
        },
        () => {
          setScannedBarcode(vin);
        }
      )
    );
  };

  const handleBarcode = async (result, barcode) => {
    setStartScanner(false);
    setOpenScanner(false);

    let vin = null;
    if (barcode.includes(",")) {
      // this is likely a GM QR Code, or something with extra data, but VIN is usually first
      const parts = barcode.split(",");
      vin = parts[0];
    } else {
      vin = barcode;
    }

    // clean the vin for any import flags
    vin = vin.replace(/[iI]/g, "");

    if (!hasSvetnaBuyerRole) {
      // buyers don't need to see DVS vehicles
      dispatch(
        utilityActions.getUtility(
          "svetna_scanned_vehicles",
          {
            filters: [
              {
                field: "vehiclevin",
                operator: "eq",
                value: vin,
              },
              {
                field: "cancelled",
                operator: "neq",
                value: 1,
              },
            ],
            relations: ["client_note_latest", "client", "title_status"],
          },
          () => {
            setScannedBarcode(vin);
          }
        )
      );
    }

    dispatch(
      utilityActions.getUtility(
        "svetna_scanned_preimport",
        {
          filters: [
            {
              field: "vin",
              operator: "eq",
              value: vin,
            },
          ],
        },
        () => {
          setScannedBarcode(vin);
        }
      )
    );
  };

  return (
    <PageWithAppDrawer2 pageTitle={"Svetna Scan"}>
      <Paper className={classes.paper}>
        <Typography variant="h6" align="center">
          {client.clientname}
        </Typography>
        {hasSvetnaBuyerRole ? (
          <Typography variant="h6" align="center">
            Buyer Scanner
          </Typography>
        ) : (
          <Typography variant="h6" align="center">
            Admin Scanner
          </Typography>
        )}

        <Grid container spacing={2}>
          <Grid item xs={12}>
            {!openScanner ? (
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={() => {
                  setOpenScanner(true);
                  setStartScanner(true);
                }}
              >
                Scan VIN
              </Button>
            ) : (
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={() => {
                  setOpenScanner(false);
                  setStartScanner(false);
                }}
              >
                Close Scanner
              </Button>
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              value={last6 || ""}
              autoFocus
              placeholder="VIN / Last6"
              fullWidth
              onChange={(event) => setLast6(event.target.value.trim())}
              onKeyPress={(event) => {
                if (event.key === "Enter") {
                  setLoading(true);
                  handleLast6(last6, client.clientid);
                }
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Button
              variant="contained"
              color="primary"
              size="small"
              disabled={loading || last6.length < 6}
              startIcon={
                loading ? (
                  <CircularProgress size={24} color="secondary" />
                ) : null
              }
              onClick={() => {
                setLoading(true);
                handleLast6(last6, client.clientid);
              }}
            >
              {` Search by VIN / Last6`}
            </Button>
          </Grid>
        </Grid>

        <br />
        <br />
        {scannedBarcode && (
          <Typography align="center">
            Value Searched: {scannedBarcode}
          </Typography>
        )}
        {!openScanner &&
          scannedBarcode &&
          (vehicles.items.length > 0 || vehiclesPreimport.items.length > 0) && (
            <>
              <VehicleResultsSvetna clientFilter={client.clientid} />
              {!hasSvetnaBuyerRole && (
                <VehicleResults clientFilter={client.clientid} />
              )}
            </>
          )}
        {!openScanner &&
          scannedBarcode &&
          vehicles.items.length === 0 &&
          vehiclesPreimport.items.length === 0 && (
            <>
              <Typography align="center">
                No existing Svetna or DVS registrations
              </Typography>
              <VehicleNhtsaLookup vin={scannedBarcode} />
            </>
          )}
      </Paper>
      <Dialog
        fullScreen
        open={openScanner}
        onClose={() => setOpenScanner(false)}
      >
        <AppBar className={classes.appBar} position="static">
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => setOpenScanner(false)}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Scanner
            </Typography>
          </Toolbar>
        </AppBar>
        <div style={{ height: "100%", width: "100%", border: "2px solid red" }}>
          <ScannerComponentDynamsoft
            started={startScanner}
            onResult={handleBarcode}
            showButtons={false}
          />
        </div>
      </Dialog>
    </PageWithAppDrawer2>
  );
}

const useScannerPageStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
}));

function VehicleNhtsaLookup(props) {
  const dispatch = useDispatch();
  const webUser = useSelector((state) => state.authentication.webUser);
  const user = useSelector((state) => state.authentication.user);
  const clients_active = useSelector((state) => state.utility.clients_active);
  const columns = [
    {
      width: 1,
      title: "Year",
      field: "modelyear",
    },
    {
      width: 2,
      title: "Make",
      field: "make",
    },
    {
      width: 2,
      title: "Model",
      field: "model",
    },
    {
      width: 2,
      title: "Series",
      field: "series",
    },
    {
      width: 2,
      title: "KM or MI",
      dataRender: () => (
        <>
          <TextField
            value={mileage}
            placeholder="KM or MI"
            type="number"
            onChange={(event) => setMileage(event.target.value)}
          />
          <RadioGroup
            row
            aria-label="km or mi"
            name="odo_type"
            id="odo_type"
            value={odoType}
            onChange={(event) => setOdoType(event.target.value)}
          >
            <FormControlLabel value="km" control={<Radio />} label="KM" />
            <FormControlLabel value="mi" control={<Radio />} label="MI" />
          </RadioGroup>
        </>
      ),
    },

    {
      width: 2,
      dataRender: (item) => (
        <>
          <Button
            variant="contained"
            color="primary"
            disabled={Number(mileage) < 10}
            size="small"
            onClick={() => {
              setBackdropOpen(true);
              registerSvetnaVehicle(props.vin, mileage, item);
            }}
          >
            Svetna Register
          </Button>
        </>
      ),
    },
  ];

  const [nhtsaData, setNhtsaData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [mileage, setMileage] = useState("");
  const [odoType, setOdoType] = useState("km");
  const [backdropOpen, setBackdropOpen] = useState(false);

  useEffect(() => {
    if (clients_active.items.length === 0) {
      dispatch(
        utilityActions.getUtility("clients_active", {
          perPage: 1000,
          filters: [
            {
              field: "clientactive",
              operator: "eq",
              value: "2",
            },
          ],
          orders: [{ field: "clientname", direction: "asc" }],
        })
      );
    }
  }, []);

  const registerSvetnaVehicle = async (vin, mileage, item) => {
    if (mileage.trim() === "") {
      alert("mileage cannot be empty");
      setBackdropOpen(false);
      return;
    }
    if (Number(mileage) < 1) {
      alert("mileage cannot be empty");
      setBackdropOpen(false);
      return;
    }

    const data = {
      vin,
      vehicle_id: 0,
      seller_id: 0,
      odo_value: mileage,
      client_id: webUser.clientid,
      year: nhtsaData[0].modelyear,
      make: nhtsaData[0].make,
      model: nhtsaData[0].model,
      buyer_user_id: user.id || null,
      details: {
        vehicle_id: 0,
        client_id: webUser.clientid,
      },
      external_data: {
        vin,
        nhtsa_vpic: nhtsaData[0],
      },
    };

    await utilityService
      .addUtility("svetna_preimport_vehicles", data)
      .then((response) => {
        const { vehicleid } = response;
        return response;
      })
      .then((response) => {
        hashHistory.push(`/svetna/vehicles/${response.id}`);
      })
      .finally(() => {
        setBackdropOpen(false);
      });
  };

  useEffect(() => {
    if (props.vin.length >= 17) {
      setLoading(true);
      vehicleService.nhtsaVinLookup(props.vin).then((response) => {
        setLoading(false);
        setNhtsaData(response);
      });
    }
  }, [props.vin]);

  return (
    <>
      <Backdrop open={backdropOpen} style={{ zIndex: 1000 }}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {loading ? (
        <CircularProgress />
      ) : (
        <Typography align="center">NHTSA Lookup</Typography>
      )}

      {nhtsaData.length > 0 &&
      ["0", "3", "14", "3,14", "0,14"].includes(nhtsaData[0].errorcode) ? (
        <ResponsiveGrid
          loading={loading}
          columns={columns}
          data={nhtsaData}
          usePagination={false}
        />
      ) : (
        <Typography align="center">No NHTSA results</Typography>
      )}
    </>
  );
}

function VehicleResultsSvetna(props) {
  const user = useSelector((state) => state.authentication.webUser);
  const vehiclesPreimport = useSelector(
    (state) => state.utility.svetna_scanned_preimport
  );
  const [clientFilter, setClientFilter] = useState(null);

  const columns = [
    {
      width: 2,
      title: "VIN",
      field: "vin",
    },
    {
      width: 3,
      title: "Year/Make/Model",
      field: "year_make_model",
    },
    {
      width: 2,
      title: "Load",
      field: "",
      dataRender: (item) =>
        item.vehicle?.client_load ? item.vehicle.client_load.name : "-----",
    },
    {
      width: 2,
      title: "Images",
      field: "",
      dataRender: (item) => "TBD",
    },
    {
      width: 3,
      dataRender: (item) => (
        <Grid container spacing={1}>
          <Button
            variant="contained"
            color="primary"
            size="small"
            component={RouterLink}
            to={`/svetna/vehicles/${item.id}`}
          >
            Declarations
          </Button>
        </Grid>
      ),
    },
  ];

  useEffect(() => {
    setClientFilter(props.clientFilter);
  }, [props.clientFilter]);

  return (
    <>
      <Typography align="center">Svetna Vehicles</Typography>
      <ResponsiveGrid
        columns={columns}
        data={vehiclesPreimport.items}
        usePagination={false}
      />
    </>
  );
}

function VehicleResults(props) {
  const user = useSelector((state) => state.authentication.webUser);
  const vehicles = useSelector(
    (state) => state.utility.svetna_scanned_vehicles
  );
  const [clientFilter, setClientFilter] = useState(null);

  const columns = [
    {
      width: 2,
      title: "VIN",
      field: "vehiclevin",
      dataRender: (item) => {
        const isAdmin = user.roleid === 2;
        return (
          <>
            {item.vehiclevin}
            <br />
            {item.title_status?.statusname}
            {isAdmin && item.client && (
              <>
                <br />
                {item.client.clientname}
              </>
            )}
          </>
        );
      },
    },
    {
      width: 3,
      title: "Year/Make/Model",
      field: "yr",
      dataRender: (item) => {
        return `${item.yr} ${item.make} ${item.model}`;
      },
    },
    {
      width: 2,
      title: "Load",
      field: "client_load.name",
    },
    {
      width: 2,
      title: "Images",
      field: "vehicle_photos",
      dataRender: (item) => {
        return item.vehicle_photos?.length;
      },
    },
    {
      width: 3,
      dataRender: (item) => {
        const isAdmin = user.roleid === 2;
        const valid =
          clientFilter !== null &&
          Number(item.clientid) === Number(clientFilter);
        if (!valid && !isAdmin)
          return (
            <Typography variant="body2" color="secondary">
              Unable to register
            </Typography>
          );

        return (
          <>
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={() => hashHistory.push(`/photoupload/${item.vehicleid}`)}
            >
              Edit
            </Button>{" "}
            {item?.client?.defaults?.client?.lotvision_domain && (
              <>
                <Button
                  color="primary"
                  variant="contained"
                  size="small"
                  href={`https://${item?.client?.defaults?.client?.lotvision_domain?.value}/vehicles?q=${item.vehiclevin}`}
                  target="_blank"
                >
                  Lot Vision
                </Button>{" "}
              </>
            )}
            {isAdmin && (
              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={() =>
                  hashHistory.push(
                    `/management/nhtsaConformityGenerate/${item.vehiclevin}`
                  )
                }
              >
                Conformity Check
              </Button>
            )}
            {item?.client_note_latest && (
              <>
                <br />
                <Typography style={{ fontWeight: "bold" }}>
                  Latest Note:
                </Typography>{" "}
                {item.client_note_latest.note}
              </>
            )}
          </>
        );
      },
    },
  ];

  useEffect(() => {
    setClientFilter(props.clientFilter);
  }, [props.clientFilter]);

  return (
    <>
      <Typography align="center">DVS Vehicles</Typography>
      <ResponsiveGrid
        columns={columns}
        data={vehicles.items}
        usePagination={false}
      />
    </>
  );
}

export { SvetnaScannerPage as default };
