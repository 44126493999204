import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Font,
  Image,
} from "@react-pdf/renderer";

import logo from "../../_assets/dvslogo_web_new_cropped.png";

import moment from "moment";

const debug = false;

// https://medium.com/investing-in-tech/creating-api-driven-pdfs-on-the-fly-in-the-browser-using-react-react-pdf-and-typescript-78ab4d1e7da5
// https://react-pdf.org/advanced#on-the-fly-rendering

Font.register({
  family: "Oswald",
  src: "https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf",
});

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
    fontFamily: "Helvetica",
  },
  bold: {
    fontFamily: "Helvetica-Bold",
  },
  title: {
    fontSize: 24,
    textAlign: "center",
    // fontFamily: "Oswald",
  },
  author: {
    fontSize: 12,
    textAlign: "center",
    // marginBottom: 40,
  },
  subtitle: {
    fontSize: 18,
    margin: 12,
    // fontFamily: "Oswald",
  },
  text: {
    margin: 12,
    fontSize: 12,
    textAlign: "left",
    // fontFamily: "Times-Roman",
  },
  image: {
    marginVertical: 15,
    marginHorizontal: 100,
  },
  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: "center",
    color: "grey",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
  viewCustomerInfo: {
    border: "1px solid black",
  },
  hr: {
    borderStyle: "solid",
    borderTopColor: "black",
    borderTopWidth: 1,
  },
});

export function TitlesSentSheet(props) {
  return (
    <Document>
      <TitlesSentPage>
        <TitlesSentHeader meta={props.pageMeta} />
        <TitlesSentVehicles items={props.items} />
        <FooterPageNumber />
      </TitlesSentPage>
    </Document>
  );
}

function TitlesSentPage(props) {
  return <Page style={styles.body}>{props.children}</Page>;
}

function TitlesSentHeader(props) {
  return (
    <View fixed>
      <View style={{ alignItems: "center" }} debug={debug}>
        <Image
          style={[styles.image, { width: "20%" }]}
          src={logo}
          debug={debug}
        />
      </View>
      <View style={{ flexDirection: "row" }} debug={debug}>
        <View style={{ width: "50%" }} debug={debug}>
          <Text style={styles.author} debug={debug}>
            1919b S Post Rd
          </Text>
          <Text style={styles.author} debug={debug}>
            Indianapolis, IN 46239
          </Text>
        </View>
        <View style={{ width: "50%" }} debug={debug}>
          <Text style={styles.author} debug={debug}>
            Tel: (317)862-7626
          </Text>
          <Text style={styles.author} debug={debug}>
            Fax: (317)862-7680
          </Text>
        </View>
      </View>

      <Line />

      <View style={{ width: "100%" }} debug={debug}>
        {props.meta && props.meta.client && (
          <Text style={styles.title}>{props.meta.client}</Text>
        )}
        {props.meta && props.meta.date && (
          <Text style={styles.author}>
            Titles Sent on {moment(props.meta.date).format("MM/DD/YYYY")}
          </Text>
        )}
      </View>

      <Line />
    </View>
  );
}

function TitlesSentVehicles(props) {
  return (
    <View style={styles.text} debug={debug}>
      <View style={[styles.bold, { flexDirection: "row" }]}>
        <View style={{ width: "30%" }}>
          <Text>Overnight Tracking #</Text>
        </View>
        <View style={{ width: "30%" }}>
          <Text>Auction</Text>
        </View>
        <View style={{ width: "40%" }}>
          <Text>VIN</Text>
        </View>
      </View>
      {props.items.map((item, index) => (
        <View style={{ flexDirection: "row" }} key={index}>
          <View style={{ width: "30%" }}>
            <Text>{item.overnight_tracking_number}</Text>
          </View>
          <View style={{ width: "30%" }}>
            <Text>{item.auction_name}</Text>
          </View>
          <View style={{ width: "40%" }}>
            <Text>{item.vin}</Text>
          </View>
        </View>
      ))}
    </View>
  );
}

function FooterPageNumber() {
  return (
    <Text
      style={styles.pageNumber}
      render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
      fixed
    />
  );
}

function Line() {
  return <View style={[styles.hr, { marginTop: 10, marginBottom: 10 }]} />;
}
