import { authHeader } from "../_helpers";
import {
  buildRelations,
  // buildFilters,
  handleResponse,
  getEnvironmentURL,
} from "../_helpers";

export const paperworkService = {
  addPaperwork,
  getPaperwork,
  updatePaperwork,
  addVehicleToPaperwork,
  removeVehicleFromPaperwork,
};

function addPaperwork(data = {}) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };

  const url = getEnvironmentURL();
  return fetch(`${url}/api/paperwork_batches?`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    });
}

function getPaperwork(id, relations = []) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  console.log("relations", relations);

  const relationStrings = buildRelations(relations);
  const relationString =
    relationStrings != null ? relationStrings.join("&") : null;

  const url = getEnvironmentURL();
  return fetch(
    `${url}/api/paperwork_batches/${id}?${relationString}`,
    requestOptions
  )
    .then(handleResponse)
    .then((response) => {
      return response;
    });
}

function updatePaperwork(id, data = {}) {
  const requestOptions = {
    method: "PUT",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };

  const url = getEnvironmentURL();
  return fetch(
    `${url}/api/paperwork_batches/${id}?with[]=vehicles_paperwork`,
    requestOptions
  )
    .then(handleResponse)
    .then((response) => {
      return response;
    });
}

function addVehicleToPaperwork(id, vehicleid) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };

  const url = getEnvironmentURL();
  return fetch(
    `${url}/api/paperwork_batches/${id}/add/${vehicleid}`,
    requestOptions
  )
    .then(handleResponse)
    .then((response) => {
      return response;
    });
}

function removeVehicleFromPaperwork(id, vehicleid) {
  const requestOptions = {
    method: "DELETE",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };

  const url = getEnvironmentURL();
  return fetch(
    `${url}/api/paperwork_batches/${id}/remove/${vehicleid}`,
    requestOptions
  )
    .then(handleResponse)
    .then((response) => {
      return response;
    });
}
