import React, { useState, useEffect } from "react";
import { getEnvironmentURL } from "../../_helpers";
import UserSignatureCanvas from "../user/UserSignatureCanvas";

import { CircularProgress, Typography } from "@material-ui/core";
import { utilityService } from "../../_services";

export default function SignatureComponent(props) {
  const [saving, setSaving] = useState(false);
  // const [preview, setPreview] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {}, []);

  const save = (data) => {
    setSaving(true);
    setError(null);
    // setPreview(null);
    console.log('saveUrl',props.saveUrl);
    utilityService
      .getEndpoint(props.saveUrl, "POST", {
        body: {
          photo: data,
        },
      })
      .catch((e) => {
        setError(e);
      })
      .finally(() => {
        setSaving(false);
        alert('Signature Saved');
      });
  };

  return (
    <>
      <Typography variant="h4">Best updated using a phone</Typography>
      <UserSignatureCanvas
        widthRatio={3}
        onSave={(data) => {
          save(data.dataURL);
        }}
        onPreview={(data) => {
          // setPreview(data.dataURL);
        }}
      />
      <br/>
      {saving && (
        <CircularProgress />
      )}
      {error && (<><Typography style={{color: "red"}}>Unable to save signature</Typography>{error}</>)}
    </>
  );
}
