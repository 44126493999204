import { authHeader, getEnvironmentURL } from "../_helpers";
import {
  buildRelations,
  buildFilters,
  buildOrders,
  handleResponse,
} from "../_helpers";

export const bayService = {
  login,
  companies,
  shipments,
  shipment,
  call,
  getUtility,
  getOneUtility,
  addUtility,
  deleteOneUtility,
  updateUtility,
  getEndpoint,
};

function login(username, password) {
  const payload = { username, password };
  return call("/login", "POST", payload);
}

function companies(userId, token) {
  const headers = {
    "x-api-user-id": userId,
    "x-api-user-token": token,
  };
  return call("/companies", "GET", null, null, headers);
}

function shipments(userId, token, companyId) {
  const headers = {
    "x-api-user-id": userId,
    "x-api-user-token": token,
  };
  return call(
    "/company/" + companyId + "/shipments",
    "GET",
    null,
    null,
    headers
  );
}

function shipment(userId, token, companyId, shipmentId) {
  const headers = {
    "x-api-user-id": userId,
    "x-api-user-token": token,
  };
  return call(
    "/company/" + companyId + "/shipment/" + shipmentId,
    "GET",
    null,
    null,
    headers
  );
}

function call(path, method, payload, params, headers, callback) {
  const url = "https://tiers.baybrokerageus.com/api";
  const endpoint = url + path;

  const requestOptions = {
    method: method || "GET",
    headers: headers || {},
    body: payload ? JSON.stringify(payload) : null,
  };

  return fetch(`${endpoint}${params ? "?" + params : ""}`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    });
}

function getUtility(utilityName, options) {
  let filters = options.filters || [];
  let relations = options.relations || [];
  let orders = options.orders || [];
  let perPage = options.perPage || 10;
  let page = options.page || 1;
  const params = options.params;

  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  const filterStrings = buildFilters(filters);
  const filterString = Array.isArray(filterStrings)
    ? filterStrings.join("&")
    : "null";
  const relationStrings = buildRelations(relations);
  const relationString = Array.isArray(relationStrings)
    ? relationStrings.join("&")
    : "null";
  const orderStrings = buildOrders(orders);
  const orderString = Array.isArray(orderStrings)
    ? orderStrings.join("&")
    : "null";

  const url = getEnvironmentURL();
  return fetch(
    `${url}/api/${utilityName}?per_page=${perPage}&page=${page}&${filterString}&${relationString}&${orderString}&${params}`,
    requestOptions
  )
    .then(handleResponse)
    .then((response) => {
      if (response.data) return response;
      return [];
    });
}

function getOneUtility(utilityName, id, options) {
  let filters = (options && options.filters) || [];
  let relations = (options && options.relations) || [];

  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  const filterStrings = buildFilters(filters);
  const filterString = Array.isArray(filterStrings)
    ? filterStrings.join("&")
    : "null";
  const relationStrings = buildRelations(relations);
  const relationString = Array.isArray(relationStrings)
    ? relationStrings.join("&")
    : "null";

  const url = getEnvironmentURL();
  return fetch(
    `${url}/api/${utilityName}/${id}?${filterString}&${relationString}`,
    requestOptions
  )
    .then(handleResponse)
    .then((response) => {
      return response;
    });
}

function addUtility(utilityName, data) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };

  const url = getEnvironmentURL();
  return fetch(`${url}/api/${utilityName}`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    });
}

function deleteOneUtility(utilityName, id, options) {
  const requestOptions = {
    method: "DELETE",
    headers: authHeader(),
  };

  const url = getEnvironmentURL();
  return fetch(`${url}/api/${utilityName}/${id}`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      // console.log("delete response", response.status);
      return response;
    });
}

function updateUtility(utilityName, id, item) {
  const requestOptions = {
    method: "PUT",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(item),
  };

  const url = getEnvironmentURL();
  return fetch(`${url}/api/${utilityName}/${id}`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    });
}

function getEndpoint(path, method, options = {}) {
  let relations = (options && options.relations) || [];
  let params = (options && options.params) || "";

  const requestOptions = {
    method,
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };

  const relationStrings = buildRelations(relations);
  const relationString = Array.isArray(relationStrings)
    ? relationStrings.join("&")
    : "null";

  const url = getEnvironmentURL();
  return fetch(`${url}/${path}?${relationString}&${params}`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    });
}
