import React, { useEffect, useState } from "react";
// import Link from "@material-ui/core/Link";
import { connect, useDispatch, useSelector } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import moment from "moment";

import { Link as RouterLink } from "react-router-dom";

import { utilityService } from "../_services";
import { utilityActions } from "../_actions";

import { PageWithAppDrawer2 } from "../_components/main/PageWithAppDrawer2";
import Paper from "@material-ui/core/Paper";
import {
  Button,
  CircularProgress,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core";
import LaunchTwoToneIcon from "@material-ui/icons/LaunchTwoTone";
import ClientNotes from "../_components/client/ClientNotes";
import ModalGeneric from "../_components/generic/ModalGeneric";

class CustomerStatsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { classes } = this.props;
    return (
      <PageWithAppDrawer2 pageTitle={"Customer Statistics"}>
        <Paper className={classes.paper}>
          <CustomerStats />
        </Paper>
      </PageWithAppDrawer2>
    );
  }
}

function CustomerStats(props) {
  // const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);

  const date = new Date();
  const [year, setYear] = useState(moment(date).format("Y"));

  const [yearData, setYearData] = useState([]);

  // const reportUrl = `${getEnvironmentURL()}/api/reports/management/stats`;

  useEffect(() => {
    getDataYear();
  }, []);

  const refreshYearData = () => {
    setLoading(true);
    getDataYear();
  };

  const getDataYear = () => {
    utilityService
      .getEndpoint(`/api/reports/management/stats/${year}`, "GET")
      .then((response) => {
        setYearData(response);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <TextField
          name="year"
          label="Stats Year"
          value={year}
          type="number"
          onChange={(e) => setYear(e.target.value)}
        />
      </Grid>
      <Grid item xs={12}>
        <Button
          variant="contained"
          size="small"
          color="primary"
          onClick={refreshYearData}
        >
          Get Yearly Data
        </Button>
      </Grid>

      <Grid item xs={12}>
        {loading ? (
          <CircularProgress />
        ) : (
          <StatsForYear year={year} data={yearData} />
        )}
      </Grid>
    </Grid>
  );
}

function StatsForYear(props) {
  const dispatch = useDispatch();
  const [displayYear, setDisplayYear] = useState(true);
  const [displayClient, setDisplayClient] = useState(false);
  const [loading, setLoading] = useState(false);
  const [clientId, setClientId] = useState(null);
  const [clientName, setClientName] = useState(null);
  const [notesOpen, setNotesOpen] = useState(false);
  const client = useSelector((state) => state.utility.clients_item);
  const [clientData, setClientData] = useState([]);
  const [minVolume, setMinVolume] = useState("");
  const [minVolumePrev, setMinVolumePrev] = useState("");

  useEffect(() => {
    if (clientId !== null) {
      setDisplayYear(false);
      setLoading(true);
      setDisplayClient(true);
      getClientData();
    }
  }, [clientId]);

  const getClientData = () => {
    dispatch(
      utilityActions.getOneUtility("clients", clientId, {
        relations: ["notes"],
      })
    );
    utilityService
      .getEndpoint(
        `/api/reports/management/stats/${props.year}/${clientId}`,
        "GET"
      )
      .then((response) => {
        setClientData(response);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getColor = (perc, prev_count) => {
    if (!prev_count || Number(prev_count) === 0) return "white";
    if (Math.abs(perc) <= 10) return "white";
    if (perc < 0) return "#ff8080";
    if (perc > 0) return "#80ff80";
  };

  return (
    <div>
      <div>
        <Typography variant="h5">
          {props.year} Statistics{" "}
          <Button
            size="small"
            variant="contained"
            color="primary"
            onClick={() => {
              setDisplayYear(true);
              setDisplayClient(false);
              setClientId(null);
              setClientName(null);
            }}
          >
            All Customers
          </Button>{" "}
          <TextField
            value={minVolume}
            onChange={(e) => setMinVolume(e.target.value)}
            placeholder="Minimum Curr Volume"
          />{" "}
          <TextField
            value={minVolumePrev}
            onChange={(e) => setMinVolumePrev(e.target.value)}
            placeholder="Minimum Prev Volume"
          />
        </Typography>
        {displayYear && (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Client</TableCell>
                <TableCell>Total / prev</TableCell>
                <TableCell>RI / prev</TableCell>
                <TableCell>Titles / prev</TableCell>
                <TableCell>YoY</TableCell>
                <TableCell>YoY RI</TableCell>
                <TableCell>YoY Titles</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.data.map((row, index) => {
                if (
                  Number(minVolume) > 0 &&
                  Number(row.count) < Number(minVolume)
                )
                  return null;
                if (
                  Number(minVolumePrev) > 0 &&
                  Number(row.count_prev) < Number(minVolumePrev)
                )
                  return null;

                return (
                  <TableRow key={index}>
                    <TableCell>
                      <Button
                        onClick={() => {
                          setClientId(row.clientid);
                          setClientName(row.clientname);
                        }}
                      >
                        {row.clientname}
                      </Button>
                    </TableCell>
                    <TableCell>
                      {row.count} / {row.count_prev || "---"}
                    </TableCell>
                    <TableCell>
                      {row.count_ri} / {row.count_ri_prev || "---"}
                    </TableCell>
                    <TableCell>
                      {row.count_titles} / {row.count_titles_prev || "---"}
                    </TableCell>
                    <TableCell
                      style={{
                        backgroundColor: getColor(
                          row.count_yoy,
                          row.count_prev
                        ),
                      }}
                    >
                      {row.count_prev > 0 ? `${row.count_yoy}%` : "---"}
                    </TableCell>
                    <TableCell
                      style={{
                        backgroundColor: getColor(
                          row.count_ri_yoy,
                          row.count_ri_prev
                        ),
                      }}
                    >
                      {row.count_ri_prev > 0 ? `${row.count_ri_yoy}%` : "---"}
                    </TableCell>
                    <TableCell
                      style={{
                        backgroundColor: getColor(
                          row.count_titles_yoy,
                          row.count_titles_prev
                        ),
                      }}
                    >
                      {row.count_titles_prev > 0
                        ? `${row.count_titles_yoy}%`
                        : "---"}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        )}
      </div>
      <div>
        {loading && <CircularProgress />}
        {!loading && displayClient && (
          <div>
            <Typography variant="h5">
              {props.year} Statistics by Month for {clientName}
            </Typography>
            <div>
              {client && client.loading && <CircularProgress />}
              {client && !client.loading && (
                <Grid container>
                  <Grid item xs={12}>
                    <RouterLink
                      to={`/admin/clients/${client.clientid}`}
                      target="_blank"
                      component={Button}
                      color="primary"
                    >
                      {client.clientname}
                      <LaunchTwoToneIcon
                        style={{ fontSize: 12 }}
                        color="primary"
                      />
                    </RouterLink>
                  </Grid>
                  <Grid item xs={12}>
                    <b>Address:</b> {client.address_full}
                  </Grid>
                  <Grid item xs={12}>
                    <b>Phone:</b> {client.clientphonenumber}
                  </Grid>
                  <Grid item xs={12}>
                    <b>Email:</b> {client.clientemailaddress}
                  </Grid>
                  <Grid item xs={12}>
                    <b>Contact:</b> {client.clientcontactname}
                  </Grid>
                  <Grid item xs={12}>
                    <b>Latest Note:</b>{" "}
                    {client.notes.length > 0 ? (
                      <>
                        <b>
                          {moment(client.notes[0].contactdate).format("M/D/Y")}
                        </b>
                        {" - "}"{client.notes[0].notes}"
                      </>
                    ) : (
                      ""
                    )}
                    <br />
                    <Button
                      size="small"
                      color="primary"
                      onClick={() => setNotesOpen(true)}
                    >
                      All Notes
                    </Button>
                  </Grid>
                </Grid>
              )}
            </div>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Month</TableCell>
                  <TableCell>Total / prev</TableCell>
                  <TableCell>RI / prev</TableCell>
                  <TableCell>Titles / prev</TableCell>
                  <TableCell>YoY</TableCell>
                  <TableCell>YoY RI</TableCell>
                  <TableCell>YoY Titles</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {clientData.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell>{row.month}</TableCell>
                    <TableCell>
                      {row.count} / {row.count_prev || "---"}
                    </TableCell>
                    <TableCell>
                      {row.count_ri} / {row.count_ri_prev || "---"}
                    </TableCell>
                    <TableCell>
                      {row.count_titles} / {row.count_titles_prev || "---"}
                    </TableCell>
                    <TableCell
                      style={{
                        backgroundColor: getColor(
                          row.count_yoy,
                          row.count_prev
                        ),
                      }}
                    >
                      {row.count_prev > 0 ? `${row.count_yoy}%` : "---"}
                    </TableCell>
                    <TableCell
                      style={{
                        backgroundColor: getColor(
                          row.count_ri_yoy,
                          row.count_ri_prev
                        ),
                      }}
                    >
                      {row.count_ri_prev > 0 ? `${row.count_ri_yoy}%` : "---"}
                    </TableCell>
                    <TableCell
                      style={{
                        backgroundColor: getColor(
                          row.count_titles_yoy,
                          row.count_titles_prev
                        ),
                      }}
                    >
                      {row.count_titles_prev > 0
                        ? `${row.count_titles_yoy}%`
                        : "---"}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        )}
      </div>
      <ModalGeneric
        open={notesOpen}
        onClose={() => {
          setNotesOpen(false);
          getClientData();
        }}
        closeText="Close"
        title="Customer Notes"
      >
        <ClientNotes clientId={clientId} />
      </ModalGeneric>
    </div>
  );
}

const styles = (theme) => ({
  root: {
    display: "flex",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
});

function mapStateToProps(state) {
  const { authentication } = state;
  const { user } = authentication;
  return {
    user,
  };
}

const connectedCustomerStatsPage = connect(mapStateToProps)(
  withStyles(styles)(CustomerStatsPage)
);
export { connectedCustomerStatsPage as CustomerStatsPage };
