import React, { Component, useRef, useState } from "react";
// import { Link as RouterLink } from "react-router-dom";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";

import { getEnvironmentURL } from "../_helpers";
import { utilityService } from "../_services";

import { PageWithAppDrawer2 } from "../_components/main/PageWithAppDrawer2";
import ScannerComponentDynamsoft from "../_components/generic/ScannerComponentDynamsoft";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import CloseIcon from "@material-ui/icons/Close";

import {
  Typography,
  Button,
  CircularProgress,
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import ResponsiveGrid from "../_components/generic/ResponsiveGrid";
import { makeStyles } from "@material-ui/styles";
import { useParams } from "react-router-dom";

class ExitPhotoPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      openScanner: false,
      startScanner: false,
      scannedBarcode: null,
      last6: "",
      loading: false,
      lookupData: null,
    };
  }

  componentDidMount() {}

  render() {
    const { classes } = this.props;
    const {
      openScanner,
      startScanner,
      scannedBarcode,
      last6,
      loading,
      lookupData,
    } = this.state;

    console.log({ lookupData });

    return (
      <PageWithAppDrawer2 pageTitle={"Exit Photo"}>
        <Paper className={classes.paper}>
          <Typography variant="h6" align="center">
            Exit Photo
          </Typography>
          {!openScanner ? (
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={() =>
                this.setState({ openScanner: true }, () =>
                  this.setState({ startScanner: true })
                )
              }
            >
              Scan VIN
            </Button>
          ) : (
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={() =>
                this.setState({ startScanner: false }, () =>
                  this.setState({ openScanner: false })
                )
              }
            >
              Close Scanner
            </Button>
          )}

          <br />

          <Grid container>
            <Grid item xs={12} sm={6}>
              <TextField
                value={last6 || ""}
                autoFocus
                placeholder="VIN"
                fullWidth
                onChange={(event) =>
                  this.setState({ last6: event.target.value.trim() })
                }
                onKeyPress={(event) => {
                  if (event.key === "Enter") {
                    this.setState({ loading: true });
                    this.handleLast6(last6);
                  }
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button
                variant="contained"
                color="primary"
                size="small"
                disabled={loading || last6.length < 6}
                startIcon={
                  loading ? (
                    <CircularProgress size={24} color="secondary" />
                  ) : null
                }
                onClick={() => {
                  this.setState({ loading: true });
                  this.handleLast6(last6);
                }}
              >
                {` Search by VIN / Last6`}
              </Button>
            </Grid>
          </Grid>

          <br />
          <br />
          {scannedBarcode && (
            <Typography align="center">
              Value Searched: {scannedBarcode}
            </Typography>
          )}
          {!openScanner && scannedBarcode && lookupData && (
            <VehicleResults lookupData={lookupData} vin={scannedBarcode} />
          )}
          {!openScanner && scannedBarcode && !lookupData && (
            <>
              <Typography align="center">Invalid VIN</Typography>
            </>
          )}
        </Paper>
        <Dialog
          fullScreen
          open={openScanner}
          onClose={() => this.setState({ openScanner: false })}
        >
          <AppBar className={classes.appBar} position="static">
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={() => this.setState({ openScanner: false })}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" className={classes.title}>
                Scanner
              </Typography>
            </Toolbar>
          </AppBar>
          <div
            style={{ height: "100%", width: "100%", border: "2px solid red" }}
          >
            <ScannerComponentDynamsoft
              started={startScanner}
              onResult={this.handleBarcode}
              showButtons={false}
            />
          </div>
        </Dialog>
      </PageWithAppDrawer2>
    );
  }

  handleLast6 = async (vin) => {
    this.setState({ lookupData: null });
    if (vin.length < 17) {
      alert("Provde at least 17 numbers/letters");
      this.setState({ loading: false });
      return;
    }

    utilityService
      .getEndpoint(`api/exitPhoto/${vin}`, "GET", {
        skipAuth: true,
      })
      .then((response) => {
        if (response?.data?.usedvehicles?.usedvehicles) {
          const usedVehicles = response.data.usedvehicles.usedvehicles;
          if (usedVehicles[0]) {
            this.setState({ scannedBarcode: vin, lookupData: usedVehicles[0] });
          } else {
            // console.log("no data");
          }
        }
      })
      .catch((e) => {
        console.warn({ e });
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  };

  handleBarcode = async (result, barcode) => {

    this.setState({ startScanner: false }, () => {
      this.setState({ openScanner: false });
    });

    let vin = null;
    if (barcode.includes(",")) {
      // this is likely a GM QR Code, or something with extra data, but VIN is usually first
      const parts = barcode.split(",");
      vin = parts[0];
    } else {
      vin = barcode;
    }

    // clean the vin for any import flags
    vin = vin.replace(/[iI]/g, "");

    this.setState({ scannedBarcode: vin });
    this.handleLast6(vin);
  };
}

function VehicleResults(props) {
  const vehicle = props.lookupData;
  const vin = props.vin;

  const columns = [
    {
      width: 3,
      title: "VIN",
      field: "vin",
    },
    {
      width: 1,
      title: "Year",
      field: "model_year",
    },
    {
      width: 2,
      title: "Make",
      field: "make",
    },
    {
      width: 2,
      title: "Model",
      field: "model",
    },
    {
      width: 4,
      title: "Add Exit Photo",
      dataRender: () => <VehicleExitPhoto vin={vin} />,
    },
  ];

  return (
    <>
      <Typography align="center">Vehicle Decode Data</Typography>
      <ResponsiveGrid
        columns={columns}
        data={[vehicle]}
        usePagination={false}
      />
    </>
  );
}

function VehicleExitPhoto(props) {
  const params = useParams();
  const { riLocation } = params;

  const [loading, setLoading] = useState(false);
  const inputElement = useRef(null);
  const [cloudUrlThumb, setCloudUrlThumb] = useState(null);

  const imageAdded = (event, imageTypeId) => {
    event.preventDefault();

    let reader = new FileReader();
    let file = event.target.files[0];
    event.target.files = null;

    reader.onloadend = () => {
      uploadImage(file);
    };

    try {
      reader.readAsDataURL(file);
    } catch (e) {}
  };

  const uploadImage = (file) => {
    let formData = new FormData();
    formData.append("photo", file);
    formData.append("vin", props.vin);
    formData.append("note", riLocation);
    formData.append("type", "foo");

    setLoading(true);

    fetch(`${getEnvironmentURL()}/api/exitPhoto/${props.vin}`, {
      method: "POST",
      body: formData,
    })
      .then(
        (response) => response.json() // if the response is a JSON object
      )
      .then(
        (success) => {
          console.log(success); // Handle the success response object
          setCloudUrlThumb(success.cloud_url_thumb);
        }
      )
      .finally(() => {
        setLoading(false);
      })
      .catch((error) => {
        // console.log(error); // Handle the error response object
        // setPreviewUrl("");
      });
  };

  return (
    <>
      {loading && <CircularProgress />}
      {!loading && (
        <>
          {!cloudUrlThumb && (
            <>
              <label htmlFor={`image_upload`}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    inputElement.current.click();
                  }}
                >
                  Take Exit Photo
                </Button>
              </label>
              <input
                id={`image_upload`}
                ref={inputElement}
                type="file"
                name={`files[exitPhoto]`}
                accept="image/*"
                capture="camera"
                style={{ display: "none" }}
                onChange={(event) => imageAdded(event, "exitPhoto")}
              />
            </>
          )}
          {cloudUrlThumb && <img src={cloudUrlThumb} alt="cloud"></img>}
        </>
      )}
    </>
  );
}

const styles = (theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
});

function mapStateToProps(state) {
  const { users, authentication, utility } = state;
  const { user, webUser } = authentication;
  return {
    user,
    webUser,
    users,
    utility,
  };
}

const connectedExitPhotoPage = connect(mapStateToProps)(
  withStyles(styles)(ExitPhotoPage)
);
export { connectedExitPhotoPage as ExitPhotoPage };
