import React from "react";
import { Link as RouterLink } from "react-router-dom";
// import Link from "@material-ui/core/Link";
import { connect, useSelector, useDispatch } from "react-redux";

import { withStyles } from "@material-ui/core/styles";
import withWidth from "@material-ui/core/withWidth";

import { PageWithAppDrawer2 } from "../_components/main/PageWithAppDrawer2";
import { utilityActions } from "../_actions";
import { Paper, Grid, Select, Button, Typography } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";

class AuctionDetailsPage extends React.Component {
  constructor(props) {
    super(props);
    const { id: auctionId } = this.props.match ? this.props.match.params : {};
    this.state = {
      auctionId,
      reload: false,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { id: auctionId } = nextProps.match ? nextProps.match.params : {};
    if (auctionId !== prevState.auctionId) {
      return {
        ...prevState,
        auctionId,
        reload: true,
      };
    }
    return false;
  }

  async componentDidMount() {
    const { items: clients } = this.props.utility.clients_active;

    if (clients.length === 0) {
      await this.props.dispatch(
        utilityActions.getUtility("clients_active", {
          perPage: 1000,
          filters: [
            {
              field: "clientactive",
              operator: "eq",
              value: "2",
            },
          ],
          orders: [{ field: "clientname", direction: "asc" }],
        })
      );
    }
  }

  render() {
    const { auctionId, reload } = this.state;
    const { classes } = this.props;

    if (reload === true) {
      this.setState({ reload: false }, () => {
        this.componentDidMount();
      });
      return <></>;
    }

    return (
      <PageWithAppDrawer2 pageTitle={`Auction #${auctionId}`}>
        <RouterLink to="/admin/auctions">All Auctions</RouterLink>
        <Paper className={classes.paper}>
          <AuctionDetails itemId={auctionId} />
        </Paper>
      </PageWithAppDrawer2>
    );
  }
}

function AuctionDetails(props) {
  const dispatch = useDispatch();
  const auction = useSelector((state) => state.utility.auctions_item);
  const states = useSelector((state) => state.utility.us_states);
  const fields = [
    {
      width: 2,
      title: "Primary Details",
      field: null,
      type: "header",
    },
    {
      width: 2,
      title: "Name",
      field: "auctionname",
      type: "string",
    },
    {
      width: 2,
      title: "Phone",
      field: "auctionphonenumber",
      type: "string",
    },
    {
      width: 2,
      title: "Contact",
      field: "auctioncontactname",
      type: "string",
    },
    {
      width: 2,
      title: "Auction Address",
      field: null,
      type: "header",
    },
    {
      width: 2,
      title: "Address",
      field: "auctionstreetaddress",
      type: "string",
    },
    {
      width: 2,
      title: "City",
      field: "auctioncity",
      type: "string",
    },
    {
      width: 1,
      title: "State",
      field: "auctionstateid",
      type: "state",
    },
    {
      width: 2,
      title: "Zip Code",
      field: "auctionzip",
      type: "string",
    },
    {
      width: 1,
      title: "Country",
      field: "country",
      type: "country",
    },
    {
      width: 2,
      title: "Mailing Address",
      field: null,
      type: "header",
    },
    {
      width: 2,
      title: "Address",
      field: "mailingaddress",
      type: "string",
    },
    {
      width: 2,
      title: "City",
      field: "mailingcity",
      type: "string",
    },
    {
      width: 1,
      title: "State",
      field: "mailingstateid",
      type: "state",
    },
    {
      width: 2,
      title: "Zip Code",
      field: "mailingzip",
      type: "string",
    },
  ];

  const id = props.itemId;

  React.useEffect(() => {
    if (states.items.length === 0) {
      dispatch(
        utilityActions.getUtility("us_states", {
          perPage: 1000,
          orders: [
            {
              field: "stateabbreviation",
              direction: "asc",
            },
          ],
        })
      );
    }
  }, []);

  React.useEffect(() => {
    if (id) {
      if (!auction) {
        getNewData();
      } else if (Number(auction.id) !== Number(id)) {
        getNewData();
      }
    }
  }, [id]);

  const getNewData = () => {
    dispatch(utilityActions.getOneUtility("auctions", id, []));
  };

  const updateLocal = (event) => {
    const { name, value } = event.target;
    dispatch(
      utilityActions.updateOneUtilityLocal("auctions", { [name]: value })
    );
  };

  const handleSave = () => {
    dispatch(
      utilityActions.updateUtility("auctions", auction.id, auction, () => {
        getNewData();
      })
    );
  };

  return (
    <>
      {auction && auction.id ? (
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Button variant="contained" color="primary" onClick={handleSave}>
              Save Changes
            </Button>
          </Grid>
          {fields.map((field, index) => (
            <React.Fragment key={index}>
              {field.type === "header" ? (
                <Grid item xs={12}>
                  <Typography variant="h5">{field.title}</Typography>
                </Grid>
              ) : (
                <>
                  <Grid item xs={12} sm={4}>
                    {field.title}
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    {field.type === "string" && (
                      <input
                        type="text"
                        name={field.field}
                        placeholder={field.title}
                        value={auction[field.field] || ""}
                        onChange={updateLocal}
                      />
                    )}
                    {field.type === "state" && (
                      <Select
                        native
                        name={field.field}
                        value={auction[field.field] || ""}
                        onChange={updateLocal}
                        style={{ fontSize: 12 }}
                        displayEmpty
                      >
                        <option value="">State</option>
                        {states &&
                          states.items.map((state, index) => (
                            <option key={index} value={state.stateid}>
                              {state.stateabbreviation} - {state.statefull}
                            </option>
                          ))}
                      </Select>
                    )}
                    {field.type === "country" && (
                      <Select
                        native
                        name={field.field}
                        value={auction[field.field] || ""}
                        onChange={updateLocal}
                        style={{ fontSize: 12 }}
                        displayEmpty
                      >
                        <option value="">Country</option>
                        <option value={1}>United States</option>
                        <option value={2}>Canada</option>
                      </Select>
                    )}
                  </Grid>
                </>
              )}
            </React.Fragment>
          ))}
        </Grid>
      ) : (
        <CircularProgress />
      )}
    </>
  );
}

const styles = (theme) => ({
  root: {
    display: "flex",
    fontSize: 12,
  },
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    width: "100%",
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    width: "100%",
  },
  fixedHeight: {
    height: 240,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },

  Button: {
    display: "block",
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
});

function mapStateToProps(state) {
  const { authentication, utility, batch } = state;
  const { user } = authentication;
  return {
    user,
    batch,
    utility,
  };
}

const connectedAuctionDetailsPage = connect(mapStateToProps)(
  withWidth()(withStyles(styles)(AuctionDetailsPage))
);
export { connectedAuctionDetailsPage as AuctionDetailsPage };
