import {
  Button,
  CircularProgress,
  Divider,
  FormControlLabel,
  Grid,
  makeStyles,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { authHeader, handleResponse, getEnvironmentURL } from "../_helpers";

import { PageWithAppDrawer2 } from "../_components/main/PageWithAppDrawer2";
import { useDispatch, useSelector } from "react-redux";
import { utilityActions } from "../_actions";
import moment from "moment";
import { Link as RouterLink, Link } from "react-router-dom";
import AlertRiNewVehicleType from "../_components/vehicle/AlertRiNewVehicleType";

export default function VehicleConformancePlusPage(props) {
  const url = getEnvironmentURL();
  const classes = useStyles();
  let { vehicleid: id } = useParams();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [vehicle, setVehicle] = useState(null);

  const getData = () => {
    if (id) {
      setLoading(true);
      setError(null);
      const requestOptions = {
        method: "GET",
        headers: {
          ...authHeader(),
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };

      return fetch(`${url}/api/vehicles/${id}`, requestOptions)
        .then(handleResponse)
        .then((response) => {
          setVehicle(response);
        })
        .catch((e) => {
          if (e.message) {
            setError(e.message);
          } else {
            setError(e);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    getData();
  }, [id]);

  return (
    <PageWithAppDrawer2 pageTitle="Conformance Plus">
      <Paper className={classes.paper}>
        <Typography variant="h5" style={{ textAlign: "center" }}>
          Conformance Plus
        </Typography>
        {loading && <CircularProgress />}
        {error && <Typography variant="h5" style={{ color: "red" }} />}
        {!loading && vehicle && vehicle.vehicle_conformance_plus && (
          <div>
            <RouterLink
              to={`/vehicles/${vehicle.vehicleid}`}
              component={Button}
              color="primary"
              variant="contained"
            >
              Back to Vehicle Details
            </RouterLink>
            <VehicleConformancePlus id={vehicle.vehicle_conformance_plus.id} getData={getData} />
          </div>
        )}
      </Paper>
    </PageWithAppDrawer2>
  );
}

function VehicleConformancePlus(props) {
  const dispatch = useDispatch();
  const id = props.id;
  const item = useSelector(
    (state) => state.utility.vehicle_conformance_plus_item
  );
  const conformanceItems = useSelector(
    (state) => state.utility.vehicle_conformance_items
  );
  const [totalItems, setTotalItems] = useState(0);
  const [completeItems, setCompleteItems] = useState(0);
  const [incompleteItems, setIncompleteItems] = useState(0);
  const [requiresFull, setRequiresFull] = useState(false);

  useEffect(() => {
    if (item && item.vehicle) {
      if (item.vehicle.is_ri_new_vehicle) {
        setRequiresFull(true);
      } else {
        setRequiresFull(false);
      }
    }
  }, [item]);

  useEffect(() => {
    dispatch(utilityActions.getOneUtility("vehicle_conformance_plus", id, []));
    dispatch(
      utilityActions.getUtility("vehicle_conformance_items", {
        perPage: 1000,
        filters: [
          {
            field: "vehicle_conformance_id",
            operator: "eq",
            value: id,
          },
        ],
        orders: [{ field: "id", direction: "asc" }],
      })
    );
  }, [dispatch]);

  useEffect(() => {
    let total = 0,
      complete = 0,
      incomplete = 0;
    if (conformanceItems.items) {
      conformanceItems.items.map((cItem) => {
        if (cItem.is_remediated === true) complete++;
        if (cItem.is_remediated === false) incomplete++;
        total++;
        return null;
      });
    }
    setTotalItems(total);
    setCompleteItems(complete);
    setIncompleteItems(incomplete);
  }, [conformanceItems]);

  return (
    <>
      {item.loading ? (
        <CircularProgress />
      ) : (
        <>
          {requiresFull ? (
            <div>
              <br />
              <AlertRiNewVehicleType
                vehicleInfo={{
                  id: item.vehicle.vehicleid,
                  yr: item.vehicle.yr,
                  make: item.vehicle.make,
                  model: item.vehicle.model,
                }}
                additionalNotes="all items require notes"
                onChange={() => props.getData()}
              />
            </div>
          ) : (
            <></>
          )}
          <Grid container>
            <Grid item xs={4} md={2}>
              VIN
            </Grid>
            <Grid item xs={8} md={10}>
              {item.vehicle.vehiclevin}
            </Grid>
            <Grid item xs={4} md={2}>
              Year
            </Grid>
            <Grid item xs={8} md={10}>
              {item.vehicle.yr}
            </Grid>
            <Grid item xs={4} md={2}>
              Make
            </Grid>
            <Grid item xs={8} md={10}>
              {item.vehicle.make}
            </Grid>
            <Grid item xs={4} md={2}>
              Model
            </Grid>
            <Grid item xs={8} md={10}>
              {item.vehicle.model}
            </Grid>
            <Grid item xs={4} md={2}>
              Total Items
            </Grid>
            <Grid item xs={8} md={10}>
              {totalItems}
            </Grid>
            <Grid item xs={4} md={2}>
              Completed Items
            </Grid>
            <Grid item xs={8} md={10}>
              {completeItems}
            </Grid>
            <Grid
              item
              xs={4}
              md={2}
              style={{
                backgroundColor: incompleteItems > 0 ? "yellow" : "inherit",
              }}
            >
              Incomplete Items
            </Grid>
            <Grid
              item
              xs={8}
              md={10}
              style={{
                backgroundColor: incompleteItems > 0 ? "yellow" : "inherit",
              }}
            >
              {incompleteItems}
            </Grid>
            <Grid item xs={4} md={2}>
              Completed Date
            </Grid>
            <Grid item xs={8} md={10}>
              {item.completed_date}
            </Grid>
            <Grid item xs={4} md={2}>
              Completed By
            </Grid>
            <Grid item xs={8} md={10}>
              {item.completed_by_user?.name}
            </Grid>
          </Grid>
          <Divider style={{ marginTop: 5, marginBottom: 5 }} />
          <Grid container spacing={1}>
            {conformanceItems.items.map((cItem, index) => (
              <VehicleConformanceItem
                item={cItem}
                key={index}
                requiresFull={requiresFull}
              />
            ))}
          </Grid>
        </>
      )}
    </>
  );
}

function VehicleConformanceItem(props) {
  const dispatch = useDispatch();
  const webUser = useSelector((state) => state.authentication.webUser);
  const item = props.item;
  const requiresFull = props.requiresFull;

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [isRemediated, setIsRemediated] = useState(null);
  const [remedyNotes, setRemedyNotes] = useState("");
  const [hasChanges, setHasChanges] = useState(false);

  useEffect(() => {
    if (item) {
      setIsRemediated(item.is_remediated);
      setRemedyNotes(item.remedy_notes);
    }
  }, [item]);

  const updateField = (fn, value) => {
    fn(value);
    if (!hasChanges) {
      setHasChanges(true);
    }
  };

  const saveItem = () => {
    let hasError = false;
    if (requiresFull) {
      if (!remedyNotes || isRemediated === null) {
        hasError = true;
      }
      if (remedyNotes && remedyNotes.trim() === "") {
        hasError = true;
      }
      if (hasError) {
        alert("New yr/make/model requires full inspection on all items");
        return;
      }
    }

    setError(null);
    setLoading(true);

    const payload = {
      is_remediated: isRemediated,
      remedy_notes: remedyNotes,
      completed_date: moment()
        .tz("America/Indiana/Indianapolis")
        .format("YYYY-MM-DD h:mm:ss"),
      completed_by: webUser.userid,
    };

    dispatch(
      utilityActions.updateUtility(
        "vehicle_conformance_items",
        item.id,
        payload,
        () => {
          setLoading(false);
          setHasChanges(false);
        }
      )
    );
  };

  return (
    <Grid item xs={12}>
      {error && (
        <Typography variant="h5" style={{ color: "red" }}>
          {error}
        </Typography>
      )}
      {loading && <CircularProgress />}
      {!loading && (
        <Grid
          container
          style={{
            padding: 3,
            border: "1px solid black",
            backgroundColor: hasChanges ? "yellow" : "inherit",
          }}
        >
          <Grid item xs={12} md={2}>
            <b>Description</b>
          </Grid>
          <Grid item xs={12} md={10}>
            {item.description}
          </Grid>
          <Grid item xs={12} md={2}>
            <b>Altered Vehicle?</b>
          </Grid>
          <Grid item xs={12} md={10}>
            <RadioGroup
              column
              aria-label="gender"
              name="controlled-radio-buttons-group"
              value={isRemediated}
              onChange={(e) =>
                updateField(setIsRemediated, Number(e.target.value))
              }
            >
              <FormControlLabel
                value={0}
                control={<Radio />}
                label="Inspected, no actions required"
              />
              <FormControlLabel
                value={1}
                control={<Radio />}
                label="Inspected, previously conformed"
              />
              <FormControlLabel
                value={2}
                control={<Radio />}
                label="Inspected, conformed by RI"
              />
            </RadioGroup>
          </Grid>
          <Grid item xs={12} md={2}>
            <b>Inspection Notes</b>
          </Grid>
          <Grid item xs={12} md={10}>
            <TextField
              value={remedyNotes || ""}
              fullWidth
              variant="outlined"
              multiline
              rows={2}
              onChange={(e) => updateField(setRemedyNotes, e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              color="primary"
              variant="contained"
              style={{ marginTop: 3, marginBottom: 3 }}
              onClick={saveItem}
            >
              Save
            </Button>
          </Grid>
          <Grid item xs={12} md={2}>
            <b>Completed Date</b>
          </Grid>
          <Grid item xs={12} md={10}>
            {item.completed_date}
          </Grid>
          <Grid item xs={12} md={2}>
            <b>Completed By</b>
          </Grid>
          <Grid item xs={12} md={10}>
            {item.completed_by_user?.name}
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(1),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
}));

export { VehicleConformancePlusPage };
