import React, { useState, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import Link from "@material-ui/core/Link";
import { connect, useDispatch, useSelector } from "react-redux";
import { pdf, BlobProvider } from "@react-pdf/renderer";

import MomentUtils from "@date-io/moment";
import {
  MuiPickersUtilsProvider,
  DatePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import { withStyles } from "@material-ui/core/styles";
import withWidth from "@material-ui/core/withWidth";

import { InvoiceDocument } from "../_components/pdf/Invoice";

import { formatNumberToStringMoney, downloadBlob } from "../_helpers";
import { utilityActions, batchActions } from "../_actions";

import { PageWithAppDrawer2 } from "../_components/main/PageWithAppDrawer2";
import ModalVehicleAddCharge from "../_components/vehicle/ModalVehicleAddCharge";
import ModalBatchAddVehicle from "../_components/vehicle/ModalBatchAddVehicle";
import ResponsiveGrid from "../_components/generic/ResponsiveGrid";

import DeleteForeverIcon from "@material-ui/icons/DeleteForever";

import {
  Paper,
  Grid,
  Select,
  TextField,
  Typography,
  Button,
  Snackbar,
  Backdrop,
  Divider,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";

import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import { utilityService } from "../_services";
import { Alert } from "@material-ui/lab";
import moment from "moment";
import { TitlesSentSheet } from "../_components/pdf/TitlesSentSheet";

class InvoiceBatchDetailsPage extends React.Component {
  constructor(props) {
    super(props);
    const { invoiceId } = this.props.match ? this.props.match.params : {};
    this.state = {
      invoiceId,
      clientId: null,
      reload: false,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { batch } = nextProps.batch;
    const clientId = batch.data ? batch.data.clientid : 0;
    const { invoiceid: invoiceId } = nextProps.match
      ? nextProps.match.params
      : {};
    if (invoiceId !== prevState.invoiceId) {
      return {
        ...prevState,
        invoiceId,
        clientId,
        reload: false,
        invoicedate: null,
        clientid: null,
        overnightcharge: null,
        overnighttrackingno: null,
      };
    } else {
      return {
        ...prevState,
        clientId,
      };
    }
    return false;
  }

  async componentDidMount() {
    const { items: clients } = this.props.utility.clients_active;
    const { items: auctions } = this.props.utility.auctions;

    if (clients.length === 0) {
      await this.props.dispatch(
        utilityActions.getUtility("clients_active", {
          perPage: 1000,
          filters: [
            {
              field: "clientactive",
              operator: "eq",
              value: "2",
            },
          ],
          orders: [{ field: "clientname", direction: "asc" }],
        })
      );
    }

    if (auctions.length === 0) {
      await this.props.dispatch(
        utilityActions.getUtility("auctions", {
          perPage: 1000,
          orders: [{ field: "auctionname", direction: "asc" }],
        })
      );
    }

    this.getNewData();
  }

  render() {
    const { invoiceId, reload, clientId } = this.state;
    const { data: item, loading } = this.props.batch.batch;
    const { classes } = this.props;

    if (reload === true) {
      this.setState({ reload: false }, () => {
        this.componentDidMount();
      });
      return <></>;
    }

    return (
      <PageWithAppDrawer2 pageTitle={`Batch #${invoiceId}`}>
        <RouterLink to="/invoicebatches">All Invoice Batches</RouterLink>
        <Paper className={classes.paper}>
          <InvoiceBatchDetails
            item={item}
            loading={loading}
            clientList={this.props.utility.clients_active.items}
            onAddVehicle={this.handleAddVehicle}
            onRemoveVehicle={this.handleRemoveVehicle}
            onUpdateBatch={this.handleUpdateBatch}
            onRemoveCharge={this.handleRemoveCharge}
            onReload={this.getNewData}
          />
        </Paper>
      </PageWithAppDrawer2>
    );
  }

  handleUpdateBatch = (data) => {
    const { dispatch } = this.props;
    const { invoiceId } = this.state;
    dispatch(batchActions.updateBatch(invoiceId, data));
  };

  getNewData = async () => {
    const { invoiceId } = this.state;
    await this.props.dispatch(batchActions.getBatch(invoiceId));
    this.setState({ loading: false });
  };

  handleAddVehicle = (vehicle, callback) => {
    const { dispatch } = this.props;
    const { invoiceId } = this.state;
    dispatch(
      batchActions.addVehicleToBatch(invoiceId, vehicle.vehicleid, callback)
    );
  };

  handleRemoveVehicle = (vehicleId) => {
    const { dispatch } = this.props;
    const { invoiceId } = this.state;
    dispatch(batchActions.removeVehicleFromBatch(invoiceId, vehicleId));
  };

  handleRemoveCharge = (chargeId) => {
    const { dispatch } = this.props;
    dispatch(
      utilityActions.deleteOneUtility("vehicle_charges", chargeId, null, () => {
        this.getNewData();
      })
    );
  };
}

function InvoiceBatchDetails(props) {
  const dispatch = useDispatch();
  const auctions = useSelector((state) => state.utility.auctions);
  const [massUpdateAuctionId, setMassUpdateAuctionId] = useState(null);
  const [massUpdateSentToAuctionDate, setMassUpdateSentToAuctionDate] =
    useState(null);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [updateLog, setUpdateLog] = useState([]);

  const [item, setItem] = useState({});
  const [loading, setLoading] = useState(true);
  const [clientList, setClientList] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalChargeOpen, setModalChargeOpen] = useState(false);
  const [vehicleId, setVehicleId] = useState(false);
  const [edit, setEdit] = useState(false);
  const [editItem, setEditItem] = useState(null);
  const [editInvoiceId, setEditInvoiceId] = useState(null);

  const [invoicedate, setInvoicedate] = useState(null);
  const [clientid, setClientid] = useState(null);
  const [overnighttrackingno, setOvernighttrackingno] = useState(null);
  const [singleInvoice, setSingleInvoice] = useState(null);

  const [download, setDownload] = useState(false);
  const [send, setSend] = useState(false);
  const [sendDisabled, setSendDisabled] = useState(true);

  const [snackOpen, setSnackOpen] = useState(false);
  const [snackText, setSnackText] = useState("");

  const [canGetTitlesSent, setCanGetTitlesSent] = useState(true);
  const [titlesSentDate, setTitlesSentDate] = useState(null);
  const [downloadTitles, setDownloadTitles] = useState(false);
  const titles_sent = useSelector((state) => state.utility.titles_sent);
  const [data, setData] = useState(null);
  const [pageMeta, setPageMeta] = useState(null);

  const [columns] = useState([
    {
      width: 2,
      title: "Invoice Number",
      field: "invoicedetailid",
    },
    {
      width: 3,
      title: "VIN",
      field: "serialnumber",
      dataRender: (item) => (
        <>
          {item.vehicleid > 0 ? (
            <>
              <RouterLink to={`/vehicles/${item.vehicleid}`}>
                {item.serialnumber}
              </RouterLink>
              {item.vehicle && (
                <div>
                  {item.vehicle.yr} {item.vehicle.make} {item.vehicle.model}
                </div>
              )}
            </>
          ) : (
            <>
              {item.serialnumber}
              {item.vehicle && (
                <div>
                  {item.vehicle.yr} {item.vehicle.make} {item.vehicle.model}
                </div>
              )}
            </>
          )}
        </>
      ),
    },
    {
      width: 5,
      title: "Invoice Amount",
      field: "totalcost",
      dataRender: (item) => (
        <InvoiceBreakdown
          item={item}
          openModalAddCharge={openModalAddCharge}
          openModalEditCharge={openModalEditCharge}
          onRemoveCharge={handleRemoveCharge}
        />
      ),
    },
    {
      width: 2,
      title: "Remove",
      field: null,
      dataRender: (item) => (
        <>
          {item.vehicleid > 0 ? (
            <Link
              href="javascript;;"
              onClick={(event) => {
                event.preventDefault();
                props.onRemoveVehicle(item.vehicleid);
              }}
            >
              Remove
            </Link>
          ) : (
            <Link
              href="javascript;;"
              onClick={(event) => {
                event.preventDefault();
                handleDeleteInvoice(item);
              }}
            >
              Delete Invoice
            </Link>
          )}
        </>
      ),
    },
  ]);

  useEffect(() => {
    setItem(props.item);
    setLoading(props.loading);
    setClientList(props.clientList);
    if (props.item) {
      setSingleInvoice(
        props.item.client ? props.item.client.clientsingleinvoice : 1
      );
      setInvoicedate(props.item.invoicedate);
      setClientid(props.item.clientid);
      setOvernighttrackingno(props.item.overnighttrackingno);
      const invoiceEmailAddress = props.item.client
        ? props.item.client.invoiceemailaddress
        : null;
      setSendDisabled(
        invoiceEmailAddress === null || invoiceEmailAddress.trim() === ""
          ? true
          : false
      );
      if (props.item && props.item.client && !props.item.client.clientname) {
        console.warn("titlesSent no client name");
        setCanGetTitlesSent(false);
      }
      let hasInvoiceWithTitleSent = false;
      props.item.invoices &&
        props.item.invoices.map((invoice) => {
          if (hasInvoiceWithTitleSent) {
            return;
          }
          if (invoice.vehicle && invoice.vehicle.titlesenttoauctiondate) {
            hasInvoiceWithTitleSent = true;
            setTitlesSentDate(
              moment(invoice.vehicle.titlesenttoauctiondate).format(
                "MM/DD/YYYY"
              )
            );
          }
        });
      if (!hasInvoiceWithTitleSent) {
        console.warn("titlesSent invoice");
        setCanGetTitlesSent(false);
      }
    }
  }, [props.item, props.loading, props.clientList]);

  const handleOnClose = () => {
    setModalOpen(false);
  };

  const handleRemoveCharge = (id) => {
    props.onRemoveCharge(id);
  };

  const handleAddManualInvoice = () => {
    utilityService
      .addUtility("invoices", {
        invoicebatchid: item.id,
        serialnumber: 0,
        clientid: clientid,
        vehicleid: 0,
        date_added_to_batch: moment().format("Y-M-D H:m:s"),
      })
      .then((response) => {
        // console.log("invoice create response", response);
      })
      .finally(() => {
        props.onReload();
      });
  };

  const handleDeleteInvoice = (invoice) => {
    utilityService
      .deleteOneUtility("invoices", invoice.id)
      .then((response) => {
        // console.log("invoice delete response", response);
      })
      .finally(() => {
        props.onReload();
      });
  };

  const openModalAddCharge = (vehicleId, invoiceId) => {
    setVehicleId(vehicleId);
    setEditInvoiceId(invoiceId);
    setModalChargeOpen(true);
  };

  const openModalEditCharge = (vehicleId, invoiceId, item) => {
    setEdit(true);
    setEditItem(item);
    setEditInvoiceId(invoiceId);
    setVehicleId(vehicleId);
    setModalChargeOpen(true);
  };

  const doMassUpdate = async () => {
    setUpdateLog([]);
    setOpenBackdrop(true);

    for (const [index, invoice] of item.invoices.entries()) {
      if (!invoice.vehicle) {
        continue;
      }

      let newValues = {};
      if (massUpdateAuctionId) {
        newValues["auctionid"] = massUpdateAuctionId;
      }
      if (massUpdateSentToAuctionDate) {
        newValues["titlesenttoauctiondate"] = massUpdateSentToAuctionDate;
      }

      await utilityService
        .updateUtility("vehicles", invoice.vehicle.vehicleid, newValues)
        .then((response) => {
          const text = `${invoice.vehicle.vehiclevin}: updated`;
          setUpdateLog((updateLog) => [...updateLog, text]);
        })
        .catch((error) => {
          const errorText = `${invoice.vehicle.vehiclevin}: ${error}`;
          setUpdateLog((updateLog) => [...updateLog, errorText]);
        });

      setMassUpdateAuctionId(null);
      setMassUpdateSentToAuctionDate(null);
      props.onReload();
    }

    setOpenBackdrop(false);
  };

  const getTitlesSent = () => {
    const filters = [
      {
        field: "sent_to_auction_date",
        operator: "eq",
        value: titlesSentDate,
      },
      {
        field: "client_id",
        operator: "eq",
        value: item.client.clientid,
      },
    ];

    const options = {
      filters,
      orders: [
        {
          field: "sent_to_auction_date",
          direction: "desc",
        },
        {
          field: "client_name",
          direction: "asc",
        },
        {
          field: "auction_name",
          direction: "asc",
        },
      ],
      perPage: 1000,
      page: 1,
    };

    dispatch(
      utilityActions.getUtility("titles_sent", options, () => {
        setDownloadTitles(true);
      })
    );
  };

  useEffect(() => {
    if (downloadTitles === false) {
      return;
    }
    if (titles_sent.items.length === 0) {
      if (downloadTitles) {
        alert("No Matching Titles");
        setDownloadTitles(false);
      }
      return;
    }
    setPageMeta({
      date: titlesSentDate,
      client: item && item.client && item.client.clientname,
    });
    let newData = [];
    titles_sent.items[0].clients[0].auctions.map((auction) => {
      auction.data.map((item) => {
        newData.push(item);
      });
    });
    setData(newData);
  }, [downloadTitles]);

  return (
    <>
      <Backdrop open={openBackdrop} style={{ zIndex: 1000, color: "white" }}>
        <CircularProgress />
        <br />
        Mass Update Status:
        <br />
        {updateLog.map((log, index) => {
          return (
            <React.Fragment key={index}>
              {log}
              <br />
            </React.Fragment>
          );
        })}
      </Backdrop>
      {loading ? (
        <CircularProgress />
      ) : (
        <>
          {data && downloadTitles && (
            <BlobProvider
              document={<TitlesSentSheet items={data} pageMeta={pageMeta} />}
            >
              {({ blob, url, loading, error }) => {
                if (!loading && url) {
                  window.open(url, "_blank");
                  setDownloadTitles(false);
                  setPageMeta(null);
                  return null;
                }
                return null;
              }}
            </BlobProvider>
          )}

          <Snackbar
            open={snackOpen}
            autoHideDuration={6000}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            onClose={() => setSnackOpen(false)}
          >
            <Alert onClose={() => setSnackOpen(false)} severity="success">
              {snackText}
            </Alert>
          </Snackbar>
          <Typography variant="h6">
            Batch Details{" "}
            <Button
              size="small"
              color="primary"
              variant="contained"
              onClick={getTitlesSent}
              disabled={!canGetTitlesSent}
            >
              Titles Sent
            </Button>{" "}
            <Button
              size="small"
              color="primary"
              variant="contained"
              onClick={() =>
                props.onUpdateBatch({
                  invoicedate,
                  clientid,
                  overnighttrackingno,
                })
              }
            >
              Update Batch
            </Button>{" "}
            <Button
              size="small"
              color="primary"
              variant="contained"
              onClick={() => setSend(true)}
              disabled={sendDisabled || send}
            >
              {send ? (
                <BlobProvider
                  document={
                    <InvoiceDocument
                      item={item}
                      singleInvoice={singleInvoice}
                    />
                  }
                >
                  {({ blob, url, loading, error }) => {
                    if (!loading && url) {
                      var reader = new FileReader();
                      reader.readAsDataURL(blob);
                      reader.onloadend = function () {
                        let base64data = reader.result;
                        utilityService
                          .getEndpoint(
                            `/api/invoice_batches/${item.id}/send`,
                            "post",
                            {
                              body: {
                                email: item.client.invoiceemailaddress,
                                docBase64: base64data,
                                item,
                              },
                            }
                          )
                          .then((response) => {
                            setSend(false);
                            setSnackText(
                              "Invoice sent: " + item.client.invoiceemailaddress
                            );
                            setSnackOpen(true);
                            props.onReload();
                            return null;
                          });
                      };
                    }
                    return (
                      <>
                        <CircularProgress color="secondary" size={22} />
                        Email Batch
                      </>
                    );
                  }}
                </BlobProvider>
              ) : (
                <>Email Batch</>
              )}
            </Button>{" "}
            <Button
              size="small"
              color="primary"
              variant="contained"
              onClick={() => setDownload(true)}
            >
              {download ? (
                <BlobProvider
                  document={
                    <InvoiceDocument
                      item={item}
                      singleInvoice={singleInvoice}
                    />
                  }
                >
                  {({ blob, url, loading, error }) => {
                    if (!loading && url) {
                      // window.location.href = url;
                      // console.log("singleInvoice", singleInvoice);
                      // console.log("item", item);
                      window.open(url, "_blank");
                      setDownload(false);
                      return null;
                    }
                    return (
                      <>
                        <CircularProgress color="secondary" size={22} />
                        Print Batch
                      </>
                    );
                  }}
                </BlobProvider>
              ) : (
                <>Print Batch</>
              )}
            </Button>{" "}
          </Typography>
          <Grid container>
            <Grid item xs={12} style={{ marginBottom: 20 }}>
              Last Emailed: {item.emailed}
              <br />
              Emailed To: {item.emailinfo}
              <br />
              Client Payment Type:{" "}
              {item.client?.payment_type?.paymenttype || "Unknown"}
              <br />
              PDFs:
              {item.attachments?.length === 0 && "Nothing Logged"}
              {item.attachments?.map((attachment) => (
                <div>
                  <a href={attachment.cloud_url} target="_blank">
                    {attachment.sent_to} ({attachment.created_at})
                  </a>
                </div>
              ))}
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
              <TextField
                readOnly
                label={`Invoice Number`}
                value={item.invoiceid}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <KeyboardDatePicker
                  // margin="inline"
                  name="invoicedate"
                  label="Invoice Date"
                  format="YYYY-MM-DD"
                  value={invoicedate || ""}
                  onChange={
                    (date, value) => setInvoicedate(value) // + " 12:00:00")
                  }
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
              <FormControl fullWidth>
                <InputLabel shrink>Client Name</InputLabel>
                <Select
                  native
                  value={clientid ? Number(clientid) : 0}
                  onChange={(e) => {
                    setClientid(e.target.value);
                  }}
                  displayEmpty
                  name="clientid"
                >
                  <option value={0}>No Client Selected</option>
                  {clientList.map((item) => {
                    return (
                      <option key={item.clientid} value={item.clientid}>
                        {item.clientname}
                      </option>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
              <TextField
                label={`Overnight Track #`}
                value={overnighttrackingno || ""}
                onChange={(e) => setOvernighttrackingno(e.target.value)}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Divider style={{ marginTop: 10, marginBottom: 10 }} />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6">Mass Update Batch</Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              Auction
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <InputLabel shrink>Auction</InputLabel>
                <Select
                  native
                  value={massUpdateAuctionId ? Number(massUpdateAuctionId) : 0}
                  onChange={(e) => {
                    setMassUpdateAuctionId(e.target.value);
                  }}
                  displayEmpty
                  name="massUpdateClientId"
                >
                  <option value={0}>No Auction Selected</option>
                  {auctions.items.map((item, index) => {
                    return (
                      <option key={index} value={item.auctionid}>
                        {item.auctionname}
                      </option>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={12}>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <KeyboardDatePicker
                  // margin="inline"
                  style={{ width: 400 }}
                  name="massUpdateSentToAuctionDate"
                  label="Title Sent To Client/Auction Date"
                  format="YYYY-MM-DD"
                  value={massUpdateSentToAuctionDate || null}
                  onChange={
                    (date, value) => setMassUpdateSentToAuctionDate(value) // + " 12:00:00")
                  }
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={12} md={12}>
              <Button
                size="small"
                color="primary"
                variant="contained"
                onClick={doMassUpdate}
                disabled={!massUpdateSentToAuctionDate && !massUpdateAuctionId}
              >
                Mass Update All Vehicles In Batch
              </Button>
            </Grid>
          </Grid>
          <br />
          <Typography variant="h6">
            {item && item.invoices && item.invoices.length} Vehicles in Batch
          </Typography>
          <Typography variant="h6">
            {item && formatNumberToStringMoney(item.invoice_total)} Total
            Charges
          </Typography>
          <Button
            variant="contained"
            color="primary"
            style={{ width: 200 }}
            disabled={Number(item.clientid) === 0}
            onClick={() => {
              setModalOpen(true);
            }}
          >
            Add Vehicle
          </Button>
          <br />
          <Button
            variant="contained"
            color="primary"
            style={{ width: 200 }}
            disabled={Number(item.clientid) === 0}
            onClick={() => {
              handleAddManualInvoice();
            }}
          >
            Add Manual Invoice
          </Button>
          <br />
          <ResponsiveGrid
            columns={columns}
            data={item.invoices}
            usePagination={false}
          />
          <ModalBatchAddVehicle
            open={modalOpen}
            onClose={handleOnClose}
            vehicles={item.available_vehicles}
            onAddVehicle={props.onAddVehicle}
          />
        </>
      )}
      <ModalVehicleAddCharge
        open={modalChargeOpen}
        edit={edit}
        item={editItem}
        invoiceId={editInvoiceId}
        vehicleId={vehicleId}
        onClose={() => {
          setModalChargeOpen(false);
          setEdit(false);
          setEditItem(null);
          props.onReload();
        }}
        onCancel={() => {
          setModalChargeOpen(false);
          setEdit(false);
          setEditItem(null);
        }}
      />
    </>
  );
}

function InvoiceBreakdown(props) {
  const item = props.item;

  return (
    <>
      <b>Total:</b> {formatNumberToStringMoney(item.totalcost)}
      {item.charges.map((charge, index) => (
        <React.Fragment key={index}>
          <br />
          <Link
            onClick={(event) => {
              event.preventDefault();
              const remove = window.confirm(
                "Are you sure you want to delete this charge?"
              );
              if (remove) {
                props.onRemoveCharge(charge.id);
              }
            }}
          >
            <DeleteForeverIcon style={{ fontSize: 16 }} color="primary" />
          </Link>{" "}
          <Link
            onClick={(event) => {
              event.preventDefault();
              props.openModalEditCharge(charge.vehicleid, item.id, charge);
            }}
          >
            {charge.type.vehiclechargetype}
          </Link>
          : {formatNumberToStringMoney(charge.chargeamt)}
        </React.Fragment>
      ))}
      <br />
      <Link onClick={() => props.openModalAddCharge(item.vehicleid, item.id)}>
        <i>Add Charge</i>
      </Link>
    </>
  );
}

const styles = (theme) => ({
  root: {
    display: "flex",
    fontSize: 12,
  },
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    width: "100%",
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    width: "100%",
  },
  fixedHeight: {
    height: 240,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },

  Button: {
    display: "block",
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
});

function mapStateToProps(state) {
  const { authentication, utility, batch } = state;
  const { user } = authentication;
  return {
    user,
    batch,
    utility,
  };
}

const connectedInvoiceBatchDetailsPage = connect(mapStateToProps)(
  withWidth()(withStyles(styles)(InvoiceBatchDetailsPage))
);
export { connectedInvoiceBatchDetailsPage as InvoiceBatchDetailsPage };
