import React, { useState, useEffect } from "react";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { TextField } from "@material-ui/core";
import { utilityService } from "../../_services";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

export default function ModalEditGst(props) {
  // const dispatch = useDispatch();
  const [open, setOpen] = useState(props.open || false);
  const vehicleId = props.vehicleId;
  const clientId = props.clientId;
  const [vehicleGst, setVehicleGst] = useState(null);
  const [loading, setLoading] = useState(false);

  const [supplierName, setSupplierName] = useState(null);
  const [gstValue, setGstValue] = useState(null);
  const [purchaseDate, setPurchaseDate] = useState(null);
  const [appliedDate, setAppliedDate] = useState(null);
  const [receivedDate, setReceivedDate] = useState(null);

  useEffect(() => {
    setOpen(props.open);
  }, [props.open]);

  useEffect(() => {
    if (open && Number(vehicleId) > 0) {
      setLoading(true);
      getData();
    }
  }, [vehicleId, open]);

  const getData = () => {
    utilityService
      .getUtility("vehicle_gst", {
        filters: [
          {
            field: "vehicle_id",
            operator: "eq",
            value: vehicleId,
          },
        ],
      })
      .then((response) => {
        if (response.data.length === 1) {
          setVehicleGst(response.data[0]);
          setSupplierName(response.data[0].supplier_name);
          setGstValue(response.data[0].gst_value);
          setPurchaseDate(response.data[0].purchase_date);
          setAppliedDate(response.data[0].applied_date);
          setReceivedDate(response.data[0].received_date);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));

  const handleCancel = () => {
    setOpen(false);
    if (props.onCancel) props.onCancel();
  }

  const handleClose = () => {
    setOpen(false);
    if (props.onClose) props.onClose();
  };

  const save = (close = false) => {
    setLoading(true);
    const payload = {
      client_id: clientId,
      vehicle_id: vehicleId,
      supplier_name: supplierName,
      gst_value: gstValue,
      purchase_date: purchaseDate,
      applied_date: appliedDate,
      received_date: receivedDate,
    };
    if (!vehicleGst) {
      utilityService.addUtility("vehicle_gst", payload).finally(() => {
        setLoading(false);
        if (close) {
          handleClose();
        }
      });
    } else {
      utilityService
        .updateUtility("vehicle_gst", vehicleGst.id, payload)
        .finally(() => {
          setLoading(false);
          if (close) {
            handleClose();
          }
        });
    }
  };

  return (
    <Dialog open={open} fullScreen={fullScreen}>
      <DialogTitle id="form-dialog-title" style={{ width: 600 }}>
        Update GST for VIN
      </DialogTitle>
      <DialogContent>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <TextField
            margin="dense"
            name={"supplier_name"}
            label={"Supplier Name"}
            type="text"
            value={supplierName || ""}
            onChange={(event) => setSupplierName(event.target.value)}
            fullWidth
          />
          <TextField
            margin="dense"
            name={"gst_value"}
            label={"GST Value"}
            type="number"
            value={gstValue || ""}
            onChange={(event) => setGstValue(event.target.value)}
            fullWidth
          />
          <KeyboardDatePicker
            fullWidth
            margin="normal"
            name="purchase_date"
            label={"GST Purchase Date"}
            format="MM/dd/yyyy"
            value={purchaseDate}
            onChange={(date, value) => setPurchaseDate(value)}
          />
          <KeyboardDatePicker
            fullWidth
            margin="normal"
            name="applied_date"
            label={"GST Applied Date"}
            format="MM/dd/yyyy"
            value={appliedDate}
            onChange={(date, value) => setAppliedDate(value)}
          />
          <KeyboardDatePicker
            fullWidth
            margin="normal"
            name="received_date"
            label={"GST Received Date"}
            format="MM/dd/yyyy"
            value={receivedDate}
            onChange={(date, value) => setReceivedDate(value)}
          />
        </MuiPickersUtilsProvider>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="primary">
          Cancel
        </Button>
        <Button onClick={save} color="primary">
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
}
