import React from "react";
import { Link as RouterLink } from "react-router-dom";
import Link from "@material-ui/core/Link";
import { connect, useSelector, useDispatch } from "react-redux";
import { byString } from "../_helpers/generic";
import { withStyles } from "@material-ui/core/styles";
import withWidth from "@material-ui/core/withWidth";

import MomentUtils from "@date-io/moment";
import {
  MuiPickersUtilsProvider,
  DatePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import { utilityActions } from "../_actions";
import { utilityService } from "../_services";

import { PageWithAppDrawer2 } from "../_components/main/PageWithAppDrawer2";
import ModalClientLoadAddVehicle from "../_components/vehicle/ModalClientLoadAddVehicle";
import ResponsiveGrid from "../_components/generic/ResponsiveGrid";

import { Paper, Grid, Select, TextField, Button, Box } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";

class ClientTransporterDetailsPage extends React.Component {
  constructor(props) {
    super(props);
    const { id } = this.props.match ? this.props.match.params : {};
    this.state = {
      id,
      reload: false,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { id } = nextProps.match ? nextProps.match.params : {};
    if (id !== prevState.id) {
      return {
        ...prevState,
        id,
        reload: true,
      };
    }
    return false;
  }

  async componentDidMount() {}

  render() {
    const { id, reload } = this.state;
    const { classes } = this.props;

    if (reload === true) {
      this.setState({ reload: false }, () => {
        this.componentDidMount();
      });
      return <></>;
    }

    return (
      <PageWithAppDrawer2 pageTitle={`Client Transporter #${id}`}>
        <RouterLink to="/clientTransporters">All Transporters</RouterLink>
        <Paper className={classes.paper}>
          <ClientTransporterDetails id={id} />
        </Paper>
      </PageWithAppDrawer2>
    );
  }
}

function ClientTransporterDetails(props) {
  const dispatch = useDispatch();
  const id = props.id;
  const webUser = useSelector((state) => state.authentication.webUser);
  const isAdmin = webUser.roleid === 2 ? true : false;
  const isClient = webUser.roleid !== 2 ? true : false;
  const clientId = webUser.client_id;
  const item = useSelector((state) => state.utility.client_transporters_item);
  const clients_active = useSelector((state) => state.utility.clients_active);

  const fields = [
    {
      width: 2,
      title: "Client Name",
      field: isClient ? "client.clientname" : "client_id",
      type: isClient ? "display" : "selectClient",
    },
    {
      width: 2,
      title: "Transporter Name",
      field: "name",
      type: "string",
    },
  ];

  React.useEffect(() => {
    if (clients_active.items.length === 0) {
      dispatch(
        utilityActions.getUtility("clients_active", {
          perPage: 1000,
          filters: [
            {
              field: "clientactive",
              operator: "eq",
              value: "2",
            },
          ],
          orders: [{ field: "clientname", direction: "asc" }],
        })
      );
    }

    return () => {
      dispatch(utilityActions.clearOneUtility("client_transporters"));
    };
  }, []);

  //   React.useEffect(() => {
  //     console.log("item", item);
  //   }, [item]);

  React.useEffect(() => {
    if (id) {
      if (!item) {
        getNewData();
      } else if (Number(item.id) !== Number(id)) {
        getNewData();
      }
    }
  }, [id]);

  const getNewData = () => {
    // console.log("getNewData");
    dispatch(utilityActions.getOneUtility("client_transporters", id, []));
  };

  const updateLocal = (event) => {
    const { name, value } = event.target;
    dispatch(
      utilityActions.updateOneUtilityLocal("client_transporters", {
        [name]: value,
      })
    );
  };
  const updateLocalManual = (name, value) => {
    dispatch(
      utilityActions.updateOneUtilityLocal("client_transporters", {
        [name]: value,
      })
    );
  };

  const handleSave = () => {
    dispatch(
      utilityActions.updateUtility("client_transporters", item.id, item, () => {
        getNewData();
      })
    );
  };

  return (
    <>
      {item && item.id ? (
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Button variant="contained" color="primary" onClick={handleSave}>
              Save Changes
            </Button>{" "}
          </Grid>
          {fields.map((field, index) => (
            <React.Fragment key={index}>
              <Grid item xs={12} sm={2}>
                {field.title}
              </Grid>
              <Grid item xs={12} sm={10}>
                {field.type === "display" && <>{byString(item, field.field)}</>}
                {field.type === "string" && (
                  <TextField
                    name={field.field}
                    placeholder={field.title}
                    value={item[field.field] || ""}
                    onChange={updateLocal}
                  />
                )}
                {clients_active.items.length > 0 &&
                  field.type === "selectClient" && (
                    <Select
                      native
                      name={field.field}
                      value={item[field.field] || ""}
                      onChange={updateLocal}
                      style={{ fontSize: 12 }}
                      displayEmpty
                    >
                      <option value="">
                        <em>Client Name</em>
                      </option>
                      {clients_active &&
                        clients_active.items.map((client, index) => (
                          <option key={index} value={client.clientid}>
                            {client.clientname}
                          </option>
                        ))}
                    </Select>
                  )}
              </Grid>
            </React.Fragment>
          ))}
        </Grid>
      ) : (
        <CircularProgress />
      )}
    </>
  );
}

const styles = (theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    width: "100%",
  },
});

function mapStateToProps(state) {
  const { authentication, utility, batch } = state;
  const { user } = authentication;
  return {
    user,
    batch,
    utility,
  };
}

const connectedClientTransporterDetailsPage = connect(mapStateToProps)(
  withWidth()(withStyles(styles)(ClientTransporterDetailsPage))
);
export { connectedClientTransporterDetailsPage as ClientTransporterDetailsPage };
