export function byString(o, s) {
  if (!s) {
    return;
  }

  s = s.replace(/\[(\w+)\]/g, ".$1"); // convert indexes to properties

  s = s.replace(/^\./, ""); // strip a leading dot

  var a = s.split(".");

  for (var i = 0, n = a.length; i < n; ++i) {
    var x = a[i];

    if (o && x in o) {
      o = o[x];
    } else {
      return;
    }
  }

  return o;
}

export function isEmpty(value) {
  if (!value) return true;
  if (value === "") return true;
  if (value === null) return true;
  return false;
}

export function setDiskCache(key, value) {
  const cacheKey = "fl-" + key;
  localStorage.setItem(cacheKey, JSON.stringify(value));
}
export function getDiskCache(key) {
  const cacheKey = "fl-" + key;
  return JSON.parse(localStorage.getItem(cacheKey));
}
export function removeDiskCache(key) {
  const cacheKey = "fl-" + key;
  localStorage.removeItem(cacheKey);
}
