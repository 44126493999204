import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import { hashHistory, isEmpty } from "../../_helpers";
import moment from "moment-timezone";

import { utilityActions } from "../../_actions";
import { vehicleService, utilityService } from "../../_services";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Typography, Grid, CircularProgress } from "@material-ui/core";
import ResponsiveGrid from "../generic/ResponsiveGrid";
import { Select } from "@material-ui/core";
import Backdrop from "@material-ui/core/Backdrop";
import Alert from "@material-ui/lab/Alert";

export default function VinLookupDialog(props) {
  const dispatch = useDispatch();

  const webUser = useSelector((state) => state.authentication.webUser);
  const isAdmin = webUser.roleid === 2 ? true : false;
  const isClient = webUser.roleid !== 2 ? true : false;
  const clients_active = useSelector((state) => state.utility.clients_active);
  const taTypes = useSelector((state) => state.utility.title_application_types);
  const tsAdditional = useSelector((state) => state.utility.title_status_additional);

  const clientGroup = webUser.client_group || null;
  const clientsList = clientGroup
    ? clientGroup.clients_base.map((c) => c.clientid)
    : [webUser.client_id];

  const [open, setOpen] = useState(false);
  const [backdropOpen, setBackdropOpen] = useState(false);
  const [vin, setVin] = useState("");
  const [vinDetails, setVinDetails] = useState(null);
  const [vehicleDetails, setVehicleDetails] = useState(null);
  const [loading, setLoading] = useState(false);
  const [clientId, setClientId] = useState(null);
  const [clientRegWarning, setClientRegWarning] = useState(false);
  const [taTypeId, setTaTypeId] = useState(null);
  const [nhtsaError, setNhtsaError] = useState(null);

  const columns = [
    {
      width: 4,
      title: "VIN",
      field: "vehiclevin",
      dataRender: (item) => (
        <RouterLink to={`/vehicles/${item.vehicleid}`}>
          {item.vehiclevin}
        </RouterLink>
      ),
    },
    {
      width: 4,
      title: "Client",
      field: "client.clientname",
    },
    {
      width: 4,
      title: "Vehicle Status",
      field: "title_status.statusname",
    },
  ];

  useEffect(() => {
    if (clients_active.items.length === 0) {
      dispatch(
        utilityActions.getUtility("clients_active", {
          perPage: 1000,
          filters: [
            {
              field: "clientactive",
              operator: "eq",
              value: "2",
            },
            !isAdmin &&
            {
              field: "clientid",
              operator: "in",
              value: clientsList.join(","),
            },
          ],
          orders: [{ field: "clientname", direction: "asc" }],
        })
      );
    }
    if (taTypes.items.length === 0) {
      dispatch(
        utilityActions.getUtility("title_application_types", {
          perPage: 1000,
          orders: [
            {
              field: "titleapplicationtype",
              direction: "asc",
            },
          ],
        })
      );
    }
    if (tsAdditional.items.length === 0) {
      dispatch(
        utilityActions.getUtility("title_status_additional", {
          perPage: 1000,
          orders: [
            {
              field: "id",
              direction: "asc",
            },
          ],
        })
      );
    }
  }, []);

  useEffect(() => {
    setOpen(props.open);
  }, [props.open]);

  const handleClose = () => {
    setOpen(false);
    props.onClose();
  };

  const handleAddItem = () => {
    if (isEmpty(clientId) || isEmpty(taTypeId)) {
      // alert("Client and Title Application Type are required to register");
      return;
    }
    setBackdropOpen(true);
    utilityService
      .addUtility("vehicles", {
        enteredby: webUser.userid,
        vehiclevin: vinDetails.vin,
        yr: vinDetails.modelyear,
        make: vinDetails.make,
        model: vinDetails.model,
        vehicletype: vinDetails.bodyclass,
        clientid: clientId, // default to no client
        billtoclientid: clientId,
        titleprocessingtype: taTypeId,
        processtype: 1, // default to IN STATE,
        recordcreateddate: moment()
          .tz("America/Indiana/Indianapolis")
          .format("YYYY-MM-DD h:mm:ss"),
      })
      .then((response) => {
        hashHistory.push(`/vehicles/${response.id}`);
        setBackdropOpen(false);
      });
  };

  const handleVinLookup = async (vin) => {
    setLoading(true);
    setVehicleDetails(null);
    setVinDetails(null);
    setNhtsaError(null);
    await utilityService
      .getUtility("vehicles_register", {
        filters: [
          {
            field: "vehiclevin",
            operator: "eq",
            value: vin,
          },
        ],
      })
      .then((response) => {
        if (response.data.length > 0) {
          setVehicleDetails(response.data);
        }
      });
    await vehicleService.nhtsaVinLookup(vin).then((response) => {
      if (response[0]) {
        setVinDetails(response[0]);
      }
      if (response.error) {
        setNhtsaError(response.error);
        setVinDetails({
          errorcode: -1,
          errortext: "NHTSA might be down",
          vin: vin,
          modelyear: "UNKNOWN",
          make: "UNKNOWN",
          model: "UKNOWN",
          bodyclass: "UKNOWN",
        });
      }
    });
    setLoading(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <Backdrop open={backdropOpen} style={{ zIndex: 1000 }}>
          <CircularProgress />
        </Backdrop>
        <DialogTitle id="form-dialog-title">VIN Lookup</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Lookup a VIN using the NHTSA API
            <br />
            Multiple Vehicles?
            <br />
            <Button
              component={RouterLink}
              to="/management/vehicleFileUpload"
              size="small"
              color="primary"
              variant="contained"
              onClick={() => {}}
            >
              Upload xls/csv
            </Button>
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="vin"
            label="VIN"
            type="text"
            fullWidth
            value={vin}
            inputProps={{
              maxLength: 17,
            }}
            onChange={(event) => {
              const value = event.target.value;
              setVin(value);
            }}
            onKeyPress={(e) => {
              if (e.key === "Enter" && vin.length > 0) {
                handleVinLookup(vin);
              }
            }}
          />
          {loading && (
            <Typography component="div" align="center">
              <CircularProgress />
            </Typography>
          )}
          {vehicleDetails && !loading && (
            <ResponsiveGrid
              columns={columns}
              data={vehicleDetails}
              usePagination={false}
            />
          )}
          {nhtsaError && (
            <Typography color="secondary">NHTSA Error: {nhtsaError}</Typography>
          )}
          {vinDetails && !loading && (
            <>
              {Number(vinDetails.errorcode) !== 0 && (
                <Typography color="secondary">
                  {vinDetails.errortext}
                </Typography>
              )}
              {vinDetails && (
                <Grid container>
                  <Grid item xs={12}>
                    <Typography variant="h6" align="center">
                      NHTSA Details
                    </Typography>
                  </Grid>
                  {vinDetails.tpms && vinDetails.tpms.length > 0 && (
                    <Grid
                      item
                      xs={12}
                      style={{ textAlign: "center", marginBottom: 20 }}
                    >
                      <Typography
                        variant="body"
                        align="center"
                        style={{
                          color: "red",
                        }}
                      >
                        WARNING:
                        <br />
                        Vehicle might not comply with Federal Motor Vehicle
                        Safety Standard No. 138
                        <br />
                      </Typography>
                      {vinDetails.tpms.map((item, index) => (
                        <Typography>
                          {item.make} {item.model} {item.years}
                        </Typography>
                      ))}
                      <a
                        href="https://icsw.nhtsa.gov/cars/rules/import/newsletters/Revised%20RI%20Newsletter%2063.pdf"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        More info on TPMS regulations (PDF)
                      </a>
                    </Grid>
                  )}
                  {vinDetails.conformanceCheck &&
                    vinDetails.conformanceCheck.requiresConformancePlus && (
                      <Grid
                        item
                        xs={12}
                        style={{ textAlign: "center", marginBottom: 20 }}
                      >
                        <Typography
                          variant="body1"
                          align="center"
                          style={{
                            color: "blue",
                          }}
                        >
                          RI NOTICE:
                          <br />
                          This type of vehicle has not been previously imported
                          and will require additional NHTSA processing if
                          importing vehicle
                          <br />
                        </Typography>
                      </Grid>
                    )}
                  <Grid item xs={6}>
                    VIN
                  </Grid>
                  <Grid item xs={6}>
                    {vinDetails.vin}
                  </Grid>
                  <Grid item xs={6}>
                    Year
                  </Grid>
                  <Grid item xs={6}>
                    {vinDetails.modelyear}
                  </Grid>
                  <Grid item xs={6}>
                    Make
                  </Grid>
                  <Grid item xs={6}>
                    {vinDetails.make}
                  </Grid>
                  <Grid item xs={6}>
                    Model
                  </Grid>
                  <Grid item xs={6}>
                    {vinDetails.model}
                  </Grid>
                  <Grid item xs={6}>
                    Class
                  </Grid>
                  <Grid item xs={6}>
                    {vinDetails.bodyclass}
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="h6" align="center">
                      Vehicle Requirements
                    </Typography>
                    {clientRegWarning && (
                      <Alert severity="error" variant="filled">
                        RI Client has not completed registration documents
                      </Alert>
                    )}
                  </Grid>
                  <Grid item xs={6}>
                    Client
                  </Grid>
                  <Grid item xs={6}>
                    <Select
                      native
                      style={{ fontSize: 12 }}
                      value={clientId || ""}
                      onChange={(event, child) => {
                        const value = event.target.value;
                        const option =
                          event.target.options[event.target.selectedIndex];
                        const { is_ri, reg_docs } = option.dataset;
                        if (
                          value !== "" &&
                          Number(is_ri) === 1 &&
                          Number(reg_docs) === 0
                        ) {
                          setClientRegWarning(true);
                        }
                        setClientId(value);
                      }}
                      displayEmpty
                    >
                      <option value={""}>Choose Option</option>

                      {clients_active.items.map((item, index) => (
                        <option
                          key={index}
                          value={item.clientid}
                          data-is_ri={item.is_ri}
                          data-reg_docs={item.ri_reg_docs_complete}
                        >
                          {item.clientname}
                        </option>
                      ))}
                    </Select>
                    {isEmpty(clientId) && (
                      <Typography display="inline" style={{ color: "red" }}>
                        Required
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={6}>
                    Title Application Type
                  </Grid>
                  <Grid item xs={6}>
                    <Select
                      native
                      style={{ fontSize: 12 }}
                      value={taTypeId || ""}
                      onChange={(event, child) => {
                        const value = event.target.value;
                        setTaTypeId(value);
                      }}
                      displayEmpty
                    >
                      <option value={""}>Choose Option</option>

                      {taTypes.items.map((item, index) => (
                        <option key={index} value={item.titleapplicationid}>
                          {item.titleapplicationtype}
                        </option>
                      ))}
                    </Select>
                    {isEmpty(taTypeId) && (
                      <Typography display="inline" style={{ color: "red" }}>
                        Required
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              )}
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={() => handleVinLookup(vin)} color="primary">
            Lookup
          </Button>
          {!vehicleDetails &&
            vinDetails &&
            Number(vinDetails.errorcode) === 0 && (
              <Button
                onClick={handleAddItem}
                color="primary"
                disabled={
                  (loading && vehicleDetails !== null) ||
                  isEmpty(clientId) ||
                  isEmpty(taTypeId)
                }
              >
                Register Vehicle
              </Button>
            )}
          {!vehicleDetails &&
            vinDetails &&
            Number(vinDetails.errorcode) !== 0 && (
              <Button
                onClick={handleAddItem}
                color="primary"
                disabled={
                  (loading && vehicleDetails !== null) ||
                  isEmpty(clientId) ||
                  isEmpty(taTypeId)
                }
              >
                Register Invalid VIN
              </Button>
            )}
          {vehicleDetails && (
            <Button
              onClick={handleAddItem}
              color="primary"
              disabled={loading || isEmpty(clientId) || isEmpty(taTypeId)}
            >
              Register DUPLICATE
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}
