import React, { useState } from "react";

import { useSelector } from "react-redux";
import { Button, Select, TextField } from "@material-ui/core";

export default function ImagePreviewDetails(props) {
  const item = props.item;
  const vehicleImageTypes = useSelector(
    (state) => state.utility.vehicle_image_types
  );
  const [customText, setCustomText] = useState(props.item.docnote);
  return (
    <>
      {item.docnote ? item.docnote : <i>No Name</i>}
      <br />
      <Select
        native
        value={item.imagetype || ""}
        onChange={(e) => {
          const target = e.target;
          const value = target.value;
          const text = target.options[target.selectedIndex].innerText;
          props.onUpdateImageType(item.id, value, text);
        }}
        displayEmpty
      >
        <option value="">Custom or No Image Name</option>
        {vehicleImageTypes &&
          vehicleImageTypes.items.map((t, index) => (
            <option key={t.id} value={t.id}>
              {t.name}
            </option>
          ))}
      </Select>
      {(item.imagetype == "" ||
        item.imagetype == null ||
        item.imagetype == 0) && (
        <>
          <TextField
            id="custom name"
            label="Custom Name"
            value={customText}
            onChange={(e) => setCustomText(e.target.value)}
            fullWidth
          />
          <br />
          <Button
            size="small"
            variant="contained"
            color="primary"
            onClick={() => props.onUpdateImageType(item.id, 0, customText)}
          >
            Update Custom
          </Button>{" "}
        </>
      )}
      <Button
        size="small"
        variant="contained"
        color="primary"
        onClick={() => props.onDeleteImage(item.id)}
      >
        Delete Image
      </Button>
    </>
  );
}
