import React, { useCallback, useMemo, useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { readString } from "react-papaparse";
// import Link from "@material-ui/core/Link";
import { connect, useSelector, useDispatch } from "react-redux";
import { withStyles } from "@material-ui/core/styles";

import { utilityActions } from "../_actions";

import { PageWithAppDrawer2 } from "../_components/main/PageWithAppDrawer2";
// import { AuctionModal } from "../_components/admin/AuctionModal";

import { utilityService } from "../_services";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import { Button, Box, Typography } from "@material-ui/core";

class AutoIMSReconciliationPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
    };
  }
  componentDidMount() {}

  static getDerivedStateFromProps(nextProps, prevState) {
    return null;
  }

  render() {
    const { classes } = this.props;
    const { data } = this.state;
    console.log("data", data);
    return (
      <PageWithAppDrawer2 pageTitle={"AutoIMS Reconciliation"}>
        <Paper className={classes.paper}>
          <DragAndDrop onAddFile={this.handleAddFile} />
          {data &&
            data.map((data2, index) => (
              <DisplayCSV key={index} rawData={data2} />
            ))}
        </Paper>
      </PageWithAppDrawer2>
    );
  }

  handleAddFile = (data) => {
    // console.log("data", data);
    this.setState((prevState) => {
      const newData = prevState.data;
      newData.push(readString(data));
      return { data: newData };
    });
  };
}

function useForceUpdate() {
  const [value, setValue] = useState(0); // integer state
  return () => setValue((value) => ++value); // update the state to force render
}

function DisplayCSV(props) {
  const dispatch = useDispatch();
  const forceUpdate = useForceUpdate();

  const [rawData, setRawData] = useState(props.rawData);
  const [data, setData] = useState([]);
  const [auctionId, setAuctionId] = useState(null);
  const auctions = useSelector((state) => state.utility.auctions);
  const [checked, setChecked] = useState([]);
  const [valid, setValid] = useState([]);
  const [invalid, setInvalid] = useState([]);
  const [count, setCount] = useState(0);
  const [descIndex, setDescIndex] = useState(0);
  const [lotIndex, setLotIndex] = useState(0);
  const [stockIndex, setStockIndex] = useState(0);

  useEffect(() => {
    let newData = [];
    console.log("rawdata", rawData);
    let dIndex = null;
    let lIndex = null;
    let sIndex = null;

    async function processCols() {
      await rawData.data[0].map((col, index) => {
        if (col.includes("Description")) {
          dIndex = index;
          setDescIndex(index);
        }
        if (col.includes("Lot ID")) {
          lIndex = index;
          setLotIndex(index);
        }
        if (col.includes("Auc. WO/SN")) {
          sIndex = index;
          setStockIndex(index);
        }
      });
    }
    processCols();

    rawData.data.map((row, index) => {
      const desc = row[dIndex];
      const lot = lIndex ? row[lIndex] : null;
      const stock = sIndex ? row[sIndex] : null;

      // console.log("desc", desc);
      //   if (desc && desc.includes("---")) return;
      const matches = desc ? desc.match(/[a-z0-9A-Z]{17}/) : null;
      newData[index] = matches
        ? { vin: matches[0], lot, stock, checked: false, valid: null }
        : null;
    });
    // console.log("newData", newData);
    // [a-z0-9A-Z]{17}
    setData(newData);
  }, [rawData]);

  useEffect(() => {
    // console.log("data updated", data);
  }, [data]);

  useEffect(() => {
    // console.log("checked", checked);
  }, [checked]);

  useEffect(() => {
    if (auctions.items.length === 0) {
      dispatch(
        utilityActions.getUtility("auctions", {
          perPage: 1000,
          orders: [{ field: "auctionname", direction: "asc" }],
        })
      );
    }
  }, []);

  function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  const updateList = async () => {
    // console.log("update list");
    if (auctionId === null || auctionId === "") {
      // console.log("no auction");
      window.alert("Must Select Auction");
      return;
    }
    for (const [index, data2] of data.entries()) {
      console.log("loop", data2);
      setCount(index);
      if (data2 && data2.vin) {
        console.log("sleep");
        await sleep(250);

        utilityService
          .getUtility("vehicles", {
            filters: [
              {
                field: "vehiclevin",
                operator: "eq",
                value: data2.vin,
              },
            ],
          })
          .then((response) => {
            if (response.data && response.data.length === 1) {
              setValid((valid) => [...valid, index]);
              console.log("lot id", data2.lot);
              console.log("stock", data2.stock);

              utilityService
                .updateUtility("vehicles", response.data[0].vehicleid, {
                  auction_id_selling: auctionId,
                  auction_lot_id: data2.lot,
                  auction_stock_number: data2.stock,
                })
                .then((response) => {
                  setChecked((checked) => [...checked, index]);
                });
            } else {
              setInvalid((invalid) => [...invalid, index]);
            }
          });
      }
    }
  };

  return (
    <>
      <Box>{rawData && rawData.data.length} Vehicles</Box>
      <Box>{count} Checked</Box>
      <FormControl fullWidth>
        <InputLabel shrink>Aution Name</InputLabel>
        <Select
          native
          value={auctionId || ""}
          onChange={(event) => setAuctionId(event.target.value)}
          displayEmpty
          name="auctionid"
        >
          <option value="">Select Auction</option>
          {auctions.items.map((auction) => {
            return (
              <option key={auction.auctionid} value={auction.auctionid}>
                {auction.auctionname}
              </option>
            );
          })}
        </Select>
      </FormControl>
      {!descIndex && !lotIndex && !stockIndex ? (
        <Typography style={{ color: "red" }}>
          No columns found, invalid CSV?
        </Typography>
      ) : (
        <Typography>
          {!auctionId && (
            <b style={{ color: "red" }}>
              Auction must be selected
              <br />
            </b>
          )}
          {descIndex ? (
            <>
              VIN Column identified
              <br />
            </>
          ) : (
            <b style={{ color: "red" }}>
              VIN Column not found
              <br />
            </b>
          )}
          {lotIndex && (
            <>
              Lot will be updated
              <br />
            </>
          )}
          {stockIndex && (
            <>
              Stock # will be updated
              <br />
            </>
          )}
        </Typography>
      )}
      <Button
        size="small"
        variant="contained"
        color="primary"
        disabled={!descIndex || !auctionId}
        onClick={updateList}
      >
        Update List
      </Button>
      <TableContainer component={Paper}>
        <Table>
          <TableBody>
            {rawData.data.map((row, index) => {
              if (row[0].includes("--")) {
                return null;
              }
              let bgColor = "inherit";
              if (invalid.includes(index)) bgColor = "red";
              if (valid.includes(index)) bgColor = "green";

              return (
                <TableRow key={index} style={{ backgroundColor: bgColor }}>
                  {row.map((col, index2) => (
                    <TableCell key={index2} component="th" scope="row">
                      {index < 2 && col}
                      {index > 1 && index2 > 0 && col}
                      {index > 1 && index2 === 0 && data[index] && (
                        <>
                          {data[index].vin}
                          <br />
                          In System:
                          {valid.includes(index) && "Yes"}
                          {invalid.includes(index) && "No"}
                          <br />
                          Updated:{" "}
                          {checked.includes(index) ? "updated" : "not updated"}
                        </>
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
};

const activeStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

function DragAndDrop(props) {
  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();

      reader.onabort = () => console.log("file reading was aborted");
      reader.onerror = () => console.log("file reading has failed");
      reader.onload = () => {
        // Do whatever you want with the file contents
        const data = reader.result;
        // console.log(binaryStr);
        props.onAddFile && props.onAddFile(data);
      };
      //   reader.readAsArrayBuffer(file);
      reader.readAsText(file);
    });
  }, []);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({ onDrop, accept: [".csv"] });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  return (
    <div className="container">
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} />
        <p>Drag 'n' drop some files here, or click to select files</p>
      </div>
    </div>
  );
}

const styles = (theme) => ({
  root: {
    display: "flex",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
});

function mapStateToProps(state) {
  const { authentication, utility } = state;
  const { user } = authentication;
  const { us_states, auctions } = utility;
  return {
    user,
    auctions,
    us_states,
  };
}

const connectedAutoIMSReconciliationPage = connect(mapStateToProps)(
  withStyles(styles)(AutoIMSReconciliationPage)
);
export { connectedAutoIMSReconciliationPage as AutoIMSReconciliationPage };
