import React, { useEffect, useState } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import moment from "moment";

import { utilityActions } from "../_actions";

import { PageWithAppDrawer2 } from "../_components/main/PageWithAppDrawer2";
import FiltersGrid from "../_components/generic/FiltersGrid";

import {
  Paper,
  Divider,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
} from "@material-ui/core";

import { formatNumberToStringMoney } from "../_helpers";

class MonthlySummaryPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {}

  render() {
    const { classes } = this.props;
    return (
      <PageWithAppDrawer2 pageTitle={"Monthly Summary"}>
        <Paper className={classes.paper}>
          <MonthlySummary />
        </Paper>
      </PageWithAppDrawer2>
    );
  }
}

function MonthlySummary(props) {
  const dispatch = useDispatch();
  const invoice_summary_monthly = useSelector(
    (state) => state.utility.invoice_summary_monthly
  );

  let date = new Date();
  const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
  const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

  const [getData, setGetData] = useState(false);
  const start = moment(firstDay).format("YYYY-MM-DD");
  const end = moment(lastDay).format("YYYY-MM-DD");

  const [availableFilters, setAvailableFilters] = useState([
    {
      name: "Year",
      field: "year",
      operator: "eq",
      value: moment().format("YYYY"),
      type: "choice",
      choices: [
        { key: moment().format("YYYY"), value: moment().format("YYYY") },
        {
          key: moment().subtract(1, "years").format("YYYY"),
          value: moment().subtract(1, "years").format("YYYY"),
        },
      ],
      default: "",
    },
    {
      name: "Month",
      field: "month",
      operator: "eq",
      value: moment().format("MM"),
      // value: 7,
      type: "choice",
      choices: monthList,
      default: "",
    },
    {
      name: "Client",
      field: "client_id",
      operator: "eq",
      value: "",
      type: "choice",
      useUtility: true,
      utility: "clients_active",
      utilityKey: "clientid",
      utilityValue: "clientname",
      utilityFilters: [
        {
          field: "clientactive",
          operator: "eq",
          value: 2,
        },
      ],
      utilityOrder: {
        field: "clientname",
        direction: "asc",
      },
      choices: [],
      default: "",
    },
  ]);

  useEffect(() => {
    if (getData === true) {
      setGetData(false);
      getNewData();
    }
  }, [getData]);

  useEffect(() => {
    getNewData();
  }, []);

  const getNewData = async () => {
    if (!start || !end) {
      return;
    }

    const filters = availableFilters.filter((filter) => {
      return filter.value ? true : false;
    });

    const options = {
      filters,
      orders: [
        {
          field: "client_name",
          direction: "asc",
        },
      ],
      perPage: 1000,
      page: 1,
    };

    dispatch(utilityActions.getUtility("invoice_summary_monthly", options));
  };

  const handleFilterChange = (newFilter, refresh = true) => {
    console.log(newFilter);
    const newFilters = availableFilters.map((filter) => {
      if (filter.field !== newFilter.field) return filter; // not the droid we're looking for, return unchanged
      if (filter.name !== newFilter.name) return filter; // not the droid we're looking for, return unchanged

      filter.value = newFilter.value;
      return filter;
    });
    setAvailableFilters(newFilters);
    if (refresh) {
      setGetData(true);
    }
  };

  let totalCount = 0;
  let totalSum = 0;
  let totalCountRI = 0;
  let totalSumRI = 0;
  let totalCountTitles = 0;
  let totalSumTitles = 0;

  return (
    <>
      <FiltersGrid
        availableFilters={availableFilters}
        onChange={handleFilterChange}
      />
      <Divider style={{ marginTop: 10, marginBottom: 50 }} />
      {invoice_summary_monthly.items &&
      invoice_summary_monthly.items.length > 0 ? (
        <Table size="small">
          <TableHead>
            <TableRow style={{ backgroundColor: "black" }}>
              <TableCell style={{ color: "white" }}>Client</TableCell>
              <TableCell style={{ color: "white" }}>Combined Count</TableCell>
              <TableCell style={{ color: "white" }}>Combined Total</TableCell>
              <TableCell style={{ color: "white" }}>RI Count</TableCell>
              <TableCell style={{ color: "white" }}>RI Total</TableCell>
              <TableCell style={{ color: "white" }}>Titles Count</TableCell>
              <TableCell style={{ color: "white" }}>Titles Total</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {invoice_summary_monthly.items.map((client, index) => {
              totalCount += client.count;
              totalSum += client.sum;
              totalCountRI += client.count_ri;
              totalSumRI += client.sum_ri;
              totalCountTitles += client.count_titles;
              totalSumTitles += client.sum_titles;

              return (
                <TableRow key={index}>
                  <TableCell>{client.client_name || "foo"}</TableCell>
                  <TableCell>{client.count}</TableCell>
                  <TableCell>{formatNumberToStringMoney(client.sum)}</TableCell>
                  <TableCell>{client.count_ri}</TableCell>
                  <TableCell>
                    {formatNumberToStringMoney(client.sum_ri)}
                  </TableCell>
                  <TableCell>{client.count_titles}</TableCell>
                  <TableCell>
                    {formatNumberToStringMoney(client.sum_titles)}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
          <TableHead>
            <TableRow style={{ backgroundColor: "black" }}>
              <TableCell style={{ color: "white" }}>Totals</TableCell>
              <TableCell style={{ color: "white" }}>Combined Count</TableCell>
              <TableCell style={{ color: "white" }}>Combined Total</TableCell>
              <TableCell style={{ color: "white" }}>RI Count</TableCell>
              <TableCell style={{ color: "white" }}>RI Total</TableCell>
              <TableCell style={{ color: "white" }}>Titles Count</TableCell>
              <TableCell style={{ color: "white" }}>Titles Total</TableCell>
            </TableRow>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>{totalCount}</TableCell>
              <TableCell>{formatNumberToStringMoney(totalSum)}</TableCell>
              <TableCell>{totalCountRI}</TableCell>
              <TableCell>{formatNumberToStringMoney(totalSumRI)}</TableCell>
              <TableCell>{totalCountTitles}</TableCell>
              <TableCell>{formatNumberToStringMoney(totalSumTitles)}</TableCell>
            </TableRow>
          </TableHead>
        </Table>
      ) : (
        "No Data"
      )}
    </>
  );
}

const monthList = [
  {
    key: 1,
    value: "January",
  },
  {
    key: 2,
    value: "February",
  },
  {
    key: 3,
    value: "March",
  },
  {
    key: 4,
    value: "April",
  },
  {
    key: 5,
    value: "May",
  },
  {
    key: 6,
    value: "June",
  },
  {
    key: 7,
    value: "July",
  },
  {
    key: 8,
    value: "August",
  },
  {
    key: 9,
    value: "September",
  },
  {
    key: 10,
    value: "October",
  },
  {
    key: 11,
    value: "November",
  },
  {
    key: 12,
    value: "December",
  },
];

const styles = (theme) => ({
  root: {
    display: "flex",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
});

function mapStateToProps(state) {
  const { authentication, utility } = state;
  const { user } = authentication;
  const { us_states, auctions } = utility;
  return {
    user,
    auctions,
    us_states,
  };
}

const connectedMonthlySummaryPage = connect(mapStateToProps)(
  withStyles(styles)(MonthlySummaryPage)
);
export { connectedMonthlySummaryPage as MonthlySummaryPage };
