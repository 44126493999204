export const paperworkConstants = {
  PAPERWORK_ADD_REQUEST: "PAPERWORK_ADD_REQUEST",
  PAPERWORK_ADD_SUCCESS: "PAPERWORK_ADD_SUCCESS",
  PAPERWORK_ADD_FAILURE: "PAPERWORK_ADD_FAILURE",

  PAPERWORK_GET_REQUEST: "PAPERWORK_GET_REQUEST",
  PAPERWORK_GET_SUCCESS: "PAPERWORK_GET_SUCCESS",
  PAPERWORK_GET_FAILURE: "PAPERWORK_GET_FAILURE",

  PAPERWORK_UPDATE_REQUEST: "PAPERWORK_UPDATE_REQUEST",
  PAPERWORK_UPDATE_SUCCESS: "PAPERWORK_UPDATE_SUCCESS",
  PAPERWORK_UPDATE_FAILURE: "PAPERWORK_UPDATE_FAILURE",

  PAPERWORK_SET_LOCAL: "PAPERWORK_SET_LOCAL",
  PAPERWORK_CLEAR: "PAPERWORK_CLEAR"
};
