import React from "react";
import { Link as RouterLink } from "react-router-dom";
// import Link from "@material-ui/core/Link";
import { connect, useSelector, useDispatch } from "react-redux";
import { withStyles } from "@material-ui/core/styles";

import { utilityActions } from "../_actions";

import { PageWithAppDrawer2 } from "../_components/main/PageWithAppDrawer2";
import ResponsiveGrid from "../_components/generic/ResponsiveGrid";

import FiltersGrid from "../_components/generic/FiltersGrid";
import { Paper, Button } from "@material-ui/core";
import { utilityService } from "../_services";
import { hashHistory } from "../_helpers";

class AdminTitleRackRatesPage2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    // this.props.dispatch(
    //   utilityActions.getUtility("clients", {
    //     perPage: 1000,
    //     filters: [
    //       {
    //         field: "clientactive",
    //         operator: "eq",
    //         value: "2"
    //       }
    //     ]
    //   })
    // );
  }

  render() {
    const { classes } = this.props;

    return (
      <PageWithAppDrawer2 pageTitle={"Rack Rates"}>
        <Paper className={classes.paper}>
          <Button
            color="primary"
            variant="contained"
            onClick={this.handleAddItem}
          >
            Add Rack Rate
          </Button>
          <TitleRackRatesTable />
        </Paper>
      </PageWithAppDrawer2>
    );
  }

  handleAddItem = () => {
    utilityService
      .addUtility("title_rack_rates", {
        chargename: "NEW RACK RATE",
        chargecost: 0,
        chargetype: 0,
        everyvehicle: 0,
        clientid: 0,
        chargebucket: 0,
      })
      .then((response) => {
        hashHistory.push(`/admin/titleRackRates/${response.id}`);
      });
  };
}

function TitleRackRatesTable(props) {
  const dispatch = useDispatch();
  const items = useSelector((state) => state.utility.title_rack_rates);
  const columns = [
    {
      width: 1,
      title: "Name",
      field: "chargename",
    },
    {
      width: 1,
      title: "Active",
      field: "charge_type.active",
      dataRender: (item) =>
        item.charge_type && item.charge_type.active === 1
          ? "Active"
          : "Inactive",
    },
    {
      width: 3,
      title: "Charge Type",
      field: "charge_type.vehiclechargetype",
      dataRender: (item) => (
        <RouterLink to={`/admin/titleRackRates/${item.id}`}>
          {item.charge_type ? item.charge_type.vehiclechargetype : "????"}
        </RouterLink>
      ),
    },
    {
      width: 1,
      title: "Bucket",
      field: "charge_type.chargebucketname",
    },
    {
      width: 1,
      title: "Cost",
      field: "chargecost",
    },
    {
      width: 1,
      title: "Every Vehicle",
      field: "everyvehicle",
      dataRender: (item) => (item.everyvehicle === 1 ? "Yes" : "No"),
    },
    {
      width: 4,
      title: "Title App Types",
      field: "titleapptype",
      dataRender: (item) => `?? ${item.titleapptype}`,
    },
  ];

  const [availableFilters, setAvailableFilters] = React.useState([
    // {
    //   name: "Name",
    //   field: "chargename",
    //   operator: "like",
    //   value: "",
    //   type: "text",
    //   default: ""
    // },
    // {
    //   name: "Charge Type",
    //   field: "chargetype",
    //   operator: "eq",
    //   value: "",
    //   type: "text",
    //   default: ""
    // }
  ]);

  const [getData, setGetData] = React.useState(false);

  const [page, setPage] = React.useState(
    items.page ? Number(items.page) - 1 : 0
  );
  const [perPage, setPerPage] = React.useState(
    items.per_page ? Number(items.per_page) : 50
  );
  const [total, setTotal] = React.useState(
    items.total ? Number(items.total) : 0
  );

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
    setGetData(true);
  };
  const handleChangeRowsPerPage = (event) => {
    const value = parseInt(event.target.value);
    setPerPage(value);
    setGetData(true);
  };
  React.useEffect(() => {
    if (getData === true) {
      setGetData(false);
      getNewData();
    }
  }, [getData]);

  React.useEffect(() => {
    setTotal(items.total ? Number(items.total) : 0);
    setPage(items.page ? Number(items.page) : 0);
    setPerPage(items.per_page ? Number(items.per_page) : 50);
  }, [items]);

  React.useEffect(() => {
    if (items.items.length === 0) {
      getNewData();
    }
  }, []);

  const handleDownload = () => {};

  const getNewData = () => {
    let newFilters = availableFilters
      .filter((filter) => {
        if (["", null].includes(filter.value)) return false;
        if (
          ["isnull", "notnull"].includes(filter.operator) &&
          filter.value === false
        )
          return false;
        return true;
      })
      .map((filter) => {
        return {
          field: filter.field,
          operator: filter.operator,
          value: filter.value,
        };
      });

    // DVS Rack rates all have clientid == 0
    newFilters.push({
      field: "clientid",
      operator: "eq",
      value: 0,
    });
    newFilters.push({
      field: "charge_type.vehiclechargetypeid",
      operator: "notnull",
      value: 0,
    });

    dispatch(
      utilityActions.getUtility("title_rack_rates", {
        page,
        perPage,
        filters: newFilters,
      })
    );
  };

  const handleFilterChange = (newFilter) => {
    const newFilters = availableFilters.map((filter) => {
      if (filter.field !== newFilter.field) return filter; // not the droid we're looking for, return unchanged
      filter.value = newFilter.value;
      return filter;
    });
    setAvailableFilters(newFilters);
    setPage(1);
    setGetData(true);
  };

  return (
    <>
      <FiltersGrid
        availableFilters={availableFilters}
        onChange={handleFilterChange}
      />
      <ResponsiveGrid
        loading={items.loading}
        columns={columns}
        data={items.items}
        page={page - 1} // API has a 1 based page count, Material UI has a zero based page count
        perPage={perPage}
        total={total}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        // showActions
        // onDownload={handleDownload}
      />
    </>
  );
}

const styles = (theme) => ({
  root: {
    display: "flex",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
});

function mapStateToProps(state) {
  const { authentication, utility } = state;
  const { user } = authentication;
  const { us_states } = utility;
  return {
    user,
    us_states,
  };
}

const connectedAdminTitleRackRatesPage2 = connect(mapStateToProps)(
  withStyles(styles)(AdminTitleRackRatesPage2)
);
export { connectedAdminTitleRackRatesPage2 as AdminTitleRackRatesPage2 };
