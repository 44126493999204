import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDropzone } from "react-dropzone";
import { makeStyles } from "@material-ui/core/styles";

import { PageWithAppDrawer2 } from "../_components/main/PageWithAppDrawer2";
import {
  AppBar,
  Button,
  CircularProgress,
  Grid,
  Paper,
  Snackbar,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@material-ui/core";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import { getEnvironmentURL, authHeader } from "../_helpers";
import MuiAlert from "@material-ui/lab/Alert";
import { useDispatch, useSelector } from "react-redux";
import FiltersGrid from "../_components/generic/FiltersGrid";
import ResponsiveGrid from "../_components/generic/ResponsiveGrid";
import { utilityActions } from "../_actions";
import moment from "moment";

export default function BisbeeReconcilePage() {
  const [tabIndex, setTabIndex] = useState(0);
  const [invoiceNumber, setInvoiceNumber] = useState("");
  const [invoiceDate, setInvoiceDate] = useState(null);
  const [processStep, setProcessStep] = useState("start");
  const [processingFile, setProcessingFile] = useState(false);
  const [responseData, setResponseData] = useState(null);
  const [snackbar, setSnackbar] = useState({
    open: false,
    severity: "info", //error, warning, info, success
    message: "default message",
    duration: 6000,
  });
  const classes = useStyles();

  useEffect(() => {
    console.log({ processStep });
  }, [processStep]);

  const showMessage = (message, severity = "info", duration = 6000) => {
    setSnackbar({
      open: true,
      severity,
      message,
      duration,
    });
  };

  const handleRejectFile = (file, errors) => {
    showMessage(file.path + " is not valid. " + errors[0].message, "error");
  };

  const handleAddFile = (file) => {
    let formData = new FormData();
    formData.append("file", file);
    formData.append("invoiceNumber", invoiceNumber);
    formData.append("invoiceDate", invoiceDate);
    setProcessingFile(true);
    setProcessStep("review");

    fetch(`${getEnvironmentURL()}/api/reports/management/bisbeeReconcile`, {
      // Your POST endpoint
      method: "POST",
      headers: { ...authHeader() },
      body: formData,
    })
      .then(
        (response) => response.json() // if the response is a JSON object
      )
      .then((data) => {
        // console.log("success", success); // Handle the success response object
        if (data.chargeNoMatch && data.moreThanOne) {
          setResponseData(data);
          showMessage("Data uploaded");
          setProcessStep("review");
        } else {
          showMessage("Unexpected data", "error");
        }
      })
      .catch((error) => {
        showMessage(error, "error"); // Handle the error response object
      })
      .finally(() => {
        setProcessingFile(false);
      });
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <PageWithAppDrawer2 pageTitle={"Bisbee Reconcile"}>
        <AppBar position="static">
          <Tabs
            value={tabIndex}
            onChange={(event, newValue) => setTabIndex(newValue)}
            aria-label="simple tabs example"
          >
            <Tab label="Upload Spreadsheet" />
            <Tab label="Bisbee Reconciliation Report" />
          </Tabs>
        </AppBar>
        {tabIndex === 0 && (
          <Paper className={classes.paper}>
            <BisbeeUpload />
          </Paper>
        )}
        {tabIndex === 1 && (
          <Paper className={classes.paper}>
            <BisbeeReport />
          </Paper>
        )}
      </PageWithAppDrawer2>
    </MuiPickersUtilsProvider>
  );
}

function BisbeeReport() {
  const dispatch = useDispatch();
  const items = useSelector((state) => state.utility.vehicle_charges);

  const [availableFilters, setAvailableFilters] = useState([
    {
      name: "Charge Date Start",
      field: "chargedate",
      operator: ">=",
      value: moment().format("YYYY-MM-DD"),
      type: "date",
      default: "",
    },
    {
      name: "Charge Date End",
      field: "chargedate",
      operator: "<=",
      value: moment().add(1, "days").format("YYYY-MM-DD"),
      type: "date",
      default: "",
    },
    {
      name: "Charge Type",
      field: "chargetype",
      operator: "eq",
      value: "63", // or 54? why are there two?
      type: "choice",
      useUtility: true,
      utility: "vehicle_charge_types",
      utilityValue: "vehiclechargetype",
      utilityOrder: {
        field: "vehiclechargetype",
        direction: "asc",
      },
      // utilityFilters: [
      //   {
      //     field: "clientactive",
      //     operator: "eq",
      //     value: 2,
      //   },
      // ],
      choices: [],
      default: "",
    },
  ]);

  const columns = [
    {
      width: 3,
      title: "VIN",
      field: "vehicle_base.vehiclevin",
    },
    {
      width: 3,
      title: "Charge Type",
      field: "type.vehiclechargetype",
    },
    {
      width: 3,
      title: "Amount",
      field: "chargeamt",
    },
    {
      width: 3,
      title: "Reconciliations",
      field: "reconciliation",
      dataRender: (item) => (
        <>
          {item.reconciliation.map((r) => (
            <div>
              {r.amount}, {r.reference} {r.date}
            </div>
          ))}
        </>
      ),
    },
  ];

  const [getData, setGetData] = useState(false);

  const [page, setPage] = useState(items.page ? Number(items.page) - 1 : 0);
  const [perPage, setPerPage] = useState(
    items.per_page ? Number(items.per_page) : 0
  );
  const [total, setTotal] = useState(items.total ? Number(items.total) : 0);

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
    setGetData(true);
  };
  const handleChangeRowsPerPage = (event) => {
    const value = parseInt(event.target.value);
    setPerPage(value);
    setGetData(true);
  };
  useEffect(() => {
    if (getData === true) {
      setGetData(false);
      getNewData();
    }
  }, [getData]);

  useEffect(() => {
    setTotal(items.total ? Number(items.total) : 0);
    setPage(items.page ? Number(items.page) : 0);
    setPerPage(items.per_page ? Number(items.per_page) : 10);
  }, [items]);

  useEffect(() => {
    if (items.items.length === 0) {
      getNewData();
    }
  }, []);

  const getNewData = (download = false) => {
    let newFilters = availableFilters
      .filter((filter) => {
        if ([null, false].includes(filter)) return false;
        if (["", null].includes(filter.value)) return false;
        if (
          ["isnull", "notnull"].includes(filter.operator) &&
          filter.value === false
        )
          return false;
        return true;
      })
      .map((filter) => {
        return {
          field: filter.field,
          operator: filter.operator,
          value: filter.value,
        };
      });

    dispatch(
      utilityActions.getUtility("vehicle_charges", {
        page,
        perPage,
        filters: newFilters,
        relations: ["vehicle_base", "type", "reconciliation"],
        // appends: ["cs_mileage", "cs_km", "has_speedo_image", "has_entry_photo"],
        // params: "withCount[]=images&withCount[]=vehicle_photos",
        orders: [{ field: "chargedate", direction: "desc" }],
      })
    );
  };

  const handleFilterChange = (newFilter) => {
    const newFilters = availableFilters.map((filter) => {
      if (filter.field !== newFilter.field) return filter; // not the droid we're looking for, return unchanged
      if (filter.name !== newFilter.name) return filter;
      filter.value = newFilter.value;
      return filter;
    });
    setAvailableFilters(newFilters);
    setPage(1);
    setGetData(true);
  };

  return (
    <>
      <Grid container spacing={1}>
        <Grid item>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              console.log("refresh");
            }}
          >
            Refresh
          </Button>
        </Grid>
      </Grid>

      <FiltersGrid
        availableFilters={availableFilters}
        onChange={handleFilterChange}
      />
      <ResponsiveGrid
        loading={items.loading}
        columns={columns}
        data={items.items}
        page={page - 1} // API has a 1 based page count, Material UI has a zero based page count
        perPage={perPage}
        total={total}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </>
  );
}

function BisbeeUpload() {
  const [invoiceNumber, setInvoiceNumber] = useState("");
  const [invoiceDate, setInvoiceDate] = useState(null);
  const [processStep, setProcessStep] = useState("start");
  const [processingFile, setProcessingFile] = useState(false);
  const [responseData, setResponseData] = useState(null);
  const [snackbar, setSnackbar] = useState({
    open: false,
    severity: "info", //error, warning, info, success
    message: "default message",
    duration: 6000,
  });

  const showMessage = (message, severity = "info", duration = 6000) => {
    setSnackbar({
      open: true,
      severity,
      message,
      duration,
    });
  };

  const handleRejectFile = (file, errors) => {
    showMessage(file.path + " is not valid. " + errors[0].message, "error");
  };

  const handleAddFile = (file) => {
    let formData = new FormData();
    formData.append("file", file);
    formData.append("invoiceNumber", invoiceNumber);
    formData.append("invoiceDate", invoiceDate);
    setProcessingFile(true);
    setProcessStep("review");

    fetch(`${getEnvironmentURL()}/api/reports/management/bisbeeReconcile`, {
      // Your POST endpoint
      method: "POST",
      headers: { ...authHeader() },
      body: formData,
    })
      .then(
        (response) => response.json() // if the response is a JSON object
      )
      .then((data) => {
        // console.log("success", success); // Handle the success response object
        if (data.chargeNoMatch && data.moreThanOne) {
          setResponseData(data);
          showMessage("Data uploaded");
          setProcessStep("review");
        } else {
          showMessage("Unexpected data", "error");
        }
      })
      .catch((error) => {
        showMessage(error, "error"); // Handle the error response object
      })
      .finally(() => {
        setProcessingFile(false);
      });
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      {processStep === "start" && (
        <Grid container>
          <Grid item xs={12}>
            <TextField
              label="Invoice Number"
              variant="outlined"
              value={invoiceNumber}
              onChange={(e) => setInvoiceNumber(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <KeyboardDatePicker
              margin="normal"
              name="invoiceDate"
              label="Invoice Date"
              format="MM/dd/yyyy"
              value={invoiceDate}
              onChange={(date, value) => setInvoiceDate(value)}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setProcessStep("upload")}
              disabled={
                invoiceNumber?.length < 1 ||
                invoiceDate?.length < 1 ||
                invoiceDate === null
              }
            >
              Next
            </Button>
          </Grid>
        </Grid>
      )}
      {processStep === "upload" && (
        <>
          <Grid container>
            <Grid item xs={12}>
              Invoice Number: {invoiceNumber}
            </Grid>
            <Grid item xs={12}>
              Invoice Date: {invoiceDate}
            </Grid>
          </Grid>

          <DragAndDrop
            onAddFile={handleAddFile}
            onRejectFile={handleRejectFile}
          />
        </>
      )}
      {processStep === "review" && (
        <>
          <Grid container>
            <Grid item xs={12}>
              Invoice Number: {invoiceNumber}
            </Grid>
            <Grid item xs={12}>
              Invoice Date: {invoiceDate}
            </Grid>
          </Grid>
          {processingFile && <CircularProgress />}
          {responseData && (
            <>
              <br />
              <Grid container spacing={2}>
                <Grid item xs={2}>
                  Vehicles Not Found ({responseData.notFound.length})
                </Grid>
                <Grid item xs={10}>
                  {responseData.notFound.map((v) => (
                    <Typography>{v}</Typography>
                  ))}
                </Grid>
                <Grid item xs={2}>
                  More than one Vehicle ({responseData.moreThanOne.length})
                </Grid>
                <Grid item xs={10}>
                  {responseData.moreThanOne.map((v) => (
                    <Typography>{v}</Typography>
                  ))}
                </Grid>
                <Grid item xs={2}>
                  Charges don't match ({responseData.chargeNoMatch.length})
                </Grid>
                <Grid item xs={10}>
                  {responseData.chargeNoMatch.map((v) => (
                    <Typography>{v}</Typography>
                  ))}
                </Grid>
                <Grid item xs={2}>
                  Reconciliation Created (
                  {responseData.reconciliationCreated.length})
                </Grid>
                <Grid item xs={10}>
                  {responseData.reconciliationCreated.map((v) => (
                    <Typography>{v}</Typography>
                  ))}
                </Grid>
                <Grid item xs={2}>
                  More than one reconciliation (
                  {responseData.moreThanOneReconciliation.length})
                </Grid>
                <Grid item xs={10}>
                  {responseData.moreThanOneReconciliation.map((v) => (
                    <Typography>{v}</Typography>
                  ))}
                </Grid>
              </Grid>
            </>
          )}
        </>
      )}
      <Snackbar
        color={snackbar.severity}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={snackbar.open}
        autoHideDuration={snackbar.duration}
        onClose={() =>
          setSnackbar({
            ...snackbar,
            open: false,
          })
        }
      >
        <MuiAlert severity={snackbar.severity} variant="filled">
          {snackbar.message}
        </MuiAlert>
      </Snackbar>
    </MuiPickersUtilsProvider>
  );
}

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
};

const activeStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

function DragAndDrop(props) {
  const onDrop = useCallback((acceptedFiles, fileRejections) => {
    acceptedFiles.forEach((file) => {
      props.onAddFile && props.onAddFile(file);
    });
    fileRejections.forEach((file) => {
      props.onRejectFile && props.onRejectFile(file.file, file.errors);
    });
  }, []);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({ onDrop, accept: [".csv", ".xlsx", ".xls"] });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  return (
    <div className="container">
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} />
        <p>
          Drag 'n' drop
          <br />
          csv, xls, xlsx
          <br />
          Or click to select files
        </p>
      </div>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
}));
