import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { BlobProvider, PDFViewer } from "@react-pdf/renderer";

import { getEnvironmentURL } from "../_helpers";

import { ClientLoadSheet } from "../_components/pdf/ClientLoadSheet";

import { utilityActions } from "../_actions";
import { CircularProgress } from "@material-ui/core";

export default function ClientLoadPrintPage() {
  const dispatch = useDispatch();
  let { id } = useParams();
  const load = useSelector((state) => state.utility.client_loads_item);
  const [loadId, setLoadId] = React.useState(null);
  const [loadLoading, setLoadLoading] = React.useState(true);

  useEffect(() => {
    console.log("mount effect");
  }, []);

  useEffect(() => {
    console.log("id effect", id);
    setLoadId(id);
  }, [id]);

  useEffect(() => {
    console.log("loadId effect", { loadId, id });
    if (loadId && loadId === id) {
      getNewData();
    }
  }, [loadId]);

  useEffect(() => {
    // console.log("load effect", load);
    setLoadLoading(load && load.loading === false ? false : true);
  }, [load]);

  const getNewData = () => {
    // console.log("getNewData");
    dispatch(
      utilityActions.getOneUtility("client_loads", loadId, {
        relations: [
          "vehicles",
          "client",
          "transporter",
          "from",
          "to",
          "pickup",
        ],
      })
    );
  };

  if (loadLoading) {
    return (
      <>
        <CircularProgress color="secondary" size={22} />
        Generating Load Sheet
      </>
    );
  }

  return (
    <PDFViewer
      style={{
        display: "block",
        width: "100%",
        height: "1000px",
        overflowY: "auto",
        frameborder: 0,
        marginheight: 0,
        marginwidth: 0,
        scrolling: "auto",
      }}
    >
      <ClientLoadSheet item={load} apiUrl={getEnvironmentURL()} />
    </PDFViewer>
  );

  // return (
  //   <BlobProvider
  //     document={<ClientLoadSheet item={load} apiUrl={getEnvironmentURL()} />}
  //   >
  //     {({ blob, url, loading, error }) => {
  //       if (!loadLoading && !loading && url) {
  //         window.location.href = url;
  //         window.open(url);
  //         return null;
  //       }
  //       return (
  //         <>
  //           <CircularProgress color="secondary" size={22} />
  //           Generating Load Sheet
  //         </>
  //       );
  //     }}
  //   </BlobProvider>
  // );
}
