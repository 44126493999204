import React, { useState, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
// import Link from "@material-ui/core/Link";
import { connect, useSelector, useDispatch } from "react-redux";
import { withStyles } from "@material-ui/core/styles";

import { utilityActions } from "../_actions";

import { PageWithAppDrawer2 } from "../_components/main/PageWithAppDrawer2";
import ResponsiveGrid from "../_components/generic/ResponsiveGrid";

import FiltersGrid from "../_components/generic/FiltersGrid";
import { Divider, Paper } from "@material-ui/core";
import moment from "moment";
import { utilityService } from "../_services";
import { downloadBlob, generateCSV } from "../_helpers";

class ClientReportPendingTitlesPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { classes } = this.props;

    return (
      <PageWithAppDrawer2 pageTitle={"Pending Titles"}>
        <Paper className={classes.paper}>
          <ClientPendingTitles />
        </Paper>
      </PageWithAppDrawer2>
    );
  }
}

function ClientPendingTitles(props) {
  const dispatch = useDispatch();
  const items = useSelector((state) => state.utility.vehicles_pending_titles);

  const webUser = useSelector((state) => state.authentication.webUser);
  const clientGroup = webUser.client_group || null;
  const clientsList = clientGroup
    ? clientGroup.clients_base.map((c) => c.clientid)
    : [webUser.client_id];
  const clientId = webUser.client_id;
  const isAdmin = webUser.roleid === 2 ? true : false;

  const columns = [
    {
      width: 2,
      title: "Client",
      field: "client.clientname",
      dataRender: (item) =>
        isAdmin ? (
          <RouterLink to={`/admin/clients`}>
            {item && item.client ? item.client.clientname : ""}
          </RouterLink>
        ) : (
          item && item.client && item.client.clientname
        ),
    },
    {
      width: 2,
      title: "Application Type",
      field: "application_type.titleapplicationtype",
    },
    {
      width: 3,
      title: "VIN",
      field: "vehiclevin",
      dataRender: (item) => (
        <RouterLink to={`/vehicles/${item.vehicleid}`}>
          {item.vehiclevin}
        </RouterLink>
      ),
    },
    {
      width: 1,
      title: "yr",
      field: "yr",
    },
    {
      width: 1,
      title: "make",
      field: "make",
    },
    {
      width: 1,
      title: "model",
      field: "model",
    },
    {
      width: 12,
      titleRender: () => <Divider />,
    },
    {
      width: 2,
      title: "Vehicle Status",
      field: "title_status.statusname",
    },
    {
      width: 2,
      title: "Process Type",
      field: "process_type_display",
    },
    {
      width: 2,
      title: "Process State",
      field: "process_state.stateabbreviation",
    },
    {
      width: 12,
      titleRender: () => <Divider />,
    },
    {
      width: 1,
      title: "Date Received",
      field: "recordcreateddate",
    },
    {
      width: 1,
      title: "Ownership",
      field: "titleregownershipdate",
    },
    {
      width: 1,
      title: "Scanned Ownership",
      field: "title_reg_ownership_scan_date",
    },
    {
      width: 1,
      title: "7501 Sent",
      field: "sent_7501_date",
    },
    {
      width: 1,
      title: "To State",
      field: "titlesenttobmvdate",
    },
    {
      width: 1,
      title: "Client Load",
      field: "client_load.name",
      dataRender: (item) => (item.client_load ? item.client_load.name : ""),
    },
    {
      width: 1,
      title: "Back from State",
      field: "titlebackfrombmvdate",
    },
    {
      width: 1,
      title: "To Customs",
      field: "submittedtocustomsdate",
    },
    {
      width: 1,
      title: "Crossed Border",
      field: "crossedborderdate",
    },
    {
      width: 1,
      title: "Confirmed Laded",
      field: "confirmedladeddate",
    },
    {
      width: 1,
      title: "DOT Submitted",
      field: "submitteddotdate",
    },
    {
      width: 1,
      title: "Vault Release",
      field: "vaultreleasedate",
    },
    {
      width: 1,
      title: "Bond Release",
      field: "bondreleasedate",
    },
    {
      width: 1,
      title: "White Copy",
      field: "state_white_copy_date",
    },
    {
      width: 1,
      title: "BKG Number",
      field: "bkg_entry_load_number",
    },
    { width: 12, titleRender: () => <Divider /> },
    { width: 3, title: "Auction", field: "auction.auctionname" },
    {
      width: 2,
      title: "Selling Auction",
      field: "auction_selling.auctionname",
    },
    {
      width: 2,
      title: "Selling Auction Lot",
      field: "auction_lot_id",
    },
    {
      width: 2,
      title: "Selling Auction Stock",
      field: "auction_stock_number",
    },

    { width: 12, titleRender: () => <Divider /> },
    { width: 12, title: "Last Note", field: "latest_note.note" },
  ];

  const [availableFilters, setAvailableFilters] = useState([
    {
      name: "Not Cancelled",
      field: "cancelled",
      operator: "neq",
      value: "1",
      type: "hidden",
      default: "",
    },
    {
      name: "Vehicle Status",
      field: "titlestatusid",
      operator: "notin",
      value: "5,6",
      type: "hidden",
      default: "",
    },
    {
      name: "Client",
      field: "clientid",
      operator: "in",
      value: clientsList.join(","),
      type: "hidden",
      default: "",
    },
    {
      name: "Sent To Auction",
      field: "titlesenttoauctiondate",
      operator: "isnull",
      value: 1,
      type: "hidden",
      default: "",
    },
    {
      name: "Client",
      field: "clientid",
      operator: "eq",
      value: "",
      type: "choice",
      useUtility: true,
      utility: "clients_active",
      utilityValue: "clientname",
      utilityOrder: {
        field: "clientname",
        direction: "asc",
      },
      utilityFilters: [
        {
          field: "clientactive",
          operator: "eq",
          value: 2,
        },
        !isAdmin && {
          field: "clientid",
          operator: "in",
          value: clientsList.join(","),
        },
      ],
      choices: [],
      default: "",
    },
    !isAdmin && clientGroup &&
    {
      name: "Client Group",
      field: "client.client_group_id",
      operator: "eq",
      value: clientGroup.id,
      type: "choice",
      choices: [
        {
          key: clientGroup.id,
          value: clientGroup.name,
        },
      ],
      default: "",
    },
  ]);

  const [getData, setGetData] = useState(false);

  const [page, setPage] = useState(items.page ? Number(items.page) - 1 : 0);
  const [perPage, setPerPage] = useState(
    items.per_page ? Number(items.per_page) : 0
  );
  const [total, setTotal] = useState(items.total ? Number(items.total) : 0);
  const [loading, setLoading] = useState(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
    setGetData(true);
  };
  const handleChangeRowsPerPage = (event) => {
    const value = parseInt(event.target.value);
    setPerPage(value);
    setGetData(true);
  };
  useEffect(() => {
    if (getData === true) {
      setGetData(false);
      getNewData();
    }
  }, [getData]);

  useEffect(() => {
    setTotal(items.total ? Number(items.total) : 0);
    setPage(items.page ? Number(items.page) : 0);
    setPerPage(items.per_page ? Number(items.per_page) : 10);
  }, [items]);

  useEffect(() => {
    if (items.items.length === 0) {
      getNewData();
    }
  }, []);

  const handleDownload = () => {
    getNewData(null, null, true);
  };

  const getNewData = async (page = null, perPage = null, download = false) => {
    let newFilters = availableFilters
      .filter((filter) => {
        if (["", null].includes(filter.value)) return false;
        if (
          ["isnull", "notnull"].includes(filter.operator) &&
          filter.value === false
        )
          return false;
        return true;
      })
      .map((filter) => {
        return {
          field: filter.field,
          operator: filter.operator,
          value: filter.value,
        };
      });

    const options = {
      filters: newFilters,
      relations: ["notes", "title_status"],
      orders: [{ field: "recordcreateddate", direction: "desc" }],
      perPage: perPage,
      page: page,
    };

    if (download) {
      setLoading(true);
      options.perPage = total + 1;
      await utilityService
        .getUtility("vehicles_pending_titles", options)
        .then((values) => {
          const downloadHeaders = columns
            .filter((item) => item.title)
            .map((item) => {
              if (!item.title) return null;
              return item.title;
            });
          const downloadFields = columns
            .filter((item) => item.field)
            .map((item) => {
              if (!item.field) return null;
              return item.field;
            });

          return generateCSV(downloadHeaders, downloadFields, values.data);
        })
        .then((csv) => {
          // console.log("csv", csv);
          const blob = new Blob([csv], { type: "text/csv" });
          downloadBlob(blob, `pending_titles.csv`);
        })
        .catch(console.error);
      setLoading(false);
      return;
    }

    dispatch(utilityActions.getUtility("vehicles_pending_titles", options));
  };

  const handleFilterChange = (newFilter) => {
    const newFilters = availableFilters.map((filter) => {
      if (filter.field !== newFilter.field) return filter; // not the droid we're looking for, return unchanged
      filter.value = newFilter.value;
      return filter;
    });
    setAvailableFilters(newFilters);
    setPage(1);
    setGetData(true);
  };

  return (
    <>
      <FiltersGrid
        availableFilters={availableFilters}
        onChange={handleFilterChange}
      />
      <ResponsiveGrid
        loading={items.loading || loading}
        columns={columns}
        data={items.items}
        page={page - 1} // API has a 1 based page count, Material UI has a zero based page count
        perPage={perPage}
        total={total}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        showActions
        onDownload={handleDownload}
      />
    </>
  );
}

const styles = (theme) => ({
  root: {
    display: "flex",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
});

function mapStateToProps(state) {
  const { authentication, utility } = state;
  const { user } = authentication;
  const { us_states } = utility;
  return {
    user,
    us_states,
  };
}

const connectedClientReportPendingTitlesPage = connect(mapStateToProps)(
  withStyles(styles)(ClientReportPendingTitlesPage)
);
export { connectedClientReportPendingTitlesPage as ClientReportPendingTitlesPage };
