import React, { useCallback, useMemo, useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { connect, useSelector, useDispatch } from "react-redux";
import { withStyles, makeStyles } from "@material-ui/core/styles";

import { utilityActions } from "../_actions";
import { getEnvironmentURL, authHeader } from "../_helpers";

import { PageWithAppDrawer2 } from "../_components/main/PageWithAppDrawer2";
import ResponsiveGrid from "../_components/generic/ResponsiveGrid";

import { utilityService } from "../_services";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

import MuiAlert from "@material-ui/lab/Alert";

import Select from "@material-ui/core/Select";
import {
  Button,
  Snackbar,
  Grid,
  Divider,
  MenuItem,
  CircularProgress,
  Checkbox,
} from "@material-ui/core";

class GstReportPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      snackbar: {
        open: false,
        severity: "info", //error, warning, info, success
        message: "default message",
        duration: 6000,
      },
    };
  }
  componentDidMount() {}

  static getDerivedStateFromProps(nextProps, prevState) {
    return null;
  }

  render() {
    const { classes } = this.props;
    const { data, snackbar } = this.state;
    return (
      <PageWithAppDrawer2 pageTitle={"GST Upload xls/csv"}>
        <Paper className={classes.paper}>
          <DragAndDrop
            onAddFile={this.handleAddFile}
            onRejectFile={this.handleRejectFile}
          />
          {data && <VehicleUpload data={data} />}
        </Paper>
        <Snackbar
          color={snackbar.severity}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          open={snackbar.open}
          autoHideDuration={snackbar.duration}
          onClose={() =>
            this.setState((prevState, props) => ({
              snackbar: {
                ...prevState.snackbar,
                open: false,
              },
            }))
          }
        >
          <MuiAlert severity={snackbar.severity} variant="filled">
            {snackbar.message}
          </MuiAlert>
        </Snackbar>
      </PageWithAppDrawer2>
    );
  }

  _showMessage = (message, severity = "info", duration = 6000) => {
    this.setState({
      snackbar: {
        open: true,
        severity,
        message,
        duration,
      },
    });
  };

  handleRejectFile = (file, errors) => {
    this._showMessage(
      file.path + " is not valid. " + errors[0].message,
      "error"
    );
  };

  handleAddFile = (file) => {
    let formData = new FormData();
    formData.append("file", file);

    fetch(`${getEnvironmentURL()}/api/reports/management/vehicleMassUpload`, {
      // Your POST endpoint
      method: "POST",
      headers: { ...authHeader() },
      body: formData,
    })
      .then(
        (response) => response.json() // if the response is a JSON object
      )
      .then((data) => {
        // console.log("success", success); // Handle the success response object
        if (Array.isArray(data)) {
          this.setState({ data });
          this._showMessage("Data uploaded");
        } else {
          this._showMessage("Unexpected data", "error");
        }
      })
      .catch((error) => {
        this._showMessage(error, "error"); // Handle the error response object
      });
  };
}

function VehicleUpload(props) {
  const dispatch = useDispatch();

  const clients_active = useSelector((state) => state.utility.clients_active);
  const taTypes = useSelector((state) => state.utility.title_application_types);
  // const webUser = useSelector((state) => state.authentication.webUser);

  const [data, setData] = useState(props.data);
  const [hasHeaders, setHasHeaders] = useState(true);

  const [dateColumn, setDateColumn] = useState(null);
  const [bgkColumn, setBgkColumn] = useState(null);
  const [supplierColumn, setSupplierColumn] = useState(null);
  const [vinColumn, setVinColumn] = useState(null);
  const [gstColumn, setGstColumn] = useState(null);

  const [loading, setLoading] = useState(false);
  const [dataProcessed, setDataProcessed] = useState(null);
  // const [clientId, setClientId] = useState(null);
  // const [taTypeId, setTaTypeId] = useState(null);

  const processColumns = [
    {
      width: 1,
      title: "Create",
      field: "",
      dataRender: (item) => (
        <Checkbox
          name={`${item.vin}[checked]`}
          value={1}
          checked={item.checked === true ? true : false}
          onChange={(e) => checkVin(item.vin, e.target.checked)}
        />
      ),
    },
    {
      width: 2,
      title: "VIN",
      field: "vin",
      dataRender: (item) => (
        <>
          <input type="hidden" name={`${item.vin}[vin]`} value={item.vin} />
          {item.vin}
        </>
      ),
    },
    {
      width: 1,
      title: "GST",
      field: "gst",
      dataRender: (item) => (
        <>
          <input type="hidden" name={`${item.vin}[gst]`} value={item.gst} />
          {item.gst}
        </>
      ),
    },
    {
      width: 2,
      title: "BGK",
      field: "bgk",
      dataRender: (item) => (
        <>
          <input type="hidden" name={`${item.vin}[bgk]`} value={item.bgk} />
          {item.bgk}
        </>
      ),
    },
    {
      width: 1,
      title: "Date",
      field: "date",
      dataRender: (item) => (
        <>
          <input type="hidden" name={`${item.vin}[date]`} value={item.date} />
          {item.date}
        </>
      ),
    },
    {
      width: 3,
      title: "Supplier",
      field: "supplier",
      dataRender: (item) => (
        <>
          <input
            type="hidden"
            name={`${item.vin}[supplier]`}
            value={item.supplier}
          />
          {item.supplier}
        </>
      ),
    },
  ];

  React.useEffect(() => {
    if (clients_active.items.length === 0) {
      dispatch(
        utilityActions.getUtility("clients_active", {
          perPage: 1000,
          filters: [
            {
              field: "clientactive",
              operator: "eq",
              value: "2",
            },
          ],
          orders: [{ field: "clientname", direction: "asc" }],
        })
      );
    }
    if (taTypes.items.length === 0) {
      dispatch(
        utilityActions.getUtility("title_application_types", {
          perPage: 1000,
          orders: [
            {
              field: "titleapplicationtype",
              direction: "asc",
            },
          ],
        })
      );
    }
  }, []);

  const checkVin = (vin, checked) => {
    setDataProcessed((oldData) =>
      oldData.map((d, index) => {
        if (d.vin !== vin) return d;
        return {
          ...d,
          checked,
        };
      })
    );
  };

  useEffect(() => {
    console.log("dataprocessed", dataProcessed);
  }, [dataProcessed]);

  useEffect(() => {
    setData(props.data);
  }, [props.data]);

  useEffect(() => {
    if (gstColumn !== null && gstColumn !== "") {
      checkVins();
    }
  }, [gstColumn]);

  const checkVins = () => {
    setLoading(true);

    const url = `api/reports/management/vehicleGstProcess`;

    utilityService
      .getEndpoint(url, "POST", {
        body: {
          dateColumn,
          bgkColumn,
          supplierColumn,
          vinColumn,
          gstColumn,
          hasHeaders,
          data,
        },
      })
      .then((response) => {
        console.log("response", response);
        if (Array.isArray(response)) {
          setDataProcessed(response);
        }
        setLoading(false);
      })
      .catch((e) => {
        console.warn("error", e.message);
        setDataProcessed(null);
        setLoading(false);
      });
  };

  // const getDocument = () => {
  //   setLoading(true);
  // };

  return (
    <Grid container>
      <Grid item xs={12}>
        First two rows of data:
        <Table>
          <TableBody>
            {data.slice(0, 2).map((row, index) => (
              <TableRow key={index}>
                {row.map((col, index2) => (
                  <TableCell key={index2}>
                    <b>[{index2}]</b> {col}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12} style={{ marginTop: 20 }}>
        <Select
          value={hasHeaders === true ? true : false}
          disabled={loading}
          onChange={(e) => {
            setHasHeaders(e.target.value);
          }}
        >
          <MenuItem value={true}>
            Has Headers (first row won't be processed)
          </MenuItem>
          <MenuItem value={false}>No Headers (all rows processed)</MenuItem>
        </Select>
      </Grid>
      <Grid item xs={12} style={{ marginTop: 20 }}>
        <Select
          value={dateColumn === null ? "" : dateColumn}
          disabled={loading}
          onChange={(e) => {
            setDateColumn(e.target.value);
          }}
          displayEmpty
        >
          <MenuItem value="">Select Column that contains Date</MenuItem>
          {data[0].map((col, index) => (
            <MenuItem key={index} value={index}>
              Column [{index}]: {col}
            </MenuItem>
          ))}
        </Select>
      </Grid>
      <Grid item xs={12} style={{ marginTop: 20 }}>
        <Select
          value={bgkColumn === null ? "" : bgkColumn}
          disabled={loading}
          onChange={(e) => {
            setBgkColumn(e.target.value);
          }}
          displayEmpty
        >
          <MenuItem value="">Select Column that contains BGK</MenuItem>
          {data[0].map((col, index) => (
            <MenuItem key={index} value={index}>
              Column [{index}]: {col}
            </MenuItem>
          ))}
        </Select>
      </Grid>
      <Grid item xs={12} style={{ marginTop: 20 }}>
        <Select
          value={supplierColumn === null ? "" : supplierColumn}
          disabled={loading}
          onChange={(e) => {
            setSupplierColumn(e.target.value);
          }}
          displayEmpty
        >
          <MenuItem value="">Select Column that contains Supplier</MenuItem>
          {data[0].map((col, index) => (
            <MenuItem key={index} value={index}>
              Column [{index}]: {col}
            </MenuItem>
          ))}
        </Select>
      </Grid>
      <Grid item xs={12} style={{ marginTop: 20 }}>
        <Select
          value={vinColumn === null ? "" : vinColumn}
          disabled={loading}
          onChange={(e) => {
            setVinColumn(e.target.value);
          }}
          displayEmpty
        >
          <MenuItem value="">Select Column that contains VIN</MenuItem>
          {data[0].map((col, index) => (
            <MenuItem key={index} value={index}>
              Column [{index}]: {col}
            </MenuItem>
          ))}
        </Select>
      </Grid>
      <Grid item xs={12} style={{ marginTop: 20 }}>
        <Select
          value={gstColumn === null ? "" : gstColumn}
          disabled={loading}
          onChange={(e) => {
            setGstColumn(e.target.value);
          }}
          displayEmpty
        >
          <MenuItem value="">Select Column that contains GST</MenuItem>
          {data[0].map((col, index) => (
            <MenuItem key={index} value={index}>
              Column [{index}]: {col}
            </MenuItem>
          ))}
        </Select>
      </Grid>
      <Grid item xs={12} style={{ marginTop: 10, marginBottom: 10 }} />
      <Grid item xs={12} style={{ marginTop: 20 }}>
        {loading && <CircularProgress />}
        {!loading && dataProcessed && (
          <>
            <form
              method="post"
              action={`${getEnvironmentURL()}/api/reports/management/vehicleGstCreate`}
              target="_blank"
            >
              <Button
                size="small"
                variant="contained"
                color="primary"
                type="submit"
              >
                Get GST Document
              </Button>
              <ResponsiveGrid
                data={dataProcessed}
                columns={processColumns}
                usePagination={false}
              />
            </form>
          </>
        )}
      </Grid>
    </Grid>
  );
}

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
};

const activeStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

function DragAndDrop(props) {
  const onDrop = useCallback((acceptedFiles, fileRejections) => {
    acceptedFiles.forEach((file) => {
      props.onAddFile && props.onAddFile(file);
    });
    fileRejections.forEach((file) => {
      props.onRejectFile && props.onRejectFile(file.file, file.errors);
    });
  }, []);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({ onDrop, accept: [".csv", ".xlsx", ".xls"] });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  return (
    <div className="container">
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} />
        <p>
          Drag 'n' drop
          <br />
          csv, xls, xlsx
          <br />
          Or click to select files
        </p>
      </div>
    </div>
  );
}

const styles = (theme) => ({
  root: {
    display: "flex",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
});

function mapStateToProps(state) {
  const { authentication, utility } = state;
  const { user } = authentication;
  const { us_states, auctions } = utility;
  return {
    user,
    auctions,
    us_states,
  };
}

const connectedGstReportPage = connect(mapStateToProps)(
  withStyles(styles)(GstReportPage)
);
export { connectedGstReportPage as GstReportPage };
