import {
  Button,
  Grid,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import LaunchTwoToneIcon from "@material-ui/icons/LaunchTwoTone";
import React, { useEffect, useState } from "react";
import ResponsiveGrid from "../_components/generic/ResponsiveGrid";
import { Link as RouterLink } from "react-router-dom";

import { PageWithAppDrawer2 } from "../_components/main/PageWithAppDrawer2";
import { utilityService } from "../_services";
import { useDispatch, useSelector } from "react-redux";
import FiltersGrid from "../_components/generic/FiltersGrid";

import { utilityActions } from "../_actions";
import { generateCSV, downloadBlob } from "../_helpers";
import moment from "moment";

export default function RiNhtsaAuditPage(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const items = useSelector((state) => state.utility.vehicles_dot_submitted);
  const [getData, setGetData] = useState(false);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(items.page ? Number(items.page) - 1 : 0);
  const [perPage, setPerPage] = useState(
    items.per_page ? Number(items.per_page) : 0
  );
  const [total, setTotal] = useState(items.total ? Number(items.total) : 0);

  const [availableFilters, setAvailableFilters] = useState([
    {
      name: "Start Date",
      field: "submitteddotdate",
      operator: ">=",
      value: moment().startOf("quarter").format("YYYY-MM-DD"),
      type: "date",
      default: "",
    },
    {
      name: "End Date",
      field: "submitteddotdate",
      operator: "<=",
      value: moment().endOf("quarter").format("YYYY-MM-DD"),
      type: "date",
      default: "",
    },
    {
      name: "Cancelled",
      field: "cancelled",
      operator: "neq",
      value: "1",
      type: "hidden",
      default: "",
    },
  ]);

  useEffect(() => {
    if (items.items.length === 0) {
      getNewData();
    }
  }, []);

  useEffect(() => {
    if (getData === true) {
      setGetData(false);
      getNewData();
    }
  }, [getData]);

  useEffect(() => {
    setTotal(items.total ? Number(items.total) : 0);
    setPage(items.page ? Number(items.page) : 0);
    setPerPage(items.per_page ? Number(items.per_page) : 10);
  }, [items]);

  const handleFilterChange = (newFilter, refresh = true) => {
    const newFilters = availableFilters.map((filter) => {
      if (filter.name !== newFilter.name) return filter; // not the droid we're looking for, return unchanged
      filter.value = newFilter.value;
      return filter;
    });
    setAvailableFilters(newFilters);
    if (refresh) {
      setPage(1);
      setGetData(true);
    }
  };

  const getNewData = (download = false) => {
    let newFilters = availableFilters
      .filter((filter) => {
        if (["", null].includes(filter.value)) return false;
        if (
          ["isnull", "notnull"].includes(filter.operator) &&
          filter.value === false
        )
          return false;
        return true;
      })
      .map((filter) => {
        return {
          field: filter.field,
          operator: filter.operator,
          value: filter.value,
        };
      });

    if (download) {
      let options = {
        filters: newFilters,
        perPage: perPage,
        page: page,
        params: "appends[]=nhtsa_conformance_location",
        relations: [
          // "client",
          "client_load_base",
          'title_status',
          'note_latest'
        ],
      };
      setLoading(true);
      options.page = 1;
      options.perPage = total + 1;
      utilityService
        .getUtility("vehicles_dot_submitted", options)
        .then((values) => {
          const downloadHeaders = downloadColumns
            .filter((item) => item.title)
            .map((item) => {
              if (!item.title) return null;
              return item.title;
            });
          const downloadFields = downloadColumns
            .filter((item) => item.field)
            .map((item) => {
              if (!item.field) return null;
              return item.field;
            });
        //   console.log('download', downloadHeaders, downloadFields, values.data);
          return generateCSV(downloadHeaders, downloadFields, values.data);
        })
        .then((csv) => {
          const blob = new Blob([csv], { type: "text/csv" });
          downloadBlob(blob, `vehicles_dot_submitted.csv`);
        })
        .catch(console.error)
        .finally(() => {
          setLoading(false);
        });
      return;
    }

    dispatch(
      utilityActions.getUtility("vehicles_dot_submitted", {
        page,
        perPage,
        filters: newFilters,
        params: "appends[]=nhtsa_conformance_location",
        // params: "with=none",
        relations:[
          // "client",
          "client_load_base",
          'title_status',
          'note_latest'
        ],
        orders: [{ field: "recordcreateddate", direction: "asc" }],
      })
    );
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
    setGetData(true);
  };
  const handleChangeRowsPerPage = (event) => {
    const value = parseInt(event.target.value);
    setPerPage(value);
    setGetData(true);
  };
  const handleDownload = () => {
    setLoading(true);
    getNewData(true);
  };

  return (
    <PageWithAppDrawer2 pageTitle={"RI NHTSA Audit Report"}>
      <Paper className={classes.paper}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="h5">Detailed Data</Typography>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                handleFilterChange(
                  {
                    name: "Start Date",
                    value: moment()
                      .subtract(1, "quarter")
                      .startOf("quarter")
                      .format("YYYY-MM-DD"),
                  },
                  false
                );
                handleFilterChange(
                  {
                    name: "End Date",
                    value: moment()
                      .subtract(1, "quarter")
                      .endOf("quarter")
                      .format("YYYY-MM-DD"),
                  },
                  false
                );
                setGetData(true);
              }}
            >
              Last Quarter
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                handleFilterChange(
                  {
                    name: "Start Date",
                    value: moment().startOf("quarter").format("YYYY-MM-DD"),
                  },
                  false
                );
                handleFilterChange(
                  {
                    name: "End Date",
                    value: moment().endOf("quarter").format("YYYY-MM-DD"),
                  },
                  false
                );
                setGetData(true);
              }}
            >
              This Quarter
            </Button>
          </Grid>
        </Grid>
        <FiltersGrid
          availableFilters={availableFilters}
          onChange={handleFilterChange}
        />
        <ResponsiveGrid
          loading={items.loading || loading}
          columns={columns}
          data={items.items}
          page={page - 1} // API has a 1 based page count, Material UI has a zero based page count
          perPage={perPage}
          total={total}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          showActions
          onDownload={handleDownload}
        />
      </Paper>
    </PageWithAppDrawer2>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
}));

const columns = [
  {
    width: 3,
    title: "VIN",
    field: "vehiclevin",
    dataRender: (item) => (
      <>
        <RouterLink to={`/vehicles/${item.vehicleid}`} target="_blank">
          {item.vehiclevin}
          <LaunchTwoToneIcon style={{ fontSize: 12 }} color="primary" />
        </RouterLink>
      </>
    ),
  },
  {
    width: 1,
    title: "Year",
    field: "yr",
  },
  {
    width: 2,
    title: "Make",
    field: "make",
  },
  {
    width: 2,
    title: "Model",
    field: "model",
  },
  {
    width: 2,
    title: "Vehicle Status",
    field: "title_status.statusname",
  },
  {
    width: 2,
    title: "Physical Location",
    field: "nhtsa_conformance_location.name",
  },
  {
    width: 2,
    title: "Date of Posession",
    field: "confirmedladeddate",
  },
  {
    width: 2,
    title: "DOT Submitted Date",
    field: "submitteddotdate",
  },
  {
    width: 2,
    title: "Vault Date",
    field: "vaultreleasedate",
  },
  {
    width: 2,
    title: "Bond Release Date",
    field: "bondreleasedate",
  },
  {
    width: 2,
    title: "Latest Note",
    field: "note_latest.note",
  },
  
];

const downloadColumns = [
  {
    width: 3,
    title: "VIN",
    field: "vehiclevin",
  },
  {
    width: 1,
    title: "Year",
    field: "yr",
  },
  {
    width: 2,
    title: "Make",
    field: "make",
  },
  {
    width: 2,
    title: "Model",
    field: "model",
  },
  {
    width: 2,
    title: "Vehicle Status",
    field: "title_status.statusname",
  },
  {
    width: 2,
    title: "Physical Location",
    field: "nhtsa_conformance_location.name",
  },
  {
    width: 2,
    title: "Date of Posession",
    field: "confirmedladeddate",
  },
  {
    width: 2,
    title: "DOT Submitted Date",
    field: "submitteddotdate",
  },
  {
    width: 2,
    title: "Vault Date",
    field: "vaultreleasedate",
  },
  {
    width: 2,
    title: "Bond Release Date",
    field: "bondreleasedate",
  },
  {
    width: 2,
    title: "Latest Note",
    field: "note_latest.note",
  },
];

export { RiNhtsaAuditPage };
