import React, { useEffect, useState } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";

import { Button, makeStyles } from "@material-ui/core";

import PageWithAppDrawer3 from "../_components/main/PageWithAppDrawer3";
import { hashHistory } from "../_helpers";
import FiltersGrid from "../_components/generic/FiltersGrid";
import ResponsiveGrid from "../_components/generic/ResponsiveGrid";
import { utilityService } from "../_services";
import { utilityActions } from "../_actions";

export function SvetnaDealersPage(props) {
  const classes = useDashboardPageStyles();
  const location = useLocation();
  const { refresh } = location.state || {};
  const webUser = useSelector((state) => state.authentication.webUser);

  const handleAddItem = () => {
    // console.log({user, webUser});
    utilityService
      .addUtility("svetna_dealers", {
        client_id: webUser.clientid,
        name: "New Svetna Dealer",
        is_buyer: false,
        is_seller: true,
      })
      .then((response) => {
        hashHistory.push(`/svetna/dealers/${response.id}`);
      });
  };

  return (
    <PageWithAppDrawer3 pageTitle={"Svetna Dealers"}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Button color="primary" variant="contained" onClick={handleAddItem}>
            Add Dealer
          </Button>
          <Paper className={classes.paper}>
            <SvetnaDealersTable refresh={refresh} />
          </Paper>
        </Grid>
      </Grid>
    </PageWithAppDrawer3>
  );
}

const useDashboardPageStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
}));

function SvetnaDealersTable(props) {
  const dispatch = useDispatch();
  const items = useSelector((state) => state.utility.svetna_dealers);
  const webUser = useSelector((state) => state.authentication.webUser);
  const { client } = webUser;

  useEffect(() => {
    if (props.refresh === true){
        setGetData(true);
    }
  }, [props])
  const columns = [
    {
      width: 3,
      title: "Name",
      field: "name",
      dataRender: (item) => (
        <RouterLink to={`/svetna/dealers/${item.id}`}>{item.name}</RouterLink>
      ),
    },
    {
      width: 2,
      title: "Is Buyer",
      field: "is_buyer",
      dataRender: (item) => (item.is_buyer ? "Yes" : "No"),
    },
    {
      width: 2,
      title: "Is Seller",
      field: "is_seller",
      dataRender: (item) => (item.is_seller ? "Yes" : "No"),
    },
  ];

  const [availableFilters, setAvailableFilters] = useState([
    {
      name: "Name",
      field: "name",
      operator: "like",
      value: "",
      type: "text",
      default: "",
    },
    {
      name: "Buyer",
      field: "is_buyer",
      operator: "eq",
      value: "",
      type: "choice",
      choices: [
        {
          key: "1",
          value: "Is Buyer",
        },
        {
          key: "0",
          value: "Not Buyer",
        },
      ],
      default: "",
    },
    {
      name: "Seller",
      field: "is_seller",
      operator: "eq",
      value: "",
      type: "choice",
      choices: [
        {
          key: "1",
          value: "Is Seller",
        },
        {
          key: "0",
          value: "Not Seller",
        },
      ],
      default: "",
    },
    {
      name: "Client",
      field: "client_id",
      operator: "eq",
      value: client.clientid,
      type: "hidden",
      default: "",
    },
  ]);

  const [getData, setGetData] = useState(false);

  const [page, setPage] = useState(items.page ? Number(items.page) - 1 : 0);
  const [perPage, setPerPage] = useState(
    items.per_page ? Number(items.per_page) : 0
  );
  const [total, setTotal] = useState(items.total ? Number(items.total) : 0);

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
    setGetData(true);
  };
  const handleChangeRowsPerPage = (event) => {
    const value = parseInt(event.target.value);
    setPerPage(value);
    setGetData(true);
  };
  const getNewData = (download = false) => {
    let newFilters = availableFilters
      .filter((filter) => {
        if (["", null].includes(filter.value)) return false;
        if (
          ["isnull", "notnull"].includes(filter.operator) &&
          filter.value === false
        )
          return false;
        return true;
      })
      .map((filter) => {
        return {
          field: filter.field,
          operator: filter.operator,
          value: filter.value,
        };
      });

    dispatch(
      utilityActions.getUtility("svetna_dealers", {
        page,
        perPage,
        filters: newFilters,
      })
    );
  };

  useEffect(() => {
    if (getData === true) {
      setGetData(false);
      getNewData();
    }
  }, [getData]);

  useEffect(() => {
    setTotal(items.total ? Number(items.total) : 0);
    setPage(items.page ? Number(items.page) : 0);
    setPerPage(items.per_page ? Number(items.per_page) : 10);
  }, [items]);

  useEffect(() => {
    if (items.items.length === 0) {
      getNewData();
    }
  }, []);

  const handleFilterChange = (newFilter) => {
    const newFilters = availableFilters.map((filter) => {
      if (filter.field !== newFilter.field) return filter; // not the droid we're looking for, return unchanged
      filter.value = newFilter.value;
      return filter;
    });
    setAvailableFilters(newFilters);
    setPage(1);
    setGetData(true);
  };

  return (
    <>
      <FiltersGrid
        availableFilters={availableFilters}
        onChange={handleFilterChange}
      />
      <div>
        <Button color="primary" variant="contained" onClick={getNewData}>
          Refresh List
        </Button>
      </div>
      <ResponsiveGrid
        loading={items.loading}
        columns={columns}
        data={items.items}
        page={page - 1} // API has a 1 based page count, Material UI has a zero based page count
        perPage={perPage}
        total={total}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        showActions
      />
    </>
  );
}

export { SvetnaDealersPage as default };
