import React, { useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment-timezone";

import { KeyboardDatePicker } from "@material-ui/pickers";

import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import ResponsiveGrid from "../generic/ResponsiveGrid";
import {
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  Select,
} from "@material-ui/core";
import ModalGeneric from "../generic/ModalGeneric";
import { utilityService } from "../../_services";
import VehicleDetailHeaderSummaryDisplay from "./VehicleDetailHeaderSummaryDisplay";

export default function VehicleDetailDates(props) {
  const vehicle = props.item;
  const [showDotTimers, setShowDotTimers] = useState(false);
  const [modalAddTimerOpen, setModalAddTimerOpen] = useState(false);
  const [modalAddTimerType, setModalAddTimerType] = useState(null);
  const [modalAddTimerDate, setModalAddTimerDate] = useState(null);
  const isAdmin = useSelector((state) =>
    state.authentication.webUser.roleid === 2 ? true : false
  );
  const titleStatuses = props.titleStatuses;

  if (!vehicle.vehiclevin) {
    return <div>Loading...</div>;
  }

  const addDotTimer = (timerType, timerDate) => {
    console.log({ timerType, timerDate });
    utilityService.addUtility("vehicle_timers", {
      vehicle_id: vehicle.id,
      category: "dot",
      type: timerType,
      date: timerDate,
    });
    setModalAddTimerDate(null);
    setModalAddTimerType(null);
  };

  return (
    <>
      <ModalGeneric
        open={modalAddTimerOpen}
        onClose={() => {
          addDotTimer(modalAddTimerType, modalAddTimerDate);
          setModalAddTimerOpen(false);
        }}
        onCancel={() => {
          setModalAddTimerOpen(false);
        }}
        closeText={
          modalAddTimerType === "stop"
            ? "Add DOT Stop Timer"
            : "Add DOT Start Timer"
        }
        cancelText="Cancel"
        title={
          modalAddTimerType === "stop"
            ? "Add DOT Stop Timer"
            : "Add DOT Start Timer"
        }
      >
        <KeyboardDatePicker
          margin="normal"
          disabled={!isAdmin}
          name="modalTimerDate"
          label={
            modalAddTimerType === "stop" ? "DOT Stop Timer" : "DOT Start Timer"
          }
          format="MM/dd/yyyy"
          value={modalAddTimerDate}
          onChange={(date, value) => setModalAddTimerDate(value)}
        />
        <Typography
          variant="body1"
          style={{ color: "red", fontWeight: "bold" }}
        >
          Save Vehicle Record after adjusting Timers
        </Typography>
      </ModalGeneric>
      <VehicleDetailHeaderSummaryDisplay
        vehicle={{
          vin: props?.item?.vehiclevin,
          year: props?.item?.yr,
          make: props?.item?.make,
          model: props?.item?.model,
        }}
        titleStatus={props?.item?.title_status?.statusname}
        timeInLatestStatus={props?.item?.time_in_latest_status}
        latestNote={props?.item?.latest_note?.note}
      />
      <TextField
        margin="dense"
        name={"entered_by_user"}
        label={"Entered by Person"}
        type="text"
        disabled={!isAdmin}
        value={vehicle.entered_by_user ? vehicle.entered_by_user.name : ""}
        readOnly
        style={{ width: "50%" }}
      />
      <TextField
        margin="dense"
        name={"recordcreateddate"}
        label={"Date entered into system"}
        type="text"
        value={
          vehicle.recordcreateddate
            ? moment(vehicle.recordcreateddate).format("M/D/Y")
            : ""
        }
        // onChange={handleChangeAuctionData}
        readOnly
        style={{ width: "50%" }}
      />
      <FormControl fullWidth>
        <InputLabel shrink>Vehicle Status</InputLabel>
        <Select
          native
          value={vehicle.titlestatusid || 1}
          onChange={props.onUpdateLocal}
          displayEmpty
          disabled={!isAdmin}
          name="titlestatusid"
        >
          {titleStatuses.map((item) => {
            return (
              <option key={item.statusid} value={item.statusid}>
                {item.statuscode} - {item.statusname}
              </option>
            );
          })}
        </Select>
      </FormControl>
      <br />
      <KeyboardDatePicker
        margin="normal"
        disabled={!isAdmin}
        name="repossessiondate"
        label="Repo/Sale Date"
        format="MM/dd/yyyy"
        value={vehicle.repossessiondate}
        onChange={(date, value) =>
          props.onUpdateLocalExplicit("repossessiondate", value)
        }
      />
      <KeyboardDatePicker
        margin="normal"
        disabled={!isAdmin}
        name="dateoflien"
        label="Date of Lien"
        format="MM/dd/yyyy"
        value={vehicle.dateoflien}
        onChange={(date, value) =>
          props.onUpdateLocalExplicit("dateoflien", value)
        }
      />
      <br />
      <KeyboardDatePicker
        margin="normal"
        disabled={!isAdmin}
        name="titleregownershipdate"
        label="Title Ownership Date"
        format="MM/dd/yyyy"
        value={
          vehicle.titleregownershipdate
            ? moment(vehicle.titleregownershipdate).format("M/D/Y")
            : null
        }
        onChange={(date, value) => {
          props.onUpdateLocalExplicit("titleregownershipdate", value);
        }}
      />
      <KeyboardDatePicker
        margin="normal"
        disabled={!isAdmin}
        name="title_reg_ownership_scan_date"
        label="Title Scan Ownership Date"
        format="MM/dd/yyyy"
        value={
          vehicle.title_reg_ownership_scan_date
            ? moment(vehicle.title_reg_ownership_scan_date).format("M/D/Y")
            : null
        }
        onChange={(date, value) => {
          props.onUpdateLocalExplicit("title_reg_ownership_scan_date", value);
        }}
      />
      <KeyboardDatePicker
        margin="normal"
        disabled={!isAdmin}
        name="scanned_7501_date"
        label="7501 Scan Date"
        format="MM/dd/yyyy"
        value={
          vehicle.scanned_7501_date
            ? moment(vehicle.scanned_7501_date).format("M/D/Y")
            : null
        }
        onChange={(date, value) => {
          props.onUpdateLocalExplicit("scanned_7501_date", value);
        }}
      />
      <KeyboardDatePicker
        margin="normal"
        disabled={!isAdmin}
        name="sent_7501_date"
        label="7501 Sent Date"
        format="MM/dd/yyyy"
        value={
          vehicle.sent_7501_date
            ? moment(vehicle.sent_7501_date).format("M/D/Y")
            : null
        }
        onChange={(date, value) => {
          props.onUpdateLocalExplicit("sent_7501_date", value);
        }}
      />
      <br />
      <KeyboardDatePicker
        margin="normal"
        disabled={!isAdmin}
        name="titlesenttobmvdate"
        label="Title sent to BMV Date"
        format="MM/dd/yyyy"
        value={
          vehicle.titlesenttobmvdate
            ? moment(vehicle.titlesenttobmvdate).format("M/D/Y")
            : null
        }
        onChange={(date, value) =>
          props.onUpdateLocalExplicit("titlesenttobmvdate", value)
        }
      />
      <KeyboardDatePicker
        margin="normal"
        disabled={!isAdmin}
        name="state_white_copy_date"
        label="Title White Copy Received"
        format="MM/dd/yyyy"
        value={
          vehicle.state_white_copy_date
            ? moment(vehicle.state_white_copy_date).format("M/D/Y")
            : null
        }
        onChange={(date, value) =>
          props.onUpdateLocalExplicit("state_white_copy_date", value)
        }
      />
      <KeyboardDatePicker
        margin="normal"
        disabled={!isAdmin}
        name="titlebackfrombmvdate"
        label="Title back from BMV Date"
        format="MM/dd/yyyy"
        value={
          vehicle.titlebackfrombmvdate
            ? moment(vehicle.titlebackfrombmvdate).format("M/D/Y")
            : null
        }
        onChange={(date, value) =>
          props.onUpdateLocalExplicit("titlebackfrombmvdate", value)
        }
      />
      <KeyboardDatePicker
        style={{ width: 400 }}
        margin="normal"
        disabled={!isAdmin}
        name="titlesenttoauctiondate"
        label="Title Sent to Client/Auction Date"
        format="MM/dd/yyyy"
        value={
          vehicle.titlesenttoauctiondate
            ? moment(vehicle.titlesenttoauctiondate).format("M/D/Y")
            : null
        }
        onChange={(date, value) =>
          props.onUpdateLocalExplicit("titlesenttoauctiondate", value)
        }
      />
      <br />
      <Button
        onClick={() => {
          setModalAddTimerType("stop");
          setModalAddTimerOpen(true);
        }}
        variant="contained"
        style={{ backgroundColor: "red" }}
        disabled={!isAdmin}
      >
        DOT Stop Timer
      </Button>{" "}
      <Button
        onClick={() => {
          setModalAddTimerType("start");
          setModalAddTimerOpen(true);
        }}
        variant="contained"
        style={{ backgroundColor: "green" }}
        disabled={!isAdmin}
      >
        DOT Resume Timer
      </Button>{" "}
      <Typography style={{ display: "inline" }}>
        DOT Timer Status: {!vehicle.dot_vault_date && "Standard 30 days"}
        {vehicle.dot_vault_date?.status === "stopped" && "Stopped"}
        {vehicle.dot_vault_date?.status === "extended" &&
          `Extended ${vehicle.dot_vault_date?.extended} days`}
      </Typography>{" "}
      <Button
        onClick={() => setShowDotTimers(!showDotTimers)}
        variant="contained"
        color="primary"
      >
        Toggle Timers
      </Button>
      {showDotTimers && (
        <div>
          {vehicle.dot_timers.map((timer) => (
            <div key={String(timer.id)}>
              {moment(timer.date).format("YYYY-MM-DD")} {timer.type}
            </div>
          ))}
        </div>
      )}
      <br />
      <KeyboardDatePicker
        margin="normal"
        disabled={!isAdmin}
        name="submittedtocustomsdate"
        label="Submitted to Customs Date"
        format="MM/dd/yyyy"
        value={
          vehicle.submittedtocustomsdate
            ? moment(vehicle.submittedtocustomsdate).format("M/D/Y")
            : null
        }
        onChange={(date, value) =>
          props.onUpdateLocalExplicit("submittedtocustomsdate", value)
        }
      />
      <KeyboardDatePicker
        margin="normal"
        disabled={!isAdmin}
        name="crossedborderdate"
        label="Crossed Border Date"
        format="MM/dd/yyyy"
        value={
          vehicle.crossedborderdate
            ? moment(vehicle.crossedborderdate).format("M/D/Y")
            : null
        }
        onChange={(date, value) => {
          props.onUpdateLocalExplicit("crossedborderdate", value);
          if (date === "Invalid Date" || date === null) {
            return;
          }
          props.onUpdateLocalExplicit("repossessiondate", value);
        }}
      />
      <KeyboardDatePicker
        margin="normal"
        disabled={!isAdmin}
        name="confirmedladeddate"
        label="Confirm Landed Date"
        format="MM/dd/yyyy"
        value={
          vehicle.confirmedladeddate
            ? moment(vehicle.confirmedladeddate).format("M/D/Y")
            : null
        }
        onChange={(date, value) =>
          props.onUpdateLocalExplicit("confirmedladeddate", value)
        }
      />
      <KeyboardDatePicker
        margin="normal"
        disabled={!isAdmin}
        name="dot_inspection_date"
        label="DOT Signoff Date"
        format="MM/dd/yyyy"
        value={
          vehicle.dot_inspection_date
            ? moment(vehicle.dot_inspection_date).format("M/D/Y")
            : null
        }
        onChange={(date, value) =>
          props.onUpdateLocalExplicit("dot_inspection_date", value)
        }
      />
      <FormGroup row>
        <FormControlLabel
          control={
            <Checkbox
              checked={Number(vehicle.is_dot_resubmit) === 1}
              onChange={(event) => {
                props.onUpdateLocalExplicit(
                  "is_dot_resubmit",
                  event.target.checked ? 1 : 0
                );
                if (event.target.checked) {
                  props.onUpdateLocalExplicit(
                    "dot_resubmit_date",
                    moment().format("Y-M-D")
                  );
                }
                else{
                  props.onUpdateLocalExplicit(
                    "dot_resubmit_date",
                    null
                  );
                }
              }}
              name="is_dot_resubmit"
            />
          }
          label="DOT Resub"
        />
      </FormGroup>
      <KeyboardDatePicker
        margin="normal"
        disabled={!isAdmin}
        name="submitteddotdate"
        label="DOT Submitted Date"
        format="MM/dd/yyyy"
        value={
          vehicle.submitteddotdate
            ? moment(vehicle.submitteddotdate).format("M/D/Y")
            : null
        }
        onChange={(date, value) => {
          props.onUpdateLocalExplicit(
            "submitteddotdate",
            value // newDate.format("Y-M-D")
          );
          if (date == "Invalid Date" || date === null) {
            return;
          }
          const newDate = moment(value, "M/D/Y");
          props.onUpdateLocalExplicit(
            "vaultreleasedate",
            newDate.add(30, "days").format("Y-M-D")
          );

          const newDate2 = moment(value, "M/D/Y");
          let daysToAdd = 21;
          if (
            vehicle.client &&
            vehicle.client.defaults &&
            vehicle.client.defaults.vehicle_release_days
          ) {
            daysToAdd = vehicle.client.defaults.vehicle_release_days;
          }
          props.onUpdateLocalExplicit(
            "vehicleinspectiondate",
            newDate2.add(daysToAdd, "days").format("Y-M-D")
          );
        }}
      />
      <KeyboardDatePicker
        margin="normal"
        disabled={!isAdmin}
        name="dot_resubmit_date"
        label="DOT Resub Date"
        format="MM/dd/yyyy"
        value={
          vehicle.dot_resubmit_date
            ? moment(vehicle.dot_resubmit_date).format("M/D/Y")
            : null
        }
        onChange={(date, value) => {
          props.onUpdateLocalExplicit(
            "dot_resubmit_date",
            value // newDate.format("Y-M-D")
          );
          if (date == "Invalid Date" || date === null) {
            return;
          }
        }}
      />
      <br />
      <KeyboardDatePicker
        margin="normal"
        disabled={!isAdmin}
        name="vehicleinspectiondate"
        label="Final Inspection Date"
        format="MM/dd/yyyy"
        value={
          vehicle.vehicleinspectiondate
            ? moment(vehicle.vehicleinspectiondate).format("M/D/Y")
            : null
        }
        onChange={(date, value) =>
          props.onUpdateLocalExplicit("vehicleinspectiondate", value)
        }
      />
      <KeyboardDatePicker
        margin="normal"
        disabled={!isAdmin}
        name="vaultreleasedate"
        label="Vault Release Date"
        format="MM/dd/yyyy"
        value={
          vehicle.vaultreleasedate
            ? moment(vehicle.vaultreleasedate).format("M/D/Y")
            : null
        }
        onChange={(date, value) =>
          props.onUpdateLocalExplicit("vaultreleasedate", value)
        }
      />
      <KeyboardDatePicker
        margin="normal"
        disabled={!isAdmin}
        name="bondreleasedate"
        label="Bond Release Date"
        format="MM/dd/yyyy"
        value={
          vehicle.bondreleasedate
            ? moment(vehicle.bondreleasedate).format("M/D/Y")
            : null
        }
        onChange={(date, value) =>
          props.onUpdateLocalExplicit("bondreleasedate", value)
        }
      />
      <br />
      <KeyboardDatePicker
        margin="normal"
        disabled={!isAdmin}
        name="speedo_pulled_date"
        label="Speedo Pulled Date"
        format="MM/dd/yyyy"
        value={
          vehicle.speedo_pulled_date
            ? moment(vehicle.speedo_pulled_date).format("M/D/Y")
            : null
        }
        onChange={(date, value) =>
          props.onUpdateLocalExplicit("speedo_pulled_date", value)
        }
      />
      <KeyboardDatePicker
        margin="normal"
        disabled={!isAdmin}
        name="speedo_shipped_date"
        label="Speedo Shipped Date"
        format="MM/dd/yyyy"
        value={
          vehicle.speedo_shipped_date
            ? moment(vehicle.speedo_shipped_date).format("M/D/Y")
            : null
        }
        onChange={(date, value) =>
          props.onUpdateLocalExplicit("speedo_shipped_date", value)
        }
      />
      <KeyboardDatePicker
        margin="normal"
        disabled={!isAdmin}
        name="speedo_received_date"
        label="Speedo Received Date"
        format="MM/dd/yyyy"
        value={
          vehicle.speedo_received_date
            ? moment(vehicle.speedo_received_date).format("M/D/Y")
            : null
        }
        onChange={(date, value) =>
          props.onUpdateLocalExplicit("speedo_received_date", value)
        }
      />
      <KeyboardDatePicker
        margin="normal"
        disabled={!isAdmin}
        name="speedo_placed_date"
        label="Speedo Placed Date"
        format="MM/dd/yyyy"
        value={
          vehicle.speedo_placed_date
            ? moment(vehicle.speedo_placed_date).format("M/D/Y")
            : null
        }
        onChange={(date, value) =>
          props.onUpdateLocalExplicit("speedo_placed_date", value)
        }
      />
      <Divider />
      <Typography variant="h6" align="center">
        Notes
      </Typography>
      <ResponsiveGrid
        usePagination={false}
        columns={[
          {
            title: "Date",
            width: 4,
            field: "notedate",
            dataRender: (item) => moment(item.notedate).format("M/D/Y h:mm:ss"),
          },
          { title: "Note", width: 4, field: "note" },
          { title: "User", width: 4, field: "user.name" },
        ]}
        data={vehicle.notes}
      />
      <Divider />
      <Typography variant="h6" align="center">
        Activity
      </Typography>
      <ResponsiveGrid
        usePagination={false}
        columns={[
          {
            title: "Date",
            width: 4,
            field: "activitytimedate",
            dataRender: (item) =>
              moment(item.activitytimedate).format("M/D/Y h:mm:ss"),
          },
          { title: "Activity", width: 4, field: "type.activitytype" },
          { title: "User", width: 4, field: "user.name" },
        ]}
        data={vehicle.activity}
      />
    </>
  );
}
