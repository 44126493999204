import React from "react";
import { useTheme } from "@material-ui/core/styles";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Box } from "@material-ui/core";

export default function RegisteredImportationDialog(props) {
  const [open, setOpen] = React.useState(props.open || false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));

  // const handleClickOpen = () => {
  //   setOpen(true);
  // };

  const handleClose = () => {
    setOpen(false);
    props.onClose();
  };

  React.useEffect(() => {
    setOpen(props.open);
  }, [props.open]);

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogActions>
        <Button autoFocus onClick={handleClose} color="primary">
          Close
        </Button>
      </DialogActions>
      <DialogTitle id="responsive-dialog-title">
        {"Registered Importation"}
      </DialogTitle>

      <DialogContent>
        <DialogContentText>
          DVS is the dominant importer of used Canadian vehicles into the United
          States. The combination of our in-house title processing, remote
          filing capability for U.S. Customs and our transportation management
          system makes DVS the “One Stop Shop” for the importation process, and
          the only economically viable option for large car manufacturers that
          wish to import pre-owned cars from Canada to the U.S.
          <br />
          <br />
          Over the past 10-years, DVS has imported over 100,000 used vehicles
          from Canada. We are the preferred provider to a number of automobile
          manufacturers. The efficiency of our operation allows DVS to have the
          vehicles processed, titled for sale in the U.S. in 4-weeks time. We
          manage the process from beginning to end.
          <br />
          <br />
          DVS has all the appropriate license, bonding and clearance to provide
          the most time-effective and cost-efficient service in the industry:
          <br />
          <br />
          <Box style={{ paddingLeft: 20 }}>
            National Highway Traffic and Safety Administration <br />
            U S Customs and Border Protection Homeland Security <br />
            FAST approved Transportation Carrier Referrals <br />
            Indiana Bureau of Motor Vehicles <br />
          </Box>
          <br />
          <br />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
