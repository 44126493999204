import React, { useState, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { Typography } from "@material-ui/core";
import { utilityActions } from "../../_actions";
import ResponsiveGrid from "../generic/ResponsiveGrid";

import { formatNumberToStringMoney } from "../../_helpers";

export default function ClientOverrides(props) {
  const dispatch = useDispatch();
  const overrides = useSelector((state) => state.utility.rack_rate_overrides);
  const defaults = useSelector((state) => state.utility.rack_rates);

  const [clientId, setClientId] = useState(props.clientId || null);
  const [overrideIds, setOverrideIds] = useState([]);

  const getRackRateName = (item) => {
    // console.log("item", item);
    // console.log("overrideIds", overrideIds);
    return overrideIds.includes(Number(item.id)) ? (
      <Typography style={{ color: "grey" }}>{item.name}</Typography>
    ) : (
      <Typography>{item.name}</Typography>
    );
  };

  const defaultColumns = [
    {
      width: 2,
      title: "Rack Rate",
      field: "name",
      dataRender: (item) => getRackRateName(item),
    },
    {
      width: 2,
      title: "Charge Amount",
      field: "charge_amount",
      dataRender: (item) => formatNumberToStringMoney(item.charge_amount),
    },
    {
      width: 1,
      title: "Every Vehicle",
      field: "every_vehicle",
      dataRender: (item) => (item.every_vehicle ? "Yes" : "No"),
    },
  ];

  useEffect(() => {
    setClientId(props.clientId);
    dispatch(
      utilityActions.getUtility("rack_rate_overrides", {
        perPage: 1000,
        filters: [{ field: "clientid", operator: "eq", value: clientId }],
        relations: ["rack_rate", "client"],
      })
    );
    dispatch(
      utilityActions.getUtility("rack_rates", {
        perPage: 1000,
        orders: [{ field: "name", direction: "asc" }],
      })
    );
  }, [clientId]);

  useEffect(() => {
    setOverrideIds(
      overrides.items.map((obj) => {
        return obj.rack_rate_id;
      })
    );
  }, [overrides]);

  // console.log("overrideIds", overrideIds);

  return (
    <>
      <Typography>Overrides</Typography>
      {overrides.items.length === 0 ? (
        "No overrides"
      ) : (
        <ResponsiveGrid
          data={overrides.items}
          columns={columns}
          usePagination={false}
        />
      )}
      <Typography>Default Rack Rates</Typography>
      {defaults.items.length === 0 ? (
        "No default rack rates"
      ) : (
        <ResponsiveGrid
          data={defaults.items}
          columns={defaultColumns}
          usePagination={false}
        />
      )}
    </>
  );
}

const columns = [
  {
    width: 2,
    title: "Rack Rate",
    field: "rack_rate.name",
  },
  {
    width: 2,
    title: "Client",
    field: "client.clientname",
  },
  {
    width: 1,
    title: "Override Amount",
    field: "charge_amount",
    dataRender: (item) => formatNumberToStringMoney(item.charge_amount),
  },
  {
    width: 1,
    title: "Default Amount",
    field: "rack_rate.charge_amount",
    dataRender: (item) =>
      formatNumberToStringMoney(item.rack_rate.charge_amount),
  },
  {
    width: 1,
    title: "Every Vehicle",
    field: "every_vehicle",
    dataRender: (item) => (item.every_vehicle ? "Yes" : "No"),
  },
  {
    width: 1,
    title: "Edit",
    field: "",
    dataRender: (item) => (
      <RouterLink to={`/admin/rackRates/overrides/${item.id}`}>Edit</RouterLink>
    ),
  },
];
