import { useEffect, useState } from "react";

export default function useCustomerDNS() {
  const [customerName, setCustomerName] = useState('dvs');
  useEffect(() => {
    if (window.location.hostname.includes("svetna")){
        setCustomerName('svetna');
    }
    else{
        setCustomerName('dvs');
    }
  }, [window.location.hostname]);
  return customerName;
}
