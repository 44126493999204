import React, { useState, useEffect } from "react";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useDispatch, useSelector } from "react-redux";

import { utilityActions } from "../../_actions";

import ResponsiveGrid from "../generic/ResponsiveGrid";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Checkbox } from "@material-ui/core";

export default function ModalPrintPaperwork(props) {
  const dispatch = useDispatch();
  const pdfForms = useSelector((state) => state.utility.pdf_forms);
  const url = props.url;
  // const [vehicleIds, setVehicleIds] = useState(props.vehicleIds);

  const [open, setOpen] = useState(props.open || false);
  const [selectedDocuments, setSelectedDocuments] = useState([]);

  useEffect(() => {
    setOpen(props.open);
    setSelectedDocuments([]);
  }, [props.open]);

  useEffect(() => {
    // setVehicleIds(props.vehicleIds);
  }, [props.vehicleIds]);

  useEffect(() => {
    if (pdfForms.items.length === 0) {
      dispatch(
        utilityActions.getUtility("pdf_forms", {
          perPage: 1000,
          orders: [
            {
              field: "name",
              direction: "asc",
            },
          ],
        })
      );
    }
  }, []);

  const checkDocument = (docId, checked) => {
    if (!checked) {
      setSelectedDocuments((selectedDocuments) => [
        ...selectedDocuments.filter((item) => item != docId),
      ]);
    } else {
      setSelectedDocuments((selectedDocuments) => [
        ...selectedDocuments,
        docId,
      ]);
    }
  };

  const columns = [
    {
      width: 2,
      title: "Selected",
      field: null,
      dataRender: (item) => {
        return (
          <>
            <Checkbox
              onChange={(event) => checkDocument(item.id, event.target.checked)}
            />
          </>
        );
      },
    },
    {
      width: 10,
      title: "Document Name",
      field: "name",
    },
  ];

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));

  const openPdf = () => {
    const newUrl = url + "?docs=" + selectedDocuments.join(",");
    console.log("newUrl", newUrl);
    window.open(newUrl, "_blank");
    handleClose();
  };

  const handleClose = () => {
    setOpen(false);
    if (props.onClose) props.onClose();
  };

  return (
    <Dialog open={open} fullScreen={fullScreen}>
      <DialogTitle id="form-dialog-title" style={{ width: 600 }}>
        Print Documents
      </DialogTitle>
      <DialogContent>
        <ResponsiveGrid
          loading={pdfForms.loading}
          data={pdfForms.items}
          columns={columns}
          usePagination={false}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={openPdf} color="primary">
          Print
        </Button>
      </DialogActions>
    </Dialog>
  );
}
