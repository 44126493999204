import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";

import { withStyles } from "@material-ui/core/styles";
import withWidth from "@material-ui/core/withWidth";

import { utilityActions } from "../_actions";

import { PageWithAppDrawer2 } from "../_components/main/PageWithAppDrawer2";
import ResponsiveGrid from "../_components/generic/ResponsiveGrid";
import FiltersGrid from "../_components/generic/FiltersGrid";

import {
  Paper,
  Divider,
  Typography,
} from "@material-ui/core";

class VehicleNotesPage extends React.Component {
  constructor(props) {
    super(props);
    const { type: noteType } = this.props.match ? this.props.match.params : {};
    this.state = {
      loading: true,
      reload: false,
      noteType,
      filters: [],
      availableFilters: [],
      orders: [],
      relations: [],
      data: [],
      columns: [],
      page: 1,
      perPage: 10,
      total: 0,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { type: noteType } = nextProps.match ? nextProps.match.params : {};
    if (noteType !== prevState.noteType) {
      return {
        ...prevState,
        noteType,
        reload: true,
      };
    }
    return false;
  }

  async componentDidMount() {
    const { noteType } = this.state;
    let columns = [];
    let utility = null;
    let availableFilters = [];
    let relations = [];
    let orders = [];

    if (noteType === "incomplete") utility = "vehicle_notes_open";
    else utility = "vehicle_notes";

    orders.push({
      field: "noteid",
      direction: "desc",
    });

    columns = [
      {
        width: 2,
        title: "Date",
        field: "notedate",
        dataRender: (item) => moment(new Date(item.notedate)).fromNow(),
      },
      {
        width: 3,
        title: "VIN / Client",
        field: "vehicle.vehiclevin",
        dataRender: (item) => (
          <>
            <RouterLink to={`/vehicles/${item.vehicleid}`}>
              {item.vehicle && item.vehicle.vehiclevin}
            </RouterLink>
            <br />
            <RouterLink
              to={`/admin/clients/${item.vehicle && item.vehicle.clientid}`}
            >
              {item.vehicle && item.vehicle.client.clientname}
            </RouterLink>
          </>
        ),
      },
      {
        width: 2,
        title: "User",
        field: "user.name",
      },
      {
        width: 2,
        title: "Completed",
        field: "user.name",
        dataRender: (item) => {
          return item.complete === false ? (
            <Typography
              style={{
                color: "blue",
                fontSize: 12,
                textDecoration: "underline",
              }}
              onClick={() => this.handleMarkComplete(item.id)}
            >
              Mark Complete
            </Typography>
          ) : (
            "Completed"
          );
        },
      },
      {
        width: 2,
        title: "Vehicle Status",
        field: "vehicle.title_status.statusname",
        dataRender: (item) =>
          item.vehicle && item.vehicle.title_status
            ? item.vehicle.title_status.statusname
            : "Unknown",
      },
      {
        width: 12,
        title: "Note",
        field: "note",
        dataRender: (item) => (
          <pre style={{ backgroundColor: "#eee", padding: 3 }}>{item.note}</pre>
        ),
      },
    ];

    availableFilters.push({
      name: "Vehicle Status",
      field: "vehicle.titlestatusid",
      operator: "eq",
      value: "",
      type: "choice",
      useUtility: true,
      utility: "title_statuses",
      utilityValue: "statusname",
      choices: [],
      default: "",
    });

    if (noteType !== "incomplete") {
      availableFilters.push({
        name: "Complete",
        field: "complete",
        operator: "eq",
        value: "",
        type: "choice",
        choices: [
          {
            key: "",
            value: "All Notes",
          },
          {
            key: "0",
            value: "Incomplete Notes",
          },
          {
            key: "1",
            value: "Completed Notes",
          },
        ],
        default: "",
      });
    }

    this.setState(
      { relations, orders, utility, columns, availableFilters },
      () => this.getNewData()
    );
  }

  render() {
    const {
      noteType,
      loading,
      reload,
      data,
      columns,
      filters,
      availableFilters,
      page,
      perPage,
      total,
    } = this.state;

    const { classes } = this.props;

    if (reload === true) {
      this.setState({ reload: false }, () => {
        this.componentDidMount();
      });
    }

    const title =
      noteType === "incomplete" ? "Incomplete Notes" : "Vehicle Notes";

    // console.log("data", data);

    return (
      <PageWithAppDrawer2 pageTitle={title}>
        <Paper className={classes.paper}>
          <FiltersGrid
            classes={this.props.classes}
            availableFilters={availableFilters}
            filters={filters}
            onChange={this.handleFilterChange}
            clientList={this.props.utility.clients_active.items}
            typeList={this.props.utility.title_application_types.items}
            statusList={this.props.utility.title_statuses.items}
          />
          <br />
          <Divider />
          <ResponsiveGrid
            loading={loading}
            columns={columns}
            data={data}
            page={page - 1} // API has a 1 based page count, Material UI has a zero based page count
            perPage={perPage}
            total={total}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
            // showActions
            // onDownload={this.handleDownload}
          />
        </Paper>
      </PageWithAppDrawer2>
    );
  }

  handleMarkComplete = (id) => {
    this.props.dispatch(
      utilityActions.updateUtility(
        "vehicle_notes_open",
        id,
        { complete: true },
        () => {
          this.setState((prevState) => {
            return {
              data: prevState.data.map((item) => {
                return {
                  ...item,
                  complete:
                    item.complete === false && item.id === id
                      ? true
                      : item.complete,
                };
              }),
            };
          });
        }
      )
    );
  };

  handleChangePage = (event, newPage) => {
    // console.log("handleChangePage", newPage + 1);
    this.setState({ page: newPage + 1 }, () => this.getNewData());
  };

  handleChangeRowsPerPage = (event) => {
    const value = parseInt(event.target.value);
    this.setState(
      {
        perPage: value,
        page: 1,
      },
      () => this.getNewData()
    );
  };

  handleFilterChange = (newFilter) => {
    let availableFilters = this.state.availableFilters;
    if (newFilter.name) {
      availableFilters = this.state.availableFilters.map((filter) => {
        if (filter.name !== newFilter.name) return filter; // not the droid we're looking for, return unchanged
        filter.value = newFilter.value;
        return filter;
      });
    } else if (newFilter.field) {
      availableFilters = this.state.availableFilters.map((filter) => {
        if (filter.field !== newFilter.field) return filter; // not the droid we're looking for, return unchanged
        filter.value = newFilter.value;
        return filter;
      });
    }

    this.setState(
      {
        availableFilters,
        page: 1,
      },
      () => this.getNewData()
    );
  };

  getNewData = async (page = null, perPage = null, download = false) => {
    const { utility, relations, orders, availableFilters } = this.state;
    if (!perPage) perPage = this.state.perPage;
    if (!page) page = this.state.page;

    if (download !== false) download = true;
    if (!download) this.setState({ loading: true });

    // translate available filters into service filters
    let newFilters = availableFilters
      .filter((filter) => {
        if (["", null].includes(filter.value)) return false;
        if (
          ["isnull", "notnull"].includes(filter.operator) &&
          filter.value === false
        )
          return false;
        return true;
      })
      .map((filter) => {
        return {
          field: filter.field,
          operator: filter.operator,
          value: filter.value,
        };
      });

    if (utility === "vehicle_notes_open") {
      newFilters.push({
        field: "complete",
        operator: "eq",
        value: 0,
      });
      newFilters.push({
        field: "roleid",
        operator: "eq",
        value: 1,
      });
    }

    let options = {
      filters: newFilters,
      relations: relations,
      orders: orders,
      perPage: perPage,
      page: page,
    };

    console.log("utility", utility);

    await this.props.dispatch(
      utilityActions.getUtility(utility, options, () => {
        const {
          items,
          page,
          per_page: perPage,
          total,
        } = this.props.utility[utility];
        this.setState({
          loading: false,
          data: items,
          page,
          perPage,
          total,
        });
      })
    );
  };
}

const styles = (theme) => ({
  root: {
    display: "flex",
    fontSize: 12,
  },
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    width: "100%",
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    width: "100%",
  },
  fixedHeight: {
    height: 240,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },

  Button: {
    display: "block",
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
});

function mapStateToProps(state) {
  const { authentication, sites, utility } = state;
  const { user } = authentication;
  return {
    user,
    sites,
    utility,
  };
}

const connectedVehicleNotesPage = connect(mapStateToProps)(
  withWidth()(withStyles(styles)(VehicleNotesPage))
);
export { connectedVehicleNotesPage as VehicleNotesPage };
