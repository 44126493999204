import { Button, Grid, makeStyles, Paper, TextField } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { utilityActions } from "../_actions";

import { PageWithAppDrawer2 } from "../_components/main/PageWithAppDrawer2";
import { utilityService } from "../_services";

import { getEnvironmentURL } from "../_helpers";
import { useParams } from "react-router";

export default function NhtsaConformityGeneratePage(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  // const items = useSelector((state) => state.utility.nhtsa_conformity_items);
  let { vin: pathVin } = useParams();

  // const [loading, setLoading] = useState(false);
  const [vin, setVin] = useState(pathVin);
  const [vinDetails, setVinDetails] = useState(null);

  const [availableFilters, setAvailableFilters] = useState([
    {
      name: "Name",
      field: "fmvss_name",
      operator: "like",
      value: "",
      type: "text",
      default: "",
    },
  ]);

  // useEffect(() => {
  //   console.log("pathVin", pathVin);
  //   setVin(pathVin);
  // }, [pathVin]);

  // const handleFilterChange = async (newFilter) => {
  //   const newFilters = availableFilters.map((filter) => {
  //     if (filter.field !== newFilter.field) return filter; // not the droid we're looking for, return unchanged
  //     filter.value = newFilter.value;
  //     return filter;
  //   });
  //   await setAvailableFilters(newFilters);
  // };

  const getVinDetails = async () => {
    await utilityService.getEndpoint("api/blackbook/" + vin, "post");
    utilityService
      .getUtility("vehicles", {
        perPage: 1,
        orders: [
          {
            field: "vehicleid",
            direction: "desc",
          },
        ],
        filters: [
          {
            field: "vehiclevin",
            operator: "eq",
            value: vin,
          },
        ],
        relations: ["blackbook_info"],
      })
      .then((response) => {
        setVinDetails(response.data[0]);
        console.log(response.data[0]);
      });
  };

  useEffect(() => {
    console.log("useEffect");
    dispatch(
      utilityActions.getUtility(
        "nhtsa_conformity_items",
        {
          perPage: 1000,
        },
        () => {
          console.log("after items");
        }
      )
    );
  }, []);

  return (
    <PageWithAppDrawer2 pageTitle="RI DOT Status">
      <Paper className={classes.paper}>
        <Grid container style={{ marginTop: 20 }}>
          <Grid container>
            <Grid item xs={11}>
              <TextField
                fullWidth
                placeholder="VIN"
                value={vin}
                onChange={(e) => setVin(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                color="primary"
                size="small"
                variant="contained"
                onClick={getVinDetails}
              >
                Check VIN
              </Button>
            </Grid>
          </Grid>
          {vinDetails && (
            <Grid container style={{ border: "1px solid black", padding: 5 }}>
              <Grid item>
                VIN: {vinDetails.vehiclevin}
                <br />
                {vinDetails.blackbook_info.map((bb, index) => (
                  <BbTrimDisplay
                    vehicleId={vinDetails.vehicleid}
                    json={bb.blackbook_json}
                    key={index}
                  />
                ))}
              </Grid>
            </Grid>
          )}
        </Grid>
      </Paper>
    </PageWithAppDrawer2>
  );
}

function BbTrimDisplay(props) {
  const { vehicleId, json: j } = props;
  const { gvw, class_name } = j;
  console.log("bb", { gvw, class_name });
  let vehType = "car";
  let vehDisplay = "Car";

  if (Number(gvw) > 10000) {
    vehType = "truck_heavy";
    vehDisplay = "Heavy Truck";
  } else {
    if (class_name.toLowerCase() === "pickup") {
      vehType = "truck";
      vehDisplay = "Truck";
    }
  }
  return (
    <>
      <Button
        size="small"
        variant="contained"
        color="primary"
        style={{ marginRight: 10 }}
        href={`${getEnvironmentURL()}/api/vehicles/${vehicleId}/forms/18?uvc=${
          j.uvc
        }&type=${vehType}`}
        target="_blank"
      >
        {vehDisplay} Conformity Doc
      </Button>
      {j.model_year} {j.make} {j.model} {j.series}, {j.class_name}, GVW {j.gvw}{" "}
      <br />
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
}));

export { NhtsaConformityGeneratePage };
