import {
  makeStyles,
  Paper,
  Tab,
  Tabs,
  Typography,
  Link as LinkExternal,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import FiltersGrid from "../_components/generic/FiltersGrid";
import ResponsiveGrid from "../_components/generic/ResponsiveGrid";
import LaunchTwoToneIcon from "@material-ui/icons/LaunchTwoTone";

import { PageWithAppDrawer2 } from "../_components/main/PageWithAppDrawer2";
import { utilityService } from "../_services";
import moment from "moment";
import { downloadBlob, generateCSV } from "../_helpers";

export default function RiDotOutstandingStatusPage(props) {
  const classes = useStyles();
  const clients = useSelector((state) => state.utility.clients_active_ri);

  const [loading, setLoading] = useState(false);
  const [clientId, setClientId] = useState(null);
  const [client, setClient] = useState(null);
  const [tabIndex, setTabIndex] = useState(0);

  const [data1, setData1] = useState([]);

  const [availableFilters, setAvailableFilters] = useState([
    {
      name: "RI Client",
      field: "clientid",
      operator: "eq",
      value: "",
      type: "choice",
      useUtility: true,
      utility: "clients_active_ri",
      utilityValue: "clientname",
      utilityOrder: {
        field: "clientname",
        direction: "asc",
      },
      utilityFilters: [
        {
          field: "clientactive",
          operator: "eq",
          value: 2,
        },
        {
          field: "is_ri",
          operator: "eq",
          value: 1,
        },
      ],
      // choices: [],
      default: "",
    },
  ]);

  const handleFilterChange = async (newFilter) => {
    const newFilters = availableFilters.map((filter) => {
      if (filter.field !== newFilter.field) return filter; // not the droid we're looking for, return unchanged
      filter.value = newFilter.value;
      if (filter.name === "RI Client") {
        setClientId(Number(filter.value));
        setClient(
          clients.items.find(
            (client) => Number(client.clientid) === Number(filter.value)
          )
        );
      }
      return filter;
    });
    await setAvailableFilters(newFilters);
  };

  const getData = (download = false) => {
    setLoading(true);
    if (!clientId) {
      console.log("no client");
      return;
    }

    if (download) {
      utilityService
        .getUtility("vehicles_base", {
          perPage: 1000,
          relations: [
            "images_speedo",
            "images_ri_label",
            "client_load",
            "title_status",
            "note_latest",
          ],
          filters: [
            {
              field: "titlestatusid",
              operator: "neq",
              value: "5",
            },
            {
              field: "clientid",
              operator: "eq",
              value: clientId,
            },
            {
              field: "submitteddotdate",
              operator: "isnull",
              value: null,
            },
          ],
          params: "withCount[]=images",
        })
        .then((values) => {
          const downloadHeaders = columns1
            .filter((item) => item.title)
            .map((item) => {
              if (!item.title) return null;
              return item.title;
            });
          const downloadFields = columns1
            .filter((item) => item.field)
            .map((item) => {
              if (!item.field) return null;
              return item.field;
            });

          return generateCSV(downloadHeaders, downloadFields, values.data);
        })
        .then((csv) => {
          const blob = new Blob([csv], { type: "text/csv" });
          downloadBlob(blob, `ri_dot_outstanding.csv`);
        })
        .catch(console.error)
        .finally(() => {
          setLoading(false);
        });
      return;
    }

    utilityService
      .getUtility("vehicles_base", {
        perPage: 1000,
        relations: [
          "images_speedo",
          "images_ri_label",
          "client_load",
          "title_status",
          "note_latest",
        ],
        filters: [
          {
            field: "titlestatusid",
            operator: "neq",
            value: "5",
          },
          {
            field: "clientid",
            operator: "eq",
            value: clientId,
          },
          {
            field: "submitteddotdate",
            operator: "isnull",
            value: null,
          },
        ],
        params: "withCount[]=images",
      })
      .then((result) => {
        result.data.sort(
          (a, b) => Number(b.images_count) - Number(a.images_count)
        );
        setData1(result.data);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (!clientId) {
      return;
    }
    if (clientId > 0) {
      getData();
    }
  }, [clientId]);

  return (
    <PageWithAppDrawer2 pageTitle="RI DOT Outstanding">
      <Paper className={classes.paper}>
        <FiltersGrid
          availableFilters={availableFilters}
          onChange={handleFilterChange}
        />
        {client && (
          <Typography>Selected Client: {client.clientname}</Typography>
        )}
        {client && (
          <>
            <Tabs value={tabIndex} onChange={(e, index) => setTabIndex(index)}>
              <Tab label={`DOT Outstanding (${data1 && data1.length})`} />
            </Tabs>
            <Typography component="div" role="tabpanel" hidden={tabIndex !== 0}>
              <ResponsiveGrid
                loading={loading}
                data={data1}
                columns={columns1}
                usePagination={false}
                onDownload={() => getData(true)}
                showActions={true}
              />
            </Typography>
          </>
        )}
      </Paper>
    </PageWithAppDrawer2>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
}));

const columns1 = [
  {
    width: 2,
    title: "VIN",
    field: "vehiclevin",
    dataRender: (item) => (
      <Link target="_blank" to={`/vehicles/${item.vehicleid}`}>
        {item.vehiclevin}{" "}
        <LaunchTwoToneIcon style={{ fontSize: 12 }} color="primary" />
      </Link>
    ),
  },
  {
    width: 2,
    title: "Load",
    field: "client_load.name",
    dataRender: (item) => (
      <Link target="_blank" to={`/clientloads/${item.client_load?.id}`}>
        {item.client_load?.name}{" "}
        <LaunchTwoToneIcon style={{ fontSize: 12 }} color="primary" />
      </Link>
    ),
  },
  {
    width: 1,
    title: "#Images",
    field: "images_count",
    dataRender: (item) => (
      <Link target="_blank" to={`/photoupload/${item.vehicleid}`}>
        {item.images_count}{" "}
        <LaunchTwoToneIcon style={{ fontSize: 12 }} color="primary" />
      </Link>
    ),
  },
  {
    width: 1,
    title: "Has Speedo",
    field: "images_speedo.pwimageid",
    dataRender: (item) =>
      item.images_speedo ? (
        <LinkExternal target="_blank" href={item.images_speedo.cloud_url_large}>
          Speedo Img
          <LaunchTwoToneIcon style={{ fontSize: 12 }} color="primary" />
        </LinkExternal>
      ) : (
        <Typography style={{ color: "red", fontWeight: "bold" }}>
          Missing
        </Typography>
      ),
  },
  {
    width: 1,
    title: "Has RI Label",
    field: "images_ri_label.pwimageid",
    dataRender: (item) =>
      item.images_ri_label ? (
        <LinkExternal target="_blank" href={item.images_ri_label.cloud_url_large}>
          RI Label Img
          <LaunchTwoToneIcon style={{ fontSize: 12 }} color="primary" />
        </LinkExternal>
      ) : (
        <Typography style={{ color: "red", fontWeight: "bold" }}>
          Missing
        </Typography>
      ),
  },
  {
    width: 1,
    title: "Since Crossed",
    field: "crossedborderdate",
    dataRender: (item) => moment(item.crossedborderdate).fromNow(),
  },
  {
    width: 1,
    title: "Status",
    field: "title_status.statusname",
  },
  {
    width: 1,
    title: "Note",
    field: "note_latest.note",
  },
  {
    width: 1,
    title: "Created",
    field: "recordcreateddate",
  },
  {
    width: 1,
    title: "BGK",
    field: "bkg_entry_load_number",
  },
  {
    width: 1,
    title: "Submitted to Customs",
    field: "submittedtocustomsdate",
  },
  {
    width: 1,
    title: "Crossed",
    field: "crossedborderdate",
  },
  {
    width: 1,
    title: "Year",
    field: "yr",
  },
  {
    width: 1,
    title: "Make",
    field: "make",
  },
  {
    width: 1,
    title: "Model",
    field: "model",
  },
];
