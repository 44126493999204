import React from "react";
// import Link from "@material-ui/core/Link";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";

import { PageWithAppDrawer2 } from "../_components/main/PageWithAppDrawer2";
// import { AuctionModal } from "../_components/admin/AuctionModal";

import { Paper } from "@material-ui/core";

class ManagementActivityDetailsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type: null,
      start: null,
      end: null
    };
  }
  componentDidMount() {}

  static getDerivedStateFromProps(nextProps, prevState) {
    const { type, start, end } = nextProps.match ? nextProps.match.params : {};
    return {
      type,
      start,
      end
    };
  }

  render() {
    const { classes } = this.props;
    return (
      <PageWithAppDrawer2 pageTitle={"Title Activity Report"}>
        <Paper className={classes.paper}>
          DO WE EVEN NEED THIS? WHAT DO WE NEED TO SEE HERE EXACTLY?
        </Paper>
      </PageWithAppDrawer2>
    );
  }
}

const styles = theme => ({
  root: {
    display: "flex"
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4)
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column"
  }
});

function mapStateToProps(state) {
  const { authentication, utility } = state;
  const { user } = authentication;
  const { us_states, auctions } = utility;
  return {
    user,
    auctions,
    us_states
  };
}

const connectedManagementActivityDetailsPage = connect(mapStateToProps)(
  withStyles(styles)(ManagementActivityDetailsPage)
);
export { connectedManagementActivityDetailsPage as ManagementActivityDetailsPage };
