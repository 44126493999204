import React, { Component } from "react";
import { Switch, Route, withRouter } from "react-router-dom";
import { connect } from "react-redux";

import { getEnvironment } from "../_helpers";

import { alertActions } from "../_actions";

import { hashHistory } from "../_helpers";
import { PrivateRoute as PR } from "../_components";
import { PrivateSvetnaRoute as PSR } from "../_components/PrivateSvetnaRoute";
import { PrivateSvetnaAdminRoute as PSAR } from "../_components/PrivateSvetnaAdminRoute";
import { AdminRoute as AR } from "../_components";
import { SecurityLevelPage } from "../Pages/SecurityLevelPage";

import { AdminClientsPage2 } from "../Pages/AdminClientsPage2";
import { AdminClientDetailsPage } from "../Pages/AdminClientDetailsPage";
import { AdminRackRatesPage } from "../Pages/AdminRackRatesPage";
import { AdminRackRateDetailsPage } from "../Pages/AdminRackRateDetailsPage";
import { AdminRackRateOverridesPage } from "../Pages/AdminRackRateOverridesPage";
import { AdminRackRateOverrideDetailsPage } from "../Pages/AdminRackRateOverrideDetailsPage";

import { AdminTitleStatusPage2 } from "../Pages/AdminTitleStatusPage2";
import { AdminTitleStatusDetailsPage } from "../Pages/AdminTitleStatusDetailsPage";
import { AdminTitleApplicationPage2 } from "../Pages/AdminTitleApplicationPage2";
import { AdminTitleApplicationDetailsPage } from "../Pages/AdminTitleApplicationDetailsPage";
import { AdminTitleRackRatesPage2 } from "../Pages/AdminTitleRackRatesPage2";
import { AdminTitleRackRateDetailsPage } from "../Pages/AdminTitleRackRateDetailsPage";

import { AdminUsers2Page } from "../Pages/AdminUsers2Page";
import { AdminUserDetailsPage } from "../Pages/AdminUserDetailsPage";
import { AuctionsPage } from "../Pages/AuctionsPage";
import { AuctionDetailsPage } from "../Pages/AuctionDetailsPage";

import { AutoIMSReconciliationPage } from "../Pages/AutoIMSReconciliationPage.js";
import { VehicleMassUploadPage } from "../Pages/VehicleMassUploadPage.js";
import { GstReportPage } from "../Pages/GstReportPage.js";

import { ClientLoadsPage } from "../Pages/ClientLoadsPage";
import { ClientLoadDetailsPage } from "../Pages/ClientLoadDetailsPage";
import ClientLoadPrintPage from "../Pages/ClientLoadPrintPage";

import { ClientLocationsPage } from "../Pages/ClientLocationsPage";
import { ClientLocationDetailsPage } from "../Pages/ClientLocationDetailsPage";

import { ClientDriversPage } from "../Pages/ClientDriversPage";
import { ClientDriverDetailsPage } from "../Pages/ClientDriverDetailsPage";

import { ClientTransportersPage } from "../Pages/ClientTransportersPage";
import { ClientTransporterDetailsPage } from "../Pages/ClientTransporterDetailsPage";

import { DashboardPage } from "../Pages/DashboardPage";
import { DashboardDrillPage } from "../Pages/DashboardDrillPage";

import { HomePage } from "../Pages/HomePage";
import { HomePageAdesa } from "../Pages/HomePageAdesa";

import { InvoicesPage } from "../Pages/InvoicesPage.js";
import { InvoiceDetailsPage } from "../Pages/InvoiceDetailsPage.js";
import { InvoiceBatchesPage } from "../Pages/InvoiceBatchesPage.js";
import { InvoiceBatchDetailsPage } from "../Pages/InvoiceBatchDetailsPage.js";
import { LoginPage } from "../Pages/LoginPage.js";
import { ForgotPasswordPage } from "../Pages/ForgotPasswordPage.js";

import { ManagementMassUpdatePage } from "../Pages/ManagementMassUpdatePage.js";
import { ManagementActivityPage } from "../Pages/ManagementActivityPage.js";
import { ManagementActivityDetailsPage } from "../Pages/ManagementActivityDetailsPage.js";
import { ManagementReportChargesPage } from "../Pages/ManagementReportChargesPage.js";
import { MobileInstructionsPage } from "../Pages/MobileInstructionsPage.js";

import { PaperworkBatchesPage } from "../Pages/PaperworkBatchesPage.js";
import { PaperworkBatchDetailsPage } from "../Pages/PaperworkBatchDetailsPage.js";
import { PhotoUploadPage } from "../Pages/PhotoUploadPage";
import { PhotoUploadPage3 } from "../Pages/PhotoUploadPage3";
import { PhotoUploadPage2 } from "../Pages/PhotoUploadPage2";

import { RegisterPage } from "../Pages/RegisterPage";
import { RegisterRequestedPage } from "../Pages/RegisterRequestedPage";
import { RegistrationDocumentsPage } from "../Pages/RegistrationDocumentsPage";
import { WelcomePage } from "../Pages/WelcomePage";

import { ResetPage } from "../Pages/ResetPage";
import { TitleReportPage } from "../Pages/TitleReportPage.js";
import { TitlesSentPage } from "../Pages/TitlesSentPage.js";
import { UnbilledUnitsPage } from "../Pages/UnbilledUnitsPage";
import { UserSignaturePage } from "../Pages/UserSignaturePage";
import { VehiclesPage } from "../Pages/VehiclesPage.js";
import { VehicleNotesPage } from "../Pages/VehicleNotesPage.js";
import { VehicleDetailsPage } from "../Pages/VehicleDetailsPage.js";

import { getCustomer } from "../_helpers";
import { InvoiceSummaryPage } from "../Pages/InvoiceSummaryPage";
import { MonthlySummaryPage } from "../Pages/MonthlySummaryPage";
import { ClientReportPendingTitlesPage } from "../Pages/ClientReportPendingTitlesPage";
import { TransportListPage } from "../Pages/TransportListPage";
import { CustomerVolumesPage } from "../Pages/CustomerVolumesPage";
import { CustomerStatsPage } from "../Pages/CustomerStatsPage";
import { OneOffInvoicesPage } from "../Pages/OneOffInvoicesPage";
import { OneOffInvoiceDetailsPage } from "../Pages/OneOffInvoiceDetailsPage";
import TpmsVehicleCheckPage from "../Pages/TpmsVehicleCheckPage";
import RiDotStatusPage from "../Pages/RiDotStatusPage";
import NhtsaConformityEditPage from "../Pages/NhtsaConformityEditPage";
import NhtsaConformityGeneratePage from "../Pages/NhtsaConformityGeneratePage";
import PdfFormFieldsPage from "../Pages/PdfFormFieldsPage";
import { YearlyTaxReportPage } from "../Pages/YearlyTaxReportPage";
import { HelpPage } from "../Pages/HelpPage";
import VehicleConformancePlusPage from "../Pages/VehicleConformancePlusPage";
import VehicleIvrPage from "../Pages/VehicleIvrPage";
import { RiCustomsSubmittedPage } from "../Pages/RiCustomsSubmittedPage";
import { RiNhtsaAuditPage } from "../Pages/RiNhtsaAuditPage";
import { ExitPhotoPage } from "../Pages/ExitPhotoPage";
import RiNhtsaIVRPage from "../Pages/RiNhtsaIVRPage";
import ClientOpenContractsPage from "../Pages/ClientOpenContractsPage";
import HudsonPaperworkPage from "../Pages/HudsonPaperworkPage";
import RiDotOutstandingStatusPage from "../Pages/RiDotOutstandingStatusPage";
import UserTimeReportPage from "../Pages/UserTimeReportPage";
import RiNeedsAppliedPage from "../Pages/RiNeedsAppliedPage";
import { MassUpdateBondReleasePage } from "../Pages/MassUpdateBondReleasePage";
import RiProblemsPage from "../Pages/RiProblemsPage";
import RiStatusPage from "../Pages/RiStatusPage";
import MobileAppDownloadPage from "../Pages/MobileAppDownloadPage";
import { SvetnaVehicleDashboardPage } from "../Pages/SvetnaDashboardPage";
import { SvetnaUsersPage } from "../Pages/SvetnaUsersPage";
import { SvetnaUserDetailsPage } from "../Pages/SvetnaUserDetailsPage";
import { SvetnaDealersPage } from "../Pages/SvetnaDealersPage";
import { SvetnaDealerDetailsPage } from "../Pages/SvetnaDealerDetailsPage";
import { SvetnaVehicleDetailsPage } from "../Pages/SvetnaVehicleDetailsPage";
import { ScannerPage } from "../Pages/ScannerPage";
import { SvetnaScannerPage } from "../Pages/SvetnaScannerPage";
import GstSubmissionsPage from "../Pages/GstSubmissionsPage";
import SvetnaReportsPage from "../Pages/SvetnaReportsPage.js";
import { RiUserTrainingPage } from "../Pages/RiUserTraining.js";
import RiUserTrainingSignPage from "../Pages/RiUserTrainingSign.js";
import BisbeeReconcilePage from "../Pages/BisbeeReconcilePage.js";

class App extends Component {
  constructor(props) {
    super(props);

    const customer = getCustomer();
    this.state = {
      customer,
    };

    hashHistory.listen((location, action) => {
      // clear alert on location change
    });
  }

  componentDidMount() {
    const { dispatch } = this.props;

    hashHistory.listen((location, action) => {
      // clear alert on location change
      dispatch(alertActions.clear());
    });

    getEnvironment("reset");
  }

  render() {
    // const { alert } = this.props;
    const { customer } = this.state;
    return (
      // {alert.message && (
      //   <div className={`alert ${alert.type}`}>{alert.message}</div>
      // )}
      <Switch>
        <Route
          exact
          path="/"
          component={customer === "adesa" ? HomePageAdesa : HomePage}
        />
        <Route
          path="/NonCompliantVehicleCheck"
          component={TpmsVehicleCheckPage}
        />
        <Route path="/register" component={RegisterPage} />
        <Route path="/register/acuity" component={RegisterPage} />

        <Route path="/help" component={HelpPage} />
        <Route
          path="/registration-requested"
          component={RegisterRequestedPage}
        />
        <Route
          path="/registration-documents"
          component={RegistrationDocumentsPage}
        />
        <Route path="/login" component={LoginPage} />
        <Route path="/forgot-password" component={ForgotPasswordPage} />
        <Route path="/reset/:token" component={ResetPage} />
        <Route path="/exitPhoto/:riLocation" component={ExitPhotoPage} />
        
        <Route path="/mobileApp" component={MobileAppDownloadPage} />

        <PR exact path="/welcome" component={WelcomePage} />

        <AR exact path="/admin/auctions" component={AuctionsPage} />
        <AR exact path="/admin/auctions/:id" component={AuctionDetailsPage} />
        <AR exact path="/admin/clients" component={AdminClientsPage2} />
        <AR
          exact
          path="/admin/clients/:id"
          component={AdminClientDetailsPage}
        />
        <AR
          exact
          path="/admin/rackRates/overrides"
          component={AdminRackRateOverridesPage}
        />
        <AR
          exact
          path="/admin/rackRates/overrides/:id"
          component={AdminRackRateOverrideDetailsPage}
        />
        <AR exact path="/admin/rackRates" component={AdminRackRatesPage} />
        <AR
          exact
          path="/admin/rackRates/:id"
          component={AdminRackRateDetailsPage}
        />
        <AR exact path="/admin/pdfFormFields/" component={PdfFormFieldsPage} />
        <AR
          exact
          path="/admin/titleStatusTypes"
          component={AdminTitleStatusPage2}
        />
        <AR
          exact
          path="/admin/titleStatusTypes/:id"
          component={AdminTitleStatusDetailsPage}
        />
        <AR
          exact
          path="/admin/titleApplicationTypes"
          component={AdminTitleApplicationPage2}
        />
        <AR
          exact
          path="/admin/titleApplicationTypes/:id"
          component={AdminTitleApplicationDetailsPage}
        />
        <AR
          exact
          path="/admin/titleRackRates"
          component={AdminTitleRackRatesPage2}
        />
        <AR
          exact
          path="/admin/titleRackRates/:id"
          component={AdminTitleRackRateDetailsPage}
        />
        <AR exact path="/admin/titlesSent" component={TitlesSentPage} />

        <AR exact path="/admin/users" component={AdminUsers2Page} />
        <AR exact path="/admin/users/:id" component={AdminUserDetailsPage} />

        <AR
          exact
          path="/accounting/user-time-report"
          component={UserTimeReportPage}
        />
        <AR
          exact
          path="/accounting/unbilled-units"
          component={UnbilledUnitsPage}
        />
        <AR
          exact
          path="/accounting/invoice-summary"
          component={InvoiceSummaryPage}
        />
        <AR
          exact
          path="/accounting/monthly-summary"
          component={MonthlySummaryPage}
        />
        <AR
          exact
          path="/accounting/yearly-tax-report"
          component={YearlyTaxReportPage}
        />
        <AR
          exact
          path="/accounting/one-off-invoices"
          component={OneOffInvoicesPage}
        />
        <AR
          exact
          path="/accounting/bisbee-reconcile"
          component={BisbeeReconcilePage}
        />
        <AR
          exact
          path="/accounting/one-off-invoices/:id"
          component={OneOffInvoiceDetailsPage}
        />

        <AR
          exact
          path="/transportation/transport-list"
          component={TransportListPage}
        />

        <PR
          exact
          path="/clientOpenContracts"
          component={ClientOpenContractsPage}
        />
        <PR
          exact
          path="/clientHudsonPaperwork"
          component={HudsonPaperworkPage}
        />
        <PR exact path="/clientLoads" component={ClientLoadsPage} />
        <PR exact path="/clientLoads/:id" component={ClientLoadDetailsPage} />
        <PR exact path="/clientLocations" component={ClientLocationsPage} />
        <PR
          exact
          path="/clientLocations/:id"
          component={ClientLocationDetailsPage}
        />
        <PR
          exact
          path="/clientTransporters"
          component={ClientTransportersPage}
        />
        <PR
          exact
          path="/clientTransporters/:id"
          component={ClientTransporterDetailsPage}
        />
        <PR exact path="/clientDrivers" component={ClientDriversPage} />
        <PR
          exact
          path="/clientDrivers/:id"
          component={ClientDriverDetailsPage}
        />
        <PR
          exact
          path="/clientLoads/:id/print"
          component={ClientLoadPrintPage}
        />
        <PR
          exact
          path="/client-reports/pending-titles"
          component={ClientReportPendingTitlesPage}
        />
        <PR exact path="/invoices" component={InvoicesPage} />
        <PR exact path="/invoices/:id" component={InvoiceDetailsPage} />

        <PR exact path="/invoicebatches" component={InvoiceBatchesPage} />
        <PR
          exact
          path="/invoicebatches/:invoiceid"
          component={InvoiceBatchDetailsPage}
        />
        <AR
          exact
          path="/management/activity"
          component={ManagementActivityPage}
        />
        <AR
          exact
          path="/management/report-charges"
          component={ManagementReportChargesPage}
        />
        <AR
          exact
          path="/management/activity/:type/:start/:end"
          component={ManagementActivityDetailsPage}
        />
        <AR
          exact
          path="/management/massUpdate"
          component={ManagementMassUpdatePage}
        />
        <AR
          exact
          path="/management/massUpdateBondReleases"
          component={MassUpdateBondReleasePage}
        />
        <AR
          exact
          path="/management/ri-dot-status"
          component={RiDotStatusPage}
        />
        <AR
          exact
          path="/management/riUserTraining"
          component={RiUserTrainingPage}
        />
        <Route
          exact
          path="/training/:userId/:webUserId/:documentName"
          component={RiUserTrainingSignPage}
        />
        <AR
          exact
          path="/management/ri-dot-outstanding"
          component={RiDotOutstandingStatusPage}
        />
        <AR
          exact
          path="/management/ri-needs-applied"
          component={RiNeedsAppliedPage}
        />
        <PR
          exact
          path="/management/ri-problems"
          component={RiProblemsPage}
        />
        <PR
          exact
          path="/management/ri-status"
          component={RiStatusPage}
        />
        <AR
          exact
          path="/management/ri-customs-submitted-report"
          component={RiCustomsSubmittedPage}
        />
        <AR
          exact
          path="/management/ri-nhtsa-audit-report"
          component={RiNhtsaAuditPage}
        />
        <AR exact path="/management/ri-nhtsa-ivr" component={RiNhtsaIVRPage} />
        <AR
          exact
          path="/management/nhtsaConformityEdit"
          component={NhtsaConformityEditPage}
        />
        <AR
          exact
          path="/management/nhtsaConformityGenerate/:vin?"
          component={NhtsaConformityGeneratePage}
        />

        <AR
          exact
          path="/management/autoims"
          component={AutoIMSReconciliationPage}
        />
        <AR
          exact
          path="/management/vehicleFileUpload"
          component={VehicleMassUploadPage}
        />
        <AR exact path="/management/gst-report" component={GstReportPage} />
        <PR exact path="/management/gst-submissions" component={GstSubmissionsPage} />
        <AR
          exact
          path="/management/customer-volumes"
          component={CustomerVolumesPage}
        />
        <AR
          exact
          path="/management/customer-stats"
          component={CustomerStatsPage}
        />
        <PR exact path="/mobile/:type" component={MobileInstructionsPage} />
        <PR exact path="/paperworkbatches" component={PaperworkBatchesPage} />
        <PR
          exact
          path="/paperworkbatches/:paperworkId"
          component={PaperworkBatchDetailsPage}
        />
        <PR exact path="/photoupload" component={PhotoUploadPage} />
        <PR
          exact
          path="/scanner"
          component={ScannerPage}
        />
        <PR exact path="/photoupload/:id" component={PhotoUploadPage2} />
        <PR exact path="/photoupload3/:id" component={PhotoUploadPage3} />
        <PR exact path="/titles/:reportType" component={TitleReportPage} />
        <AR exact path="/user/signature" component={UserSignaturePage} />
        <PR exact path="/vehicles" component={VehiclesPage} />
        <PR exact path="/vehicle_notes/:type" component={VehicleNotesPage} />
        <PR exact path="/vehicles/:vehicleid" component={VehicleDetailsPage} />
        <PR
          exact
          path="/vehicles/:vehicleid/conformance"
          component={VehicleConformancePlusPage}
        />
        <PR
          exact
          path="/vehicles/:vehicleid/vehicleIVR"
          component={VehicleIvrPage}
        />

        <PR exact path="/dashboard" component={DashboardPage} />
        <PR
          exact
          path="/dashboard/drill/:drillType"
          component={DashboardDrillPage}
        />
        <PR
          exact
          path="/dashboard/drill/:drillType/:clientId"
          component={DashboardDrillPage}
        />
        <PR exact path="/access/:level" component={SecurityLevelPage} />
        <PSR exact path="/svetna/vehicledashboard" component={SvetnaVehicleDashboardPage} />
        <PSAR exact path="/svetna/users" component={SvetnaUsersPage} />
        <PSAR exact path="/svetna/users/:id" component={SvetnaUserDetailsPage} />
        <PSR exact path="/svetna/dealers" component={SvetnaDealersPage} />
        <PSR exact path="/svetna/dealers/:id" component={SvetnaDealerDetailsPage} />
        <PSR exact path="/svetna/vehicles/:id" component={SvetnaVehicleDetailsPage} />
        <PSR exact path="/svetna/scan" component={SvetnaScannerPage} />
        <PSR exact path="/svetna/reports" component={SvetnaReportsPage} />
      </Switch>
    );
  }
}

function mapStateToProps(state) {
  const { alert } = state;
  const { autoLogin } = state.authentication;
  return {
    alert,
    autoLogin,
  };
}

const connectedApp = connect(mapStateToProps)(App);

export default withRouter(connectedApp);
