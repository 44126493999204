import React, { useEffect, useState } from "react";
import moment from "moment";

import { PageWithAppDrawer2 } from "../_components/main/PageWithAppDrawer2";
import FiltersGrid from "../_components/generic/FiltersGrid";

import {
  Paper,
  Divider,
  Button,
  makeStyles,
  Grid,
  Typography,
} from "@material-ui/core";

import { utilityService } from "../_services";
import ResponsiveGrid from "../_components/generic/ResponsiveGrid";
import { downloadBlob, generateCSV } from "../_helpers";

export default function UserTimeReportPage(props) {
  const classes = useStyles();

  return (
    <PageWithAppDrawer2 pageTitle={"User Time Reporting"}>
      <Paper className={classes.paper}>
        <YearlyTaxReport />
      </Paper>
    </PageWithAppDrawer2>
  );
}

function YearlyTaxReport(props) {
  const startDefault = moment().startOf("month").format("YYYY-MM-DD");
  const endDefault = moment().endOf("month").format("YYYY-MM-DD");

  const [getData, setGetData] = useState(false);

  const [start, setStart] = useState(startDefault);
  const [end, setEnd] = useState(endDefault);
  const [userId, setUserId] = useState(null);

  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);

  const [availableFilters, setAvailableFilters] = useState([
    {
      name: "Start Date",
      field: "start_date",
      operator: ">=",
      value: moment().startOf("month").format("YYYY-MM-DD"),
      type: "date",
      default: "",
    },
    {
      name: "End Date",
      field: "end_date",
      operator: "<=",
      value: moment().endOf("month").format("YYYY-MM-DD"),
      type: "date",
      default: "",
    },
    {
      name: "Staff User",
      field: "user_id",
      operator: "eq",
      value: "",
      type: "choice",
      useUtility: true,
      utility: "web_users",
      utilityKey: "userid",
      utilityValue: "name",
      utilityFilters: [
        {
          field: "active",
          operator: "eq",
          value: 0,
        },
      ],
      utilityOrder: {
        field: "firstname",
        direction: "asc",
      },
      choices: [],
      default: "",
    },
    {
      name: "Staff Group",
      field: "user_id",
      operator: "in",
      value: "",
      type: "choice",
      choices: [
        {
          key: "667,668,669,670,671,672,673",
          value: "MI Group (Norman)",
        },
      ],
      default: "",
    },
  ]);

  useEffect(() => {
    if (getData === true) {
      setGetData(false);
      getNewData();
    }
  }, [getData]);

  useEffect(() => {
    getNewData();
  }, []);

  const getNewData = async (download = false) => {
    if (!start || !end || !userId) {
      return;
    }
    setLoading(true);

    const options = {
      body: {
        start,
        end,
        userId,
      },
    };

    if (download) {
      utilityService
        .getEndpoint("api/reports/management/userTimeReport", "POST", options)
        .then((values) => {
          const downloadHeaders = columns
            .filter((item) => item.title)
            .map((item) => {
              if (!item.title) return null;
              return item.title;
            });
          const downloadFields = columns
            .filter((item) => item.field)
            .map((item) => {
              if (!item.field) return null;
              return item.field;
            });

          return generateCSV(downloadHeaders, downloadFields, values.data);
        })
        .then((csv) => {
          const blob = new Blob([csv], { type: "text/csv" });
          downloadBlob(blob, `user_time_reporting.csv`);
        })
        .catch(console.error)
        .finally(() => {
          setLoading(false);
        });
      return;
    }

    utilityService
      .getEndpoint("api/reports/management/userTimeReport", "POST", options)
      .then((response) => {
        setResults(response.data);
        setLoading(false);
      });
  };

  const handleFilterChange = (newFilter, refresh = true) => {
    const newFilters = availableFilters.map((filter) => {
      if (filter.field !== newFilter.field) return filter; // not the droid we're looking for, return unchanged
      if (filter.name !== newFilter.name) return filter; // not the droid we're looking for, return unchanged

      if (filter.field === "start_date") {
        setStart(newFilter.value);
      }
      if (filter.field === "end_date") {
        setEnd(newFilter.value);
      }
      if (filter.field === "user_id") {
        setUserId(newFilter.value);
      }

      filter.value = newFilter.value;
      return filter;
    });
    setAvailableFilters(newFilters);
    if (refresh) {
      setGetData(true);
    }
  };

  return (
    <>
      <Grid container spacing={1} style={{ marginBottom: 20 }}>
        <Grid item>
          <Button
            variant="contained"
            size="small"
            color="primary"
            onClick={() => {
              handleFilterChange(
                {
                  name: "Start Date",
                  field: "start_date",
                  operator: ">=",
                  value: moment()
                    .subtract(1, "month")
                    .startOf("month")
                    .format("YYYY-MM-DD"),
                },
                false
              );
              handleFilterChange(
                {
                  name: "End Date",
                  field: "end_date",
                  operator: "<=",
                  value: moment()
                    .subtract(1, "month")
                    .endOf("month")
                    .format("YYYY-MM-DD"),
                },
                true
              );
            }}
          >
            Last Month
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            size="small"
            color="primary"
            onClick={() => {
              handleFilterChange(
                {
                  name: "Start Date",
                  field: "start_date",
                  operator: ">=",
                  value: moment().startOf("month").format("YYYY-MM-DD"),
                },
                false
              );
              handleFilterChange(
                {
                  name: "End Date",
                  field: "end_date",
                  operator: "<=",
                  value: moment().endOf("month").format("YYYY-MM-DD"),
                },
                true
              );
            }}
          >
            Current Month
          </Button>
        </Grid>
      </Grid>
      <FiltersGrid
        availableFilters={availableFilters}
        onChange={handleFilterChange}
      />
      <Divider style={{ marginTop: 10, marginBottom: 50 }} />
      <ResponsiveGrid
        loading={loading}
        data={results}
        columns={columns}
        usePagination={false}
        onDownload={() => getNewData(true)}
        showActions={true}
      />
    </>
  );
}

const columns = [
  {
    width: 2,
    title: "Date",
    field: "date",
    dataRender: (item) =>
      item.date === "Period Total" ? (
        <Typography style={{ fontWeight: "bold" }}>{item.date}</Typography>
      ) : (
        item.date
      ),
  },
  {
    width: 2,
    title: "Pictures Total",
    field: "pictures_taken",
    dataRender: (item) =>
      item.date === "Period Total" ? (
        <Typography style={{ fontWeight: "bold" }}>{item.pictures_taken}</Typography>
      ) : (
        item.pictures_taken
      ),
  },
  {
    width: 2,
    title: "Speedo Pictures",
    field: "speedo_images",
    dataRender: (item) =>
      item.date === "Period Total" ? (
        <Typography style={{ fontWeight: "bold" }}>{item.speedo_images}</Typography>
      ) : (
        item.speedo_images
      ),
  },
  {
    width: 2,
    title: "RI Labels",
    field: "ri_images",
    dataRender: (item) =>
      item.date === "Period Total" ? (
        <Typography style={{ fontWeight: "bold" }}>{item.ri_images}</Typography>
      ) : (
        item.ri_images
      ),
  },
  {
    width: 2,
    title: "Unique Vehicles",
    field: "vehicles_touched",
    dataRender: (item) =>
      item.date === "Period Total" ? (
        <Typography style={{ fontWeight: "bold" }}>{item.vehicles_touched}</Typography>
      ) : (
        item.vehicles_touched
      ),
  },
  {
    width: 2,
    title: "Average Pics",
    field: "avg_pics",
    dataRender: (item) =>
      item.date === "Period Total" ? (
        <Typography style={{ fontWeight: "bold" }}>{item.avg_pics}</Typography>
      ) : (
        item.avg_pics
      ),
  },
];

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
}));
