import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link as RouterLink } from "react-router-dom";

import { utilityActions } from "../_actions";
import { generateCSV, downloadBlob } from "../_helpers";

import { PageWithAppDrawer2 } from "../_components/main/PageWithAppDrawer2";
import ResponsiveGrid from "../_components/generic/ResponsiveGrid";

import FiltersGrid from "../_components/generic/FiltersGrid";
import { Paper, Button, makeStyles, Grid, Typography } from "@material-ui/core";
import { utilityService } from "../_services";
import LaunchTwoToneIcon from "@material-ui/icons/LaunchTwoTone";

export default function RiStatusPage(props) {
  const classes = useStyles();

  return (
    <PageWithAppDrawer2 pageTitle={"RI Status Report"}>
      <Paper className={classes.paper}>
        <RiStatus />
      </Paper>
    </PageWithAppDrawer2>
  );
}

function RiStatus(props) {
  const dispatch = useDispatch();
  const items = useSelector((state) => state.utility.vehicles_problems);
  const webUser = useSelector((state) => state.authentication.webUser);
  const clientGroup = webUser.client_group || null;
  const clientsList = clientGroup
    ? clientGroup.clients_base.map((c) => c.clientid)
    : [webUser.client_id];
  const isAdmin = webUser.roleid === 2 ? true : false;
  const isClient = webUser.roleid !== 2 ? true : false;
  const clientId = webUser.client_id;
  const columns = [
    {
      width: 2,
      title: "Client Name",
      field: "client_base.clientname",
    },
    {
      width: 2,
      title: "VIN",
      field: "vehiclevin",
      dataRender: (item) => (
        <RouterLink to={`/vehicles/${item.vehicleid}`} target="_blank">
          {item.vehiclevin}{" "}
          <LaunchTwoToneIcon style={{ fontSize: 12 }} color="primary" />
        </RouterLink>
      ),
    },
    {
      width: 2,
      title: "yr/mk/model",
      field: "year_make_model",
    },
    {
      width: 2,
      title: "Vehicles Status",
      field: "title_status.statusname",
    },
    {
      width: 1,
      title: "Ownership",
      field: "titleregownershipdate",
    },
    {
      width: 2,
      title: "Load Name",
      field: "client_load_base.name",
    },
    {
      width: 2,
      title: "Crossed Date",
      field: "crossedborderdate",
    },
    {
      width: 1,
      title: "DOT Submitted",
      field: "submitteddotdate",
    },
    {
      width: 1,
      title: "Sent To State",
      field: "titlesenttobmvdate",
    },
    {
      width: 1,
      title: "Back from State",
      field: "titlebackfrombmvdate",
    },
    {
      width: 1,
      title: "Vault Release",
      field: "vaultreleasedate",
    },
    {
      width: 1,
      title: "Bond Release",
      field: "bondreleasedate",
    },
    {
      width: 2,
      title: "Last Note",
      field: "note_latest.note",
    },
  ];

  const [availableFilters, setAvailableFilters] = useState([
    {
      name: "VIN",
      field: "vehiclevin",
      operator: "like",
      value: "",
      type: "text",
      default: "",
    },
    {
      name: "Status",
      field: "titlestatusid",
      operator: "eq",
      value: "",
      type: "choice",
      useUtility: true,
      utility: "title_statuses_ri_problems",
      //   utilityFilters: [{
      //     field: "statusid",
      //     operator: "in",
      //     value: "2,14,19"
      //   }],
      utilityValue: "statusname",
      choices: [],
      default: "",
    },
    isAdmin && {
      name: "Client",
      field: "clientid",
      operator: "eq",
      value: "",
      type: "choice",
      useUtility: true,
      utility: "clients_active",
      utilityValue: "clientname",
      utilityOrder: {
        field: "clientname",
        direction: "asc",
      },
      utilityFilters: [
        {
          field: "clientactive",
          operator: "eq",
          value: 2,
        },
      ],
      choices: [],
      default: "",
    },
    {
      name: "Bond Release Date",
      field: "bondreleasedate",
      operator: "eq",
      value: "",
      type: "date",
      default: "",
    },
    {
      name: "Cancelled",
      field: "cancelled",
      operator: "eq",
      value: 0,
      type: "hidden",
      default: "",
    },
    {
      name: "RI Vehicle",
      field: "client_base.is_ri",
      operator: "eq",
      value: 1,
      type: "hidden",
      default: "",
    },
    isClient &&
      !clientGroup && {
        name: "Client",
        field: "clientid",
        operator: "eq",
        value: clientId,
        type: "hidden",
        default: "",
      },
    isClient &&
      clientGroup && {
        name: "Clients",
        field: "clientid",
        operator: "in",
        value: clientsList.join(","),
        type: "hidden",
        default: "",
      },
  ]);

  const [getData, setGetData] = useState(false);

  const [page, setPage] = useState(items.page ? Number(items.page) - 1 : 0);
  const [perPage, setPerPage] = useState(
    items.per_page ? Number(items.per_page) : 50
  );
  const [total, setTotal] = useState(items.total ? Number(items.total) : 0);

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
    setGetData(true);
  };
  const handleChangeRowsPerPage = (event) => {
    const value = parseInt(event.target.value);
    setPerPage(value);
    setGetData(true);
  };
  useEffect(() => {
    if (getData === true) {
      setGetData(false);
      getNewData();
    }
  }, [getData]);

  useEffect(() => {
    setTotal(items.total ? Number(items.total) : 0);
    setPage(items.page ? Number(items.page) : 0);
    setPerPage(items.per_page ? Number(items.per_page) : 50);
  }, [items]);

  useEffect(() => {
    if (items.items.length === 0) {
      getNewData();
    }
  }, []);

  const getNewData = (download = false) => {
    let newFilters = availableFilters
      .filter((filter) => {
        if ([null, false].includes(filter)) return false;
        if (["", null].includes(filter.value)) return false;
        if (
          ["isnull", "notnull"].includes(filter.operator) &&
          filter.value === false
        )
          return false;
        return true;
      })
      .map((filter) => {
        return {
          field: filter.field,
          operator: filter.operator,
          value: filter.value,
        };
      });

    if (download) {
      let options = {
        filters: newFilters,
        perPage: perPage,
        page: page,
        relations: [
          "client_base",
          "client_load_base",
          "title_status",
          "note_latest",
        ],
        appends: ["year_make_model"],
        // params: "withCount[]=images&withCount[]=vehicle_photos",
        orders: [{ field: "crossedborderdate", direction: "desc" }],
      };
      // setLoading(true);
      options.page = 1;
      options.perPage = total + 1;
      utilityService
        .getUtility("vehicles_problems", options)
        .then((values) => {
          const downloadHeaders = columns
            .filter((item) => item.title)
            .map((item) => {
              if (!item.title) return null;
              return item.title;
            });
          const downloadFields = columns
            .filter((item) => item.field)
            .map((item) => {
              if (!item.field) return null;
              return item.field;
            });

          return generateCSV(downloadHeaders, downloadFields, values.data);
        })
        .then((csv) => {
          const blob = new Blob([csv], { type: "text/csv" });
          downloadBlob(blob, `ri_problem_report.csv`);
        })
        .catch(console.error)
        .finally(() => {
          // setLoading(false);
        });
      return;
    }

    dispatch(
      utilityActions.getUtility("vehicles_problems", {
        page,
        perPage,
        filters: newFilters,
        relations: [
          "client_base",
          "client_load_base",
          "title_status",
          "note_latest",
        ],
        appends: ["year_make_model"],
        // params: "withCount[]=images&withCount[]=vehicle_photos",
        orders: [{ field: "crossedborderdate", direction: "desc" }],
      })
    );
  };

  const handleFilterChange = (newFilter) => {
    const newFilters = availableFilters.map((filter) => {
      if (filter.field !== newFilter.field) return filter; // not the droid we're looking for, return unchanged
      if (filter.name !== newFilter.name) return filter;
      filter.value = newFilter.value;
      return filter;
    });
    setAvailableFilters(newFilters);
    setPage(1);
    setGetData(true);
  };

  return (
    <>
      <Grid container spacing={1}>
        <Grid item>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              getNewData();
            }}
          >
            Refresh
          </Button>
        </Grid>
        <Grid item>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              getNewData(true);
            }}
          >
            Download
          </Button>
        </Grid>
      </Grid>

      {clientGroup && (
        <Grid container>
          <Grid xs={12}>
            <Typography>Client Group: {clientGroup.name}</Typography>
          </Grid>
          {clientGroup.clients_base.map((c) => (
            <Grid xs={12} key={c.clientid}>
              <Typography>- {c.clientname}</Typography>
            </Grid>
          ))}
        </Grid>
      )}

      <FiltersGrid
        availableFilters={availableFilters}
        onChange={handleFilterChange}
      />
      <ResponsiveGrid
        loading={items.loading}
        columns={columns}
        data={items.items}
        page={page - 1} // API has a 1 based page count, Material UI has a zero based page count
        perPage={perPage}
        total={total}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
}));
