import { authHeader } from "../_helpers";
import {
  buildRelations,
  // buildFilters,
  handleResponse,
  getEnvironmentURL,
} from "../_helpers";

export const batchService = {
  addBatch,
  getBatch,
  updateBatch,
  addVehicleToBatch,
  removeVehicleFromBatch,
};

function addBatch(data = {}) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };

  const url = getEnvironmentURL();
  return fetch(`${url}/api/invoice_batches?`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    });
}

function getBatch(id, relations = []) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  const relationStrings = buildRelations(relations);
  const relationString =
    relationStrings != null ? relationStrings.join("&") : null;

  const url = getEnvironmentURL();
  return fetch(
    `${url}/api/invoice_batches/${id}?${relationString}`,
    requestOptions
  )
    .then(handleResponse)
    .then((response) => {
      return response;
    });
}

function updateBatch(id, data = {}) {
  const requestOptions = {
    method: "PUT",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };

  const url = getEnvironmentURL();
  return fetch(`${url}/api/invoice_batches/${id}?`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    });
}

function addVehicleToBatch(id, vehicleid) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };

  const url = getEnvironmentURL();
  return fetch(
    `${url}/api/invoice_batches/${id}/add/${vehicleid}`,
    requestOptions
  )
    .then(handleResponse)
    .then((response) => {
      return response;
    });
}

function removeVehicleFromBatch(id, vehicleid) {
  const requestOptions = {
    method: "DELETE",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };

  const url = getEnvironmentURL();
  return fetch(
    `${url}/api/invoice_batches/${id}/remove/${vehicleid}`,
    requestOptions
  )
    .then(handleResponse)
    .then((response) => {
      return response;
    });
}
