import React from "react";
import { useSelector } from "react-redux";
import { Route, Redirect } from "react-router-dom";

export const PrivateSvetnaRoute = ({ component: Component, ...rest }) => {
  const user = useSelector((state) => state.authentication.user);

  if (user === null) {
    return (
      <Route
        {...rest}
        render={(props) => (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        )}
      />
    );
  }
  if (user) {
    if (!user.has_svetna_role) {
      return (
        <Route
          {...rest}
          render={(props) => (
            <Redirect
              to={{
                pathname: "/access/svetna",
                state: { from: props.location },
              }}
            />
          )}
        />
      );
    } else {
      return <Route {...rest} render={(props) => <Component {...props} />} />;
    }
  }

  return (
    <Route
      {...rest}
      render={(props) => (
        <Redirect
          to={{ pathname: "/login", state: { from: props.location } }}
        />
      )}
    />
  );
};
