import React, { useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
// import Link from "@material-ui/core/Link";
import { connect, useSelector, useDispatch } from "react-redux";
import { withStyles } from "@material-ui/core/styles";

import { utilityActions } from "../_actions";
import { generateCSV, downloadBlob } from "../_helpers";

import { PageWithAppDrawer2 } from "../_components/main/PageWithAppDrawer2";
import ResponsiveGrid from "../_components/generic/ResponsiveGrid";

import FiltersGrid from "../_components/generic/FiltersGrid";
import { Paper, Button, Divider } from "@material-ui/core";
import { utilityService } from "../_services";
import { hashHistory } from "../_helpers";

class AdminClientsPage2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {}

  render() {
    const { classes } = this.props;

    return (
      <PageWithAppDrawer2 pageTitle={"Clients"}>
        <Paper className={classes.paper}>
          <div>
            <Button
              color="primary"
              variant="contained"
              onClick={this.handleAddItem}
            >
              Add Client
            </Button>
          </div>
          <ClientsTable />
        </Paper>
      </PageWithAppDrawer2>
    );
  }

  handleAddItem = () => {
    utilityService
      .addUtility("clients", {
        clientname: "NEW CLIENT",
        clientactive: 2,
      })
      .then((response) => {
        hashHistory.push(`/admin/clients/${response.id}`);
      });
  };
}

function ClientsTable(props) {
  const dispatch = useDispatch();
  const items = useSelector((state) => state.utility.clients);
  const columns = [
    {
      width: 2,
      title: "Name",
      field: "clientname",
      dataRender: (item) => (
        <>
          <RouterLink to={`/admin/clients/${item.id}`}>
            {item.clientname}
          </RouterLink>
          <br />
          {item.client_parent && `[${item.client_parent.clientname}]`}
        </>
      ),
    },
    {
      width: 2,
      title: "Address",
      field: "clientstreetaddress",
    },
    {
      width: 2,
      title: "City",
      field: "clientcity",
    },
    {
      width: 1,
      title: "State",
      field: "state.stateabbreviation",
    },
    {
      width: 1,
      title: "type",
      field: "type.clienttype",
    },
    {
      width: 2,
      title: "Rack Rates",
      field: "rackrates",
      dataRender: (item) => (item.dvsrackrates === 1 ? "Yes" : "No"),
    },
    {
      width: 2,
      title: "Rate Overrides",
      field: "overrides_count",
    },
    {
      width: 12,
      titleRender: () => <Divider />,
    },
    {
      width: 2,
      title: "Contact Name",
      field: "clientcontactname",
    },
    {
      width: 2,
      title: "Contact Phone",
      field: "clientphonenumber",
    },
    {
      width: 2,
      title: "Contact Email",
      field: "clientemailaddress",
    },
    {
      width: 2,
      title: "Invoice Email",
      field: "invoiceemailaddress",
    },
  ];

  const [availableFilters, setAvailableFilters] = useState([
    {
      name: "Active",
      field: "clientactive",
      operator: "eq",
      value: "2",
      type: "choice",
      choices: [
        {
          key: "1",
          value: "Not Active",
        },
        {
          key: "2",
          value: "Active",
        },
      ],
      default: "",
    },
    {
      name: "Name",
      field: "clientname",
      operator: "like",
      value: "",
      type: "text",
      default: "",
    },
    {
      name: "City",
      field: "clientcity",
      operator: "like",
      value: "",
      type: "text",
      default: "",
    },
    {
      name: "Client Type",
      field: "is_ri",
      operator: "eq",
      value: "",
      type: "choice",
      choices: [
        {
          key: "0",
          value: "Title Clients Only",
        },
        {
          key: "1",
          value: "RI Clients Only",
        },
      ],
      default: "",
    },
  ]);

  const [getData, setGetData] = useState(false);
  const [loading, setLoading] = useState(false);

  const [page, setPage] = useState(items.page ? Number(items.page) - 1 : 0);
  const [perPage, setPerPage] = useState(
    items.per_page ? Number(items.per_page) : 0
  );
  const [total, setTotal] = useState(items.total ? Number(items.total) : 0);

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
    setGetData(true);
  };
  const handleChangeRowsPerPage = (event) => {
    const value = parseInt(event.target.value);
    setPerPage(value);
    setGetData(true);
  };
  useEffect(() => {
    if (getData === true) {
      setGetData(false);
      getNewData();
    }
  }, [getData]);

  useEffect(() => {
    setTotal(items.total ? Number(items.total) : 0);
    setPage(items.page ? Number(items.page) : 0);
    setPerPage(items.per_page ? Number(items.per_page) : 10);
  }, [items]);

  useEffect(() => {
    if (items.items.length === 0) {
      getNewData();
    }
  }, []);

  // const handleDownload = () => {};

  const getNewData = (download = false) => {
    let newFilters = availableFilters
      .filter((filter) => {
        if (["", null].includes(filter.value)) return false;
        if (
          ["isnull", "notnull"].includes(filter.operator) &&
          filter.value === false
        )
          return false;
        return true;
      })
      .map((filter) => {
        return {
          field: filter.field,
          operator: filter.operator,
          value: filter.value,
        };
      });

    if (download) {
      let options = {
        filters: newFilters,
        perPage: perPage,
        page: page,
      };
      setLoading(true);
      options.page = 1;
      options.perPage = total + 1;
      utilityService
        .getUtility("clients", options)
        .then((values) => {
          const downloadHeaders = columns
            .filter((item) => item.title)
            .map((item) => {
              if (!item.title) return null;
              return item.title;
            });
          const downloadFields = columns
            .filter((item) => item.field)
            .map((item) => {
              if (!item.field) return null;
              return item.field;
            });

          return generateCSV(downloadHeaders, downloadFields, values.data);
        })
        .then((csv) => {
          const blob = new Blob([csv], { type: "text/csv" });
          downloadBlob(blob, `clients.csv`);
        })
        .catch(console.error)
        .finally(() => {
          setLoading(false);
        });
      return;
    }

    dispatch(
      utilityActions.getUtility("clients", {
        page,
        perPage,
        filters: newFilters,
        orders: [{ field: "clientname", direction: "asc" }],
      })
    );
  };

  const handleFilterChange = (newFilter) => {
    const newFilters = availableFilters.map((filter) => {
      if (filter.field !== newFilter.field) return filter; // not the droid we're looking for, return unchanged
      filter.value = newFilter.value;
      return filter;
    });
    setAvailableFilters(newFilters);
    setPage(1);
    setGetData(true);
  };

  const handleDownload = () => {
    getNewData(true);
  };

  return (
    <>
      <FiltersGrid
        availableFilters={availableFilters}
        onChange={handleFilterChange}
      />
      <ResponsiveGrid
        loading={items.loading}
        columns={columns}
        data={items.items}
        page={page - 1} // API has a 1 based page count, Material UI has a zero based page count
        perPage={perPage}
        total={total}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        showActions
        onDownload={handleDownload}
      />
    </>
  );
}

const styles = (theme) => ({
  root: {
    display: "flex",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
});

function mapStateToProps(state) {
  const { authentication, utility } = state;
  const { user } = authentication;
  const { us_states } = utility;
  return {
    user,
    us_states,
  };
}

const connectedAdminClientsPage2 = connect(mapStateToProps)(
  withStyles(styles)(AdminClientsPage2)
);
export { connectedAdminClientsPage2 as AdminClientsPage2 };
