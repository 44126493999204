import React, { useState, useEffect } from "react";
// import { Link as RouterLink } from "react-router-dom";
import { connect, useSelector, useDispatch } from "react-redux";
import { withStyles, makeStyles } from "@material-ui/core/styles";

// import { isIOS, isSafari } from "react-device-detect";

import { utilityActions } from "../_actions";
import { getEnvironmentURL, authHeader } from "../_helpers";

import { PageWithAppDrawer2 } from "../_components/main/PageWithAppDrawer2";
// import safari_icon from "../_assets/safari-icon.png";

import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Camera, { FACING_MODES } from "react-html5-camera-photo";
import "./RapidPhotos.css";

import { utilityService } from "../_services";
import ModalGeneric from "../_components/generic/ModalGeneric";

import {
  Typography,
  Button,
  Link,
  CircularProgress,
  TextField,
  Divider,
  Backdrop,
  Select,
  FormControl,
  InputLabel,
  FormHelperText,
} from "@material-ui/core";
import { isEmpty } from "lodash";

export function PhotoUploadPage3(props) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const user = useSelector((state) => state.authentication.webUser);

  const { id: vehicleId } = props.match ? props.match.params : {};

  useEffect(() => {
    dispatch(utilityActions.getOneUtility("vehicles", vehicleId));
  }, []);

  if (user.roleid !== 2 && !user.client.clientName) {
    return (
      <PageWithAppDrawer2 pageTitle={"Upload Photos v3 (not used)"}>
        <Paper className={classes.paper}>
          <Typography variant="h6" align="center">
            For clients only right now
          </Typography>
        </Paper>
      </PageWithAppDrawer2>
    );
  }

  return (
    <PageWithAppDrawer2 pageTitle={"Upload Photos v3 (not used)"}>
      <Paper className={classes.paper}>
        <Typography variant="h6" align="center">
          {user.client.clientName}
        </Typography>
        {/* {!vehicle ? <CircularProgress /> : <VehicleCard vehicle={vehicle} />} */}
      </Paper>
    </PageWithAppDrawer2>
  );
}

class PhotoUploadPage4 extends React.Component {
  constructor(props) {
    super(props);
    const { id: vehicleId } = this.props.match ? this.props.match.params : {};
    const isAdmin = this.props.webUser.roleid === 2;
    const clientId = this.props.webUser.client_id;

    this.state = {
      vehicleId,
      isAdmin,
      clientId,
    };
  }

  async componentDidMount() {
    const { dispatch } = this.props;
    const { vehicleId, clientId } = this.state;

    const { items: image_types } = this.props.utility.vehicle_image_types;

    if (image_types.length === 0) {
      this.props.dispatch(
        utilityActions.getUtility("vehicle_image_types", {
          perPage: 100,
          orders: [
            {
              field: "display_order",
              direction: "asc",
            },
          ],
        })
      );
    }

    this.props.dispatch(
      utilityActions.getUtility("client_loads", {
        perPage: 25,
        relations: ["client"],
        filters: [
          {
            field: "client_id",
            operator: "eq",
            value: clientId,
          },
        ],
        orders: [
          {
            field: "created_at",
            direction: "desc",
          },
        ],
      })
    );

    await dispatch(utilityActions.getOneUtility("vehicles", vehicleId));
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(utilityActions.clearOneUtility("vehicles"));
  }

  render() {
    const { classes, webUser, utility } = this.props;
    const { clientname: clientName } = webUser.client || {};
    const { vehicles_item: vehicle } = utility;

    if (webUser.roleid !== 2 && !clientName) {
      return (
        <PageWithAppDrawer2 pageTitle={"Upload Photos"}>
          <Paper className={classes.paper}>
            <Typography variant="h6" align="center">
              For clients only right now
            </Typography>
          </Paper>
        </PageWithAppDrawer2>
      );
    }

    return (
      <PageWithAppDrawer2 pageTitle={"Upload Photos"}>
        <Paper className={classes.paper}>
          <Typography variant="h6" align="center">
            {clientName}
          </Typography>
          {!vehicle ? <CircularProgress /> : <VehicleCard vehicle={vehicle} />}
        </Paper>
      </PageWithAppDrawer2>
    );
  }
}

function VehicleCard(props) {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.authentication.webUser);
  const clientId = useSelector(
    (state) => state.authentication.webUser.client_id
  );
  const vehicleImageTypes = useSelector(
    (state) => state.utility.vehicle_image_types
  );
  const clientLoads = useSelector((state) => state.utility.client_loads);
  const drivers = useSelector((state) => state.utility.client_drivers_full);

  const [vehicle, setVehicle] = useState(props.vehicle);
  const [vehicleType, setVehicleType] = useState("");
  const [images, setImages] = useState([]);
  const [mileage, setMileage] = useState("");
  const [kilometers, setKilometers] = useState("");
  const [manufacturer, setManufacturer] = useState("");
  const [manuDate, setManuDate] = useState("");
  const [gvwr, setGvwr] = useState("");
  const [gawrFront, setGawrFront] = useState("");
  const [gawrRear, setGawrRear] = useState("");
  const [valueUSD, setValueUSD] = useState("");
  const [hasRecall, setHasRecall] = useState(0);
  const [transitNumber, setTransitNumber] = useState("");

  const [loadNumber, setLoadNumber] = useState("");
  const [loadId, setLoadId] = useState("");
  const [needToSave, setNeedToSave] = useState(true);
  const [saving, setSaving] = useState(false);

  const [modalPhotoOpen, setModalPhotoOpen] = useState(false);
  // const [dataUri, setDataUri] = useState("");
  const [imageIndex, setImageIndex] = useState(0);
  const [mirror, setMirror] = useState(false);

  const [openSingle, setOpenSingle] = useState(false);
  // const [singleError, setSingleError] = useState("");
  const [driverId, setDriverId] = useState(null);
  const [singleLoadNumber, setSingleLoadNumber] = useState("");

  useEffect(() => {
    setVehicle(props.vehicle);
    let getDrivers = true;
    if (props.vehicle) {
      if (getDrivers) {
        dispatch(
          utilityActions.getUtility("client_drivers_full", {
            perPage: 1000,
            filters: [
              {
                field: "client_id",
                operator: "eq",
                value: clientId,
              },
            ],
            orders: [{ field: "first_name", direction: "asc" }],
          })
        );
      }
      setMileage(props.vehicle.mileage);
      updateMileageKilometers(props.vehicle.mileage, null);
      setLoadNumber(props.vehicle.customer_load_number);
      setLoadId(props.vehicle.client_load_id);
      setDriverId(props.vehicle.client_driver_id);
      setManufacturer(props.vehicle.manufacturer);
      setManuDate(props.vehicle.manufacture_date);
      setGvwr(props.vehicle.gvwr_kg);
      setGawrFront(props.vehicle.gawr_front_kg);
      setGawrRear(props.vehicle.gawr_rear_kg);
      setVehicleType(props.vehicle.vehicletype);
      setValueUSD(props.vehicle.value_us_dollars);
      setTransitNumber(props.vehicle.transit_number);
      setDriverId(props.vehicle.client_driver_id);
      setHasRecall(
        props.vehicle.has_recall === null ? "" : props.vehicle.has_recall
      );
    }
    let images = [];
    props.vehicle.images &&
      props.vehicle.images.forEach((image) => {
        const imageType = image.type && image.type.id;
        if (imageType) {
          images[imageType] = image;
        }
      });
    setImages(images);

    setImageIndex(getFirstImageIndex());
  }, [props.vehicle]);

  useEffect(() => {
    setImageIndex(getFirstImageIndex());
  }, [images]);

  useEffect(() => {
    // console.log("hasRecall", hasRecall);
  }, [hasRecall]);

  useEffect(() => {
    if (user.roleid === 2) {
      setNeedToSave(false);
      return;
    }

    if (!props.vehicle) {
      setNeedToSave(true);
      return;
    } else if (
      props.vehicle.mileage === null ||
      props.vehicle.mileage === "" ||
      props.vehicle.customer_load_id === null ||
      props.vehicle.customer_load_id === ""
    ) {
      setNeedToSave(true);
    } else if (
      Number(props.vehicle.mileage) !== Number(mileage) ||
      props.vehicle.customer_load_number !== loadNumber
    ) {
      setNeedToSave(true);
    } else {
      setNeedToSave(false);
    }
  }, [mileage, loadNumber, props.vehicle]);

  const saveBasics = () => {
    setSaving(true);
    dispatch(
      utilityActions.updateUtility(
        "vehicles",
        props.vehicle.id,
        {
          mileage,
          customer_load_number: loadNumber,
          client_load_id: loadId,
          manufacturer,
          manufacture_date: manuDate,
          gvwr_kg: gvwr,
          gawr_front_kg: gawrFront,
          gawr_rear_kg: gawrRear,
          gvwr_lbs: parseInt(gvwr * 2.20462),
          gawr_front_lbs: parseInt(gawrFront * 2.20462),
          gawr_rear_lbs: parseInt(gawrRear * 2.20462),
          value_us_dollars: parseInt(valueUSD),
          has_recall: hasRecall === 0 || hasRecall === 1 ? hasRecall : null,
          transit_number: transitNumber,
          client_driver_id: driverId,
        },
        () => {
          setSaving(false);
        }
      )
    );
  };

  useEffect(() => {
    console.log("useEffect loadId");
  }, [loadId]);

  const updateMileageKilometers = (mileage, kilometers) => {
    if (mileage) {
      console.log("mileage");
      setMileage(mileage);
      setKilometers(Number(mileage * 1.60934).toFixed(0));
    }
    if (kilometers) {
      setKilometers(kilometers);
      setMileage(Number(kilometers * 0.621371).toFixed(0));
    }
    if (!mileage && !kilometers) {
      setMileage("");
      setKilometers("");
    }
  };

  const quickAddLoad = async () => {
    const load = window.prompt("New Load Number");
    if (!load || load.toString().trim() === "") {
      return;
    }

    utilityService
      .getUtility("client_loads", {
        filters: [
          {
            field: "client_id",
            operator: "eq",
            value: clientId,
          },
          {
            field: "name",
            operator: "eq",
            value: load,
          },
        ],
      })
      .then((response) => {
        if (response && response.data && response.data.length === 1) {
          setLoadId(response.data[0].id);
          alert("Load already exists, setting load");
        } else if (response && response.data && response.data.length > 1) {
          alert("More than one load with that name, please use dropdown");
        } else if (response && response.data && response.data.length === 0) {
          // creating new load
          utilityService
            .addUtility("client_loads", {
              client_id: clientId,
              created_by: user.userid,
              name: load,
            })
            .then(async (response) => {
              await dispatch(
                utilityActions.getUtility("client_loads", {
                  perPage: 25,
                  relations: ["client"],
                  filters: [
                    {
                      field: "client_id",
                      operator: "eq",
                      value: clientId,
                    },
                  ],
                  orders: [
                    {
                      field: "created_at",
                      direction: "desc",
                    },
                  ],
                })
              );
              setLoadId(response.id);
            });
        }
      });
  };

  function handleTakePhotoAnimationDone(dataUri) {
    const imageType = vehicleImageTypes.items[imageIndex];
    setImageIndex(imageIndex + 1);
    // let newImages = images;
    // newImages[imageType.id] = "temp";
    // setImages(newImages);
    uploadImage(dataUri, vehicle.id, imageType.name, imageType.id);
  }

  function getFirstImageIndex() {
    let x;
    for (x = 0; x < vehicleImageTypes.items.length; x++) {
      if (!images[vehicleImageTypes.items[x].id]) {
        return x;
      }
    }
    return 0;
  }

  const uploadImage = (file, vehicleId, note, type) => {
    let formData = new FormData();
    formData.append("photo", file);
    formData.append("vehicleId", vehicleId);
    formData.append("note", note);
    formData.append("type", type);

    fetch(`${getEnvironmentURL()}/api/vehicles/${props.vehicleId}/images`, {
      // Your POST endpoint
      method: "POST",
      headers: { ...authHeader() },
      body: formData,
    })
      .then(
        (response) => response.json() // if the response is a JSON object
      )
      .then(
        //TODO:save also in customer object
        (success) => {
          // console.log(success); // Handle the success response object
          // refreshImages();
        }
      )
      .catch((error) => {
        // console.log(error); // Handle the error response object
        // setPreviewUrl("");
      });
  };

  const refreshImages = () => {
    dispatch(utilityActions.getOneUtility("vehicles", vehicle.id));
  };

  const createSingle = () => {
    if (!singleLoadNumber) {
      // setSingleError("Load Number is required");
      return;
    }
    // setSingleError("");
    utilityService
      .addUtility("client_loads", {
        name: singleLoadNumber,
        load_type: "single",
        client_id: clientId,
        created_by: user.userid,
      })
      .then((response) => {
        setLoadId(response.id);
        setOpenSingle(false);
        dispatch(
          utilityActions.getUtility("client_loads", {
            perPage: 25,
            filters: [
              {
                field: "client_id",
                operator: "eq",
                value: clientId,
              },
            ],
            orders: [
              {
                field: "created_at",
                direction: "desc",
              },
            ],
          })
        );
      });
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography align="center">{vehicle.vehiclevin}</Typography>
          <Typography align="center">
            {vehicle.yr} {vehicle.make} {vehicle.model}{" "}
          </Typography>
          {vehicle.client && (
            <Typography align="center">{vehicle.client.clientname}</Typography>
          )}
        </Grid>
        <Grid item xs={6} sm={4} md={3}>
          <TextField
            required
            id="mileage"
            name="mileage"
            label="Mileage"
            value={mileage || ""}
            helperText="US Miles"
            onChange={(event) =>
              updateMileageKilometers(event.target.value, null)
            }
          />
        </Grid>
        <Grid item xs={6} sm={4} md={3}>
          <TextField
            required
            id="kilometers"
            name="kilometers"
            label="Kilometers"
            value={kilometers}
            helperText="Kilometers"
            onChange={(event) =>
              updateMileageKilometers(null, event.target.value)
            }
          />
        </Grid>
        <Grid item xs={6} sm={4} md={3}>
          <FormControl>
            <InputLabel shrink>Load</InputLabel>
            <Select
              native
              style={{ fontSize: 12 }}
              value={loadId || ""}
              name="client_load_id"
              onChange={(event) => setLoadId(event.target.value)}
              displayEmpty
            >
              <option value={""}>Not in Load</option>
              {clientLoads.items &&
                clientLoads.items.map((load, index) => {
                  return (
                    <option value={load.id} key={index}>
                      {load.name}
                      {load.load_type === "single" &&
                        ` - singles(${load.vehicles_count})`}
                    </option>
                  );
                })}
            </Select>
            <FormHelperText>Load Number</FormHelperText>
          </FormControl>
          <br />
          <Button
            color="primary"
            onClick={quickAddLoad}
            disabled={loadId ? true : false}
          >
            Quick Add Load
          </Button>
          <br />
          <Button
            color="primary"
            onClick={() => setOpenSingle(true)}
            disabled={loadId ? true : false}
          >
            Create Single Load
          </Button>
        </Grid>
        <Grid item xs={6} sm={4} md={3}>
          <FormControl>
            <InputLabel shrink>Has Recall</InputLabel>
            <Select
              native
              style={{ fontSize: 12 }}
              // value={hasRecall === null ? "" : hasRecall}
              value={hasRecall || 0}
              // value={
              //   hasRecall === null || hasRecall === "" ? "" : hasRecall || ""
              // }
              name="has_recall"
              onChange={(event) => setHasRecall(event.target.value)}
              displayEmpty
            >
              <option value={""}>Has Recall?</option>
              <option value={0}>No - No Recall</option>
              <option value={1}>Yes - Has Recall</option>
            </Select>
            <FormHelperText>NHTSA Recall Check</FormHelperText>
          </FormControl>
        </Grid>
        {props.vehicle.client_load &&
          props.vehicle.client_load.load_type === "single" && (
            <>
              <Grid item xs={12} sm={4} md={3}>
                <TextField
                  fullWidth
                  name="transit_number"
                  label="Transit Number"
                  value={transitNumber || ""}
                  onChange={(event) => setTransitNumber(event.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={8} md={9}>
                <FormControl>
                  <InputLabel shrink>Driver</InputLabel>
                  <Select
                    native
                    value={driverId || ""}
                    onChange={(e) => setDriverId(e.target.value)}
                  >
                    <option value={null}>SELECT DRIVER</option>
                    {drivers.items.map((driver, index) => (
                      <option value={driver.id} key={index}>
                        {driver.first_name} {driver.last_name}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </>
          )}
        <Grid item xs={12} sm={4} md={3}>
          <TextField
            fullWidth
            name="manufacturer"
            label="Manufacturer"
            value={manufacturer || ""}
            onChange={(event) => setManufacturer(event.target.value)}
          />
        </Grid>
        <Grid item xs={6} sm={4} md={3}>
          <TextField
            fullWidth
            name="manufacture_date"
            // type="number"
            inputMode="numeric"
            label="Manufacture Date"
            // helperText="Manufacture Date"
            value={manuDate || ""}
            onChange={(event) => setManuDate(event.target.value)}
          />
        </Grid>
        <Grid item xs={6} sm={12} md={6}>
          <TextField
            fullWidth
            name="vehicletype"
            label="Type"
            helperText="US NHTSA Vehicle Type"
            // type="number"
            value={vehicleType || ""}
            onChange={(event) => setVehicleType(event.target.value)}
          />
        </Grid>
        <Grid item xs={6} sm={4} md={3}>
          <TextField
            name="gvwr"
            label="GVWR (kg)"
            type="number"
            value={gvwr || ""}
            onChange={(event) => setGvwr(event.target.value)}
          />
        </Grid>
        <Grid item xs={6} sm={4} md={3}>
          <TextField
            name="gawr_front"
            label="GAWR Front (kg)"
            type="number"
            value={gawrFront || ""}
            onChange={(event) => setGawrFront(event.target.value)}
          />
        </Grid>
        <Grid item xs={6} sm={4} md={3}>
          <TextField
            name="gawr_rear"
            label="GAWR Rear (kg)"
            type="number"
            value={gawrRear || ""}
            onChange={(event) => setGawrRear(event.target.value)}
          />
        </Grid>
        <Grid item xs={6} sm={4} md={3}>
          <TextField
            name="value_us_dollars"
            label="Value USD"
            type="number"
            value={valueUSD || ""}
            onChange={(event) => setValueUSD(event.target.value)}
          />
        </Grid>
        <Grid item xs={12} align="center">
          <Button
            variant="contained"
            color="primary"
            size="small"
            // disabled={!needToSave}
            onClick={saveBasics}
          >
            Save Mileage and Load
          </Button>{" "}
          <Button
            variant="contained"
            color="primary"
            size="small"
            disabled={needToSave}
            href={`${getEnvironmentURL()}/api/vehicles/${vehicle.id}/forms/26`}
            target="_blank"
          >
            RI Label
          </Button>{" "}
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Button
            size="small"
            color="primary"
            variant="contained"
            onClick={() => setModalPhotoOpen(true)}
          >
            Rapid Photos
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>

        {!needToSave &&
          vehicleImageTypes.items.map((imageType, index) => {
            return (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <VehicleImageNamed
                  imageType={imageType}
                  vehicleId={vehicle.vehicleid}
                  vehicleImage={images[imageType.id]}
                />
              </Grid>
            );
          })}
      </Grid>
      <Backdrop open={saving} style={{ zIndex: 10 }}>
        <CircularProgress />
      </Backdrop>
      <ModalGeneric
        open={openSingle}
        title="Add to Single Load"
        closeText="Create Single Load"
        onClose={createSingle}
        cancelText="Cancel"
        onCancel={() => {
          setOpenSingle(false);
          setLoadNumber("");
        }}
      >
        <TextField
          placeholder="Load Number"
          value={singleLoadNumber}
          onChange={(e) => setSingleLoadNumber(e.target.value)}
        />
      </ModalGeneric>
      <ModalGeneric
        open={modalPhotoOpen}
        // showActions={false}
        actionStyle={{
          backgroundColor: "none",
          justifyContent: "space-between",
        }}
        // title="Rapid Photos"
        fullScreen={true}
        onClose={() => {
          setModalPhotoOpen(false);
          refreshImages();
        }}
        onCancel={() => setMirror(!mirror)}
        cancelText={mirror ? "Mirror On" : "Mirror Off"}
      >
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              zIndex: 1000,
              width: "100%",
              flexDirection: "row",
            }}
          >
            {vehicleImageTypes.items.map((imageType, index) => {
              let color = "secondary";
              let variant = "outlined";
              if (images[imageType.id]) {
                color = "secondary";
                variant = "contained";
              }
              if (index === imageIndex) {
                color = "primary";
                variant = "contained";
              }
              return (
                <Button
                  key={index}
                  color={color}
                  size="small"
                  variant={variant}
                  onClick={() => setImageIndex(index)}
                >
                  {imageType.name}
                </Button>
              );
            })}
          </div>
          <Camera
            idealFacingMode={FACING_MODES.ENVIRONMENT}
            isFullscreen={true}
            isImageMirror={mirror}
            style={{
              width: "100%",
              height: "100%",
              position: "absolute",
              top: 0,
              left: 0,
            }}
            onTakePhotoAnimationDone={handleTakePhotoAnimationDone}
          />
        </div>
        {/* <Camera
          onTakePhotoAnimationDone={handleTakePhotoAnimationDone}
          isFullscreen={false}
        /> */}
      </ModalGeneric>
    </>
  );
}

function VehicleImageNamed(props) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const inputElement = React.useRef(null);
  const [previewUrl, setPreviewUrl] = useState("");
  const [cloudUrl, setCloudUrl] = useState(null);
  const [cloudUrlThumb, setCloudUrlThumb] = useState(null);

  // const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (props.vehicleImage) {
      if (isEmpty(props.vehicleImage.imagename)) {
        setCloudUrl(null);
        setCloudUrlThumb(null);
      } else {
        if (!isEmpty(props.vehicleImage.cloud_url_orig)) {
          setCloudUrl(props.vehicleImage.cloud_url_orig);
        } else {
          setCloudUrl(
            `${getEnvironmentURL()}/api/vehicles/${
              props.vehicleImage.vehicleid
            }/images/${props.vehicleImage.id}`
          );
        }
        if (!isEmpty(props.vehicleImage.cloud_url_thumb)) {
          setCloudUrlThumb(props.vehicleImage.cloud_url_thumb);
        } else {
          setCloudUrlThumb(
            `${getEnvironmentURL()}/api/vehicles/${
              props.vehicleImage.vehicleid
            }/images/${props.vehicleImage.id}?size=thumb`
          );
        }
      }
    }
  }, [props.vehicleImage]);

  const imageAdded = (event, imageTypeId) => {
    event.preventDefault();
    // setLoading(true);

    let reader = new FileReader();
    let file = event.target.files[0];
    event.target.files = null;

    reader.onloadend = () => {
      setPreviewUrl(reader.result);
      // setLoading(false);
      uploadImage(file);
    };

    try {
      reader.readAsDataURL(file);
    } catch (e) {}
  };

  const deleteImage = () => {
    // setLoading(true);
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this image?"
    );
    if (!confirmDelete) {
      return;
    }
    dispatch(
      utilityActions.deleteOneUtility(
        "vehicle_images",
        props.vehicleImage.id,
        null,
        () => {
          // setLoading(false);
          setPreviewUrl("");
          refreshImages();
        }
      )
    );
  };

  const uploadImage = (file) => {
    let formData = new FormData();
    formData.append("photo", file);
    formData.append("vehicleId", props.vehicleId);
    formData.append("note", props.imageType.name);
    formData.append("type", props.imageType.id);

    fetch(`${getEnvironmentURL()}/api/vehicles/${props.vehicleId}/images`, {
      // Your POST endpoint
      method: "POST",
      headers: { ...authHeader() },
      body: formData,
    })
      .then(
        (response) => response.json() // if the response is a JSON object
      )
      .then(
        //TODO:save also in customer object
        (success) => {
          // console.log(success); // Handle the success response object
          refreshImages();
        }
      )
      .catch((error) => {
        // console.log(error); // Handle the error response object
        setPreviewUrl("");
      });
  };

  const refreshImages = () => {
    dispatch(utilityActions.getOneUtility("vehicles", props.vehicleId));
  };

  return (
    <Paper
      className={classes.paper}
      align="center"
      style={{ textAlign: "center" }}
    >
      {props.imageType.name}
      <br />
      <input
        id={`image_upload_${props.imageType.id}`}
        type="file"
        name={`files[${props.imageType.id}]`}
        accept="image/*"
        capture="camera"
        style={{ display: "none" }}
        onChange={(event) => imageAdded(event, props.imageType.id)}
      />
      <input
        id={`image_upload_file_${props.imageType.id}`}
        ref={inputElement}
        type="file"
        name={`files[${props.imageType.id}]`}
        accept="image/*"
        // capture="camera"
        style={{ display: "none" }}
        onChange={(event) => imageAdded(event, props.imageType.id)}
      />

      {props.vehicleImage ? (
        <>
          <Link target="_blank" href={cloudUrl}>
            <img
              alt="img"
              src={cloudUrlThumb}
              style={{
                width: 100,
                height: 100,
                maxHeight: 100,
                maxWidth: 100,
              }}
            />
          </Link>
          <div style={{ textAlign: "left" }}>
            <Button color="primary" variant="contained" onClick={deleteImage}>
              Delete
            </Button>{" "}
            User: {props.vehicleImage.uploaded_by.name}
          </div>
        </>
      ) : (
        <>
          {previewUrl ? (
            <img
              src={previewUrl}
              alt="img"
              style={{ width: 100, height: 100, maxWidth: 100, maxHeight: 100 }}
            />
          ) : (
            <>
              <label
                htmlFor={`image_upload_${props.imageType.id}`}
                style={{
                  display: "inline-block",
                  align: "center",
                  width: 100,
                  maxWidth: 100,
                  height: 100,
                  maxHeight: 100,
                  border: "1px solid black",
                }}
              >
                <img
                  alt="img"
                  src="https://via.placeholder.com/100/000000/FFFFFF?text=Camera"
                  style={{
                    // width: "100%",
                    width: 100,
                    height: 100,
                    maxWidth: 100,
                    maxHeight: 100,
                  }}
                />
              </label>
              <br />
              <label htmlFor={`image_upload_${props.imageType.id}`}>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    inputElement.current.click();
                  }}
                >
                  Upload File
                </Button>
              </label>
            </>
          )}
        </>
      )}
    </Paper>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));

const styles = (theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
});

function mapStateToProps(state) {
  const { users, authentication, utility } = state;
  const { user, webUser } = authentication;
  return {
    user,
    webUser,
    users,
    utility,
  };
}

const connectedPhotoUploadPage4 = connect(mapStateToProps)(
  withStyles(styles)(PhotoUploadPage4)
);
export { connectedPhotoUploadPage4 as PhotoUploadPage4 };
