import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link as RouterLink, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { authHeader } from "../_helpers";

import { PageWithAppDrawer2 } from "../_components/main/PageWithAppDrawer2";
import { utilityActions } from "../_actions";
import {
  Paper,
  Grid,
  Select,
  Button,
  Backdrop,
  Snackbar,
  makeStyles,
  TextField,
  MenuItem,
  FormControl,
  InputLabel,
  Radio,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  AppBar,
  Tabs,
  Tab,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import CircularProgress from "@material-ui/core/CircularProgress";
import ResponsiveGrid from "../_components/generic/ResponsiveGrid";
import { Autocomplete, createFilterOptions } from "@material-ui/lab";
import { utilityService } from "../_services";
import { getEnvironmentURL, hashHistory } from "../_helpers";
import { useDropzone } from "react-dropzone";

export function SvetnaVehicleDetailsPage() {
  const classes = useVehicleDetailsPageStyles();
  const { id: vehicleId } = useParams();
  const [tabIndex, setTabIndex] = useState(0);

  return (
    <PageWithAppDrawer2 pageTitle={`Preimport Vehicle #${vehicleId}`}>
      <RouterLink
        to={{ pathname: "/svetna/vehicledashboard", state: { refresh: true } }}
      >
        Vehicle Dashboard
      </RouterLink>
      <AppBar position="static" style={{ marginTop: 10 }}>
        <Tabs
          value={tabIndex}
          onChange={(e, newValue) => setTabIndex(newValue)}
          aria-label="simple tabs example"
        >
          <Tab label="Vehicle Details" />
          <Tab label="Vehicle History" />
          <Tab label="Photos" />
        </Tabs>
      </AppBar>
      <Typography component="div" role="tabpanel" hidden={tabIndex !== 0}>
        <Paper className={classes.paper}>
          <SvetnaVehicleDetails itemId={vehicleId} />
        </Paper>
      </Typography>
      <Typography component="div" role="tabpanel" hidden={tabIndex !== 1}>
        <Paper className={classes.paper}>
          <SvetnaVehicleHistoryGrid itemId={vehicleId} />
        </Paper>
      </Typography>
      <Typography component="div" role="tabpanel" hidden={tabIndex !== 2}>
        <Paper className={classes.paper}>
          <SvetnaVehiclePhotos itemId={vehicleId} />
        </Paper>
      </Typography>
    </PageWithAppDrawer2>
  );
}

const useVehicleDetailsPageStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    width: "100%",
  },
  formControl: {
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

function SvetnaVehiclePhotos(props) {
  const id = props.itemId;
  const dispatch = useDispatch();
  const fileLinks = useSelector((state) => state.utility.file_links);
  const [loading, setLoading] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    severity: "success",
    message: "",
    duration: 1000,
  });

  const fileLinkColumns = [
    {
      width: 3,
      title: "File Id",
      field: "file_id",
    },
    {
      width: 9,
      title: "Preview",
      field: "file.url",
      dataRender: (item) => (
        <a href={item.file.url} target="_blank">
          <img
            src={item.file.url}
            style={{ width: "100%" }}
            alt={`File ID ${item.file.id}`}
          />
        </a>
      ),
    },
  ];

  useEffect(() => {
    if (Number(id) > 0) {
      setLoading(true);
      getNewData();
    }
  }, [id]);

  const getNewData = () => {
    dispatch(
      utilityActions.getUtility(
        "file_links",
        {
          perPage: 1000,
          filters: [
            {
              field: "parent_type",
              operator: "eq",
              value: "preimport_vehicles",
            },
            {
              field: "parent_id",
              operator: "eq",
              value: id,
            },
          ],
          orders: [
            {
              field: "created_at",
              direction: "desc",
            },
          ],
        },
        () => {
          setLoading(false);
        }
      )
    );
  };

  const handleAddFile = (file) => {
    // console.log({ file });
    let formData = new FormData();
    formData.append("file", file);
    formData.append("attrs[0][public]", 1);
    formData.append("attrs[0][parent_type]", "preimport_vehicles");
    formData.append("attrs[0][parent_id]", id);

    fetch(`${getEnvironmentURL()}/api/files`, {
      method: "POST",
      headers: { ...authHeader() },
      body: formData,
    })
      .then(
        (response) => response.json() // if the response is a JSON object
      )
      .then((data) => {
        if (Array.isArray(data)) {
          showMessage("Data uploaded");
        } else {
          showMessage("Unexpected data", "error");
        }
      })
      .catch((error) => {
        showMessage(error, "error"); // Handle the error response object
      });
  };

  const handleRejectFile = (file, errors) => {
    showMessage(file.path + " is not valid. " + errors[0].message, "error");
  };

  const showMessage = (message, severity = "info", duration = 6000) => {
    setSnackbar({
      open: true,
      severity,
      message,
      duration,
    });
  };

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Button variant="contained" color="primary" onClick={getNewData}>
            Refresh Photo List
          </Button>
        </Grid>
        <Grid item xs={12}>
          <DragAndDrop
            onAddFile={handleAddFile}
            onRejectFile={handleRejectFile}
          />
        </Grid>
        <Grid item xs={12}>
          {loading && <CircularProgress />}
          {!loading && (
            <ResponsiveGrid
              loading={loading}
              columns={fileLinkColumns}
              data={fileLinks.items}
              usePagination={false}
            />
          )}
        </Grid>
      </Grid>
      <Snackbar
        color={snackbar.severity}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={snackbar.open}
        autoHideDuration={snackbar.duration}
        onClose={() =>
          setSnackbar({
            ...snackbar,
            open: false,
          })
        }
      >
        <MuiAlert severity={snackbar.severity} variant="filled">
          {snackbar.message}
        </MuiAlert>
      </Snackbar>
    </>
  );
}

function DragAndDrop(props) {
  const onDrop = useCallback((acceptedFiles, fileRejections) => {
    acceptedFiles.forEach((file) => {
      props.onAddFile && props.onAddFile(file);
    });
    fileRejections.forEach((file) => {
      props.onRejectFile && props.onRejectFile(file.file, file.errors);
    });
  }, []);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({ onDrop, accept: [".png", ".jpg", ".jpeg"] });

  const baseStyle = {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px",
    borderWidth: 2,
    borderRadius: 2,
    borderColor: "#eeeeee",
    borderStyle: "dashed",
    backgroundColor: "#fafafa",
    color: "#bdbdbd",
    outline: "none",
    transition: "border .24s ease-in-out",
  };

  const activeStyle = {
    borderColor: "#2196f3",
  };

  const acceptStyle = {
    borderColor: "#00e676",
  };

  const rejectStyle = {
    borderColor: "#ff1744",
  };

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  return (
    <div className="container">
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} />
        <p>
          Drag 'n' drop
          <br />
          csv, xls, xlsx
          <br />
          Or click to select files
        </p>
      </div>
    </div>
  );
}

function SvetnaVehicleHistoryGrid(props) {
  const id = props.itemId;
  const dispatch = useDispatch();
  const historyItems = useSelector((state) => state.utility.vehicle_history);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (Number(id) > 0) {
      setLoading(true);
      getNewData();
    }
  }, [id]);

  const getNewData = () => {
    dispatch(
      utilityActions.getUtility(
        "vehicle_history",
        {
          perPage: 1000,
          filters: [
            {
              field: "preimport_vehicle_id",
              operator: "eq",
              value: id,
            },
          ],
          orders: [
            {
              field: "id",
              direction: "desc",
            },
          ],
        },
        () => {
          setLoading(false);
        }
      )
    );
  };

  const historyColumns = [
    {
      width: 3,
      title: "Field",
      field: "target_field",
    },
    {
      width: 3,
      title: "New Value",
      field: "value",
    },
    {
      width: 3,
      title: "User",
      field: "user.name",
    },
    {
      width: 3,
      title: "Date",
      field: "updated_at",
    },
  ];

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Button variant="contained" color="primary" onClick={getNewData}>
          Refresh History
        </Button>
      </Grid>
      <Grid item>
        {loading && <CircularProgress />}
        {!loading && (
          <ResponsiveGrid
            loading={loading}
            columns={historyColumns}
            data={historyItems.items}
            usePagination={false}
          />
        )}
      </Grid>
    </Grid>
  );
}

function SvetnaVehicleDetails(props) {
  const dispatch = useDispatch();
  const classes = useVehicleDetailsPageStyles();
  const webUser = useSelector((state) => state.authentication.webUser);
  const user = useSelector((state) => state.authentication.user);
  const hasBuyerRole = user.has_buyer_role || false;
  const item = useSelector(
    (state) => state.utility.svetna_preimport_vehicles_item
  );
  const svetnaSelectDealers = useSelector(
    (state) => state.utility.svetna_select_dealers
  );
  const svetnaSelectBuyers = useSelector(
    (state) => state.utility.svetna_select_buyers
  );
  const [loading, setLoading] = useState(false);
  const [bodyWorkRows, setBodyWorkRows] = useState(1);
  const [snackbar, setSnackbar] = useState({
    open: false,
    severity: "info", //error, warning, info, success
    message: "default message",
    duration: 6000,
  });

  const [open, toggleOpen] = useState(false);
  const [dialogValue, setDialogValue] = useState({
    name: "",
  });

  useEffect(() => {
    dispatch(
      utilityActions.getUtility("svetna_select_dealers", {
        perPage: 1000,
        orders: [
          {
            field: "name",
            direction: "asc",
          },
        ],
      })
    );
  }, []);

  const id = props.itemId;
  const filter = createFilterOptions();

  useEffect(() => {
    if (id) {
      if (!item) {
        getNewData();
      } else if (Number(item.id) != -Number(id)) {
        getNewData();
      }
    }
  }, [id]);

  const getNewData = () => {
    dispatch(
      utilityActions.getOneUtility(
        "svetna_preimport_vehicles",
        id,
        {
          relationsAdd: ["history"],
        },
        () => {
          setLoading(false);
        }
      )
    );
    dispatch(
      utilityActions.getUtility("svetna_select_buyers", {
        filters: [
          { field: "clientid", operator: "eq", value: webUser.clientid },
        ],
        relationsAdd: ["cloud_user"],
      })
    );
  };

  const updateLocal = (event) => {
    let { name, value } = event.target;
    if (name === "buyer_user_id" && value === "") {
      value = null;
    }
    console.log({ name, value });
    doUpdateLocal(name, value);
  };

  const updateLocalDetails = (event) => {
    let { name, value } = event.target;
    const newName = "details";
    const newValue = {
      ...item.details,
      [name]: value,
    };
    doUpdateLocal(newName, newValue);
  };

  const updateLocalSeller = (item) => {
    const newName = "seller";
    doUpdateLocal(newName, item);
    doUpdateLocal("seller_id", item.id);
  };

  const doUpdateLocal = (name, value) => {
    dispatch(
      utilityActions.updateOneUtilityLocal("svetna_preimport_vehicles", {
        [name]: value,
      })
    );
  };

  const handleSave = () => {
    delete item.loading;
    setLoading(true);
    dispatch(
      utilityActions.updateUtility(
        "svetna_preimport_vehicles",
        item.id,
        item,
        () => {
          getNewData();
          setSnackbar({
            open: true,
            severity: "success",
            message: "Vehicle Updated",
            duration: 6000,
          });
        }
      )
    );
  };

  const handleClose = () => {
    setDialogValue({
      name: "",
    });

    toggleOpen(false);
  };

  const handleSubmitNewDealer = (event) => {
    event.preventDefault();
    setLoading(true);
    utilityService
      .addUtility("svetna_dealers", {
        client_id: webUser.clientid,
        name: dialogValue.name,
        is_buyer: false,
        is_seller: true,
      })
      .then((response) => {
        doUpdateLocal("seller", response);
        doUpdateLocal("seller_id", response.id);
        dispatch(
          utilityActions.getUtility("svetna_select_dealers", {
            perPage: 1000,
            orders: [
              {
                field: "name",
                direction: "asc",
              },
            ],
          })
        );
      })
      .finally(() => {
        setLoading(false);
      });

    handleClose();
  };

  const handleNavigateToPhotos = () => {
    hashHistory.push(`/photoupload/${item.vehicle_id}`);
  };

  return (
    <>
      <Backdrop open={loading} style={{ zIndex: 100 }} />
      <Snackbar
        color={snackbar.severity}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={snackbar.open}
        autoHideDuration={snackbar.duration}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <MuiAlert severity={snackbar.severity} variant="filled">
          {snackbar.message}
        </MuiAlert>
      </Snackbar>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <form onSubmit={handleSubmitNewDealer}>
          <DialogTitle id="form-dialog-title">
            Add a new Svetna Dealer
          </DialogTitle>
          <DialogContent>
            <DialogContentText>Didn't find your seller?</DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              name="name"
              value={dialogValue.name}
              onChange={(event) =>
                setDialogValue({
                  ...dialogValue,
                  name: event.target.value,
                })
              }
              label="Seller Name"
              type="text"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button type="submit" color="primary">
              Add
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      {item && item.id ? (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            Has Buyer Role {JSON.stringify(hasBuyerRole)}
          </Grid>
          <Grid item xs={12} style={{ marginBottom: 10 }}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSave}
              disabled={loading}
            >
              Save Changes
            </Button>{" "}
            {Number(item.vehicle_id) > 0 && (
              <Button
                variant="contained"
                color="primary"
                onClick={handleNavigateToPhotos}
                disabled={loading}
              >
                DVS Photo Page
              </Button>
            )}{" "}
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              variant="outlined"
              label="VIN"
              type="text"
              name="vin"
              placeholder="VIN"
              value={item.vin || ""}
              onChange={updateLocal}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              variant="outlined"
              label="Model Year"
              type="number"
              name="year"
              placeholder="Model Year"
              value={item.year || ""}
              onChange={updateLocal}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              variant="outlined"
              label="Make"
              type="text"
              name="make"
              placeholder="Make"
              value={item.make || ""}
              onChange={updateLocal}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              variant="outlined"
              label="Model"
              type="text"
              name="model"
              placeholder="Model"
              value={item.model || ""}
              onChange={updateLocal}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              variant="outlined"
              label="Odometer"
              type="number"
              name="odo_value"
              placeholder="Odometer"
              value={item.odo_value || ""}
              onChange={updateLocal}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormControl component="fieldset">
              <FormLabel component="legend">Odo Type</FormLabel>
              <RadioGroup
                row
                aria-label="odometer type"
                name="odo_type"
                defaultValue="km"
                value={item.odo_type}
                onChange={updateLocal}
              >
                <FormControlLabel
                  value="km"
                  control={<Radio color="primary" />}
                  label="KM"
                  labelPlacement="end"
                />
                <FormControlLabel
                  value="mi"
                  control={<Radio color="primary" />}
                  label="MI"
                  labelPlacement="end"
                />
              </RadioGroup>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={4}>
            <FormControl
              variant="outlined"
              className={classes.formControl}
              fullWidth
            >
              <InputLabel id="has_recall-label">Has Recall</InputLabel>
              <Select
                labelId="has_recall-label"
                id="has_recall"
                name="has_recall"
                value={item.details.has_recall}
                onChange={updateLocalDetails}
                label="Has Recall"
              >
                <MenuItem value={false}>No</MenuItem>
                <MenuItem value={true}>Yes</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={4}>
            <FormControl
              variant="outlined"
              className={classes.formControl}
              fullWidth
            >
              <InputLabel id="requires_windshield_replacement-label">
                Windshield Replacement
              </InputLabel>
              <Select
                labelId="requires_windshield_replacement-label"
                id="requires_windshield_replacement"
                name="requires_windshield_replacement"
                value={item.details.requires_windshield_replacement}
                onChange={updateLocalDetails}
                label="Windshield Replacement"
              >
                <MenuItem value={false}>No</MenuItem>
                <MenuItem value={true}>Yes</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={4}>
            <FormControl
              variant="outlined"
              className={classes.formControl}
              fullWidth
            >
              <InputLabel id="requires_tires-label">Tires Required</InputLabel>
              <Select
                labelId="requires_tires-label"
                id="requires_tires"
                name="requires_tires"
                value={item.details.requires_tires}
                onChange={updateLocalDetails}
                label="Tires Required"
              >
                <MenuItem value={false}>No</MenuItem>
                <MenuItem value={true}>Yes</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={4}>
            <FormControl
              variant="outlined"
              className={classes.formControl}
              fullWidth
            >
              <InputLabel id="requires_body_work-label">
                Body Work Required
              </InputLabel>
              <Select
                labelId="requires_body_work-label"
                id="requires_body_work"
                name="requires_body_work"
                value={item.details.requires_body_work}
                onChange={updateLocalDetails}
                label="Body Work Required"
              >
                <MenuItem value={false}>No</MenuItem>
                <MenuItem value={true}>Yes</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={8}>
            <TextField
              fullWidth
              id="body_work_details"
              name="body_work_details"
              label="Body Work Details"
              multiline
              rows={bodyWorkRows}
              value={item.details.body_work_details || ""}
              variant="outlined"
              onChange={updateLocalDetails}
              onFocus={() => setBodyWorkRows(4)}
              onBlur={() => setBodyWorkRows(1)}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              variant="outlined"
              label="Purchase Price before taxes"
              type="number"
              name="purchase_price_before_taxes"
              placeholder="Purchase Price before taxes"
              value={item.details.purchase_price_before_taxes || ""}
              onChange={updateLocalDetails}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              variant="outlined"
              label="MMR at purchase"
              type="number"
              name="purchase_mmr"
              placeholder="MMR at purchase"
              value={item.details.purchase_mmr || ""}
              onChange={updateLocalDetails}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            {svetnaSelectBuyers.loading ? (
              <CircularProgress size="small" />
            ) : (
              <FormControl
                variant="outlined"
                className={classes.formControl}
                fullWidth
              >
                <InputLabel id="buyer_user_id-label">Buyer</InputLabel>
                <Select
                  labelId="buyer_user_id-label"
                  id="buyer_user_id"
                  name="buyer_user_id"
                  value={item.buyer_user_id || ""}
                  onChange={updateLocal}
                  label="Buyer"
                >
                  <MenuItem value={""}>No Buyer</MenuItem>
                  {svetnaSelectBuyers.items.map((buyer) => {
                    if (!buyer.cloud_user) {
                      return null;
                    }
                    return (
                      <MenuItem
                        key={String(buyer.cloud_user.id)}
                        value={buyer.cloud_user.id}
                      >
                        {buyer.cloud_user.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            )}
          </Grid>

          <Grid item xs={12} sm={6}>
            {svetnaSelectDealers.loading ? (
              <CircularProgress size="small" />
            ) : (
              <Autocomplete
                fullWidth
                value={item.seller || {}}
                onChange={(event, newValue) => {
                  if (typeof newValue === "string") {
                    // timeout to avoid instant validation of the dialog's form.
                    setTimeout(() => {
                      toggleOpen(true);
                      setDialogValue({
                        name: newValue,
                      });
                    });
                  } else if (newValue && newValue.inputValue) {
                    toggleOpen(true);
                    setDialogValue({
                      name: newValue.inputValue,
                    });
                  } else {
                    updateLocalSeller(newValue);
                  }
                }}
                filterOptions={(options, params) => {
                  const filtered = filter(options, params);

                  if (params.inputValue !== "") {
                    filtered.push({
                      inputValue: params.inputValue,
                      name: `Add "${params.inputValue}"`,
                    });
                  }

                  return filtered;
                }}
                id="free-solo-dialog-svetna-seller"
                options={svetnaSelectDealers.items}
                getOptionLabel={(option) => {
                  // e.g value selected with enter, right from the input
                  if (typeof option === "string") {
                    return option;
                  }
                  if (option.inputValue) {
                    return option.inputValue;
                  }
                  return option.name;
                }}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                renderOption={(option) => option.name}
                freeSolo
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth={true}
                    label="Seller"
                    variant="outlined"
                  />
                )}
              />
            )}
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              multiline
              rows={4}
              variant="outlined"
              label="Additional Declarations"
              type="text"
              name="additional_information"
              placeholder="Additional Declarations"
              value={item.details.additional_information || ""}
              onChange={updateLocalDetails}
            />
          </Grid>
        </Grid>
      ) : (
        <CircularProgress />
      )}
    </>
  );
}

export { SvetnaVehicleDetailsPage as default };
