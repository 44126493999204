import React from "react";
import { connect } from "react-redux";
import { Link as RouterLink } from "react-router-dom";

import { userActions, alertActions } from "../_actions";

import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";

import LaunchTwoToneIcon from "@material-ui/icons/LaunchTwoTone";

import { PageWithAppDrawer2 } from "../_components/main/PageWithAppDrawer2";

class RegisterRequestedPage extends React.Component {
  constructor(props) {
    super(props);

    // reset login status
    this.props.dispatch(userActions.logout());

    // const env = getEnvironment("reset");

    this.state = {};
  }

  componentDidMount() {
    const { dispatch } = this.props;
    const { environmentDVS } = this.state;
    dispatch(userActions.setEnvironment(environmentDVS));
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(alertActions.clear());
  }

  handleClearAlert = () => {
    const { dispatch } = this.props;
    dispatch(alertActions.clear());
  };

  render() {
    const { classes } = this.props;

    return (
      <PageWithAppDrawer2
        pageTitle={"Registration Requested"}
        showSideBar={false}
        showHomeLinks={true}
      >
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <div className={classes.paper}>
            <Typography variant="h5">
              Thanks for registering with DVS
            </Typography>
            <Typography style={{ marginTop: 20 }}>
              A member of the DVS staff has received your request and will make
              sure that all required paperwork has been completed.
            </Typography>
            <Typography style={{ marginTop: 20 }}>
              There are documents that must be completed before DVS can fully
              service our RI customers. Below is a link to the list of documents
              that are typically required.
            </Typography>
            <RouterLink to="/registration-documents" target="_blank">
              Registration Documents
              <LaunchTwoToneIcon style={{ fontSize: 16 }} color="primary" />
            </RouterLink>
          </div>
        </Container>
      </PageWithAppDrawer2>
    );
  }
}

const styles = (theme) => ({
  "@global": {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
});

function mapStateToProps(state) {
  const { alert } = state;
  const { loggingIn, environmentDVS } = state.authentication;
  return {
    alert,
    loggingIn,
    environmentDVS,
  };
}

// const connectedRegisterRequestedPage = connect(mapStateToProps)(RegisterRequestedPage);
// export default withRouter(connectedRegisterRequestedPage);

// export default withRouter(connect(mapStateToProps)(RegisterRequestedPage));

const connectedRegisterRequestedPage = connect(mapStateToProps)(
  withStyles(styles)(RegisterRequestedPage)
);
export { connectedRegisterRequestedPage as RegisterRequestedPage };
