import {
  Button,
  CircularProgress,
  Grid,
  makeStyles,
  MenuItem,
  Paper,
  Select,
  TextField,
} from "@material-ui/core";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { utilityActions } from "../_actions";
import FiltersGrid from "../_components/generic/FiltersGrid";

import { PageWithAppDrawer2 } from "../_components/main/PageWithAppDrawer2";
import { utilityService } from "../_services";

// import CheckCircleTwoToneIcon from "@material-ui/icons/CheckCircleTwoTone";
import HighlightOffTwoToneIcon from "@material-ui/icons/HighlightOffTwoTone";

export default function NhtsaConformityEditPage(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const items = useSelector((state) => state.utility.nhtsa_conformity_items);

  // const [loading, setLoading] = useState(false);
  const [vin, setVin] = useState("2GC2KREG7K1137618");
  const [vinDetails, setVinDetails] = useState(null);

  const [availableFilters, setAvailableFilters] = useState([
    {
      name: "Name",
      field: "fmvss_name",
      operator: "like",
      value: "",
      type: "text",
      default: "",
    },
  ]);

  const handleFilterChange = async (newFilter) => {
    const newFilters = availableFilters.map((filter) => {
      if (filter.field !== newFilter.field) return filter; // not the droid we're looking for, return unchanged
      filter.value = newFilter.value;
      return filter;
    });
    await setAvailableFilters(newFilters);
  };

  const getVinDetails = () => {
    utilityService
      .getUtility("vehicles", {
        perPage: 1,
        orders: [
          {
            field: "vehicleid",
            direction: "desc",
          },
        ],
        filters: [
          {
            field: "vehiclevin",
            operator: "eq",
            value: vin,
          },
        ],
        relations: ["blackbook_info"],
      })
      .then((response) => {
        setVinDetails(response.data[0]);
        console.log(response.data[0]);
      });
  };

  useEffect(() => {
    dispatch(
      utilityActions.getUtility(
        "nhtsa_conformity_items",
        {
          perPage: 1000,
        },
        () => {}
      )
    );
  }, [dispatch]);

  return (
    <PageWithAppDrawer2 pageTitle="RI DOT Status">
      <Paper className={classes.paper}>
        <FiltersGrid
          availableFilters={availableFilters}
          onChange={handleFilterChange}
        />
        {items.loading ? (
          <CircularProgress />
        ) : (
          <Grid container style={{ marginTop: 20 }}>
            <Grid container>
              <Grid item xs={2}>
                <Button
                  color="primary"
                  size="small"
                  variant="contained"
                  onClick={getVinDetails}
                >
                  Check VIN
                </Button>
              </Grid>
              <Grid item xs={10}>
                <TextField
                  placeholder="VIN"
                  value={vin}
                  onChange={(e) => setVin(e.target.value)}
                />
              </Grid>
            </Grid>
            {vinDetails && (
              <Grid container style={{ border: "1px solid black", padding: 5 }}>
                <Grid item>
                  VIN: {vinDetails.vehiclevin}
                  <br />
                  {vinDetails.blackbook_info.map((bb, index) => {
                    const j = bb.blackbook_json;
                    return (
                      <Fragment key={index}>
                        {j.model_year} {j.make} {j.model} {j.series},{" "}
                        {j.class_name}, GVW {j.gvw} <br />
                      </Fragment>
                    );
                  })}
                </Grid>
              </Grid>
            )}
            <Grid container style={{ marginTop: 20 }}>
              <Grid item xs={2} style={{ fontWeight: "bold" }}>
                FMVSS Number
              </Grid>
              <Grid item xs={4} style={{ fontWeight: "bold" }}>
                FMVSS Description
              </Grid>
              <Grid item xs={2} style={{ fontWeight: "bold" }}>
                Car
              </Grid>
              <Grid item xs={2} style={{ fontWeight: "bold" }}>
                Truck
              </Grid>
              <Grid item xs={2} style={{ fontWeight: "bold" }}>
                Heavy Truck
              </Grid>
            </Grid>
            {items.items.map((item, index) => (
              <ConformityItem item={item} key={index} index={index} />
            ))}
          </Grid>
        )}
      </Paper>
    </PageWithAppDrawer2>
  );
}

function ConformityItem(props) {
  const dispatch = useDispatch();
  const { item, index } = props;
  const even = Number(index) % 2 === 0;

  const updateItem = (e) => {
    const value = e.target.value;
    const name = e.target.name;

    dispatch(
      utilityActions.updateUtility("nhtsa_conformity_items", item.id, {
        [name]: value,
      }),
      (response) => {}
    );
  };

  return (
    <Grid container style={{ backgroundColor: even ? "lightgrey" : "inherit" }}>
      <Grid item xs={2}>
        {item.fmvss_number}
      </Grid>
      <Grid item xs={4}>
        {item.fmvss_name}
      </Grid>
      <Grid
        item
        xs={2}
        style={{
          flex: 1,
          flexDirection: "row",
          justifyContent: "space-between",
          alignContent: "space-between",
        }}
      >
        {!item.car && <HighlightOffTwoToneIcon style={{ color: "red" }} />}
        <Select name="car" value={item.car} onChange={updateItem}>
          <MenuItem value={0}>No</MenuItem>
          <MenuItem value={1}>Yes</MenuItem>
        </Select>
        {item.car === 1 && (
          <Select
            name="car_default"
            value={item.car_default || ""}
            displayEmpty
            onChange={updateItem}
          >
            <MenuItem value={""}>No Default</MenuItem>
            <MenuItem value={"O"}>Original</MenuItem>
            <MenuItem value={"M"}>Modified</MenuItem>
            <MenuItem value={"N"}>Not Applicable</MenuItem>
          </Select>
        )}
      </Grid>
      <Grid item xs={2}>
        {!item.truck && <HighlightOffTwoToneIcon style={{ color: "red" }} />}
        <Select name="truck" value={item.truck} onChange={updateItem}>
          <MenuItem value={0}>No</MenuItem>
          <MenuItem value={1}>Yes</MenuItem>
        </Select>
        {item.truck === 1 && (
          <Select
            name="truck_default"
            value={item.truck_default || ""}
            displayEmpty
            onChange={updateItem}
          >
            <MenuItem value={""}>No Default</MenuItem>
            <MenuItem value={"O"}>Original</MenuItem>
            <MenuItem value={"M"}>Modified</MenuItem>
            <MenuItem value={"N"}>Not Applicable</MenuItem>
          </Select>
        )}
      </Grid>
      <Grid item xs={2}>
        {!item.truck_heavy && (
          <HighlightOffTwoToneIcon style={{ color: "red" }} />
        )}
        <Select
          name="truck_heavy"
          value={item.truck_heavy}
          onChange={updateItem}
        >
          <MenuItem value={0}>No</MenuItem>
          <MenuItem value={1}>Yes</MenuItem>
        </Select>
        {item.truck_heavy === 1 && (
          <Select
            name="truck_heavy_default"
            value={item.truck_heavy_default || ""}
            displayEmpty
            onChange={updateItem}
          >
            <MenuItem value={""}>No Default</MenuItem>
            <MenuItem value={"O"}>Original</MenuItem>
            <MenuItem value={"M"}>Modified</MenuItem>
            <MenuItem value={"N"}>Not Applicable</MenuItem>
          </Select>
        )}
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
}));

export { NhtsaConformityEditPage };
