import React from "react";
import { Link as RouterLink } from "react-router-dom";
// import Link from "@material-ui/core/Link";
import { connect, useSelector, useDispatch } from "react-redux";
import { withStyles } from "@material-ui/core/styles";

import { utilityActions } from "../_actions";

import { PageWithAppDrawer2 } from "../_components/main/PageWithAppDrawer2";
// import { AuctionModal } from "../_components/admin/AuctionModal";
import ResponsiveGrid from "../_components/generic/ResponsiveGrid";
import FiltersGrid from "../_components/generic/FiltersGrid";

import { Paper, Button } from "@material-ui/core";
import { utilityService } from "../_services";
import { hashHistory } from "../_helpers";

class AuctionsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: this.props.auctions.current_page || 1,
      per_page: this.props.auctions.per_page || 10,
      total: this.props.auctions.total || 0,
      filter: "",
      modalOpen: false,
      modalTitle: null,
      modalContent: [],
    };
  }
  componentDidMount() {
    this.props.dispatch(
      utilityActions.getUtility("us_states", {
        perPage: 100,
        orders: [
          {
            field: "stateabbreviation",
            direction: "asc",
          },
        ],
      })
    );
  }

  componentWillUnmount() {
    const { dispatch } = this.props;

    dispatch(utilityActions.clearUtility("auctions"));
  }

  render() {
    const { classes } = this.props;
    return (
      <PageWithAppDrawer2 pageTitle={"Auctions"}>
        <Paper className={classes.paper}>
          <Button
            color="primary"
            variant="contained"
            onClick={this.handleAddItem}
          >
            Add Auction
          </Button>
          <AuctionTable />
        </Paper>
      </PageWithAppDrawer2>
    );
  }

  handleAddItem = () => {
    utilityService
      .addUtility("auctions", { auctionname: "NEW AUCTION" })
      .then((response) => {
        hashHistory.push(`/admin/auctions/${response.id}`);
      });
  };
}

function AuctionTable(props) {
  // const classes = useStyles();
  const dispatch = useDispatch();
  const auctions = useSelector((state) => state.utility.auctions);
  const columns = [
    {
      width: 2,
      title: "Name",
      field: "auctionname",
      dataRender: (item) => (
        <RouterLink to={`/admin/auctions/${item.id}`}>
          {item.auctionname}
        </RouterLink>
      ),
    },
    {
      width: 2,
      title: "City",
      field: "auctioncity",
    },
    {
      width: 1,
      title: "State",
      field: "state.stateabbreviation",
    },
    {
      width: 2,
      title: "Phone",
      field: "auctionphonenumber",
    },
    {
      width: 2,
      title: "Contact",
      field: "auctioncontactname",
    },
  ];

  const [availableFilters, setAvailableFilters] = React.useState([
    {
      name: "Name",
      field: "auctionname",
      operator: "like",
      value: "",
      type: "text",
      default: "",
    },
    {
      name: "City",
      field: "auctioncity",
      operator: "like",
      value: "",
      type: "text",
      default: "",
    },
    {
      name: "Contact Name",
      field: "auctioncontactname",
      operator: "like",
      value: "",
      type: "text",
      default: "",
    },
  ]);

  const [getData, setGetData] = React.useState(false);

  const [page, setPage] = React.useState(
    auctions.page ? Number(auctions.page) - 1 : 0
  );
  const [perPage, setPerPage] = React.useState(
    auctions.per_page ? Number(auctions.per_page) : 0
  );
  const [total, setTotal] = React.useState(
    auctions.total ? Number(auctions.total) : 0
  );

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
    setGetData(true);
  };
  const handleChangeRowsPerPage = (event) => {
    const value = parseInt(event.target.value);
    setPerPage(value);
    setGetData(true);
  };
  React.useEffect(() => {
    if (getData === true) {
      setGetData(false);
      getNewData();
    }
  }, [getData]);

  React.useEffect(() => {
    setTotal(auctions.total ? Number(auctions.total) : 0);
    setPage(auctions.page ? Number(auctions.page) : 0);
    setPerPage(auctions.per_page ? Number(auctions.per_page) : 10);
  }, [auctions]);

  React.useEffect(() => {
    if (auctions.items.length === 0) {
      getNewData();
    }
  }, []);

  const getNewData = () => {
    let newFilters = availableFilters
      .filter((filter) => {
        if (["", null].includes(filter.value)) return false;
        if (
          ["isnull", "notnull"].includes(filter.operator) &&
          filter.value === false
        )
          return false;
        return true;
      })
      .map((filter) => {
        return {
          field: filter.field,
          operator: filter.operator,
          value: filter.value,
        };
      });
    dispatch(
      utilityActions.getUtility("auctions", {
        page,
        perPage,
        filters: newFilters,
        orders: [{ field: "auctionname", direction: "asc" }],
      })
    );
  };

  const handleFilterChange = (newFilter) => {
    const newFilters = availableFilters.map((filter) => {
      if (filter.field !== newFilter.field) return filter; // not the droid we're looking for, return unchanged
      filter.value = newFilter.value;
      return filter;
    });
    setAvailableFilters(newFilters);
    setPage(1);
    setGetData(true);
  };

  return (
    <>
      <FiltersGrid
        availableFilters={availableFilters}
        onChange={handleFilterChange}
      />
      <ResponsiveGrid
        loading={auctions.loading}
        columns={columns}
        data={auctions.items}
        page={page - 1} // API has a 1 based page count, Material UI has a zero based page count
        perPage={perPage}
        total={total}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        // showActions
        // onDownload={handleDownload}
      />
    </>
  );
}

// const useStyles = makeStyles(theme => ({
//   paper: {
//     padding: theme.spacing(2),
//     display: "flex",
//     overflow: "auto",
//     flexDirection: "column",
//     width: "100%"
//   }
// }));

const styles = (theme) => ({
  root: {
    display: "flex",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
});

function mapStateToProps(state) {
  const { authentication, utility } = state;
  const { user } = authentication;
  const { us_states, auctions } = utility;
  return {
    user,
    auctions,
    us_states,
  };
}

const connectedAuctionsPage = connect(mapStateToProps)(
  withStyles(styles)(AuctionsPage)
);
export { connectedAuctionsPage as AuctionsPage };
