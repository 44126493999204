import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { connect, useSelector, useDispatch } from "react-redux";

import { withStyles } from "@material-ui/core/styles";
import withWidth from "@material-ui/core/withWidth";

import { PageWithAppDrawer2 } from "../_components/main/PageWithAppDrawer2";
import { utilityActions } from "../_actions";
import { Paper, Grid, Button } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";

class InvoiceDetailsPage extends React.Component {
  constructor(props) {
    super(props);
    const { id } = this.props.match ? this.props.match.params : {};
    this.state = {
      id,
      reload: false,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { id } = nextProps.match ? nextProps.match.params : {};
    if (id !== prevState.id) {
      return {
        ...prevState,
        id,
        reload: true,
      };
    }
    return false;
  }

  async componentDidMount() {
    // const { items: clients } = this.props.utility.client_types;
    // if (clients.length === 0) {
    //   await this.props.dispatch(
    //     utilityActions.getUtility("clients", {
    //       perPage: 1000,
    //       filters: [
    //         {
    //           field: "clientactive",
    //           operator: "eq",
    //           value: "2"
    //         }
    //       ],
    //       orders: [{ field: "clientname", direction: "asc" }]
    //     })
    //   );
    // }
  }

  render() {
    const { id, reload } = this.state;
    const { classes } = this.props;

    if (reload === true) {
      this.setState({ reload: false }, () => {
        this.componentDidMount();
      });
      return <></>;
    }

    return (
      <PageWithAppDrawer2 pageTitle={`Invoice #${id}`}>
        <RouterLink to="/invoices">All Invoices</RouterLink>
        <Paper className={classes.paper}>
          <InvoiceDetails itemId={id} />
        </Paper>
      </PageWithAppDrawer2>
    );
  }
}

function InvoiceDetails(props) {
  const dispatch = useDispatch();
  const item = useSelector((state) => state.utility.invoices_item);

  const id = props.itemId;

  const getNewData = () => {
    // console.log("getNewData");
    dispatch(utilityActions.getOneUtility("invoices", id, []));
  };

  React.useEffect(() => {}, [item]);

  React.useEffect(() => {}, []);

  React.useEffect(() => {
    if (id) {
      if (!item) {
        getNewData();
      } else if (Number(item.id) !== Number(id)) {
        getNewData();
      }
    }
  }, [id, item]);



  // const updateLocal = (event) => {
  //   const { name, value } = event.target;
  //   console.log("updateLocal", { name, value });
  //   dispatch(
  //     utilityActions.updateOneUtilityLocal("invoices", { [name]: value })
  //   );
  // };

  const handleSave = () => {
    dispatch(
      utilityActions.updateUtility("clients", item.id, item, () => {
        getNewData();
      })
    );
  };

  return (
    <>
      {item && item.id ? (
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Button variant="contained" color="primary" onClick={handleSave}>
              Save Changes
            </Button>
          </Grid>
        </Grid>
      ) : (
        <CircularProgress />
      )}
    </>
  );
}

const styles = (theme) => ({
  root: {
    display: "flex",
    fontSize: 12,
  },
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    width: "100%",
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    width: "100%",
  },
  fixedHeight: {
    height: 240,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },

  Button: {
    display: "block",
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
});

function mapStateToProps(state) {
  const { authentication, utility, batch } = state;
  const { user } = authentication;
  return {
    user,
    batch,
    utility,
  };
}

const connectedInvoiceDetailsPage = connect(mapStateToProps)(
  withWidth()(withStyles(styles)(InvoiceDetailsPage))
);
export { connectedInvoiceDetailsPage as InvoiceDetailsPage };
