import React, { useEffect, useState } from "react";
import { Link as RouterLink, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { PageWithAppDrawer2 } from "../_components/main/PageWithAppDrawer2";
import { utilityActions } from "../_actions";
import {
  Paper,
  Grid,
  Select,
  Button,
  Backdrop,
  Snackbar,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import CircularProgress from "@material-ui/core/CircularProgress";
import ModalGeneric from "../_components/generic/ModalGeneric";

export function SvetnaDealerDetailsPage() {
  const classes = useAdminUserPageStyles();
  const { id } = useParams();

  return (
    <PageWithAppDrawer2 pageTitle={`Dealer #${id}`}>
      <RouterLink
        to={{ pathname: "/svetna/dealers", state: { refresh: true } }}
      >
        All Svetna Dealers
      </RouterLink>
      <Paper className={classes.paper}>
        <DealerDetails itemId={id} />
      </Paper>
    </PageWithAppDrawer2>
  );
}

const useAdminUserPageStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    width: "100%",
  },
}));

function DealerDetails(props) {
  const dispatch = useDispatch();
  const item = useSelector((state) => state.utility.svetna_dealers_item);
  const [loading, setLoading] = useState(false);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [dealerDeleted, setDealerDeleted] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    severity: "info", //error, warning, info, success
    message: "default message",
    duration: 6000,
  });

  const id = props.itemId;

  useEffect(() => {
    if (id) {
      if (!item) {
        getNewData();
      } else if (Number(item.id) != Number(id)) {
        getNewData();
      }
    }
  }, [id]);

  const getNewData = () => {
    dispatch(
      utilityActions.getOneUtility("svetna_dealers", id, [], () => {
        setLoading(false);
      })
    );
  };

  const updateLocal = (event) => {
    let { name, value } = event.target;

    dispatch(
      utilityActions.updateOneUtilityLocal("svetna_dealers", { [name]: value })
    );
    if (name === "clientid") {
      dispatch(
        utilityActions.updateOneUtilityLocal("svetna_dealers", {
          client_id: value,
        })
      );
    }
  };

  const handleSave = () => {
    delete item.loading;
    setLoading(true);
    dispatch(
      utilityActions.updateUtility("svetna_dealers", item.id, item, () => {
        getNewData();
        setSnackbar({
          open: true,
          severity: "success",
          message: "User Updated",
          duration: 6000,
        });
      })
    );
  };

  const handleDelete = () => {
    delete item.loading;
    setLoading(true);
    dispatch(utilityActions.deleteOneUtility("svetna_dealers", item.id, []));
    setLoading(false);
    setOpenModalDelete(false);
    setDealerDeleted(true);
  };

  return (
    <>
      <Backdrop open={loading} style={{ zIndex: 100 }} />
      <Snackbar
        color={snackbar.severity}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={snackbar.open}
        autoHideDuration={snackbar.duration}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <MuiAlert severity={snackbar.severity} variant="filled">
          {snackbar.message}
        </MuiAlert>
      </Snackbar>
      {dealerDeleted ? (
        <Typography variant="h6" style={{ color: "blue" }}>
          Dealer has been deleted. This dealer can be recovered if necessary
        </Typography>
      ) : (
        <>
          {item && item.id ? (
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSave}
                  disabled={loading}
                >
                  Save Changes
                </Button>{" "}
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => setOpenModalDelete(true)}
                  disabled={loading}
                >
                  Delete Dealer
                </Button>
              </Grid>

              <Grid item xs={12} sm={4}>
                Dealer Name
              </Grid>
              <Grid item xs={12} sm={8}>
                <TextField
                  fullWidth
                  type="text"
                  name="name"
                  placeholder="Dealer Name"
                  value={item.name || ""}
                  onChange={updateLocal}
                />
              </Grid>

              <Grid item xs={12} sm={4}>
                Is Buyer?
              </Grid>
              <Grid item xs={12} sm={8}>
                <Select
                  native
                  name="is_buyer"
                  value={Number(item.is_buyer) === 1 ? "1" : "0"}
                  onChange={updateLocal}
                  style={{ fontSize: 12 }}
                  displayEmpty
                >
                  <option value="0">No</option>
                  <option value="1">Yes</option>
                </Select>
              </Grid>

              <Grid item xs={12} sm={4}>
                Is Seller?
              </Grid>
              <Grid item xs={12} sm={8}>
                <Select
                  native
                  name="is_seller"
                  value={Number(item.is_seller) === 1 ? "1" : "0"}
                  onChange={updateLocal}
                  style={{ fontSize: 12 }}
                  displayEmpty
                >
                  <option value="0">No</option>
                  <option value="1">Yes</option>
                </Select>
              </Grid>
            </Grid>
          ) : (
            <CircularProgress />
          )}
        </>
      )}
      <ModalGeneric
        open={openModalDelete}
        onClose={handleDelete}
        onCancel={() => {
          setOpenModalDelete(false);
        }}
        closeText={"Delete Dealer"}
        cancelText="Cancel"
        title={"Delete this Dealer?"}
      />
    </>
  );
}

function AdminUserClientOptions(props) {
  const clients = useSelector((state) => state.utility.clients_active);
  return (
    <>
      <Grid item xs={12} sm={4}>
        Client
      </Grid>
      <Grid item xs={12} sm={8}>
        <Select
          native
          name={`clientid`}
          value={props.item[`clientid`] || 0}
          onChange={props.onChange}
          style={{ fontSize: 12 }}
          displayEmpty
        >
          <option key={0} value={0}>
            Client
          </option>
          {clients.items.map((client, index) => (
            <option key={index} value={client.clientid}>
              {client.clientname}
            </option>
          ))}
        </Select>
      </Grid>
      <Grid item xs={12} sm={4}>
        Titles Sent
      </Grid>
      <Grid item xs={12} sm={8}>
        <Select
          native
          name={`titlessent`}
          value={props.item[`titlessent`] || 0}
          onChange={props.onChange}
          style={{ fontSize: 12 }}
        >
          <option value={0}>No</option>
          <option value={1}>Yes</option>
        </Select>
      </Grid>
      <Grid item xs={12} sm={4}>
        Title History
      </Grid>
      <Grid item xs={12} sm={8}>
        <Select
          native
          name={`titlehistory`}
          value={props.item[`titlehistory`] || 0}
          onChange={props.onChange}
          style={{ fontSize: 12 }}
        >
          <option value={0}>No</option>
          <option value={1}>Yes</option>
        </Select>
      </Grid>
      <Grid item xs={12} sm={4}>
        Report Menu
      </Grid>
      <Grid item xs={12} sm={8}>
        <Select
          native
          name={`reportmenu`}
          value={props.item[`reportmenu`] || 0}
          onChange={props.onChange}
          style={{ fontSize: 12 }}
        >
          <option value={0}>No</option>
          <option value={1}>Yes</option>
        </Select>
      </Grid>
      <Grid item xs={12} sm={4}>
        Vehicle Search
      </Grid>
      <Grid item xs={12} sm={8}>
        <Select
          native
          name={`vehiclesearch`}
          value={props.item[`vehiclesearch`] || 0}
          onChange={props.onChange}
          style={{ fontSize: 12 }}
        >
          <option value={0}>No</option>
          <option value={1}>Yes</option>
        </Select>
      </Grid>
      <Grid item xs={12} sm={4}>
        Lien Verifications
      </Grid>
      <Grid item xs={12} sm={8}>
        <Select
          native
          name={`lienverifications`}
          value={props.item[`lienverifications`] || 0}
          onChange={props.onChange}
          style={{ fontSize: 12 }}
        >
          <option value={0}>No</option>
          <option value={1}>Yes</option>
        </Select>
      </Grid>
    </>
  );
}

function AdminUserAdminOptions(props) {
  return (
    <>
      <Grid item xs={12} sm={4}>
        Title Module
      </Grid>
      <Grid item xs={12} sm={8}>
        <Select
          native
          name={`titlemodule`}
          value={props.item[`titlemodule`] || 0}
          onChange={props.onChange}
          style={{ fontSize: 12 }}
        >
          <option value={0}>No</option>
          <option value={1}>Yes</option>
        </Select>
      </Grid>
      <Grid item xs={12} sm={4}>
        Client Contact Module
      </Grid>
      <Grid item xs={12} sm={8}>
        <Select
          native
          name={`clientcontactmodule`}
          value={props.item[`clientcontactmodule`] || 0}
          onChange={props.onChange}
          style={{ fontSize: 12 }}
        >
          <option value={0}>No</option>
          <option value={1}>Yes</option>
        </Select>
      </Grid>
      <Grid item xs={12} sm={4}>
        Management Module
      </Grid>
      <Grid item xs={12} sm={8}>
        <Select
          native
          name={`managementmodule`}
          value={props.item[`managementmodule`] || 0}
          onChange={props.onChange}
          style={{ fontSize: 12 }}
        >
          <option value={0}>No</option>
          <option value={1}>Yes</option>
        </Select>
      </Grid>
    </>
  );
}

export { SvetnaDealerDetailsPage as default };
