import React, { useState } from "react";
import { getEnvironmentURL } from "../../_helpers";
import { Link as RouterLink } from "react-router-dom";

import { Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import ModalGeneric from "../generic/ModalGeneric";
import SignatureComponent from "../generic/SignatureComponent";
import { useSelector } from "react-redux";
import VehicleDetailHeaderSummaryDisplay from "./VehicleDetailHeaderSummaryDisplay";

export default function VehicleDetailDocuments(props) {
  const vehicle = props.item;
  const webUser = useSelector((state) => state.authentication.webUser);

  const [modalSign, setModalSign] = useState(false);
  return (
    <>
      <VehicleDetailHeaderSummaryDisplay
        vehicle={{
          vin: props?.item?.vehiclevin,
          year: props?.item?.yr,
          make: props?.item?.make,
          model: props?.item?.model,
        }}
        titleStatus={props?.item?.title_status?.statusname}
        timeInLatestStatus={props?.item?.time_in_latest_status}
        latestNote={props?.item?.latest_note?.note}
      />
      <Typography>
        Paperwork Batch:{" "}
        {vehicle.titledocsbatchid > 0 ? (
          <RouterLink to={`/paperworkbatches/${vehicle.titledocsbatchid}`}>
            {vehicle.titledocsbatchid}
          </RouterLink>
        ) : (
          <i>None</i>
        )}
      </Typography>
      <Button
        variant="outlined"
        color="primary"
        target="_blank"
        href={`${getEnvironmentURL()}/api/vehicles/${
          vehicle.vehicleid
        }/forms/7`}
      >
        Title Application
      </Button>
      <br />
      <Button
        variant="outlined"
        color="primary"
        target="_blank"
        href={`${getEnvironmentURL()}/api/vehicles/${
          vehicle.vehicleid
        }/forms/5`}
      >
        Certificate of Reposession
      </Button>
      <br />
      <Button
        variant="outlined"
        color="primary"
        target="_blank"
        href={`${getEnvironmentURL()}/api/vehicles/${
          vehicle.vehicleid
        }/forms/3`}
      >
        Odometer Statement
      </Button>
      <br />
      <Button
        variant="outlined"
        color="primary"
        target="_blank"
        href={`${getEnvironmentURL()}/api/vehicles/${
          vehicle.vehicleid
        }/forms/11`}
      >
        Odometer Statement (Import)
      </Button>
      <br />
      <Button
        variant="outlined"
        color="primary"
        target="_blank"
        href={`${getEnvironmentURL()}/api/vehicles/${
          vehicle.vehicleid
        }/forms/4`}
      >
        ST108 - Certificate of Gross Retail or Use Tax
      </Button>
      <br />
      <Button
        variant="outlined"
        color="primary"
        target="_blank"
        href={`${getEnvironmentURL()}/api/vehicles/${
          vehicle.vehicleid
        }/forms/8`}
      >
        Foreign Shipper Declaration
      </Button>
      <br />
      <Button
        variant="outlined"
        color="primary"
        target="_blank"
        href={`${getEnvironmentURL()}/api/vehicles/${
          vehicle.vehicleid
        }/forms/9`}
      >
        Importer Declaration
      </Button>
      <br />
      <Button
        variant="outlined"
        color="primary"
        target="_blank"
        href={`${getEnvironmentURL()}/api/vehicles/${
          vehicle.vehicleid
        }/forms/1`}
      >
        Physical Inspection
      </Button>
      <br />
      <Button
        variant="contained"
        color="primary"
        onClick={() => setModalSign(true)}
      >
        Sign Physical Inspection
      </Button>
      <br /><br/>
      <Button
        variant="outlined"
        color="primary"
        target="_blank"
        href={`${getEnvironmentURL()}/api/vehicles/${
          vehicle.vehicleid
        }/forms/27`}
      >
        Airbag Passenger Compliance
      </Button>
      <br/><br/>
      {vehicle.vehicletype} ({vehicle.yr} {vehicle.make} {vehicle.model})
      <br/>
      <Button
        variant="outlined"
        color="primary"
        target="_blank"
        href={`${getEnvironmentURL()}/api/vehicles/${
          vehicle.vehicleid
        }/forms/28?user_id=${webUser.userid}`}
      >
        USMCA Truck
      </Button>
      <Button
        variant="outlined"
        color="primary"
        target="_blank"
        href={`${getEnvironmentURL()}/api/vehicles/${
          vehicle.vehicleid
        }/forms/29?user_id=${webUser.userid}`}
      >
        USMCA Not Truck
      </Button>
      <ModalGeneric
        open={modalSign}
        onClose={() => {
          setModalSign(false);
        }}
        onCancel={() => {
          setModalSign(false);
        }}
        closeText={"Close"}
        title={"Sign Physical Inspection"}
      >
        <SignatureComponent userId={webUser.id} saveUrl={`api/vehicles/${
          vehicle.vehicleid
        }/sign/physicalInspection`} />
      </ModalGeneric>
    </>
  );
}
