import React, { useState, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import { connect, useSelector, useDispatch } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import moment from "moment";
import {
  formatNumberToStringMoney,
  generateCSV,
  downloadBlob,
} from "../_helpers";

import { utilityActions } from "../_actions";
import { utilityService } from "../_services";

import { PageWithAppDrawer2 } from "../_components/main/PageWithAppDrawer2";
import ResponsiveGrid from "../_components/generic/ResponsiveGrid";

import FiltersGrid from "../_components/generic/FiltersGrid";
import { Button, Paper } from "@material-ui/core";

import LaunchTwoToneIcon from "@material-ui/icons/LaunchTwoTone";

class UnbilledUnitsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { classes } = this.props;

    return (
      <PageWithAppDrawer2 pageTitle={"Unbilled Units"}>
        <Paper className={classes.paper}>
          <UnbilledUnitsTable />
        </Paper>
      </PageWithAppDrawer2>
    );
  }
}

function UnbilledUnitsTable(props) {
  const dispatch = useDispatch();
  const items = useSelector((state) => state.utility.vehicles_unbilled);
  const columns = [
    {
      width: 1,
      title: "Client Name",
      field: "client.clientname",
    },
    {
      width: 1,
      title: "App Type",
      field: "application_type.titleapplicationtype",
    },
    {
      width: 1,
      title: "Created",
      field: "recordcreateddate",
      dataRender: (item) => moment(item.recordcreateddate).format("MM/DD/YYYY"),
    },
    {
      width: 1,
      title: "Date to BMV",
      field: "titlesenttobmvdate",
      dataRender: (item) =>
        item.titlesenttobmvdate
          ? moment(item.titlesenttobmvdate).format("MM/DD/YYYY")
          : "",
    },
    {
      width: 1,
      title: "Auction",
      field: "auction.auctionname",
    },
    {
      width: 1,
      title: "Date to Auction",
      field: "titlesenttoauctiondate",
      dataRender: (item) =>
        item.titlesenttoauctiondate
          ? moment(item.titlesenttoauctiondate).format("MM/DD/YYYY")
          : "",
    },
    {
      width: 1,
      title: "DOT Sub Date",
      field: "submitteddotdate",
      dataRender: (item) =>
        item.submitteddotdate
          ? moment(item.submitteddotdate).format("MM/DD/YYYY")
          : "",
    },
    {
      width: 2,
      title: "VIN",
      field: "vehiclevin",
      dataRender: (item) => (
        <RouterLink to={`/vehicles/${item.vehicleid}`} target="_blank">
          {item.vehiclevin}
          <LaunchTwoToneIcon style={{ fontSize: 12 }} color="primary" />
        </RouterLink>
      ),
    },
    {
      width: 1,
      title: "Year/Make/Model",
      field: "",
      dataRender: (item) => `${item.yr} ${item.make} ${item.model}`,
    },
    {
      width: 1,
      title: "Status",
      field: "title_status.statusname",
    },
    {
      width: 1,
      title: "Charges",
      field: "fees_total",
      dataRender: (item) => formatNumberToStringMoney(item.fees_total),
    },
  ];

  const [availableFilters, setAvailableFilters] = useState([
    {
      name: "Created",
      field: "recordcreateddate",
      operator: ">=",
      value: "2017-01-01 00:00:00",
      type: "hidden",
      default: "",
    },
    {
      name: "Invoice ID",
      field: "invoiceid",
      operator: "eq",
      value: "0",
      type: "hidden",
      default: "",
    },
    {
      name: "Cancelled",
      field: "cancelled",
      operator: "eq",
      value: "0",
      type: "hidden",
      default: "",
    },
    {
      name: "Client Name",
      field: "client.clientname",
      operator: "like",
      value: "",
      type: "text",
      default: "",
    },
    {
      name: "Auction",
      field: "auction.auctionname",
      operator: "like",
      value: "",
      type: "text",
      default: "",
    },
  ]);

  const [getData, setGetData] = useState(false);

  const [page, setPage] = useState(items.page ? Number(items.page) - 1 : 0);
  const [perPage, setPerPage] = useState(
    items.per_page ? Number(items.per_page) : 50
  );
  const [total, setTotal] = useState(items.total ? Number(items.total) : 0);

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
    setGetData(true);
  };
  const handleChangeRowsPerPage = (event) => {
    const value = parseInt(event.target.value);
    setPerPage(value);
    setGetData(true);
  };
  useEffect(() => {
    if (getData === true) {
      setGetData(false);
      getNewData();
    }
  }, [getData]);

  useEffect(() => {
    setTotal(items.total ? Number(items.total) : 0);
    setPage(items.page ? Number(items.page) : 0);
    setPerPage(items.per_page ? Number(items.per_page) : 10);
  }, [items]);

  useEffect(() => {
    if (items.items.length === 0) {
      getNewData();
    }
  }, []);

  const handleDownload = () => {
    console.log("handleDownload");
    getNewData(true);
  };

  const getNewData = async (download = false) => {
    let newFilters = availableFilters
      .filter((filter) => {
        if (["", null].includes(filter.value)) return false;
        if (
          ["isnull", "notnull"].includes(filter.operator) &&
          filter.value === false
        )
          return false;
        return true;
      })
      .map((filter) => {
        return {
          field: filter.field,
          operator: filter.operator,
          value: filter.value,
        };
      });

    if (download) {
      await utilityService
        .getUtility("vehicles_unbilled", {
          page: 1,
          perPage: 10000,
          filters: newFilters,
          orders: [{ field: "recordcreateddate", direction: "asc" }],
          relations: [
            "title_status",
            "charges",
            "client",
            "application_type",
            "auction",
          ],
          params: "appends[]=fees_total",
        })
        .then((values) => {
          return generateCSV(
            [
              "Client Name",
              "App Type",
              "Created",
              "Date to BMV",
              "Auction",
              "Date to Auction",
              "Dot Sub Date",
              "VIN",
              "Year",
              "Make",
              "Model",
              "Status",
              "Charges",
            ],
            [
              "client.clientname",
              "application_type.titleapplicationtype",
              "recordcreateddate",
              "titlesenttobmvdate",
              "auction.auctionname",
              "titlesenttoauctiondate",
              "submitteddotdate",
              "vehiclevin",
              "yr",
              "make",
              "model",
              "title_status.statusname",
              "fees_total",
            ],
            values.data
          );
        })
        .then((csv) => {
          const blob = new Blob([csv], { type: "text/csv" });
          downloadBlob(blob, "unbilled_units.csv");
        })
        .catch(console.error);
      return;
    }

    dispatch(
      utilityActions.getUtility("vehicles_unbilled", {
        page,
        perPage,
        filters: newFilters,
        orders: [{ field: "recordcreateddate", direction: "asc" }],
        relations: [
          "title_status",
          "charges",
          "client",
          "application_type",
          "auction",
        ],
        params: "appends[]=fees_total",
      })
    );
  };

  const handleFilterChange = (newFilter) => {
    const newFilters = availableFilters.map((filter) => {
      if (filter.field !== newFilter.field) return filter; // not the droid we're looking for, return unchanged
      filter.value = newFilter.value;
      return filter;
    });
    setAvailableFilters(newFilters);
    setPage(1);
    setGetData(true);
  };

  return (
    <>
      <FiltersGrid
        availableFilters={availableFilters}
        onChange={handleFilterChange}
      />
      <div>
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={handleDownload}
        >
          Download All
        </Button>
      </div>
      <ResponsiveGrid
        loading={items.loading}
        columns={columns}
        data={items.items}
        page={page - 1} // API has a 1 based page count, Material UI has a zero based page count
        perPage={perPage}
        total={total}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        // showActions
        // onDownload={handleDownload}
      />
    </>
  );
}

const styles = (theme) => ({
  root: {
    display: "flex",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
});

function mapStateToProps(state) {
  const { authentication, utility } = state;
  const { user } = authentication;
  const { us_states } = utility;
  return {
    user,
    us_states,
  };
}

const connectedUnbilledUnitsPage = connect(mapStateToProps)(
  withStyles(styles)(UnbilledUnitsPage)
);
export { connectedUnbilledUnitsPage as UnbilledUnitsPage };
