import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { utilityActions } from "../../_actions";

import { KeyboardDatePicker } from "@material-ui/pickers";

import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import VehicleDetailHeaderSummaryDisplay from "./VehicleDetailHeaderSummaryDisplay";

export default function VehicleDetailDebtorInformation(props) {
  const vehicle = props.item;
  const dispatch = useDispatch();
  const states = useSelector((state) => state.utility.us_states);
  const isAdmin = useSelector((state) =>
    state.authentication.webUser.roleid === 2 ? true : false
  );

  useEffect(() => {
    if (states.items.length === 0) {
      dispatch(
        utilityActions.getUtility("us_states", {
          perPage: 1000,
          orders: [
            {
              field: "stateabbreviation",
              direction: "asc",
            },
          ],
        })
      );
    }
  }, []);

  return (
    <>
      <VehicleDetailHeaderSummaryDisplay
        vehicle={{
          vin: props?.item?.vehiclevin,
          year: props?.item?.yr,
          make: props?.item?.make,
          model: props?.item?.model,
        }}
        titleStatus={props?.item?.title_status?.statusname}
        timeInLatestStatus={props?.item?.time_in_latest_status}
        latestNote={props?.item?.latest_note?.note}
      />
      <TextField
        margin="dense"
        name={"debtorname"}
        label={"Name"}
        type="text"
        disabled={!isAdmin}
        value={vehicle.debtorname || ""}
        onChange={props.onUpdateLocal}
        fullWidth
      />
      <TextField
        margin="dense"
        name={"debtoraddress"}
        label={"Street Address"}
        type="text"
        disabled={!isAdmin}
        value={vehicle.debtoraddress || ""}
        onChange={props.onUpdateLocal}
        fullWidth
      />
      <TextField
        margin="dense"
        name={"debtorcity"}
        label={"City"}
        type="text"
        disabled={!isAdmin}
        value={vehicle.debtorcity || ""}
        onChange={props.onUpdateLocal}
        fullWidth
      />
      <FormControl fullWidth>
        <InputLabel shrink>State</InputLabel>
        <Select
          native
          name="debtorstate"
          value={vehicle.debtorstate || ""}
          onChange={props.onUpdateLocal}
          style={{ fontSize: 12 }}
          displayEmpty
          disabled={!isAdmin}
        >
          <option value="">State</option>
          {states &&
            states.items.map((state, index) => (
              <option key={index} value={state.stateid}>
                {state.stateabbreviation} - {state.statefull}
              </option>
            ))}
        </Select>
      </FormControl>
      <TextField
        margin="dense"
        name={"debtorzip"}
        label={"Zip"}
        type="text"
        disabled={!isAdmin}
        value={vehicle.debtorzip || ""}
        onChange={props.onUpdateLocal}
        fullWidth
      />
      <FormControl fullWidth>
        <InputLabel shrink>New or Used</InputLabel>
        <Select
          native
          value={vehicle.neworusedid || 0}
          onChange={props.onUpdateLocal}
          displayEmpty
          disabled={!isAdmin}
          name="neworusedid"
        >
          <option value="">Select New or Used</option>
          <option value={0}>Select New or Used</option>
          <option value={1}>New</option>
          <option value={2}>Used</option>
        </Select>
      </FormControl>
      <KeyboardDatePicker
        margin="normal"
        disabled={!isAdmin}
        name="repossessiondate"
        label="Repo/Sale Date"
        format="MM/dd/yyyy"
        value={vehicle.repossessiondate}
        onChange={(date, value) =>
          props.onUpdateLocalExplicit("repossessiondate", value)
        }
      />
      <KeyboardDatePicker
        margin="normal"
        disabled={!isAdmin}
        name="dateoflien"
        label="Date of Lien"
        format="MM/dd/yyyy"
        value={vehicle.dateoflien}
        onChange={(date, value) =>
          props.onUpdateLocalExplicit("dateoflien", value)
        }
      />
    </>
  );
}
