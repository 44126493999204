import React from "react";
import Link from "@material-ui/core/Link";
import { getEnvironmentURL } from "../../_helpers";

import iconAdobe from "../../_assets/Adobe-icon.png";
import iconExcel from "../../_assets/Excel-icon.png";
import iconWord from "../../_assets/Word-icon.png";
import iconEmail from "../../_assets/E-mail-icon.png";
import { isEmpty } from "lodash";

export default function ImagePreview(item, size = 100) {
  let imageType = `https://www.ascindy.com/paperworkImages/${item.imagename}`;
  let link = imageType;
  let isIcon = false;

  if (item && (item.imagename.includes("_-_AWS_-_") || item.cloud_url_orig)) {
    if (isEmpty(item.imagename)) {
      imageType = null;
      link = null;
    } else {
      if (!isEmpty(item.cloud_url_orig)) {
        link = item.cloud_url_orig;
      } else {
        link = `${getEnvironmentURL()}/api/vehicles/${item.vehicleid}/images/${
          item.id
        }`;
      }
      if (!isEmpty(item.cloud_url_thumb)) {
        imageType = item.cloud_url_thumb;
      } else {
        imageType = `${getEnvironmentURL()}/api/vehicles/${
          item.vehicleid
        }/images/${item.id}?size=thumb`;
      }
    }
  } else {
    // link = `${getEnvironmentURL()}/api/vehicles/${item.vehicleid}/images/${
    //   item.id
    // }`;
    // imageType = link + "?size=thumb";
  }

  if (["xls", "xlsx", "csv"].includes(item.imageextension)) {
    imageType = iconExcel;
    isIcon = true;
  } else if (["doc", "docx"].includes(item.imageextension)) {
    imageType = iconWord;
    isIcon = true;
  } else if (["htm", "html"].includes(item.imageextension)) {
    imageType = iconAdobe;
    isIcon = true;
  } else if (["pdf"].includes(item.imageextension)) {
    imageType = iconEmail;
    isIcon = true;
  }

  if (isIcon) {
    size = 50;
  }
  return (
    <Link href={link} target="_blank">
      <img src={imageType} alt={item.docnote} height={size} width={size} />
      {/* {link} */}
    </Link>
  );
}
