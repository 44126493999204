import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Font,
  Image,
} from "@react-pdf/renderer";
// import styled from "@react-pdf/styled-components";

import logo from "../../_assets/dvslogo_web_new_cropped.png";

import moment from "moment";

const debug = false;

// https://medium.com/investing-in-tech/creating-api-driven-pdfs-on-the-fly-in-the-browser-using-react-react-pdf-and-typescript-78ab4d1e7da5
// https://react-pdf.org/advanced#on-the-fly-rendering

Font.register({
  family: "Oswald",
  src: "https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf",
});

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
    fontFamily: "Helvetica",
  },
  bold: {
    fontFamily: "Helvetica-Bold",
  },
  title: {
    fontSize: 24,
    textAlign: "center",
    // fontFamily: "Oswald",
  },
  author: {
    fontSize: 12,
    textAlign: "center",
    // marginBottom: 40,
  },
  subtitle: {
    fontSize: 18,
    margin: 12,
    // fontFamily: "Oswald",
  },
  text: {
    // margin: 12,
    fontSize: 12,
    textAlign: "left",
    // fontFamily: "Times-Roman",
  },
  image: {
    marginVertical: 15,
    marginHorizontal: 100,
  },
  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: "center",
    color: "grey",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
  viewCustomerInfo: {
    border: "1px solid black",
  },
  hr: {
    borderStyle: "solid",
    borderTopColor: "black",
    borderTopWidth: 1,
  },
});

export function InvoiceOneOffDocument(props) {
  return (
    <Document>
      <InvoicePage>
        <InvoiceHeader invoiceNumber={props.item.id} />
        <InvoiceCustomerInfo item={props.item} />
        <InvoiceLine />
        <InvoiceDetailsHeader item={props.item} />
        <InvoiceDetailsTable item={props.item} />
        <InvoiceLine />
        <InvoiceTransportDetails item={props.item} />
        <FooterPageNumber />
      </InvoicePage>
    </Document>
  );
}

function InvoicePage(props) {
  return <Page style={styles.body}>{props.children}</Page>;
}

function InvoiceHeader(props) {
  return (
    <View fixed>
      <View style={{ alignItems: "center" }} debug={debug}>
        <Image
          style={[styles.image, { width: "20%" }]}
          src={logo}
          debug={debug}
        />
      </View>
      <View style={{ flexDirection: "row" }}>
        <View style={{ width: "50%" }}>
          <Text style={styles.author}>1919b S Post Rd</Text>
          <Text style={styles.author}>Indianapolis, IN 46239</Text>
        </View>
        <View style={{ width: "50%" }}>
          <Text style={styles.author}>Tel: +1.317.862.9100</Text>
          <Text style={styles.author}> Fax: +1.317.862.7625</Text>
        </View>
      </View>

      <InvoiceLine />

      <Text style={[styles.author, { marginTop: 10 }]}>
        <Text style={styles.bold}>Invoice Number:</Text>{" "}
        {props.invoiceNumber || "foo"}
      </Text>

      <InvoiceLine />
    </View>
  );
}

function InvoiceCustomerInfo(props) {
  return (
    <View style={[styles.text, { flexDirection: "row" }]}>
      <View style={{ width: "70%" }} debug={debug}>
        <Text style={styles.bold} debug={debug}>
          Customer
        </Text>
        <Text>{props.item.client_name}</Text>
        <Text> </Text>
        <Text style={styles.bold} debug={debug}>
          Address
        </Text>
        <Text>{props.item.street}</Text>
        <Text>
          {props.item.city}, {props.item.state} {props.item.zip}
        </Text>
      </View>

      <View style={{ width: "30%" }} debug={debug}>
        <Text style={styles.bold} debug={debug}>
          Invoice Date
        </Text>
        <Text debug={debug}>{moment().format("M/D/Y")}</Text>
        <Text> </Text>
        <Text style={styles.bold} debug={debug}>
          Phone
        </Text>
        <Text>{props.item.phone}</Text>
      </View>
    </View>
  );
}

function InvoiceDetailsHeader(props) {
  return (
    <View
      style={[styles.bold, styles.text, { flexDirection: "row" }]}
      debug={debug}
    >
      <View style={{ width: "30%" }}>
        <Text>VIN</Text>
      </View>
      <View style={{ width: "10%" }}>
        <Text>Year</Text>
      </View>
      <View style={{ width: "20%" }}>
        <Text>Make</Text>
      </View>
      <View style={{ width: "20%" }}>
        <Text>Model</Text>
      </View>
      <View style={{ width: "20%" }}>
        <Text>Total</Text>
      </View>
    </View>
  );
}

function InvoiceDetailsTable(props) {
  return (
    <View debug={debug}>
      <View style={{ flexDirection: "row" }} debug={debug}>
        <View style={{ width: "30%", fontSize: 10 }}>
          <Text>{props.item.vin}</Text>
        </View>
        <View style={{ width: "10%", fontSize: 10 }}>
          <Text>{props.item.year}</Text>
        </View>
        <View style={{ width: "20%", fontSize: 10 }}>
          <Text>{props.item.make}</Text>
        </View>
        <View style={{ width: "20%", fontSize: 10 }}>
          <Text>{props.item.model}</Text>
        </View>
        <View style={{ width: "20%", fontSize: 10 }}>
          <Text>${props.item.amount}</Text>
        </View>
      </View>
    </View>
  );
}

function InvoiceTransportDetails(props) {
  const item = props.item;
  return (
    <View style={[styles.text, { flexDirection: "row" }]}>
      <View style={{ width: "70%" }} debug={debug}>
        <Text style={styles.bold} debug={debug}>
          Pickup Location
        </Text>
        <Text>{item.pickup_location}</Text>
        <Text> </Text>
        <Text style={styles.bold} debug={debug}>
          Delivery Location
        </Text>
        <Text>{item.delivery_location}</Text>
      </View>

      <View style={{ width: "30%" }} debug={debug}>
        <Text style={styles.bold} debug={debug}>
          Driver
        </Text>
        <Text debug={debug}>{item.driver_name}</Text>
      </View>
    </View>
  );
}

function FooterPageNumber() {
  return (
    <Text
      style={styles.pageNumber}
      render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
      fixed
    />
  );
}

function InvoiceLine() {
  return <View style={[styles.hr, { marginTop: 10, marginBottom: 10 }]} />;
}
