import { batchConstants } from "../_constants";
import { batchService } from "../_services";
import { alertActions } from ".";

export const batchActions = {
  addBatch,
  getBatch,
  updateBatch,
  updateLocalBatch,
  addVehicleToBatch,
  removeVehicleFromBatch,
  clearBatch,
};

function addBatch(data = {}, callback) {
  return (dispatch) => {
    dispatch(request());

    batchService
      .addBatch(data)
      .then(
        (batch) => {
          dispatch(success(batch));
          return batch;
        },
        (error) => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      )
      .then((batch) => {
        if (callback) callback(batch);
      });
  };

  function request() {
    return { type: batchConstants.BATCH_ADD_REQUEST };
  }
  function success(batch) {
    return { type: batchConstants.BATCH_ADD_SUCCESS, batch };
  }
  function failure(error) {
    return { type: batchConstants.BATCH_ADD_FAILURE, error };
  }
}

function getBatch(id, relations = []) {
  if (relations === null) relations = [];

  return (dispatch) => {
    dispatch(request());

    batchService.getBatch(id, relations).then(
      (batch) => {
        dispatch(success(batch));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request() {
    return { type: batchConstants.BATCH_GET_REQUEST };
  }
  function success(batch) {
    return { type: batchConstants.BATCH_GET_SUCCESS, batch };
  }
  function failure(error) {
    return { type: batchConstants.BATCH_GET_FAILURE, error };
  }
}

function updateBatch(id, data = {}) {
  return (dispatch) => {
    dispatch(request());

    batchService.updateBatch(id, data).then(
      (batch) => {
        dispatch(success(batch));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request() {
    return { type: batchConstants.BATCH_UPDATE_REQUEST };
  }
  function success(batch) {
    return { type: batchConstants.BATCH_UPDATE_SUCCESS, batch };
  }
  function failure(error) {
    return { type: batchConstants.BATCH_UPDATE_FAILURE, error };
  }
}

function addVehicleToBatch(id, vehicleid, callback = null) {
  return (dispatch) => {
    dispatch(request());

    batchService.addVehicleToBatch(id, vehicleid).then(
      (batch) => {
        dispatch(success(batch));
        if (callback) callback(batch);
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
        if (callback) callback(error);
      }
    );
  };

  function request() {
    return { type: batchConstants.BATCH_UPDATE_REQUEST };
  }
  function success(batch) {
    return { type: batchConstants.BATCH_UPDATE_SUCCESS, batch };
  }
  function failure(error) {
    return { type: batchConstants.BATCH_UPDATE_FAILURE, error };
  }
}

function removeVehicleFromBatch(id, vehicleid) {
  return (dispatch) => {
    dispatch(request());

    batchService.removeVehicleFromBatch(id, vehicleid).then(
      (batch) => {
        dispatch(success(batch));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request() {
    return { type: batchConstants.BATCH_UPDATE_REQUEST };
  }
  function success(batch) {
    return { type: batchConstants.BATCH_UPDATE_SUCCESS, batch };
  }
  function failure(error) {
    return { type: batchConstants.BATCH_UPDATE_FAILURE, error };
  }
}

function updateLocalBatch(data) {
  return (dispatch) => {
    dispatch(update(data));
  };

  function update(data) {
    return { type: batchConstants.BATCH_SET_LOCAL, data };
  }
}

function clearBatch() {
  return (dispatch) => {
    dispatch(clear());
  };

  function clear() {
    return { type: batchConstants.BATCH_CLEAR };
  }
}
