import React, { useEffect, useState } from "react";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

export default function ModalGeneric(props) {
  const [open, setOpen] = useState(props.open);
  const closeText = props.closeText || "Close";
  const cancelText = props.cancelText || "";
  const showActions = props.showActions === false ? false : true;
  const maxWidth = props.maxWidth || false;
  const fullWidth = props.fullWidth || false;
  
  const theme = useTheme();
  let fullScreen = useMediaQuery(theme.breakpoints.down("xs"));
  if (props.fullScreen === false) fullScreen = false;

  useEffect(() => {
    setOpen(props.open);
  }, [props.open]);

  const handleCancel = () => {
    if (props.onCancel) props.onCancel();
    else setOpen(false);
  };

  const handleClose = () => {
    if (props.onClose) props.onClose();
    else setOpen(false);
  };

  return (
    <Dialog
      open={open}
      fullScreen={props.fullScreen === true ? true : fullScreen}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
    >
      {props.title && (
        <DialogTitle id="form-dialog-title">{props.title}</DialogTitle>
      )}
      <DialogContent>{props.children}</DialogContent>
      {showActions && (
        <DialogActions style={{ ...props.actionStyle }}>
          {cancelText && (
            <Button onClick={handleCancel} color="primary" variant="contained">
              {cancelText}
            </Button>
          )}

          <Button onClick={handleClose} color="primary" variant="contained">
            {closeText}
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
}
