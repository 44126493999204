import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

export default function useHasRole(roleToCheck) {
  const user = useSelector((state) => state.authentication.user);
  const [hasRole, setHasRole] = useState(true);
  const roles = user?.roles;

  useEffect(() => {
    if (roles[roleToCheck] === true){
        setHasRole(true);
    }
    else{
        setHasRole(false);
    }
  }, [user]);
  return hasRole;
}
