import React from "react";
import moment from "moment-timezone";

import { formatNumberToStringMoney } from "../../_helpers";

import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

export default function VehicleDetailInvoice(props) {
  const invoice = props.invoice || {};
  const charges = props.charges || [];
  const isInvoiced = props.invoice === null ? false : true;
  // const payments = props.payments || [];

  const totalCharges = props.charges.reduce((accumulator, charge) => {
    return accumulator + Number(charge.chargeamt);
  }, 0);

  // console.log("totalCharges", totalCharges);

  if (isInvoiced) {
    return (
      <>
        <Typography variant="h6" gutterBottom align="left">
          Invoice Details
        </Typography>
        <Grid container style={{ fontSize: 10 }}>
          <Grid item xs={12} md={8} style={{ fontWeight: "bold" }}>
            Invoice Number:
          </Grid>
          <Grid item xs={12} md={4}>
            {invoice.invoicedetailid}
          </Grid>
          <Grid item xs={12} md={8} style={{ fontWeight: "bold" }}>
            Invoice Date:
          </Grid>
          <Grid item xs={12} md={4}>
            {moment(invoice.invoicedate).format("M/D/Y")}
          </Grid>
          {charges.map((item) => {
            return (
              <Grid container key={item.vehiclechargeid}>
                <Grid item xs={12} md={8} style={{ fontWeight: "bold" }}>
                  {item.type.vehiclechargetype}
                </Grid>
                <Grid item xs={12} md={4}>
                  {formatNumberToStringMoney(item.chargeamt)}
                </Grid>
              </Grid>
            );
          })}
        </Grid>
        Total: {formatNumberToStringMoney(totalCharges)}
      </>
    );
  }

  return (
    <Typography variant="h6" gutterBottom align="center">
      <br />
      Not yet invoiced
      <br />
      Total: {formatNumberToStringMoney(totalCharges)}
      <br />
    </Typography>
  );
}
