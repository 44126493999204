import React from "react";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import ResponsiveGrid from "../generic/ResponsiveGrid";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { formatNumberToStringMoney } from "../../_helpers";
import {
  CircularProgress,
  Divider,
  TextField,
  Typography,
} from "@material-ui/core";
import { utilityService } from "../../_services";

export default function ModalBatchAddVehicle(props) {
  const [open, setOpen] = React.useState(props.open || false);
  const [vehicles, setVehicles] = React.useState([]);
  const [loadingIndex, setLoadingIndex] = React.useState([]);
  const [addedIndex, setAddedIndex] = React.useState([]);
  const [search, setSearch] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [message, setMessage] = React.useState("");

  const [columns] = React.useState([
    {
      width: 2,
      title: "Add",
      field: null,
      dataRender: (item) => {
        const updating = loadingIndex.includes(item.vehicleid) ? true : false;
        const added = addedIndex.includes(item.vehicleid) ? true : false;
        return (
          <Button
            variant="contained"
            color="primary"
            disabled={added}
            style={{
              backgroundColor: Number(item.invoiceid) > 0 ? "red" : null,
            }}
            onClick={() => {
              let addVehicle = true;
              if (Number(item.invoiceid ) > 0){
                addVehicle = window.confirm(
                  "This vehicle has already been invoiced.\nAre you REALLY REALLY SURE that you want to ADD it again?"
                );
              }
              
              if (addVehicle) {
                setLoadingIndex(loadingIndex.push(item.vehicleid));
                props.onAddVehicle(item, (data) => {
                  setAddedIndex(addedIndex.push(item.vehicleid));
                });
              }
            }}
          >
            {!added && updating && (
              <CircularProgress color="secondary" size={20} />
            )}
            {added ? `Added` : `Add`}
          </Button>
        );
      },
    },
    {
      width: 4,
      title: "VIN",
      field: "vehiclevin",
    },
    {
      width: 2,
      title: "Model",
      field: "model",
    },
    {
      width: 2,
      title: "Type",
      field: "processing_type.titleprocesstype",
    },
    {
      width: 2,
      title: "Type",
      field: "processing_type.titleprocesstype",
    },
    {
      width: 2,
      title: "",
      field: null,
    },
    {
      width: 2,
      title: "App",
      field: "app_fee_total",
      dataRender: (item) => formatNumberToStringMoney(item.app_fee_total),
    },
    {
      width: 2,
      title: "Plate",
      field: "plates_fee_total",
      dataRender: (item) => formatNumberToStringMoney(item.plates_fee_total),
    },
    {
      width: 2,
      title: "Other",
      field: "other_fee_total",
      dataRender: (item) => formatNumberToStringMoney(item.other_fee_total),
    },
    {
      width: 2,
      title: "Total",
      field: "fees_total",
      dataRender: (item) => formatNumberToStringMoney(item.fees_total),
    },
    {
      width: 2,
      title: "Invoice ID",
      field: "invoiceid",
      dataRender: (item) =>
        Number(item.invoiceid) > 0 ? (
          <Typography style={{ fontWeight: "bold", color: "red" }}>
            {item.invoiceid}
          </Typography>
        ) : (
          ""
        ),
    },
    {
      width: 2,
      title: "",
      field: null,
    },
    {
      width: 4,
      title: "Client",
      field: "client.clientname",
    },
  ]);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));

  React.useEffect(() => {
    setOpen(props.open);
  }, [props.open, props.vehicles]);

  const handleClose = () => {
    setOpen(false);
    if (props.onClose) props.onClose();
  };

  const handleSearch = () => {
    setLoading(true);
    setMessage("Searching... " + search);
    utilityService
      .getUtility("vehicles", {
        filters: [
          { field: "cancelled", operator: "neq", value: "1" },
          {
            field: "vehiclevin",
            operator: "like",
            value: search,
          },
        ],
        orders: [
          {
            field: "vehicleid",
            direction: "desc",
          },
        ],
        relations: ["processing_type", "charges"],
      })
      .then((response) => {
        setLoading(false);
        setMessage(response.data.length + " results");
        setVehicles(response.data);
      })
      .catch((error) => {
        setLoading(false);
        setMessage(error);
      });
  };

  return (
    <Dialog open={open} fullScreen={fullScreen}>
      <DialogTitle id="form-dialog-title">Add Vehicle to Batch</DialogTitle>
      <DialogContent>
        <TextField
          value={search}
          placeholder="VIN or partial VIN"
          onChange={(e) => setSearch(e.target.value)}
          onKeyPress={(e) => {
            if (e.key === "Enter") handleSearch();
          }}
        />
        <Button
          size="small"
          variant="contained"
          color="primary"
          onClick={handleSearch}
        >
          Search
        </Button>
        <Divider style={{ marginTop: 5, marginBottom: 5 }} />
        {message}
        <Divider style={{ marginTop: 5, marginBottom: 5 }} />
        <ResponsiveGrid
          data={vehicles}
          columns={columns}
          usePagination={false}
          style={{ marginTop: 20 }}
          loading={loading}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
