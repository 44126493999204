import { vehicleConstants } from "../_constants";

const initialState = {
  vehicles: {
    loading: false,
    items: [],
    page: 1,
    per_page: 10,
    total: 0
  },
  vehicle: {
    loading: false,
    data: {}
  }
};

export function vehicles(state = initialState, action) {
  switch (action.type) {
    case vehicleConstants.VEHICLES_GET_REQUEST:
      return {
        ...state,
        vehicles: {
          ...state.vehicles,
          loading: true
        }
      };
    case vehicleConstants.VEHICLES_GET_SUCCESS:
      return {
        ...state,
        vehicles: {
          ...state.vehicles,
          items: action.vehicles.data,
          page: action.vehicles.current_page,
          per_page: action.vehicles.per_page,
          total: action.vehicles.total,
          loading: false
        }
      };
    case vehicleConstants.VEHICLES_GET_FAILURE:
      return {
        ...state,
        vehicles: {
          ...state.vehicles,
          loading: false
        }
      };

    case vehicleConstants.VEHICLE_GET_REQUEST:
      return {
        ...state,
        vehicle: {
          ...state.vehicle,
          loading: true
        }
      };
    case vehicleConstants.VEHICLE_GET_SUCCESS:
      return {
        ...state,
        vehicle: {
          ...state.vehicle,
          loading: false,
          data: action.vehicle
        }
      };
    case vehicleConstants.VEHICLE_GET_FAILURE:
      return {
        ...state,
        vehicle: {
          ...state.vehicle,
          loading: false
        }
      };

    case vehicleConstants.VEHICLE_UPDATE_REQUEST:
      return {
        ...state,
        vehicle: {
          ...state.vehicle,
          loading: true
        }
      };
    case vehicleConstants.VEHICLE_UPDATE_SUCCESS:
      return {
        ...state,
        vehicle: {
          ...state.vehicle,
          loading: false,
          data: action.vehicle
        }
      };
    case vehicleConstants.VEHICLE_UPDATE_FAILURE:
      return {
        ...state,
        vehicle: {
          ...state.vehicle,
          loading: false
        }
      };

    case vehicleConstants.VEHICLE_ADD_REQUEST:
      return {
        ...state,
        vehicle: {
          ...state.vehicle,
          loading: true
        }
      };
    case vehicleConstants.VEHICLE_ADD_SUCCESS:
      return {
        ...state,
        vehicle: {
          ...state.vehicle,
          loading: false,
          data: action.vehicle
        }
      };
    case vehicleConstants.VEHICLE_ADD_FAILURE:
      return {
        ...state,
        vehicle: {
          ...state.vehicle,
          loading: false
        }
      };

    case vehicleConstants.VEHICLE_SET_LOCAL:
      return {
        ...state,
        vehicle: {
          ...state.vehicle,
          data: {
            ...state.vehicle.data,
            ...action.data
          }
        }
      };
    case vehicleConstants.VEHICLE_CLEAR:
      return {
        ...state,
        vehicle: {
          ...state.vehicle,
          data: {}
        }
      };

    default:
      return state;
  }
}
