import {
  Button,
  Grid,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import LaunchTwoToneIcon from "@material-ui/icons/LaunchTwoTone";
import React, { useEffect, useState } from "react";
import ResponsiveGrid from "../_components/generic/ResponsiveGrid";
import { Link as RouterLink } from "react-router-dom";

import { PageWithAppDrawer2 } from "../_components/main/PageWithAppDrawer2";
import { utilityService } from "../_services";
import { useDispatch, useSelector } from "react-redux";
import FiltersGrid from "../_components/generic/FiltersGrid";

import { utilityActions } from "../_actions";
import { generateCSV, downloadBlob } from "../_helpers";
import moment from "moment";

import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

export default function RiCustomsSubmittedPage(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const items = useSelector(
    (state) => state.utility.vehicles_customs_submitted
  );
  const [statsData, setStatsData] = useState(null);
  const [periodOverPeriod, setPeriodOverPeriod] = useState({});
  const [graphData2, setGraphData2] = useState({});
  const [graphData2a, setGraphData2a] = useState({});
  const [graphData3, setGraphData3] = useState({});
  const [graphData3a, setGraphData3a] = useState({});
  const [graphData4, setGraphData4] = useState({});

  const [getData, setGetData] = useState(false);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(items.page ? Number(items.page) - 1 : 0);
  const [perPage, setPerPage] = useState(
    items.per_page ? Number(items.per_page) : 0
  );
  const [total, setTotal] = useState(items.total ? Number(items.total) : 0);

  const [availableFilters, setAvailableFilters] = useState([
    {
      name: "Start Date",
      field: "submittedtocustomsdate",
      operator: ">=",
      value: moment().startOf("week").format("YYYY-MM-DD"),
      type: "date",
      default: "",
    },
    {
      name: "End Date",
      field: "submittedtocustomsdate",
      operator: "<=",
      value: moment().endOf("week").format("YYYY-MM-DD"),
      type: "date",
      default: "",
    },
  ]);

  useEffect(() => {
    if (items.items.length === 0) {
      getNewData();
    }
    getStatsData();
  }, []);

  useEffect(() => {
    if (getData === true) {
      setGetData(false);
      getNewData();
    }
  }, [getData]);

  useEffect(() => {
    setTotal(items.total ? Number(items.total) : 0);
    setPage(items.page ? Number(items.page) : 0);
    setPerPage(items.per_page ? Number(items.per_page) : 10);
  }, [items]);

  const handleFilterChange = (newFilter, refresh = true) => {
    const newFilters = availableFilters.map((filter) => {
      if (filter.name !== newFilter.name) return filter; // not the droid we're looking for, return unchanged
      filter.value = newFilter.value;
      return filter;
    });
    setAvailableFilters(newFilters);
    if (refresh) {
      setPage(1);
      setGetData(true);
    }
  };

  const getStatsData = () => {
    console.log("getStatsData");
    utilityService
      .getEndpoint("api/reports/management/customsSubmittedStats", "GET")
      .then((res) => {
        // console.log("stats", res);
        setStatsData(res);
        setPeriodOverPeriod(res.periodOverPeriod);
        setGraphData2(res.graphData2);
        setGraphData2a(res.graphData2a);
        setGraphData3(res.graphData3);
        setGraphData3a(res.graphData3a);
        setGraphData4(res.graphData4);
      });
  };

  useEffect(() => {
    console.log("useEffect statsData", statsData);
  }, [statsData]);

  const getNewData = (download = false) => {
    let newFilters = availableFilters
      .filter((filter) => {
        if (["", null].includes(filter.value)) return false;
        if (
          ["isnull", "notnull"].includes(filter.operator) &&
          filter.value === false
        )
          return false;
        return true;
      })
      .map((filter) => {
        return {
          field: filter.field,
          operator: filter.operator,
          value: filter.value,
        };
      });

    if (download) {
      let options = {
        filters: newFilters,
        perPage: perPage,
        page: page,
        params: "with=none",
      };
      setLoading(true);
      options.page = 1;
      options.perPage = total + 1;
      utilityService
        .getUtility("vehicles_customs_submitted", options)
        .then((values) => {
          const downloadHeaders = columns
            .filter((item) => item.title)
            .map((item) => {
              if (!item.title) return null;
              return item.title;
            });
          const downloadFields = columns
            .filter((item) => item.field)
            .map((item) => {
              if (!item.field) return null;
              return item.field;
            });

          return generateCSV(downloadHeaders, downloadFields, values.data);
        })
        .then((csv) => {
          const blob = new Blob([csv], { type: "text/csv" });
          downloadBlob(blob, `vehicles_customs_submitted.csv`);
        })
        .catch(console.error)
        .finally(() => {
          setLoading(false);
        });
      return;
    }

    dispatch(
      utilityActions.getUtility("vehicles_customs_submitted", {
        page,
        perPage,
        filters: newFilters,
        params: "with=none",
        orders: [{ field: "recordcreateddate", direction: "desc" }],
      })
    );
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
    setGetData(true);
  };
  const handleChangeRowsPerPage = (event) => {
    const value = parseInt(event.target.value);
    setPerPage(value);
    setGetData(true);
  };
  const handleDownload = () => {
    setLoading(true);
    getNewData(true);
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip">
          <p className="label">{`${label} : ${payload[0].value}`}</p>
          <p className="desc">Anything you want can be displayed here.</p>
        </div>
      );
    }

    return null;
  };

  return (
    <PageWithAppDrawer2 pageTitle={"RI Customs Submitted Report"}>
      <Paper className={classes.paper}>
        <Grid container spacing={1}>
        {graphData3a && (
            <Grid item xs={12}>
              <Typography variant="h5">Total Weekly Volume</Typography>
              <div
                style={{
                  // border: "1px solid blue",
                  height: "300px",
                  width: "100%",
                }}
              >
                <ResponsiveContainer width="100%" height="100%">
                  <LineChart
                    width={"100%"}
                    height={400}
                    data={graphData3a}
                    margin={{
                      top: 5,
                      right: 30,
                      left: 20,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis type="number" domain={["auto", "auto"]} />
                    <Tooltip />
                    <Legend />
                    <Line
                      type="monotone"
                      dataKey={`year_${moment().format("YYYY")}`}
                      stroke="#8884d8"
                      strokeWidth={3}
                    />
                    <Line
                      type="monotone"
                      dataKey={`year_${moment()
                        .subtract(1, "year")
                        .format("YYYY")}`}
                      stroke="#ff0000"
                    />
                    <Line
                      type="monotone"
                      dataKey={`year_${moment()
                        .subtract(2, "year")
                        .format("YYYY")}`}
                      stroke="#00ff00"
                    />
                    <Line
                      type="monotone"
                      dataKey={`year_${moment()
                        .subtract(3, "year")
                        .format("YYYY")}`}
                      stroke="#00ffff"
                    />
                    <Line
                      type="monotone"
                      dataKey={`year_${moment()
                        .subtract(4, "year")
                        .format("YYYY")}`}
                      stroke="#666666"
                    />
                    <Line
                      type="monotone"
                      dataKey={`year_${moment()
                        .subtract(5, "year")
                        .format("YYYY")}`}
                      stroke="#999999"
                    />
                    <Line
                      type="monotone"
                      dataKey={`year_${moment()
                        .subtract(6, "year")
                        .format("YYYY")}`}
                      stroke="#cccccc"
                    />
                    
                  </LineChart>
                </ResponsiveContainer>
              </div>
            </Grid>
          )}
          {graphData3 && (
            <Grid item xs={6}>
              <Typography variant="h5">Submitted Weekly Volume</Typography>
              <div
                style={{
                  // border: "1px solid blue",
                  height: "300px",
                  width: "100%",
                }}
              >
                <ResponsiveContainer width="100%" height="100%">
                  <LineChart
                    width={"100%"}
                    height={400}
                    data={graphData3}
                    margin={{
                      top: 5,
                      right: 30,
                      left: 20,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis type="number" domain={["auto", "auto"]} />
                    <Tooltip />
                    <Legend />
                    <Line
                      type="monotone"
                      dataKey={`year_${moment().format("YYYY")}`}
                      stroke="#8884d8"
                      strokeWidth={3}
                    />
                    <Line
                      type="monotone"
                      dataKey={`year_${moment()
                        .subtract(1, "year")
                        .format("YYYY")}`}
                      stroke="#ff0000"
                    />
                    <Line
                      type="monotone"
                      dataKey={`year_${moment()
                        .subtract(2, "year")
                        .format("YYYY")}`}
                      stroke="#00ff00"
                    />
                    <Line
                      type="monotone"
                      dataKey={`year_${moment()
                        .subtract(3, "year")
                        .format("YYYY")}`}
                      stroke="#00ffff"
                    />
                    <Line
                      type="monotone"
                      dataKey={`year_${moment()
                        .subtract(4, "year")
                        .format("YYYY")}`}
                      stroke="#ff00ff"
                    />
                  </LineChart>
                </ResponsiveContainer>
              </div>
            </Grid>
          )}
          {graphData4 && (
            <Grid item xs={6}>
              <Typography variant="h5">Submitted Monthly Volume</Typography>
              <div
                style={{
                  // border: "1px solid blue",
                  height: "300px",
                  width: "100%",
                }}
              >
                <ResponsiveContainer width="100%" height="100%">
                  <LineChart
                    width={"100%"}
                    height={400}
                    data={graphData4}
                    margin={{
                      top: 5,
                      right: 30,
                      left: 20,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis type="number" domain={["auto", "auto"]} />
                    <Tooltip />
                    <Legend />
                    <Line
                      type="monotone"
                      dataKey={`year_${moment().format("YYYY")}`}
                      stroke="#8884d8"
                      strokeWidth={3}
                    />
                    <Line
                      type="monotone"
                      dataKey={`year_${moment()
                        .subtract(1, "year")
                        .format("YYYY")}`}
                      stroke="#ff0000"
                    />
                    <Line
                      type="monotone"
                      dataKey={`year_${moment()
                        .subtract(2, "year")
                        .format("YYYY")}`}
                      stroke="#00ff00"
                    />
                    <Line
                      type="monotone"
                      dataKey={`year_${moment()
                        .subtract(3, "year")
                        .format("YYYY")}`}
                      stroke="#00ffff"
                    />
                    <Line
                      type="monotone"
                      dataKey={`year_${moment()
                        .subtract(4, "year")
                        .format("YYYY")}`}
                      stroke="#ff00ff"
                    />
                  </LineChart>
                </ResponsiveContainer>
              </div>
            </Grid>
          )}
          <Grid item xs={12}>
            <Typography variant="h5">Period over Period</Typography>
            {statsData && (
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Period</TableCell>
                    <TableCell>Current Period</TableCell>
                    <TableCell>Previous Period</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>Week</TableCell>
                    <TableCell>
                      {Number(statsData.periodOverPeriod.thisweek)}
                    </TableCell>
                    <TableCell>{statsData.periodOverPeriod.lastweek}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Month</TableCell>
                    <TableCell>
                      {statsData.periodOverPeriod.thismonth}
                    </TableCell>
                    <TableCell>
                      {statsData.periodOverPeriod.lastmonth}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Year</TableCell>
                    <TableCell>{statsData.periodOverPeriod.thisyear}</TableCell>
                    <TableCell>{statsData.periodOverPeriod.lastyear}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            )}
          </Grid>
          <Typography variant="h5">Detailed Data</Typography>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                handleFilterChange(
                  {
                    name: "Start Date",
                    value: moment().startOf("week").format("YYYY-MM-DD"),
                  },
                  false
                );
                handleFilterChange(
                  {
                    name: "End Date",
                    value: moment().endOf("week").format("YYYY-MM-DD"),
                  },
                  false
                );
                setGetData(true);
              }}
            >
              This Week
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                handleFilterChange(
                  {
                    name: "Start Date",
                    value: moment()
                      .subtract(1, "weeks")
                      .startOf("week")
                      .format("YYYY-MM-DD"),
                  },
                  false
                );
                handleFilterChange(
                  {
                    name: "End Date",
                    value: moment()
                      .subtract(1, "weeks")
                      .endOf("week")
                      .format("YYYY-MM-DD"),
                  },
                  false
                );
                setGetData(true);
              }}
            >
              Last Week
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                handleFilterChange(
                  {
                    name: "Start Date",
                    value: moment().startOf("month").format("YYYY-MM-DD"),
                  },
                  false
                );
                handleFilterChange(
                  {
                    name: "End Date",
                    value: moment().endOf("month").format("YYYY-MM-DD"),
                  },
                  false
                );
                setGetData(true);
              }}
            >
              This Month
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                handleFilterChange(
                  {
                    name: "Start Date",
                    value: moment()
                      .subtract(1, "months")
                      .startOf("month")
                      .format("YYYY-MM-DD"),
                  },
                  false
                );
                handleFilterChange(
                  {
                    name: "End Date",
                    value: moment()
                      .subtract(1, "months")
                      .endOf("month")
                      .format("YYYY-MM-DD"),
                  },
                  false
                );
                setGetData(true);
              }}
            >
              Last Month
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                handleFilterChange(
                  {
                    name: "Start Date",
                    value: moment().startOf("year").format("YYYY-MM-DD"),
                  },
                  false
                );
                handleFilterChange(
                  {
                    name: "End Date",
                    value: moment().endOf("year").format("YYYY-MM-DD"),
                  },
                  false
                );
                setGetData(true);
              }}
            >
              This Year
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                handleFilterChange(
                  {
                    name: "Start Date",
                    value: moment()
                      .subtract(1, "year")
                      .startOf("year")
                      .format("YYYY-MM-DD"),
                  },
                  false
                );
                handleFilterChange(
                  {
                    name: "End Date",
                    value: moment()
                      .subtract(1, "year")
                      .endOf("year")
                      .format("YYYY-MM-DD"),
                  },
                  false
                );
                setGetData(true);
              }}
            >
              Last Year
            </Button>
          </Grid>
        </Grid>
        <FiltersGrid
          availableFilters={availableFilters}
          onChange={handleFilterChange}
        />
        <ResponsiveGrid
          loading={items.loading || loading}
          columns={columns}
          data={items.items}
          page={page - 1} // API has a 1 based page count, Material UI has a zero based page count
          perPage={perPage}
          total={total}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          showActions
          onDownload={handleDownload}
        />
      </Paper>
    </PageWithAppDrawer2>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
}));

const columns = [
  {
    width: 3,
    title: "VIN",
    field: "vehiclevin",
    dataRender: (item) => (
      <>
        <RouterLink to={`/vehicles/${item.vehicleid}`} target="_blank">
          {item.vehiclevin}
          <LaunchTwoToneIcon style={{ fontSize: 12 }} color="primary" />
        </RouterLink>
      </>
    ),
  },
  {
    width: 1,
    title: "yr",
    field: "yr",
  },
  {
    width: 2,
    title: "make",
    field: "make",
  },
  {
    width: 2,
    title: "model",
    field: "model",
  },

  {
    width: 2,
    title: "Customs Submitted",
    field: "submittedtocustomsdate",
  },
];

export { RiCustomsSubmittedPage };
