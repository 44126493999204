import React from "react";
import { Link as RouterLink } from "react-router-dom";
// import Link from "@material-ui/core/Link";
import { connect, useSelector, useDispatch } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import queryString from "query-string";

import { utilityActions } from "../_actions";
import { utilityService } from "../_services";
import { formatNumberToStringMoney } from "../_helpers";

import { PageWithAppDrawer2 } from "../_components/main/PageWithAppDrawer2";
import ResponsiveGrid from "../_components/generic/ResponsiveGrid";

import FiltersGrid from "../_components/generic/FiltersGrid";
import { Paper, Button } from "@material-ui/core";
import { hashHistory } from "../_helpers";

class AdminRackRateOverridesPage extends React.Component {
  constructor(props) {
    super(props);
    const { rack_rate_id, clientid } = queryString.parse(
      this.props.location.search
    );

    this.state = {
      rack_rate_id,
      clientid,
      reload: false,
    };
  }

  async componentDidMount() {}

  static getDerivedStateFromProps(nextProps, prevState) {
    const { rack_rate_id, clientid } = queryString.parse(
      nextProps.location.search
    );
    if (rack_rate_id !== prevState.rack_rate_id) {
      return {
        ...prevState,
        rack_rate_id,
        clientid,
        reload: true,
      };
    }
    return false;
  }

  render() {
    const { rack_rate_id, clientid, reload } = this.state;
    const { classes } = this.props;

    if (reload === true) {
      this.setState({ reload: false }, () => {
        this.componentDidMount();
      });
      return <></>;
    }

    return (
      <PageWithAppDrawer2 pageTitle={"Rack Rate Overrides"}>
        <Paper className={classes.paper}>
          <Button
            color="primary"
            variant="contained"
            onClick={this.handleAddItem}
          >
            Add Override
          </Button>
          <OverridesTable
            defaultFilters={{ rack_rate_id, clientid }}
            onChangeRackRate={this.handleChangeRackRate}
          />
        </Paper>
      </PageWithAppDrawer2>
    );
  }

  handleChangeRackRate = (rack_rate_id) => {
    console.log("new rack rate", rack_rate_id);
    this.setState({ rack_rate_id });
    hashHistory.push(`?rack_rate_id=${rack_rate_id}`);
  };

  handleAddItem = () => {
    const { rack_rate_id } = this.state;
    // console.log("rack_rate_id", rack_rate_id);
    // return;
    utilityService
      .addUtility("rack_rate_overrides", {
        rack_rate_id,
      })
      .then((response) => {
        hashHistory.push(`/admin/rackRates/overrides/${response.id}`);
      });
  };
}

function OverridesTable(props) {
  const dispatch = useDispatch();
  const items = useSelector((state) => state.utility.rack_rate_overrides);
  const columns = [
    {
      width: 2,
      title: "Rack Rate",
      field: "rack_rate.name",
    },
    {
      width: 2,
      title: "Client",
      field: "client.clientname",
    },
    {
      width: 1,
      title: "Override Amount",
      field: "charge_amount",
      dataRender: (item) => formatNumberToStringMoney(item.charge_amount),
    },
    {
      width: 1,
      title: "Default Amount",
      field: "rack_rate.charge_amount",
      dataRender: (item) =>
        formatNumberToStringMoney(item.rack_rate.charge_amount),
    },
    {
      width: 1,
      title: "Every Vehicle",
      field: "every_vehicle",
      dataRender: (item) => (item.every_vehicle ? "Yes" : "No"),
    },
    {
      width: 1,
      title: "Edit",
      field: "",
      dataRender: (item) => (
        <RouterLink to={`/admin/rackRates/overrides/${item.id}`}>
          Edit
        </RouterLink>
      ),
    },
  ];

  const [availableFilters, setAvailableFilters] = React.useState([
    {
      name: "Rack Rate",
      field: "rack_rate_id",
      operator: "eq",
      value:
        props.defaultFilters && props.defaultFilters.rack_rate_id
          ? props.defaultFilters.rack_rate_id
          : "",
      type: "choice",
      useUtility: true,
      utility: "rack_rates_all",
      utilityValue: "name",
      // choices: [],
      default: "",
      onChange: (value) => {
        if (
          props.onChangeRackRate &&
          typeof props.onChangeRackRate === "function"
        ) {
          props.onChangeRackRate(value);
        }
      },
    },
    {
      name: "Client",
      field: "clientid",
      operator: "eq",
      value:
        props.defaultFilters && props.defaultFilters.clientid
          ? props.defaultFilters.clientid
          : "",
      type: "choice",
      useUtility: true,
      utility: "clients_active",
      utilityValue: "clientname",
      utilityOrder: {
        field: "clientname",
        direction: "asc",
      },
      utilityFilters: [
        {
          field: "clientactive",
          operator: "eq",
          value: 2,
        },
      ],
      // choices: [],
      default: "",
    },
    {
      name: "Every Vehicle",
      field: "every_vehicle",
      operator: "eq",
      value: "",
      type: "choice",
      choices: [
        {
          key: "1",
          value: "Every Vehicle",
        },
        {
          key: "0",
          value: "Not Every Vehicle",
        },
      ],
      default: "",
    },
  ]);

  const [getData, setGetData] = React.useState(false);

  const [page, setPage] = React.useState(
    items.page ? Number(items.page) - 1 : 0
  );
  const [perPage, setPerPage] = React.useState(
    items.per_page ? Number(items.per_page) : 0
  );
  const [total, setTotal] = React.useState(
    items.total ? Number(items.total) : 0
  );

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
    setGetData(true);
  };
  const handleChangeRowsPerPage = (event) => {
    const value = parseInt(event.target.value);
    setPerPage(value);
    setGetData(true);
  };
  React.useEffect(() => {
    if (getData === true) {
      setGetData(false);
      getNewData();
    }
  }, [getData]);

  React.useEffect(() => {
    setTotal(items.total ? Number(items.total) : 0);
    setPage(items.page ? Number(items.page) : 0);
    setPerPage(items.per_page ? Number(items.per_page) : 10);
  }, [items]);

  React.useEffect(() => {
    if (items.items.length === 0) {
      getNewData();
    }

    return () => {
      dispatch(utilityActions.clearUtility("rack_rate_overrides"));
    };
  }, []);

  // const handleDownload = () => {};

  const getNewData = () => {
    let newFilters = availableFilters
      .filter((filter) => {
        if (["", null].includes(filter.value)) return false;
        if (
          ["isnull", "notnull"].includes(filter.operator) &&
          filter.value === false
        )
          return false;
        return true;
      })
      .map((filter) => {
        return {
          field: filter.field,
          operator: filter.operator,
          value: filter.value,
        };
      });
    dispatch(
      utilityActions.getUtility("rack_rate_overrides", {
        page,
        perPage,
        relations: ["rack_rate", "client"],
        filters: newFilters,
      })
    );
  };

  const handleFilterChange = (newFilter) => {
    const newFilters = availableFilters.map((filter) => {
      if (filter.field !== newFilter.field) return filter; // not the droid we're looking for, return unchanged
      filter.value = newFilter.value;
      return filter;
    });
    setAvailableFilters(newFilters);
    setPage(1);
    setGetData(true);
  };

  return (
    <>
      <FiltersGrid
        availableFilters={availableFilters}
        onChange={handleFilterChange}
      />
      <ResponsiveGrid
        loading={items.loading}
        columns={columns}
        data={items.items}
        page={page - 1} // API has a 1 based page count, Material UI has a zero based page count
        perPage={perPage}
        total={total}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        // showActions
        // onDownload={handleDownload}
      />
    </>
  );
}

const styles = (theme) => ({
  root: {
    display: "flex",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
});

function mapStateToProps(state) {
  const { authentication, utility } = state;
  const { user } = authentication;
  const { us_states } = utility;
  return {
    user,
    us_states,
  };
}

const connectedAdminRackRateOverridesPage = connect(mapStateToProps)(
  withStyles(styles)(AdminRackRateOverridesPage)
);
export { connectedAdminRackRateOverridesPage as AdminRackRateOverridesPage };
