import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { connect } from "react-redux";

import { withStyles } from "@material-ui/core/styles";
import withWidth from "@material-ui/core/withWidth";

import { PageWithAppDrawer2 } from "../_components/main/PageWithAppDrawer2";
import { Paper, Typography } from "@material-ui/core";

class SecurityLevelPage extends React.Component {
  constructor(props) {
    super(props);
    const { level } = this.props.match ? this.props.match.params : {};
    this.state = {
      level,
    };
  }
  async componentDidMount() {}

  render() {
    const { level } = this.state;
    const { classes } = this.props;

    let title = "Access to this page is not allowed";
    if (level === "admin") {
      title = "This page is restricted to Administrators";
    }
    if (level === "auction") {
      title = "This page is restricted to Auction Staff";
    }
    return (
      <PageWithAppDrawer2 pageTitle={`Access Problem`}>
        <Paper className={classes.paper}>
          <Typography variant="h6" align="center">
            {title}
          </Typography>
          <br />
          <Typography align="center">
            Please <RouterLink to="/login">login</RouterLink> with the proper
            credentials
          </Typography>
        </Paper>
      </PageWithAppDrawer2>
    );
  }
}

const styles = (theme) => ({
  root: {
    display: "flex",
    fontSize: 12,
  },
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    width: "100%",
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    width: "100%",
  },
  fixedHeight: {
    height: 240,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },

  Button: {
    display: "block",
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
});

function mapStateToProps(state) {
  const { authentication } = state;
  const { user } = authentication;
  return {
    user,
  };
}

const connectedSecurityLevelPage = connect(mapStateToProps)(
  withWidth()(withStyles(styles)(SecurityLevelPage))
);
export { connectedSecurityLevelPage as SecurityLevelPage };
