import React, { useState } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import moment from "moment";

import { PageWithAppDrawer2 } from "../_components/main/PageWithAppDrawer2";
import FiltersGrid from "../_components/generic/FiltersGrid";

import { Paper, Divider, Button } from "@material-ui/core";

import { getEnvironmentURL } from "../_helpers";

class YearlyTaxReportPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {}

  render() {
    const { classes } = this.props;
    return (
      <PageWithAppDrawer2 pageTitle={"Yearly Tax Report"}>
        <Paper className={classes.paper}>
          <YearlyTaxReport />
        </Paper>
      </PageWithAppDrawer2>
    );
  }
}

function YearlyTaxReport(props) {
  const [year, setYear] = useState(moment().format("YYYY"));
  const [month, setMonth] = useState(null);
  const [clientId, setClientId] = useState(null);

  const [availableFilters, setAvailableFilters] = useState([
    {
      name: "Year",
      field: "year",
      operator: "eq",
      value: moment().format("YYYY"),
      type: "choice",
      choices: [
        { key: moment().format("YYYY"), value: moment().format("YYYY") },
        {
          key: moment().subtract(1, "years").format("YYYY"),
          value: moment().subtract(1, "years").format("YYYY"),
        },
        {
          key: moment().subtract(2, "years").format("YYYY"),
          value: moment().subtract(2, "years").format("YYYY"),
        },
      ],
      default: "",
    },
    {
      name: "Month",
      field: "month",
      operator: "eq",
      value: moment().format("MM"),
      // value: 7,
      type: "choice",
      choices: monthList,
      default: "",
    },
    {
      name: "Client",
      field: "client_id",
      operator: "eq",
      value: "",
      type: "choice",
      useUtility: true,
      utility: "clients_active",
      utilityKey: "clientid",
      utilityValue: "clientname",
      utilityFilters: [
        {
          field: "clientactive",
          operator: "eq",
          value: 2,
        },
      ],
      utilityOrder: {
        field: "clientname",
        direction: "asc",
      },
      choices: [],
      default: "",
    },
  ]);

  const handleFilterChange = (newFilter, refresh = true) => {
    const newFilters = availableFilters.map((filter) => {
      if (filter.field !== newFilter.field) return filter; // not the droid we're looking for, return unchanged
      if (filter.name !== newFilter.name) return filter; // not the droid we're looking for, return unchanged
      if (filter.field === "year") {
        setYear(newFilter.value);
      }
      if (filter.field === "month") {
        setMonth(newFilter.value);
      }
      if (filter.field === "client_id") {
        setClientId(newFilter.value);
      }

      filter.value = newFilter.value;
      return filter;
    });
    setAvailableFilters(newFilters);
  };

  return (
    <>
      <FiltersGrid
        availableFilters={availableFilters}
        onChange={handleFilterChange}
      />
      <Divider style={{ marginTop: 10, marginBottom: 50 }} />
      <form
        id="tax-form"
        method="post"
        action={getEnvironmentURL() + "/api/reports/management/taxReport"}
        target="_blank"
      >
        <input type="hidden" name="year" value={year} />
        <input type="hidden" name="month" value={month} />
        <input type="hidden" name="clientId" value={clientId} />

        <Button type="submit" variant="contained" color="primary" size="small">
          Get Report
        </Button>
      </form>
    </>
  );
}

const monthList = [
  {
    key: 1,
    value: "January",
  },
  {
    key: 2,
    value: "February",
  },
  {
    key: 3,
    value: "March",
  },
  {
    key: 4,
    value: "April",
  },
  {
    key: 5,
    value: "May",
  },
  {
    key: 6,
    value: "June",
  },
  {
    key: 7,
    value: "July",
  },
  {
    key: 8,
    value: "August",
  },
  {
    key: 9,
    value: "September",
  },
  {
    key: 10,
    value: "October",
  },
  {
    key: 11,
    value: "November",
  },
  {
    key: 12,
    value: "December",
  },
];

const styles = (theme) => ({
  root: {
    display: "flex",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
});

function mapStateToProps(state) {
  const { authentication, utility } = state;
  const { user } = authentication;
  const { us_states, auctions } = utility;
  return {
    user,
    auctions,
    us_states,
  };
}

const connectedYearlyTaxReportPage = connect(mapStateToProps)(
  withStyles(styles)(YearlyTaxReportPage)
);
export { connectedYearlyTaxReportPage as YearlyTaxReportPage };
