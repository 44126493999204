import React from "react";
import ReactDOM from "react-dom";
import App from "./App/App";
import HttpsRedirect from "react-https-redirect";
import * as serviceWorker from "./serviceWorker";

// ROUTER
import { HashRouter } from "react-router-dom";

// THEME
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import muiTheme from "./theme/muiTheme";

// REDUX
import { Provider } from "react-redux";
import { store } from "./_helpers";

import ReactModal from "react-modal";

ReactModal.setAppElement("#root");

ReactDOM.render(
  <Provider store={store}>
    {process.env.NODE_ENV.includes("development") ? (
      <HashRouter>
        <MuiThemeProvider theme={muiTheme}>
          <App />
        </MuiThemeProvider>
      </HashRouter>
    ) : (
      <HttpsRedirect>
        <HashRouter>
          <MuiThemeProvider theme={muiTheme}>
            <App />
          </MuiThemeProvider>
        </HashRouter>
      </HttpsRedirect>
    )}
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
