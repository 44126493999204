import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

import { byString } from "../../_helpers/generic";

import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import Hidden from "@material-ui/core/Hidden";
import CircularProgress from "@material-ui/core/CircularProgress";

import TablePagination from "@material-ui/core/TablePagination";
import { Button, TableSortLabel } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
    width: "100%",
  },
  headerGrid: {
    fontSize: 12,
    fontWeight: "bold",
    textAlign: "left",
  },
  dataGrid: {
    fontSize: 12,
    textAlign: "left",
    textOverflow: "ellipsis",
  },
  divider: {
    padding: 0,
  },
}));

export default function ResponsiveGrid(props) {
  const downloadLabel = props.downloadLabel || "Download";

  const [loading, setLoading] = useState(props.loading || false);
  const [usePagination, setUsePagination] = useState(
    typeof props.usePagination === "undefined" ? false : true
  );
  const [showActions, setShowActions] = useState(
    typeof props.showActions === "undefined" ? false : true
  );

  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(5);
  const [total, setTotal] = useState(100);

  const [columns, setColumns] = useState([]);
  const [data, setData] = useState([]);

  useEffect(() => {
    setColumns(props.columns);
    setData(Array.isArray(props.data) ? props.data : []);
    setLoading(props.loading);
    setPage(props.page);
    setPerPage(props.perPage);
    setTotal(props.total);
    setUsePagination(props.usePagination === false ? false : true);
    setShowActions(props.showActions ? true : false);
  }, [props]);

  return (
    <Grid container style={props.style || null}>
      {loading ? (
        <Box align="center" style={{ width: "100%" }}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          {usePagination && (
            <ResponsiveGridPagination
              total={total}
              page={Number(page)}
              perPage={Number(perPage)}
              onChangePage={props.onChangePage ? props.onChangePage : () => {}}
              onChangeRowsPerPage={
                props.onChangeRowsPerPage ? props.onChangeRowsPerPage : () => {}
              }
            />
          )}
          {showActions && props.onDownload && (
            <ResponsiveGridActions
              onDownload={props.onDownload}
              downloadLabel={downloadLabel}
            />
          )}
          <ResponsiveGridHeader
            columns={columns}
            useOrdering={props.useOrdering}
            orderBy={props.orderBy}
            orderDir={props.orderDir}
            onChangeOrder={props.onChangeOrder}
          />
          <ResponsiveGridContent columns={columns} data={data} />
        </>
      )}
    </Grid>
  );
}

function ResponsiveGridPagination(props) {
  return (
    <TablePagination
      component={"div"}
      rowsPerPageOptions={[5, 10, 25, 50]}
      count={props.total}
      rowsPerPage={props.perPage}
      page={props.page}
      SelectProps={{
        inputProps: { "aria-label": "rows per page" },
        native: true,
      }}
      onChangePage={props.onChangePage}
      onChangeRowsPerPage={props.onChangeRowsPerPage}
    />
  );
}

function ResponsiveGridActions(props) {
  return (
    <Grid container>
      {props.onDownload && (
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={props.onDownload}
          >
            {props.downloadLabel}
          </Button>
        </Grid>
      )}
    </Grid>
  );
}

function ResponsiveGridContent(props) {
  const classes = useStyles();

  return (
    <>
      {props.data.map((item, rowIndex) => {
        return (
          <Grid
            key={rowIndex}
            container
            alignItems="center"
            className={classes.dataGrid}
          >
            {props.columns.map((column, colIndex) => {
              const value = byString(item, column.field);
              // const value = "foo";
              return (
                // <div key={colIndex}>
                <React.Fragment key={colIndex}>
                  <Hidden smUp>
                    <Grid item xs={6} className={classes.headerGrid}>
                      {column.title || ""}
                    </Grid>
                  </Hidden>
                  <Grid
                    item
                    xs={6}
                    sm={column.width || 1}
                    className={classes.dataGrid}
                  >
                    {column.dataRender ? (
                      column.dataRender(item, rowIndex, colIndex)
                    ) : (
                      <>{value}</>
                    )}
                  </Grid>
                </React.Fragment>
                // </div>
              );
            })}
            <Grid item xs={12}>
              <Box py={1}>
                <Divider className={classes.divider} />
              </Box>
            </Grid>
          </Grid>
        );
      })}
    </>
  );
}

function ResponsiveGridHeader(props) {
  const classes = useStyles();

  return (
    <Hidden xsDown>
      <Grid container alignItems="center" className={classes.headerGrid}>
        {props.columns.map((header, index) => {
          return (
            <Grid
              key={index}
              item
              xs={header.width || 1}
              sm={header.width || 1}
              md={header.width || 1}
            >
              {props.useOrdering && header.canSort ? (
                <TableSortLabel
                  active={props.orderBy === header.field}
                  direction={
                    props.orderBy === header.field ? props.orderDir : "asc"
                  }
                  onClick={() => {
                    props.onChangeOrder(
                      header.field,
                      props.orderBy === header.field && props.orderDir === "asc"
                        ? "desc"
                        : "asc"
                    );
                  }}
                >
                  {header.titleRender ? (
                    header.titleRender(header)
                  ) : (
                    <>{header.title}</>
                  )}
                </TableSortLabel>
              ) : (
                <>
                  {header.titleRender ? (
                    header.titleRender(header)
                  ) : (
                    <>{header.title}</>
                  )}
                </>
              )}
            </Grid>
          );
        })}
        <Grid item xs={12}>
          <Box py={1}>
            <Divider />
          </Box>
        </Grid>
      </Grid>
    </Hidden>
  );
}
