import React, { useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { utilityActions } from "../_actions";

import { PageWithAppDrawer2 } from "../_components/main/PageWithAppDrawer2";
import ResponsiveGrid from "../_components/generic/ResponsiveGrid";

import { Paper, makeStyles, Typography, Button, Link } from "@material-ui/core";
import { getEnvironmentURL } from "../_helpers";
import { utilityService } from "../_services";

export function RiUserTrainingPage(props) {
  const dispatch = useDispatch();
  const classes = useRiUserTrainingPageStyles();

  return (
    <PageWithAppDrawer2 pageTitle={"NHTSA RI Training"}>
      <Paper className={classes.paper}>
        <UsersTable />
      </Paper>
    </PageWithAppDrawer2>
  );
}

const useRiUserTrainingPageStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
}));

export { RiUserTrainingPage as default };

const sendSigRequest = (email, userId, webUserId, name) => {
  const confirmEmail = prompt("Send Training Acknowledgement", email);
  if (!confirmEmail) {
    return;
  }
  utilityService.getEndpoint("api/sendEmail", "post", {
    body: {
      to: confirmEmail,
      subject: "2024 RI Training Acknowledgement",
      view: "emails.training-acknowledgement",
      viewVars: {
        name: name,
        userId: userId,
        webUserId: webUserId,
        documentName: "training2024",
      },
    },
  });
};

function UsersTable(props) {
  const dispatch = useDispatch();
  const items = useSelector((state) => state.utility.users_training);
  const [getData, setGetData] = React.useState(false);

  const columns = [
    {
      width: 4,
      title: "Name",
      field: "name",
      dataRender: (item) => (
        <RouterLink to={`/admin/users/${item.id}`}>{item.name}</RouterLink>
      ),
    },
    {
      width: 4,
      title: "Email",
      field: "web_user.useremail",
    },
    {
      width: 4,
      title: "Training Completions",
      field: "training_signatures",
      dataRender: (item) => (
        <>
          {item.training_signatures.map((s) => (
            <Typography variant="body2" key={s.id}>
              {s.document_type} {s.signed_date}{" "}
              <Link
                target="_blank"
                href={`${getEnvironmentURL()}/api/users/${
                  item?.web_user?.userid || -1
                }/multipleForms/0?docs=39`}
              >
                View Doc
              </Link>
            </Typography>
          ))}
          <Button
            variant="contained"
            size="small"
            color="primary"
            onClick={() => sendSigRequest(item.web_user.useremail, item.id, item.web_user.id, item.web_user.name)}
          >
            Send Signature Request
          </Button>
        </>
      ),
    },
  ];

  const getNewData = (download = false) => {
    dispatch(
      utilityActions.getUtility("users_training", {
        perPage: 1000,
        filters: [
          {
            field: "user_roles.name",
            operator: "like",
            value: "training",
          },
        ],
        relationsAdd: ["training_signatures"],
        appends: ["web_user"],
      })
    );
  };

  useEffect(() => {
    if (getData === true) {
      setGetData(false);
      getNewData();
    }
  }, [getData]);

  useEffect(() => {
    if (items.items.length === 0) {
      getNewData();
    }
  }, []);

  return (
    <>
      <ResponsiveGrid
        usePagination={false}
        loading={items.loading}
        columns={columns}
        data={items.items}
        page={0} // API has a 1 based page count, Material UI has a zero based page count
        perPage={1000}
      />
    </>
  );
}
