import React from "react";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useDispatch, useSelector } from "react-redux";

import { utilityActions } from "../../_actions";
import { paperworkActions } from "../../_actions";

import ResponsiveGrid from "../generic/ResponsiveGrid";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { CircularProgress } from "@material-ui/core";

import { Grid, Input } from "@material-ui/core";
import moment from "moment";

export default function ModalPaperworkAddVehicle(props) {
  const dispatch = useDispatch();
  const vehicleSelector = useSelector(
    (state) => state.utility.paperwork_vehicles
  );

  const [open, setOpen] = React.useState(props.open || false);
  let [loadingIndex, setLoadingIndex] = React.useState([]);
  let [addedIndex, setAddedIndex] = React.useState([]);

  const addVehicle = (paperworkId, vehicleId) => {
    loadingIndex.push(vehicleId);
    dispatch(
      paperworkActions.addVehicleToPaperwork(paperworkId, vehicleId, () => {
        addedIndex.push(vehicleId);
      })
    );
  };

  const columns = [
    {
      width: 2,
      title: "Add",
      field: null,
      dataRender: (item, rowIndex, colIndex) => {
        const updating =
          loadingIndex && loadingIndex.includes(item.vehicleid) ? true : false;
        const added =
          addedIndex && addedIndex.includes(item.vehicleid) ? true : false;

        if (added) {
          return "Added";
        }

        if (!added) {
          return (
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                addVehicle(props.item.id, item.vehicleid);
              }}
            >
              {updating ? (
                <CircularProgress color="secondary" size={20} />
              ) : (
                `Add`
              )}
            </Button>
          );
        }
      },
    },
    {
      width: 4,
      title: "VIN",
      field: "vehiclevin",
    },
    {
      width: 2,
      title: "Auction",
      field: "auction.auctionname",
    },
    {
      width: 3,
      title: "Client",
      field: "client.clientname",
    },
    {
      width: 1,
      title: "Status",
      field: "title_status.statusname",
    },
  ];

  const [availableFilters, setAvailableFilters] = React.useState([
    {
      name: "Client Name",
      field: "client.clientname",
      operator: "like",
      value: "",
      type: "text",
      default: "",
    },
    {
      name: "VIN",
      field: "vehiclevin",
      operator: "like",
      value: "",
      type: "text",
      default: "",
    },
    {
      name: "Cancelled",
      field: "cancelled",
      operator: "neq",
      value: "1",
      type: "hidden",
      default: "",
    },
  ]);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));

  // React.useEffect(() => {
  //   // load clients if they are not already loaded
  //   if (clientSelector.items.length === 0) {
  //     dispatch(
  //       utilityActions.getUtility("clients", {
  //         perPage: 1000,
  //         filters: [
  //           {
  //             field: "clientactive",
  //             operator: "eq",
  //             value: "2"
  //           }
  //         ],
  //         orders: [{ field: "clientname", direction: "asc" }]
  //       })
  //     );
  //   }
  // }, []);

  React.useEffect(() => {
    setOpen(props.open);
  }, [props.open]);

  React.useEffect(() => {
    getVehicles();
  }, [availableFilters]);

  const handleClose = () => {
    setOpen(false);
    setAvailableFilters(
      availableFilters.map((filter) => {
        return {
          ...filter,
          value: "",
        };
      })
    );
    if (props.onClose) props.onClose();
  };

  const getVehicles = () => {
    const perPage = availableFilters[0].value === "" ? 10 : 1000;
    dispatch(
      utilityActions.getUtility("paperwork_vehicles", {
        perPage,
        filters: [
          {
            field: "printtitledocuments",
            operator: "eq",
            value: 0,
          },
          {
            field: "recordcreateddate",
            operator: ">",
            value: moment().subtract(24, "months").format("YYYY-MM-DD"),
          },
          ...availableFilters.filter((filter) => filter.value !== ""),
        ],
        orders: [{ field: "vehicleid", direction: "desc" }],
      })
    );
  };

  // const vehicleUpdating = (rowIndex, updating = true) => {
  //   setVehicles(
  //     vehicles.map((vehicle, index) => {
  //       if (rowIndex === index) {
  //         vehicle.updating = true;
  //       }
  //       return vehicle;
  //     })
  //   );
  // };

  const handleFilterChange = (newFilter) => {
    const newFilters = availableFilters.map((filter) => {
      if (filter.field !== newFilter.field) return filter; // not the droid we're looking for, return unchanged
      filter.value = newFilter.value;
      return filter;
    });
    setAvailableFilters(newFilters);
  };

  return (
    <Dialog open={open} fullScreen={fullScreen}>
      <DialogTitle id="form-dialog-title" style={{ width: 600 }}>
        Add Vehicle to Paperwork Batch
      </DialogTitle>
      <DialogContent>
        <FiltersGrid
          availableFilters={availableFilters}
          onChange={handleFilterChange}
        />
        <ResponsiveGrid
          loading={vehicleSelector.loading}
          data={vehicleSelector.items}
          columns={columns}
          usePagination={false}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Done
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function FiltersGrid(props) {
  const [availableFilters, setAvailableFilters] = React.useState([]);

  React.useEffect(() => {
    setAvailableFilters(props.availableFilters);
  }, [props]);

  return (
    <Grid
      container
      display="flex"
      justify="center"
      alignContent="center"
      alignItems="center"
    >
      <Grid
        container
        style={{ flex: 1, fontSize: 12 }}
        alignItems="center"
        alignContent="center"
      >
        {availableFilters.map((filter, index) => {
          if (filter.type === "hidden")
            return <React.Fragment key={index}></React.Fragment>;
          const isActive = filter.value === filter.default ? false : true;
          return (
            <React.Fragment key={index}>
              <Grid
                item
                xs={2}
                style={{ fontWeight: isActive ? "bold" : "inherit" }}
              >
                {filter.name}
              </Grid>
              <Grid item xs={4}>
                {filter.type === "text" && (
                  <FiltersTypeText filter={filter} onChange={props.onChange} />
                )}
              </Grid>
            </React.Fragment>
          );
        })}
      </Grid>
    </Grid>
  );
}

function FiltersTypeText(props) {
  const [filter, setFilter] = React.useState({});
  // const [value, setValue] = React.useState("");
  const [text, setText] = React.useState("");

  React.useEffect(() => {
    setFilter(props.filter);
    setText(props.filter.value || "");
  }, [props.filter]);

  return (
    <Input
      style={{ fontSize: 12 }}
      value={text || ""}
      placeholder={filter.name}
      onChange={(event) => setText(event.target.value)}
      onKeyPress={(event) => {
        if (event.key === "Enter") {
          props.onChange({
            field: filter.field,
            operator: "like",
            value: text,
          });
        }
      }}
    />
  );
}
