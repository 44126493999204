import React, { useEffect, useState } from "react";
import Dynamsoft from "./Dynamsoft";
import BarcodeScanner from "./BarcodeScanner";

import { Button } from "@material-ui/core";

export default function ScannerComponent(props) {
  let reader = null;

  const [showButtons, setShowButtons] = useState(
    props.showButtons === true ? true : false
  );
  const [isRunning, setIsRunning] = useState(false);

  useEffect(() => {
    return () => {
      if (reader) reader.destroy();
    };
  }, []);

  useEffect(() => {
    // setLoading(props.loading);
    setShowButtons(props.showButtons);
  }, [props.loading, props.showButtons]);

  useEffect(() => {
    // if (props.started === true) {
    //   if (isRunning === false) {
    //     captureStart(props.onResult);
    //   }
    // } else if (props.started === false) {
    //   if (isRunning === true) {
    //     captureStop();
    //   }
    // } else {
    // }
  }, [props.started]);

  const captureStart = (callback = () => {}, stopOnCapture = true) => {
    // setIsRunning(true);
    // codeReader.decodeFromVideoDevice(source, "video", (result, err) => {
    //   if (result) {
    //     if (stopOnCapture === true) {
    //       captureStop();
    //     }
    //     callback(result, err);
    //   }
    //   if (err && !(err instanceof NotFoundException)) {
    //     console.log("error", err);
    //   }
    // });
  };

  const captureStop = () => {
    // setIsRunning(false);
    // codeReader.reset();
  };

  return (
    <div style={{ height: "100%", width: "100%", border: "2px solid red" }}>
      {showButtons && (
        <div>
          <Button
            color="primary"
            variant="contained"
            onClick={() => captureStart(props.onResult)}
          >
            Scan Barcode
          </Button>{" "}
          <Button color="primary" variant="contained" onClick={captureStop}>
            Stop Scanner
          </Button>
        </div>
      )}

      <BarcodeScanner onCapture={props.onResult} />
    </div>
  );
}
