import React from "react";
import { Link as RouterLink } from "react-router-dom";

export const Settings = {
  title: "Missing Ownership",
  notes: [
    "Not Cancelled",
    "Not Complete",
    "RI Type",
    "Crossed the Border",
    "Missing Registration Ownership",
  ],
  columns: [
    {
      width: 2,
      title: "VIN",
      field: "vehiclevin",
      dataRender: (item) => (
        <RouterLink to={`/vehicles/${item.id}`}>{item.vehiclevin}</RouterLink>
      ),
    },
    {
      width: 2,
      title: "Client",
      field: "client.clientname",
    },
    {
      width: 2,
      title: "Created",
      field: "recordcreateddate",
    },
    {
      width: 2,
      title: "Crossed Date",
      field: "crossedborderdate",
    },
  ],
  availableFilters: [
    {
      name: "Not Cancelled",
      field: "titlestatusid",
      operator: "neq",
      value: "5",
      type: "hidden",
      default: "",
    },
    {
      name: "Not Completed",
      field: "titlestatusid",
      operator: "neq",
      value: "6",
      type: "hidden",
      default: "",
    },
    {
      name: "RI Type",
      field: "application_type.is_ri_type",
      operator: "eq",
      value: 1,
      type: "hidden",
      default: "",
    },
    {
      name: "Crossed Border Date",
      field: "crossedborderdate",
      operator: "notnull",
      value: "1",
      type: "hidden",
      default: "",
    },
    {
      name: "Registration Ownership",
      field: "titleregownershipdate",
      operator: "isnull",
      value: "1",
      type: "hidden",
      default: "",
    },
  ],
};
