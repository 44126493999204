import React, { useState } from "react";
// import Link from "@material-ui/core/Link";
import { connect, useDispatch } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import moment from "moment";

import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import { getEnvironmentURL } from "../_helpers";

import { PageWithAppDrawer2 } from "../_components/main/PageWithAppDrawer2";
import Paper from "@material-ui/core/Paper";
import { Button, Checkbox, Grid } from "@material-ui/core";

class CustomerVolumesPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { classes } = this.props;
    return (
      <PageWithAppDrawer2 pageTitle={"Customer Volumes Report"}>
        <Paper className={classes.paper}>
          <CustomerVolumesRequest />
        </Paper>
      </PageWithAppDrawer2>
    );
  }
}

function CustomerVolumesRequest(props) {
  // const dispatch = useDispatch();

  const date = new Date();
  const defaultStart = new Date(date.getFullYear() - 1, date.getMonth(), 1);
  const defaultEnd = new Date(date.getFullYear(), date.getMonth(), 1);
  const [start, setStart] = useState(moment(defaultStart).format("M/D/Y"));
  const [end, setEnd] = useState(moment(defaultEnd).format("M/D/Y"));
  // const [minPerYearCount, setMinPerYearCount] = useState("0");
  // const [minPerYearYear, setMinPerYearYear] = useState("2020");
  const [exactDates, setExactDates] = useState(false);

  const reportUrl = `${getEnvironmentURL()}/api/reports/management/customerVolumes`;

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Grid container>
        <Grid item xs={12}>
          <KeyboardDatePicker
            margin="normal"
            name="start"
            label="Report Start Date"
            format="MM/dd/yyyy"
            value={start ? moment(start).format("M/D/Y") : null}
            onChange={(date, value) => {
              setStart(value);
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <KeyboardDatePicker
            margin="normal"
            name="end"
            label="Report End Date"
            format="MM/dd/yyyy"
            value={end ? moment(end).format("M/D/Y") : null}
            onChange={(date, value) => {
              setEnd(value);
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Checkbox
            checked={exactDates}
            onChange={(e) => setExactDates(e.target.checked)}
          />{" "}
          Use Exact Dates?
        </Grid>
      </Grid>
      <form
        method="POST"
        action={reportUrl}
        target="_blank"
        style={{ marginTop: 20 }}
      >
        <Button type="submit" variant="contained" size="small" color="primary">
          Get Data
        </Button>
        <input type="hidden" name="start" value={start} />
        <input type="hidden" name="end" value={end} />
        <input type="hidden" name="exactDates" value={exactDates} />
      </form>
    </MuiPickersUtilsProvider>
  );
}

const styles = (theme) => ({
  root: {
    display: "flex",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
});

function mapStateToProps(state) {
  const { authentication } = state;
  const { user } = authentication;
  return {
    user,
  };
}

const connectedCustomerVolumesPage = connect(mapStateToProps)(
  withStyles(styles)(CustomerVolumesPage)
);
export { connectedCustomerVolumesPage as CustomerVolumesPage };
