import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

export default function useHasSvetnaBuyerRole() {
  const user = useSelector((state) => state.authentication.user);
  const [hasSvetnaBuyerRole, setHasSvetnaBuyerRole] = useState(false);

  useEffect(() => {
    if (user?.has_svetna_buyer_role === true){
        setHasSvetnaBuyerRole(true);
    }
    else{
        setHasSvetnaBuyerRole(false);
    }
  }, [user]);
  return hasSvetnaBuyerRole;
}
