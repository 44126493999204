import { authHeader } from "../_helpers";
import {
  buildRelations,
  buildFilters,
  handleResponse,
  getEnvironmentURL,
} from "../_helpers";

export const vehicleService = {
  getVehicles,
  addVehicle,
  getVehicle,
  updateVehicle,
  nhtsaVinLookup,
  vin10VinLookup,
  vin10Dimensions,
};

function getVehicles(filters = [], relations = [], perPage = 10, page = 1) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  const filterStrings = buildFilters(filters);
  const filterString = filterStrings.join("&");
  const relationStrings = buildRelations(relations);
  const relationString =
    relationStrings != null ? relationStrings.join("&") : null;

  const url = getEnvironmentURL();
  return fetch(
    `${url}/api/vehicles?per_page=${perPage}&page=${page}&${filterString}&${relationString}&order_by[]=vehicleid|desc`,
    requestOptions
  )
    .then(handleResponse)
    .then((response) => {
      if (response.data) return response;
      return [];
    });
}

function addVehicle(data = {}) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };

  const url = getEnvironmentURL();
  return fetch(`${url}/api/vehicles?`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    });
}

function getVehicle(id, relations = []) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  const relationStrings = buildRelations(relations);
  const relationString =
    relationStrings != null ? relationStrings.join("&") : null;

  const url = getEnvironmentURL();
  return fetch(`${url}/api/vehicles/${id}?${relationString}`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    });
}

function updateVehicle(id, data = {}) {
  const requestOptions = {
    method: "PUT",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };

  const url = getEnvironmentURL();
  return fetch(`${url}/api/vehicles/${id}?`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    });
}

function nhtsaVinLookup(vin, options = {}) {
  const recall = options.recall === true ? "recall" : "";
  // const conformanceCheck = options.conformanceCheck === true ? "conformanceCheck" : "";
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  const url = getEnvironmentURL();
  return fetch(`${url}/api/nhtsa/vin/${vin}?${recall}&conformanceCheck`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    });
}

function vin10VinLookup(vin, options = {}) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  const url = getEnvironmentURL();
  return fetch(`${url}/api/vin10/vin/${vin}`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    });
}

function vin10Dimensions(year, make, model, options = {}) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  const url = getEnvironmentURL();
  return fetch(
    `${url}/api/vin10/dimensions/${year}/${make}/${model}`,
    requestOptions
  )
    .then(handleResponse)
    .then((response) => {
      return response;
    });
}
