import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link as RouterLink } from "react-router-dom";

import { isEmpty } from "../../_helpers";
import { utilityActions } from "../../_actions";

import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  TextField,
  Typography,
} from "@material-ui/core";
import VehicleDetailHeaderSummaryDisplay from "./VehicleDetailHeaderSummaryDisplay";

export default function VehicleDetailTitleInformation(props) {
  const dispatch = useDispatch();
  const tsAdditional = useSelector(
    (state) => state.utility.title_status_additional
  );
  const [tsaItems, setTsaItems] = useState(null);

  const isAdmin = useSelector((state) =>
    state.authentication.webUser.roleid === 2 ||
    state.authentication.webUser.roleid === 11
      ? true
      : false
  );
  const states = useSelector((state) => state.utility.us_states);
  const [vehicle, setVehicle] = useState(props.item || {});
  const titleStatuses = props.titleStatuses;
  const titleApplicationTypes = props.titleApplicationTypes;
  const auctionList = props.auctionList || [];
  const clientList = props.clientList;
  const hasInvoiceBatch =
    vehicle.invoice && vehicle.invoice.invoice_batch ? true : false;
  const hasTitleStatusAdditional = vehicle?.client?.client_defaults.find(d => d.field === 'title_status_additional' && d.value === true) ? true : false

  useEffect(() => {
    setVehicle(props.item);
    if (tsaItems === null) {
      setTsaItems(props.item.title_status_additional.map((item) => item.id));
    }
  }, [props.item]);

  useEffect(() => {
    if (states.items.length === 0) {
      dispatch(
        utilityActions.getUtility("us_states", {
          perPage: 1000,
          orders: [
            {
              field: "stateabbreviation",
              direction: "asc",
            },
          ],
        })
      );
    }
  }, []);

  const changeTsaItems = (id, checked) => {
    let newTsaItems = [...tsaItems];
    if (checked) {
      newTsaItems = [...tsaItems, id];
    }
    if (!checked) {
      newTsaItems = tsaItems.filter((tsId) => tsId !== id);
    }
    setTsaItems(newTsaItems);
    props.onUpdateLocalExplicit("title_status_additional_update", newTsaItems);
  };

  return (
    <>
      <VehicleDetailHeaderSummaryDisplay
        vehicle={{
          vin: props?.item?.vehiclevin,
          year: props?.item?.yr,
          make: props?.item?.make,
          model: props?.item?.model,
        }}
        titleStatus={props?.item?.title_status?.statusname}
        timeInLatestStatus={props?.item?.time_in_latest_status}
        latestNote={props?.item?.latest_note?.note}
      />

      {isAdmin && (
        <>
          <Typography>
            Paperwork Batch:{" "}
            {vehicle.titledocsbatchid > 0 ? (
              <RouterLink to={`/paperworkbatches/${vehicle.titledocsbatchid}`}>
                {vehicle.titledocsbatchid}
              </RouterLink>
            ) : (
              <i>None</i>
            )}
          </Typography>
          <Typography>
            Invoice Batch:{" "}
            {vehicle.invoice && vehicle.invoice.invoice_batch ? (
              <RouterLink
                to={`/invoicebatches/${vehicle.invoice.invoicebatchid}`}
              >
                {vehicle.invoice.invoicebatchid}
              </RouterLink>
            ) : (
              <i>None</i>
            )}
          </Typography>
          <Typography>
            Client Load:{" "}
            {vehicle.client_load ? (
              <RouterLink to={`/clientloads/${vehicle.client_load.id}`}>
                {vehicle.client_load.name}
              </RouterLink>
            ) : (
              <i>None</i>
            )}
          </Typography>
          <Typography>
            Client Transporter:{" "}
            {vehicle.client_load && vehicle.client_load.transporter ? (
              vehicle.client_load.transporter.name
            ) : (
              <i>None</i>
            )}
          </Typography>
        </>
      )}

      <TextField
        margin="dense"
        name={"clientstocknumber"}
        label={"Client Stock Number"}
        type="text"
        value={
          vehicle.clientstocknumber == null ? "" : vehicle.clientstocknumber
        }
        onChange={props.onUpdateLocal}
        fullWidth
      />

      <FormControl fullWidth>
        <InputLabel shrink>Vehicle Status</InputLabel>
        <Select
          native
          value={vehicle.titlestatusid || 1}
          onChange={props.onUpdateLocal}
          disabled={!isAdmin}
          name="titlestatusid"
        >
          {titleStatuses.map((item) => {
            return (
              <option key={item.statusid} value={item.statusid}>
                {item.statuscode} - {item.statusname}
              </option>
            );
          })}
        </Select>
      </FormControl>

      {/* These are additional statuses for Problem */}
      {hasTitleStatusAdditional && Number(vehicle.titlestatusid) === 2 && (
        <FormGroup>
          {tsAdditional.items.map((tsItem) => (
            <FormControlLabel
              key={tsItem.id}
              control={
                <Checkbox
                  onChange={(e) => changeTsaItems(tsItem.id, e.target.checked)}
                  checked={
                    tsaItems
                      ? tsaItems.find((id) => id === tsItem.id)
                        ? true
                        : false
                      : false
                  }
                />
              }
              label={tsItem.name}
            />
          ))}
        </FormGroup>
      )}

      {/* Cancelled requires a reason */}
      {vehicle.titlestatusid === 5 && (
        <TextField
          margin="dense"
          required
          disabled={!isAdmin}
          name={"cancelledreason"}
          label={"Cancelled Reason"}
          helperText="Reason for Cancellation"
          type="text"
          value={vehicle.cancelledreason || ""}
          onChange={props.onUpdateLocal}
          fullWidth
        />
      )}
      <FormControl fullWidth>
        <InputLabel shrink>Processing Type</InputLabel>
        <Select
          native
          value={vehicle.processtype || 1}
          onChange={props.onUpdateLocal}
          displayEmpty
          disabled={!isAdmin}
          name="processtype"
        >
          <option value="">Processing Type</option>
          <option value={1}>In State</option>
          <option value={2}>Out of State</option>
        </Select>
      </FormControl>
      {vehicle.processtype == 2 && (
        <FormControl fullWidth>
          <InputLabel shrink>Process State</InputLabel>
          <Select
            native
            name="processstate"
            value={vehicle.processstate || ""}
            onChange={props.onUpdateLocal}
            displayEmpty
          >
            <option value="">State</option>
            {states &&
              states.items.map((state, index) => (
                <option key={index} value={state.stateid}>
                  {state.stateabbreviation} - {state.statefull}
                </option>
              ))}
          </Select>
        </FormControl>
      )}
      <FormControl fullWidth>
        <InputLabel shrink>Client Name (Bill To)</InputLabel>
        <Select
          native
          value={vehicle.clientid === 0 ? "" : vehicle.clientid}
          onChange={(event) => {
            props.onUpdateLocal(event);
            if (isEmpty(vehicle.billtoclientid)) {
              props.onUpdateLocalExplicit("billtoclientid", event.target.value);
            }
          }}
          displayEmpty
          disabled={!isAdmin}
          name="clientid"
        >
          <option value="">--Client Name (Bill To)</option>
          {clientList.map((item) => {
            return (
              <option key={item.clientid} value={item.clientid}>
                {item.clientname}
              </option>
            );
          })}
        </Select>
      </FormControl>
      <FormControl fullWidth>
        <InputLabel shrink>Title To</InputLabel>
        <Select
          native
          value={vehicle.billtoclientid === 0 ? "" : vehicle.billtoclientid}
          onChange={(e) => {
            props.onUpdateLocalExplicit("client_agent_id", null);
            props.onUpdateLocal(e);
          }}
          displayEmpty
          disabled={!isAdmin}
          name="billtoclientid"
        >
          <option value="">--Title to:</option>
          {clientList.map((item) => {
            return (
              <option key={item.clientid} value={item.clientid}>
                {item.clientname}
              </option>
            );
          })}
        </Select>
      </FormControl>
      <FormControl fullWidth>
        <InputLabel shrink>Purchasing Client</InputLabel>
        <Select
          native
          value={
            vehicle.purchaseclientid === 0 || vehicle.purchaseclientid === null
              ? ""
              : vehicle.purchaseclientid
          }
          onChange={props.onUpdateLocal}
          displayEmpty
          disabled={!isAdmin}
          name="purchaseclientid"
        >
          <option value="">--Purchasing Client:</option>
          {clientList.map((item) => {
            return (
              <option key={item.clientid} value={item.clientid}>
                {item.clientname}
              </option>
            );
          })}
        </Select>
      </FormControl>
      <FormControl fullWidth>
        <InputLabel shrink>Title Application Type</InputLabel>
        <Select
          native
          value={
            vehicle.titleprocessingtype === 0 ? "" : vehicle.titleprocessingtype
          }
          onChange={props.onUpdateLocal}
          displayEmpty
          disabled={!isAdmin || hasInvoiceBatch}
          name="titleprocessingtype"
        >
          <option value="">Title Application Type</option>
          {titleApplicationTypes.map((item) => {
            return (
              <option
                key={item.titleapplicationid}
                value={item.titleapplicationid}
              >
                {item.titleapplicationtype}
              </option>
            );
          })}
        </Select>
      </FormControl>
      <FormControl fullWidth>
        <InputLabel shrink>Dealer Title?</InputLabel>
        <Select
          native
          value={vehicle.retaildeal}
          onChange={props.onUpdateLocal}
          disabled={!isAdmin || hasInvoiceBatch}
          name="retaildeal"
        >
          <option value={0}>No</option>
          <option value={1}>Yes</option>
        </Select>
      </FormControl>

      <FormControl fullWidth>
        <InputLabel shrink>Auction Name (Bay Importer Address)</InputLabel>
        <Select
          native
          value={vehicle.auctionid || ""}
          onChange={props.onUpdateLocal}
          displayEmpty
          disabled={!isAdmin}
          name="auctionid"
        >
          <option value="">None</option>
          {auctionList.map((auction) => {
            return (
              <option key={auction.auctionid} value={auction.auctionid}>
                {auction.auctionname}
              </option>
            );
          })}
        </Select>
      </FormControl>

      <FormControl fullWidth>
        <InputLabel shrink>Auction Selling</InputLabel>
        <Select
          native
          value={vehicle.auction_id_selling || ""}
          onChange={props.onUpdateLocal}
          displayEmpty
          disabled={!isAdmin}
          name="auction_id_selling"
        >
          <option value="">None</option>
          {auctionList.map((auction) => {
            return (
              <option key={auction.auctionid} value={auction.auctionid}>
                {auction.auctionname}
              </option>
            );
          })}
        </Select>
      </FormControl>

      <FormControl fullWidth>
        <InputLabel shrink>Lienholder?</InputLabel>
        <Select
          native
          value={vehicle.is_lienholder === null ? 0 : vehicle.is_lienholder}
          onChange={props.onUpdateLocal}
          displayEmpty
          disabled={!isAdmin}
          name="is_lienholder"
        >
          <option value={0}>No</option>
          <option value={1}>Yes</option>
        </Select>
      </FormControl>

      <FormControl fullWidth>
        <InputLabel shrink>Speed?</InputLabel>
        <Select
          native
          value={vehicle.speedyesno === null ? 0 : vehicle.speedyesno}
          onChange={props.onUpdateLocal}
          displayEmpty
          disabled={!isAdmin}
          name="speedyesno"
        >
          <option value={0}>No</option>
          <option value={1}>Yes</option>
        </Select>
      </FormControl>
      <FormControl fullWidth>
        <InputLabel shrink>Vehicle History Report Run</InputLabel>
        <Select
          native
          value={vehicle.carfaxran === null ? 0 : vehicle.carfaxran}
          onChange={props.onUpdateLocal}
          displayEmpty
          disabled={!isAdmin}
          name="carfaxran"
        >
          <option value={0}>No</option>
          <option value={1}>Yes</option>
        </Select>
      </FormControl>
      <FormControl fullWidth>
        <InputLabel shrink>Speedo Change Required</InputLabel>
        <Select
          native
          value={
            vehicle.speedo_required === null ? "" : vehicle.speedo_required
          }
          onChange={props.onUpdateLocal}
          displayEmpty
          disabled={!isAdmin}
          name="speedo_required"
        >
          <option value={""}>Need to verify speedo requirement</option>
          <option value={0}>Speed Change Not Required</option>
          <option value={1}>Speedo Change Required</option>
        </Select>
      </FormControl>
      <FormControl fullWidth>
        <InputLabel shrink>Client Agent (Title To)</InputLabel>
        <Select
          native
          value={vehicle.client_agent_id || ""}
          onChange={props.onUpdateLocal}
          displayEmpty
          disabled={!isAdmin}
          name="client_agent_id"
        >
          <option value={""}>Client Agent for Salvage Affidavit, etc</option>
          {vehicle &&
            vehicle.billing_client &&
            vehicle.billing_client.client_agents.map((agent, index) => (
              <option key={index} value={agent.id}>
                {agent.name}
              </option>
            ))}
        </Select>
      </FormControl>
    </>
  );
}
