import React, { useState, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Prompt } from "react-router";
import { connect, useSelector, useDispatch } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import moment from "moment";
import MomentUtils from "@date-io/moment";

import { utilityActions } from "../_actions";

import { PageWithAppDrawer2 } from "../_components/main/PageWithAppDrawer2";
import LaunchTwoToneIcon from "@material-ui/icons/LaunchTwoTone";

import {
  Paper,
  Button,
  Divider,
  Typography,
  Grid,
  TextField,
  Backdrop,
  CircularProgress,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import { utilityService } from "../_services";
import { getEnvironmentURL } from "../_helpers";
import ModalGeneric from "../_components/generic/ModalGeneric";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";

class ManagementMassUpdatePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {}

  static getDerivedStateFromProps(nextProps, prevState) {
    return null;
  }

  render() {
    const { classes } = this.props;
    return (
      <PageWithAppDrawer2 pageTitle={"Mass Update"}>
        <Paper className={classes.paper}>
          <MassUpdate />
        </Paper>
      </PageWithAppDrawer2>
    );
  }
}

function MassUpdate(props) {
  const dispatch = useDispatch();
  const titleStatuses = useSelector((state) => state.utility.title_statuses);
  const webUser = useSelector((state) => state.authentication.webUser);
  const tsAdditional = useSelector(
    (state) => state.utility.title_status_additional
  );

  const [items, setItems] = useState([]);
  const [modalUpdates, setModalUpdates] = useState(false);
  const [modalUpdateValues, setModalUpdateValues] = useState({});
  const [modalVinInspect, setModalVinInspect] = useState(false);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [updateLog, setUpdateLog] = useState([]);
  const [tsaItems, setTsaItems] = useState([]);

  const [vinInspectName, setVinInspectName] = useState("");

  const handleSearchResults = (item) => {
    // console.log("MassUpdate.handleSearchResults", item);
    setItems((items) => [item, ...items]);
  };

  const removeItem = (id) => {
    setItems((items) => [...items.filter((item) => item.id !== id)]);
  };

  useEffect(() => {
    // console.log("useEffect items", items);
  }, [items]);

  const updateValue = (field, value) => {
    setModalUpdateValues({ ...modalUpdateValues, [field]: value });
  };

  const changeTsaItems = (id, checked) => {
    let newTsaItems = [...tsaItems];
    if (checked) {
      newTsaItems = [...tsaItems, id];
    }
    if (!checked) {
      newTsaItems = tsaItems.filter((tsId) => tsId !== id);
    }
    setTsaItems(newTsaItems);
    updateValue('title_status_additional_update', newTsaItems);
  };

  useEffect(() => {
    if (titleStatuses.items.length === 0) {
      dispatch(utilityActions.getUtility("title_statuses", { perPage: 1000 }));
    }
  }, []);

  const doMassUpdate = async () => {
    setUpdateLog([]);

    setOpenBackdrop(true);
    for (const [index, v] of items.entries()) {
      console.log("modalUpdateValues", modalUpdateValues);
      const note = modalUpdateValues["note"];
      const emptyNote = !note || /^\s*$/.test(note);
      let newValues = Object.assign({}, modalUpdateValues);
      delete newValues["note"];
      await utilityService
        .updateUtility("vehicles", v.vehicleid, newValues)
        .then((response) => {
          const text = `${v.vehiclevin}: updated`;
          setUpdateLog((updateLog) => [...updateLog, text]);
        })
        .catch((error) => {
          const errorText = `${v.vehiclevin}: ${error}`;
          setUpdateLog((updateLog) => [...updateLog, errorText]);
        });

      if (!emptyNote) {
        await utilityService
          .addUtility("vehicle_notes", {
            notedate: moment()
              .tz("America/Indiana/Indianapolis")
              .format("YYYY-MM-DD h:mm:ss"),
            vehicleid: v.vehicleid,
            userid: webUser.userid,
            roleid: webUser.roleid,
            complete: 0,
            note,
          })
          .catch((error) => {
            const errorText = `${v.vehiclevin}: ${error}`;
            setUpdateLog((updateLog) => [...updateLog, errorText]);
          });
      }
    }
    setModalUpdateValues({});
    setOpenBackdrop(false);
  };

  const doVinInspect = () => {
    let url = `${getEnvironmentURL()}/api/vehicles/${items
      .map((v) => v.vehicleid)
      .join(",")}/multipleForms/0?docs=1&r[Text2.13.0]=${vinInspectName}`;
    window.open(url, "_blank");
  };

  return (
    <>
      <form
        id="bay-form"
        method="post"
        action={
          getEnvironmentURL() + "/api/reports/management/bay/recallWarranty"
        }
        target="_blank"
      >
        {items.map((v, index) => (
          <input
            key={index}
            type="hidden"
            name="vehicles[]"
            value={v.vehicleid}
          />
        ))}
      </form>
      <form
        id="conformity-inspection-form"
        method="post"
        action={
          getEnvironmentURL() + "/api/reports/management/conformity/inspection"
        }
        target="_blank"
      >
        {items.map((v, index) => (
          <input
            key={index}
            type="hidden"
            name="vehicles[]"
            value={v.vehicleid}
          />
        ))}
      </form>
      <Prompt
        when={items && items.length > 0}
        message="You have items in Mass Update, are you sure you want to leave?"
      />
      <Typography>Mass Update</Typography>
      <VehicleSearchContainer onSearchResults={handleSearchResults} />
      <Divider style={{ marginTop: 30 }} />
      <Typography>
        Assets to update:{" "}
        <Button
          size="small"
          variant="contained"
          color="primary"
          disabled={items.length === 0}
          onClick={() => setModalUpdates(true)}
        >
          Choose Updates
        </Button>{" "}
        <Button
          form="bay-form"
          type="submit"
          size="small"
          variant="contained"
          color="primary"
          disabled={items.length === 0}
        >
          Bay Recall Warranties
        </Button>{" "}
        <Button
          form="conformity-inspection-form"
          type="submit"
          size="small"
          variant="contained"
          color="primary"
          disabled={items.length === 0}
        >
          Conformity Inspections
        </Button>{" "}
        <Button
          size="small"
          variant="contained"
          color="primary"
          disabled={items.length === 0}
          onClick={() => setModalVinInspect(true)}
        >
          VIN Inspections
        </Button>{" "}
        <Button
          size="small"
          variant="contained"
          color="primary"
          disabled={items.length === 0}
          onClick={() => {
            setItems([]);
            setUpdateLog([]);
          }}
        >
          Clear Results
        </Button>
      </Typography>
      <Grid container style={{ marginTop: 10 }}>
        {items.map((item, index) => (
          <React.Fragment key={index}>
            <Grid item xs={6} sm={2}>
              <Button color="primary" onClick={() => removeItem(item.id)}>
                Remove
              </Button>
            </Grid>
            <Grid item xs={6} sm={2}>
              <RouterLink to={`/vehicles/${item.vehicleid}`} target="_blank">
                {item.vehiclevin}
                <LaunchTwoToneIcon style={{ fontSize: 12 }} color="primary" />
              </RouterLink>
              <br />
              {item.yr} {item.make} {item.model}
            </Grid>
            <Grid item xs={6} sm={2}>
              {item.title_status && item.title_status.statusname}
            </Grid>
            <Grid item xs={6} sm={2}>
              <b>Load</b>
              <br />
              {item.client_load ? (
                <RouterLink
                  to={`/clientLoads/${item.client_load.id}`}
                  target="_blank"
                >
                  {item.client_load.name}
                  <LaunchTwoToneIcon style={{ fontSize: 12 }} color="primary" />
                </RouterLink>
              ) : (
                <i>No Load</i>
              )}
            </Grid>
            <Grid item xs={6} sm={2}>
              <b>Client</b>
              <br />
              {item.client ? item.client.clientname : <i>No Client</i>}
            </Grid>
            <Grid item xs={6} sm={1}>
              <b>DOT Submit</b>
              <br />
              {item.submitteddotdate || "No DOT Sub"}
            </Grid>
            <Grid item xs={6} sm={1}>
              <b>Vault Release</b>
              <br />
              {item.vaultreleasedate || "No Vault"}
              <br />
              <b>Bond Release</b>
              <br />
              {item.bondreleasedate || "No Bond Release"}
            </Grid>
          </React.Fragment>
        ))}
      </Grid>
      <Divider style={{ marginTop: 30 }} />
      <Typography>Update Results</Typography>
      {updateLog.map((log, index) => (
        <div key={index}>{log}</div>
      ))}
      <ModalGeneric
        open={modalUpdates}
        title="Update Fields"
        closeText="Update All"
        onClose={() => {
          setModalUpdates(false);
          doMassUpdate();
        }}
        cancelText="Discard Changes"
        onCancel={() => {
          setModalUpdates(false);
          setModalUpdateValues({});
        }}
      >
        <Grid container>
          {fields.map((field, index) => {
            let fieldColor = "inherit";
            // console.log(modalUpdateValues[field.field]);
            if (typeof modalUpdateValues[field.field] !== "undefined") {
              fieldColor = "yellow";
            }
            if (field.type === "titlestatus") {
              return (
                <React.Fragment key={index}>
                  <Grid item xs={4} style={{ backgroundColor: fieldColor }}>
                    {field.name}
                  </Grid>
                  <Grid item xs={8} style={{ backgroundColor: fieldColor }}>
                    <FormControl fullWidth>
                      <InputLabel shrink>Vehicle Status</InputLabel>
                      <Select
                        native
                        value={modalUpdateValues[field.field] || ""}
                        onChange={(e) =>
                          updateValue(field.field, e.target.value)
                        }
                        displayEmpty
                        name="titlestatusid"
                      >
                        {titleStatuses.items.map((item, index) => {
                          return (
                            <option key={index} value={item.statusid}>
                              {item.statuscode} - {item.statusname}
                            </option>
                          );
                        })}
                      </Select>
                    </FormControl>
                    {/* These are additional statuses for Problem */}
                    {Number(modalUpdateValues[field.field]) === 2 && (
                      <FormGroup>
                        {tsAdditional.items.map((tsItem) => (
                          <FormControlLabel
                            key={tsItem.id}
                            control={
                              <Checkbox
                                onChange={(e) =>
                                  changeTsaItems(tsItem.id, e.target.checked)
                                }
                                checked={
                                  tsaItems
                                    ? tsaItems.find((id) => id === tsItem.id)
                                      ? true
                                      : false
                                    : false
                                }
                              />
                            }
                            label={tsItem.name}
                          />
                        ))}
                      </FormGroup>
                    )}
                  </Grid>
                </React.Fragment>
              );
            }
            if (field.type === "note") {
              return (
                <React.Fragment key={index}>
                  <Grid item xs={4} style={{ backgroundColor: fieldColor }}>
                    {field.name}
                  </Grid>
                  <Grid item xs={8} style={{ backgroundColor: fieldColor }}>
                    <FormControl fullWidth>
                      <TextField
                        id="outlined-multiline-static"
                        label="New Note"
                        multiline
                        rows="2"
                        variant="outlined"
                        style={{ width: "100%" }}
                        onChange={(e) =>
                          updateValue(field.field, e.target.value)
                        }
                      />
                    </FormControl>
                  </Grid>
                </React.Fragment>
              );
            }
            if (field.type === "date") {
              return (
                <React.Fragment key={index}>
                  <Grid item xs={4} style={{ backgroundColor: fieldColor }}>
                    {field.name}
                  </Grid>
                  <Grid item xs={8} style={{ backgroundColor: fieldColor }}>
                    {field.type === "date" && (
                      <MuiPickersUtilsProvider utils={MomentUtils}>
                        <KeyboardDatePicker
                          clearable
                          style={{ fontSize: 12 }}
                          // variant="inline"
                          label={field.name}
                          format="MM/DD/YYYY"
                          autoOk={true}
                          value={modalUpdateValues[field.field] || null}
                          InputProps={{
                            style: { fontSize: 12 },
                          }}
                          InputLabelProps={{ style: { fontSize: 12 } }}
                          onChange={(date) =>
                            updateValue(
                              field.field,
                              date ? date.format("YYYY-MM-DD") : ""
                            )
                          }
                        />
                      </MuiPickersUtilsProvider>
                    )}
                  </Grid>
                </React.Fragment>
              );
            }
            return null;
          })}
        </Grid>
      </ModalGeneric>

      <ModalGeneric
        open={modalVinInspect}
        title="Vin Inspect"
        closeText="Print"
        onClose={() => {
          setModalVinInspect(false);
          doVinInspect();
        }}
        cancelText="Cancel"
        onCancel={() => {
          setModalVinInspect(false);
        }}
      >
        <Grid container>
          <Grid item xs={4}>
            Printed Name
          </Grid>
          <Grid item xs={8}>
            <FormControl fullWidth>
              <TextField
                id="outlined-multiline-static"
                label="Printed Name"
                rows="2"
                variant="outlined"
                style={{ width: "100%" }}
                onChange={(e) => setVinInspectName(e.target.value)}
              />
            </FormControl>
          </Grid>
        </Grid>
      </ModalGeneric>

      <Backdrop open={openBackdrop} style={{ zIndex: 1000, color: "white" }}>
        <CircularProgress />
        <br />
        Mass Update Status:
        <br />
        {updateLog.map((log, index) => {
          return (
            <React.Fragment key={index}>
              {log}
              <br />
            </React.Fragment>
          );
        })}
      </Backdrop>
    </>
  );
}

function VehicleSearchContainer(props) {
  const [modalOpen, setModalOpen] = useState(false);
  const [modalItems, setModalItems] = useState([]);
  const [last6, setLast6] = useState("");
  const [vin, setVin] = useState("");

  const handleSearchResults = async (value, type) => {
    let field = "vin";
    if (type === "last6") field = "last6";
    if (type === "vin") field = "vehiclevin";
    await utilityService
      .getUtility("vehicles", {
        filters: [
          {
            field,
            operator: "eq",
            value,
          },
        ],
        orders: [
          {
            field: "vehicleid",
            direction: "desc",
          },
        ],
        relations: ["bay_vehicle", "client_load", "title_status"],
      })
      .then((response) => {
        // console.log(
        //   "VehicleSearchContainer.handleSearchResults.response",
        //   response
        // );
        if (response.data.length === 1) {
          if (props.onSearchResults) {
            props.onSearchResults(response.data[0]);
            return;
          }
        }
        if (response.data.length > 1) {
          setModalItems(response.data);
          setModalOpen(true);
        }
      });
  };
  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <TextField
            margin="dense"
            name={"last6"}
            label={"Last6"}
            value={last6}
            onChange={(e) => setLast6(e.target.value)}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                handleSearchResults(e.target.value, "last6");
                setLast6("");
              }
            }}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            margin="dense"
            name={"VIN"}
            label={"VIN"}
            value={vin}
            onChange={(e) => setVin(e.target.value)}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                handleSearchResults(e.target.value, "vin");
                setVin("");
              }
            }}
            fullWidth
          />
        </Grid>
      </Grid>
      <ModalGeneric
        open={modalOpen}
        title="Multiple Results"
        onClose={() => setModalOpen(false)}
      >
        {modalItems.map((item, index) => {
          const status = item.title_status?.statusname;
          const bgColor = status === "Cancelled" ? "red" : "";
          return (
            <div style={{ marginBottom: 5 }} key={index}>
              <Button
                size="small"
                variant="contained"
                color="primary"
                style={{ backgroundColor: bgColor }}
                onClick={() => {
                  props.onSearchResults(item);
                  setModalItems([]);
                  setModalOpen(false);
                }}
              >
                Select
              </Button>{" "}
              {item.vehiclevin} {item.yr} {item.make} {item.model}
              <br />
              {item.client ? item.client.clientname : "No Client"} -{" "}
              {item.recordcreateddate}
              <br />
              <Typography>{status}</Typography>
              <Divider />
            </div>
          );
        })}
      </ModalGeneric>
    </>
  );
}

const styles = (theme) => ({
  root: {
    display: "flex",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
});

const fields = [
  {
    name: "Vehicle Status",
    field: "titlestatusid",
    type: "titlestatus",
  },
  {
    name: "Note",
    field: "note",
    type: "note",
  },
  {
    name: "Repo/Sale Date",
    field: "repossessiondate",
    type: "date",
  },
  {
    name: "Title Ownership",
    field: "titleregownershipdate",
    type: "date",
  },
  {
    name: "Title Scan Ownership",
    field: "title_reg_ownership_scan_date",
    type: "date",
  },
  {
    name: "Sent To BMV",
    field: "titlesenttobmvdate",
    type: "date",
  },
  {
    name: "Title White Copy Received",
    field: "state_white_copy_date",
    type: "date",
  },
  {
    name: "Sent Back from BMV",
    field: "titlebackfrombmvdate",
    type: "date",
  },

  {
    name: "Title Sent To Client/Auction",
    field: "titlesenttoauctiondate",
    type: "date",
  },
  {
    name: "Submitted To Customs",
    field: "submittedtocustomsdate",
    type: "date",
  },
  {
    name: "Crossed Border",
    field: "crossedborderdate",
    type: "date",
  },
  {
    name: "Scanned 7501 Date",
    field: "scanned_7501_date",
    type: "date",
  },
  {
    name: "Confirmed Laded",
    field: "confirmedladeddate",
    type: "date",
  },
  {
    name: "DOT Submitted",
    field: "submitteddotdate",
    type: "date",
  },
  {
    name: "Vault Release",
    field: "vaultreleasedate",
    type: "date",
  },
  {
    name: "Bond Release",
    field: "bondreleasedate",
    type: "date",
  },
  {
    name: "Speedo Pulled Date",
    field: "speedo_pulled_date",
    type: "date",
  },
  {
    name: "Speedo Shipped Date",
    field: "speedo_shipped_date",
    type: "date",
  },
  {
    name: "Speedo Received Date",
    field: "speedo_received_date",
    type: "date",
  },
  {
    name: "Speedo Placed Date",
    field: "speedo_placed_date",
    type: "date",
  },
];

function mapStateToProps(state) {
  const { authentication, utility } = state;
  const { user } = authentication;
  const { us_states, auctions } = utility;
  return {
    user,
    auctions,
    us_states,
  };
}

const connectedManagementMassUpdatePage = connect(mapStateToProps)(
  withStyles(styles)(ManagementMassUpdatePage)
);
export { connectedManagementMassUpdatePage as ManagementMassUpdatePage };
