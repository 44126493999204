/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import {
  Button,
  CircularProgress,
  Divider,
  Grid,
  Link,
  Snackbar,
  Typography,
} from "@material-ui/core";
import React, {
  useState,
  useEffect,
  Fragment,
  useCallback,
  useMemo,
} from "react";
import { useDropzone } from "react-dropzone";
import MuiAlert from "@material-ui/lab/Alert";
import { authHeader, getEnvironmentURL } from "../../_helpers";
import { Launch } from "@material-ui/icons";
import { utilityService } from "../../_services";

export default function ClientLoadTransport(props) {
  const { load, onRefreshItem } = props;
  const [loading, setLoading] = useState(false);
  const [count7501, setCount7501] = useState(0);
  const [has7501, setHas7501] = useState(false);
  const [missingBayVehicleId, setMissingBayVehicleId] = useState(false);

  const [snackbar, setSnackbar] = useState({
    open: false,
    severity: "info", //error, warning, info, success
    message: "default message",
    duration: 6000,
  });

  useEffect(() => {
    let count = 0;
    let missingBay = false;
    let link7501 = null;
    setHas7501(link7501);
    if (load.vehicles.length > 0) {
      load.vehicles.map((v) => {
        if (!v.bay_vehicle?.bay_vehicle_id) {
          missingBay = true;
        }
        v.images.map((i) => {
          if (i.docnote === "7501 Not Stamped") {
            count++;
            link7501 = i.cloud_url_orig;
          }
          return null;
        });
        return null;
      });
      setCount7501(count);
      if (count > 0) {
        setHas7501(link7501);
      }
      setMissingBayVehicleId(missingBay);
    }
    setLoading(load?.loading);
  }, [load]);

  const snackbarShow = (message, severity = "info", duration = 6000) => {
    // type Color = "info" | "error" | "success" | "warning"
    setSnackbar({
      open: true,
      severity,
      message,
      duration,
    });
  };

  const snackbarClose = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const handleReject7501 = (file, errors) => {
    snackbarShow(file.path + " is not valid. " + errors[0].message, "error");
  };

  const handleRejectOwnership = (file, errors) => {
    snackbarShow(file.path + " is not valid. " + errors[0].message, "error");
  };

  const handleAdd7501 = (file) => {
    let formData = new FormData();
    formData.append("file", file);
    setLoading(true);
    fetch(
      `${getEnvironmentURL()}/api/client_loads/${
        load.id
      }/transport/add7501NotStamped`,
      {
        // Your POST endpoint
        method: "POST",
        headers: { ...authHeader() },
        body: formData,
      }
    )
      .then(
        (response) => response.json() // if the response is a JSON object
      )
      .then((data) => {
        // console.log("success", success); // Handle the success response object
        snackbarShow("7501 Uploaded", "success");
        props.onRefreshItem && props.onRefreshItem();
      })
      .catch((error) => {
        snackbarShow("Error 7501 Upload", "error");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleAddOwnership = (file, vehicleId) => {
    let formData = new FormData();
    formData.append("file", file);
    setLoading(true);
    fetch(
      `${getEnvironmentURL()}/api/client_loads/${
        load.id
      }/vehicle/${vehicleId}/transport/addOwnership`,
      {
        // Your POST endpoint
        method: "POST",
        headers: { ...authHeader() },
        body: formData,
      }
    )
      .then(
        (response) => response.json() // if the response is a JSON object
      )
      .then((data) => {
        // console.log("success", success); // Handle the success response object
        snackbarShow("Ownership Uploaded", "success");
        props.onRefreshItem && props.onRefreshItem();
      })
      .catch((error) => {
        snackbarShow("Error Ownership Upload", "error");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleDelete7501 = () => {
    const rc = window.confirm("Delete 7501 Not Stamped from load?");
    if (!rc) {
      return;
    }
    setLoading(true);
    utilityService
      .getEndpoint(
        `api/client_loads/${load.id}/transport/remove7501NotStamped`,
        "DELETE"
      )
      .then((response) => {
        props.onRefreshItem && props.onRefreshItem();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleDeleteOwnership = (vehicleImageId, vin) => {
    const rc = window.confirm(`Delete ownership for ${vin}?`);
    if (!rc) {
      return;
    }
    setLoading(true);
    utilityService
      .getEndpoint(`api/vehicle_images/${vehicleImageId}`, "DELETE")
      .then((response) => {
        props.onRefreshItem && props.onRefreshItem();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      {loading ? (
        <CircularProgress />
      ) : (
        <Grid container style={{ padding: 10 }} spacing={1}>
          {missingBayVehicleId && (
            <>
              <Typography style={{ color: "red" }}>
                Some vehicles are missing Bay Vehicle Id
              </Typography>
            </>
          )}
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              target="_blank"
              disabled={missingBayVehicleId || !has7501}
              href={`${getEnvironmentURL()}/api/client_loads/${
                load.id
              }/transport/transportGetLoadFiles`}
            >
              Get All Documents
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5">
              7501 Not Stamped{" "}
              <Typography
                style={{
                  display: "inline",
                  color: "green",
                  fontWeight: "bold",
                }}
              >{`(${count7501} vehicles with 7501)`}</Typography>
            </Typography>
            {has7501 ? (
              <>
                <Link target="_blank" href={has7501}>
                  7501 Not Stamped <Launch />
                </Link>
                <br />
                <Button
                  variant="contained"
                  style={{ backgroundColor: "red" }}
                  onClick={handleDelete7501}
                >
                  Delete 7501 from all vehicles
                </Button>
              </>
            ) : (
              <DragAndDrop
                onAddFile={handleAdd7501}
                onRejectFile={handleReject7501}
                acceptableTypes={[".pdf"]}
              >
                <Typography>Upload 7501 (not stamped)</Typography>
              </DragAndDrop>
            )}
          </Grid>
          <Grid xs={12}>
            <Divider style={{ marginTop: 10, marginBottom: 10 }} />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5">Vehicle Documents</Typography>
          </Grid>

          {load?.vehicles?.map((v) => {
            const has7501 = v.images.find(
              (i) => i.docnote === "7501 Not Stamped"
            );
            const hasOwnership = v.images.find(
              (i) => i.docnote === "Canadian Ownership"
            );

            return (
              <Fragment key={v.vehicleid}>
                <Grid item style={{ border: "1px solid black" }}>
                  <Typography>{v.vehiclevin}</Typography>
                  {!v.bay_vehicle?.bay_vehicle_id && (
                    <Typography style={{ color: "red" }}>
                      Bay ID Missing
                    </Typography>
                  )}
                  {hasOwnership ? (
                    <>
                      <Link href={hasOwnership.cloud_url_orig} target="_blank">
                        {hasOwnership.docnote}{" "}
                        <Launch style={{ fontSize: 12 }} />
                      </Link>
                      <br/>
                      <Button
                        variant="contained"
                        style={{ backgroundColor: "red" }}
                        onClick={() =>
                          handleDeleteOwnership(hasOwnership.id, v.vehiclevin)
                        }
                      >
                        Delete Ownership
                      </Button>
                    </>
                  ) : (
                    <DragAndDrop
                      onAddFile={(file) =>
                        handleAddOwnership(file, v.vehicleid)
                      }
                      onRejectFile={handleRejectOwnership}
                      acceptableTypes={[
                        ".pdf",
                        ".png",
                        ".jpg",
                        ".jpeg",
                        ".jfif",
                      ]}
                    >
                      <Typography>
                        Upload Ownership for {v.vehiclevin}
                      </Typography>
                    </DragAndDrop>
                  )}
                </Grid>
              </Fragment>
            );
          })}
        </Grid>
      )}
      <Snackbar
        color={snackbar.severity}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={snackbar.open}
        autoHideDuration={snackbar.duration}
        onClose={snackbarClose}
      >
        <MuiAlert severity={snackbar.severity} variant="filled">
          {snackbar.message}
        </MuiAlert>
      </Snackbar>
    </>
  );
}

function DragAndDrop(props) {
  const acceptableTypes = props.acceptableTypes || [".csv"];
  const onDrop = useCallback((acceptedFiles, fileRejections) => {
    acceptedFiles.forEach((file) => {
      if (props.metaData) {
        props.onAddFile && props.onAddFile(file, props.metadata);
      } else {
        props.onAddFile && props.onAddFile(file);
      }
    });
    fileRejections.forEach((file) => {
      if (props.metaData) {
        props.onRejectFile &&
          props.onRejectFile(file.file, file.errors, props.metaData);
      } else {
        props.onRejectFile && props.onRejectFile(file.file, file.errors);
      }
    });
  }, []);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop,
    accept: acceptableTypes,
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  return (
    <div className="container">
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} />
        {props.children || "Upload File"}
        <Divider style={{ width: "100%" }} />
        Valid Files:
        {acceptableTypes.map((acceptableType) => (
          <Typography>{acceptableType}</Typography>
        ))}
      </div>
    </div>
  );
}

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
};

const activeStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};
