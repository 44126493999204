import { paperworkConstants } from "../_constants";
import { paperworkService } from "../_services";
import { alertActions } from ".";

export const paperworkActions = {
  addPaperwork,
  getPaperwork,
  updatePaperwork,
  updateLocalPaperwork,
  addVehicleToPaperwork,
  removeVehicleFromPaperwork,
  clearPaperwork,
};

function addPaperwork(data = {}, callback) {
  return (dispatch) => {
    dispatch(request());

    paperworkService
      .addPaperwork(data)
      .then(
        (paperwork) => {
          dispatch(success(paperwork));
          return paperwork;
        },
        (error) => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      )
      .then((paperwork) => {
        if (callback) callback(paperwork);
      });
  };

  function request() {
    return { type: paperworkConstants.PAPERWORK_ADD_REQUEST };
  }
  function success(paperwork) {
    return { type: paperworkConstants.PAPERWORK_ADD_SUCCESS, paperwork };
  }
  function failure(error) {
    return { type: paperworkConstants.PAPERWORK_ADD_FAILURE, error };
  }
}

function getPaperwork(id, relations = []) {
  // console.log("actions relations", relations);
  if (!relations) relations = ["vehicles"];

  return (dispatch) => {
    dispatch(request());

    paperworkService.getPaperwork(id, relations).then(
      (paperwork) => {
        dispatch(success(paperwork));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request() {
    return { type: paperworkConstants.PAPERWORK_GET_REQUEST };
  }
  function success(paperwork) {
    return { type: paperworkConstants.PAPERWORK_GET_SUCCESS, paperwork };
  }
  function failure(error) {
    return { type: paperworkConstants.PAPERWORK_GET_FAILURE, error };
  }
}

function updatePaperwork(id, data = {}) {
  return (dispatch) => {
    dispatch(request());

    paperworkService.updatePaperwork(id, data).then(
      (paperwork) => {
        dispatch(success(paperwork));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request() {
    return { type: paperworkConstants.PAPERWORK_UPDATE_REQUEST };
  }
  function success(paperwork) {
    return { type: paperworkConstants.PAPERWORK_UPDATE_SUCCESS, paperwork };
  }
  function failure(error) {
    return { type: paperworkConstants.PAPERWORK_UPDATE_FAILURE, error };
  }
}

function addVehicleToPaperwork(id, vehicleid, callback = null) {
  return (dispatch) => {
    dispatch(request());

    paperworkService.addVehicleToPaperwork(id, vehicleid).then(
      (paperwork) => {
        dispatch(success(paperwork));
        if (callback) {
          callback(paperwork);
        }
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request() {
    return { type: paperworkConstants.PAPERWORK_UPDATE_REQUEST };
  }
  function success(paperwork) {
    return { type: paperworkConstants.PAPERWORK_UPDATE_SUCCESS, paperwork };
  }
  function failure(error) {
    return { type: paperworkConstants.PAPERWORK_UPDATE_FAILURE, error };
  }
}

function removeVehicleFromPaperwork(id, vehicleid, callback = null) {
  return (dispatch) => {
    dispatch(request());

    paperworkService.removeVehicleFromPaperwork(id, vehicleid).then(
      (paperwork) => {
        dispatch(success(paperwork));
        if (callback) {
          callback(paperwork);
        }
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request() {
    return { type: paperworkConstants.PAPERWORK_UPDATE_REQUEST };
  }
  function success(paperwork) {
    return { type: paperworkConstants.PAPERWORK_UPDATE_SUCCESS, paperwork };
  }
  function failure(error) {
    return { type: paperworkConstants.PAPERWORK_UPDATE_FAILURE, error };
  }
}

function updateLocalPaperwork(data) {
  return (dispatch) => {
    dispatch(update(data));
  };

  function update(data) {
    return { type: paperworkConstants.PAPERWORK_SET_LOCAL, data };
  }
}

function clearPaperwork() {
  return (dispatch) => {
    dispatch(clear());
  };

  function clear() {
    return { type: paperworkConstants.PAPERWORK_CLEAR };
  }
}
