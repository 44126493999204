import React from "react";
import { connect } from "react-redux";
import { withStyles, withTheme } from "@material-ui/core/styles";
import withWidth from "@material-ui/core/withWidth";

import { PageWithAppDrawer2 } from "../_components/main/PageWithAppDrawer2";
import TitleServiceDialog from "../_components/homepage/TitleServicesDialog";
import RegisteredImportationDialog from "../_components/homepage/RegisteredImportationDialog";

// import DashboardMenu from "./DashboardMenu";
// import { VehiclesPage } from "../Pages/VehiclesPage";
// import { AuctionsPage } from "../AuctionsPage/AuctionsPage";

import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";

import logo from "../_assets/adesalogo.png";

class HomePageAdesa extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      titleServiceOpen: false,
      registeredImportOpen: false,
    };
  }

  componentDidMount() {}

  render() {
    const { classes } = this.props;
    const { titleServiceOpen, registeredImportOpen } = this.state;
    const mobile = ["xs"].includes(this.props.width);

    return (
      <PageWithAppDrawer2
        pageTitle={"Home"}
        showSideBar={true}
        showHomeLinks={true}
      >
        <Container maxWidth="lg" className={classes.container}>
          <Grid container className={classes.titleGrid}>
            <Grid item xs={12}>
              <Paper className={classes.paper}>
                <div style={{ textAlign: "center" }}>
                  <img
                    src={logo}
                    alt=""
                    style={{ width: "100%", maxWidth: 400 }}
                  />
                  <Typography variant="subtitle1">
                    <Typography
                      color="primary"
                      display="inline"
                      style={{ fontWeight: "bold" }}
                    >
                      ADESA
                    </Typography>{" "}
                    provides wholesale vehicle auction solutions to professional
                    car buyers and sellers
                  </Typography>
                </div>
              </Paper>
            </Grid>
          </Grid>
        </Container>
        <TitleServiceDialog
          open={titleServiceOpen}
          onClose={() => {
            this.setState({ titleServiceOpen: false });
          }}
        />
        <RegisteredImportationDialog
          open={registeredImportOpen}
          onClose={() => {
            this.setState({ registeredImportOpen: false });
          }}
        />
      </PageWithAppDrawer2>
    );
  }
}

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
  },
  titleGrid: {
    paddingBottom: theme.spacing(2),
  },
});

function mapStateToProps(state) {
  const { users, authentication, dms } = state;
  const { user } = authentication;
  return {
    user,
    users,
    dms,
  };
}

const connectedHomePageAdesa = connect(mapStateToProps)(
  withWidth()(withTheme(withStyles(styles)(HomePageAdesa)))
);
export { connectedHomePageAdesa as HomePageAdesa };
