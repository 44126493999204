import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { red } from "@material-ui/core/colors";
import { Grid } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { utilityActions } from "../_actions";
import ResponsiveGrid from "./generic/ResponsiveGrid";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  card: {
    maxWidth: 345,
  },
  media: {
    height: 150,
    paddingTop: "56.25%", // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatar: {
    backgroundColor: red[500],
  },
}));

export default function HistoryItems(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { id, objType } = props;

  const history_items = useSelector((state) => state.utility.history_items);
  const history_items_clientload = useSelector(
    (state) => state.utility.history_items_clientload
  );

  const [items, setItems] = useState([]);

  const [showHistory, setShowHistory] = useState(false);

  useEffect(() => {
    if (showHistory === true) {
      getData();
    }
  }, [showHistory]);

  useEffect(() => {
    if (objType === "vehicle" && history_items.items.length > 0) {
      setItems(history_items);
    } else if (
      objType === "clientload" &&
      history_items_clientload.items.length > 0
    ) {
      setItems(history_items_clientload);
    }
  }, [history_items, history_items_clientload]);

  const getData = () => {
    let endpoint = "history_items";
    let objectType = "vehicle";
    if (objType === "clientload") {
      endpoint = "history_items_clientload";
      objectType = "client_load";
    }
    dispatch(
      utilityActions.getUtility(endpoint, {
        perPage: 1000,
        filters: [
          {
            field: "object_type",
            operator: "eq",
            value: objectType,
          },
          {
            field: "object_type_id",
            operator: "eq",
            value: id,
          },
        ],
        orders: [
          {
            field: "id",
            direction: "desc",
          },
        ],
      })
    );
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography>History Items</Typography>
      </Grid>
      {showHistory === false ? (
        <Grid item xs={12}>
          <Button
            size="small"
            color="primary"
            variant="contained"
            onClick={() => setShowHistory(true)}
          >
            Retrieve History
          </Button>
        </Grid>
      ) : (
        <Grid item xs={12}>
          <Button
            size="small"
            color="primary"
            variant="contained"
            onClick={() => getData()}
          >
            Refresh History
          </Button>
        </Grid>
      )}

      {showHistory && (
        <Grid item xs={12}>
          <ResponsiveGrid
            loading={items.loading}
            data={items.items}
            columns={columns}
            usePagination={false}
          />
        </Grid>
      )}
    </Grid>
  );
}

const columns = [
  {
    width: 2,
    title: "Date",
    field: "created_at",
    dataRender: (item) => moment(item.created_at).format("MM/DD/YYYY"),
  },
  {
    width: 2,
    title: "Field",
    field: "target_field",
  },
  {
    width: 3,
    title: "Value",
    field: "value",
  },
  {
    width: 2,
    title: "User",
    field: "user.name",
  },
];
