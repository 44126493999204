import React, { useState, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import Link from "@material-ui/core/Link";
import { connect, useDispatch } from "react-redux";

import MomentUtils from "@date-io/moment";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import { withStyles } from "@material-ui/core/styles";
import withWidth from "@material-ui/core/withWidth";

import { PageWithAppDrawer2 } from "../_components/main/PageWithAppDrawer2";
import ModalPaperworkAddVehicle from "../_components/vehicle/ModalPaperworkAddVehicle";
import ModalPrintPaperwork from "../_components/vehicle/ModalPrintPaperwork";
import ModalUpdateMileage from "../_components/vehicle/ModalUpdateMileage";
import ResponsiveGrid from "../_components/generic/ResponsiveGrid";
import { utilityActions, paperworkActions } from "../_actions";
import { getEnvironmentURL } from "../_helpers";
import {
  Paper,
  Typography,
  Button,
  Grid,
  TextField,
  Backdrop,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import { utilityService } from "../_services";
import moment from "moment";

class PaperworkBatchDetailsPage extends React.Component {
  constructor(props) {
    super(props);
    const { paperworkId } = this.props.match ? this.props.match.params : {};
    this.state = {
      paperworkId,
      reload: false,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { paperworkId } = nextProps.match ? nextProps.match.params : {};
    if (paperworkId !== prevState.paperworkId) {
      return {
        ...prevState,
        paperworkId,
        reload: true,
      };
    }
    return false;
  }

  async componentDidMount() {
    const { items: clients } = this.props.utility.clients_active;

    if (clients.length === 0) {
      await this.props.dispatch(
        utilityActions.getUtility("clients_active", {
          perPage: 1000,
          filters: [
            {
              field: "clientactive",
              operator: "eq",
              value: "2",
            },
          ],
          orders: [{ field: "clientname", direction: "asc" }],
        })
      );
    }

    this.getNewData();
  }

  render() {
    const { paperworkId, reload } = this.state;
    const { data: item, loading } = this.props.paperwork.paperwork;
    const { classes } = this.props;

    if (reload === true) {
      this.setState({ reload: false }, () => {
        this.componentDidMount();
      });
      return <></>;
    }

    return (
      <PageWithAppDrawer2 pageTitle={`Batch #${paperworkId}`}>
        <RouterLink to="/paperworkbatches">All Paperwork Batches</RouterLink>
        <Paper className={classes.paper}>
          <PaperworkBatchDetails
            item={item}
            loading={loading}
            clientList={this.props.utility.clients_active.items}
            onRemoveVehicle={this.handleRemoveVehicle}
            onRefresh={this.getNewData}
          />
        </Paper>
      </PageWithAppDrawer2>
    );
  }

  getNewData = async (page = null, perPage = null) => {
    console.log("getNewData");
    const { paperworkId } = this.state;

    await this.props.dispatch(
      paperworkActions.getPaperwork(paperworkId, ["vehicles_paperwork"])
    );

    this.setState({ loading: false });
  };

  handleRemoveVehicle = (vehicle) => {
    const { paperworkId } = this.state;

    console.log("remove vehicle");
    this.props.dispatch(
      paperworkActions.removeVehicleFromPaperwork(
        paperworkId,
        vehicle.vehicleid
      )
    );
  };
}

function PaperworkBatchDetails(props) {
  const dispatch = useDispatch();
  const [item, setItem] = useState({});
  const [loading, setLoading] = useState(true);
  // const [clientList, setClientList] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalPrintOpen, setModalPrintOpen] = useState(false);

  const [modalMileageOpen, setModalMileageOpen] = useState(false);
  const [modalMileageItem, setModalMileageItem] = useState(null);

  const [personSigning, setPersonSigning] = useState(null);

  const [columns] = useState([
    {
      width: 2,
      title: "VIN",
      field: "vehiclevin",
      dataRender: (item) => (
        <RouterLink to={`/vehicles/${item.vehicleid}`}>
          {item.vehiclevin}
          <br />
          {item.bay_vehicle ? "(has bay)" : "(bay unknown)"}
        </RouterLink>
      ),
    },
    {
      width: 2,
      title: "Client",
      field: "client.clientname",
    },
    {
      width: 1,
      title: "Mileage",
      field: "mileage",
      dataRender: (item) => (
        <Link
          onClick={() => {
            setModalMileageItem(item);
            setModalMileageOpen(true);
          }}
        >
          {item.mileage}
        </Link>
      ),
    },
    {
      width: 1,
      title: "Sent to BMV",
      field: "titlesenttobmvdate",
      dataRender: (item) =>
        moment(item.titlesenttobmvdate).format("MM/DD/YYYY"),
    },
    {
      width: 2,
      title: "Remove",
      field: null,
      dataRender: (item) => (
        <Link
          onClick={(event) => {
            event.preventDefault();
            props.onRemoveVehicle(item);
          }}
        >
          Remove
        </Link>
      ),
    },
    {
      width: 4,
      title: "Bay Document Status",
      field: null,
      dataRender: (item) => (
        <BayDocumentStatus docList={item.bay_document_status} />
      ),
    },
  ]);

  useEffect(() => {
    setItem(props.item);
    if (props.item) {
      setPersonSigning(props.item.person_signing);
    }
    setLoading(props.loading);
    // setClientList(props.clientList);
  }, [props.item, props.loading, props.clientList]);

  const handleOnClose = () => {
    setModalOpen(false);
  };

  const save = () => {
    dispatch(
      paperworkActions.updatePaperwork(item.id, {
        person_signing: personSigning,
      })
    );
  };

  const [updateLog, setUpdateLog] = useState([]);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [titleSentToBMVDate, setTitleSentToBMVDate] = useState(null);

  const massUpdate = async (type, data = null) => {
    setUpdateLog([]);
    let field = null;
    let value = null;
    let updateType = "field";
    if (type === "sentToState") {
      field = "TitleSentToBMVDate";
      value = titleSentToBMVDate;
    }

    setOpenBackdrop(true);
    for (const [index, v] of item.vehicles_paperwork.entries()) {
      if (updateType === "field") {
        await utilityService
          .updateUtility("vehicles", v.vehicleid, {
            [field]: value,
          })
          .then((response) => {
            // console.log(response);
            const text = `${v.vehiclevin}: updated`;
            setUpdateLog((updateLog) => [...updateLog, text]);
          })
          .catch((error) => {
            const errorText = `${v.vehiclevin}: ${error}`;
            setUpdateLog((updateLog) => [...updateLog, errorText]);
          });
      }
    }
    await props.onRefresh();
    setOpenBackdrop(false);
  };

  return (
    <>
      {loading ? (
        <CircularProgress />
      ) : (
        <>
          <Backdrop
            open={openBackdrop}
            style={{ zIndex: 1000, color: "white" }}
          >
            <CircularProgress />
            <br />
            Mass Update Status:
            <br />
            {updateLog.map((log, index) => {
              return (
                <React.Fragment key={index}>
                  {log}
                  <br />
                </React.Fragment>
              );
            })}
          </Backdrop>
          <br />
          <Typography variant="h6">Vehicles in Batch</Typography>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              size="small"
              style={{ width: 200 }}
              onClick={save}
            >
              Save Details
            </Button>{" "}
            <Button
              variant="contained"
              color="primary"
              size="small"
              style={{ width: 200 }}
              onClick={() => setModalOpen(true)}
            >
              Add Vehicle
            </Button>{" "}
            <Button
              variant="contained"
              color="primary"
              size="small"
              style={{ width: 200 }}
              onClick={() => setModalPrintOpen(true)}
              // target="_blank"
              // href={`${getEnvironmentURL()}/api/paperwork_batches/${
              //   item.id
              // }/forms`}
            >
              Print Batch
            </Button>
          </Grid>
          <Grid item style={{ marginTop: 10 }}>
            <TextField
              fullWidth
              id="person_signing"
              label="Person Signing Police Inspection"
              value={personSigning || ""}
              onChange={(e) => setPersonSigning(e.target.value.toUpperCase())}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <KeyboardDatePicker
                clearable
                style={{ fontSize: 12 }}
                // variant="inline"
                name={"TitleSentToBMVDate"}
                label={"Sent to BMV Date"}
                format="MM/DD/YYYY"
                autoOk={true}
                value={titleSentToBMVDate || null}
                InputProps={{
                  style: { fontSize: 12 },
                }}
                InputLabelProps={{ style: { fontSize: 12 } }}
                onChange={(date) =>
                  setTitleSentToBMVDate(date ? date.format("MM/DD/YYYY") : null)
                }
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={12} sm={9}>
            <Button
              size="small"
              color="primary"
              variant="contained"
              onClick={() => massUpdate("sentToState")}
            >
              Update all Sent to BMV
            </Button>
          </Grid>

          <br />
          <ResponsiveGrid
            columns={columns}
            data={item.vehicles_paperwork}
            usePagination={false}
          />
          <ModalPaperworkAddVehicle
            item={item}
            open={modalOpen}
            onClose={handleOnClose}
          />
          <ModalUpdateMileage
            item={modalMileageItem}
            open={modalMileageOpen}
            onClose={() => {
              setModalMileageOpen(false);
              setModalMileageItem(null);
              props.onRefresh();
            }}
          />
          <ModalPrintPaperwork
            url={`${getEnvironmentURL()}/api/paperwork_batches/${
              item.id
            }/forms`}
            vehicleIds={
              item && item.vehicles_paperwork
                ? item.vehicles_paperwork.map((v) => v.vehicleid)
                : []
            }
            open={modalPrintOpen}
            onClose={() => setModalPrintOpen(false)}
          />
        </>
      )}
    </>
  );
}

function BayDocumentStatus(props) {
  const docList = props.docList;
  const [missing, setMissing] = useState(null);
  const [exists, setExists] = useState(null);
  const [showDetails, setShowDetails] = useState(false);

  // useEffect(() => {
  //   if (props.docList) setDocList(props.docList);
  // }, [props]);

  useEffect(() => {
    let isMissing = 0;
    let isThere = 0;
    docList.map((doc) => {
      if (doc.status === true) isThere++;
      else isMissing++;
      return null;
    });
    setMissing(isMissing);
    setExists(isThere);
  }, [docList]);

  const toggleDetails = () => {
    setShowDetails(!showDetails);
  };

  return (
    <>
      {Array.isArray(docList) ? (
        <>
          <Typography style={{ color: "red", display: "inline" }}>
            {missing} missing
          </Typography>{" "}
          <Typography style={{ color: "blue", display: "inline" }}>
            {exists} existing
          </Typography>{" "}
          <Link onClick={toggleDetails}>Details</Link>
          {showDetails &&
            docList.map((doc, index) => {
              let lineColor = "grey";
              if (doc.status === true) lineColor = "blue";
              if (doc.status === false) lineColor = "red";
              return (
                <Typography key={index} style={{ color: lineColor }}>
                  {doc.name}
                </Typography>
              );
            })}
        </>
      ) : (
        <Typography style={{ color: "red" }}>No status available</Typography>
      )}
    </>
  );
}

const styles = (theme) => ({
  root: {
    display: "flex",
    fontSize: 12,
  },
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    width: "100%",
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    width: "100%",
  },
  fixedHeight: {
    height: 240,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },

  Button: {
    display: "block",
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
});

function mapStateToProps(state) {
  const { authentication, utility, batch, paperwork } = state;
  const { user } = authentication;
  return {
    user,
    batch,
    paperwork,
    utility,
  };
}

const connectedPaperworkBatchDetailsPage = connect(mapStateToProps)(
  withWidth()(withStyles(styles)(PaperworkBatchDetailsPage))
);
export { connectedPaperworkBatchDetailsPage as PaperworkBatchDetailsPage };
