import { batchConstants } from "../_constants";

const initialState = {
  batch: {
    loading: false,
    data: {}
  }
};

export function batch(state = initialState, action) {
  switch (action.type) {
    case batchConstants.BATCH_GET_REQUEST:
      return {
        ...state,
        batch: {
          ...state.batch,
          loading: true
        }
      };
    case batchConstants.BATCH_GET_SUCCESS:
      return {
        ...state,
        batch: {
          ...state.batch,
          loading: false,
          data: action.batch
        }
      };
    case batchConstants.BATCH_GET_FAILURE:
      return {
        ...state,
        batch: {
          ...state.batch,
          loading: false
        }
      };

    case batchConstants.BATCH_UPDATE_REQUEST:
      return {
        ...state,
        batch: {
          ...state.batch
          // loading: true
        }
      };
    case batchConstants.BATCH_UPDATE_SUCCESS:
      return {
        ...state,
        batch: {
          ...state.batch,
          // loading: false,
          data: action.batch
        }
      };
    case batchConstants.BATCH_UPDATE_FAILURE:
      return {
        ...state,
        batch: {
          ...state.batch
          // loading: false
        }
      };

    case batchConstants.BATCH_ADD_REQUEST:
      return {
        ...state,
        batch: {
          ...state.batch,
          loading: true
        }
      };
    case batchConstants.BATCH_ADD_SUCCESS:
      return {
        ...state,
        batch: {
          ...state.batch,
          loading: false,
          data: action.batch
        }
      };
    case batchConstants.BATCH_ADD_FAILURE:
      return {
        ...state,
        batch: {
          ...state.batch,
          loading: false
        }
      };

    case batchConstants.BATCH_SET_LOCAL:
      return {
        ...state,
        batch: {
          ...state.batch,
          data: {
            ...state.batch.data,
            ...action.data
          }
        }
      };
    case batchConstants.BATCH_CLEAR:
      return {
        ...state,
        batch: {
          ...state.batch,
          data: {}
        }
      };

    default:
      return state;
  }
}
