import React from "react";
import { connect } from "react-redux";
import { withStyles, withTheme } from "@material-ui/core/styles";
import withWidth from "@material-ui/core/withWidth";

import { PageWithAppDrawer2 } from "../_components/main/PageWithAppDrawer2";
import TitleServiceDialog from "../_components/homepage/TitleServicesDialog";
import RegisteredImportationDialog from "../_components/homepage/RegisteredImportationDialog";

import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";

class HelpPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      titleServiceOpen: false,
      registeredImportOpen: false,
    };
  }

  componentDidMount() {}

  render() {
    const { classes } = this.props;
    const { titleServiceOpen, registeredImportOpen } = this.state;
    const mobile = ["xs"].includes(this.props.width);
    const titleVariant = mobile ? "h6" : "h3";

    return (
      <PageWithAppDrawer2
        pageTitle={"Home"}
        showSideBar={true}
        showHomeLinks={true}
      >
        <Container maxWidth="lg" className={classes.container}>
          <Grid container className={classes.titleGrid}>
            <Grid item xs={12}>
              <Paper className={classes.paper}>
                <Typography
                  variant={titleVariant}
                  color="primary"
                  align="center"
                  gutterBottom
                >
                  Need Help?
                </Typography>
                <Typography variant="subtitle1" style={{ textAlign: "center" }}>
                  For immediate help call our office:
                  <br />
                  <a href="tel://18773874639">1-877-387-4639</a>
                  <br />
                  <br />
                  For all other help, or to send us information, email us:
                  <br />
                  <a href="mailto:dvsri@godvs.com">DVSRI@godvs.com</a>
                </Typography>
              </Paper>
            </Grid>
          </Grid>
        </Container>
        <TitleServiceDialog
          open={titleServiceOpen}
          onClose={() => {
            this.setState({ titleServiceOpen: false });
          }}
        />
        <RegisteredImportationDialog
          open={registeredImportOpen}
          onClose={() => {
            this.setState({ registeredImportOpen: false });
          }}
        />
      </PageWithAppDrawer2>
    );
  }
}

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
  },
  titleGrid: {
    paddingBottom: theme.spacing(2),
  },
});

function mapStateToProps(state) {
  const { users, authentication, dms } = state;
  const { user } = authentication;
  return {
    user,
    users,
    dms,
  };
}

const connectedHelpPage = connect(mapStateToProps)(
  withWidth()(withTheme(withStyles(styles)(HelpPage)))
);
export { connectedHelpPage as HelpPage };
