import React, { useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
// import Link from "@material-ui/core/Link";
import { connect, useSelector, useDispatch } from "react-redux";
import { withStyles } from "@material-ui/core/styles";

import { utilityActions } from "../_actions";

import { PageWithAppDrawer2 } from "../_components/main/PageWithAppDrawer2";
import ResponsiveGrid from "../_components/generic/ResponsiveGrid";

import FiltersGrid from "../_components/generic/FiltersGrid";
import { Paper, Button, Typography } from "@material-ui/core";
import { utilityService } from "../_services";
import { hashHistory } from "../_helpers";

class AdminTitleApplicationPage2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    // this.props.dispatch(
    //   utilityActions.getUtility("clients", {
    //     perPage: 1000,
    //     filters: [
    //       {
    //         field: "clientactive",
    //         operator: "eq",
    //         value: "2"
    //       }
    //     ]
    //   })
    // );
  }

  render() {
    const { classes } = this.props;

    return (
      <PageWithAppDrawer2 pageTitle={"Title Application Types"}>
        <Paper className={classes.paper}>
          <Button
            color="primary"
            variant="contained"
            onClick={this.handleAddItem}
          >
            Add Title Application Type
          </Button>
          <TitleAppTypesTable />
        </Paper>
      </PageWithAppDrawer2>
    );
  }

  handleAddItem = () => {
    utilityService
      .addUtility("title_application_types", {
        titleapplicationtype: "NEW TITLE APPLICATION TYPE",
        titleapplicationcost: 0,
        stateid: 1,
      })
      .then((response) => {
        hashHistory.push(`/admin/titleApplicationTypes/${response.id}`);
      });
  };
}

function TitleAppTypesTable(props) {
  const dispatch = useDispatch();
  const items = useSelector(
    (state) => state.utility.title_application_types_list
  );
  const columns = [
    {
      width: 6,
      title: "Name",
      field: "titleapplicationtype",
      dataRender: (item) => (
        <>
          <RouterLink to={`/admin/titleApplicationTypes/${item.id}`}>
            {item.titleapplicationtype}
          </RouterLink>
          <br />
          <TitleApplicationTypeDetails item={item} />
        </>
      ),
    },
    {
      width: 1,
      title: "State",
      field: "state.statefull",
    },
    {
      width: 1,
      title: "Conforming Required",
      field: "has_conforming_requirement",
      dataRender: (item) => (item.has_conforming_requirement ? "Yes" : "No"),
    },
    {
      width: 1,
      title: "Cost",
      field: "titleapplicationcost",
    },
    {
      width: 1,
      title: "Convenience Fee",
      field: "titleapplicationconvenience",
    },
    {
      width: 1,
      title: "ST108 Exemption #",
      field: "exemption_number",
    },
    {
      width: 1,
      title: "Late Days",
      field: "late_fee_in_days",
    },
  ];

  const [availableFilters, setAvailableFilters] = React.useState([
    {
      name: "Name",
      field: "titleapplicationtype",
      operator: "like",
      value: "",
      type: "text",
      default: "",
    },
  ]);

  const [getData, setGetData] = React.useState(false);

  const [page, setPage] = React.useState(
    items.page ? Number(items.page) - 1 : 0
  );
  const [perPage, setPerPage] = React.useState(
    items.per_page ? Number(items.per_page) : 25
  );
  const [total, setTotal] = React.useState(
    items.total ? Number(items.total) : 0
  );

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
    setGetData(true);
  };
  const handleChangeRowsPerPage = (event) => {
    const value = parseInt(event.target.value);
    setPerPage(value);
    setGetData(true);
  };
  React.useEffect(() => {
    if (getData === true) {
      setGetData(false);
      getNewData();
    }
  }, [getData]);

  React.useEffect(() => {
    setTotal(items.total ? Number(items.total) : 0);
    setPage(items.page ? Number(items.page) : 0);
    setPerPage(items.per_page ? Number(items.per_page) : 25);
  }, [items]);

  React.useEffect(() => {
    if (items.items.length === 0) {
      getNewData();
    }
  }, []);

  // const handleDownload = () => {};

  const getNewData = () => {
    console.log("getNewData");
    let newFilters = availableFilters
      .filter((filter) => {
        if (["", null].includes(filter.value)) return false;
        if (
          ["isnull", "notnull"].includes(filter.operator) &&
          filter.value === false
        )
          return false;
        return true;
      })
      .map((filter) => {
        return {
          field: filter.field,
          operator: filter.operator,
          value: filter.value,
        };
      });
    dispatch(
      utilityActions.getUtility("title_application_types_list", {
        page,
        perPage,
        relations: ["state", "costs", "rack_rates"],
        orders: [
          {
            field: "titleapplicationtype",
            direction: "asc",
          },
        ],
        filters: newFilters,
      })
    );
  };

  const handleFilterChange = (newFilter) => {
    const newFilters = availableFilters.map((filter) => {
      if (filter.field !== newFilter.field) return filter; // not the droid we're looking for, return unchanged
      filter.value = newFilter.value;
      return filter;
    });
    setAvailableFilters(newFilters);
    setPage(1);
    setGetData(true);
  };

  return (
    <>
      <FiltersGrid
        availableFilters={availableFilters}
        onChange={handleFilterChange}
      />
      <ResponsiveGrid
        loading={items.loading}
        columns={columns}
        data={items.items}
        page={page - 1} // API has a 1 based page count, Material UI has a zero based page count
        perPage={perPage}
        total={total}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        // showActions
        // onDownload={handleDownload}
      />
    </>
  );
}

function TitleApplicationTypeDetails(props) {
  const item = props.item;
  const [total, setTotal] = useState(0);

  useEffect(() => {
    let tmpTotal = 0;
    item.rack_rates.map((rackRate) => {
      tmpTotal += Number(rackRate.charge_amount);
      return null;
    });
    item.costs.map((cost) => {
      tmpTotal -= Number(cost.cost);
      return null;
    });
    setTotal(tmpTotal);
  }, []);

  // return <>rack rates</>;

  return (
    <>
      <Typography>Total: ${total}</Typography>
      {item.rack_rates.map((rackRate, index) => {
        return (
          <Typography key={index} variant="body2" style={{ color: "blue" }}>
            + ${rackRate.charge_amount} {rackRate.name}
          </Typography>
        );
      })}
      {item.costs.map((cost, index) => {
        return (
          <Typography key={index} variant="body2" style={{ color: "red" }}>
            - ${cost.cost} {cost.name}
          </Typography>
        );
      })}
    </>
  );
}

const styles = (theme) => ({
  root: {
    display: "flex",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
});

function mapStateToProps(state) {
  const { authentication, utility } = state;
  const { user } = authentication;
  const { us_states } = utility;
  return {
    user,
    us_states,
  };
}

const connectedAdminTitleApplicationPage2 = connect(mapStateToProps)(
  withStyles(styles)(AdminTitleApplicationPage2)
);
export { connectedAdminTitleApplicationPage2 as AdminTitleApplicationPage2 };
