import React, { useState, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import { connect, useSelector, useDispatch } from "react-redux";
import { withStyles } from "@material-ui/core/styles";

import { utilityActions } from "../_actions";
import { utilityService } from "../_services";

import { PageWithAppDrawer2 } from "../_components/main/PageWithAppDrawer2";

import FiltersGrid from "../_components/generic/FiltersGrid";
import {
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  Paper,
  TextField,
} from "@material-ui/core";

import LaunchTwoToneIcon from "@material-ui/icons/LaunchTwoTone";

class TransportListPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { classes } = this.props;

    return (
      <PageWithAppDrawer2 pageTitle={"Transportation POC"}>
        <Paper className={classes.paper}>
          <TransportTablePOC />
        </Paper>
      </PageWithAppDrawer2>
    );
  }
}

function TransportTablePOC(props) {
  const dispatch = useDispatch();
  const items = useSelector((state) => state.utility.vehicles_transport_poc);
  // const columns = [
  //   {
  //     width: 1,
  //     title: "Client Name",
  //     field: "client.clientname",
  //   },
  //   {
  //     width: 2,
  //     title: "VIN",
  //     field: "vehiclevin",
  //     dataRender: (item) => (
  //       <RouterLink to={`/vehicles/${item.vehicleid}`} target="_blank">
  //         {item.vehiclevin}
  //         <LaunchTwoToneIcon style={{ fontSize: 12 }} color="primary" />
  //       </RouterLink>
  //     ),
  //   },
  //   {
  //     width: 1,
  //     title: "Year/Make",
  //     field: "",
  //     dataRender: (item) => `${item.yr} ${item.make}`,
  //   },
  //   {
  //     width: 1,
  //     title: "Status",
  //     field: "title_status.statusname",
  //   },
  // ];

  const [availableFilters, setAvailableFilters] = useState([
    {
      name: "Has Transport POC",
      field: "transport_test",
      operator: "eq",
      value: "1",
      type: "hidden",
      default: "",
    },
    {
      name: "Created",
      field: "recordcreateddate",
      operator: ">=",
      value: "2017-01-01 00:00:00",
      type: "hidden",
      default: "",
    },
    {
      name: "Client Name",
      field: "client.clientname",
      operator: "like",
      value: "",
      type: "text",
      default: "",
    },
  ]);

  const [getData, setGetData] = useState(false);

  // const [page, setPage] = useState(items.page ? Number(items.page) - 1 : 0);
  // const [perPage, setPerPage] = useState(
  //   items.per_page ? Number(items.per_page) : 50
  // );
  // const [total, setTotal] = useState(items.total ? Number(items.total) : 0);

  useEffect(() => {
    if (getData === true) {
      setGetData(false);
      getNewData();
    }
  }, [getData]);

  useEffect(() => {
    // setTotal(items.total ? Number(items.total) : 0);
    // setPage(items.page ? Number(items.page) : 0);
    // setPerPage(items.per_page ? Number(items.per_page) : 10);
  }, [items]);

  useEffect(() => {
    if (items.items.length === 0) {
      getNewData();
    }
  }, []);

  const getNewData = async (download = false) => {
    console.log("getNewData");
    let newFilters = availableFilters
      .filter((filter) => {
        if (["", null].includes(filter.value)) return false;
        if (
          ["isnull", "notnull"].includes(filter.operator) &&
          filter.value === false
        )
          return false;
        return true;
      })
      .map((filter) => {
        return {
          field: filter.field,
          operator: filter.operator,
          value: filter.value,
        };
      });

    if (download) {
      //   await utilityService
      //     .getUtility("vehicles_transport_poc", {
      //       page: 1,
      //       perPage: 10000,
      //       filters: newFilters,
      //       orders: [{ field: "recordcreateddate", direction: "asc" }],
      //     })
      //     .then((values) => {
      //       return generateCSV(
      //         [
      //           "Client Name",
      //           "App Type",
      //           "Created",
      //           "Date to BMV",
      //           "Auction",
      //           "Date to Auction",
      //           "VIN",
      //           "Year",
      //           "Make",
      //           "Status",
      //           "Charges",
      //         ],
      //         [
      //           "client.clientname",
      //           "application_type.titleapplicationtype",
      //           "recordcreateddate",
      //           "titlesenttobmvdate",
      //           "auction.auctionname",
      //           "titlesenttoauctiondate",
      //           "vehiclevin",
      //           "yr",
      //           "make",
      //           "title_status.statusname",
      //           "fees_total",
      //         ],
      //         values.data
      //       );
      //     })
      //     .then((csv) => {
      //       const blob = new Blob([csv], { type: "text/csv" });
      //       downloadBlob(blob, "unbilled_units.csv");
      //     })
      //     .catch(console.error);
      return;
    }

    dispatch(
      utilityActions.getUtility("vehicles_transport_poc", {
        page: 1,
        perPage: 1000,
        relations: ["vehicle_transport"],
        filters: newFilters,
        orders: [{ field: "recordcreateddate", direction: "asc" }],
      })
    );
  };

  const handleFilterChange = (newFilter) => {
    const newFilters = availableFilters.map((filter) => {
      if (filter.field !== newFilter.field) return filter; // not the droid we're looking for, return unchanged
      filter.value = newFilter.value;
      return filter;
    });
    setAvailableFilters(newFilters);
    // setPage(1);
    setGetData(true);
  };

  return (
    <>
      <div>
        <Button
          size="small"
          variant="contained"
          color="primary"
          onClick={() => setGetData(true)}
        >
          Refresh
        </Button>
      </div>
      <FiltersGrid
        availableFilters={availableFilters}
        onChange={handleFilterChange}
      />
      {/* <div>
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={handleDownload}
        >
          Download All
        </Button>
      </div> */}
      {/* <ResponsiveGrid
        loading={items.loading}
        columns={columns}
        data={items.items}
        page={page - 1} // API has a 1 based page count, Material UI has a zero based page count
        perPage={perPage}
        total={total}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        // showActions
        // onDownload={handleDownload}
      /> */}
      <Grid container style={{ marginTop: 20 }} spacing={3}>
        {items.items &&
          items.items.map((item, index) => {
            return (
              <TransportListItem
                item={item}
                key={index}
                onUpdate={getNewData}
              />
            );
          })}
      </Grid>
    </>
  );
}

function TransportListItem(props) {
  const [dirty, setDirty] = useState(false);
  const [item, setItem] = useState(props.item);
  const [transporter, setTransporter] = useState("");
  const [driver, setDriver] = useState("");
  const [notes, setNotes] = useState("");
  const [locationPickup, setLocationPickup] = useState("");
  const [locationDropoff, setLocationDropoff] = useState("");
  const [isReadyPickup, setIsReadyPickup] = useState(0);
  const [HasVehicleArrived, setHasVehicleArrived] = useState(0);
  const [hasRecall, setHasRecall] = useState(0);
  const [isRecallComplete, setIsRecallComplete] = useState(0);

  useEffect(() => {
    if (props.item) {
      console.log("props.item", props.item);
      setItem(props.item);
      setTransporter(props.item.vehicle_transport.transporter);
      setDriver(props.item.vehicle_transport.driver);
      setNotes(props.item.vehicle_transport.notes);
      setLocationPickup(props.item.vehicle_transport.location_pickup);
      setLocationDropoff(props.item.vehicle_transport.location_dropoff);
      setIsReadyPickup(props.item.vehicle_transport.is_ready_pickup);
      setHasVehicleArrived(props.item.vehicle_transport.has_vehicle_arrived);
      setHasRecall(props.item.vehicle_transport.has_recall);
      setIsRecallComplete(props.item.vehicle_transport.is_recall_completed);
    }
  }, [props.item]);

  const save = () => {
    utilityService
      .updateUtility("vehicle_transport", item.vehicle_transport.id, {
        transporter,
        driver,
        notes,
        location_pickup: locationPickup,
        location_dropoff: locationDropoff,
        is_ready_pickup: isReadyPickup,
        has_vehicle_arrived: HasVehicleArrived,
        has_recall: hasRecall,
        is_recall_completed: isRecallComplete,
      })
      .then((response) => {
        props.onUpdate();
        setDirty(false);
      });
  };

  return (
    <Grid
      item
      container
      spacing={1}
      style={dirty ? { backgroundColor: "yellow" } : null}
    >
      <Grid item xs={3}>
        <RouterLink to={`/vehicles/${item.vehicleid}`} target="_blank">
          {item.vehiclevin}
          <LaunchTwoToneIcon style={{ fontSize: 12 }} color="primary" />
        </RouterLink>
        <br />
        {item.yr} {item.make}
        <br />
        {item.client.clientname}
        <br />
        <Button size="small" variant="contained" color="primary" onClick={save}>
          Save
        </Button>
      </Grid>
      <Grid item xs={3}>
        <b>Transporter</b>
        <br />
        <TextField
          multiline
          fullWidth
          value={transporter || ""}
          onChange={(e) => {
            setTransporter(e.target.value);
            setDirty(true);
          }}
        />
      </Grid>
      <Grid item xs={3}>
        <b>Driver</b>
        <br />
        <TextField
          multiline
          fullWidth
          value={driver || ""}
          onChange={(e) => {
            setDriver(e.target.value);
            setDirty(true);
          }}
        />
      </Grid>
      <Grid item xs={3}>
        <b>Notes</b>
        <br />
        <TextField
          multiline
          fullWidth
          value={notes || ""}
          onChange={(e) => {
            setNotes(e.target.value);
            setDirty(true);
          }}
        />
      </Grid>
      <Grid item xs={3} />
      <Grid item xs={3}>
        <b>Pickup Location</b>
        <br />
        <TextField
          multiline
          fullWidth
          value={locationPickup || ""}
          onChange={(e) => {
            setLocationPickup(e.target.value);
            setDirty(true);
          }}
        />
      </Grid>
      <Grid item xs={3}>
        <b>Dropoff Location</b>
        <br />
        <TextField
          multiline
          fullWidth
          value={locationDropoff || ""}
          onChange={(e) => {
            setLocationDropoff(e.target.value);
            setDirty(true);
          }}
        />
      </Grid>
      <Grid item xs={3}>
        <FormGroup column>
          <FormControlLabel
            control={
              <Checkbox
                checked={isReadyPickup}
                onChange={(e) => {
                  setIsReadyPickup(e.target.checked);
                  setDirty(true);
                }}
              />
            }
            label="Ready for Pickup?"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={HasVehicleArrived}
                onChange={(e) => {
                  setHasVehicleArrived(e.target.checked);
                  setDirty(true);
                }}
              />
            }
            label="Vehicle Arrived?"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={hasRecall}
                onChange={(e) => {
                  setHasRecall(e.target.checked);
                  setDirty(true);
                }}
              />
            }
            label="Has Recall?"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={isRecallComplete}
                onChange={(e) => {
                  setIsRecallComplete(e.target.checked);
                  setDirty(true);
                }}
              />
            }
            label="Recall Complete?"
          />
        </FormGroup>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
    </Grid>
  );
}

const styles = (theme) => ({
  root: {
    display: "flex",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
});

function mapStateToProps(state) {
  const { authentication, utility } = state;
  const { user } = authentication;
  const { us_states } = utility;
  return {
    user,
    us_states,
  };
}

const connectedTransportListPage = connect(mapStateToProps)(
  withStyles(styles)(TransportListPage)
);
export { connectedTransportListPage as TransportListPage };
