import { authHeader, getEnvironmentURL, removeDiskCache } from "../_helpers";

export const userService = {
  login,
  logout,
  checkEmailAvailable,
  passwordReset,
  forgotPassword,
  resetTokenData,
  resetTokenUpdate,
  register,
  getAll,
  getById,
  update,
  delete: _delete,
};

function login(username, password, options = {}) {
  const { customerName } = options;
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ username, password }),
  };

  const url = getEnvironmentURL();
  return fetch(`${url}/api/authenticate/${customerName}`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      // console.log("response", response);
      let user = response.user;
      user.token = response.access_token;
      localStorage.setItem("user", JSON.stringify(response.user));
      localStorage.setItem("webUser", JSON.stringify(response.webUser));

      return response;
    });
}

function logout() {
  // remove user from local storage to log user out
  localStorage.removeItem("user");
  localStorage.removeItem("webUser");
  removeDiskCache("vehicles-filters");
}

function checkEmailAvailable(email) {
  const data = {
    email,
  };
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };

  const url = getEnvironmentURL();
  return fetch(`${url}/api/checkEmailAvailable`, requestOptions).then(
    handleResponse
  );
}

function forgotPassword(email) {
  const data = {
    email,
  };
  const requestOptions = {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };

  const url = getEnvironmentURL();
  return fetch(`${url}/api/forgotPassword`, requestOptions).then(
    handleResponse
  );
}

function passwordReset(id, email) {
  const data = {
    email,
  };
  const requestOptions = {
    method: "PUT",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };

  const url = getEnvironmentURL();
  return fetch(`${url}/api/web_users/${id}/passwordReset`, requestOptions).then(
    handleResponse
  );
}

function resetTokenData(token) {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  };

  const url = getEnvironmentURL();
  return fetch(
    `${url}/api/web_users/resetTokenData/${token}`,
    requestOptions
  ).then(handleResponse);
}

function resetTokenUpdate(token, password) {
  const data = {
    password,
  };
  const requestOptions = {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };

  const url = getEnvironmentURL();
  return fetch(
    `${url}/api/web_users/resetTokenUpdate/${token}`,
    requestOptions
  ).then(handleResponse);
}

function getAll() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  const url = getEnvironmentURL();
  return fetch(`${url}/api/users`, requestOptions).then(handleResponse);
}

function getById(id) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  const url = getEnvironmentURL();
  fetch(`${url}/api/users/${id}`, requestOptions).then(handleResponse);
}

function register(registrationData) {
  const data = {
    ...registrationData,
  };
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };

  const url = getEnvironmentURL();
  return fetch(`${url}/api/register`, requestOptions).then(handleResponse);
}

function update(user) {
  // const requestOptions = {
  //     method: 'PUT',
  //     headers: { ...authHeader(), 'Content-Type': 'application/json' },
  //     body: JSON.stringify(user)
  // };
  // return fetch(`https://godvs.greers.org/users/${user.id}`, requestOptions).then(handleResponse);;
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
  // const requestOptions = {
  //     method: 'DELETE',
  //     headers: authHeader()
  // };
  // return fetch(`https://godvs.greers.org/users/${id}`, requestOptions).then(handleResponse);
}

function handleResponse(response) {
  // console.log("handleResponse");
  return response.text().then((text) => {
    if (text.includes("Unauthorized")) {
      // console.log("text unauthorized");
      return Promise.reject("Invalid Credentials");
    }
    const data = text && JSON.parse(text);
    if (!response.ok) {
      // console.log("not okay", response.status);
      if (response.status === 401) {
        // logout();
        // location.reload(true);
        return Promise.reject("Not Authorized");
      }
      // console.log("response not okay");

      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }
    // console.log("response okay", response.status);

    return data.response ? data.response : data;
  });
}
