import React from "react";
import { Route, Redirect } from "react-router-dom";

export const AdminRoute = ({ component: Component, ...rest }) => {
  const webUser = JSON.parse(localStorage.getItem("webUser"));
  // console.log("webUser", webUser);
  if (webUser === null) {
    return (
      <Route
        {...rest}
        render={(props) => (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        )}
      />
    );
  }
  if (webUser && webUser.roleid && webUser.roleid !== 2) {
    return (
      <Route
        {...rest}
        render={(props) => (
          <Redirect
            to={{ pathname: "/access/admin", state: { from: props.location } }}
          />
        )}
      />
    );
  }
  return (
    <Route
      {...rest}
      render={(props) =>
        localStorage.getItem("user") ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        )
      }
    />
  );
};
