import { utilityConstants } from "../_constants";

const initialState = {
  auctions: {
    loading: false,
    items: [],
  },
  clients: {
    loading: false,
    items: [],
  },
  clients_active: {
    loading: false,
    items: [],
  },
  clients_active_ri: {
    loading: false,
    items: [],
  },
  clients_active_gst: {
    loading: false,
    items: [],
  },
  client_groups: {
    loading: false,
    items: [],
  },
  client_agents: {
    loading: false,
    items: [],
  },
  client_defaults: {
    loading: false,
    items: [],
  },
  client_loads: {
    loading: false,
    items: [],
  },
  client_load_vehicles: {
    loading: false,
    items: [],
  },
  client_locations: {
    loading: false,
    items: [],
  },
  client_soc_locations: {
    loading: false,
    items: [],
  },
  client_notes: {
    loading: false,
    items: [],
  },
  client_transporters: {
    loading: false,
    items: [],
  },
  client_drivers: {
    loading: false,
    items: [],
  },
  client_drivers_full: {
    loading: false,
    items: [],
  },
  client_open_vehicles: {
    loading: false,
    items: [],
  },
  client_types: {
    loading: false,
    items: [],
  },
  vehicle_gst: {
    loading: false,
    items: [],
  },
  file_links: {
    loading: false,
    items: [],
  },
  history_items: {
    loading: false,
    items: [],
  },
  history_items_clientload: {
    loading: false,
    items: [],
  },
  invoice_batches: {
    loading: false,
    items: [],
  },
  invoices: {
    loading: false,
    items: [],
  },
  invoice_summary: {
    loading: false,
    items: [],
  },
  invoice_summary_monthly: {
    loading: false,
    items: [],
  },
  nhtsa_conformity_items: {
    loading: false,
    items: [],
  },
  one_off_invoices: {
    loading: false,
    items: [],
  },
  paperwork_batches: {
    loading: false,
    items: [],
  },
  paperwork_vehicles: {
    loading: false,
    items: [],
  },
  payment_types: {
    loading: false,
    items: [],
  },
  pdf_forms: {
    loading: false,
    items: [],
  },
  pdf_form_fields: {
    loading: false,
    items: [],
  },
  rack_rates: {
    loading: false,
    items: [],
  },
  rack_rates_all: {
    loading: false,
    items: [],
  },
  rack_rate_overrides: {
    loading: false,
    items: [],
  },
  reports_title_activity: {
    loading: false,
    items: [],
  },
  scanned_vehicles: {
    loading: false,
    items: [],
  },
  svetna_dealers: {
    loading: false,
    items: [],
  },
  svetna_select_buyers: {
    loading: false,
    items: [],
  },
  svetna_select_dealers: {
    loading: false,
    items: [],
  },
  svetna_scanned_vehicles: {
    loading: false,
    items: [],
  },
  svetna_scanned_preimport: {
    loading: false,
    items: [],
  },
  svetna_preimport_vehicles: {
    loading: false,
    items: [],
  },
  svetna_report_vehicles: {
    loading: false,
    items: [],
  },
  title_statuses: {
    loading: false,
    items: [],
  },
  title_status_additional: {
    loading: false,
    items: [],
  },
  title_statuses_ri_problems: {
    loading: false,
    items: [],
  },
  title_application_types: {
    loading: false,
    items: [],
  },
  title_application_types_list: {
    loading: false,
    items: [],
  },
  title_rack_rates: {
    loading: false,
    items: [],
  },
  titles_sent: {
    loading: false,
    items: [],
  },
  us_states: {
    loading: false,
    items: [],
  },
  user_roles: {
    loading: false,
    items: [],
  },
  user_to_roles: {
    loading: false,
    items: []
  },
  vehicles: {
    loading: false,
    items: [],
  },
  vehicle_conformance_plus: {
    loading: false,
    items: [],
  },
  vehicle_conformance_plus_item: {
    loading: true,
  },
  vehicle_conformance_items: {
    loading: false,
    items: [],
  },
  vehicles_drill: {
    loading: false,
    items: [],
  },
  vehicles_register: {
    loading: false,
    items: [],
  },
  vehicles_unbilled: {
    loading: false,
    items: [],
  },
  vehicles_pending_titles: {
    loading: false,
    items: [],
  },
  vehicles_problems: {
    loading: false,
    items: [],
  },
  vehicles_customs_submitted: {
    loading: false,
    items: [],
  },
  vehicles_dot_submitted: {
    loading: false,
    items: [],
  },
  vehicles_transport_poc: {
    loading: false,
    items: [],
  },
  vehicle_charge_types: {
    loading: true,
    items: [],
  },
  vehicle_charges: {
    loading: true,
    items: [],
  },
  vehicle_image_types: {
    loading: true,
    items: [],
  },
  vehicle_notes: {
    loading: true,
    items: [],
  },
  vehicle_notes_open: {
    loading: false,
    items: [],
  },
  vehicle_history: {
    loading: false,
    items: [],
  },
  vehicle_statuses: {
    loading: false,
    items: [],
  },
  web_users: {
    loading: false,
    items: [],
  },
  web_user_roles: {
    loading: false,
    items: [],
  },
  users_training: {
    loading: false,
    items: [],
  },
};

export function utility(state = initialState, action) {
  switch (action.type) {
    case utilityConstants.UTILITY_UPDATE_LOCAL:
      return {
        ...state,
        [action.utilityName + "_item"]: {
          ...state[action.utilityName + "_item"],
          ...action.data,
        },
      };

    case utilityConstants.UTILITY_UPDATE_LOCAL_TIRES:
      return {
        ...state,
        [action.utilityName + "_item"]: {
          ...state[action.utilityName + "_item"],
          vehicle_tire_data: {
            ...state[action.utilityName + "_item"].vehicle_tire_data,
            ...action.data
          },
        },
      };

    case utilityConstants.UTILITY_CLEAR_REQUEST:
      return {
        ...state,
        [action.utilityName]: {
          loading: false,
          items: [],
        },
      };

    case utilityConstants.UTILITY_CLEAR_ONE_REQUEST:
      return {
        ...state,
        [action.utilityName + "_item"]: null,
      };

    case utilityConstants.UTILITY_GET_REQUEST:
      return {
        ...state,
        [action.utilityName]: {
          ...state[action.utilityName],
          loading: true,
        },
      };
    case utilityConstants.UTILITY_GET_SUCCESS:
      return {
        ...state,
        [action.utilityName]: {
          ...state[action.utilityName],
          items: action.values.data,
          page: Number(action.values.current_page),
          per_page: Number(action.values.per_page),
          total: Number(action.values.total),
          loading: false,
        },
      };
    case utilityConstants.UTILITY_GET_FAILURE:
      return {
        ...state,
        [action.utilityName]: {
          ...state[action.utilityName],
          loading: false,
        },
      };

    case utilityConstants.UTILITY_GET_ONE_REQUEST:
      return {
        ...state,
        [action.utilityName + "_item"]: {
          ...state[action.utilityName + "_item"],
          loading: true,
        },
      };
    case utilityConstants.UTILITY_GET_ONE_SUCCESS:
      return {
        ...state,
        [action.utilityName + "_item"]: {
          ...state[action.utilityName + "_item"],
          ...action.values,
          loading: false,
        },
      };
    case utilityConstants.UTILITY_GET_ONE_FAILURE:
      return {
        ...state,
        [action.utilityName + "_item"]: {
          ...state[action.utilityName + "_item"],
          loading: false,
        },
      };

    case utilityConstants.UTILITY_DELETE_ONE_REQUEST:
      return {
        ...state,
      };
    case utilityConstants.UTILITY_DELETE_ONE_SUCCESS:
      return {
        ...state,
      };
    case utilityConstants.UTILITY_DELETE_ONE_FAILURE:
      return {
        ...state,
      };

    case utilityConstants.UTILITY_ADD_ONE_REQUEST:
      return {
        ...state,
      };
    case utilityConstants.UTILITY_ADD_ONE_SUCCESS:
      return {
        ...state,
      };
    case utilityConstants.UTILITY_ADD_ONE_FAILURE:
      return {
        ...state,
      };

    case utilityConstants.UTILITY_UPDATE_REQUEST:
      return {
        ...state,
        [action.utilityName]: {
          ...state[action.utilityName],
          // loading: true,
        },
      };
    case utilityConstants.UTILITY_UPDATE_SUCCESS:
      console.log("UTILITY_UPDATE_SUCCESS", action);
      let newObj = {
        [action.utilityName]: {
          ...state[action.utilityName],
          items: state[action.utilityName].items.map((item) =>
            Number(item.id) === Number(action.id) ? action.item : item
          ),
          loading: false,
        },
      };
      if (state[action.utilityName + "_item"]) {
        newObj[action.utilityName + "_item"] =
          Number(state[action.utilityName + "_item"].id) ===
          Number(action.item.id)
            ? action.item
            : state[action.utilityName + "_item"];
      }
      return {
        ...state,
        ...newObj,
      };
      // return {
      //   ...state,
      //   [action.utilityName]: {
      //     ...state[action.utilityName],
      //     items: state[action.utilityName].items.map((item) =>
      //       Number(item.id) === Number(action.id) ? action.item : item
      //     ),
      //     loading: false,
      //   },
      //   [action.utilityName + "_item"]:
      //     Number(state[action.utilityName + "_item"].id) ===
      //     Number(action.item.id)
      //       ? action.item
      //       : state[action.utilityName + "_item"],
      // };
    case utilityConstants.UTILITY_UPDATE_FAILURE:
      return {
        ...state,
        [action.utilityName]: {
          ...state[action.utilityName],
          loading: false,
        },
      };

    default:
      return state;
  }
}
