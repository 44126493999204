import React, { useState, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { utilityActions } from "../../_actions";

import ResponsiveGrid from "../generic/ResponsiveGrid";
import ImagePreview from "../generic/ImagePreview";
import ImagePreviewDetails from "../generic/ImagePreviewDetails";

export default function VehicleDetailImages(props) {
  const dispatch = useDispatch();
  const vehicleImageTypes = useSelector(
    (state) => state.utility.vehicle_image_types
  );

  const [items, setItems] = useState(props.items || []);

  useEffect(() => {
    setItems(props.items);
  }, [props.items]);

  useEffect(() => {
    if (vehicleImageTypes.items.length === 0) {
      dispatch(
        utilityActions.getUtility("vehicle_image_types", {
          perPage: 1000,
          orders: [
            {
              field: "display_order",
              direction: "asc",
            },
          ],
        })
      );
    }
  }, []);

  const updateImageType = (imageId, imageType, imageText) => {
    const newItems = items.map((i) => {
      if (i.id != imageId) return i;
      return {
        ...i,
        imagetype: imageType,
        docnote: imageText,
      };
    });
    setItems((items) => [...newItems]);
    dispatch(
      utilityActions.updateUtility("vehicle_images", imageId, {
        imagetype: imageType,
        docnote: imageText,
      })
    );
  };

  const deleteImage = (imageId) => {
    dispatch(
      utilityActions.deleteOneUtility("vehicle_images", imageId, null, () => {
        props.onSave();
      })
    );
  };

  if (items.length === 0) {
    return <>No Images</>;
  }

  return (
    <ResponsiveGrid
      usePagination={false}
      columns={[
        {
          title: "Preview",
          width: 3,
          field: "imagename",
          dataRender: (item) => ImagePreview(item, 100),
        },
        {
          title: "Name",
          width: 8,
          field: "docnote",
          dataRender: (item) => (
            <ImagePreviewDetails
              item={item}
              onUpdateImageType={updateImageType}
              onDeleteImage={deleteImage}
            />
          ),
        },
        { title: "Type", width: 1, field: "imageextension" },
        // {
        //   title: "Email",
        //   width: 2,
        //   dataRender: item => <RouterLink to="/">Email Image</RouterLink>
        // },
        // {
        //   title: "Delete",
        //   width: 2,
        //   dataRender: item => <RouterLink to="/">Delete Image</RouterLink>
        // }
      ]}
      data={items}
    />
  );
}
