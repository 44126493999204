import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import moment from "moment-timezone";
import queryString from "query-string";

import { vehicleActions, utilityActions } from "../_actions";
import { utilityService } from "../_services";
import { getEnvironmentURL } from "../_helpers";

import {
  hashHistory,
  formatNumberToStringMoney,
  generateCSV,
  downloadBlob,
  setDiskCache,
  getDiskCache,
  removeDiskCache,
} from "../_helpers";

import ResponsiveGrid from "../_components/generic/ResponsiveGrid";
import FiltersGrid from "../_components/generic/FiltersGrid";
import VinLookupDialog from "../_components/vehicle/VinLookupDialog";

import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import PageWithAppDrawer3 from "../_components/main/PageWithAppDrawer3";

class VehiclesPage extends React.Component {
  constructor(props) {
    super(props);
    const { webUser } = this.props;
    const clientGroup = webUser.client_group || null;
    const clientsList = clientGroup
      ? clientGroup.clients_base.map((c) => c.clientid)
      : [webUser.client_id];
    const { vin } = queryString.parse(this.props.location.search);
    const cachedFilters = getDiskCache("vehicles-filters");
    this.state = {
      availableFilters: cachedFilters || [],
      checkedList: [],
      clientId: webUser.client_id,
      clientGroup,
      clientsList,
      columns: [],
      data: [],
      filters: [],
      isAdmin: webUser.roleid === 2 ? true : false,
      isClient: webUser.roleid !== 2 ? true : false,
      loading: true,
      openVinSearch: false,
      orders: [],
      modalOpen: false,
      modalTitle: null,
      modalContent: [],
      page: 1,
      perPage: 10,
      relations: ["images"],
      reload: false,
      total: 0,
      vinDetails: null,
      vinSearch: vin,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { vin } = queryString.parse(nextProps.location.search);
    if (vin !== prevState.vinSearch) {
      return {
        ...prevState,
        vinSearch: vin,
        reload: true,
      };
    }
    return false;
  }

  componentDidMount() {
    this.init();
  }

  init(options = {}) {
    const {reloadData} = options;
    const { dispatch, utility } = this.props;
    const {
      isAdmin,
      isClient,
      clientsList,
      vinSearch,
      // availableFilters: stateFilters,
    } = this.state;
    const stateFilters = reloadData ? [] : this.state.availableFilters;
    dispatch(vehicleActions.clearVehicle());

    let relations = [];
    let orders = [];

    orders.push({
      field: "vehicleid",
      direction: "desc",
    });

    const columns = [
      {
        width: 1,
        title: "RI",
        field: null,
        dataRender: (item) => {
          return (
            <>
              <Checkbox onChange={() => this.setChecked(item.id)} />
            </>
          );
        },
      },
      {
        width: 1,
        title: "Cancelled",
        field: "cancelled",
        dataRender: (item) =>
          item.cancelled === 1 ? <b style={{ color: "red" }}>CANCELLED</b> : "",
      },
      {
        width: 2,
        title: "Application Type",
        field: "application_type.titleapplicationtype",
      },
      {
        width: 2,
        title: "Vehicle Status",
        field: "title_status.statusname",
      },
      {
        width: 2,
        title: "Process Type",
        field: "process_type_display",
      },
      {
        width: 2,
        title: "Process State",
        field: "process_state.stateabbreviation",
      },
      {
        width: 2,
        title: "Date Received",
        field: "recordcreateddate",
        dataRender: (item) => moment(item.recordcreateddate).format("M/D/Y"),
      },
      {
        width: 1,
        title: "User",
        field: "entered_by_user.username",
      },
      { width: 1, title: "PW Batch", field: "titledocsbatchid" },
      {
        width: 1,
        title: "Inv Batch",
        field: "invoice.invoicebatchid",
        dataRender: (item) =>
          item.invoice ? (
            <RouterLink to={`/invoicebatches/${item.invoice.invoicebatchid}`}>
              {item.invoice.invoicebatchid}
            </RouterLink>
          ) : (
            ""
          ),
      },
      {
        width: 1,
        title: "Inv Amount",
        field: "fees_total",
        dataRender: (item) => formatNumberToStringMoney(item.fees_total),
      },
      {
        width: 1,
        title: "Year",
        field: "yr",
      },
      {
        width: 2,
        title: "Make",
        field: "make",
      },
      {
        width: 2,
        title: "Model",
        field: "model",
      },
      {
        width: 3,
        title: "VIN",
        field: "vehiclevin",
        dataRender: (item) => (
          <RouterLink to={`/vehicles/${item.vehicleid}`}>
            {item.vehiclevin}
          </RouterLink>
        ),
      },
      {
        width: 1,
        title: "Mileage",
        field: "mileage",
      },
      {
        width: 3,
        title: "Client",
        field: "client.clientname",
      },
      {
        width: 1,
        title: "Load #",
        field: "client_load.id",
        dataRender: (item) =>
          item.client_load && (
            <RouterLink to={`/clientLoads/${item.client_load.id}`}>
              {item.client_load.name}
            </RouterLink>
          ),
      },
      {
        width: 4,
        title: "Photos/Files",
        field: null,
        dataRender: (item) =>
          item.images.length > 0 ? (
            <RouterLink to={`/photoupload/${item.id}`}>
              {item.images.length} Photos{" "}
            </RouterLink>
          ) : (
            <RouterLink to={`/photoupload/${item.id}`}>
              <i>no photos</i>
            </RouterLink>
          ),
      },
      {
        width: 2,
        title: "RI Label",
        field: null,
        dataRender: (item) => (
          <Button
            color="primary"
            variant="outlined"
            size="small"
            href={`${getEnvironmentURL()}/api/vehicles/${item.id}/forms/26`}
            target="_blank"
            style={{ color: "primary" }}
          >
            RI Label
          </Button>
        ),
      },
      {
        width: 2,
        title: "White Copy Date",
        field: "state_white_copy_date",
      },
      {
        width: 2,
        title: "Date to BMV",
        field: "titlesenttobmvdate",
      },
      {
        width: 2,
        title: "Date to Auction",
        field: "titlesenttoauctiondate",
      },
    ];

    let availableFilters = [
      {
        name: "Start Date",
        field: "recordcreateddate",
        operator: ">=",
        value: "",
        type: "date",
        default: "",
      },
      {
        name: "End Date",
        field: "recordcreateddate",
        operator: "<=",
        value: "",
        type: "date",
        default: "",
      },
      {
        name: "White Copy Date Start",
        field: "state_white_copy_date",
        operator: ">=",
        value: "",
        type: "date",
        default: "",
      },
      {
        name: "White Copy Date End",
        field: "state_white_copy_date",
        operator: "<=",
        value: "",
        type: "date",
        default: "",
      },
      {
        name: "VIN",
        field: "vehiclevin",
        operator: "like",
        value: vinSearch && vinSearch.length !== 6 ? vinSearch : "",
        type: "text",
        default: "",
      },
      {
        name: "Last6",
        field: "last6",
        operator: "eq",
        value: vinSearch && vinSearch.length === 6 ? vinSearch : "",
        type: "text",
        default: "",
      },
      {
        name: "Year",
        field: "yr",
        operator: "eq",
        value: "",
        type: "text",
        default: "",
      },
      {
        name: "Make",
        field: "make",
        operator: "eq",
        value: "",
        type: "text",
        default: "",
      },
      {
        name: "Model",
        field: "model",
        operator: "eq",
        value: "",
        type: "text",
        default: "",
      },
      {
        name: "Load Search",
        field: "client_load.name",
        operator: "like",
        value: "",
        type: "text",
        default: "",
      },
      {
        name: "Load Select",
        field: "client_load_id",
        operator: "eq",
        value: "",
        type: "choice",
        useUtility: true,
        utility: "client_loads",
        utilityKey: "id",
        utilityValue: "name",
        utilityPerPage: 25,
        utilityRelations: ["client", "created_by"],
        utilityOrder: {
          field: "id",
          direction: "desc",
        },
        utilityFilters: isClient
          ? [
              {
                field: "client_id",
                operator: "in",
                value: clientsList.join(","),
              },
            ]
          : [],
        choices: [],
        default: "",
      },
    ];

    if (isAdmin) {
      availableFilters.push({
        name: "Client",
        field: "clientid",
        operator: "eq",
        value: "",
        type: "choice",
        useUtility: true,
        utility: "clients_active",
        utilityKey: "clientid",
        utilityValue: "clientname",
        utilityFilters: [
          {
            field: "clientactive",
            operator: "eq",
            value: 2,
          },
        ],
        utilityOrder: {
          field: "clientname",
          direction: "asc",
        },
        choices: [],
        default: "",
      });
      availableFilters.push({
        name: "Application Type",
        field: "titleprocessingtype",
        operator: "eq",
        value: "",
        type: "choice",
        useUtility: true,
        utility: "title_application_types",
        utilityKey: "id",
        utilityValue: "titleapplicationtype",
        // utilityFilters: [
        //   {
        //     field: "clientactive",
        //     operator: "eq",
        //     value: 2,
        //   },
        // ],
        utilityOrder: {
          field: "titleapplicationtype",
          direction: "asc",
        },
        choices: [],
        default: "",
      });
      availableFilters.push({
        name: "Debtor Name",
        field: "debtorname",
        operator: "like",
        value: "",
        type: "text",
        default: "",
      });
    } else {
      availableFilters.push({
        name: "Client",
        field: "clientid",
        operator: "eq",
        value: "",
        type: "choice",
        useUtility: true,
        utility: "clients_active",
        utilityKey: "clientid",
        utilityValue: "clientname",
        utilityFilters: [
          {
            field: "clientactive",
            operator: "eq",
            value: 2,
          },
          {
            field: "clientid",
            operator: "in",
            value: clientsList.join(","),
          },
        ],
        utilityOrder: {
          field: "clientname",
          direction: "asc",
        },
        choices: [],
        default: "",
      });
      availableFilters.push({
        name: "Client",
        field: "clientid",
        operator: "in",
        value: clientsList.join(","),
        type: "hidden",
        default: "",
      });
    }

    if (stateFilters.length > 0) {
      availableFilters = stateFilters;
    }

    this.setState(
      { relations, orders, utility: "vehicles", columns, availableFilters },
      () => {
        if (utility.vehicles.items.length === 0 || reloadData) {
          this.getNewData();
        } else {
          const {
            items,
            page,
            per_page: perPage,
            total,
          } = this.props.utility.vehicles;
          this.setState({
            loading: false,
            data: items,
            page,
            perPage,
            total,
          });
        }
      }
    );
  }

  render() {
    const {
      availableFilters,
      checkedList,
      columns,
      data,
      isAdmin,
      loading,
      // filters,
      openVinSearch,
      page,
      perPage,
      reload,
      total,
      vinDetails,
    } = this.state;

    // if (reload === true) {
    //   this.setState({ reload: false }, () => {
    //     this.componentDidMount();
    //   });
    //   return <></>;
    // }

    return (
      <PageWithAppDrawer3 pageTitle={"Vehicles"}>
        {isAdmin && (
          <>
            <Button
              variant="contained"
              color="primary"
              onClick={() => this.setState({ openVinSearch: true })}
            >
              Add Vehicle
            </Button>{" "}
          </>
        )}
        <Button
          variant="contained"
          color="primary"
          disabled={checkedList.length === 0}
          href={`${getEnvironmentURL()}/api/vehicles/${checkedList.join(
            ","
          )}/forms/26`}
          target="_blank"
        >
          Get RI Labels
          {checkedList.length > 0 ? ` (${checkedList.length})` : ""}
        </Button>{" "}
        <Button variant="contained" color="primary" onClick={this.resetFilters}>
          Reset Search Filters
        </Button>
        <FiltersGrid
          availableFilters={availableFilters}
          onChange={this.handleFilterChange}
        />
        <ResponsiveGrid
          loading={loading}
          columns={columns}
          data={data}
          page={Number(page) - 1} // API has a 1 based page count, Material UI has a zero based page count
          perPage={Number(perPage)}
          total={Number(total)}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
          showActions
          onDownload={this.handleDownload}
          downloadLabel={"Download List CSV"}
        />
        <VinLookupDialog
          open={openVinSearch}
          onClose={this.handleVinSearchClose}
          onVinLookup={this.handleVinLookup}
          onRegister={this.handleRegister}
          vinDetails={vinDetails}
        />
      </PageWithAppDrawer3>
    );
  }

  resetFilters = () => {
    removeDiskCache("vehicles-filters");
    this.init({reloadData: true});
    // this.setState({ reload: true, availableFilters: [] }, () => {
    //   this.getNewData()
    // });
  };

  setChecked = (id) => {
    let { checkedList } = this.state;
    let isChecked = checkedList.includes(id);

    if (isChecked) {
      checkedList = checkedList.filter((existingId) => existingId !== id);
    } else {
      checkedList.push(id);
    }
    this.setState({ checkedList });
  };

  handleFilterChange = (newFilter) => {
    const { availableFilters } = this.state;
    const newFilters = availableFilters.map((filter) => {
      if (filter.field !== newFilter.field) return filter; // not the droid we're looking for, return unchanged
      if (filter.operator !== newFilter.operator) return filter; // not the droid we're looking for, return unchanged

      filter.value = newFilter.value;
      return filter;
    });
    this.setState({ availableFilters: newFilters }, () => {
      setDiskCache("vehicles-filters", newFilters);
      this.getNewData();
    });
  };

  handleDownload = () => {
    this.getNewData(null, null, true);
  };

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage + 1 }, () => this.getNewData());
  };

  handleChangeRowsPerPage = (event) => {
    const value = parseInt(event.target.value);
    this.setState(
      {
        perPage: value,
        page: 1,
      },
      () => this.getNewData()
    );
  };

  handleRegister = (vinDetails) => {
    const { dispatch } = this.props;

    dispatch(
      vehicleActions.updateLocalVehicle({
        vehiclevin: vinDetails.vin,
        yr: vinDetails.modelyear,
        make: vinDetails.make,
        model: vinDetails.model,
        vehicletype: vinDetails.vehicletype,
      })
    );
    hashHistory.push("/vehicles/new");
  };

  handleVinSearchClose = (value) => {
    this.setState({ openVinSearch: false });
  };

  handleChangeFilter = (text) => {
    this.setState({ filter: text }, () => {
      this.getNewData();
    });
  };

  handleChangeData = (page, per_page) => {
    page++;
    this.getNewData(page, per_page);
  };

  getNewData = async (page = null, perPage = null, download = false) => {
    const { utility, columns, relations, orders, availableFilters } =
      this.state;
    if (!perPage) perPage = this.state.perPage;
    if (!page) page = this.state.page;

    this.setState({ loading: true });

    // translate available filters into service filters
    let newFilters = availableFilters
      .filter((filter) => {
        if (["", null].includes(filter.value)) return false;
        if (
          ["isnull", "notnull"].includes(filter.operator) &&
          filter.value === false
        )
          return false;
        return true;
      })
      .map((filter) => {
        return {
          field: filter.field,
          operator: filter.operator,
          value: filter.value,
        };
      });

    // if (isClient) {
    //   newFilters.push({
    //     field: "clientid",
    //     operator: "eq",
    //     value: clientId
    //   });
    // }

    let options = {
      filters: newFilters,
      relations: relations,
      orders: orders,
      perPage: perPage,
      page: page,
    };

    if (download) {
      this.setState({ loading: true });
      options.perPage = 10000;
      await utilityService
        .getUtility(utility, options)
        .then((values) => {
          const downloadHeaders = columns
            .filter((item) => item.title)
            .map((item) => {
              if (!item.title) return null;
              return item.title;
            });
          const downloadFields = columns
            // .filter(item => item.field)
            .map((item) => {
              if (!item.field) return null;
              return item.field;
            });

          return generateCSV(downloadHeaders, downloadFields, values.data);
        })
        .then((csv) => {
          const blob = new Blob([csv], { type: "text/csv" });
          downloadBlob(blob, `vehicles.csv`);
        })
        .catch(console.error);
      this.setState({ loading: false });
      return;
    }

    await this.props.dispatch(
      utilityActions.getUtility(utility, options, () => {
        const {
          items,
          page,
          per_page: perPage,
          total,
        } = this.props.utility[utility];
        this.setState({
          loading: false,
          data: items,
          page,
          perPage,
          total,
        });
      })
    );
  };
}

const styles = (theme) => ({
  root: {
    display: "flex",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
});

function mapStateToProps(state) {
  const { authentication, utility } = state;
  const { user, webUser } = authentication;
  return {
    user,
    webUser,
    utility,
  };
}

const connectedVehiclesPage = connect(mapStateToProps)(
  withStyles(styles)(VehiclesPage)
);
export { connectedVehiclesPage as VehiclesPage };
