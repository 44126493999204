export function buildRelations(relations, addType = null) {
  if (relations === null) return null;
  if (relations.length === 0) return null;
  let strings = [];
  relations.forEach(function (relation, index) {
    if (relation.includes("notHas") || relation.includes("has")) {
      relation = relation.split("|");
      strings.push(`${relation[0]}[]=${relation[1]}`);
    } else {
      if (addType === "add") {
        strings.push(`withAdd[]=${relation}`);
      } else {
        strings.push(`with[]=${relation}`);
      }
    }
  });
  return strings;
}

export function buildFilters(filters) {
  // console.log("serviceHelpers buildFilters", {filters})
  if (filters === null) return "";
  let strings = [];
  filters.forEach(function (object, index) {
    if (object) {
      // console.log("serviceHelpers object", {object, isArray: Array.isArray(object)})
      if (Array.isArray(object)) {
        // console.log("serviceHelpers object is Array")
        if (object.length === 1) {
          const { field, operator, value } = object;
          const str1 = buildFilter(index, field, operator, value);
          // console.log({str1})
          strings.push(str1);
        } else {
          object.forEach(function (subObject, subIndex) {
            const { field, operator, value } = subObject;
            const str2 = buildFilter(index, field, operator, value, true);
            // console.log({str2})
            strings.push(str2);
          });
        }
      } else {
        // console.log("serviceHelpers object not Array")
        const { field, operator, value } = object;
        const str3 = buildFilter(index, field, operator, value);
        // console.log({str3})
        strings.push(str3);
      }
    }
  });
  return strings;
}

function buildFilter(index, field, operator, value, subFilter = false) {
  // relation queries have to be top level
  // console.log("serviceHelpers buildFilter", {index, field, operator, value, subFilter})
  if (field && field.includes(".")) {
    // console.log("serviceHelpers buildFilter includes relation (.)", {subFilter})
    if (["isnull", "notnull"].includes(operator)) {
      // console.log("serviceHelpers buildFilter is (isnull notnull)", {subFilter})
      if (subFilter) return `filters[${index}][]=${field}|${operator}`;
      else return `filters[]=${field}|${operator}`;
    } else {
      // console.log("serviceHelpers buildFilter not (isnull notnull)", {subFilter})
      if (subFilter) return `filters[${index}][]=${field}|${operator}|${value}`;
      else return `filters[]=${field}|${operator}|${value}`;
    }
  }

  // console.log("serviceHelpers buildFilter not includes relation (.)", {subFilter})
  if (["isnull", "notnull"].includes(operator)) {
    // console.log("serviceHelpers buildFilter is (isnull notnull)", {subFilter})
    if (subFilter) return `filters[${index}][]=${field}|${operator}`;
    else return `filters[${index}][]=${field}|${operator}`;
  } else {
    // console.log("serviceHelpers buildFilter not (isnull notnull)", {subFilter})
    if (subFilter) return `filters[${index}][]=${field}|${operator}|${value}`;
    else return `filters[${index}][]=${field}|${operator}|${value}`;
  }
}

export function buildOrders(orders) {
  if (orders === null) return "";
  let strings = [];
  orders.forEach(function (object, index) {
    const { field, direction } = object;
    let dir = "asc";
    if (["asc", "desc"].includes(direction)) dir = direction;
    strings.push(`order_by[]=${field}|${dir}`);
  });
  return strings;
}

export function buildAppends(appends) {
  if (appends === null) return null;
  if (appends.length === 0) return null;
  let strings = [];
  appends.forEach(function (append, index) {
    strings.push(`appends[]=${append}`);
  });
  return strings;
}

export function handleResponse(response) {
  return response.text().then((text) => {
    let data = null;
    try {
      data = text && JSON.parse(text);
    } catch (e) {
      // console.log('e',e);
      console.log("error", e.message);
      console.log("text", text);
      data = null;
      // throw e;
    }

    if (!response.ok) {
      if (response.status === 401) {
        return Promise.reject("Not Authorized");
      }
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }
    return data.response ? data.response : data;
  });
}

export function getEnvironmentURL() {
  const env = getEnvironment();
  return env?.url;
}

export function getCompanyName() {
  const env = getEnvironment();
  return env?.company;
}

export function getCustomer() {
  return "dvs";
}

export function getEnvironment(action = null) {
  if (action === "reset") {
    localStorage.setItem("environmentDVS", null);
  }
  let env = null;
  let company = null;
  try {
    env = JSON.parse(localStorage.getItem("environmentDVS"));
  } catch (e) {
    localStorage.setItem("environmentDVS", JSON.stringify(env));
  }
  if (env !== null) {
    return env;
  }
  let envs = JSON.parse(localStorage.getItem("environmentsDVS"));
  if (envs === null) {
    localStorage.setItem(
      "environmentsDVS",
      JSON.stringify([
        {
          name: "Development",
          env: "development",
          url: "https://godvs.greers.org",
        },
        {
          name: "Production",
          env: "production",
          url: "https://api.dvs-carzip.com",
        },
      ])
    );
    envs = JSON.parse(localStorage.getItem("environmentDVS"));
  }
  if (envs !== null && envs.length > 0) {
    const hostname = window.location.hostname;
    if (
      [
        "asc.carzipx.com",
        "dvsapp.dvs-corp.com",
        "dvsapp-prod.dvs-corp.com",
        "asc.dvs-carzip.com",
        "svetna.dvs-corp.com",
      ].includes(hostname)
    ) {
      env = envs.find((obj) => obj.env === "production");
    } else if (
      ["dvsapp-dev.dvs-corp.com", "svetna-dev.dvs-corp.com"].includes(hostname)
    ) {
      env = envs.find((obj) => obj.env === "development");
    } else if (["localhost", "localhost.svetna"].includes(hostname)) {
      // env = envs.find((obj) => obj.env === "development");
      env = envs.find((obj) => obj.env === "production");
    }
    else {
      env = envs.find((obj) => obj.env === "development");
    }

    if (env !== null) {
      company = 'dvs';
      if (window.location.hostname.includes("svetna")){
        company = 'svetna';
      }
      env.company = company;
      localStorage.setItem("environmentDVS", JSON.stringify(env));
      return env;
    }
  }
  return null;
}
