export const vehicleConstants = {
  VEHICLES_GET_REQUEST: "VEHICLES_GET_REQUEST",
  VEHICLES_GET_SUCCESS: "VEHICLES_GET_SUCCESS",
  VEHICLES_GET_FAILURE: "VEHICLES_GET_FAILURE",

  VEHICLE_ADD_REQUEST: "VEHICLE_ADD_REQUEST",
  VEHICLE_ADD_SUCCESS: "VEHICLE_ADD_SUCCESS",
  VEHICLE_ADD_FAILURE: "VEHICLE_ADD_FAILURE",

  VEHICLE_GET_REQUEST: "VEHICLE_GET_REQUEST",
  VEHICLE_GET_SUCCESS: "VEHICLE_GET_SUCCESS",
  VEHICLE_GET_FAILURE: "VEHICLE_GET_FAILURE",

  VEHICLE_UPDATE_REQUEST: "VEHICLE_UPDATE_REQUEST",
  VEHICLE_UPDATE_SUCCESS: "VEHICLE_UPDATE_SUCCESS",
  VEHICLE_UPDATE_FAILURE: "VEHICLE_UPDATE_FAILURE",

  VEHICLE_SET_LOCAL: "VEHICLE_SET_LOCAL",
  VEHICLE_CLEAR: "VEHICLE_CLEAR"
};
