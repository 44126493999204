import React, { useCallback, useMemo, useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { Link as RouterLink } from "react-router-dom";
// import Link from "@material-ui/core/Link";
import { connect, useSelector, useDispatch } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import moment from "moment";

import { utilityActions } from "../_actions";
import { getEnvironmentURL, authHeader } from "../_helpers";

import { PageWithAppDrawer2 } from "../_components/main/PageWithAppDrawer2";
// import { AuctionModal } from "../_components/admin/AuctionModal";
import ResponsiveGrid from "../_components/generic/ResponsiveGrid";

import { utilityService } from "../_services";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

import MuiAlert from "@material-ui/lab/Alert";

import Select from "@material-ui/core/Select";
import {
  Button,
  Typography,
  Snackbar,
  Grid,
  Divider,
  MenuItem,
  CircularProgress,
  Checkbox,
} from "@material-ui/core";

class VehicleMassUploadPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      snackbar: {
        open: false,
        severity: "info", //error, warning, info, success
        message: "default message",
        duration: 6000,
      },
    };
  }
  componentDidMount() {}

  static getDerivedStateFromProps(nextProps, prevState) {
    return null;
  }

  render() {
    const { classes } = this.props;
    const { data, snackbar } = this.state;
    return (
      <PageWithAppDrawer2 pageTitle={"Vehicle Import xls/csv"}>
        <Paper className={classes.paper}>
          <DragAndDrop
            onAddFile={this.handleAddFile}
            onRejectFile={this.handleRejectFile}
          />
          {data && <VehicleUpload data={data} />}
        </Paper>
        <Snackbar
          color={snackbar.severity}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          open={snackbar.open}
          autoHideDuration={snackbar.duration}
          onClose={() =>
            this.setState((prevState, props) => ({
              snackbar: {
                ...prevState.snackbar,
                open: false,
              },
            }))
          }
        >
          <MuiAlert severity={snackbar.severity} variant="filled">
            {snackbar.message}
          </MuiAlert>
        </Snackbar>
      </PageWithAppDrawer2>
    );
  }

  _showMessage = (message, severity = "info", duration = 6000) => {
    this.setState({
      snackbar: {
        open: true,
        severity,
        message,
        duration,
      },
    });
  };

  handleRejectFile = (file, errors) => {
    this._showMessage(
      file.path + " is not valid. " + errors[0].message,
      "error"
    );
  };

  handleAddFile = (file) => {
    let formData = new FormData();
    formData.append("file", file);

    fetch(`${getEnvironmentURL()}/api/reports/management/vehicleMassUpload`, {
      // Your POST endpoint
      method: "POST",
      headers: { ...authHeader() },
      body: formData,
    })
      .then(
        (response) => response.json() // if the response is a JSON object
      )
      .then((data) => {
        // console.log("success", success); // Handle the success response object
        if (Array.isArray(data)) {
          this.setState({ data });
          this._showMessage("Data uploaded");
        } else {
          this._showMessage("Unexpected data", "error");
        }
      })
      .catch((error) => {
        this._showMessage(error, "error"); // Handle the error response object
      });
  };
}

function VehicleUpload(props) {
  const dispatch = useDispatch();

  const clients_active = useSelector((state) => state.utility.clients_active);
  const taTypes = useSelector((state) => state.utility.title_application_types);
  const title_statuses = useSelector((state) => state.utility.title_statuses);
  const auctions = useSelector((state) => state.utility.auctions);

  const webUser = useSelector((state) => state.authentication.webUser);

  const [data, setData] = useState(props.data);
  const [hasHeaders, setHasHeaders] = useState(true);
  const [vinColumn, setVinColumn] = useState(null);
  const [odoColumn, setOdoColumn] = useState(null);
  const [priceColumn, setPriceColumn] = useState(null);
  const [loading, setLoading] = useState(false);
  const [dataProcessed, setDataProcessed] = useState(null);
  const [clientId, setClientId] = useState(null);
  const [billToClientId, setBillToClientId] = useState(null);
  const [taTypeId, setTaTypeId] = useState(null);
  const [titleStatusId, setTitleStatusId] = useState(null);
  const [processingTypeId, setProcessingTypeId] = useState(null);
  const [auctionId, setAuctionId] = useState(null);

  const processColumns = [
    {
      width: 1,
      title: "Create",
      field: "",
      dataRender: (item) => (
        <Checkbox
          checked={item.checked === true ? true : false}
          onChange={(e) => checkVin(item.vin, e.target.checked)}
        />
      ),
    },
    {
      width: 4,
      title: "VIN",
      field: "vin",
      dataRender: (item) => {
        let hasWarnings = false;
        if (item.vehicles.length > 0) hasWarnings = true;
        if (item.data && item.data.tpms && item.data.tpms.length > 0)
          hasWarnings = true;
        if (hasWarnings) {
          return (
            <Typography style={{ color: "red", fontWeight: "bold" }}>
              {item.vin}
            </Typography>
          );
        }
        return (
          <>
            {item.vin}
            {item.odo && (
              <>
                <br />
                Mileage: {item.odo}
              </>
            )}
            {item.price && (
              <>
                <br />
                Price: {item.price}
              </>
            )}
          </>
        );
      },
    },
    {
      width: 2,
      title: "Existing Vehicles",
      field: "",
      dataRender: (item) =>
        item.vehicles.length > 0
          ? item.vehicles.map((v, index) => (
              <React.Fragment key={index}>
                <RouterLink to={`/vehicles/${v.id}`}>{v.vehiclevin}</RouterLink>{" "}
                {v.client.clientname}
                <br />
              </React.Fragment>
            ))
          : "",
    },
    {
      width: 2,
      title: "TPMS Issue",
      field: "",
      dataRender: (item) =>
        item.data && item.data.tpms && item.data.tpms.length > 0 ? (
          <a
            href="https://icsw.nhtsa.gov/cars/rules/import/newsletters/Revised%20RI%20Newsletter%2063.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            Potential TPMS Issue
          </a>
        ) : (
          ""
        ),
    },
    {
      width: 2,
      title: "NHTSA",
      field: "",
      dataRender: (item) => (item.data && item.data.modelyear ? "Yes" : "No"),
    },
  ];

  useEffect(() => {
    if (clients_active.items.length === 0) {
      dispatch(
        utilityActions.getUtility("clients_active", {
          perPage: 1000,
          filters: [
            {
              field: "clientactive",
              operator: "eq",
              value: "2",
            },
          ],
          orders: [{ field: "clientname", direction: "asc" }],
        })
      );
    }
    if (taTypes.items.length === 0) {
      dispatch(
        utilityActions.getUtility("title_application_types", {
          perPage: 1000,
          orders: [
            {
              field: "titleapplicationtype",
              direction: "asc",
            },
          ],
        })
      );
    }
    if (title_statuses.items.length === 0) {
      dispatch(
        utilityActions.getUtility("title_statuses", {
          perPage: 1000,
        })
      );
    }
    if (auctions.items.length === 0) {
      dispatch(
        utilityActions.getUtility("auctions", {
          perPage: 1000,
        })
      );
    }
  }, []);

  const checkVin = (vin, checked) => {
    setDataProcessed((oldData) =>
      oldData.map((d, index) => {
        if (d.vin !== vin) return d;
        return {
          ...d,
          checked,
        };
      })
    );
  };

  useEffect(() => {
    console.log("dataprocessed", dataProcessed);
  }, [dataProcessed]);

  useEffect(() => {
    setData(props.data);
  }, [props.data]);

  useEffect(() => {
    if (vinColumn !== null && vinColumn !== "") {
      checkVins();
    }
  }, [vinColumn, odoColumn, priceColumn]);

  const checkVins = () => {
    setLoading(true);

    const url = `api/reports/management/vehicleMassProcess`;

    utilityService
      .getEndpoint(url, "POST", {
        body: {
          vinColumn,
          odoColumn,
          priceColumn,
          hasHeaders,
          data,
        },
      })
      .then((response) => {
        console.log("response", response);
        if (Array.isArray(response)) {
          setDataProcessed(response);
        }
        setLoading(false);
      })
      .catch((e) => {
        console.warn("error", e.message);
        setDataProcessed(null);
        setLoading(false);
      });
  };

  const addVehicles = () => {
    setLoading(true);
    dataProcessed.map((v, index) => {
      if (v.checked === false) {
        return null;
      }
      const newVehicle = {
        enteredby: webUser.userid,
        vehiclevin: v.vin,
        yr: v.data.modelyear,
        make: v.data.make,
        model: v.data.model,
        vehicletype: v.data.bodyclass,
        value_us_dollars: v.price || null,
        mileage: v.odo || null,
        clientid: clientId,
        billtoclientid: billToClientId,
        titleprocessingtype: taTypeId,
        titlestatusid: titleStatusId,
        processtype: processingTypeId,
        auctionid: auctionId,
        recordcreateddate: moment()
          .tz("America/Indiana/Indianapolis")
          .format("YYYY-MM-DD h:mm:ss"),
      };
      // console.log("newVehicle", newVehicle);
      // return;
      utilityService.addUtility("vehicles", newVehicle).then((response) => {
        // console.log("create response", response);
        checkVins();
      });
    });
    setLoading(false);
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        First two rows of data:
        <Table>
          <TableBody>
            {data.slice(0, 2).map((row, index) => (
              <TableRow key={index}>
                {row.map((col, index2) => (
                  <TableCell key={index2}>
                    <b>[{index2}]</b> {col}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12} style={{ marginTop: 20 }}>
        <Select
          value={hasHeaders === true ? true : false}
          disabled={loading}
          onChange={(e) => {
            setHasHeaders(e.target.value);
          }}
        >
          <MenuItem value={true}>
            Has Headers (first row won't be processed)
          </MenuItem>
          <MenuItem value={false}>No Headers (all rows processed)</MenuItem>
        </Select>
      </Grid>
      <Grid item xs={12} style={{ marginTop: 20 }}>
        <Select
          value={vinColumn === null ? "" : vinColumn}
          disabled={loading}
          onChange={(e) => {
            setVinColumn(e.target.value);
          }}
          displayEmpty
        >
          <MenuItem value="">Select Column that contains VIN</MenuItem>
          {data[0].map((col, index) => (
            <MenuItem key={index} value={index}>
              Column [{index}]: {col}
            </MenuItem>
          ))}
        </Select>
      </Grid>
      <Grid item xs={12} style={{ marginTop: 20 }}>
        <Select
          value={odoColumn === null ? "" : odoColumn}
          disabled={loading}
          onChange={(e) => {
            setOdoColumn(e.target.value);
          }}
          displayEmpty
        >
          <MenuItem value="">
            (optional) Select Column that contains Odo/Miles
          </MenuItem>
          {data[0].map((col, index) => (
            <MenuItem key={index} value={index}>
              Column [{index}]: {col}
            </MenuItem>
          ))}
        </Select>
      </Grid>
      <Grid item xs={12} style={{ marginTop: 20 }}>
        <Select
          value={priceColumn === null ? "" : priceColumn}
          disabled={loading}
          onChange={(e) => {
            setPriceColumn(e.target.value);
          }}
          displayEmpty
        >
          <MenuItem value="">
            (optional) Select Column that contains price
          </MenuItem>
          {data[0].map((col, index) => (
            <MenuItem key={index} value={index}>
              Column [{index}]: {col}
            </MenuItem>
          ))}
        </Select>
      </Grid>
      <Grid item xs={12} style={{ marginTop: 10, marginBottom: 10 }} />
      <Grid item xs={12} style={{ marginTop: 20 }}>
        {loading && <CircularProgress />}
        {!loading && dataProcessed && (
          <>
            <Select
              native
              style={{ fontSize: 12 }}
              value={titleStatusId || ""}
              onChange={(event, child) => {
                const value = event.target.value;
                setTitleStatusId(value);
              }}
              displayEmpty
            >
              <option value={""}>Vehicle Status</option>

              {title_statuses.items.map((item, index) => (
                <option key={item.statusid} value={item.statusid}>
                  {item.statuscode} - {item.statusname}
                </option>
              ))}
            </Select>
            <br />
            <Select
              native
              style={{ fontSize: 12 }}
              value={processingTypeId || 1}
              onChange={(event, child) => {
                const value = event.target.value;
                setProcessingTypeId(value);
              }}
              displayEmpty
              name="processtype"
            >
              <option value="">Processing Type</option>
              <option value={1}>In State</option>
              <option value={2}>Out of State</option>
            </Select>
            <br />
            <Select
              native
              style={{ fontSize: 12 }}
              value={clientId || ""}
              onChange={(event, child) => {
                const value = event.target.value;
                setClientId(value);
              }}
              displayEmpty
            >
              <option value={""}>Choose Client</option>

              {clients_active.items.map((item, index) => (
                <option key={index} value={item.clientid}>
                  {item.clientname}
                </option>
              ))}
            </Select>
            <br />
            <Select
              native
              style={{ fontSize: 12 }}
              value={billToClientId || ""}
              onChange={(event, child) => {
                const value = event.target.value;
                setBillToClientId(value);
              }}
              displayEmpty
              name="billtoclientid"
            >
              <option value="">Bill To Client:</option>
              {clients_active.items.map((item, index) => (
                <option key={index} value={item.clientid}>
                  {item.clientname}
                </option>
              ))}
            </Select>
            <br />
            <Select
              native
              style={{ fontSize: 12 }}
              value={taTypeId || ""}
              onChange={(event, child) => {
                const value = event.target.value;
                setTaTypeId(value);
              }}
              displayEmpty
            >
              <option value={""}>Choose Application Type</option>

              {taTypes.items.map((item, index) => (
                <option key={index} value={item.titleapplicationid}>
                  {item.titleapplicationtype}
                </option>
              ))}
            </Select>
            <br />
            <Select
              native
              style={{ fontSize: 12 }}
              value={auctionId || ""}
              onChange={(event) => {
                const value = event.target.value;
                setAuctionId(value);
              }}
              displayEmpty
              name="auctionid"
            >
              <option value="">Auction ID (None)</option>
              {auctions.items.map((auction) => {
                return (
                  <option key={auction.auctionid} value={auction.auctionid}>
                    {auction.auctionname}
                  </option>
                );
              })}
            </Select>
            <br />
            <Button
              size="small"
              variant="contained"
              color="primary"
              onClick={addVehicles}
              disabled={!clientId || !taTypeId}
            >
              Add Vehicles
            </Button>
            <ResponsiveGrid
              data={dataProcessed}
              columns={processColumns}
              usePagination={false}
            />
          </>
        )}
      </Grid>
    </Grid>
  );
}

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
};

const activeStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

function DragAndDrop(props) {
  const onDrop = useCallback((acceptedFiles, fileRejections) => {
    acceptedFiles.forEach((file) => {
      props.onAddFile && props.onAddFile(file);
    });
    fileRejections.forEach((file) => {
      props.onRejectFile && props.onRejectFile(file.file, file.errors);
    });
  }, []);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({ onDrop, accept: [".csv", ".xlsx", ".xls"] });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  return (
    <div className="container">
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} />
        <p>
          Drag 'n' drop
          <br />
          csv, xls, xlsx
          <br />
          Or click to select files
        </p>
      </div>
    </div>
  );
}

const styles = (theme) => ({
  root: {
    display: "flex",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
});

function mapStateToProps(state) {
  const { authentication, utility } = state;
  const { user } = authentication;
  const { us_states, auctions } = utility;
  return {
    user,
    auctions,
    us_states,
  };
}

const connectedVehicleMassUploadPage = connect(mapStateToProps)(
  withStyles(styles)(VehicleMassUploadPage)
);
export { connectedVehicleMassUploadPage as VehicleMassUploadPage };
