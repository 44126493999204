import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link as RouterLink } from "react-router-dom";

import { userActions, alertActions } from "../_actions";
import { getEnvironment } from "../_helpers";

import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";

import SnackbarContent from "@material-ui/core/SnackbarContent";
import ErrorIcon from "@material-ui/icons/Error";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";

import PageWithAppDrawer3 from "../_components/main/PageWithAppDrawer3";
import useOnlineStatus from "../_hooks/useOnlineStatus";
import { CircularProgress } from "@material-ui/core";
import useCustomerDNS from "../_hooks/useCustomerDNS";

import logoDvs from "../_assets/dvslogo_web_new_cropped.png";
import logoSvetna from "../_assets/svetna-logo.png";


export function LoginPage(props) {
  const classes = useLoginPageStyles();
  const dispatch = useDispatch();
  const isOnline = useOnlineStatus();
  const customerName = useCustomerDNS();

  const alert = useSelector((state) => state.alert);

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  useEffect(() => {
    dispatch(userActions.logout());
    getEnvironment("reset");
  }, [dispatch]);

  useEffect(() => {
    return () => {
      dispatch(alertActions.clear());
    };
  }, []);

  const handleClearAlert = () => {
    const { dispatch } = this.props;
    dispatch(alertActions.clear());
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (username && password) {
      let options = {};
      options.customerName = customerName;
      if (customerName === 'svetna'){
        options.route = '/svetna/vehicledashboard';
      }
      dispatch(userActions.login(username, password, options ));
    }
  };

  return (
    <PageWithAppDrawer3
      pageTitle={"Login"}
      showSideBar={false}
      showHomeLinks={true}
    >
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <img
            src={customerName === "svetna" ? logoSvetna : logoDvs}
            alt="logo"
            style={{width: '100%'}}
          />
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in {customerName.toUpperCase()}
          </Typography>

          <form className={classes.form} noValidate onSubmit={handleSubmit}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="username"
              label="Email Address"
              name="username"
              type="email"
              autoComplete="email"
              autoFocus
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            {alert.message && (
              <SnackbarContent
                style={{ backgroundColor: "red" }}
                aria-describedby="client-snackbar"
                message={
                  <span
                    id="client-snackbar"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <ErrorIcon />
                    {alert.message}
                  </span>
                }
                action={[
                  <IconButton
                    key="close"
                    aria-label="close"
                    color="inherit"
                    onClick={handleClearAlert}
                  >
                    <CloseIcon />
                  </IconButton>,
                ]}
              />
            )}

            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={!isOnline}
              className={classes.submit}
            >
              {isOnline ? (
                `Sign In`
              ) : (
                <>
                  <CircularProgress
                    color="secondary"
                    size={16}
                    style={{ marginRight: 10 }}
                  />
                  Network Unavailable
                </>
              )}
            </Button>
            <Grid container>
              <Grid item xs={12}>
                <Link
                  component={RouterLink}
                  to={"/forgot-password"}
                  variant="button"
                >
                  Forgot Password?
                </Link>
              </Grid>
              <Grid item xs={12} style={{ marginTop: 20 }}>
                <Link component={RouterLink} to={"/register"} variant="button">
                  Register for an account
                </Link>
              </Grid>
            </Grid>
          </form>
        </div>
      </Container>
    </PageWithAppDrawer3>
  );
}

const useLoginPageStyles = makeStyles((theme) => ({
  "@global": {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export { LoginPage as default };
