import React, { useState, useEffect } from "react";
import { Link as RouterLink, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { pdf, BlobProvider } from "@react-pdf/renderer";

import { PageWithAppDrawer2 } from "../_components/main/PageWithAppDrawer2";
import ResponsiveGrid from "../_components/generic/ResponsiveGrid";
import { utilityActions } from "../_actions";
import { hashHistory } from "../_helpers";
import {
  Paper,
  Button,
  makeStyles,
  Backdrop,
  CircularProgress,
  Grid,
  TextField,
  Select,
  Snackbar,
  Typography,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { utilityService, vehicleService } from "../_services";
import { InvoiceOneOffDocument } from "../_components/pdf/InvoiceOneOff";

export function OneOffInvoiceDetailsPage() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const item = useSelector((state) => state.utility.one_off_invoices_item);
  const clients_active = useSelector((state) => state.utility.clients_active);

  const [hasChanges, setHasChanges] = useState(false);
  const [openBackdrop, setOpenBackdrop] = useState(false);

  const [download, setDownload] = useState(false);
  const [send, setSend] = useState(false);
  const [sendDisabled, setSendDisabled] = useState(true);

  const [snackOpen, setSnackOpen] = useState(false);
  const [snackText, setSnackText] = useState("");

  const [doingVinLookup, setDoingVinLookup] = useState(false);

  const fields = [
    {
      width: 2,
      title: "Client",
      field: "client_id",
      type: "selectclient",
    },
    {
      width: 2,
      title: "Client Name",
      field: "client_name",
      type: "stringdisplay",
    },
    {
      width: 2,
      title: "Client Email",
      field: "client_email",
      type: "stringdisplay",
    },

    {
      width: 2,
      title: "Street",
      field: "street",
      type: "stringdisplay",
    },
    {
      width: 2,
      title: "City",
      field: "city",
      type: "stringdisplay",
    },
    {
      width: 2,
      title: "State",
      field: "state",
      type: "stringdisplay",
    },
    {
      width: 2,
      title: "zip",
      field: "zip",
      type: "stringdisplay",
    },
    {
      width: 2,
      title: "Phone",
      field: "phone",
      type: "stringdisplay",
    },
    {
      width: 2,
      title: "VIN",
      field: "vin",
      type: "vin",
    },
    {
      width: 2,
      title: "Year",
      field: "year",
      type: "string",
    },
    {
      width: 2,
      title: "Make",
      field: "make",
      type: "string",
    },
    {
      width: 2,
      title: "Model",
      field: "model",
      type: "string",
    },
    {
      width: 2,
      title: "Amount",
      field: "amount",
      type: "string",
    },
    {
      width: 2,
      title: "Pickup Location",
      field: "pickup_location",
      type: "string",
    },
    {
      width: 2,
      title: "Delivery Location",
      field: "delivery_location",
      type: "string",
    },
    {
      width: 2,
      title: "Driver Name",
      field: "driver_name",
      type: "string",
    },
  ];

  const { id } = useParams();

  useEffect(() => {
    if (clients_active.items.length === 0) {
      dispatch(
        utilityActions.getUtility("clients_active", {
          perPage: 1000,
          filters: [
            {
              field: "clientactive",
              operator: "eq",
              value: "2",
            },
          ],
          orders: [{ field: "clientname", direction: "asc" }],
        })
      );
    }
  }, []);

  useEffect(() => {
    if (id) {
      if (!item) {
        getNewData();
      } else if (Number(item.id) !== Number(id)) {
        getNewData();
      }
    }
  }, [id]);

  const getNewData = () => {
    dispatch(
      utilityActions.getOneUtility("one_off_invoices", id, [], () => {
        setOpenBackdrop(false);
      })
    );
  };

  const updateLocal = (event) => {
    const { name, value } = event.target;
    setHasChanges(true);
    dispatch(
      utilityActions.updateOneUtilityLocal("one_off_invoices", {
        [name]: value,
      })
    );
  };

  const handleSave = () => {
    setOpenBackdrop(true);
    dispatch(
      utilityActions.updateUtility("one_off_invoices", item.id, item, () => {
        setHasChanges(false);
        getNewData();
      })
    );
  };

  const updateClient = (clientId) => {
    const client = clients_active.items.find(
      (client) => client.clientid === clientId
    );
    setHasChanges(true);
    dispatch(
      utilityActions.updateOneUtilityLocal("one_off_invoices", {
        client_name: client ? client.clientname : "",
        street: client ? client.clientstreetaddress : "",
        city: client ? client.clientcity : "",
        state: client ? client.state && client.state.statefull : "",
        zip: client ? client.clientzip : "",
        phone: client ? client.clientphonenumber : "",
        client_email: client ? client.invoiceemailaddress : "",
        client_id: client ? clientId : null,
      })
    );
  };

  const vinLookup = () => {
    if (item.vin && item.vin.length === 17) {
      setDoingVinLookup(true);
      vehicleService
        .nhtsaVinLookup(item.vin)
        .then((response) => {
          if (response[0]) {
            setHasChanges(true);
            dispatch(
              utilityActions.updateOneUtilityLocal("one_off_invoices", {
                year: response[0].modelyear,
                make: response[0].make,
                model: response[0].model,
              })
            );
          }
          if (response.error) {
            console.warn(response.error);
          }
        })
        .finally(() => {
          setDoingVinLookup(false);
        });
    } else {
    }
  };

  return (
    <PageWithAppDrawer2 pageTitle={`One Off Invoice`}>
      <Paper className={classes.paper}>
        <Backdrop open={openBackdrop} style={{ zIndex: 10000, color: "white" }}>
          <CircularProgress />
          Updating
        </Backdrop>
        <Snackbar
          open={snackOpen}
          autoHideDuration={6000}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          onClose={() => setSnackOpen(false)}
        >
          <Alert onClose={() => setSnackOpen(false)} severity="success">
            {snackText}
          </Alert>
        </Snackbar>
        <RouterLink
          to="/accounting/one-off-invoices"
          style={{ marginBottom: 10 }}
        >
          All One Off Invoices
        </RouterLink>
        <Grid container spacing={2}>
          <Grid item>
            <Button
              variant="contained"
              size="small"
              color="primary"
              onClick={handleSave}
            >
              Save Changes
            </Button>
          </Grid>
          <Grid item>
            <Button
              size="small"
              color="primary"
              variant="contained"
              onClick={() => setDownload(true)}
              disabled={hasChanges}
            >
              {download ? (
                <BlobProvider document={<InvoiceOneOffDocument item={item} />}>
                  {({ blob, url, loading, error }) => {
                    if (!loading && url) {
                      // window.location.href = url;
                      // console.log("singleInvoice", singleInvoice);
                      // console.log("item", item);
                      window.open(url, "_blank");
                      setDownload(false);
                      return null;
                    }
                    return (
                      <>
                        <CircularProgress color="secondary" size={22} />
                        Print One Off Invoice
                      </>
                    );
                  }}
                </BlobProvider>
              ) : (
                <>Print One Off Invoice</>
              )}
            </Button>
          </Grid>
          <Grid item>
            <Button
              size="small"
              color="primary"
              variant="contained"
              disabled={hasChanges || (item && !item.client_email)}
              onClick={() => {
                setSend(true);
              }}
            >
              {send ? (
                <BlobProvider document={<InvoiceOneOffDocument item={item} />}>
                  {({ blob, url, loading, error }) => {
                    if (!loading && url) {
                      var reader = new FileReader();
                      reader.readAsDataURL(blob);
                      reader.onloadend = function () {
                        let base64data = reader.result;
                        utilityService
                          .getEndpoint(
                            `/api/invoice_batches/${item.id}/send`,
                            "post",
                            {
                              body: {
                                email: item.client_email,
                                docBase64: base64data,
                                item,
                              },
                            }
                          )
                          .then((response) => {
                            setSend(false);
                            setSnackText("Invoice sent: " + item.client_email);
                            setSnackOpen(true);
                            return null;
                          });
                      };
                    }
                    return (
                      <>
                        <CircularProgress color="secondary" size={22} />
                        Email One Off Invoice
                      </>
                    );
                  }}
                </BlobProvider>
              ) : (
                <>Email One Off Invoice</>
              )}
            </Button>
          </Grid>
        </Grid>
        {item && item.id ? (
          <Grid container spacing={1}>
            {fields.map((field, index) => (
              <React.Fragment key={index}>
                <Grid item xs={12} sm={4}>
                  {field.title}
                </Grid>
                <Grid item xs={12} sm={8}>
                  {field.type === "string" && (
                    <TextField
                      name={field.field}
                      placeholder={field.title}
                      value={item[field.field] || ""}
                      style={{ width: "100%" }}
                      onChange={updateLocal}
                    />
                  )}
                  {field.type === "vin" && (
                    <>
                      <TextField
                        name={field.field}
                        placeholder={field.title}
                        value={item[field.field] || ""}
                        style={{ width: "100%" }}
                        onChange={updateLocal}
                      />
                      <Button
                        size="small"
                        color="primary"
                        variant="contained"
                        onClick={vinLookup}
                        disabled={doingVinLookup}
                      >
                        Lookup VIN
                        {doingVinLookup && (
                          <CircularProgress
                            size={14}
                            style={{ marginLeft: 5 }}
                          />
                        )}
                      </Button>
                    </>
                  )}
                  {field.type === "stringdisplay" && (
                    <Typography style={{ width: "100%" }}>
                      {item[field.field]}
                    </Typography>
                  )}
                  {field.type === "selectclient" && (
                    <Select
                      fullWidth
                      native
                      name={field.field}
                      value={item[field.field] || ""}
                      onChange={(e) => updateClient(Number(e.target.value))}
                    >
                      <option value="">Select Client</option>
                      {clients_active.items.map((client, index) => (
                        <option key={index} value={client.clientid}>
                          {client.clientname}
                        </option>
                      ))}
                    </Select>
                  )}
                  {field.type === "boolean" && (
                    <Select
                      native
                      name={field.field}
                      value={item[field.field]}
                      onChange={updateLocal}
                      style={{ fontSize: 12 }}
                    >
                      <option value={0}>No</option>
                      <option value={1}>Yes</option>
                    </Select>
                  )}
                </Grid>
              </React.Fragment>
            ))}
          </Grid>
        ) : (
          <CircularProgress />
        )}
      </Paper>
    </PageWithAppDrawer2>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
}));
