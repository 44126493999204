/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useState, useEffect } from "react";
import { Link as RouterLink, useLocation, useParams } from "react-router-dom";
import Link from "@material-ui/core/Link";
import { connect, useSelector, useDispatch } from "react-redux";
import { byString } from "../_helpers/generic";
import { withStyles } from "@material-ui/core/styles";
import withWidth from "@material-ui/core/withWidth";
import JSONPretty from "react-json-pretty";
import { hashHistory } from "../_helpers";

import MomentUtils from "@date-io/moment";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import { utilityActions } from "../_actions";
import { utilityService } from "../_services";
import { getEnvironmentURL, authHeader } from "../_helpers";

import { PageWithAppDrawer2 } from "../_components/main/PageWithAppDrawer2";
import ModalClientLoadAddVehicle from "../_components/vehicle/ModalClientLoadAddVehicle";
import ResponsiveGrid from "../_components/generic/ResponsiveGrid";
import ModalPrintPaperwork from "../_components/vehicle/ModalPrintPaperwork";

import {
  Paper,
  Grid,
  Select,
  TextField,
  Button,
  Backdrop,
  AppBar,
  Hidden,
  Tabs,
  Tab,
  Typography,
  Divider,
  Snackbar,
  FormControl,
  InputLabel,
  MenuItem,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import CircularProgress from "@material-ui/core/CircularProgress";
import LaunchTwoToneIcon from "@material-ui/icons/LaunchTwoTone";
import { isEmpty, trim } from "lodash";
import ModalGeneric from "../_components/generic/ModalGeneric";
import moment from "moment";
import HistoryItems from "../_components/HistoryItems";
import Alert from "@material-ui/lab/Alert";
import ClientLoadTransport from "../_components/clientLoad/ClientLoadTransport";

class ClientLoadDetailsPage extends React.Component {
  constructor(props) {
    super(props);
    const { id } = this.props.match ? this.props.match.params : {};
    this.state = {
      id,
      reload: false,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { id } = nextProps.match ? nextProps.match.params : {};
    if (id !== prevState.id) {
      return {
        ...prevState,
        id,
        reload: true,
      };
    }
    return false;
  }

  async componentDidMount() {}

  render() {
    const { id, reload } = this.state;
    const { classes } = this.props;

    if (reload === true) {
      this.setState({ reload: false }, () => {
        this.componentDidMount();
      });
      return <></>;
    }

    return (
      <PageWithAppDrawer2 pageTitle={`Client Load #${id}`}>
        <RouterLink to="/clientLoads">All Loads</RouterLink>
        <Paper className={classes.paper}>
          <ClientLoadDetails id={id} />
        </Paper>
      </PageWithAppDrawer2>
    );
  }
}

function ClientLoadDetails(props) {
  const dispatch = useDispatch();
  const id = props.id;
  const webUser = useSelector((state) => state.authentication.webUser);
  const item = useSelector((state) => state.utility.client_loads_item);
  const clients_active = useSelector((state) => state.utility.clients_active);
  const locations = useSelector((state) => state.utility.client_locations);
  const transporters = useSelector(
    (state) => state.utility.client_transporters
  );
  const drivers = useSelector((state) => state.utility.client_drivers_full);
  const [hasRequestedTransportation, setHasRequestedTransportation] =
    useState(false);

  // const url = getEnvironmentURL();
  const conformityInspectionRef = useRef(null);
  const titleDocsRef = useRef(null);

  const isAdmin = webUser.roleid === 2 ? true : false;
  const isClient = webUser.roleid !== 2 ? true : false;
  // const clientId = webUser.client_id;

  const [tabIndex, setTabIndex] = useState(0);
  const [socBlackbookExists, setSocBlackbookExists] = useState(false);
  const [blackbookLoading, setBlackbookLoading] = useState(false);
  const [socBayDirectly, setSocBayDirectly] = useState(false);

  const [modalOpen, setModalOpen] = useState(false);
  const [modalPrintOpen, setModalPrintOpen] = useState(false);
  const [modalErrorOpen, setModalErrorOpen] = useState(false);
  const [modalErrorText, setModalErrorText] = useState("");
  const [modalChangeTypeOpen, setModalChangeTypeOpen] = useState(false);
  const [modalChangeType, setModalChangeType] = useState("");

  const [doUsdCheck, setDoUsdCheck] = useState(true);
  const [modalUsdValuesOpen, setModalUsdValuesOpen] = useState(false);
  const [loadHasUsdValues, setLoadHasUsdValues] = useState(true);
  const [loadHasUsdValuesErrors, setLoadHasUsdValuesErrors] = useState([]);

  const [bkgNumber, setBkgNumber] = useState("");
  const [sentToCustomsDate, setSentToCustomsDate] = useState(null);
  const [crossedBorderDate, setCrossedBorderDate] = useState(null);
  const [scannedDate, setScannedDate] = useState(null);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [updateLog, setUpdateLog] = useState([]);
  const [canDelete, setCanDelete] = useState(false);
  const [canChangeToSingle, setCanChangeToSingle] = useState(false);
  const [canChangeToMultiple, setCanChangeToMultiple] = useState(false);

  const [modalBay, setModalBay] = useState(false);
  const [bayName, setBayName] = useState("Ray Ramsey");
  const [bayDate, setBayDate] = useState(moment().format("MM/DD/YYYY"));
  const [socSigner, setSocSigner] = useState("");

  const [modalUrlFix, setModalUrlFix] = useState(false);
  const [vehicleFixId, setVehicleFixId] = useState(null);

  const [canUpdateScanned7501, setCanUpdateScanned7501] = useState(false);

  const [snackbar, setSnackbar] = React.useState({
    open: false,
    severity: "info", //error, warning, info, success
    message: "default message",
    duration: 6000,
  });

  const fields = [
    {
      width: 2,
      title: "Client Name",
      field: isClient ? "client.clientname" : "client_id",
      type: isClient ? "display" : "selectClient",
    },
    {
      width: 2,
      title: "Load Name",
      field: "name",
      type: "string",
    },
    {
      width: 2,
      title: "Port",
      field: "port",
      type: "string",
    },
    {
      width: 2,
      title: "Port in US",
      field: "port_us",
      type: "string",
    },
    {
      width: 2,
      title: "Date of Departure",
      field: "date_departure",
      type: "date",
    },
    {
      width: 2,
      title: "Transporter",
      field: "client_transporter_id",
      type: "selectClientTransporter",
    },
    {
      width: 2,
      title: "From Location",
      field: "location_from_id",
      type: "selectClientLocation",
    },
    {
      width: 2,
      title: "To Location",
      field: "location_to_id",
      type: "selectClientLocation",
    },
    {
      width: 2,
      title: "Pickup Location",
      field: "location_pickup_id",
      type: "selectClientLocation",
    },
  ];

  const columns = [
    {
      width: 1,
      title: "Remove",
      field: null,
      dataRender: (item) => (
        <Link
          onClick={(event) => {
            event.preventDefault();
            removeVehicle(item.vehicleid);
          }}
        >
          Remove
        </Link>
      ),
    },
    {
      width: 3,
      title: "Vehicle Info",
      field: "vehiclevin",
      dataRender: (item) => (
        <>
          <RouterLink to={`/vehicles/${item.vehicleid}`}>
            {item.vehiclevin}
          </RouterLink>
          <br />
          {item.yr} {item.make}
          <br />
          {item.model}
          <br />
          {item.bay_vehicle && " (in Bay)"}
          {item.images.map((image) => {
            if (Number(image.imagetype) === 9) {
              return (
                <div key={String(image.id)}>
                  <a
                    href={image.cloud_url_large}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {image.type?.name}
                  </a>
                </div>
              );
            }
            return null;
          })}
          <Button
            disabled={false}
            variant="outlined"
            onClick={() => openUrlFix(item.vehicleid)}
          >
            Fix with Bay URL
          </Button>
          {/* <Button
            disabled={false}
            variant="outlined"
            href={`${getEnvironmentURL()}/api/vehicles/${
              item.vehicleid
            }/dotPackage`}

            // href={`${getEnvironmentURL()}/api/vehicles/${
            //   item.vehicleid
            // }/multipleForms/0?docs=15,17,16,25,35,36,24,9&downloadName=${
            //   item.vehiclevin
            // }-all.pdf&format=zip`}
          >
            DOT Package
          </Button> */}
          <Button
            disabled={false}
            variant="outlined"
            onClick={(event) => {
              event.preventDefault();
              checkPolicy(
                item.vehicleid,
                item.vehiclevin,
                `${getEnvironmentURL()}/api/vehicles/${
                  item.vehicleid
                }/dotPackage`
              );
            }}
            href={`${getEnvironmentURL()}/api/vehicles/${
              item.vehicleid
            }/dotPackage`}

            // href={`${getEnvironmentURL()}/api/vehicles/${
            //   item.vehicleid
            // }/multipleForms/0?docs=15,17,16,25,35,36,24,9&downloadName=${
            //   item.vehiclevin
            // }-all.pdf&format=zip`}
          >
            DOT Package
          </Button>
        </>
      ),
    },
    {
      width: 3,
      title: "Single Driver Info",
      field: "client_driver_id",
      dataRender: (item) => (
        <VehicleSingleDriverOptions
          vehicleId={item.vehicleid}
          scacCode={item.scac_code}
          driverId={item.client_driver_id}
          transitNumber={item.transit_number}
        />
      ),
    },

    {
      width: 1,
      title: "Bay Images",
      field: "",
      dataRender: (item) => (
        <>
          {item.bay_image_status.status}
          <br />
          {item.bay_image_status.warnings.length > 0 &&
            item.bay_image_status.warnings.map((warning, key) => (
              <Typography key={key} style={{ color: "red", fontSize: 12 }}>
                {warning}
              </Typography>
            ))}
        </>
      ),
    },
    {
      width: 1,
      title: "BKG #",
      field: "bkg_entry_load_number",
    },
    {
      width: 1,
      title: "Submitted to Customs",
      field: "submittedtocustomsdate",
    },
    {
      width: 1,
      title: "Crossed Border",
      field: "crossedborderdate",
    },
    {
      width: 1,
      title: "7501 Scanned",
      field: "scanned_7501_date",
    },
  ];

  const checkPolicy = async (vehicleId, vin, url) => {
    console.log({ vehicleId, vin });
    // return false;
    const rc = await utilityService
      .getEndpoint(`/api/vehicles/${vehicleId}/checkBayPolicyNumber`, "GET")
      .then((response) => {
        console.log({ response });
        window.location = url;
      })
      .catch((e) => {
        console.log(`${vin} ${e}`);
        alert(`${vin} ${e}`);
        return false;
      })
      .finally(() => {
        return true;
      });
    return rc;
  };

  useEffect(() => {
    if (clients_active.items.length === 0) {
      dispatch(
        utilityActions.getUtility("clients_active", {
          perPage: 1000,
          filters: [
            {
              field: "clientactive",
              operator: "eq",
              value: "2",
            },
          ],
          orders: [{ field: "clientname", direction: "asc" }],
        })
      );
    }

    return () => {
      dispatch(utilityActions.clearOneUtility("client_loads"));
    };
  }, []);

  useEffect(() => {
    let getTransporters = false;
    let getLocations = false;
    let getDrivers = false;
    let shouldUpdateScanned7501 = true;
    if (item && item.id) {
      if (doUsdCheck) {
        setDoUsdCheck(false);
        checkUsdValues();
      }
      if (item.load_type === "single") {
        // is single load
        setCanChangeToMultiple(true);
        setCanChangeToSingle(false);
      } else {
        // is multiple load
        setCanChangeToMultiple(false);
        setCanChangeToSingle(true);
      }
      if (transporters.items.length === 0) {
        getTransporters = true;
      }
      if (locations.items.length === 0) {
        getLocations = true;
      }
      if (drivers.items.length === 0) {
        getDrivers = true;
      }
      if (
        item &&
        item.client_id &&
        transporters.items.length > 0 &&
        transporters.items[0].client_id != item.client_id
      ) {
        getTransporters = true;
      }
      if (
        item &&
        item.client_id &&
        locations.items.length > 0 &&
        locations.items[0].client_id != item.client_id
      ) {
        getLocations = true;
      }
      if (
        item &&
        item.client_id &&
        drivers.items.length > 0 &&
        drivers.items[0].client_id != item.client_id
      ) {
        getDrivers = true;
      }
      if (item.vehicles && item.vehicles.length === 0) {
        setCanDelete(true);
      } else {
        setCanDelete(false);
        item.vehicles.map((v) => {
          if (!v.crossedborderdate) {
            shouldUpdateScanned7501 = false;
          }
        });
      }
      setCanUpdateScanned7501(shouldUpdateScanned7501);

      let needsBlackbook = false;
      item.vehicles.map((v, index) => {
        if (v.blackbook_info.length === 0) {
          console.warn("no blackbook", v.vehiclevin);
          needsBlackbook = true;
        }
        return;
      });
      setSocBlackbookExists(!needsBlackbook);
    }
    if (getLocations && !hasRequestedTransportation) {
      dispatch(
        utilityActions.getUtility("client_locations", {
          perPage: 1000,
          filters: [
            {
              field: "client_id",
              operator: "eq",
              value: item.client_id,
            },
          ],
          orders: [{ field: "name", direction: "asc" }],
        })
      );
      setHasRequestedTransportation(true);
    }
    if (getTransporters && !hasRequestedTransportation) {
      dispatch(
        utilityActions.getUtility("client_transporters", {
          perPage: 1000,
          filters: [
            {
              field: "client_id",
              operator: "eq",
              value: item.client_id,
            },
          ],
          orders: [{ field: "name", direction: "asc" }],
        })
      );
      setHasRequestedTransportation(true);
    }
    if (getDrivers && !hasRequestedTransportation) {
      dispatch(
        utilityActions.getUtility("client_drivers_full", {
          perPage: 1000,
          filters: [
            {
              field: "client_id",
              operator: "eq",
              value: item.client_id,
            },
          ],
          orders: [{ field: "first_name", direction: "asc" }],
        })
      );
      setHasRequestedTransportation(true);
    }
  }, [item]);

  useEffect(() => {
    if (id) {
      if (!item) {
        getNewData();
      } else if (Number(item.id) !== Number(id)) {
        getNewData();
      }
    }
  }, [id]);

  const getNewData = () => {
    dispatch(
      utilityActions.getOneUtility("client_loads", id, {
        relations: ["vehicles", "vehicles_single", "bay_shipment", "driver"],
      })
    );
  };

  const updateLocal = (event) => {
    const { name, value } = event.target;
    dispatch(
      utilityActions.updateOneUtilityLocal("client_loads", { [name]: value })
    );
  };
  const updateLocalManual = (name, value, callback) => {
    dispatch(
      utilityActions.updateOneUtilityLocal(
        "client_loads",
        { [name]: value },
        callback
      )
    );
  };

  const checkUsdValues = () => {
    let usdErrors = [];
    item.vehicles.map((v) => {
      if (Number(v.value_us_dollars) < 1) {
        usdErrors.push(`${v.vehiclevin} is missing Value in US Dollars`);
      }
      return;
    });
    if (usdErrors.length > 0) {
      setLoadHasUsdValues(false);
      setLoadHasUsdValuesErrors(usdErrors);
      setModalUsdValuesOpen(true);
    }
  };

  const handleSave = (callback) => {
    dispatch(
      utilityActions.updateUtility("client_loads", item.id, item, () => {
        getNewData();
        setSnackbar({
          open: true,
          severity: "success",
          message: "Load Updated",
          duration: 3000,
        });
        if (callback) callback();
      })
    );
  };

  const handleOnClose = () => {
    setModalOpen(false);
    getNewData();
  };

  const removeVehicle = async (vehicleId) => {
    await utilityService
      .getEndpoint(`api/client_loads/${props.id}/remove/${vehicleId}`, "DELETE")
      .then((response) => {
        getNewData();
      });
  };

  const deleteLoad = async () => {
    setOpenBackdrop(true);
    await utilityService
      .getEndpoint(`api/client/client_loads/${props.id}`, "DELETE")
      .then((response) => {
        if (response.length === 0) {
          alert("Load Deleted");
          hashHistory.push("/clientLoads");
        } else {
          alert("Unable to delete load\n" + response);
        }
      })
      .catch((error) => {
        alert("Unable to delete load\n" + error);
      });
    setOpenBackdrop(false);
  };

  const massUpdate = async (type, data = null) => {
    handleSave();
    setUpdateLog([]);
    let field = null;
    let value = null;
    let updateType = "field";
    if (type === "bkg") {
      field = "bkg_entry_load_number";
      value = bkgNumber;
    }
    if (type === "customs") {
      field = "submittedtocustomsdate";
      value = sentToCustomsDate;
    }
    if (type === "crossed") {
      field = "crossedborderdate";
      value = crossedBorderDate;
    }
    if (type === "scanned") {
      field = "scanned_7501_date";
      value = scannedDate;
    }

    if (type === "7501scan") {
      updateType = type;
    }

    setOpenBackdrop(true);
    for (const [index, v] of item.vehicles.entries()) {
      if (updateType === "field") {
        await utilityService
          .updateUtility("vehicles", v.vehicleid, {
            [field]: value,
          })
          .then((response) => {
            const text = `${v.vehiclevin}: updated`;
            setUpdateLog((updateLog) => [...updateLog, text]);
          })
          .catch((error) => {
            const errorText = `${v.vehiclevin}: ${error}`;
            setUpdateLog((updateLog) => [...updateLog, errorText]);
          });
      }
    }
    await getNewData();
    setOpenBackdrop(false);
  };

  const handleFileUpload = (event) => {
    const vehicleId =
      item.vehicles && item.vehicles.length > 0
        ? item.vehicles[0].vehicleid
        : null;
    if (!vehicleId) {
      alert("No vehicles in load");
      return;
    }
    let vehicleIds = [];
    item.vehicles.map((v) => {
      vehicleIds.push(v.vehicleid);
      return;
    });

    setOpenBackdrop(true);

    event.preventDefault();

    const file = event.target.files[0];

    let formData = new FormData();
    formData.append("file", file);
    formData.append("vehicleId", vehicleIds.join(","));
    formData.append("note", "7501 Scanned");
    formData.append("type", 0);
    formData.append("returnObject", 1);

    fetch(`${getEnvironmentURL()}/api/vehicles/${vehicleId}/images`, {
      method: "POST",
      headers: { ...authHeader() },
      body: formData,
    })
      .then((response) => {
        return response.json(); // if the response is a JSON object
      })
      .then(
        //TODO:save also in customer object
        (response) => {
          massUpdate("7501scan", response);
        }
      )
      .catch((error) => {
        setOpenBackdrop(false);
        alert(error.message);
      });
  };

  const changeTypeConfirm = (type) => {
    if (type === "single") {
      if (item.bay_shipment) {
        setModalErrorText("Cannot change type, load already sent to bay");
        setModalErrorOpen(true);
        return;
      }
      setModalChangeTypeOpen(true);
      setModalChangeType(type);
      updateLocalManual("load_type", type);
    }
    if (type === "multiple") {
      let canChangeToMultiple = true;
      item.vehicles.map((v) => {
        if (v.bay_shipment) {
          canChangeToMultiple = false;
        }
        return;
      });
      if (!canChangeToMultiple) {
        setModalErrorText(
          "Cannot change type, vehicles records already sent to bay"
        );
        setModalErrorOpen(true);
        return;
      }
      setModalChangeTypeOpen(true);
      setModalChangeType(type);
      updateLocalManual("load_type", type);
    }
  };

  const changeType = (type) => {
    setModalChangeTypeOpen(false);
    handleSave();
  };

  const refreshBlackbook = () => {
    setBlackbookLoading(true);
    utilityService
      .getEndpoint(`api/client_loads/${item.id}/blackbook`, "post")
      .then((response) => {
        setBlackbookLoading(false);
        getNewData();
      });
  };

  const openUrlFix = (vehicleId) => {
    console.log("openUrlFix");
    setModalUrlFix(true);
    setVehicleFixId(vehicleId);
  };

  return (
    <>
      <form
        id="bay-form"
        method="post"
        action={
          getEnvironmentURL() +
          "/api/reports/management/bay/statementOfConformity"
        }
        target="_blank"
      >
        <input type="hidden" name="replacements[name]" value={bayName} />
        {socBayDirectly && <input type="hidden" name="socBay" value="1" />}
        <input type="hidden" name="replacements[date]" value={bayDate} />
        <input type="hidden" name="title" value="Client Load" />
        <input type="hidden" name="soc_signer" value={socSigner} />
        <input
          type="hidden"
          name="subtitle"
          value={(item && item.name) || ""}
        />

        <input type="hidden" name="replacements[designation]" value="Agent" />
        {item &&
          item.vehicles &&
          item.vehicles.map((v, index) => (
            <input
              key={index}
              type="hidden"
              name="vehicles[]"
              value={v.vehicleid}
            />
          ))}
      </form>
      <form
        ref={conformityInspectionRef}
        id="conformity-inspection-form"
        method="post"
        action={
          getEnvironmentURL() + "/api/reports/management/conformity/inspection"
        }
        target="_blank"
      >
        <input type="hidden" name="replacements[designation]" value="Agent" />
        {item &&
          item.vehicles &&
          item.vehicles.map((v, index) => (
            <input
              key={index}
              type="hidden"
              name="vehicles[]"
              value={v.vehicleid}
            />
          ))}
      </form>
      <form
        ref={titleDocsRef}
        id="conformity-inspection-form"
        method="post"
        action={getEnvironmentURL() + "/api/reports/management/bay/titleDocs"}
        target="_blank"
      >
        <input type="hidden" name="replacements[designation]" value="Agent" />
        {item &&
          item.vehicles &&
          item.vehicles.map((v, index) => (
            <input
              key={index}
              type="hidden"
              name="vehicles[]"
              value={v.vehicleid}
            />
          ))}
      </form>
      <ModalGeneric
        open={modalChangeTypeOpen}
        title={`Change Load Type`}
        cancelText="Cancel"
        onCancel={() => {
          setModalChangeTypeOpen(false);
          updateLocalManual(
            "load_type",
            modalChangeType === "single" ? "multiple" : "single"
          );
          setModalChangeType("");
        }}
        closeText={`Change to ${modalChangeType}`}
        onClose={() => changeType(modalChangeType)}
      >
        {modalChangeType === "single" && (
          <>
            Changing type to Single
            <br />
            Each Vehicle will have it's own broker record
          </>
        )}
        {modalChangeType === "multiple" && (
          <Typography variant="h5" style={{ color: "red" }}>
            WARNING: Driver and transit information will be lost
          </Typography>
        )}
      </ModalGeneric>
      <ModalGeneric
        open={modalErrorOpen}
        title="Error"
        onClose={() => setModalErrorOpen(false)}
      >
        <Typography variant="h5" style={{ color: "red" }}>
          {modalErrorText}
        </Typography>
      </ModalGeneric>
      <ModalGetSoc
        open={modalBay}
        socBayDirectly={socBayDirectly}
        bayDate={bayDate}
        bayName={bayName}
        setBayName={setBayName}
        setBayDate={setBayDate}
        setSocSigner={setSocSigner}
        onClose={() => {
          setModalBay(false);
          setSocBayDirectly(false);
        }}
      />
      <ModalUrlFix
        open={modalUrlFix}
        vehicleId={vehicleFixId}
        clientLoadId={item?.id}
        bayShipmentId={item?.bay_shipment?.bay_shipment_id}
        onClose={() => {
          setVehicleFixId(null);
          setModalUrlFix(false);
        }}
        onCancel={() => {
          setVehicleFixId(null);
          setModalUrlFix(false);
        }}
      />

      <Snackbar
        color={snackbar.severity}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={snackbar.open}
        autoHideDuration={snackbar.duration}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <MuiAlert severity={snackbar.severity} variant="filled">
          {snackbar.message}
        </MuiAlert>
      </Snackbar>
      {item && item.id ? (
        <>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Button variant="contained" color="primary" onClick={handleSave}>
                Save Changes
              </Button>{" "}
              {loadHasUsdValues ? (
                <Button
                  variant="contained"
                  color="primary"
                  component={RouterLink}
                  to={`/clientLoads/${item.id}/print`}
                  target="_blank"
                  disabled={!loadHasUsdValues}
                >
                  Print Load Sheet
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  style={{ backgroundColor: "yellow" }}
                  onClick={() => setModalUsdValuesOpen(true)}
                >
                  Print Load Sheet
                </Button>
              )}{" "}
              <Button
                variant="contained"
                color="primary"
                style={{ width: 200 }}
                onClick={() => setModalPrintOpen(true)}
              >
                Print Documents
              </Button>{" "}
              <Button
                variant="contained"
                color="primary"
                style={{ width: 200 }}
                onClick={deleteLoad}
                disabled={!canDelete}
              >
                Delete Load
              </Button>{" "}
              {isAdmin && (
                <Button
                  variant="contained"
                  color={socBlackbookExists ? "primary" : "secondary"}
                  // href={`${url}/api/`}
                  // target="_blank"
                  onClick={() => conformityInspectionRef.current.submit()}
                >
                  Conformity Inspection
                </Button>
              )}{" "}
              {isAdmin && (
                <Button
                  variant="contained"
                  color={socBlackbookExists ? "primary" : "secondary"}
                  onClick={() => setModalBay(true)}
                  // disabled={!socBlackbookExists}
                >
                  Statements of Conformity
                </Button>
              )}
              {isAdmin && !socBlackbookExists && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={refreshBlackbook}
                  disabled={blackbookLoading}
                >
                  {blackbookLoading && (
                    <CircularProgress color="secondary" size={14} />
                  )}
                  SOC Refresh Blackbook
                </Button>
              )}
              {isAdmin && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setModalBay(true);
                    setSocBayDirectly(true);
                  }}
                >
                  SOC from Bay Directly
                </Button>
              )}
              {isAdmin && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => titleDocsRef.current.submit()}
                >
                  Title Paperwork
                </Button>
              )}
            </Grid>
            {!loadHasUsdValues && (
              <Grid item xs={12}>
                <Alert severity="error" variant="filled">
                  Load Sheet Disabled: One or more vehicles missing USD Value
                </Alert>
              </Grid>
            )}
            <Grid item xs={12}>
              <Typography style={{ display: "inline" }}>
                {item.load_type === "single"
                  ? "Single Vehicle Load"
                  : "Multiple Vehicle Load"}
              </Typography>{" "}
              <Button
                size="small"
                variant="outlined"
                color="primary"
                disabled={!canChangeToSingle}
                onClick={() => changeTypeConfirm("single")}
              >
                Change to Single (Caravan)
              </Button>{" "}
              <Button
                size="small"
                variant="outlined"
                color="primary"
                disabled={!canChangeToMultiple}
                onClick={() => changeTypeConfirm("multiple")}
              >
                Change to Multiple (Carrier)
              </Button>
            </Grid>
          </Grid>
          <Hidden xsDown>
            <AppBar position="static" color="default">
              <Tabs
                value={tabIndex}
                onChange={(e, newValue) => setTabIndex(newValue)}
                indicatorColor="primary"
                textColor="primary"
                variant="fullWidth"
              >
                <SmallTab label="Basic Details" />
                <SmallTab
                  label={`Vehicles ${
                    item && item.vehicles && `(${item.vehicles.length})`
                  }`}
                />
                <SmallTab label="Bay" />
                <SmallTab label="Transport" />
              </Tabs>
            </AppBar>
          </Hidden>
          <Hidden smUp>
            <AppBar position="static" color="default" style={{ padding: 20 }}>
              <Select
                native
                value={tabIndex}
                onChange={(e) => {
                  setTabIndex(Number(e.target.value));
                }}
              >
                <option value={0}>Basic Details</option>
                <option value={1}>
                  Vehicles{" "}
                  {item && item.vehicles && `(${item.vehicles.length})`}
                </option>
                <option value={2}>Bay</option>
              </Select>
            </AppBar>
          </Hidden>
          <Typography
            component="div"
            role="tabpanel"
            hidden={tabIndex !== 0}
            style={{ paddingTop: 10 }}
          >
            <Grid container spacing={1}>
              {fields.map((field, index) => (
                <React.Fragment key={index}>
                  <Grid item xs={12} sm={2}>
                    {field.title}
                  </Grid>
                  <Grid item xs={12} sm={10}>
                    {field.type === "display" && (
                      <>{byString(item, field.field)}</>
                    )}
                    {field.type === "string" && (
                      <TextField
                        name={field.field}
                        placeholder={field.title}
                        value={item[field.field] || ""}
                        onChange={updateLocal}
                      />
                    )}
                    {clients_active.items.length > 0 &&
                      field.type === "selectClient" && (
                        <Select
                          native
                          name={field.field}
                          value={item[field.field] || ""}
                          onChange={updateLocal}
                          style={{ fontSize: 12 }}
                          displayEmpty
                        >
                          <option value="">Client Name</option>
                          {clients_active &&
                            clients_active.items.map((client, index) => (
                              <option key={index} value={client.clientid}>
                                {client.clientname}
                              </option>
                            ))}
                        </Select>
                      )}
                    {drivers.items.length > 0 &&
                      field.type === "selectClientDriver" && (
                        <Select
                          native
                          name={field.field}
                          value={item[field.field] || ""}
                          onChange={updateLocal}
                          style={{ fontSize: 12 }}
                          displayEmpty
                        >
                          <option value="">Driver</option>
                          {drivers &&
                            drivers.items.map((driver, index) => (
                              <option key={index} value={driver.id}>
                                {driver.first_name} {driver.last_name}
                              </option>
                            ))}
                        </Select>
                      )}
                    {field.type === "displayOnly" && (
                      <Typography>{item[field.field]}</Typography>
                    )}
                    {field.type === "selectClientTransporter" && (
                      <>
                        <Select
                          native
                          name={field.field}
                          value={item[field.field] || ""}
                          onChange={updateLocal}
                          style={{ fontSize: 12 }}
                          displayEmpty
                        >
                          <option value="">Transporter</option>
                          {transporters &&
                            transporters.items.map((obj, index) => (
                              <option key={index} value={obj.id}>
                                {obj.name}
                              </option>
                            ))}
                        </Select>
                        {locations.items.length === 0 && (
                          <>Client has no transporters</>
                        )}
                      </>
                    )}
                    {field.type === "selectClientLocation" && (
                      <>
                        <Select
                          native
                          name={field.field}
                          value={item[field.field] || ""}
                          onChange={updateLocal}
                          style={{ fontSize: 12 }}
                          displayEmpty
                        >
                          <option value="">Location</option>
                          {locations &&
                            locations.items.map((obj, index) => (
                              <option key={index} value={obj.id}>
                                {obj.alias} - {obj.name}
                              </option>
                            ))}
                        </Select>
                        {locations.items.length === 0 && (
                          <>Client has no locations</>
                        )}
                      </>
                    )}
                    {field.type === "date" && (
                      <MuiPickersUtilsProvider utils={MomentUtils}>
                        <KeyboardDatePicker
                          clearable
                          style={{ fontSize: 12 }}
                          // variant="inline"
                          name={field.field}
                          label={field.title}
                          format="MM/DD/YYYY"
                          autoOk={true}
                          value={item[field.field] || null}
                          InputProps={{
                            style: { fontSize: 12 },
                          }}
                          InputLabelProps={{ style: { fontSize: 12 } }}
                          onChange={(date) =>
                            updateLocalManual(
                              field.field,
                              date ? date.format("MM/DD/YYYY") : ""
                            )
                          }
                        />
                      </MuiPickersUtilsProvider>
                    )}
                  </Grid>
                </React.Fragment>
              ))}
            </Grid>
            <Divider style={{ marginTop: 20, marginBottom: 20 }} />
            <div style={{ width: "100%" }}>
              <HistoryItems objType={"clientload"} id={item.id} />
            </div>
          </Typography>
          <Typography
            component="div"
            role="tabpanel"
            hidden={tabIndex !== 1}
            style={{ paddingTop: 10 }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={3}>
                <TextField
                  name={"bkg_entry_load_number"}
                  placeholder={"BKG Load Number"}
                  value={bkgNumber}
                  onChange={(event) => {
                    setBkgNumber(event.target.value);
                    dispatch(
                      utilityActions.updateOneUtilityLocal("client_loads", {
                        bkg_number: event.target.value,
                      })
                    );
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={9}>
                <Button
                  size="small"
                  color="primary"
                  variant="contained"
                  onClick={() => massUpdate("bkg")}
                >
                  Update all BKG
                </Button>
              </Grid>

              <Grid item xs={12} sm={3}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <KeyboardDatePicker
                    clearable
                    style={{ fontSize: 12 }}
                    // variant="inline"
                    name={"sentToCustomsDate"}
                    label={"Sent to Customs Date"}
                    format="MM/DD/YYYY"
                    autoOk={true}
                    value={sentToCustomsDate || null}
                    InputProps={{
                      style: { fontSize: 12 },
                    }}
                    InputLabelProps={{ style: { fontSize: 12 } }}
                    onChange={(date) =>
                      setSentToCustomsDate(
                        date ? date.format("MM/DD/YYYY") : null
                      )
                    }
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={12} sm={9}>
                <Button
                  size="small"
                  color="primary"
                  variant="contained"
                  onClick={() => massUpdate("customs")}
                >
                  Update all Customs Date
                </Button>
              </Grid>

              <Grid item xs={12} sm={3}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <KeyboardDatePicker
                    clearable
                    style={{ fontSize: 12 }}
                    // variant="inline"
                    name={"crossedBorderDate"}
                    label={"Crossed Border Date"}
                    format="MM/DD/YYYY"
                    autoOk={true}
                    value={crossedBorderDate || null}
                    InputProps={{
                      style: { fontSize: 12 },
                    }}
                    InputLabelProps={{ style: { fontSize: 12 } }}
                    onChange={(date) =>
                      setCrossedBorderDate(
                        date ? date.format("MM/DD/YYYY") : null
                      )
                    }
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={12} sm={9}>
                <Button
                  size="small"
                  color="primary"
                  variant="contained"
                  onClick={() => massUpdate("crossed")}
                >
                  Update all Crossed Border
                </Button>
              </Grid>
              <Grid item xs={12} sm={3}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <KeyboardDatePicker
                    clearable
                    disabled={!canUpdateScanned7501}
                    style={{ fontSize: 12 }}
                    // variant="inline"
                    name={"scanned_7501_date"}
                    label={"Scanned 7501 Date"}
                    format="MM/DD/YYYY"
                    autoOk={true}
                    value={scannedDate || null}
                    InputProps={{
                      style: { fontSize: 12 },
                    }}
                    InputLabelProps={{ style: { fontSize: 12 } }}
                    onChange={(date) =>
                      setScannedDate(date ? date.format("MM/DD/YYYY") : null)
                    }
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={12} sm={9}>
                <Button
                  disabled={!canUpdateScanned7501}
                  size="small"
                  color="primary"
                  variant="contained"
                  onClick={() => massUpdate("scanned")}
                >
                  Update all Scanned 7501 Date
                </Button>
                {!canUpdateScanned7501 && (
                  <Typography style={{ color: "red" }}>
                    There are vehicles without a Crossed Border Date
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12} sm={3}>
                Upload Scanned 7501
              </Grid>
              <Grid item xs={12} sm={9}>
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  component="label"
                  disabled={
                    item.vehicles && item.vehicles.length > 0 ? false : true
                  }
                >
                  Choose 7501 File
                  <input
                    id={`upload_file`}
                    type="file"
                    name={`file`}
                    style={{ display: "none" }}
                    onChange={handleFileUpload}
                  />
                </Button>
              </Grid>
              <Grid item xs={12} sm={3}>
                Get All Bay Documents for All Vehicles
              </Grid>
              <Grid item xs={12} sm={9}>
                <Button
                  variant="contained"
                  size="small"
                  color="primary"
                  target="_blank"
                  href={`${getEnvironmentURL()}/api/client_loads/${
                    item.id
                  }/getBayDocuments?display=web`}
                >
                  Retreive All{" "}
                  <LaunchTwoToneIcon
                    style={{ fontSize: 12 }}
                    color="secondary"
                  />
                </Button>
              </Grid>

              <Grid item xs={12} style={{ marginTop: 20 }}>
                <Divider />
              </Grid>
              <Grid item xs={12} sm={2}>
                Vehicles
                <br />
                <Button
                  size="small"
                  color="primary"
                  variant="contained"
                  onClick={() => setModalOpen(true)}
                >
                  Add Vehicle
                </Button>
              </Grid>
              <Grid item xs={12} sm={10}>
                {item.vehicles && item.vehicles.length === 0 ? (
                  <i>No Vehicles</i>
                ) : (
                  <>
                    <ResponsiveGrid
                      data={item.vehicles}
                      columns={columns}
                      usePagination={false}
                    />
                  </>
                )}
              </Grid>
            </Grid>
          </Typography>
          <Typography
            component="div"
            role="tabpanel"
            hidden={tabIndex !== 2}
            style={{ paddingTop: 10 }}
          >
            {item.load_type === "multiple" && (
              <BayDetails load={item} onSave={handleSave} />
            )}
            {item.load_type === "single" && (
              <BayDetailsSingle load={item} onSave={handleSave} />
            )}
          </Typography>
          <Typography
            component="div"
            role="tabpanel"
            hidden={tabIndex !== 3}
            style={{ paddingTop: 10 }}
          >
            <ClientLoadTransport load={item} onRefreshItem={getNewData} />
          </Typography>
        </>
      ) : (
        <CircularProgress />
      )}
      {item && item.loading === false && (
        <>
          <ModalClientLoadAddVehicle
            item={item}
            open={modalOpen}
            onClose={handleOnClose}
            // onAddVehicle={handleAddVehicle}
          />
          <ModalPrintPaperwork
            url={`${getEnvironmentURL()}/api/vehicles/${item.vehicles
              .map((v) => v.vehicleid)
              .join(",")}/multipleForms/0`}
            open={modalPrintOpen}
            onClose={() => setModalPrintOpen(false)}
          />
        </>
      )}
      <ModalGeneric
        open={modalUsdValuesOpen}
        title="Vehicles missing Values in USD"
        onClose={() => setModalUsdValuesOpen(false)}
      >
        <Typography variant="h5" style={{ color: "red" }}>
          Unable to print load sheet until Values are Supplied
        </Typography>
        <br />
        {loadHasUsdValuesErrors.map((message) => (
          <Typography>{message}</Typography>
        ))}
      </ModalGeneric>
      <Backdrop open={openBackdrop} style={{ zIndex: 1000, color: "white" }}>
        <CircularProgress />
        <br />
        Mass Update Status:
        <br />
        {updateLog.map((log, index) => {
          return (
            <React.Fragment key={index}>
              {log}
              <br />
            </React.Fragment>
          );
        })}
      </Backdrop>
    </>
  );
}

function ModalUrlFix(props) {
  const { open: modalOpen, vehicleId, clientLoadId, bayShipmentId } = props;
  const [loadingVehicle, setLoadingVehicle] = useState(false);
  const [loadingBayVehicle, setLoadingBayVehicle] = useState(false);
  const [loadingFixVehicle, setLoadingFixVehicle] = useState(false);

  const [vehicle, setVehicle] = useState(null);
  const [bayVehicle, setBayVehicle] = useState(null);
  const [bayError, setBayError] = useState(null);
  const [bayFixResults, setBayFixResults] = useState(null);

  const [urlBayVehicleId, setUrlBayVehicleId] = useState(null);
  const [urlBayShipmentId, setUrlBayShipmentId] = useState(null);

  useEffect(() => {
    if (vehicleId) {
      setLoadingVehicle(true);
      utilityService
        .getEndpoint(`api/vehicles_base/${vehicleId}`, "get", {
          relations: ["bay_vehicle"],
        })
        .then((response) => {
          setVehicle(response);
          setLoadingVehicle(false);
        });
    }
  }, [vehicleId]);

  useEffect(() => {
    if (vehicle) {
      setLoadingBayVehicle(true);
      utilityService
        .getEndpoint(`api/vehicles/${vehicleId}/bayVehicleInfo`, "get")
        .then((response) => {
          if (response.vehicles.length === 0) {
            setBayError("Vehicle not found in Bay");
          } else if (response.vehicles.length > 1) {
            setBayError("More than one vehicle found in Bay");
          } else {
            setBayVehicle(response.vehicles[0]);
          }
        })
        .finally(() => {
          setLoadingBayVehicle(false);
        });
    }
  }, [vehicle]);

  const fixVehicle = () => {
    setLoadingFixVehicle(true);
    utilityService
      .getEndpoint(`api/vehicles/${vehicleId}/fixBayVehicle2`, "put", {
        body: {
          vehicleId: vehicle.vehicleid,
          clientLoadId: clientLoadId,
          bayVehicleId: urlBayVehicleId,
          bayShipmentId: urlBayShipmentId,
        },
      })
      .then((response) => {
        if (response?.success) {
          setBayFixResults(
            "SUCCESS: Vehicle Updated, Shipment Updated, RELOAD this page"
          );
        } else {
          setBayFixResults("ERROR: " + response?.error);
        }
      })
      .finally(() => {
        setLoadingFixVehicle(false);
      });
  };

  const analyzeUrl = (event) => {
    const text = event.target.value;
    const regex = /.*shipments\/([0-9]*)\/.*vins\/([0-9]*)[^0-9]*/i;
    const found = text.match(regex);
    if (found && found.length === 3) {
      setUrlBayShipmentId(found[1]);
      setUrlBayVehicleId(found[2]);
    } else {
      setUrlBayShipmentId(null);
      setUrlBayVehicleId(null);
    }
  };

  return (
    <ModalGeneric
      open={modalOpen}
      title="Bay URL Vehicle Fix"
      cancelText="CLose"
      closeText="Fix Vehicle"
      onCancel={() => {
        setVehicle(null);
        setBayVehicle(null);
        setUrlBayShipmentId(null);
        setUrlBayVehicleId(null);
        setBayError(null);
        setBayFixResults(null);
        props.onCancel();
      }}
      onClose={() => {
        fixVehicle();
      }}
    >
      {loadingVehicle && <CircularProgress />}
      {!loadingVehicle && vehicle && (
        <>
          {vehicle && (
            <>
              <Typography>
                DVS {vehicle.vehiclevin} (bayId=
                {vehicle?.bay_vehicle?.bay_vehicle_id}) (shipmentId=
                {bayShipmentId})
              </Typography>
            </>
          )}
        </>
      )}
      {loadingBayVehicle && <CircularProgress />}
      {bayError && (
        <>
          <Typography style={{ color: "red" }}>{bayError}</Typography>
        </>
      )}
      {!loadingBayVehicle && bayVehicle && (
        <>
          <Typography>
            Bay {bayVehicle.vin} (bayId={bayVehicle.id})
          </Typography>
          <TextField placeholder="Bay URL" fullWidth onChange={analyzeUrl} />
        </>
      )}
      {urlBayShipmentId && urlBayVehicleId && (
        <>
          <Typography>
            URL (bayId={urlBayVehicleId}) (shipmentId={urlBayShipmentId})
          </Typography>
        </>
      )}
      {loadingFixVehicle && <CircularProgress />}
      {!loadingFixVehicle && bayFixResults && (
        <>
          <Typography
            style={{
              fontWeight: "bold",
              color: bayFixResults.includes("SUCCESS") ? "green" : "red",
            }}
          >
            {bayFixResults}
          </Typography>
        </>
      )}
    </ModalGeneric>
  );
}

function ModalGetSoc(props) {
  const modalOpen = props.open;
  const socBayDirectly = props.socBayDirectly;
  const bayName = props.bayName;
  const bayDate = props.bayDate;
  const [signers, setSigners] = useState([]);
  const [selectedSigner, setSelectedSigner] = useState("");

  useEffect(() => {
    utilityService
      .getEndpoint("api/web_users", "get", {
        params: "filters[]=can_sign_soc|1",
      })
      .then((response) => {
        setSigners(response.data);
      });
  }, []);

  return (
    <ModalGeneric
      open={modalOpen}
      title="Signing Name"
      onClose={() => {
        props.onClose();
      }}
    >
      {socBayDirectly && (
        <Typography>From Bay Directly, no SOC checkbox replacements</Typography>
      )}
      <FormControl variant="filled" style={{ minWidth: 200 }}>
        <InputLabel id="demo-simple-select-filled-label">Signer</InputLabel>
        <Select
          value={selectedSigner}
          onChange={(e) => {
            setSelectedSigner(e.target.value);
            props.setSocSigner(e.target.value);
            if (Number(e.target.value) > 0) {
              const u = signers.find(
                (u) => Number(u.id) === Number(e.target.value)
              );
              props.setBayName(u.name);
            } else {
              props.setBayName("");
            }
          }}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          {signers.map((signer) => (
            <MenuItem key={String(signer.id)} value={signer.id}>
              {signer.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <TextField
        fullWidth
        value={bayName}
        onChange={(e) => props.setBayName(e.target.value)}
      />
      <TextField
        fullWidth
        value={bayDate}
        onChange={(e) => props.setBayDate(e.target.value)}
      />
      <Button
        // disabled={selectedSigner === ""}
        form="bay-form"
        type="submit"
        variant="contained"
        color="primary"
      >
        Get Documents
      </Button>
    </ModalGeneric>
  );
}

function BayDetails(props) {
  const dispatch = useDispatch();

  const item = useSelector((state) => state.utility.client_loads_item);
  const [id, setId] = useState(null);
  const [shipment, setShipment] = useState(null);
  const [canCreateBay, setCanCreateBay] = useState(false);
  const [createErrors, setCreateErrors] = useState(null);
  const [isCreating, setIsCreating] = useState(false);

  const [canUpdateBay, setCanUpdateBay] = useState(false);
  const [updateErrors, setUpdateErrors] = useState(null);
  const [isUpdating, setIsUpdating] = useState(false);

  const [bayStatus, setBayStatus] = useState(null);
  const [isChecking, setIsChecking] = useState(false);

  const updateLocal = (event) => {
    const { name, value } = event.target;
    // return;
    dispatch(
      utilityActions.updateOneUtilityLocal("client_loads", { [name]: value })
    );
  };
  const updateLocalManual = (name, value) => {
    dispatch(
      utilityActions.updateOneUtilityLocal("client_loads", { [name]: value })
    );
  };

  useEffect(() => {
    setShipment(item.bay_shipment);
    let create = true;
    let update = true;
    let doCheck = false;
    if (item) {
      if (item.id != id) {
        setId(item.id);
        doCheck = true;
      }
      if (item.vehicles.length === 0) {
        create = false;
        update = false;
      }
      if (item.vehicles.length > 0) {
        item.vehicles.map((v) => {
          if (Number(v.mileage) === 0) {
            create = false;
            update = false;
          }
          return null;
        });
      }
      if (item.location_from_id <= 0) {
        create = false;
        update = false;
      }
      if (item.location_to_id <= 0) {
        create = false;
        update = false;
      }
      if (item.client_transporter_id === null) {
        create = false;
        update = false;
      }
      if (trim(item.paps_number) === "") create = false;
      if (!isEmpty(item.bay_shipment)) {
        create = false;
      } else {
        update = false;
      }
    }
    if (doCheck) {
      checkBaySystem();
    }
    setCanCreateBay(create);
    setCanUpdateBay(update);
  }, [item]);

  const createBayShipment = () => {
    setIsCreating(true);
    setCreateErrors(null);
    setBayStatus(null);
    props.onSave(() => {
      utilityService
        .getEndpoint(`api/client_loads/${item.id}/createBayShipment`, "POST")
        .then((response) => {
          if (response.errors.length > 0) {
            setCreateErrors(response.errors);
          }
          props.onSave(() => {
            setIsCreating(false);
            checkBaySystem();
          });
        })
        .catch((e) => {
          setIsCreating(false);
        });
    });
  };

  const updateBayShipment = (photos = false) => {
    setIsUpdating(true);
    setUpdateErrors(null);
    setBayStatus(null);
    props.onSave(() => {
      let url = `api/client_loads/${item.id}/updateBayShipment`;
      if (photos) {
        url += "?photos=1";
      }
      utilityService
        .getEndpoint(url, "POST")
        .then((response) => {
          if (response.errors.length > 0) {
            setUpdateErrors(response.errors);
          }
          props.onSave(() => {
            setIsUpdating(false);
            checkBaySystem();
          });
        })
        .catch((e) => {
          setIsUpdating(false);
        });
    });
  };

  const checkBaySystem = () => {
    setIsChecking(true);
    setBayStatus(null);
    utilityService
      .getEndpoint(`api/client_loads/${item.id}/checkBayShipment`, "GET")
      .then((response) => {
        setIsChecking(false);
        setBayStatus(response);
      })
      .catch((e) => {
        console.warn("check error", e.message);
        setIsChecking(false);
        setBayStatus(null);
      });
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        Multiple (Carrier) Load
      </Grid>
      <Grid item xs={12}>
        {item.client_transporter_id === null && (
          <Alert severity="warning">Transporter missing</Alert>
        )}
        {item.vehicles.length === 0 && (
          <Alert severity="warning">No Vehicles</Alert>
        )}
        {item.location_from_id <= 0 && (
          <Alert severity="warning">Location From Missing</Alert>
        )}
        {item.location_to_id <= 0 && (
          <Alert severity="warning">Location To Missing</Alert>
        )}
        {item.vehicles.length > 0 &&
          item.vehicles.map((v) => {
            if (Number(v.mileage) === 0) {
              return (
                <Alert severity="warning">
                  {v.vehiclevin} missing mileage {v.mileage}
                </Alert>
              );
            }
            return null;
          })}
      </Grid>
      <Grid item xs={12}>
        <Button
          variant="contained"
          size="small"
          color="primary"
          disabled={!canCreateBay || isCreating}
          onClick={createBayShipment}
        >
          Create Bay Shipment
        </Button>{" "}
        <Button
          variant="contained"
          size="small"
          color="primary"
          disabled={!canUpdateBay || isUpdating}
          onClick={() => updateBayShipment(false)}
          endIcon={
            isUpdating ? <CircularProgress size={14} color="secondary" /> : null
          }
        >
          Update Bay Shipment
        </Button>{" "}
        <Button
          variant="contained"
          size="small"
          color="primary"
          disabled={!canUpdateBay || isUpdating}
          onClick={() => updateBayShipment(true)}
          endIcon={
            isUpdating ? <CircularProgress size={14} color="secondary" /> : null
          }
        >
          Update Photos
        </Button>
      </Grid>
      {isCreating ? (
        <CircularProgress />
      ) : (
        <>
          {createErrors && createErrors.length > 0 && (
            <Grid item xs={12}>
              {createErrors.map((errorText, index) => (
                <Typography key={index}>{errorText}</Typography>
              ))}
            </Grid>
          )}
          <Grid item xs={2}>
            Paps Number
          </Grid>

          <Grid item xs={10}>
            <TextField
              name="paps_number"
              value={item.paps_number || ""}
              onChange={updateLocal}
              fullWidth
              placeholder="Paps Number"
            />
          </Grid>
          <Grid item xs={2}>
            BKG Number
          </Grid>
          <Grid item xs={10}>
            <TextField
              name="bkg_number"
              value={item.bkg_number || ""}
              onChange={updateLocal}
              fullWidth
              placeholder="BKG Number"
            />
          </Grid>
          <Grid item xs={2}>
            Bay Status
          </Grid>
          <Grid item xs={10}>
            <Button
              size="small"
              variant="contained"
              color="primary"
              onClick={checkBaySystem}
              disabled={isChecking}
              endIcon={
                isChecking ? (
                  <CircularProgress size={14} color="secondary" />
                ) : null
              }
            >
              Check Bay System
            </Button>
            {bayStatus && <BayStatus status={bayStatus} />}
          </Grid>
          <Grid item xs={2}>
            Bay JSON
          </Grid>
          <Grid item xs={10}>
            {item.bay_shipment && (
              <>
                <JSONPretty data={item.bay_shipment}></JSONPretty>
                {/* {JSON.stringify(item.bay_shipment)} */}
              </>
            )}
          </Grid>
        </>
      )}
    </Grid>
  );
}

function BayDetailsSingle(props) {
  const dispatch = useDispatch();

  const item = useSelector((state) => state.utility.client_loads_item);
  const [id, setId] = useState(null);
  const [shipment, setShipment] = useState(null);
  const [canCreateBay, setCanCreateBay] = useState(false);
  const [createErrors, setCreateErrors] = useState(null);
  const [isCreating, setIsCreating] = useState(false);

  const [canUpdateBay, setCanUpdateBay] = useState(false);
  const [updateErrors, setUpdateErrors] = useState(null);
  const [isUpdating, setIsUpdating] = useState(false);

  const [bayStatus, setBayStatus] = useState(null);
  const [isChecking, setIsChecking] = useState(false);

  const [bayVehicleStatus, setBayVehicleStatus] = useState({
    bayHasShipment: 0,
    bayNotHasShipment: 0,
    bayTotal: 0,
  });

  const BayVehicleColumns = [
    {
      width: 2,
      title: "VIN",
      field: "vehiclevin",
    },
    {
      width: 2,
      title: "BKG#",
      field: "bkg_number",
      dataRender: (item) => item.bkg_number || "-----",
    },
    {
      width: 8,
      title: "Bay Status",
      field: null,
      dataRender: (item) => {
        const status = getBayVehicleStatus(item.vehicleid);
        return status ? status.error : "";
      },
    },
  ];

  const getBayVehicleStatus = (id) => {
    if (bayStatus) {
      if (bayStatus[id]) {
        return bayStatus[id];
      }
    } else {
      return null;
    }
  };

  const updateLocal = (event) => {
    const { name, value } = event.target;
    dispatch(
      utilityActions.updateOneUtilityLocal("client_loads", { [name]: value })
    );
  };
  const updateLocalManual = (name, value) => {
    dispatch(
      utilityActions.updateOneUtilityLocal("client_loads", { [name]: value })
    );
  };

  useEffect(() => {
    setShipment(item.bay_shipment);

    // check for vehicle statuses
    let bayHasShipment = 0;
    let bayNotHasShipment = 0;
    let bayTotal = 0;
    if (item) {
      item.vehicles.map((vehicle) => {
        bayTotal++;
        if (vehicle.bay_shipment) bayHasShipment++;
        else bayNotHasShipment++;
      });
    }
    setBayVehicleStatus({
      bayHasShipment,
      bayNotHasShipment,
      bayTotal,
    });

    // enable actions
    let create = true;
    let update = true;
    let doCheck = false;
    if (item) {
      if (item.id != id) {
        setId(item.id);
        doCheck = true;
      }
      if (item.vehicles.length === 0) create = false;
      if (item.location_from_id <= 0) create = false;
      if (item.location_to_id <= 0) create = false;
      if (bayNotHasShipment === 0) create = false;
      if (bayHasShipment === 0) update = false;
    }
    if (doCheck) {
      checkBaySystem();
    }
    setCanCreateBay(create);
    setCanUpdateBay(update);
  }, [item]);

  const createBayShipment = () => {
    setIsCreating(true);
    setCreateErrors(null);
    setBayStatus(null);
    props.onSave(() => {
      utilityService
        .getEndpoint(
          `api/client_loads/${item.id}/createBayShipmentSingle`,
          "POST"
        )
        .then((response) => {
          if (response.errors.length > 0) {
            setCreateErrors(response.errors);
          }
          props.onSave(() => {
            setIsCreating(false);
            checkBaySystem();
          });
        })
        .catch((e) => {
          console.log("create error", e.message);
          setIsCreating(false);
        });
    });
  };

  const updateBayShipment = (photos = false) => {
    setIsUpdating(true);
    setUpdateErrors(null);
    setBayStatus(null);
    props.onSave(() => {
      let url = `api/client_loads/${item.id}/updateBayShipmentSingle`;
      if (photos) {
        url += "?photos=1";
      }
      utilityService
        .getEndpoint(url, "POST")
        .then((response) => {
          if (response.errors.length > 0) {
            setUpdateErrors(response.errors);
          }
          props.onSave(() => {
            setIsUpdating(false);
            checkBaySystem();
          });
        })
        .catch((e) => {
          console.log("update error", e.message);
          setIsUpdating(false);
        });
    });
  };

  const checkBaySystem = () => {
    setIsChecking(true);
    setBayStatus(null);
    utilityService
      .getEndpoint(`api/client_loads/${item.id}/checkBayShipmentSingle`, "GET")
      .then((response) => {
        setIsChecking(false);
        setBayStatus(response);
      })
      .catch((e) => {
        console.warn("check error", e.message);
        setIsChecking(false);
        setBayStatus(null);
      });
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        Single (Caravan) Load, total vehicles ({bayVehicleStatus.bayTotal})
      </Grid>
      <Grid item xs={12}>
        <Button
          variant="contained"
          size="small"
          color="primary"
          disabled={!canCreateBay || isCreating}
          onClick={createBayShipment}
        >
          Create Bay Shipments ({bayVehicleStatus.bayNotHasShipment})
        </Button>{" "}
        <Button
          variant="contained"
          size="small"
          color="primary"
          disabled={!canUpdateBay || isUpdating}
          onClick={() => updateBayShipment(false)}
          endIcon={
            isUpdating ? <CircularProgress size={14} color="secondary" /> : null
          }
        >
          Update Bay Shipments ({bayVehicleStatus.bayHasShipment})
        </Button>{" "}
        <Button
          variant="contained"
          size="small"
          color="primary"
          disabled={!canUpdateBay || isUpdating}
          onClick={() => updateBayShipment(true)}
          endIcon={
            isUpdating ? <CircularProgress size={14} color="secondary" /> : null
          }
        >
          Update Photos
        </Button>
      </Grid>
      {isCreating ? (
        <Grid item xs={12}>
          <CircularProgress />
        </Grid>
      ) : (
        <Grid item xs={12}>
          <Grid container style={{ fontSize: 10, marginTop: 20 }}>
            {createErrors && createErrors.length > 0 && (
              <Grid item xs={12}>
                {createErrors.map((errorText, index) => (
                  <Typography key={index}>{errorText}</Typography>
                ))}
              </Grid>
            )}
            <ResponsiveGrid
              data={item.vehicles}
              columns={BayVehicleColumns}
              usePagination={false}
            />
          </Grid>
        </Grid>
      )}
      <Grid item xs={12}>
        Bay JSON Check
        <br />
        {JSON.stringify(bayStatus)}
      </Grid>
    </Grid>
  );
}

function VehicleSingleDriverOptions(props) {
  const drivers = useSelector((state) => state.utility.client_drivers_full);

  const [vehicleId, setVehicleId] = useState(props.vehicleId);
  const [driverId, setDriverId] = useState(props.driverId);
  const [transitNumber, setTransitNumber] = useState(props.transitNumber);
  const [scacCode, setScacCode] = useState(props.scacCode);

  const [needsToSave, setNeedsToSave] = useState(false);
  const [saving, setSaving] = useState(false);

  const saveChanges = () => {
    setSaving(true);
    utilityService
      .updateUtility("vehicles", vehicleId, {
        client_driver_id: driverId,
        transit_number: transitNumber,
      })
      .then((response) => {})
      .finally(() => {
        setSaving(false);
        setNeedsToSave(false);
      });
  };

  return (
    <>
      {saving ? (
        <CircularProgress />
      ) : (
        <>
          <TextField
            value={scacCode || ""}
            label="Scac Code"
            placeholder="Scac Code"
            style={{ fontSize: 12 }}
          />
          <FormControl>
            <InputLabel shrink>Driver</InputLabel>
            <Select
              native
              value={driverId || ""}
              style={{ fontSize: 12 }}
              onChange={(e) => {
                setDriverId(e.target.value);
                setNeedsToSave(true);
              }}
            >
              <option value="">NO DRIVER</option>
              {drivers.items &&
                drivers.items.map((driver, index) => {
                  return (
                    <option value={driver.id} key={index}>
                      {driver.name}
                    </option>
                  );
                })}
            </Select>
          </FormControl>
          <br />
          <TextField
            value={transitNumber || ""}
            label="Transit Number"
            placeholder="Transit Number"
            style={{ fontSize: 12 }}
            onChange={(e) => {
              setTransitNumber(e.target.value);
              setNeedsToSave(true);
            }}
          />
          <br />
          {needsToSave && (
            <Button
              variant="contained"
              size="small"
              color="primary"
              onClick={saveChanges}
            >
              Save Changes
            </Button>
          )}
        </>
      )}
    </>
  );
}

function BayStatus(props) {
  const [status, setStatus] = useState(props.status);

  useEffect(() => {
    setStatus(props.status);
  }, [props]);

  return (
    <Grid container>
      <Grid item xs={3}>
        Status
      </Grid>
      <Grid item xs={9}>
        {status.status}
      </Grid>
      <Grid item xs={3}>
        Bay Shipment
      </Grid>
      <Grid item xs={9}>
        {status.hasShipment === false ? (
          <Typography style={{ color: "red" }}>No Bay Shipment</Typography>
        ) : (
          `Bay Shipment Id: ${status.shipmentJson.id}`
        )}
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={3}>
        Errors
      </Grid>
      <Grid item xs={9}>
        {!status.errors || status.errors.length === 0 ? (
          <i>no errors</i>
        ) : (
          status.errors.map((error, index) => (
            <Typography key={index} style={{ color: "red" }}>
              {error}
            </Typography>
          ))
        )}
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={3}>
        ASC Load Vehicles
      </Grid>
      <Grid item xs={9}>
        {status.loadHasVehicles === false ? (
          <Typography style={{ color: "red" }}>No Vehicles in Load</Typography>
        ) : (
          `${status.loadNumVehicles} vehicles in Load`
        )}
      </Grid>
      <Grid item xs={3}>
        Bay Shipment Vehicles
      </Grid>
      <Grid item xs={9}>
        {status.bayHasVehicles === false ? (
          <Typography style={{ color: "red" }}>
            No Vehicles for Shipment in Bay
          </Typography>
        ) : (
          `${status.bayNumVehicles} vehicles in shipment`
        )}
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={3}>
        ASC Vehicles not in Bay
      </Grid>
      <Grid item xs={9}>
        {status.inLoadNotInBay.length > 0 &&
          status.inLoadNotInBay.map((vin, key) => (
            <Typography key={key} style={{ color: "red" }}>
              {vin}
            </Typography>
          ))}
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={3}>
        Bay Vehicles not in Load
      </Grid>
      <Grid item xs={9}>
        {status.inBayNotInLoad.length > 0 &&
          status.inBayNotInLoad.map((vin, key) => (
            <Typography key={key} style={{ color: "red" }}>
              {vin}
            </Typography>
          ))}
      </Grid>
    </Grid>
  );

  // return (
  //   <Grid container>
  //     {Object.keys(status).map((key) => (
  //       <React.Fragment key={key}>
  //         <Grid item xs={3}>
  //           {key}
  //         </Grid>
  //         <Grid item xs={9}>
  //           {status[key]}
  //         </Grid>
  //       </React.Fragment>
  //     ))}
  //   </Grid>
  // );
}

const SmallTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(4),
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:hover": {
      color: "#40a9ff",
      opacity: 1,
    },
    "&$selected": {
      color: "#1890ff",
      fontWeight: theme.typography.fontWeightMedium,
    },
    "&:focus": {
      color: "#40a9ff",
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

const styles = (theme) => ({
  // root: {
  //   display: "flex",
  //   fontSize: 12,
  // },
  // content: {
  //   flexGrow: 1,
  //   height: "100vh",
  //   overflow: "auto",
  // },
  // container: {
  //   paddingTop: theme.spacing(4),
  //   paddingBottom: theme.spacing(4),
  //   width: "100%",
  // },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    width: "100%",
  },
  // fixedHeight: {
  //   height: 240,
  // },
  // textField: {
  //   marginLeft: theme.spacing(1),
  //   marginRight: theme.spacing(1),
  // },

  // Button: {
  //   display: "block",
  //   marginTop: theme.spacing(2),
  // },
  // formControl: {
  //   margin: theme.spacing(1),
  //   minWidth: 120,
  // },
});

function mapStateToProps(state) {
  const { authentication, utility, batch } = state;
  const { user } = authentication;
  return {
    user,
    batch,
    utility,
  };
}

const connectedClientLoadDetailsPage = connect(mapStateToProps)(
  withWidth()(withStyles(styles)(ClientLoadDetailsPage))
);
export { connectedClientLoadDetailsPage as ClientLoadDetailsPage };
