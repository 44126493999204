import React, { useEffect } from "react";
import { Typography } from "@material-ui/core";

export default function VehicleDetailHeaderSummaryDisplay(props) {
  return (
    <>
      <Typography variant="body1" align="center">
        <b>VIN</b>: {props?.vehicle?.vin}
      </Typography>

      <Typography variant="body1" align="center">
        <b>Vehicle</b>: {props?.vehicle?.year} {props?.vehicle?.make}{" "}
        {props?.vehicle?.model}
      </Typography>

      <Typography variant="body1" align="center">
        <b>Vehicle Status</b>: {props?.titleStatus} ({props.timeInLatestStatus?.days || '??'} days in status)
      </Typography>
      
      <Typography variant="body1" align="center">
        <b>Latest Note</b>: {props?.latestNote}
      </Typography>
      
    </>
  );
}
